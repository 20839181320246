import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { LoadingButton } from '@mui/lab'
import { Button, ClickAwayListener, Paper } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { NOOP_PROMISE_VOID } from 'wdc-cube'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { DashboardFilterScope } from '../DashboardFilter.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeDashboardStyles } from './Dashboard.styles'

// @Inject
const texts = TextsProvider.get()

// :: Component(DashboardFilterView)

type DashboardFilterProps = IViewProps & {
    scope: DashboardFilterScope
}

export function DashboardFilterView({ className, scope }: DashboardFilterProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeDashboardStyles()

    return (
        <div
            key="container"
            className={clsx(styles.filter, className)}
            onClick={scope.opened ? NOOP_PROMISE_VOID : scope.onOpen}
        >
            <FilterAltOutlinedIcon />
            {scope.opened ? (
                <div className={styles.filterPopover}>
                    <PanelSearch scope={scope} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

type PanelSearchProps = {
    scope: DashboardFilterScope
}

function PanelSearch({ scope }: PanelSearchProps) {
    const { classes: styles } = getOrMakeDashboardStyles()

    return (
        <>
            <ClickAwayListener onClickAway={scope.onClose}>
                <Paper className={styles.filterPopoverContent}>
                    <div className={styles.spacingIntoPopoverContent}>
                        <div className={styles.adjustGrid}>
                            <div className={styles.adjustGridLabelItem}>
                                <label className={styles.filterLabelText}>{texts.DASHBOARD_FILTER_DATE_TIME}</label>
                            </div>
                            <ViewSlot scope={scope.startTime} />
                            <div className={styles.adjustGridItem}>
                                <ViewSlot scope={scope.endTime} />
                            </div>
                            <div className={styles.adjustChannelLabel}>
                                <label className={clsx(styles.filterLabelText, styles.filterChannelLabelText)}>
                                    {texts.DASHBOARD_FILTER_CHANNEL_NAME}
                                </label>
                                <ViewSlot scope={scope.channelsSelector} />
                            </div>
                            <div className={styles.filterAlignButton}>
                                <Button color="inherit" onClick={scope.onClear}>
                                    {texts.DASHBOARD_FILTER_CLEAR_BUTTON}
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    color="secondary"
                                    loading={scope.filtering}
                                    disabled={!scope.valid}
                                    onClick={scope.onApply}
                                >
                                    {texts.DASHBOARD_FILTER_FILTER_BUTTON}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </Paper>
            </ClickAwayListener>
        </>
    )
}
