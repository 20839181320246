import React from 'react'
import Intercom from '@intercom/messenger-js-sdk'

type ChatProps = {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    attributes?: any
}

export function Chat({ attributes }: ChatProps) {
    React.useEffect(() => {
        if (attributes) {
            Intercom(attributes)
        }
    }, [attributes])

    return <div />
}
