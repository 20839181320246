import { ExportToCsv } from 'src/utils/export-to-csv'
import { Logger } from 'wdc-cube'
import { DashboardService } from '../Dashboard.service'
import { TextsProvider } from '../texts'

const LOG = Logger.get('ActiveMessagesResumedExportXLSCommand')

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

export type ActiveMessagesResumedExportXLSCommandArguments = {
    channels: string[]
    startTime: Date
    endTime: Date
    customerId?: string
    alert?: (message: string) => void
}

export class ActiveMessagesResumedExportXLSCommand {
    private args: ActiveMessagesResumedExportXLSCommandArguments

    constructor(args: ActiveMessagesResumedExportXLSCommandArguments) {
        this.args = args
    }

    public async run() {
        const { channels, startTime, endTime, customerId, alert } = this.args

        const recordSet = (
            await dashboardService.fetchActiveMessagesResumed({
                channels,
                startDateTime: startTime,
                endDateTime: endTime,
                userId: customerId,
                pageIndex: 0,
                itemsPerPage: 10_000
            })
        ).entries
        if (recordSet.length === 0) {
            const message = texts.NO_CONTENT_TO_GENERATE_FILE
            if (alert) {
                alert(message)
            } else {
                LOG.info(message)
            }
            return
        }

        const reportData: Record<string, unknown>[] = recordSet.map((record) => ({
            nome_do_cliente: record.user.name,
            mesagens_enviadas: record.sentMessages
        }))

        const csvExporter = new ExportToCsv({
            filename: texts.FILE_NAME + (customerId ? `_${customerId}` : ''),
            fieldSeparator: '||',
            quoteStrings: '"',
            decimalSeparator: ',',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        })

        csvExporter.generateCsv(reportData)
    }
}
