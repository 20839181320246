import { ArrayStringCompare, DateFns, Strings, lodash } from '@syonet/lang'
import { ChannelId, Utils } from '@whatsapp/communication'
import { NOOP_PROMISE_VOID, Presenter, action } from 'wdc-cube'
import { ChatsPresenter } from '.'
import { ChatKeys } from '../../Constants'
import { AutoCompleteField, StringOptionType } from '../../components/autocompletefield'
import { ChipItemScope, ChipPanelScope } from '../../components/chippanel'
import { MultiListingPresenter } from '../../components/multilist'
import { ChatTicketStatus, ChatsService } from './Chats.service'
import { ChatsFilterScope } from './ChatsFilter.scopes'
import { TextsProvider } from './texts'

import type { BaseEvent } from '../../components/autocompletefield'
import type { KeyPressEvent, TextChangeEvent } from './ChatsFilter.scopes'

export type ChatsFilterState = {
    channels: string[]
    startTime: Date
    endTime: Date
    ticketStatus?: ChatTicketStatus
    customerName?: StringOptionType
    customerPhone?: StringOptionType
    totalItems?: number
    noCache?: boolean
}

// @Inject
const texts = TextsProvider.get()

// @Inject
const chatsService = ChatsService.singleton()

const FILTER_ID = '9fe54893-9d3b-467b-9847-622fb4d3ab97'

export class ChatsFilterPresenter extends Presenter<ChatsFilterScope> {
    public onSearch: (request: ChatsFilterState) => Promise<void> = NOOP_PROMISE_VOID

    private __initialized = false

    private __state: ChatsFilterState = {
        channels: [],
        startTime: DateFns.startOfDay(Date.now()),
        endTime: DateFns.endOfDay(Date.now()),
        ticketStatus: ChatTicketStatus.ALL
    }

    private __multiListingPresenter = new MultiListingPresenter(this, this.scope.channelsSelector, this.updateManager)
    private __customerNameField = new AutoCompleteField(this, this.scope.customerName, this.updateManager)
    private __customerPhoneField = new AutoCompleteField(this, this.scope.customerPhone, this.updateManager)
    private __credentialMap = new Map<string, Partial<ChannelId>>()
    private __channelMap = new Map<string, Partial<ChannelId>>()

    public constructor(owner: ChatsPresenter) {
        super(owner, new ChatsFilterScope())
    }

    public override release() {
        this.__customerNameField.release()
        this.__customerPhoneField.release()
        this.__multiListingPresenter.release()
        super.release()
    }

    public override get owner() {
        return super.owner as ChatsPresenter
    }

    public get credentials() {
        return this.__credentialMap
    }

    public get channels() {
        return this.__state.channels
    }

    public get customerName() {
        return this.__state.customerName
    }

    public get customerPhone() {
        return this.__state.customerPhone
    }

    public get ticketStatus() {
        return this.__state.ticketStatus ?? ChatTicketStatus.ALL
    }

    public get ticketStatusName() {
        return this.scope.ticketStatusOptions.get(this.ticketStatus) ?? ''
    }

    public get startTime() {
        return this.__state.startTime
    }

    public get endTime() {
        return this.__state.endTime
    }

    public fixKeys(keys: ChatKeys): ChatKeys {
        const previousFilter = this.load()

        if (!keys.startTime() && previousFilter?.startTime) {
            keys.startTime(previousFilter.startTime)
        }

        if (!keys.endTime() && previousFilter?.endTime) {
            keys.endTime(previousFilter.endTime)
        }

        if (!keys.channels() && previousFilter?.channels) {
            keys.channels(previousFilter.channels)
        }

        const ticketStatusIdx = keys.ticketStatus()
        if (ticketStatusIdx !== undefined && (ticketStatusIdx < 0 || ticketStatusIdx > 2)) {
            keys.ticketStatus(ChatTicketStatus.ALL)
        }

        const startTime = keys.startTime() ?? this.startTime
        const endTime = keys.endTime() ?? this.endTime

        if (DateFns.isAfter(startTime, endTime)) {
            keys.startTime(endTime)
            keys.endTime(startTime)
        }
        return keys
    }

    public cloneState(): ChatsFilterState {
        return lodash.cloneDeep(this.__state)
    }

    public save() {
        this.owner.app.setAttribute(FILTER_ID, this.__state)
    }

    public load() {
        if (this.__initialized) {
            const result: ChatsFilterState = { ...this.__state }
            return result
        } else {
            return this.owner.app.getAttribute(FILTER_ID) as ChatsFilterState | undefined
        }
    }

    public publishParameters(keys: ChatKeys) {
        keys.channels(this.channels)

        if (DateFns.isAfter(this.startTime, this.endTime)) {
            keys.startTime(this.endTime)
            keys.endTime(this.startTime)
        } else {
            keys.startTime(this.startTime)
            keys.endTime(this.endTime)
        }

        if (this.__state.customerName) {
            keys.customerNameId(this.__state.customerName.id)
        }

        if (this.__state.customerPhone) {
            keys.customerPhoneId(this.__state.customerPhone.id)
        }

        if (this.__state.ticketStatus !== ChatTicketStatus.ALL) {
            keys.ticketStatus(this.__state.ticketStatus)
        }
    }

    public propagateContext(keys: ChatKeys) {
        this.publishParameters(keys)
        keys.credentials(this.credentials)
    }

    private initilizeState() {
        this.scope.onOpen = this.onOpen.bind(this)
        this.scope.onClose = this.onClose.bind(this)
        this.scope.onClear = this.onClear.bind(this)
        this.scope.onApply = this.onApply.bind(this)
        this.scope.onTicketStatusValueUpdate = this.onUpdateChatIndex.bind(this)
        this.scope.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.scope.startTime.update = this.update
        this.scope.startTime.label = texts.CHATS_FILTER_DATE_TIME_START
        this.scope.startTime.onChange = this.onUpdateStartTime.bind(this)
        this.scope.startTime.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.scope.endTime.update = this.update
        this.scope.endTime.label = texts.CHATS_FILTER_DATE_TIME_END
        this.scope.endTime.onChange = this.onUpdateEndTime.bind(this)
        this.scope.endTime.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.scope.ticketStatusOptions.set(ChatTicketStatus.ALL, texts.CHAT_FILTER_TICKET_STATUS_ALL)
        this.scope.ticketStatusOptions.set(ChatTicketStatus.HAS_TICKET, texts.CHAT_FILTER_TICKET_STATUS_HUMAN)
        this.scope.ticketStatusOptions.set(ChatTicketStatus.NO_TICKET, texts.CHAT_FILTER_TICKET_STATUS_ROBOT)
        this.scope.ticketStatusOptions.length = 3

        this.__customerNameField.onSearch = this.onCustomerSearch.bind(this)
        this.__customerNameField.onGetSelectedValues = (map) => {
            if (this.__state.customerName) {
                map.set(this.__state.customerName.id, this.__state.customerName)
            }
        }
        this.scope.onCustomerNameInputChanged = this.onCustomerNameInputChanged.bind(this)

        this.__customerPhoneField.onSearch = this.onCustomerSearch.bind(this)
        this.__customerPhoneField.onGetSelectedValues = (map) => {
            if (this.__state.customerPhone) {
                map.set(this.__state.customerPhone.id, this.__state.customerPhone)
            }
        }
        this.scope.onCustomerPhoneInputChanged = this.onCustomerPhoneInputChanged.bind(this)

        this.__initialized = true
    }

    public async synchronizeState(keys: ChatKeys, force = false) {
        let changed = force

        if (!this.__initialized) {
            this.initilizeState()
            this.__initialized = true
        }

        const newChannels = keys.channels() ?? this.channels
        changed = changed || ArrayStringCompare(newChannels, this.channels) !== 0

        const newStartTime = keys.startTime() || this.startTime
        changed = changed || !DateFns.isEqual(newStartTime, this.startTime)

        const newEndTime = keys.endTime() || this.endTime
        changed = changed || !DateFns.isEqual(newEndTime, this.endTime)

        const newCustomerNameId = keys.customerNameId()

        const newCustomerName = newCustomerNameId
            ? { id: newCustomerNameId, name: 'unkown' }
            : this.__state.customerName
        changed = changed || newCustomerName?.id !== this.customerName?.id

        const newCustomerPhoneId = keys.customerPhoneId()

        const newCustomerPhone = newCustomerPhoneId
            ? { id: newCustomerPhoneId, name: 'unknown', phone: 'unknown' }
            : this.__state.customerPhone
        changed = changed || newCustomerPhone?.id !== this.customerPhone?.id

        const newTicketStatus = keys.ticketStatus() ?? this.ticketStatus
        changed = changed || newTicketStatus !== this.ticketStatus

        if (changed) {
            await this.applyData({
                channels: newChannels,
                startTime: newStartTime,
                endTime: newEndTime,
                ticketStatus: newTicketStatus,
                customerName: newCustomerName,
                customerPhone: newCustomerPhone
            })
        }

        return changed
    }

    public applyCredentialData(credentials: Map<string, Partial<ChannelId>>) {
        this.__credentialMap.clear()

        for (const credential of credentials.values()) {
            if (credential.phone) {
                this.__credentialMap.set(credential.phone, credential)
            }
        }

        this.normalizeChannels()
    }

    public async applyData(newsState: ChatsFilterState) {
        this.__state.channels = newsState.channels
        this.__state.startTime = newsState.startTime ?? DateFns.startOfDay(Date.now())
        this.__state.endTime = newsState.endTime ?? DateFns.endOfDay(Date.now())
        this.__state.ticketStatus = newsState.ticketStatus ?? ChatTicketStatus.ALL
        this.__state.customerName = newsState.customerName
        this.__state.customerPhone = newsState.customerPhone
        this.__customerPhoneField.pageSize = newsState.totalItems ?? 10
        this.__customerPhoneField.clearCache()
        this.normalizeChannels()
    }

    private normalizeChannels() {
        this.__channelMap.clear()
        const newChannels: string[] = []

        // Keep only when exists a corresponding credential
        for (const phone of this.__state.channels) {
            const credential = this.__credentialMap.get(phone)
            if (credential) {
                newChannels.push(phone)
                this.__channelMap.set(phone, credential)
            }
        }

        // In case of non valid channels, assume all credentials
        if (newChannels.length === 0) {
            for (const credential of this.__credentialMap.values()) {
                if (credential.phone) {
                    newChannels.push(credential.phone)
                    this.__channelMap.set(credential.phone, credential)
                }
            }
        }

        this.__state.channels = newChannels
    }

    private async onCustomerSearch(text: string, maxPageSize: number) {
        return await chatsService.fetchCustomers({
            channels: this.channels,
            startTime: this.startTime,
            endTime: this.endTime,
            ticketStatus: ChatTicketStatus.ALL,
            text,
            pageIndex: 0,
            itemsPerPage: maxPageSize
        })
    }

    @action()
    public close() {
        this.scope.opened = false
    }

    @action()
    private onUpdateStartTime(value: Date | null | undefined) {
        if (value) {
            this.scope.startTime.value = value
        } else {
            this.scope.startTime.value = this.__state.startTime
        }
    }

    @action()
    private onUpdateEndTime(value: Date | null | undefined) {
        if (value) {
            this.scope.endTime.value = value
        } else {
            this.scope.endTime.value = this.__state.endTime
        }
    }

    @action()
    private onUpdateChatIndex(evt: TextChangeEvent) {
        this.scope.ticketStatusValue = +evt.target.value
    }

    @action()
    private onCustomerNameInputChanged(evt: BaseEvent, newValue: string) {
        if (newValue) {
            this.scope.isCustomerNameChanging = true
        } else {
            this.scope.isCustomerNameChanging = false
        }
    }

    @action()
    private onCustomerPhoneInputChanged(evt: BaseEvent, newValue: string) {
        if (newValue) {
            this.scope.isCustomerPhoneChanging = true
        } else {
            this.scope.isCustomerPhoneChanging = false
        }
    }

    @action()
    private async onOpen() {
        this.scope.startTime.value = this.__state.startTime
        this.scope.endTime.value = this.__state.endTime
        this.scope.customerName.value = this.__state.customerName ?? null
        this.scope.customerPhone.value = this.__state.customerPhone ?? null
        this.scope.ticketStatusValue = this.__state.ticketStatus ?? ChatTicketStatus.ALL

        this.__multiListingPresenter.syncItems(
            Array.from(this.__credentialMap.values()).map((credential) => ({
                code: credential.phone as string,
                description: Utils.nameOrPhoneAndGroup({
                    name: credential.name,
                    phone: credential.phone ?? '',
                    group: credential.group ?? ''
                }),
                selected: this.__channelMap.has(credential.phone as string)
            }))
        )

        this.scope.opened = true
    }

    @action()
    private async onClose() {
        this.scope.opened = false
    }

    @action()
    private async onClear() {
        const filterArgs = this.cloneState()
        filterArgs.noCache = true

        if (this.__state.startTime || this.__state.endTime) {
            const now = new Date()
            filterArgs.startTime = DateFns.startOfDay(now)
            filterArgs.endTime = DateFns.endOfDay(now)
        }

        if (this.__state.customerName) {
            delete filterArgs.customerName
        }

        if (this.__state.customerPhone) {
            delete filterArgs.customerPhone
        }

        if (this.__state.ticketStatus) {
            delete filterArgs.ticketStatus
        }

        if (this.__state.channels.length < this.scope.channelsSelector.entries.length - 1) {
            filterArgs.channels = filterArgs.channels.filter((item) => item)
            if (filterArgs.channels.length === 0) {
                filterArgs.channels = Array.from(this.__credentialMap.values()).map(
                    (credential) => credential.phone ?? ''
                )
            } else {
                const channels: string[] = []
                Array.from(this.__credentialMap.values()).map((credential) => {
                    channels.push(credential.phone ?? '')
                })
                filterArgs.channels = channels
            }
        }

        this.scope.isCustomerNameChanging = false
        this.scope.isCustomerPhoneChanging = false

        try {
            this.scope.filtering = true
            this.scope.opened = false

            await this.onSearch(filterArgs)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    @action()
    private async onApplyFilterByPressedKey(evt: KeyPressEvent) {
        if (evt.key === 'Enter') {
            this.scope.onApply()
        }
    }

    @action()
    private async onApply() {
        const request: ChatsFilterState = {
            channels: this.selectedChannels,
            startTime: this.selectedStartTime,
            endTime: this.selectedEndTime,
            noCache: true
        }

        const ticketStatusIdx = this.scope.ticketStatusValue
        if (ticketStatusIdx != undefined && ticketStatusIdx !== ChatTicketStatus.ALL) {
            request.ticketStatus = ticketStatusIdx
        }

        const customerName = this.scope.customerName.value
        if (customerName) {
            request.customerName = customerName
        }

        const customerPhone = this.scope.customerPhone.value
        if (customerPhone) {
            request.customerPhone = customerPhone
        }

        if (DateFns.isAfter(request.startTime, request.endTime)) {
            this.alert(
                'error',
                texts.CHATS_FILTER_STATE_INCONSISTENCY_ALERT_TITLE,
                texts.CHATS_FILTER_STATE_INCONSISTENCY_ALERT_DESCRIPTION
            )
            return
        }

        if (request.channels.length === 0) {
            this.alert(
                'error',
                texts.CHATS_FILTER_STATE_INCONSISTENCY_ALERT_TITLE,
                texts.CHATS_FILTER_SEARCH_WITHOUT_ITEM_ALERT_DESCRIPTION
            )
            return
        }

        try {
            this.scope.filtering = true
            this.scope.opened = false

            await this.onSearch(request)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    private get selectedChannels() {
        const channels = this.__multiListingPresenter.codes
        return channels.length > 0 ? channels : this.__state.channels
    }

    private get selectedStartTime() {
        const date = this.scope.startTime.value
        return date ? date : this.__state.startTime
    }

    private get selectedEndTime() {
        const date = this.scope.endTime.value
        return date ? date : this.__state.endTime
    }

    public synchronizeChips(chipPanelScope: ChipPanelScope) {
        let i = 0
        try {
            let chip

            // start moment
            chip = chipPanelScope.entries.get(i)
            if (!chip) {
                chip = new ChipItemScope()
                chip.update = this.update
                chipPanelScope.entries.set(i, chip)
            }

            chip.description = `${texts.CHATS_FILTER_DATE_TIME_START}: ${DateFns.format(
                this.startTime,
                texts.CHATS_FILTER_DATE_TIME_FORMAT
            )}`
            chip.onRemove = this.onRemoveIntervalFilter.bind(this)
            i++

            // end moment
            chip = chipPanelScope.entries.get(i)
            if (!chip) {
                chip = new ChipItemScope()
                chip.update = this.update
                chipPanelScope.entries.set(i, chip)
            }

            chip.description = `${texts.CHATS_FILTER_DATE_TIME_END}: ${DateFns.format(
                this.endTime,
                texts.CHATS_FILTER_DATE_TIME_FORMAT
            )}`
            chip.onRemove = this.onRemoveIntervalFilter.bind(this)
            i++

            // It is not possible to remove channel when there is only one
            if (this.credentials.size > 1) {
                if (this.channels.length < this.credentials.size) {
                    for (const channel of this.channels) {
                        const credential = this.credentials.get(channel)
                        if (credential) {
                            chip = chipPanelScope.entries.get(i)
                            if (!chip) {
                                chip = new ChipItemScope()
                                chip.update = this.update
                                chipPanelScope.entries.set(i, chip)
                            }

                            const channelDescription = Utils.nameOrPhoneAndGroup({
                                name: credential.name,
                                phone: credential.phone ?? '',
                                group: credential.group ?? ''
                            })
                            chip.description = `${texts.CHATS_FILTER_CHANNEL_CHIP_DESCRIPTION}: ${channelDescription}`
                            chip.onRemove = this.onRemoveChannelFilter.bind(this, channel)
                            i++
                        }
                    }
                }
            }

            // userName
            if (this.customerName) {
                chip = chipPanelScope.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.update = this.update
                    chipPanelScope.entries.set(i, chip)
                }

                chip.description = `${texts.CHATS_FILTER_CUSTOMER_NAME} ${this.customerName.name}`
                chip.onRemove = this.onRemoveCustomerName.bind(this)
                i++
            }

            // userPhone
            if (this.customerPhone) {
                chip = chipPanelScope.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.update = this.update
                    chipPanelScope.entries.set(i, chip)
                }

                if (this.customerPhone.phone) {
                    chip.description = `${texts.CHATS_FILTER_CUSTOMER_PHONE} ${Strings.formatPhoneV2(
                        this.customerPhone.phone
                    )}`
                }
                chip.onRemove = this.onRemoveCustomerPhone.bind(this)
                i++
            }

            // chatName
            if (this.ticketStatus !== ChatTicketStatus.ALL) {
                chip = chipPanelScope.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.update = this.update
                    chipPanelScope.entries.set(i, chip)
                }

                chip.onRemove = this.onRemoveTicketStatus.bind(this)
                chip.description = `${texts.CHATS_FILTER_CHAT_TYPE} ${this.ticketStatusName}`
                i++
            }
        } finally {
            chipPanelScope.entries.length = i
        }
    }

    @action()
    private async onRemoveIntervalFilter() {
        const newFilterState = this.cloneState()
        const now = new Date()
        newFilterState.startTime = DateFns.startOfDay(now)
        newFilterState.endTime = DateFns.endOfDay(now)
        await this.onSearch(newFilterState)
    }

    @action()
    private async onRemoveCustomerName() {
        const newFilterState = this.cloneState()
        delete newFilterState.customerName
        await this.onSearch(newFilterState)
    }

    @action()
    private async onRemoveCustomerPhone() {
        const newFilterState = this.cloneState()
        delete newFilterState.customerPhone
        await this.onSearch(newFilterState)
    }

    @action()
    private async onRemoveTicketStatus() {
        const newFilterState = this.cloneState()
        delete newFilterState.ticketStatus
        await this.onSearch(newFilterState)
    }

    @action()
    private async onRemoveChannelFilter(channel: string) {
        const newFilterState = this.cloneState()
        newFilterState.channels = newFilterState.channels.filter((item) => item !== channel)

        if (newFilterState.channels.length === 0) {
            for (const credential of this.credentials.values()) {
                if (credential.phone) {
                    newFilterState.channels.push(credential.phone)
                }
            }
        }

        await this.onSearch(newFilterState)
    }

    public override onBeforeScopeUpdate() {
        const newChannels = this.selectedChannels
        const newStartTime = this.selectedStartTime
        const newEndTime = this.selectedEndTime

        let valid = true

        if (newChannels.length === 0) {
            valid = false
        }

        if (DateFns.isAfter(newStartTime, newEndTime)) {
            valid = false
        }

        const selectedCodes = this.__multiListingPresenter.codes
        if (selectedCodes.length === 0) {
            valid = false
        }

        this.scope.valid = valid
    }
}
