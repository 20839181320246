import { Button, DialogActions } from '@mui/material'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { SimpleAlertMessageContentScope } from '../Main.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeSimpleAlertMessageContentStyles } from './SimpleAlertMessageContent.styles'

// @Inject
const texts = TextsProvider.get()

export type SimpleAlertMessageContentViewProps = IViewProps & {
    scope: SimpleAlertMessageContentScope
}

export function SimpleAlertMessageContentView({ scope }: SimpleAlertMessageContentViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeSimpleAlertMessageContentStyles()

    return <div>
        <div className={styles.content}>
            {scope.message}
        </div>
        <DialogActions>
            <Button onClick={scope.onCancel}>
                {scope.cancelLabelOption || texts.SIMPLE_ALERT_MESSAGE_CANCEL_BUTTON}
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={scope.onConfirm}
                autoFocus
            >
                {scope.confirmLabelOption || texts.SIMPLE_ALERT_MESSAGE_CONFIRM_BUTTON}
            </Button>
        </DialogActions>
    </div>
}