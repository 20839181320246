import {
    MultipleNumericValuesAutoCompleteScope,
    MultipleStringValuesAutoCompleteScope,
    NumberOptionType,
    SimpleNumericValueAutoCompleteScope,
    SimpleStringValueAutoCompleteScope,
    StringOptionType
} from 'src/components/autocompletefield'
import { BaseEvent, KeyPressEvent, TextChangeEvent } from 'src/utils'
import { v4 as uuid } from 'uuid'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

export type {
    StringOptionType,
    NumberOptionType,
    SimpleNumericValueAutoCompleteScope,
    MultipleNumericValuesAutoCompleteScope,
    MultipleStringValuesAutoCompleteScope
}

@Observable
export class DashboardCustomerServiceFilterScope extends Scope {
    readonly uid = uuid()

    @observe() opened = false
    @observe() filtering = false
    @observe() valid = false
    @observe() ticketValue = ''

    readonly customer = new SimpleStringValueAutoCompleteScope()
    readonly company = new MultipleNumericValuesAutoCompleteScope()
    readonly user = new MultipleStringValuesAutoCompleteScope()
    readonly category = new MultipleStringValuesAutoCompleteScope()

    readonly serviceTypeOptions = new ObservableArray<NumberOptionType>(this)
    @observe() serviceTypeValue: NumberOptionType | null = null

    readonly durationOptions = new ObservableArray<NumberOptionType>(this)
    @observe() durationValue: NumberOptionType | null = null

    @observe() onTicketChange = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onServiceTypeChange = Scope.ASYNC_ACTION_TWO<BaseEvent, NumberOptionType | null>()
    @observe() onDurationChange = Scope.ASYNC_ACTION_TWO<BaseEvent, NumberOptionType | null>()
    @observe() onApplyFilterByPressedKey = Scope.SYNC_ACTION_ONE<KeyPressEvent>()

    @observe() onOpen = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
    @observe() onClear = Scope.ASYNC_ACTION
    @observe() onApply = Scope.ASYNC_ACTION
}
