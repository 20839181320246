import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()(() => ({
    view: {
        '& .MuiInputBase-input': {
            minWidth: 75,
            maxWidth: 240,
            padding: '8.5px 14px'
        }
    }
}))
