import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import Settings from '@mui/icons-material/Settings'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip
} from '@mui/material'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import clsx from 'clsx'
import React, { useRef } from 'react'
import { DndProvider, DragSourceHookSpec, DropTargetHookSpec, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SegmentationListElement } from 'src/components/segmentation/SegmentationList'
import { bindUpdate, CubeRefObject, IViewProps, ViewSlot } from 'wdc-cube-react'
import {
    ChannelConfigurationFormCompaniesScope,
    ChannelConfigurationFormCompanyScope
} from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormCompanyStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'

// @Inject
const texts = TextsProvider.get()

interface DropResult {
    sourceUid: string
    targetUid: string
}

// :: Component(ChannelConfigurationFormCompanies)

type ChannelConfigurationFormCompaniesProps = IViewProps & {
    scope: ChannelConfigurationFormCompaniesScope
    segmentation: SegmentationListElement
}

export function ChannelConfigurationFormCompaniesView({ scope, segmentation }: ChannelConfigurationFormCompaniesProps) {
    bindUpdate(React, scope)

    const addCompanyButtonRef = useRef<HTMLButtonElement>(null)

    if (scope.blink === 'add-company-button' && addCompanyButtonRef.current) {
        const buttomElm = addCompanyButtonRef.current
        buttomElm.scrollIntoView({ behavior: 'smooth' })

        setTimeout(() => {
            scope.blink = ''
        }, 3000)
    }

    const { classes: styles } = getOrMakeStyle()

    return (
        <div className={styles.companyBand}>
            <h2 className={styles.companyHeader}>{texts.CHANNEL_CONFIGURATION_GENERAL_COMPANIES}</h2>
            <div className={styles.adjustButtonAndIconBlink}>
                <div className={styles.addCompanyButton}>
                    <Button
                        ref={addCompanyButtonRef}
                        variant="contained"
                        color="secondary"
                        onClick={scope.onAppendCompany}
                    >
                        <AddIcon style={{ paddingRight: 8 }} />
                        <span>{texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_ADD_COMPANY}</span>
                    </Button>
                </div>
                {scope.blink === 'add-company-button' ? (
                    <div className={styles.buttonBlink}>
                        <IconButton>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {scope.companyEntries.length > 0 ? (
                <div style={{ width: '100%' }}>
                    <FormGroup>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={scope.companySegmentation}
                                        onChange={scope.onCompanySegmentation}
                                        color="secondary"
                                    />
                                }
                                label={texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_LABEL}
                            />

                            <Tooltip title={texts.CHANNEL_CONFIGURATION_GENERAL_ON_SHOW_INFO_TOOLTIP} arrow>
                                <IconButton onClick={scope.onShowInfo}>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </FormGroup>
                    {scope.companySegmentation && segmentation}
                    <div style={{ width: '100%' }}>
                        <DndProvider backend={HTML5Backend}>
                            <div className={styles.adjustCardGrid}>
                                {scope.companyEntries.map((companyScope) => {
                                    return (
                                        <ChannelConfigurationFormCompanyView
                                            key={companyScope.uid}
                                            scope={companyScope}
                                            onDrop={scope.onSwapPosition}
                                        />
                                    )
                                })}
                            </div>
                        </DndProvider>
                    </div>
                </div>
            ) : (
                <Alert severity="error" sx={{ width: '98.7%' }}>
                    {texts.CHANNEL_CONFIGURATION_GENERAL_NO_COMPANY_ADDED}
                </Alert>
            )}
        </div>
    )
}

// :: Component(ChannelConfigurationFormCompanyView)

type DropEvent = (targetUid: string, sourceUid: string) => void

type DragObject = {
    sourceUid: string
}

type CollectedProps = {
    isDragging?: boolean
    canDrop?: boolean
    isOver?: boolean
}

type ChannelConfigurationFormCompanyViewProps = IViewProps & {
    scope: ChannelConfigurationFormCompanyScope
    onDrop: DropEvent
}

function ChannelConfigurationFormCompanyView(props: ChannelConfigurationFormCompanyViewProps) {
    const { className, scope } = props

    bindUpdate(React, scope)

    const { styles, segmentFieldRef, onFocusOnSegmentFieldImmediatly, onUseDrag, onUseDrop } =
        ChannelConfigurationFormCompanyViewContext.getOrCreate(props)

    const [{ isDragging }, drag] = useDrag<DragObject, DropResult, CollectedProps>(onUseDrag)
    const [{ canDrop, isOver }, drop] = useDrop<DragObject, DropResult, CollectedProps>(onUseDrop)

    const opacity = isDragging ? 0.4 : 1

    const isActive = canDrop && isOver
    let backgroundColor = '#fff'
    if (isActive) {
        backgroundColor = '#cecacab3'
    } else if (canDrop) {
        backgroundColor
    }

    const labelId = `${scope.uid}-label`

    if (scope.focus === 'segment') {
        onFocusOnSegmentFieldImmediatly()
    }

    function handleOpenEditorCompanyModal() {
        if (scope.companyValue) {
            scope.onOpenCompanyConfigurationEditor({ id: scope.companyValue })
        }
    }

    return (
        <div ref={drag} style={{ opacity, backgroundColor }} className={clsx(styles.view, styles.card, className)}>
            <div ref={drop}>
                <div className={styles.cardHeader}>
                    <div>
                        <IconButton>
                            <DragIndicatorIcon />
                        </IconButton>
                        <span className={styles.customFont}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_CARD_POSITION_DESCRIPTION} {scope.position}
                        </span>
                    </div>
                    <div className={styles.adjustActionIconsPosition}>
                        <Tooltip title={texts.CHANNEL_CONFIGURATION_GENERAL_TOOGLE_SHOW_ATTENDANT_NAME_TOOLTIP}>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    onClick={scope.onToggleShowAttendantName}
                                    checked={scope.toggleShowAttendantName}
                                    color="secondary"
                                />{' '}
                                <FingerprintIcon />
                            </Box>
                        </Tooltip>
                        <div>
                            <IconButton onClick={handleOpenEditorCompanyModal}>
                                <Settings />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton onClick={scope.onDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className={styles.cardContent}>
                    <FormControl fullWidth>
                        <InputLabel id={labelId}>{texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_INPUT_LABEL}</InputLabel>
                        <Select
                            labelId={labelId}
                            id={scope.uid}
                            value={(scope.companyValue ?? '').toString()}
                            label={texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_INPUT_LABEL}
                            onChange={scope.onCompanyChanged}
                        >
                            {scope.companyOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {scope.segmentationVisible ? (
                        <div className={styles.adjustSegmentationContent}>
                            <FormControl className={styles.adjustSegmentationPosition} fullWidth>
                                <TextField
                                    label={texts.CHANNEL_CONFIGURATION_GENERAL_SEGMENTATION_INPUT_LABEL}
                                    InputProps={{
                                        startAdornment: (
                                            <div onClickCapture={onFocusOnSegmentFieldImmediatly} className={styles.adjustSegmentationContainer}>
                                                <ViewSlot scope={scope.segmentation} />
                                            </div>
                                        )
                                    }}
                                    value={scope.segmentName}
                                    onChange={scope.onSegmentNameChanged}
                                    onBlur={scope.onSegmentNameBlur}
                                    onKeyDown={scope.onSegmentNameKeyDown}
                                    inputRef={segmentFieldRef}
                                />
                            </FormControl>
                            <div className={styles.segmentationInfo}>
                                {texts.CHANNEL_CONFIGURATION_GENERAL_INCLUDE_NEW_SEGMENTATION_HINT}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}

class ChannelConfigurationFormCompanyViewContext {
    static readonly create = () => new ChannelConfigurationFormCompanyViewContext()

    static getOrCreate({ scope, onDrop }: ChannelConfigurationFormCompanyViewProps) {
        const [ctx] = React.useState(ChannelConfigurationFormCompanyViewContext.create)
        ctx.scope = scope
        ctx.styles = getOrMakeStyle().classes
        ctx.onDrop = onDrop
        return ctx
    }

    styles!: ReturnType<typeof getOrMakeStyle>['classes']
    readonly segmentFieldRef = new CubeRefObject<HTMLInputElement>()

    scope!: ChannelConfigurationFormCompanyScope
    onDrop?: DropEvent

    // Events

    readonly onFocusOnSegmentFieldImmediatly = () => {
        this.segmentFieldRef.current?.focus()
        this.scope.focus = ''
    }

    readonly onUseDrag = (): DragSourceHookSpec<DragObject, DropResult, CollectedProps> => {
        return {
            type: '1b958f203e50',
            item: { sourceUid: this.scope.uid },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId()
            })
        }
    }

    readonly onUseDrop = (): DropTargetHookSpec<DragObject, DropResult, CollectedProps> => {
        return {
            accept: '1b958f203e50',
            drop: ({ sourceUid }) => {
                const result: DropResult = { sourceUid, targetUid: this.scope.uid }
                if (this.onDrop) {
                    this.onDrop(result.targetUid, result.sourceUid)
                }
                return result
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }
    }
}
