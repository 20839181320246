import { BaseEvent } from 'src/utils'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

export type { BaseEvent }

export type StringOptionType = {
    id: string
    name: string
    phone?: string
}

export type NumberOptionType = {
    id: number
    name: string
}

export type OptionLabelType = (option: string | StringOptionType) => string

// :: Simple values

@Observable
export class SimpleValueAutoCompleteScope<T> extends Scope {
    readonly options = new ObservableArray<T>(this)

    @observe() value?: T | null

    @observe() onChange = Scope.ASYNC_ACTION_ONE<BaseEvent>()
    @observe() onInputChange = Scope.SYNC_ACTION_TWO<BaseEvent, string>()
    @observe() onFocus = Scope.SYNC_ACTION
}

export class SimpleNumericValueAutoCompleteScope extends SimpleValueAutoCompleteScope<NumberOptionType> { }
export class SimpleStringValueAutoCompleteScope extends SimpleValueAutoCompleteScope<StringOptionType> { }

// :: Multiple values

@Observable
export class MultipleValuesAutoCompleteScope<T> extends Scope {
    readonly options = new ObservableArray<T>(this)
    @observe() value?: T[] | null
    @observe() required = false

    @observe() onChange = Scope.ASYNC_ACTION_TWO<BaseEvent, T[] | null>()
    @observe() onInputChange = Scope.SYNC_ACTION_TWO<BaseEvent, string>()
    @observe() onFocus = Scope.SYNC_ACTION
}

export class MultipleNumericValuesAutoCompleteScope extends MultipleValuesAutoCompleteScope<NumberOptionType> { }
export class MultipleStringValuesAutoCompleteScope extends MultipleValuesAutoCompleteScope<StringOptionType> { }
