import { KeyPressEvent } from 'src/utils'
import { Observable, observe, Scope } from 'wdc-cube'

export type { KeyPressEvent }

@Observable
export class DateTimePickerScope extends Scope {
    @observe() label?: string
    @observe() value?: Date | null = null
    @observe() format?: string

    @observe() onChange = Scope.SYNC_ACTION_TWO<Date | null | undefined, string | undefined>()
    @observe() onApplyFilterByPressedKey = Scope.ASYNC_ACTION_ONE<KeyPressEvent>()
}
