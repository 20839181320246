import { TextChangeEvent } from 'src/utils'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

export type { TextChangeEvent }

export type OptionType<T> = {
    id: T
    name: string
}

@Observable
export class ComboScope<T> extends Scope {
    readonly options = new ObservableArray<OptionType<T>>(this)
    @observe() value?: T | null
    @observe() excluded = false
    @observe() disabled = false
    @observe() required = false

    @observe() onChange = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
}
