import { ChipItemScope } from 'src/components/chippanel'
import { ChannelConfigurationListingKeys, ChannelErrorLocation, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { CubePresenter, FlipIntent, NOOP_PROMISE_VOID, NOOP_VOID, ScopeSlot } from 'wdc-cube'
import { WaitingScope } from '../dashboard/Dashboard.scopes'
import { MainPresenter } from '../main'
import { AbstractValidationContentPresenter } from './AbstractValidationContentPresenter'
import {
    ChannelConfigurationService,
    ChannelInfoListing,
    ChannelValidationStatus
} from './ChannelConfiguration.service'
import { ChannelConfigurationListingScope, ChannelEntryScope } from './ChannelConfigurationListing.scopes'
import {
    ChannelConfigurationListingFilterPresenter,
    ChannelConfigurationListingFilterState
} from './ChannelConfigurationListingFilter.presenter'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

const configChannelsService = ChannelConfigurationService.singleton()

export class ChannelConfigurationListingPresenter extends CubePresenter<
    MainPresenter,
    ChannelConfigurationListingScope
> {
    private parentSlot = NOOP_VOID as ScopeSlot

    private filterSlot = NOOP_VOID as DetachedScopeSlot

    private readonly filter = new ChannelConfigurationListingFilterPresenter(this)

    private readonly alertValidationContentPresenter = new AlertValidationContentPresenter(this)

    private loggedUserId = ''

    private alertData = new Map<string, ChannelValidationStatus>()

    private closeAlert = NOOP_VOID

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationListingScope())
    }

    public override release() {
        this.filterSlot(this.filter.scope, true)
        this.filter.release()

        this.alertValidationContentPresenter.release()

        super.release()
    }

    // Cube context syncronization methods: Begin

    public override async applyParameters(
        intent: FlipIntent,
        initialization: boolean,
        last: boolean
    ): Promise<boolean> {
        const keys = new ChannelConfigurationListingKeys(intent)

        if (last) {
            keys.refresh(true)
        }

        if (initialization) {
            await this.initializeState(keys)
            this.parentSlot(this.scope)
        } else {
            this.parentSlot(this.scope)
            await this.synchronizeState(keys)
        }

        if (last) {
            this.filterSlot(this.filter.scope, false)
        } else {
            this.propagateContext(intent)
            this.filterSlot(this.filter.scope, true)
            this.closeAlert()
            this.closeAlert = NOOP_VOID
        }

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationListingKeys(intent)
        this.filter.publishParameters(keys)
    }

    // Cube context syncronization methods: End

    private async initializeState(keys: ChannelConfigurationListingKeys) {
        this.bindListeners()
        this.parentSlot = keys.parentSlot()
        this.filterSlot = keys.filterSlot()

        const waiting = new WaitingScope()
        waiting.text = texts.LOADING_CONTENT_DESCRIPTION
        waiting.update = this.update
        this.parentSlot(waiting)

        this.alertValidationContentPresenter.initialize({
            closeAction: () => {
                this.closeAlert()
                this.closeAlert = NOOP_VOID
            },

            publishAction: NOOP_PROMISE_VOID
        })

        await this.synchronizeState(keys)
    }

    private async synchronizeState(keys: ChannelConfigurationListingKeys) {
        let changed = keys.refresh() ?? false

        const newLoggedUserId = keys.loggedUserId() ?? this.loggedUserId
        changed = changed || this.loggedUserId !== newLoggedUserId

        changed = this.filter.synchronizeState(keys) || changed

        if (changed) {
            this.loggedUserId = newLoggedUserId
            await this.doSearch(this.filter.cloneState())
        }
    }

    private propagateContext(intent: FlipIntent) {
        const keys = new ChannelConfigurationListingKeys(intent)
        this.filter.publishParameters(keys)
        keys.parentSlot(this.parentSlot)
    }

    private bindListeners() {
        {
            // Filter Information Panel
            const filterInfoPanel = this.scope.filterChipPanel
            filterInfoPanel.update = this.update
            this.updateManager.hint(ChipItemScope, filterInfoPanel, 5)
        }

        {
            const channelTableScope = this.scope.channelTable
            channelTableScope.update = this.update
        }

        this.filter.onSearch = this.onSearch.bind(this)
    }

    private async doSearch(filterArgs: ChannelConfigurationListingFilterState) {
        try {
            this.scope.channelTable.loading = true

            const listingData = await configChannelsService.fetchListing(filterArgs.name, filterArgs.number)
            this.listingSynchronizeState(listingData)
            this.filter.applyData(filterArgs)
        } finally {
            this.scope.channelTable.loading = false
            this.updateHistory()
        }
    }

    private async onSearch(filterArgs: ChannelConfigurationListingFilterState) {
        this.doSearch(filterArgs)
        this.updateHistory()
    }

    private async onEditChannel(item: ChannelEntryScope) {
        const channelId = item.id
        if (channelId) {
            const formKeys = KeysFactory.channelConfigurationForm(this.app)
            formKeys.channelId(channelId)
            await this.flipToIntent(formKeys.intent)
        } else {
            throw new Error(texts.CHANNEL_CONFIGURATION_LISTING_ON_EDIT_CHANNEL_ERROR)
        }
    }

    private async onClickAlert(item: ChannelEntryScope) {
        const channelId = item.id
        if (channelId) {
            const alertData = this.alertData.get(channelId)
            if (alertData) {
                const alertPresenter = this.alertValidationContentPresenter
                alertPresenter.synchronizeData(channelId, alertData)
                alertPresenter.prepareToInformation()
                this.closeAlert = this.app.alert(
                    alertPresenter.severity,
                    alertPresenter.caption,
                    alertPresenter.scope,
                    alertPresenter.closeListener
                )
            } else {
                // TODO
            }
        } else {
            throw new Error(texts.CHANNEL_CONFIGURATION_LISTING_ON_CLICK_ALERT_CHANNEL_ERROR)
        }
    }

    private listingSynchronizeState(listingData: ChannelInfoListing) {
        const channelTableScope = this.scope.channelTable

        this.alertData.clear()

        let i = 0
        for (const item of listingData.items) {
            let itemScope = channelTableScope.entries.get(i)
            if (!itemScope) {
                itemScope = new ChannelEntryScope()
                itemScope.update = this.update
                itemScope.onEdit = this.onEditChannel.bind(this, itemScope)
                itemScope.onClickAlert = this.onClickAlert.bind(this, itemScope)
                channelTableScope.entries.set(i, itemScope)
            }

            itemScope.id = item.id
            itemScope.name = item.name
            itemScope.draft = item.draft ?? false
            itemScope.phone = item.phone
            itemScope.warningType = item.status.type
            itemScope.publishedStatus = item.published
                ? texts.CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_AFIRMATION
                : texts.CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_DENY

            this.alertData.set(item.id, item.status)

            i++
        }
        channelTableScope.entries.length = i
    }

    public override onBeforeScopeUpdate() {
        this.filter.syncChips(this.scope.filterChipPanel)
    }
}

class AlertValidationContentPresenter extends AbstractValidationContentPresenter {
    private __channelId = ''

    public constructor(owner: ChannelConfigurationListingPresenter) {
        super(owner)
    }

    public override get owner() {
        return super.owner as ChannelConfigurationListingPresenter
    }

    public synchronizeData(channelId: string, data: ChannelValidationStatus) {
        this.__channelId = channelId
        this.applyStatus(data)
    }

    private async gotoForm(location: ChannelErrorLocation) {
        if (this.__channelId) {
            const formKeys = KeysFactory.channelConfigurationForm(this.owner.app)
            formKeys.channelId(this.__channelId)
            formKeys.showError(location)
            await this.owner.app.flipToIntent(formKeys.intent)
        }
    }

    protected override async onChannelNameClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.CHANNEL_NAME)
    }

    protected override async onCompanyAssigmentClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.COMPANY)
    }

    protected override async onCompanySegmentationClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.COMPANY_SEGMENT)
    }

    protected override async onMenuDefinitionsClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.MENU)
    }

    protected override async onMessageValidationClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.MESSAGE)
    }

    protected override async onWorkingHourValidationClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.HOURS)
    }

    protected override async onWorkingHourExisting24Clicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.HOURS24)
    }

    protected override async onExtrasValidationClicked() {
        this.__closeAction()
        await this.gotoForm(ChannelErrorLocation.EXTRA)
    }
}
