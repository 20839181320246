import { ActiveMessageButton } from '../../../infobip'
import { DefaultVariable } from './templates'

export enum WhatsAppTemplateLanguage {
    EN_US = 'en_US',
    PT_BR = 'pt_BR',
    ES = 'es'
}

export enum WhatsAppTemplateCategory {
    MARKETING = 'MARKETING',
    AUTHENTICATION = 'AUTHENTICATION',
    UTILITY = 'UTILITY'
}

export enum WhatsAppTemplateType {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    DOCUMENT = 'DOCUMENT',
    LOCATION = 'LOCATION',
    PRODUCT = 'PRODUCT',
    CATALOG = 'CATALOG',
    LTO = 'LTO',
    CAROUSEL = 'CAROUSEL',
    MEDIA = 'MEDIA',
    UNSUPPORTED = 'UNSUPPORTED'
}

export enum WhatsAppTemplateHeaderFormat {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    DOCUMENT = 'DOCUMENT',
    LOCATION = 'LOCATION'
}

export type WhatsAppTemplateHeaderText = {
    format: WhatsAppTemplateHeaderFormat.TEXT
    text: string
    example?: string
}

export type WhatsAppTemplateHeaderImage = {
    format: WhatsAppTemplateHeaderFormat.IMAGE
    example?: string
}

export type WhatsAppTemplateHeaderVideo = {
    format: WhatsAppTemplateHeaderFormat.VIDEO
    example?: string
}

export type WhatsAppTemplateHeaderDocument = {
    format: WhatsAppTemplateHeaderFormat.DOCUMENT
    example?: string
}

export type WhatsAppTemplateHeaderLocation = {
    format: WhatsAppTemplateHeaderFormat.LOCATION
}

export enum WhatsAppTemplateButtonType {
    QUICK_REPLY = 'QUICK_REPLY',
    URL = 'URL',
    PHONE_NUMBER = 'PHONE_NUMBER'
}
export declare type WhatsAppTemplateHeaderMedia =
    | WhatsAppTemplateHeaderImage
    | WhatsAppTemplateHeaderVideo
    | WhatsAppTemplateHeaderDocument

export type WhatsAppTemplateStructureHeader =
    | WhatsAppTemplateHeaderText
    | WhatsAppTemplateHeaderImage
    | WhatsAppTemplateHeaderVideo
    | WhatsAppTemplateHeaderDocument
    | WhatsAppTemplateHeaderLocation

export type WhatsAppTemplateFooter = {
    text: string
}

export type WhatsAppTemplateButtonQuickReply = {
    type: WhatsAppTemplateButtonType.QUICK_REPLY
    text: string
}

export type WhatsAppTemplateButtonPhoneNumber = {
    type: WhatsAppTemplateButtonType.PHONE_NUMBER
    text: string
    phoneNumber: string
}

export type WhatsAppTemplateButtonURL = {
    type: WhatsAppTemplateButtonType.URL
    text: string
    url: string
    example?: string
}

export type WhatsAppTemplateButton =
    | WhatsAppTemplateButtonQuickReply
    | WhatsAppTemplateButtonPhoneNumber
    | WhatsAppTemplateButtonURL

export type WhatsAppTemplateBody = { text: string; examples?: string[] }

export type WhatsAppTemplateStructure = {
    body: WhatsAppTemplateBody
    type: WhatsAppTemplateType
    header?: WhatsAppTemplateStructureHeader
    footer?: WhatsAppTemplateFooter
    buttons?: WhatsAppTemplateButton[]
}

export type WhatsAppTemplate = {
    name: string
    language: WhatsAppTemplateLanguage
    category: WhatsAppTemplateCategory
    allowCategoryChange: boolean
    structure: WhatsAppTemplateStructure
}

export enum TemplateQuality {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    UNKNOWN = 'UNKNOWN'
}

export enum WhatsAppTemplateStatus {
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    DELETED = 'DELETED',
    PENDING_DELETION = 'PENDING_DELETION',
    DISABLED = 'DISABLED'
}

export const REJECTED_STATUSES = [
    WhatsAppTemplateStatus.REJECTED,
    WhatsAppTemplateStatus.DELETED,
    WhatsAppTemplateStatus.DISABLED
]

export type GupshupDuplicatedCreationResponse = {
    channel: string
}

export type GupshupWhatsAppTemplate = {
    elementName: string
    languageCode: WhatsAppTemplateLanguage
    category: WhatsAppTemplateCategory
    templateType: WhatsAppTemplateType
    content: string
    header?: string
    footer?: string
    buttons?: WhatsAppTemplateButton[]
    example: string
    enableSample: boolean
    allowTemplateCategoryChange: boolean
    exampleHeader?: string
    exampleMedia?: string
    vertical: string
}

export type TemplateResponse = {
    appId: string
    category: string
    createdOn: number
    data: string
    elementName: string
    id: string
    languageCode: string
    languagePolicy: string
    master: boolean
    meta: string
    modifiedOn: number
    namespace: string
    status: WhatsAppTemplateStatus
    templateType: WhatsAppTemplateType
    vertical: string
    allowTemplateCategoryChange?: boolean
}

export type SuccessTemplateCreation = {
    channel: string
    status: string
    template?: TemplateResponse
    defaultVariables?: DefaultVariable[]
}

export type ErrorTemplateCreation = {
    channel: string
    status: string
    message: string
    gupshupTemplate: GupshupWhatsAppTemplate
}

export type TemplateCreationResponse = SuccessTemplateCreation | ErrorTemplateCreation

export type GupshupTemplateCreationResponse = {
    template: WhatsAppTemplate
    data: TemplateCreationResponse[]
}

export type UploadTemplateImage = {
    file: string
    file_type: string
}

export type UploadTemplateImageResponse = {
    handleId: {
        message: string
    }
    status: string
}

export type GupshupTemplateDeletionResponse = {
    channel: string
    templates: {
        id: string
        name: string
        status?: string
        message?: string
    }[]
}

export function getOriginalTemplateFromData(data: string) {
    return data.replace(/(https?:\/\/[\w.-]+)\/\{\{(\d+)\}\}/, (match, p1, p2) => p1 + '/' + parseInt(p2))
}

export const toStringButtons = (buttons: WhatsAppTemplateButton[] | ActiveMessageButton[]): string => {
    return `| ${buttons.map((button) => ('text' in button ? `[${button.text}]` : `[${button.parameter}]`)).join(' | ')}`
}
