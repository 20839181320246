import { action, AlertSeverity, IPresenterOwner, NOOP_PROMISE_VOID, NOOP_VOID, Presenter } from 'wdc-cube'
import { ChannelConfig, ChannelValidationStatus } from './ChannelConfiguration.service'
import {
    ValidationContentScope,
    ValidationContinueActionScope,
    ValidationMessageScope,
    ValidationPublishActionScope
} from './ChannelConfigurationAlert.scopes'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

type PublishActionTye = (severity: AlertSeverity, data: ChannelConfig) => Promise<void>

export class AbstractValidationContentPresenter extends Presenter<ValidationContentScope> {
    public readonly closeListener = this.onContinue.bind(this)

    protected __caption = ''

    protected __closeAction = NOOP_VOID
    protected __publishAction: PublishActionTye = NOOP_PROMISE_VOID

    protected readonly channelNameScope = new ValidationMessageScope()
    protected readonly companyAssigmentScope = new ValidationMessageScope()
    protected readonly companySegmentationScope = new ValidationMessageScope()
    protected readonly menuDefinitionsScope = new ValidationMessageScope()
    protected readonly messageValidationScope = new ValidationMessageScope()
    protected readonly workingHourValidationScope = new ValidationMessageScope()
    protected readonly workingHourExisting24Scope = new ValidationMessageScope()
    protected readonly extrasValidationScope = new ValidationMessageScope()

    protected readonly publishActionScope = new ValidationPublishActionScope()
    protected readonly continueActionScope = new ValidationContinueActionScope()

    private channelNameStatus = false
    private companyAssigmentStatus = false
    private companySegmentationStatus = -1
    private menuDefinitionsStatus = -1
    private messageValidationStatus = false
    private workingHourValidationStatus = -1
    private workingHourExisting24Ok = false
    private extrasValidationStatus = false

    public constructor(owner: IPresenterOwner) {
        super(owner, new ValidationContentScope())
    }

    public override release() {
        this.scope.update = NOOP_VOID
        super.release()
    }

    public get caption() {
        return this.__caption
    }

    public get severity(): AlertSeverity {
        if (this.scope.errors.length > 0) {
            return 'error'
        } else if (this.scope.warnings.length > 0) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    public initialize(cfg: { closeAction: () => void; publishAction: PublishActionTye }) {
        this.__closeAction = cfg.closeAction
        this.__publishAction = cfg.publishAction

        this.channelNameScope.id = 1
        this.channelNameScope.onClick = this.onChannelNameClicked.bind(this)
        this.channelNameScope.update = this.update

        this.companyAssigmentScope.id = 2
        this.companyAssigmentScope.onClick = this.onCompanyAssigmentClicked.bind(this)
        this.companyAssigmentScope.update = this.update

        this.companySegmentationScope.id = 3
        this.companySegmentationScope.onClick = this.onCompanySegmentationClicked.bind(this)
        this.companySegmentationScope.update = this.update

        this.menuDefinitionsScope.id = 4
        this.menuDefinitionsScope.onClick = this.onMenuDefinitionsClicked.bind(this)
        this.menuDefinitionsScope.update = this.update

        this.messageValidationScope.id = 5
        this.messageValidationScope.onClick = this.onMessageValidationClicked.bind(this)
        this.messageValidationScope.update = this.update

        this.workingHourValidationScope.id = 6
        this.workingHourValidationScope.onClick = this.onWorkingHourValidationClicked.bind(this)
        this.workingHourValidationScope.update = this.update

        this.workingHourExisting24Scope.id = 7
        this.workingHourExisting24Scope.onClick = this.onWorkingHourExisting24Clicked.bind(this)
        this.workingHourExisting24Scope.update = this.update

        this.extrasValidationScope.id = 8
        this.extrasValidationScope.onClick = this.onExtrasValidationClicked.bind(this)
        this.extrasValidationScope.update = this.update

        this.continueActionScope.update = this.update
        this.continueActionScope.onContinue = this.closeListener

        this.publishActionScope.update = this.update
        this.publishActionScope.onCancel = this.closeListener

        this.channelName(true)
        this.companyAssigment(true)
        this.companySegmentationOk()
        this.menuDefinitionsOk()
        this.messageValidation(true)
        this.workingHourOk()
        this.workingHourExisting24(true)
        this.extraValidation(true)
    }

    protected async onContinue() {
        this.__closeAction()
    }

    @action()
    private async onPublish(severity: AlertSeverity, formData: ChannelConfig) {
        this.publishActionScope.publishing = true
        try {
            this.publishActionScope.onPublish = NOOP_PROMISE_VOID
            await this.__publishAction(severity, formData)
        } finally {
            this.publishActionScope.publishing = false
        }
    }

    protected async onChannelNameClicked() {
        this.__closeAction()
    }

    protected async onCompanyAssigmentClicked() {
        this.__closeAction()
    }

    protected async onCompanySegmentationClicked() {
        this.__closeAction()
    }

    protected async onMenuDefinitionsClicked() {
        this.__closeAction()
    }

    protected async onMessageValidationClicked() {
        this.__closeAction()
    }

    protected async onWorkingHourValidationClicked() {
        this.__closeAction()
    }

    protected async onWorkingHourExisting24Clicked() {
        this.__closeAction()
    }

    protected async onExtrasValidationClicked() {
        this.__closeAction()
    }

    public prepareToPublish(status: ChannelValidationStatus, formData: ChannelConfig) {
        this.applyStatus(status)

        this.publishActionScope.onPublish = this.onPublish.bind(this, status.type, formData)

        this.__caption = texts.CHANNEL_CONFIGURATION_VALIDATION_CAPTION_VALIDATE_PUBLISH
        this.scope.information = texts.CHANNEL_CONFIGURATION_VALIDATION_INFO_VALIDATE_PUBLISH
        this.scope.actions = this.publishActionScope
        this.update()
    }

    public prepareToInformation() {
        this.beforeUpdate()

        if (this.severity === 'success') {
            this.__caption = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_CAPTION
            this.scope.information = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_INFO
        } else {
            if (this.severity === 'error') {
                this.__caption = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_CAPTION
                this.scope.information = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_INFO
            } else if (this.severity === 'warning') {
                this.__caption = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_CAPTION
                this.scope.information = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_INFO
            } else {
                this.__caption = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_CAPTION
                this.scope.information = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_INFO
            }
        }

        this.scope.actions = this.continueActionScope
        this.update()
    }

    public channelName(ok: boolean) {
        if (this.channelNameStatus !== ok) {
            this.channelNameStatus = ok
            if (ok) {
                this.channelNameScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_NAME_ESTABLISHED
            } else {
                this.channelNameScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITHOUT_NAME_ESTABLISHED
            }
        }
        return this.channelNameScope.message
    }

    public companyAssigment(ok: boolean) {
        if (this.companyAssigmentStatus !== ok) {
            this.companyAssigmentStatus = ok
            if (ok) {
                this.companyAssigmentScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_LINKED_TO_CHANNEL
            } else {
                this.companyAssigmentScope.message =
                    texts.CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_NOT_LINKED_TO_CHANNEL
            }
        }
        return this.companyAssigmentScope.message
    }

    public companySegmentationOk() {
        return this.companySegmentation(0)
    }

    public companySegmentationNonUsed() {
        return this.companySegmentation(-1)
    }

    public companySegmentationIncomplete() {
        return this.companySegmentation(1)
    }

    private companySegmentation(status: number) {
        if (this.companySegmentationStatus !== status) {
            this.companySegmentationStatus = status
            switch (status) {
                case 0:
                    this.companySegmentationScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_SEGMENTED_COMPANIES
                    break
                case 1:
                    this.companySegmentationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_WITHOUT_SEGMENTATION
                    break
                default:
                    this.companySegmentationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_SEGMENTATION_DEFAULT_VALUE
            }
        }
        return this.companySegmentationScope.message
    }

    public menuDefinitionsOk() {
        return this.menuDefinitions(0)
    }

    public menuDefinitionsNoMenuDefined() {
        return this.menuDefinitions(1)
    }

    public menuDefinitionsNotAllCompaniesHaveMenu() {
        return this.menuDefinitions(4)
    }

    public menuDefinitionsPartialDefined() {
        return this.menuDefinitions(2)
    }

    public menuDefinitionsWithInconsistencies() {
        return this.menuDefinitions(3)
    }

    private menuDefinitions(status: number) {
        if (this.menuDefinitionsStatus !== status) {
            this.menuDefinitionsStatus = status
            switch (status) {
                case 0:
                    this.menuDefinitionsScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_MENUS_MESSAGE
                    break
                case 1:
                    this.menuDefinitionsScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_MENUS_MESSAGE
                    break
                case 2:
                    this.menuDefinitionsScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_MENUS_MESSAGE
                    break
                case 3:
                    this.menuDefinitionsScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_MENUS_WITH_ICONSISTENCY_MESSAGE
                    break
                case 4:
                    this.menuDefinitionsScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_MENUS_COMPANIES_WITHOUT_MENU_MESSAGE
                    break
                default:
                    this.menuDefinitionsScope.message = ''
            }
        }
        return this.menuDefinitionsScope.message
    }

    public messageValidation(ok: boolean) {
        if (this.messageValidationStatus !== ok) {
            this.messageValidationStatus = ok
            if (ok) {
                this.messageValidationScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_VALID_MESSAGES
            } else {
                this.messageValidationScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_INVALID_MESSAGES
            }
        }
        return this.messageValidationScope.message
    }

    public workingHourOk() {
        return this.workingHourValidation(0)
    }

    public workingHourPartialFulfilled() {
        return this.workingHourValidation(1)
    }

    public workingHourInvalidHours() {
        return this.workingHourValidation(2)
    }

    public workingHourWithInconsistencies() {
        return this.workingHourValidation(3)
    }

    private workingHourValidation(status: number) {
        if (this.workingHourValidationStatus !== status) {
            this.workingHourValidationStatus = status
            switch (status) {
                case 0:
                    this.workingHourValidationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_WORKING_HOURS
                    break
                case 1:
                    this.workingHourValidationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_WORKING_HOURS
                    break
                case 2:
                    this.workingHourValidationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_INVALID_WORKING_HOURS
                    break
                case 3:
                    this.workingHourValidationScope.message =
                        texts.CHANNEL_CONFIGURATION_VALIDATION_WORKING_HOURS_WITH_INCONSISTENCIES
                    break
                default:
                    this.workingHourValidationScope.message = ''
            }
        }
        return this.workingHourValidationScope.message
    }

    public workingHourExisting24(ok: boolean) {
        if (this.workingHourExisting24Ok !== ok) {
            this.workingHourExisting24Ok = ok
            if (ok) {
                this.workingHourExisting24Scope.message = ''
            } else {
                this.workingHourExisting24Scope.message =
                    texts.CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_WORKING_HOURS
            }
        }
        return this.workingHourExisting24Scope.message
    }

    public extraValidation(ok: boolean) {
        if (this.extrasValidationStatus !== ok) {
            this.extrasValidationStatus = ok
            if (ok) {
                this.extrasValidationScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_VALID_EXTRAS_CONFIG
            } else {
                this.extrasValidationScope.message = texts.CHANNEL_CONFIGURATION_VALIDATION_NOT_VALID_EXTRAS_CONFIG
            }
        }
        return this.extrasValidationScope.message
    }

    public beforeUpdate() {
        let successIdx = 0
        let warningIdx = 0
        let errorIdx = 0

        if (this.channelNameStatus) {
            this.scope.successes.set(successIdx++, this.channelNameScope)
        } else {
            this.scope.errors.set(errorIdx++, this.channelNameScope)
        }

        if (this.companyAssigmentStatus) {
            this.scope.successes.set(successIdx++, this.companyAssigmentScope)
        } else {
            this.scope.errors.set(errorIdx++, this.companyAssigmentScope)
        }

        if (this.companySegmentationStatus === 0) {
            this.scope.successes.set(successIdx++, this.companySegmentationScope)
        } else if (this.companySegmentationStatus > 0) {
            this.scope.warnings.set(warningIdx++, this.companySegmentationScope)
        }

        if (this.menuDefinitionsStatus === 0) {
            this.scope.successes.set(successIdx++, this.menuDefinitionsScope)
        } else {
            this.scope.errors.set(errorIdx++, this.menuDefinitionsScope)
        }

        if (this.messageValidationStatus) {
            this.scope.successes.set(successIdx++, this.messageValidationScope)
        } else {
            this.scope.errors.set(errorIdx++, this.messageValidationScope)
        }

        if (this.workingHourValidationStatus === 0) {
            if (!this.workingHourExisting24Ok) {
                this.scope.warnings.set(warningIdx++, this.workingHourExisting24Scope)
            } else {
                this.scope.successes.set(successIdx++, this.workingHourValidationScope)
            }
        } else {
            this.scope.errors.set(errorIdx++, this.workingHourValidationScope)
        }

        if (this.extrasValidationStatus) {
            this.scope.successes.set(successIdx++, this.extrasValidationScope)
        } else {
            this.scope.errors.set(errorIdx++, this.extrasValidationScope)
        }

        this.scope.successes.length = successIdx
        this.scope.warnings.length = warningIdx
        this.scope.errors.length = errorIdx

        this.publishActionScope.publishedEnabled = errorIdx === 0
    }

    public applyStatus(data: ChannelValidationStatus) {
        if (data.channelName) {
            if (data.channelName.empty || data.channelName.invalid) {
                this.channelName(false)
            } else {
                this.channelName(true)
            }
        } else {
            this.channelName(true)
        }

        if (data.companyAssigment) {
            if (data.companyAssigment.noCompanyAssigned || data.companyAssigment.notAllCompanyWereAssigned) {
                this.companyAssigment(false)
            } else {
                this.companyAssigment(true)
            }
        } else {
            this.companyAssigment(true)
        }

        if (data.companySegmentation) {
            if (data.companySegmentation.missingAll || data.companySegmentation.missingSome) {
                this.companySegmentationIncomplete()
            } else {
                // TODO: detect if is using segmentation
                this.companySegmentationNonUsed()
            }
        } else {
            this.companySegmentationNonUsed()
        }

        if (data.menuDefinition) {
            if (
                data.menuDefinition.hasMissingEventId ||
                data.menuDefinition.hasMissingLink ||
                data.menuDefinition.hasMissingDescription ||
                data.menuDefinition.hasEmptyMenu
            ) {
                this.menuDefinitionsPartialDefined()
            } else if (data.menuDefinition.noMenuDefined) {
                this.menuDefinitionsNoMenuDefined()
            } else if (data.menuDefinition.hasCompanyNotUsed) {
                this.menuDefinitionsNotAllCompaniesHaveMenu()
            } else if (
                data.menuDefinition.hasDuplicatedCompanies ||
                data.menuDefinition.hasNonValidCompanies ||
                data.menuDefinition.hasDuplicatedEventId ||
                data.menuDefinition.hasInvalidMenuType
            ) {
                this.menuDefinitionsWithInconsistencies()
            } else {
                this.menuDefinitionsOk()
            }
        } else {
            this.menuDefinitionsOk()
        }

        if (data.messageValidation) {
            if (data.messageValidation.hasEmptyMessages || data.messageValidation.hasMissingTags) {
                this.messageValidation(false)
            } else {
                this.messageValidation(true)
            }
        } else {
            this.messageValidation(true)
        }

        if (data.workingHourValidation) {
            if (data.workingHourValidation.hasInvalidTime) {
                this.workingHourInvalidHours()
            } else if (data.workingHourValidation.hasEmptyTime) {
                this.workingHourPartialFulfilled()
            } else if (
                data.workingHourValidation.hasPeriodInconsistencies ||
                data.workingHourValidation.hasDataInconsistencies ||
                data.workingHourValidation.hasDataInconsistencies ||
                data.workingHourValidation.hasOverlapedTime ||
                data.workingHourValidation.hasDuplicatedCompanyUsage
            ) {
                this.workingHourWithInconsistencies()
            } else {
                this.workingHourOk()
            }

            if (
                data.workingHourValidation.hasCompaniesUsing24Hours ||
                data.workingHourValidation.hasNoWorkingHoursDefinition
            ) {
                this.workingHourExisting24(false)
            } else {
                this.workingHourExisting24(true)
            }
        } else {
            this.workingHourOk()
            this.workingHourExisting24(true)
        }

        if (data.extraValidation) {
            if (data.extraValidation.hasUseGoBack !== undefined) {
                this.extraValidation(true)
            } else {
                this.extraValidation(false)
            }
        } else {
            this.extraValidation(true)
        }
    }
}
