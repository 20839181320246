import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import InboxIcon from '@mui/icons-material/Inbox'
import { grey } from '@mui/material/colors'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import clsx from 'clsx'
import React from 'react'
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd'
import voca from 'voca'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { RichEditorScope } from '../ChannelConfigurationForm.scopes'
import {
    ChannelConfigurationMenuEditorFormEventScope,
    ChannelConfigurationMenuEditorFormInformationScope,
    ChannelConfigurationMenuEditorFormLinkScope,
    ChannelConfigurationMenuEditorFormSchedulingRobotScope,
    ChannelConfigurationMenuEditorFormScope,
    ChannelConfigurationMenuEditorFormSubMenuScope,
    MenuOptionScope
} from '../ChannelConfigurationMenuEditorForm.scopes'
import { TextsProvider } from '../texts'
import { ChannelMenuType } from '@whatsapp/communication/lib/bff/admin/ChannelConfiguration.communication'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { UploadFile } from 'src/components/upload'
import { RichDefaultEditorScope } from 'src/components/richeditor'

// @Inject
const texts = TextsProvider.get()
type MenuOptionViewProps = IViewProps & {
    scope: MenuOptionScope
    index: number
    provided: DraggableProvided
    onSelect: (id: string) => void
}

type NoDataComponentProps = {
    className?: string
}

type MenuEditViewProps = {
    scope: MenuOptionScope
}

export type ChannelConfigurationMenuEditorFormSubMenuViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormSubMenuScope
}

export type ChannelConfigurationMenuEditorFormSchedulingRobotViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormSchedulingRobotScope
}

export type ChannelConfigurationMenuEditorFormViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormScope
}

export type ChannelConfigurationMenuEditorFormLinkViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormLinkScope
}

export type ChannelConfigurationMenuEditorFormEventViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormEventScope
}

export type ChannelConfigurationMenuEditorFormInformationViewProps = IViewProps & {
    scope: ChannelConfigurationMenuEditorFormInformationScope
}

export function ChannelConfigurationMenuEditorFormInformationView({
    scope
}: ChannelConfigurationMenuEditorFormInformationViewProps) {
    bindUpdate(React, scope)
    const [, setForceUpdate] = React.useState(false)

    const eventOptionLabelId = `${scope.uid}-event-option-label`
    const eventOptionSelectId = `${scope.uid}-event-option-select`

    return (
        <>
            <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_TOOLTIP}>
                <FormControl
                    sx={{
                        gridArea: '1 / 2 / 2 / 3'
                    }}
                >
                    <UploadFile
                        sx={{
                            margin: 0,
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'right',
                            gap: 1
                        }}
                        accept="image/jpg,image/png,image/jpeg,video/mp4,application/pdf"
                        validationFn={scope.onLinkValidation}
                        textField={
                            <TextField
                                error={!!scope.linkError}
                                label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK}
                                placeholder={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK_PLACEHOLDER}
                                value={scope.link}
                                sx={{ flexGrow: 1 }}
                                onChange={scope.onLinkChanged}
                            />
                        }
                        onSelectFile={async (files) => {
                            await scope.onSelectFile(files[0])
                            setForceUpdate((m) => !m)
                        }}
                    />
                </FormControl>
            </Tooltip>
            <Box
                sx={{
                    display: 'grid',
                    gap: 3,
                    width: '625px',
                    gridTemplateAreas: `
                    "description description"
                    "message message"
                    "button-positivo button-negativo"
                    "select-event select-event"
                `
                }}
            >
                <MenuDescription
                    scope={scope.description}
                    gridArea="description"
                    tooltip={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_DESCRIPTION_TOOLTIP}
                />
                <MenuDescription
                    scope={scope.message}
                    gridArea="message"
                    tooltip={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_MESSAGE_TOOLTIP}
                />
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_POSITIVE_BUTTON_TOOLTIP}>
                    <FormControl
                        sx={{
                            gridArea: 'button-positivo'
                        }}
                    >
                        <TextField
                            inputProps={{ maxLength: 20 }}
                            error={!scope.positiveButton}
                            label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_POSITIVE_BUTTON}
                            placeholder={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_POSITIVE_BUTTON}
                            value={scope.positiveButton}
                            onChange={scope.onPositiveButton}
                        />
                    </FormControl>
                </Tooltip>
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_NEGATIVE_BUTTON_TOOLTIP}>
                    <FormControl
                        sx={{
                            gridArea: 'button-negativo'
                        }}
                    >
                        <TextField
                            inputProps={{ maxLength: 20 }}
                            error={!scope.negativeButton}
                            label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEGATIVE_BUTTON}
                            placeholder={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEGATIVE_BUTTON}
                            value={scope.negativeButton}
                            onChange={scope.onNegativeButton}
                        />
                    </FormControl>
                </Tooltip>
                <Box
                    sx={{
                        gridArea: 'select-event',
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <FormControl sx={{ flexGrow: 1 }}>
                        <InputLabel id={eventOptionLabelId}>
                            {texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT}
                        </InputLabel>
                        <Select
                            error={!!scope.eventError}
                            labelId={eventOptionLabelId}
                            id={eventOptionSelectId}
                            value={String(scope.eventValue ?? '')}
                            label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT}
                            onChange={scope.onEventChanged}
                        >
                            {scope.eventOptions.map((option) => (
                                <MenuItem key={option.id} value={String(option.id)}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip
                        sx={{ alignSelf: 'center' }}
                        title={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_SELECT_EVENT_TOOLTIP}
                    >
                        <HelpOutlineIcon color="disabled" />
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export function ChannelConfigurationMenuEditorFormEventView({
    scope
}: ChannelConfigurationMenuEditorFormEventViewProps) {
    bindUpdate(React, scope)

    const eventOptionLabelId = `${scope.uid}-event-option-label`
    const eventOptionSelectId = `${scope.uid}-event-option-select`

    return (
        <>
            <FormControl
                sx={{
                    gridArea: '1 / 2 / 2 / 3'
                }}
            >
                <InputLabel id={eventOptionLabelId}>{texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT}</InputLabel>
                <Select
                    error={scope.eventError ? true : false}
                    labelId={eventOptionLabelId}
                    id={eventOptionSelectId}
                    value={String(scope.eventValue ?? '')}
                    label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT}
                    onChange={scope.onEventChanged}
                >
                    {scope.eventOptions.map((option) => (
                        <MenuItem key={option.id} value={String(option.id)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <MenuDescription scope={scope.description} />
        </>
    )
}

export function ChannelConfigurationMenuEditorFormLinkView({ scope }: ChannelConfigurationMenuEditorFormLinkViewProps) {
    bindUpdate(React, scope)

    return (
        <>
            <FormControl
                sx={{
                    gridArea: '1 / 2 / 2 / 3'
                }}
            >
                <TextField
                    error={scope.linkError ? true : false}
                    label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK}
                    placeholder={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK_PLACEHOLDER}
                    value={scope.link}
                    onChange={scope.onLinkChanged}
                />
            </FormControl>
            <MenuDescription scope={scope.description} />
        </>
    )
}

export function ChannelConfigurationMenuEditorFormSchedulingRobotView({
    scope
}: ChannelConfigurationMenuEditorFormSchedulingRobotViewProps) {
    bindUpdate(React, scope)

    return <MenuDescription scope={scope.description} />
}

export function ChannelConfigurationMenuEditorFormSubMenuView({
    scope
}: ChannelConfigurationMenuEditorFormSubMenuViewProps) {
    bindUpdate(React, scope)

    return <MenuDescription scope={scope.description} />
}

export function NoDataComponent({ className }: NoDataComponentProps) {
    return (
        <div className={clsx(className)}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    height: 155,
                    marginTop: 1,
                    color: '#666',
                    backgroundColor: '#f3f3f369'
                }}
            >
                <InboxIcon style={{ fontSize: 52 }} />
                <p>{texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NO_DATA_DESCRIPTION}</p>
            </Box>
        </div>
    )
}

export function ChannelConfigurationMenuEditorFormView({
    scope: menuScope
}: ChannelConfigurationMenuEditorFormViewProps) {
    bindUpdate(React, menuScope)

    function Navigation() {
        const hover = { ':hover': { cursor: 'pointer' } }
        const lastSelectedMenu = menuScope.lastSelectedMenu
        const selectedMenu = menuScope.selectedMenu

        if (!selectedMenu) return null
        const { menuDescription } = extractOptionDetails(selectedMenu)

        return (
            <Breadcrumbs sx={{ margin: 1 }} aria-label="breadcrumb">
                <Typography
                    sx={hover}
                    onClick={() => {
                        selectedMenu.onUnselect()
                        lastSelectedMenu?.onUnselect()
                    }}
                >
                    {voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_TITLE, menuScope.menuId)}
                </Typography>
                {lastSelectedMenu ? (
                    <Typography sx={hover} onClick={selectedMenu.onUnselect}>
                        {extractOptionDetails(lastSelectedMenu).menuDescription}
                    </Typography>
                ) : null}
                <Typography color="text.primary">{menuDescription}</Typography>
            </Breadcrumbs>
        )
    }

    function MenuDivingView({ scope: divingViewScope }: MenuEditViewProps) {
        bindUpdate(React, divingViewScope)

        const type = divingViewScope.menuTypeDetail?.type

        const back = () => divingViewScope.onUnselect()

        return (
            <>
                <HeaderView />
                <Navigation />
                <EditView scope={divingViewScope} />

                {type === 'SUB_MENU' ? (
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                        <Button variant="contained" color="secondary" onClick={menuScope.onAppendOption}>
                            {texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEW_OPTION_BUTTON}
                        </Button>
                    </Box>
                ) : (
                    <></>
                )}

                <BoxWithScrollY maxHeight="210px">
                    <ListView menu={divingViewScope.id} />
                </BoxWithScrollY>

                <Footer>
                    <Button variant="contained" color="secondary" onClick={back}>
                        {texts.CHANNEL_CONFIGURATION_FORM_BACK_BUTTON_ACTION}
                    </Button>
                </Footer>
            </>
        )
    }

    function EditView({ scope: editView }: MenuEditViewProps) {
        const [, forceUpdate] = React.useState(0)
        const menuTypeLabelId = `${editView.uid}-menu-type-label`
        const menuTypeSelectId = `${editView.uid}-menu-type-select`

        const menuTypeDetail = editView.menuTypeDetail
        const isRobotDetail = menuTypeDetail?.type === 'ROBOT'
        const isSubMenuDetail = menuTypeDetail?.type === 'SUB_MENU'

        const gridArea = isRobotDetail || isSubMenuDetail ? '1 / 1 / 2 / 3' : '1 / 1 / 2 / 2'

        const menuTypeOptions = editView.isSubService
            ? editView.menuTypeOptions.filter(({ id }) => id !== ChannelMenuType.SUB_MENU.valueOf())
            : editView.menuTypeOptions

        const itIsDeactivated = !!editView.subServices.length

        return (
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '200px 400px',
                    gridTemplateRows: 'auto auto',
                    gap: 3
                }}
            >
                <FormControl
                    sx={{
                        gridArea
                    }}
                >
                    <InputLabel id={menuTypeLabelId}>{texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE}</InputLabel>
                    <Select
                        disabled={itIsDeactivated}
                        labelId={menuTypeLabelId}
                        id={menuTypeSelectId}
                        value={editView.menuTypeValue?.toString()}
                        label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE}
                        onChange={(e) => {
                            forceUpdate((l) => ++l)
                            editView.onMenuTypeChanged(e)
                        }}
                        IconComponent={() =>
                            itIsDeactivated ? (
                                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE_MENU_DISABLE}>
                                    <PriorityHighIcon color="warning" />
                                </Tooltip>
                            ) : null
                        }
                    >
                        {menuTypeOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <ViewSlot scope={menuTypeDetail} />
            </Box>
        )
    }

    function HeaderView() {
        return (
            <Typography variant="h6">
                {voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_MENU_CONFIGURATION, menuScope.menuId)}
            </Typography>
        )
    }

    function HomeView() {
        return (
            <>
                <HeaderView />
                <Box>
                    <ViewSlot scope={menuScope.companiesSelector} />
                </Box>
                <Box
                    sx={{
                        alignSelf: 'end'
                    }}
                >
                    <Button variant="contained" color="secondary" onClick={menuScope.onAppendOption}>
                        <AddIcon style={{ paddingRight: 8 }} />
                        <span>{texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEW_OPTION_BUTTON}</span>
                    </Button>
                </Box>

                {menuScope.entries.length === 0 ? <NoDataComponent /> : <></>}

                <BoxWithScrollY maxHeight="440px">
                    <ListView />
                </BoxWithScrollY>

                <Footer>
                    <Button color="inherit" onClick={menuScope.onCancel}>
                        {texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_DISCARD_BUTTON}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={menuScope.onSave}>
                        {texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CONFIRM_BUTTON}
                    </Button>
                </Footer>
            </>
        )
    }

    function ListView({ menu }: { menu?: string }) {
        const menus: MenuOptionScope[] = []

        if (menu !== undefined) {
            const parentMenu = menuScope.entries.find(({ id }) => menu === id)
            const parentSubservices = parentMenu?.subServices
            menus.push(...menuScope.entries.filter(({ id }) => parentSubservices?.includes(id)))
        } else {
            menus.push(...menuScope.entries.filter(({ isSubService }) => !isSubService))
        }

        const droppableId = `${menuScope.uid}-droppable`

        function handleOnDragEnd(result: DropResult) {
            if (result.destination && result.destination.droppableId === result.source.droppableId) {
                const destination = result.destination.index
                const source = result.source.index

                const destinationMenu = menus[destination]
                const sourceMenu = menus[source]

                menuScope.onSwapPosition(destinationMenu.position - 1, sourceMenu.position - 1)
            }
        }

        return (
            <>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={droppableId}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {menus?.map((menuOption, index) => {
                                    return (
                                        <Draggable key={menuOption.id} draggableId={menuOption.uid} index={index}>
                                            {(provided) => {
                                                return (
                                                    <OptionView
                                                        key={menuOption.id}
                                                        scope={menuOption}
                                                        provided={provided}
                                                        index={index}
                                                        onSelect={menuScope.onMenuSelect}
                                                    />
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        )
    }

    function OptionView({ scope: optionScope, index, provided, onSelect }: MenuOptionViewProps) {
        const { menuDescription, menuType } = extractOptionDetails(optionScope)

        const { draggableProps, innerRef, dragHandleProps } = provided

        return (
            <Card
                sx={{
                    marginBottom: 1,
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr auto',
                    alignItems: 'center'
                }}
                {...draggableProps}
                ref={innerRef}
                {...dragHandleProps}
            >
                <Box
                    draggable
                    sx={{
                        color: grey[700]
                    }}
                >
                    <DragIndicatorIcon />
                </Box>
                <Typography
                    variant="body1"
                    padding={1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onSelect(optionScope.uid)}
                >
                    {index + 1}.&nbsp;{menuDescription} ({menuType})
                </Typography>
                <IconButton sx={{ justifySelf: 'end' }} onClick={optionScope.onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Card>
        )
    }

    return (
        <Card sx={{ padding: 3, width: 624, height: 800, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!menuScope.selectedMenu ? <HomeView /> : <MenuDivingView scope={menuScope.selectedMenu} />}
        </Card>
    )
}

function MenuDescription({
    scope,
    gridArea,
    tooltip
}: {
    scope: RichEditorScope | RichDefaultEditorScope
    tooltip?: string
    gridArea?: string
}) {
    const component = (
        <FormControl
            sx={{
                gridArea: gridArea ?? '2 / 1 / 3 / 3'
            }}
        >
            <ViewSlot scope={scope} />
        </FormControl>
    )

    return tooltip ? <Tooltip title={tooltip}>{component}</Tooltip> : <>{component}</>
}

function Footer({ children }: React.PropsWithChildren<unknown>) {
    return (
        <Box
            sx={{
                marginTop: 'auto',
                alignSelf: 'flex-end',
                display: 'flex',
                gap: 1
            }}
        >
            {children}
        </Box>
    )
}

function extractOptionDetails(optionScope: MenuOptionScope) {
    const menuTypeDetail = optionScope.menuTypeDetail

    const menuType = optionScope.menuTypeOptions.find(({ id }) => optionScope.menuTypeValue === id)?.name
    const menuDescription = menuTypeDetail?.description?.text.getCurrentContent().getPlainText()

    const groupTypeOfEvent =
        menuTypeDetail && 'uid' in menuTypeDetail && menuTypeDetail.eventValue
            ? ` - ${menuTypeDetail.eventOptions.find(({ id }) => id === menuTypeDetail.eventValue)?.name}`
            : ''
    return { menuDescription, menuType, groupTypeOfEvent }
}

function BoxWithScrollY({ children, maxHeight }: React.PropsWithChildren<{ maxHeight: `${number}px` }>) {
    return (
        <Box
            sx={{
                maxHeight,
                overflowY: 'scroll'
            }}
        >
            {children}
        </Box>
    )
}
