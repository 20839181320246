import { Logger } from '@syonet/lang'
import { BffRestClient } from '@whatsapp/communication'
import { ServiceLike } from 'wdc-cube'

const LOG = new Logger('EurekaService')

// @Inject
const bffClient = BffRestClient.singleton()

let usingLocalTunnel = false

let usingLocalhost = false

function static_computeEurekaUrl() {
    const locationUrl = new URL(window.location.href)

    if (locationUrl.hostname.indexOf('fenabrave.') !== -1) {
        return 'https://eureka.fenabrave.syonet.com'
    }

    if (locationUrl.hostname.indexOf('staging.') !== -1) {
        return 'https://eureka.staging.prod.whatsapp.syonet.com'
    }

    if (locationUrl.hostname.indexOf('gupshup.') !== -1) {
        return 'https://eureka.gupshup.prod.whatsapp.syonet.com'
    }

    if (locationUrl.hostname.indexOf('prod.') !== -1) {
        return 'https://eureka.prod.whatsapp.syonet.com'
    }

    if (locationUrl.hostname.indexOf('internal.') !== -1) {
        return 'https://eureka.internal.syonet.com'
    }

    if (locationUrl.hostname.indexOf('.loca.lt') !== -1) {
        usingLocalTunnel = true
        return `https://syo-whats-app-bus.loca.lt`
    }

    usingLocalhost = true

    const port = window.location.port === '3000' ? 3072 : 3093

    return `http://localhost:${port}`
}

export class EurekaService implements ServiceLike {
    private static readonly INSTANCE = new EurekaService()

    public static singleton() {
        return EurekaService.INSTANCE
    }

    // :: Instance

    private __initialized = false

    public get name() {
        return 'syo-eureka-service'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        bffClient.eurekaEndpoint = static_computeEurekaUrl()
        bffClient.useInternalHosts = false
        await bffClient.refreshEndpoints()
        this.__initialized = true
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public get usingLocalTunnel() {
        return usingLocalTunnel
    }

    public get usingLocalhost() {
        return usingLocalhost
    }

    public endpoint() {
        return bffClient.eurekaEndpoint
    }

    public async refresh() {
        await bffClient.refreshEndpoints()
    }

    public getInfobipMiddlewareEndpoint() {
        return bffClient.infobipMiddlewareEndPoint
    }

    public getSyoWhatsAppBusEndPoint() {
        return bffClient.whatsappBusEndPoint
    }

    public getSyoReportsEndpoint() {
        return bffClient.syoReportsEndPoint
    }

    public getSyoMessengerEndpoint() {
        return bffClient.serverMessengerEndPoint
    }

    public getSyoMiddlewareEndpoint() {
        return bffClient.syonetMiddlewareEndPoint
    }

    public getSyoRoomManagerEndpoint() {
        return bffClient.roomManagerEndPoint
    }

    public getSyoAliceBotEndpoint() {
        return bffClient.syoBotAliceEndPoint
    }
}
