import { Button } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AlertWarningConfirmContentScope } from '../ChannelConfigurationAlert.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeWarningAlertStyles } from './Alert.styles'

// @Inject
const texts = TextsProvider.get()

export type AlertWarningConfirmContentViewProps = IViewProps & {
    scope: AlertWarningConfirmContentScope
}

export function AlertWarningConfirmContentView({ className, scope }: AlertWarningConfirmContentViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeWarningAlertStyles()

    return (
        <div className={clsx(className)}>
            <div className={styles.adjustText}>
                <span>{scope.message}</span>
            </div>
            <div className={styles.adjustButton}>
                <Button color="inherit" onClick={scope.onCancel}>
                    {scope.cancelLabelOption || texts.CHANNEL_CONFIGURATION_ALERT_VALIDATION_CANCEL_BUTTON_TEXT}
                </Button>
                <Button
                    variant={scope.unfocusConfirmButton ? 'text' : 'contained'}
                    color="inherit"
                    className={scope.unfocusConfirmButton ? 'transparent' : styles.customButton}
                    onClick={scope.onConfirm}
                >
                    {scope.confirmLabelOption || texts.CHANNEL_CONFIGURATION_ALERT_VALIDATION_CONFIRM_BUTTON_TEXT}
                </Button>
            </div>
        </div>
    )
}
