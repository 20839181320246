import { DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { SimpleAlertScope } from '../Main.scopes'
import { getOrMakeSimpleAlertStyles } from './SimpleAlert.styles'

export type SimpleAlertViewProps = IViewProps & {
    scope: SimpleAlertScope
}

export function SimpleAlertView({ scope }: SimpleAlertViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeSimpleAlertStyles()

    return <div className={styles.simpleAlertPane}>
        <DialogTitle fontWeight={600}>
            {scope.title}
        </DialogTitle>
        <DialogContent>
            <ViewSlot scope={scope.content} />
        </DialogContent>
    </div>
}
