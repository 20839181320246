import { makeStyles } from 'tss-react/mui'

const Arial = 'Arial'

export const getOrMakeSuccessAlertStyles = makeStyles()(() => ({
    adjustText: {
        textAlign: 'center'
    },
    adjustButton: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

export const getOrMakeWarningAlertStyles = makeStyles()(() => ({
    adjustText: {
        lineHeight: 1.7
    },
    adjustButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 30
    },
    customButton: {
        backgroundColor: '#00000080!important',
        color: '#fff!important'
    }
}))

export const getOrMakeErrorAlertStyles = makeStyles()(() => ({
    adjustText: {
        textAlign: 'center'
    },
    adjustButton: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 10
    }
}))

export const getOrMakeValidationAlertStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        width: 420
    },
    adjustText: {
        fontFamily: Arial,
        fontSize: 14,
        letterSpacing: 0.15,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 17
    },
    adjustValidationStatus: {
        display: 'flex',
        alignItems: 'center'
    },
    adjustStatusRelativeToIcon: {
        marginLeft: 10
    },
    alertBody: {
        paddingInlineStart: 20
    },
    alertContent: {
        textAlign: 'left'
    },
    continueActionBar: {
        display: 'flex',
        justifyContent: 'center'
    },
    customButtonContent: {
        textTransform: 'lowercase',
        textDecoration: 'underline',

        '&::first-letter': {
            textTransform: 'uppercase'
        }
    },
    publishActionBar: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10
    },
    secondaryTitleText: {
        fontFamily: Arial,
        fontSize: 14,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.54)',
        marginLeft: 3
    }
}))
