import { Autocomplete, TextField } from '@mui/material'
import { lodash } from '@syonet/lang'
import React, { KeyboardEventHandler } from 'react'
import { bindUpdate } from 'wdc-cube-react'
import { BaseEvent, OptionLabelType, SimpleStringValueAutoCompleteScope, StringOptionType } from '../AutoCompleteField.scopes'

export type SimpleAutoCompleteStringFieldProps = {
    scope: SimpleStringValueAutoCompleteScope
    label?: string
    placeholder?: string
    optionLabel?: OptionLabelType
    disabled?: boolean
    onInputChange?: (event: BaseEvent, value: string) => void
    onKeyUp?: KeyboardEventHandler<HTMLDivElement> | undefined
}

export function SimpleAutoCompleteStringFieldView({
    scope,
    label,
    placeholder,
    optionLabel,
    disabled,
    onInputChange,
    onKeyUp
}: SimpleAutoCompleteStringFieldProps) {
    bindUpdate(React, scope)

    return (
        <Autocomplete
            size="small"
            freeSolo
            options={scope.options.map((option) => option)}
            getOptionLabel={optionLabel || defaultOptionLabel}
            value={scope.value ?? null}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={scope.onChange}
            onInputChange={onInputChange ? onInputChange : scope.onInputChange}
            onFocus={scope.onFocus}
            onKeyUp={onKeyUp}
            disabled={disabled}
            renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
        />
    )
}

function defaultOptionLabel(option: string | StringOptionType) {
    return lodash.isString(option) ? option : option.name
}
