import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, CubeRefObject, IViewProps } from 'wdc-cube-react'
import {
    ChannelConfigurationFormWorkingHourItemScope,
    ChannelConfigurationFormWorkingHoursScope
} from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormTimesStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationFormWorkingHoursProps = IViewProps & {
    scope: ChannelConfigurationFormWorkingHoursScope
}

export function ChannelConfigurationFormWorkingHoursView({
    className,
    scope
}: ChannelConfigurationFormWorkingHoursProps) {
    bindUpdate(React, scope)

    const { styles, addHoursButtonRef, blinkAddHoursButton } =
        ChannelConfigurationFormWorkingHoursViewContext.getOrCreate(scope)

    if (scope.blink === 'add-working-hours-button') {
        blinkAddHoursButton()
    }

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustHeader}>
                <div>
                    <Button
                        ref={addHoursButtonRef}
                        variant="contained"
                        color="secondary"
                        className={styles.newHourButton}
                        onClick={scope.onOpenEditor}
                    >
                        <AddIcon style={{ paddingRight: 8 }} />
                        <span>{texts.CHANNEL_CONFIGURATION_WORKING_HOURS_NEW_HOUR_BUTTON}</span>
                    </Button>
                    {scope.blink === 'add-working-hours-button' ? (
                        <span className={styles.buttonBlink}>
                            <IconButton>
                                <ArrowBackIcon />
                            </IconButton>
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className={styles.listing}>
                <TableContainer className={styles.container}>
                    <Table stickyHeader aria-label="sticky table" className={styles.table} arial-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.customTableCell}>
                                    {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_COMPANY}
                                </TableCell>
                                <TableCell className={styles.customTableCell}>
                                    {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_DAYS}
                                </TableCell>
                                <TableCell className={styles.customTableCell}>
                                    {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_TIME}
                                </TableCell>
                                <TableCell className={styles.customTableCell}>{''}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scope.entries.map((item) => (
                                <TimesRow key={item.id} scope={item} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

class ChannelConfigurationFormWorkingHoursViewContext {
    static readonly create = () => new ChannelConfigurationFormWorkingHoursViewContext()

    static getOrCreate(scope: ChannelConfigurationFormWorkingHoursScope) {
        const [ctx] = React.useState(ChannelConfigurationFormWorkingHoursViewContext.create)
        ctx.scope = scope
        ctx.styles = getOrMakeStyle().classes
        return ctx
    }

    styles!: ReturnType<typeof getOrMakeStyle>['classes']
    readonly addHoursButtonRef = new CubeRefObject<HTMLButtonElement>()

    scope!: ChannelConfigurationFormWorkingHoursScope

    readonly blinkAddHoursButton = () => {
        const { scope } = this
        const buttomElm = this.addHoursButtonRef.current
        if (buttomElm) {
            buttomElm.scrollIntoView({ behavior: 'smooth' })

            setTimeout(() => {
                scope.blink = ''
            }, 3000)
        }
    }
}

// :: Component(TimesRow)

const InlineStyle = {
    warnings: { color: '#856404' },
    errors: { color: '#F44336' }
}

type TimesRowProps = {
    className?: string
    scope: ChannelConfigurationFormWorkingHourItemScope
}

function TimesRow({ className, scope }: TimesRowProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyle()

    return (
        <TableRow className={clsx(computeErrorOrWarningClassName(), className)}>
            <TableCell>
                <span className={styles.customTableCellFont}>{scope.companyNames.join(', ')}</span>
                {hasErrorOrWarning() ? (
                    <div className={styles.adjustSpaceBetweenMenuAndErrorMessages}>
                        {scope.warnings.map((msg, i) => (
                            <div key={i} style={InlineStyle.warnings}>
                                {msg}
                            </div>
                        ))}
                        {scope.errors.map((msg, i) => (
                            <div key={i} style={InlineStyle.errors}>
                                {msg}
                            </div>
                        ))}
                    </div>
                ) : undefined}
            </TableCell>
            <TableCell>
                <span className={styles.customTableCellFont}>{scope.frequencyDescription.join(', ')}</span>
            </TableCell>
            <TableCell>
                <span className={clsx(styles.vbox, styles.customTableCellFont)}>
                    {scope.workingHoursDescription.map((description, i) => (
                        <span key={i}>{description}</span>
                    ))}
                </span>
            </TableCell>
            <TableCell>
                <Tooltip title={texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDIT_HOUR_TOOLTIP} arrow>
                    <IconButton onClick={scope.onEdit}>
                        <CreateIcon />
                    </IconButton>
                </Tooltip>
                {scope.id !== -1 ? (
                    <Tooltip title={texts.CHANNEL_CONFIGURATION_WORKING_HOURS_DELETE_HOUR_TOOLTIP} arrow>
                        <IconButton onClick={scope.onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : undefined}
            </TableCell>
        </TableRow>
    )

    // Functional Methods

    function computeErrorOrWarningClassName() {
        if (scope.warnings.length) {
            return styles.tableCellWithWarning
        }

        if (scope.errors.length) {
            return styles.tableCellWithError
        }
    }

    function hasErrorOrWarning() {
        return scope.errors.length > 0 || scope.warnings.length > 0
    }
}
