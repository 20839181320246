import { AxiosResponse } from 'axios'

export class HttpError extends Error {
    public readonly status: number
    public readonly response?: AxiosResponse

    constructor(status: number, statusText: string, response?: AxiosResponse) {
        super(statusText)
        this.status = status
        this.response = response
    }
}
