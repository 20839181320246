import { KeyPressEvent } from 'src/utils'
import { Observable, observe, Scope } from 'wdc-cube'
import { TextChangeEvent } from './ChannelConfigurationForm.scopes'

export type { TextChangeEvent, KeyPressEvent }

@Observable
export class ChannelConfigurationListingFilterScope extends Scope {
    @observe() channelName = ''
    @observe() channelNumber = ''
    @observe() opened = false
    @observe() valid = false
    @observe() filtering = false

    @observe() onOpen = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
    @observe() onClear = Scope.ASYNC_ACTION
    @observe() onApply = Scope.ASYNC_ACTION

    @observe() onUpdateChannelName = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onUpdateChannelNumber = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onApplyFilterByPressedKey = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
}
