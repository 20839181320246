import { ChipPanelScope } from 'src/components/chippanel'
import { Observable, Scope } from 'wdc-cube'
import { DashboardActiveMessagesResumedScope } from './DashboardActiveMessagesResumed.scopes'
import { DashboardCustomerServiceScope } from './DashboardCustomerService.scopes'

export { WaitingScope } from 'src/components/waiting'

@Observable
export class DashboardScope extends Scope {
    readonly filterChips = new ChipPanelScope()
    readonly customerServices = new DashboardCustomerServiceScope()
    readonly activeMessages = new DashboardActiveMessagesResumedScope()
}
