export const Texts = {
    CHANNELS: 'Canais',
    CHANNEL_NAME_LISTING: 'Nome',
    CHANNEL_DRAFT_LISTING: '(Rascunho)',
    CHANNEL_NUMBER_LISTING: 'Número',
    CHANNEL_STATUS_LISTING: 'Status',
    CHANNEL_PUBLISHED_LISTING: 'Publicado',

    CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_AFIRMATION: 'Sim',
    CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_DENY: 'Não',
    CHANNEL_CONFIGURATION_LISTING_ON_EDIT_CHANNEL_ERROR: 'Missing channelId which is a required argument',
    CHANNEL_CONFIGURATION_LISTING_ON_CLICK_ALERT_CHANNEL_ERROR: 'Missing channelId which is a required argument',

    CHANNEL_FILTER_CHANNEL_NAME: 'Nome',
    CHANNEL_FILTER_CHANNEL_NAME_PLACEHOLDER: 'Digite o nome do canal',
    CHANNEL_FILTER_CHANNEL_NUMBER: 'Número',
    CHANNEL_FILTER_CHANNEL_NUMBER_PLACEHOLDER: 'Digite o número do canal',
    CHANNEL_FILTER_CLEAR_BUTTON: 'Limpar',
    CHANNEL_FILTER_FILTER_BUTTON: 'Filtrar',

    CHANNEL_CONFIGURATION_LISTING_FILTER_STATUS: 'Status',
    CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NAME: 'Canal',
    CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NUMBER: 'Número do canal',

    PARTIALLY_CONFIGURED_CHANNEL: 'Configuração válida, com ressalvas.',
    CONFIGURED_CHANNEL: 'Configuração válida.',
    NOT_CONFIGURED_CHANNEL: 'Configuração pendente.',

    CANCEL_EDITION_OPTION_MESSAGE: 'Você tem certeza que deseja cancelar a edição?',
    CANCEL_EDITION_OPTION_NO: 'Não',
    CANCEL_EDITION_OPTION_YES: 'Sim',
    CANCEL_EDITION_OPTION_ALERT_TITLE: 'Cancelar',

    DELETE_MENU_OPTION_NO: 'Não',
    DELETE_MENU_OPTION_YES: 'Sim',

    DISCARD_EDITION_OPTION_MESSAGE:
        'As alterações deste canal seguem como um rascunho e só\nserão aplicadas se as mudanças forem publicadas.\n\n' +
        'É possível salvar ou descartar as alterações, sem que elas\nsejam publicadas.',
    DISCARD_EDITION_OPTION_CLOSE: 'Fechar',
    DISCARD_EDITION_OPTION_DISCARD: 'Descartar',
    DISCARD_EDITION_OPTION_ALERT_TITLE: 'Rascunho',
    DISCARD_EDITION_OPTION_ERROR: 'Ao tentar descartar o rascunho',

    DO_DISCARD_EDITION_TITLE: 'Descartar rascunho?',
    DO_DISCARD_EDITION_DESCRIPTION: 'Você tem certeza que deseja descartar este rascunho?',
    DO_DISCARD_EDITION_NO_OPTION: 'Não',
    DO_DISCARD_EDITION_YES_OPTION: 'Sim',

    CHANGES_MADE_WITH_SUCCESS: 'Alterações salvas com sucesso',
    ERROR_DURING_SAVING_ALERT_TITLE: 'Erro ao salvar',
    ERROR_DURING_SAVING_ALERT_CONTENT: 'Ocorreu um erro não previsto. Tente mais tarde',
    SAVE_IS_NOT_POSSIBLE: 'Não foi possível salvar',
    NOT_POSSIBLE_APPLY_CHANNEL_CONFIGURATION_WITH_ERRORS:
        'Não é permitido publicar uma configuração de canal que apresente erros',
    ERROR_DURING_PUBLISH_ALERT_TITLE: 'Erro na aplicação',

    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_DESCRIPTION: 'Empresas',
    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_UNEXPECTED_ERROR: 'Unexpected error',
    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_MISSING_COMPANIES_ERROR: 'Missing companyItemScope for code',

    CHANNEL_CONFIGURATION: 'Configuração de canal',
    CHANNEL_CONFIGURATION_DRAFT: 'Rascunho',
    CHANNEL_NOT_CONFIGURED_TOOLTIP: 'Canal não configurado',
    CHANNEL_WITH_OBSERVATIONS_TOOLTIP: 'Canal com observações',

    CHANNEL_CONFIGURATION_FORM_GENERAL_TAB: 'Geral',
    CHANNEL_CONFIGURATION_FORM_MENU_TAB: 'Menu',
    CHANNEL_CONFIGURATION_FORM_MESSAGES_TAB: 'Mensagens',
    CHANNEL_CONFIGURATION_FORM_WORKING_HOURS_TAB: 'Horários',
    CHANNEL_CONFIGURATION_FORM_EXTRAS_TAB: 'Extras',
    CHANNEL_CONFIGURATION_FORM_SCHEDULE_ROBOT_TAB: 'Robô de agendamento',

    CHANNEL_CONFIGURATION_FORM_SAVE_ACTION: 'Salvar',
    CHANNEL_CONFIGURATION_FORM_BACK_BUTTON_ACTION: 'Voltar',
    CHANNEL_CONFIGURATION_FORM_PUBLISH_BUTTON_ACTION: 'Publicar',

    CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_ERROR_STATUS: 'Status',
    CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_UNKNOWN_ERROR_MESSAGE: 'Unknown error message.',

    CHANNEL_CONFIGURATION_GENERAL_NAME: 'Nome do canal',
    CHANNEL_CONFIGURATION_GENERAL_NAME_PLACEHOLDER: 'Digite aqui o nome do canal',
    CHANNEL_CONFIGURATION_GENERAL_NUMBER: 'Número do canal',
    CHANNEL_CONFIGURATION_GENERAL_PACKAGE: 'Pacotes',
    CHANNEL_CONFIGURATION_GENERAL_COMPANIES: 'Empresas',
    CHANNEL_CONFIGURATION_GENERAL_PACKAGE_TOOLTIP:
        'Para alterar o pacote entre em contato com o atendimento da Syonet.',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_TITLE: 'Segmentação de empresas',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_ORDER: 'Ordem de segmentação',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_CONTENT:
        'Ao marcar esta opção você habilita a segmentação de empresas no início do atendimento.\nAntes de exibir o menu principal, será exibida a segmentação configurada, geralmente apresentando as bandeiras das lojas, mas também é possível segmentar por qualquer outra informação, como por exemplo, cidade ou estado.',
    CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_TITLE: 'Não permitido',
    CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_CONTENT: 'Todas as empresas já foram incluídas',
    CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_CONFIRM_MESSAGE: 'Você tem certeza que deseja excluir a empresa',
    CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_AFIRMATION_MESSAGE: 'Excluir empresa',
    CHANNEL_CONFIGURATION_GENERAL_FIELD_CANNOT_BE_BLANK_ERROR: 'Campo não pode ficar em branco',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE:
        'Você tem certeza que deseja substituir a empresa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE_COMPLEMENT: 'pela empresa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_DELETE_COMPANY_NAME: 'Excluir empresa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_ADD_COMPANY: 'Adicionar empresa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_LABEL: 'Usar segmentação de empresas',
    CHANNEL_CONFIGURATION_GENERAL_ON_SHOW_INFO_TOOLTIP:
        'Ao marcar esta opção você habilita a segmentação de empresas no inicio do atendimento.',
    CHANNEL_CONFIGURATION_GENERAL_TOOGLE_SHOW_ATTENDANT_NAME_TOOLTIP:
        'Mostrar o nome do atendente em cada mensagem de texto enviada no chat.',
    CHANNEL_CONFIGURATION_GENERAL_CARD_POSITION_DESCRIPTION: 'Posição',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_INPUT_LABEL: 'Empresa',
    CHANNEL_CONFIGURATION_GENERAL_SEGMENTATION_INPUT_LABEL: 'Segmentação',
    CHANNEL_CONFIGURATION_GENERAL_INCLUDE_NEW_SEGMENTATION_HINT: 'ENTER para nova segmentação',
    CHANNEL_CONFIGURATION_GENERAL_NO_COMPANY_ADDED: 'Nenhuma empresa adicionada.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE: 'Auto-Resgate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_TITLE: 'Configuração do Auto-Resgate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME:
        'Perante uma resposta positiva do cliente, o robô voltará a conversar do ponto em que parou.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_COMPLET:
        'Este tipo de resgate fará com que o robô chame o cliente de forma automática X minutos após a conversa parar.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE: 'Resgate automático',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE_EVENT: 'Gerar evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_NEGATIVE_ANSWER_RESUME:
        'Perante uma resposta negativa do cliente, o robô apenas se despedirá do mesmo.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CHECK_MESSAGE: 'Usar Auto-Resgate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_START: 'Resgatar cliente após',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END: 'minutos',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_RESCUE_CAPTION: 'Mensagem de resgate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_GOODBYE_CAPTION: 'Mensagem de despedida',

    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_EXPLICATION_TEXT_TYPE_GENERATE_EVENT:
        'Este tipo de resgate irá gerar o evento definido abaixo X minutos após a conversa parar.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END_TYPE_GENERATE_EVENT: 'Gerar evento após',

    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION:
        'Usa notificação no fluxo de Pré-venda',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION_TOOLTIP:
        'Atendente do evento também será notificado a cada mensagem recebida.',

    CHANNEL_CONFIGURATION_MENU_NEW_MENU_BUTTON: 'CONFIGURAR NOVO MENU',
    CHANNEL_CONFIGURATION_MENU_VINCULATE_SEGMENTATION: 'Vincular segmentação aos menus',
    CHANNEL_CONFIGURATION_MENU_NEW_MENU: 'Novo',
    CHANNEL_CONFIGURATION_MENU_COMPANIES_TITLE: 'Empresas',
    CHANNEL_CONFIGURATION_MENU_OPTIONS_TITLE: 'Opções',
    CHANNEL_CONFIGURATION_MENU_COMPANIES_DESCRIPTION: 'Empresas: %s',
    CHANNEL_CONFIGURATION_MENU_EVENT_CHIP: 'Evento',
    CHANNEL_CONFIGURATION_MENU_LINK_CHIP: 'Link',
    CHANNEL_CONFIGURATION_MENU_SCHEDULE_ROBOT_CHIP: 'Robô de agendamento',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CHIP: 'Segmentado',
    CHANNEL_CONFIGURATION_MENU_EDIT_MENU_TOOLTIP: 'Editar menu',
    CHANNEL_CONFIGURATION_MENU_DUPLICATE_MENU_TOOLTIP: 'Duplicar menu',
    CHANNEL_CONFIGURATION_MENU_DELETE_MENU_TOOLTIP: 'Excluir menu',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CHANNEL_ID_ERROR: 'ChannelId is a required argument',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_MENU_CONFIGURATION: 'Configuração do menu %d',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEW_OPTION_BUTTON: 'Nova opção',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NO_DATA_DESCRIPTION: 'Nenhuma opção definida.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_DELETE_OPTION_BUTTON: 'Excluir Opção',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_OPTION: 'Opção',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE: 'Tipo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK: 'Link',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK: 'Link do arquivo ou Upload',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_ERROR: 'Tipo de arquivo não suportado: ',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_SUPORTED_TYPES: 'Tipos suportados: ',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_TOOLTIP: `Imagens em formato jpeg, jpg e png são permitidas.
    Arquivos PDF e videos MP4 também podem ser configurados para upload automático.
    O tamanho máximo permitido para um arquivo é 5mb para imagem, 16mb para vídeo e 100mb para PDF`,
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_POSITIVE_BUTTON: 'Botão positivo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEGATIVE_BUTTON: 'Botão negativo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_DESCRIPTION_TOOLTIP: 'Texto utilizado na seleção de menus.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_MESSAGE_TOOLTIP:
        'Mensagem enviada ao cliente quando item é selecionado.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_POSITIVE_BUTTON_TOOLTIP:
        'O botão positivo representa a resposta do cliente que deseja avançar na negociação e se comunicar com um humano.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_NEGATIVE_BUTTON_TOOLTIP:
        'O botão negativo representa a resposta do cliente que deseja repetir (voltar) a última etapa da conversa.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_SELECT_EVENT_TOOLTIP:
        'Caso o cliente responda de maneira positiva, um evento será gerado no CRM. Defina o evento adequado para cada opção do menu.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE_MENU_DISABLE:
        'Este menu possui submenus, não é possível alterar o tipo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK_PLACEHOLDER: 'Digite ou cole o link desejado',
    CHANNEL_CONFIGURATION_MENU_EDITOR_COMPANY_COUNT_ERROR: 'Falta marcar pelo menos uma empresa',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_EVENT_TYPE: 'Evento',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_LINK_TYPE: 'Link',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SCHEDULE_ROBOT_TYPE: 'Robô de agendamento',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SUB_MENU_TYPE: 'Menu',
    CHANNEL_CONFIGURATION_MENU_EDITOR_INFORMATION_MENU_TYPE: 'Informações',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_EVENT_CAPTION: 'Descrição',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_CAPTION_STATUS: 'Falta informar o conteúdo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_LINK_CAPTION: 'Descrição',
    CHANNEL_CONFIGURATION_MENU_EDITOR_NEEDED_FIELD: 'Campo obrigatório',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_ROBOT_CAPTION: 'Descrição',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MESSAGE_INFORMATION_CAPTION: 'Mensagem',
    CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD: 'Falta informar o conteúdo',
    CHANNEL_CONFIGURATION_MENU_ITEM_TITLE: 'MENU %d',
    CHANNEL_CONFIGURATION_MENU_TITLE: 'Menu %d',
    CHANNEL_CONFIGURATION_MENU_NO_COMPANY_ESTABLISHED: 'Nenhuma empresa definida.',
    CHANNEL_CONFIGURATION_MENU_NO_MENU_ITEM_ESTABLISHED: 'Nenhum item de menu definido.',
    CHANNEL_CONFIGURATION_MENU_MISSING_MESSAGE: 'Falta definição da mensagem em',
    CHANNEL_CONFIGURATION_MENU_MISSING_POSITIVE_BUTTON: 'Falta definição de botão positivo em',
    CHANNEL_CONFIGURATION_MENU_MISSING_NEGATIVE_BUTTON: 'Falta definição de botão negativo em',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION: 'Falta definição do evento em',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_SINGULAR: 'item',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_PLURAL: 'itens',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION: 'Falta texto da descrição em',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_SINGULAR: 'item',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_PLURAL: 'itens',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL: 'Falta url do link em',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR: 'item',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL: 'itens',
    CHANNEL_CONFIGURATION_MENU_MISSING_COMPANIES_WITHOUT_MENUS: 'As seguintes empresas não possuem menus: %s.',
    CHANNEL_CONFIGURATION_MENU_ON_DELETE_QUESTION: 'Você tem certeza que deseja excluir o "%s" ?',
    CHANNEL_CONFIGURATION_MENU_ON_DELETE_TITLE: 'Excluir %s',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_TITLE: 'Segmentações de menu - Menu %d',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_FIRST_COLUMN_HEADER: 'Opções do menu',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SECOND_COLUMN_HEADER: 'Segmentações',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_BUTTON_TITLE: 'Nova segmentação',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_TOOLTIP_TITLE: 'Selecione uma opção de menu',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SELECTED_MENU: 'Menu selecionado: %s',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CONFIRM_BUTTON_TITLE: 'Confirmar',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_LINK: 'Link',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_ROBOT: 'Robô de agendamento',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_SUB_MENU: 'Menu',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_INFORMATION: 'Informações',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_UNKNOWN: 'Tipo desconhecido',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_LABEL: 'Segmentações',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_PLACEHOLDER: 'Segmentações',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_DELETE_SEGMENTATION_BUTTON_TITLE: 'Remover Segmentação',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NO_SEGMENTED_COMPANY: 'Nenhuma empresa segmentada',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_OPTION_NOT_SELECTED: 'Nenhuma opção de menu selecionada',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_TITLE: 'Erro ao adicionar empresa',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_CONTENT:
        'Você já adicionou a empresa %s para segmentar!',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_TITLE: 'Erro ao segmentar empresa',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_CONTENT:
        'Selecione um menu antes de segmentar uma empresa!',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU:
        'Você segmentou um sub-menu (filho). Adicionar esta segmentação também ao menu (pai)?',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CANCEL_OPTION: 'Não',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CONFIRM_OPTION: 'Segmentar',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SIMPLE_ALERT_TITLE: 'Segmentar menu',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ALERT_WARNING_DURING_MENU_CREATION:
        'Publique o canal para poder segmentar o menu criado recentemente.',

    CHANNEL_CONFIGURATION_MESSAGES_TITLE: 'Início do atendimento',
    CHANNEL_CONFIGURATION_MESSAGES_EVENT_GENERATION: 'Geração de evento',
    CHANNEL_CONFIGURATION_MESSAGES_SPECIFIC_MESSAGE: 'Situações específicas',
    CHANNEL_CONFIGURATION_MESSAGES_GREETING_CAPTION: 'Saudação',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_CAPTION: 'Segmentação de empresas',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_CAPTION: 'Seleção de empresas',
    CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENTS_SELECTION_CAPTION: 'Seleção de departamentos',
    CHANNEL_CONFIGURATION_MESSAGES_GENERATED_EVENT_CAPTION: 'Evento gerado',
    CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_NEW_TICKET_CAPTION: 'Evento gerado fora do horário comercial',
    CHANNEL_CONFIGURATION_MESSAGES_ATTENDENCE_CONTINUATION_CAPTION: 'Continuação de atendimento',
    CHANNEL_CONFIGURATION_MESSAGES_IDENTITY_CONFIRMATION_CAPTION: 'Confirmação de identidade',
    CHANNEL_CONFIGURATION_MESSAGES_OFFLINE_SYSTEM_CAPTION: 'Sistema indisponível',
    CHANNEL_CONFIGURATION_MESSAGES_INVALID_OPTION_CAPTION: 'Opção inválida',
    CHANNEL_CONFIGURATION_MESSAGES_MISSING_CONTENT_MESSAGE: 'Falta informar o conteúdo',
    CHANNEL_CONFIGURATION_MESSAGES_INSERT_NEEDED_VARIABLE: 'Insira a variável necessária:',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_TAG_NAME: 'segmentações',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_TAG_NAME: 'empresas',
    CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENT_SELECTION_TAG_NAME: 'departamentos',
    CHANNEL_CONFIGURATION_MESSAGES_NEW_TICKET_TAG_NAME: 'evento',
    CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_HOURS_NEW_TICKET_TAG_NAME: 'evento',

    CHANNEL_CONFIGURATION_WORKING_HOURS_NEW_HOUR_BUTTON: 'CONFIGURAR NOVO HORÁRIO',
    CHANNEL_CONFIGURATION_WORKING_HOURS_COMPANY: 'Empresa',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DAYS: 'Dias',
    CHANNEL_CONFIGURATION_WORKING_HOURS_TIME: 'Horários',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDIT_HOUR_TOOLTIP: 'Editar horário',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DELETE_HOUR_TOOLTIP: 'Excluir horário',
    CHANNEL_CONFIGURATION_WORKING_HOURS_HOLE_DAY_LABEL: '24 horas',
    CHANNEL_CONFIGURATION_WORKING_HOURS_MONDAY: 'Seg',
    CHANNEL_CONFIGURATION_WORKING_HOURS_TUESDAY: 'Ter',
    CHANNEL_CONFIGURATION_WORKING_HOURS_WEDNESDAY: 'Qua',
    CHANNEL_CONFIGURATION_WORKING_HOURS_THURSDAY: 'Qui',
    CHANNEL_CONFIGURATION_WORKING_HOURS_FRIDAY: 'Sex',
    CHANNEL_CONFIGURATION_WORKING_HOURS_SATURDAY: 'Sáb',
    CHANNEL_CONFIGURATION_WORKING_HOURS_SUNDAY: 'Dom',
    CHANNEL_CONFIGURATION_WORKING_HOURS_ALL_DAYS: 'Todos os dias',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DEFAULT_VALUE: 'Horário padrão quando não definido',
    CHANNEL_CONFIGURATION_WORKING_HOURS_MISSING_HOUR_CONFIGURATION: 'Falta configurar horário para as empresas',
    CHANNEL_CONFIGURATION_WORKING_HOURS_PARTIALLY_PERIOD_CONFIGURATION: 'Configuração parcial de alguns períodos',
    CHANNEL_CONFIGURATION_WORKING_HOURS_ICONSISTENT_HOUR: 'Inconsistências nos horários',
    CHANNEL_CONFIGURATION_WORKING_HOURS_AND_BETWEEN_TIMES: 'e',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_CHANNEL_ID_ERROR: 'ChannelId is a required argument',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_NEW_TIMES_ID_ERROR: 'newTimesId is a required argument',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TITLE: 'Configurações de horários de atendimentos',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_AND_BETWEEN_TIMES: 'e',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SPACE_BETWEEN_TIMES: 'às',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_COMPANY_QUANTITY_ERROR: 'Falta marcar pelo menos uma empresa',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SUNDAY_CAPTION: 'Domingo',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_MONDAY_CAPTION: 'Segunda-feira',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TUESDAY_CAPTION: 'Terça-feira',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_WEDNESDAY_CAPTION: 'Quarta-feira',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_THURSDAY_CAPTION: 'Quinta-feira',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_FRIDAY_CAPTION: 'Sexta-feira',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SATURDAY_CAPTION: 'Sábado',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_START_TIME_FIELD: 'Hora início',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_END_TIME_FIELD: 'Hora fim',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TIME_ERROR: 'O segundo período deve ser posterior ao primeiro',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_START_TIME_VALIDATION: 'Anterior ao tempo final',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_END_TIME_VALIDATION: 'Posterior ao tempo final',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_START_TIME_PERIOD_VALIDATION: 'Requerido',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_END_TIME_PERIOD_VALIDATION: 'Requerido',

    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_TITLE: 'Palavras ignoradas',
    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_DESCRIPTION:
        'As palavras configuradas serão ignoradas para fim de começar uma conversa, seja com o robô ou um atendente. Não é diferenciado entre letras maiúsculas e minúsculas.',
    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_LABEL: 'Palavras',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_TITLE: 'Opção Voltar',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_DESCRIPTION:
        'A cada pergunta que o robô fizer ao cliente é possível exibirmos um botão de "Voltar". Nossa sugestão é não exibir esse botão em todas as perguntas, mas ensinar ao usuário que o mesmo pode digitar "voltar" sempre que desejar.',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_NOT_SHOW_GO_BACK_OPTION_LABEL: 'Não exibir o botão "Voltar"',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_SHOW_GO_BACK_OPTION_LABEL:
        'Exibir o botão voltar em todas as etapas de conversa do robô',

    CHANNEL_CONFIGURATION_VALIDATION_CAPTION_VALIDATE_PUBLISH: 'Validar publicação',
    CHANNEL_CONFIGURATION_VALIDATION_INFO_VALIDATE_PUBLISH:
        'Atenção, você está prestes a <strong>publicar este canal</strong>, a partir de agora as novas configurações entrarão em vigor.',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_CAPTION: 'Canal configurado corretamente',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_INFO: 'Tudo certo com a configuração deste canal',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_CAPTION:
        'Canal apresenta erros de configuração',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_INFO:
        'Parece que há erros que impedem a publicação do canal',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_CAPTION:
        'Canal configurado corretamente, mas com observações',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_INFO:
        'Parece que há configurações não recomendadas no seu canal!',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_CAPTION: 'Canal parcialmente configurado',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_INFO:
        'Parece que há configurações pendentes no seu canal!',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_NAME_ESTABLISHED: 'Nome do canal definido',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITHOUT_NAME_ESTABLISHED: 'Canal sem nome definido',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_LINKED_TO_CHANNEL: 'Empresas vinculadas ao canal',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_NOT_LINKED_TO_CHANNEL: 'Empresas não vinculadas ao canal',
    CHANNEL_CONFIGURATION_VALIDATION_SEGMENTED_COMPANIES: 'Empresas segmentadas',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_WITHOUT_SEGMENTATION: 'Há empresas sem segmentação',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_SEGMENTATION_DEFAULT_VALUE: '',
    CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_MENUS_MESSAGE: 'Menus definidos',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_MENUS_MESSAGE: 'Menus não definidos',
    CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_MENUS_MESSAGE: 'Menus parcialmente definidos',
    CHANNEL_CONFIGURATION_VALIDATION_MENUS_WITH_ICONSISTENCY_MESSAGE: 'Menus com inconsistências',
    CHANNEL_CONFIGURATION_VALIDATION_MENUS_COMPANIES_WITHOUT_MENU_MESSAGE: 'Nem todas as empresas possuem menus',
    CHANNEL_CONFIGURATION_VALIDATION_VALID_MESSAGES: 'Mensagens válidas',
    CHANNEL_CONFIGURATION_VALIDATION_INVALID_MESSAGES: 'Mensagens inválidas',
    CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_WORKING_HOURS: 'Horários definidos',
    CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_WORKING_HOURS: 'Horários parcialmente definidos',
    CHANNEL_CONFIGURATION_VALIDATION_INVALID_WORKING_HOURS: 'Horários inválidos',
    CHANNEL_CONFIGURATION_VALIDATION_WORKING_HOURS_WITH_INCONSISTENCIES: 'Horários com inconsistências',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_WORKING_HOURS: 'Horários não definidos',
    CHANNEL_CONFIGURATION_VALIDATION_VALID_EXTRAS_CONFIG: 'Configurações extras válidas',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_VALID_EXTRAS_CONFIG: 'Configurações extras inválidas',

    CHANNEL_CONFIGURATION_ALERT_CONTENT_TITLE: 'Configure e ative seu canal agora para começar a usá-lo.',
    CHANNEL_CONFIGURATION_ALERT_CONTENT_CLOSE_BUTTON: 'Fechar',
    CHANNEL_CONFIGURATION_ALERT_CONTENT_CONFIGURE_CHANNEL_BUTTON: 'Configurar canal',

    CHANNEL_CONFIGURATION_SUCCESS_ALERT_VALIDATION_CONTENT_TITLE: 'Configurações válidas',
    CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TITLE: 'Configurações inválidas',
    CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TEXT: '(corrija para publicar)',
    CHANNEL_CONFIGURATION_WARNING_ALERT_VALIDATION_CONTENT_TITLE: 'Observações',
    CHANNEL_CONFIGURATION_VALIDATION_CLOSE_ACTION_BUTTON: 'Fechar',
    CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_CLOSE_BUTTON: 'Fechar',
    CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_PUBLISH_BUTTON: 'Publicar',

    CHANNEL_CONFIGURATION_ALERT_VALIDATION_CANCEL_BUTTON_TEXT: 'Cancelar',
    CHANNEL_CONFIGURATION_ALERT_VALIDATION_CONFIRM_BUTTON_TEXT: 'Confirmar',

    CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS: 'Parece que há configurações pendentes no seu canal!',
    CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS_DESCRIPTION: 'Essas são as configurações pendentes',
    CHANNEL_CONFIGURATION_ALERT_WARNING_MENU: 'Menu',
    CHANNEL_CONFIGURATION_ALERT_WARNING_MESSAGES: 'Mensagens',
    CHANNEL_CONFIGURATION_ALERT_WARNING_TIMES: 'Horários',
    CHANNEL_CONFIGURATION_ALERT_WARNING_CONTINUE_BUTTON: 'Continuar',
    CHANNEL_CONFIGURATION_ALERT_WARNING_CONFIGURE_CHANNEL_BUTTON: 'Configurar canal',

    LOADING_CONTENT_DESCRIPTION: 'Carregando...',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_GROUP_AND_TYPE_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_ORIGIN_EVENT: 'Origem evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_MEDIA_EVENT: 'Mídia evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_EVENT_GENERATE: 'Gerar evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_AUTO_RESCUE: 'Resgate automático',

    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_COMPANY_ALERT_TITLE: 'Empresa vinculada ao menu',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_TITLE: 'Segmentação vinculada ao menu',
    CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_SEGMENTATION_ALERT_CONTENT:
        'Antes de excluir a segmentação "%s", desvincule-a do Menu %s.',
    CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_COMPANY_ALERT_CONTENT:
        'Antes de excluir a empresa "%s", desvincule-a do Menu %s.',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CLOSE_ACTION: 'Fechar',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CONFIRM_ACTION: 'ir ao menu'
}
