import { keyframes } from '@emotion/react'
import { MAX_INTERNAL_WIDTH } from 'src/Constants'
import { makeStyles } from 'tss-react/mui'

const load = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const fadein = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const fadeout = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const getOrMakeMainStyles = makeStyles()(() => ({
    modal: {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: MAX_INTERNAL_WIDTH,
        margin: 20
    },
    MainView: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        alignSelf: 'stretch',
        flexGrow: 1,
        backgroundColor: 'white'
    },
    appBar: {
        backgroundColor: '#616161!important',
        zIndex: 1
    },
    appBarMenuButton: {
        marginRight: 2
    },
    appBarTitle: {
        flexGrow: 1
    },
    Body: {
        flexGrow: 1,
        flexShrink: 1,
        alignSelf: 'stretch',
        overflowY: 'scroll',
        overflowX: 'hidden',
        justifyContent: 'center',
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        gridTemplateRows: '1fr',
        gap: '0px 0px'
    },
    BodyMenu: {
        gridArea: 'grid-area: 1 / 1 / 2 / 2'
    },
    BodyContent: {
        gridArea: 'grid-area: 1 / 1 / 2 / 3',
        marginBottom: 32,
        maxWidth: MAX_INTERNAL_WIDTH
    },
    Filter: {
        display: 'flex'
    },
    FilterHidden: {
        display: 'none'
    },
    adjustAlertPosition: {
        marginTop: 50
    },
    appLoadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: 'center',
        backgroundColor: '#fff',
        zIndex: 10
    },
    appLoading: {
        margin: 'auto',
        border: '16px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '16px solid #43A047',
        width: 100,
        height: 100,
        animation: `${load} 2s linear infinite`
    },
    appLoadingText: {
        marginTop: '15%',
        fontSize: 16,
        letterSpacing: 0.15
    },
    fabContainer: {
        flexGrow: 1,
        alignSelf: 'stretch',
        justifyContent: 'center',
        position: 'relative'
    },
    fabContent: {
        bottom: 16,
        right: 16,
        position: 'absolute',
        zIndex: 10
    },
    fabFadeIn: {
        display: 'block',
        animation: `${fadein} 0.5s ease-in-out`
    },
    fabFadeOut: {
        display: 'none',
        animation: `${fadeout} ease 0.3s`
    }
}))

const drawerWidth = 240

export const getOrMakeMenuStyles = makeStyles()((theme) => ({
    drawer: {
        flexShrink: 0,

        ['& .MuiDrawer-paper']: {
            minWidth: drawerWidth,
            boxSizing: 'border-box',
            position: 'inherit'
        },
        zIndex: 1
    },
    drawerContainer: {
        overflowY: 'auto'
    },
    menuList: {
        width: '100%',
        maxWidth: drawerWidth,
        backgroundColor: theme.palette.background.paper
    },
    subMenuItem: {
        paddingLeft: '32px!important'
    }
}))
