import { Observable, observe, Scope } from 'wdc-cube'
import { RichEditorScope } from 'src/components/richeditor'
import { CheckedChangeEvent, TextChangeEvent } from 'src/utils'
import { ChannelMenuEventOption } from '../ChannelConfiguration.service'
import { ChannelSourceAndMediaEventOption } from '@whatsapp/communication/lib/bff/admin/ChannelConfiguration.communication'

@Observable
export class ChannelConfigurationSelfRescueFormScope extends Scope {
    readonly rescueMessage = new RichEditorScope()
    readonly goodbyeMessage = new RichEditorScope()

    @observe() selfRescueId = 0
    @observe() selfRescueType = 'ROBOT_RESCUE'
    @observe() robotRescueMinutes = 1
    @observe() active = false
    @observe() confirmButtonEnabled = false
    @observe() robotRescueMinutesError = false
    @observe() rescue = ''
    @observe() goodbye = ''

    @observe() selfRescueTypeName = ''
    @observe() selfRescueGroup = ''
    @observe() selfRescueOrigin = ''
    @observe() selfRescueMidia = ''

    @observe() selfRescuemenuEventOptionsForm = [] as ChannelMenuEventOption[]
    @observe() selfRescueSourceAndMediaEventOptionsForm = [] as ChannelSourceAndMediaEventOption[]

    @observe() onSelfRescueTypeName = Scope.SYNC_ACTION_ONE<string>()
    @observe() onSelfRescueGroup = Scope.SYNC_ACTION_ONE<string>()
    @observe() onSelfRescueOrigin = Scope.SYNC_ACTION_ONE<string>()
    @observe() onSelfRescueMidia = Scope.SYNC_ACTION_ONE<string>()

    @observe() onSelfRescue = Scope.SYNC_ACTION_TWO<CheckedChangeEvent, boolean>()
    @observe() onSelfRescueMinutesChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onSelfRescueTyped = Scope.SYNC_ACTION_ONE<string>()

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
}
