/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AdminChats, AdminDashboard as NS, ChannelId, PagingDTO } from '@whatsapp/communication'
import { BaseAdminService } from 'src/services'
import { DateFns } from 'src/utils'
import { Logger, ServiceLike } from 'wdc-cube'

const LOG = Logger.get('DashboardService')

// :: Re-exporting types and consts because on commonjs there is no Namespace

export type { ChannelId, PagingDTO }

export type ChatTicketStatus = AdminChats.ChatTicketStatus
export const ChatTicketStatus = AdminChats.ChatTicketStatus

export type Visualization = NS.Visualization
export const Visualization = NS.Visualization

export type CustomerServiceType = NS.CustomerServiceType
export const CustomerServiceType = NS.CustomerServiceType

export type CustomerIdAndName = NS.CustomerIdAndName
export type CompanyIdAndName = NS.CompanyIdAndName
export type UserIdAndName = NS.UserIdAndName
export type CategoryOption = NS.CategoryOption

export type SelectedFilterOptions = NS.CustomerServicesResponse['filter']

export type CustomerServiceEntry = NS.CustomerServiceEntry
export type ResumedActiveMessageDTO = NS.ResumedActiveMessageDTO
export type DetailedActiveMessageDTO = NS.DetailedActiveMessageDTO
export type QueryResponse = NS.Response['query']
export type MutationResponse = NS.Response['mutation']

export type CustomerServicesRequest = NS.CustomerServicesRequest
export type CustomerServicesResponse = NS.CustomerServicesResponse

export type ActiveMessagesResumedRequest = NS.ActiveMessagesResumedRequest
export type ActiveMessagesResumedResponse = NS.ActiveMessagesResumedResponse

export type ActiveMessagesDetailedRequest = NS.ActiveMessagesDetailedRequest
export type ActiveMessagesDetailedResponse = NS.ActiveMessagesDetailedResponse

export type ContentCustomerServiceRequest = Omit<CustomerServicesRequest, 'startTime' | 'endTime'> & {
    startDateTime?: Date
    endDateTime?: Date
}

export type ContentActiveMessagesResumedRequest = Omit<ActiveMessagesResumedRequest, 'startTime' | 'endTime'> & {
    startDateTime?: Date
    endDateTime?: Date
    userId?: string
}

export type ContentRequest = {
    channels: string[]
    startTime: Date
    endTime: Date
    customerServices: Omit<CustomerServicesRequest, 'channels' | 'startTime' | 'endTime'>
    activeMessages: Omit<ActiveMessagesResumedRequest, 'channels' | 'startTime' | 'endTime'>
    noCache?: boolean
}

export type ResumedActiveMessagesContent = {
    channels: string[]
    startTime: Date
    endTime: Date
    user: string
    itemsPerPage?: number
    pageIndex?: number
}

export class DashboardService extends BaseAdminService implements ServiceLike {
    private static readonly INSTANCE = new DashboardService()

    public static singleton() {
        return DashboardService.INSTANCE
    }

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    // :: Instance

    private __initialized = false

    public get name() {
        return 'DashboardService'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        this.__initialized = true
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public async fetchCustomServicesFilterOptions(
        channels: string[],
        itemsPerPage?: number,
        pageIndex?: number,
        startDateTime?: Date,
        endDateTime?: Date
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { customersFromCustomerService, usersFromCustomerService, companiesFromCustomerService } = (
            await this.send({
                query: {
                    customersFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex },
                    usersFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex },
                    categoriesFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex },
                    companiesFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex }
                }
            })
        ).query!

        this.raiseUnexpected(customersFromCustomerService)
        this.raiseUnexpected(usersFromCustomerService)
        this.raiseUnexpected(companiesFromCustomerService)

        return {
            customers: customersFromCustomerService!,
            users: usersFromCustomerService!,
            companies: companiesFromCustomerService!
        }
    }

    public async fetchCustomerByName(
        customerName: string,
        channels: string[],
        itemsPerPage?: number,
        pageIndex?: number,
        startDateTime?: Date,
        endDateTime?: Date
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { customersFromCustomerService } = (
            await this.send({
                query: {
                    customersFromCustomerService: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage,
                        pageIndex,
                        customerName
                    }
                }
            })
        ).query!

        this.raiseUnexpected(customersFromCustomerService)

        return customersFromCustomerService!
    }

    public async fetchUserByName(
        userName: string,
        channels: string[],
        itemsPerPage?: number,
        pageIndex?: number,
        startDateTime?: Date,
        endDateTime?: Date
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { usersFromCustomerService } = (
            await this.send({
                query: {
                    usersFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex, userName }
                }
            })
        ).query!

        this.raiseUnexpected(usersFromCustomerService)

        return usersFromCustomerService!
    }

    public async fetchCategoryByName(
        categoryName: string,
        channels: string[],
        itemsPerPage?: number,
        pageIndex?: number,
        startDateTime?: Date,
        endDateTime?: Date
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { categoriesFromCustomerService } = (
            await this.send({
                query: {
                    categoriesFromCustomerService: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage,
                        pageIndex,
                        categoryName
                    }
                }
            })
        ).query!

        this.raiseUnexpected(categoriesFromCustomerService)

        return categoriesFromCustomerService!
    }

    public async fetchCompaniesByName(
        companyName: string,
        channels: string[],
        itemsPerPage?: number,
        pageIndex?: number,
        startDateTime?: Date,
        endDateTime?: Date
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { companiesFromCustomerService } = (
            await this.send({
                query: {
                    companiesFromCustomerService: { channels, startTime, endTime, itemsPerPage, pageIndex, companyName }
                }
            })
        ).query!

        this.raiseUnexpected(companiesFromCustomerService)

        return companiesFromCustomerService!
    }

    public async fetchCustomerServices(request: ContentCustomerServiceRequest) {
        const channels = request.channels
        const startTime = request.startDateTime ? DateFns.formatISO(request.startDateTime) : undefined
        const endTime = request.endDateTime ? DateFns.formatISO(request.endDateTime) : undefined

        const { customerServices } = (
            await this.send({
                query: {
                    customerServices: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage: request.itemsPerPage,
                        pageIndex: request.pageIndex,
                        visualization: request.visualization,
                        ticketIds: request.ticketIds,
                        customerIds: request.customerIds,
                        companyIds: request.companyIds,
                        userIds: request.userIds,
                        categoryIds: request.categoryIds,
                        type: request.type,
                        durationInDays: request.durationInDays
                    }
                }
            })
        ).query!

        this.raiseUnexpected(customerServices)

        return customerServices!
    }

    public async fetchActiveMessagesResumed(request: ContentActiveMessagesResumedRequest) {
        const channels = request.channels
        const startTime = request.startDateTime ? DateFns.formatISO(request.startDateTime) : undefined
        const endTime = request.endDateTime ? DateFns.formatISO(request.endDateTime) : undefined

        const { activeMessagesResumed } = (
            await this.send({
                query: {
                    activeMessagesResumed: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage: request.itemsPerPage,
                        pageIndex: request.pageIndex,
                        userId: request.userId
                    }
                }
            })
        ).query!

        this.raiseUnexpected(activeMessagesResumed)

        return activeMessagesResumed!
    }

    public async fetchActiveMessagesDetailed(
        channels: string[],
        itemsPerPage = 10,
        pageIndex = 0,
        startDateTime?: Date,
        endDateTime?: Date,
        userId?: string
    ) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { activeMessagesDetailed } = (
            await this.send({
                query: {
                    activeMessagesDetailed: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage,
                        pageIndex,
                        userId
                    }
                }
            })
        ).query!

        this.raiseUnexpected(activeMessagesDetailed)

        return activeMessagesDetailed!
    }

    public async fetchContent(request: ContentRequest) {
        const channels = request.channels
        const startTime = request.startTime ? DateFns.formatISO(request.startTime) : undefined
        const endTime = request.endTime ? DateFns.formatISO(request.endTime) : undefined

        const { customerServices, activeMessagesResumed } = (
            await this.send({
                query: {
                    customerServices: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage: request.customerServices.itemsPerPage,
                        pageIndex: request.customerServices.pageIndex,
                        visualization: request.customerServices.visualization,
                        ticketIds: request.customerServices.ticketIds,
                        customerIds: request.customerServices.customerIds,
                        companyIds: request.customerServices.companyIds,
                        userIds: request.customerServices.userIds,
                        categoryIds: request.customerServices.categoryIds,
                        type: request.customerServices.type,
                        durationInDays: request.customerServices.durationInDays,
                        noCache: request.noCache
                    },
                    activeMessagesResumed: {
                        channels,
                        startTime,
                        endTime,
                        itemsPerPage: request.activeMessages.itemsPerPage,
                        pageIndex: request.activeMessages.pageIndex,
                        userId: request.activeMessages.userId
                    }
                }
            })
        ).query!

        this.raiseUnexpected(customerServices)
        this.raiseUnexpected(activeMessagesResumed)

        return {
            customerServices: customerServices!,
            activeMessagesResumed: activeMessagesResumed!
        }
    }

    public async fetchCustomerActiveMessagesContent({
        channels,
        startTime: startDateTime,
        endTime: endDateTime,
        user: userId,
        itemsPerPage,
        pageIndex
    }: ResumedActiveMessagesContent) {
        const startTime = startDateTime ? DateFns.formatISO(startDateTime) : undefined
        const endTime = endDateTime ? DateFns.formatISO(endDateTime) : undefined

        const { activeMessagesResumed, activeMessagesDetailed } = (
            await this.send({
                query: {
                    activeMessagesResumed: { channels, startTime, endTime, userId, itemsPerPage: 1, pageIndex: 0 },
                    activeMessagesDetailed: { channels, startTime, endTime, userId, itemsPerPage, pageIndex }
                }
            })
        ).query!

        this.raiseUnexpected(activeMessagesResumed)
        this.raiseUnexpected(activeMessagesDetailed)

        return {
            activeMessagesResumed: activeMessagesResumed!,
            activeMessagesDetailed: activeMessagesDetailed!
        }
    }
}
