import { EditorState } from 'draft-js'
import { ChannelConfigurationMenuEditorFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { HtmlUtils, toURL } from 'src/utils'
import { editorStateToWhatsapp, whatsappToEditorState } from 'src/utils/draft-js'
import { action, CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID, Presenter } from 'wdc-cube'
import { MainPresenter } from '../main'
import { ChannelMenuEntry, ChannelMenuItem, ChannelMenuType } from './ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import { Tabs } from './ChannelConfigurationForm.scopes'
import {
    ChannelConfigurationMenuEditorFormEventScope,
    ChannelConfigurationMenuEditorFormInformationScope,
    ChannelConfigurationMenuEditorFormLinkScope,
    ChannelConfigurationMenuEditorFormSchedulingRobotScope,
    ChannelConfigurationMenuEditorFormScope,
    ChannelConfigurationMenuEditorFormSubMenuScope,
    IEventMenu,
    MenuOptionScope
} from './ChannelConfigurationMenuEditorForm.scopes'

import type { TextChangeEvent } from './ChannelConfigurationMenuEditorForm.scopes'

import { CompanySelectorPresenter } from './CompanySelectorPresenter'
import { TextsProvider } from './texts'
import { UploadFileService } from 'src/services/UploadFileService'
import {
    VIDEO_SUPPORTED_TYPES,
    IMAGE_SUPPORTED_TYPES,
    DOCUMENT_SUPPORTED_TYPES,
    getUrlType
} from '@whatsapp/communication'

const supportedTypes = [...VIDEO_SUPPORTED_TYPES, ...IMAGE_SUPPORTED_TYPES, ...DOCUMENT_SUPPORTED_TYPES].map((t) =>
    t.replace('.', '')
)

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationMenuEditorFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationMenuEditorFormScope>
    implements IPresenterOwner
{
    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private __formPresenter?: ChannelConfigurationFormPresenter

    private channelId = ''

    private menuId = -1

    private disposed = false

    readonly companySelectorPresenter = new CompanySelectorPresenter(
        this.app,
        this.scope.companiesSelector,
        this.updateManager
    )

    private menuOptionPresenterList: MenuOptionPresenter[] = []

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationMenuEditorFormScope())
        this.companySelectorPresenter.onAfterCompanyToggled = this.onCompanyToggled.bind(this)
        this.companySelectorPresenter.isCompanyInUse = this.checkIfCompanyIsInUseBySomeMenuEntry.bind(this)
    }

    public override release() {
        this.disposed = true
        this.modalSlot(this.scope, true)
        this.companySelectorPresenter.release()
        this.menuOptionPresenterList.forEach((childPresenter) => childPresenter.release())
        this.menuOptionPresenterList.length = 0
        super.release()
    }

    public get formPresenter() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.__formPresenter!
    }

    // Cube context syncronization methods: Begin

    // @Override
    public async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChannelConfigurationMenuEditorFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    // @Override
    public publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationMenuEditorFormKeys(intent)
        keys.channelId(this.channelId)
        if (this.menuId > 0) {
            keys.menuId(this.menuId)
        }
    }

    private async initializeState(keys: ChannelConfigurationMenuEditorFormKeys) {
        this.bindListeners()
        this.modalSlot = keys.modalSlot()
        this.__formPresenter = keys.hostPresenter() as ChannelConfigurationFormPresenter

        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChannelConfigurationMenuEditorFormKeys, force = false) {
        let changed = force || (keys.refresh() ?? false)

        const newTemplateId = keys.channelId() ?? this.channelId
        changed = changed || newTemplateId !== this.channelId

        const newMenuId = keys.menuId() ?? this.menuId
        changed = changed || newMenuId !== this.menuId

        if (changed) {
            if (!newTemplateId) {
                throw new Error(texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CHANNEL_ID_ERROR)
            }

            this.channelId = newTemplateId
            this.menuId = newMenuId

            await this.refresh()
        }
    }

    private bindListeners() {
        this.scope.onCancel = this.onCancel.bind(this)
        this.scope.onSave = this.onSave.bind(this)
        this.scope.onAppendOption = this.onAppendOption.bind(this)
        this.scope.onSwapPosition = this.onShowMenuPositions.bind(this)
        this.scope.onMenuSelect = this.onMenuSelect.bind(this)
    }

    private async refresh() {
        const form = this.formPresenter.menuPresenter.getForm(this.menuId)

        this.scope.menuId = this.menuId = form.id

        this.companySelectorPresenter.synchronizeState(
            this.formPresenter?.getSelectedCompanyMap() ?? new Map(),
            form.companies
        )

        this.synchronizeMenuOptions(form.entries)
    }

    private synchronizeMenuOptions(menuOptions: ChannelMenuEntry[]) {
        let i = 0
        for (const menuOption of menuOptions) {
            let menuOptionPresenter = this.menuOptionPresenterList[i]
            if (!menuOptionPresenter) {
                menuOptionPresenter = new MenuOptionPresenter(this)
                this.menuOptionPresenterList[i] = menuOptionPresenter
                this.scope.entries.set(i, menuOptionPresenter.scope)
            }
            menuOptionPresenter.scope.position = i + 1
            menuOptionPresenter.scope.id = menuOption.id
            menuOptionPresenter.scope.subServices = menuOption.subServices
            menuOptionPresenter.scope.isSubService = menuOption.isSubService
            menuOptionPresenter.scope.deactivated = menuOption.subServices?.length > 0
            menuOptionPresenter.scope.segmentations = menuOption.segmentations
            menuOptionPresenter.synchronizeState(menuOption)
            i++
        }
        this.scope.entries.length = i

        for (let j = this.menuOptionPresenterList.length - 1; j >= i; j--) {
            const menuOptionPresener = this.menuOptionPresenterList[i]
            menuOptionPresener.release()
        }
        this.menuOptionPresenterList.length = i

        this.syncronizeEventOptions()
    }

    private checkIfCompanyIsInUseBySomeMenuEntry(companyId: number) {
        if (this.__formPresenter) {
            return this.__formPresenter.menuPresenter.isCompanyBeingUsed(companyId)
        }
        return false
    }

    @action()
    private async onAppendOption() {
        const i = this.menuOptionPresenterList.length
        const menuOptionPresenter = new MenuOptionPresenter(this)

        menuOptionPresenter.scope.position = i + 1
        menuOptionPresenter.synchronizeState({
            type: ChannelMenuType.EVENT,
            description: '',
            id: '',
            subServices: [],
            isSubService: false,
            segmentations: []
        })

        const parentOption = this.scope.selectedMenu
        if (parentOption) {
            const oldSubServices = parentOption.subServices
            parentOption.subServices = [...oldSubServices, menuOptionPresenter.menu.id]
            menuOptionPresenter.scope.isSubService = true
        }

        this.menuOptionPresenterList[i] = menuOptionPresenter
        this.scope.entries.set(i, menuOptionPresenter.scope)
    }

    private onCompanyToggled() {
        for (const menuOptionPresenter of this.menuOptionPresenterList) {
            menuOptionPresenter.onCompanyToggled()
        }
    }

    @action()
    private onMenuSelect(uuid: string) {
        if (this.scope.selectedMenu) {
            this.scope.lastSelectedMenu = this.scope.selectedMenu
        }
        this.scope.selectedMenu = this.scope.entries.find((s) => uuid === s.uid)
    }

    @action()
    private onShowMenuPositions(targetIndex: number, sourceIndex: number) {
        this.scope.entries.move(sourceIndex, targetIndex)

        const [removedItem] = this.menuOptionPresenterList.splice(sourceIndex, 1)
        this.menuOptionPresenterList.splice(targetIndex, 0, removedItem)

        this.menuOptionPresenterList.forEach((menu, index) => (menu.scope.position = index + 1))
    }

    @action()
    private async onCancel() {
        await this.close()
    }

    @action()
    private async onSave() {
        this.formPresenter.menuPresenter.saveForm(this.extractForm())
        await this.close()
    }

    private async close() {
        if (!this.disposed) {
            const keys = KeysFactory.channelConfigurationForm(this.app)
            keys.tabIndex(Tabs.MENU)
            await this.flipToIntent(keys.intent)
        }
    }

    deleteOption(menuPresenter: MenuOptionPresenter) {
        let menuOptions: ChannelMenuEntry[] = []
        const hasSameId = (id: string) => menuPresenter.menu.id === id
        for (const otherMenuPresenter of this.menuOptionPresenterList) {
            if (otherMenuPresenter !== menuPresenter) {
                const menuOption = otherMenuPresenter.extractOption()

                if (menuOption) {
                    if (!menuOption.isSubService) {
                        menuOption.subServices = menuOption.subServices.filter((id) => !hasSameId(id))
                    }
                    menuOptions.push(menuOption)
                }
            }
        }

        const menuOption = menuPresenter.extractOption()
        if (menuOption && menuOption.type === ChannelMenuType.SUB_MENU) {
            menuOptions = menuOptions.filter((menu) => !menuOption.subServices.includes(menu.id))
        }

        this.synchronizeMenuOptions(menuOptions)
    }

    syncronizeEventOptions() {
        for (const otherMenuPresenter of this.menuOptionPresenterList) {
            otherMenuPresenter.syncronizeEventOptions()
        }
    }

    private extractForm() {
        const result: ChannelMenuItem = {
            id: this.menuId,
            companies: this.companySelectorPresenter.extractCompanyIdArray(),
            entries: [],
            segmentations: []
        }

        for (const optionPresenter of this.menuOptionPresenterList) {
            const menuOption = optionPresenter.extractOption()
            if (menuOption) {
                result.entries.push(menuOption)
            }
        }

        return result
    }

    // @Override
    public onBeforeScopeUpdate() {
        let companyCount = 0
        for (const itemScope of this.scope.companiesSelector.entries) {
            if (itemScope.selected) {
                companyCount++
            }
        }

        if (companyCount === 0) {
            this.scope.companiesSelector.error = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_COMPANY_COUNT_ERROR
        } else {
            this.scope.companiesSelector.error = ''
        }
    }
}

class MenuOptionPresenter extends Presenter<MenuOptionScope> {
    private eventDetail?: EventMenuPresenter

    private linkDetail?: LinkMenuPresenter

    private robotDetail?: RobotMenuPresenter

    private subMenuDetail?: SubMenuPresenter

    private informationDetail?: InformationMenuPresenter

    private initialized = false

    constructor(owner: ChannelConfigurationMenuEditorFormPresenter) {
        super(owner, new MenuOptionScope(), owner.updateManager)
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get menu() {
        const id = this.scope.id
        const subServices = this.scope.subServices
        const isSubService = this.scope.isSubService
        const segmentations = this.scope.segmentations
        return { id, subServices, isSubService, segmentations }
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.update = this.update
        this.scope.onMenuTypeChanged = this.onMenuTypeChanged.bind(this)
        this.scope.onDelete = this.onDelete.bind(this)
        this.scope.onUnselect = this.onUnselect.bind(this)

        this.scope.menuTypeOptions.length = 0
        this.scope.menuTypeOptions.push({
            id: ChannelMenuType.EVENT,
            name: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_EVENT_TYPE
        })
        this.scope.menuTypeOptions.push({
            id: ChannelMenuType.LINK,
            name: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_LINK_TYPE
        })
        this.scope.menuTypeOptions.push({
            id: ChannelMenuType.ROBOT,
            name: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SCHEDULE_ROBOT_TYPE
        })
        this.scope.menuTypeOptions.push({
            id: ChannelMenuType.SUB_MENU,
            name: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SUB_MENU_TYPE
        })
        this.scope.menuTypeOptions.push({
            id: ChannelMenuType.INFORMATION,
            name: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_INFORMATION_MENU_TYPE
        })

        this.initialized = true
    }

    public synchronizeState(option: ChannelMenuEntry) {
        if (!this.initialized) {
            this.initializeState()
        }

        const oldLinkDetail = this.linkDetail
        const oldEventDetail = this.eventDetail
        const oldRobotDetail = this.robotDetail
        const oldSubMenuDetail = this.subMenuDetail
        const oldInformationDetail = this.informationDetail

        try {
            if (option.type === ChannelMenuType.EVENT) {
                this.scope.menuTypeValue = 1

                let childPresenter = this.eventDetail
                if (!childPresenter) {
                    childPresenter = new EventMenuPresenter(this)
                    this.eventDetail = childPresenter
                }

                childPresenter.synchronizeState(option.description, option.eventId)
                this.scope.menuTypeDetail = childPresenter.scope

                if (option.segmentations) {
                    this.scope.segmentations = option.segmentations
                }

                this.linkDetail = undefined
                this.robotDetail = undefined
                this.subMenuDetail = undefined
                this.informationDetail = undefined
            } else if (option.type === ChannelMenuType.LINK) {
                this.scope.menuTypeValue = 2

                let childPresenter = this.linkDetail
                if (!childPresenter) {
                    childPresenter = new LinkMenuPresenter(this)
                    this.linkDetail = childPresenter
                }

                childPresenter.synchronizeState(option.description, option.link ?? '')
                this.scope.menuTypeDetail = childPresenter.scope

                this.eventDetail = undefined
                this.robotDetail = undefined
                this.subMenuDetail = undefined
                this.informationDetail = undefined
            } else if (option.type === ChannelMenuType.ROBOT) {
                this.scope.menuTypeValue = 3

                let childPresenter = this.robotDetail
                if (!childPresenter) {
                    childPresenter = new RobotMenuPresenter(this)
                    this.robotDetail = childPresenter
                }

                childPresenter.synchronizeState(option.description)
                this.scope.menuTypeDetail = childPresenter.scope

                this.eventDetail = undefined
                this.linkDetail = undefined
                this.subMenuDetail = undefined
                this.informationDetail = undefined
            } else if (option.type === ChannelMenuType.SUB_MENU) {
                this.scope.menuTypeValue = 4

                let childPresenter = this.subMenuDetail
                if (!childPresenter) {
                    childPresenter = new SubMenuPresenter(this)
                    this.subMenuDetail = childPresenter
                }

                childPresenter.synchronizeState(option.description)
                this.scope.menuTypeDetail = childPresenter.scope

                this.eventDetail = undefined
                this.linkDetail = undefined
                this.robotDetail = undefined
                this.informationDetail = undefined
            } else if (option.type === ChannelMenuType.INFORMATION) {
                this.scope.menuTypeValue = 5

                let childPresenter = this.informationDetail
                if (!childPresenter) {
                    childPresenter = new InformationMenuPresenter(this)
                    this.informationDetail = childPresenter
                }

                childPresenter.synchronizeState(option)
                this.scope.menuTypeDetail = childPresenter.scope

                this.eventDetail = undefined
                this.linkDetail = undefined
                this.robotDetail = undefined
                this.subMenuDetail = undefined
            }
        } finally {
            if (oldEventDetail && oldEventDetail !== this.eventDetail) {
                oldEventDetail.release()
            }

            if (oldLinkDetail && oldLinkDetail !== this.linkDetail) {
                oldLinkDetail.release()
            }

            if (oldRobotDetail && oldRobotDetail !== this.robotDetail) {
                oldRobotDetail.release()
            }

            if (oldSubMenuDetail && oldSubMenuDetail !== this.subMenuDetail) {
                oldSubMenuDetail.release()
            }

            if (oldInformationDetail && oldInformationDetail !== this.informationDetail) {
                oldInformationDetail.release()
            }
        }
    }

    onCompanyToggled() {
        if (this.eventDetail) this.eventDetail.onCompanyToggled()
        if (this.informationDetail) this.informationDetail.onCompanyToggled
    }

    @action()
    private onUnselect() {
        this.owner.scope.selectedMenu = undefined
        const lastSelectedMenu = this.owner.scope.lastSelectedMenu
        if (lastSelectedMenu) {
            this.owner.scope.lastSelectedMenu = undefined
            this.owner.scope.selectedMenu = lastSelectedMenu
        }
    }

    @action()
    private async onMenuTypeChanged(evt: TextChangeEvent) {
        const menuTypeValue = +evt.target.value

        let description = ''
        let message = ''
        let negativeButton = ''
        let positiveButton = ''
        let link = ''
        let eventId: string | undefined

        if (this.eventDetail) {
            description = editorStateToWhatsapp(this.eventDetail.scope.description.text)
            eventId = this.eventDetail.scope.eventValue ?? undefined
        } else if (this.linkDetail) {
            description = editorStateToWhatsapp(this.linkDetail.scope.description.text)
            link = this.linkDetail.scope.link ?? undefined
        } else if (this.robotDetail) {
            description = editorStateToWhatsapp(this.robotDetail.scope.description.text)
        } else if (this.subMenuDetail) {
            description = editorStateToWhatsapp(this.subMenuDetail.scope.description.text)
        } else if (this.informationDetail) {
            description = editorStateToWhatsapp(this.informationDetail.scope.description.text)
            message = editorStateToWhatsapp(this.informationDetail.scope.message.text)
            eventId = this.informationDetail.scope.eventValue ?? undefined
            link = this.informationDetail.scope.link ?? undefined
            negativeButton = this.informationDetail.scope.negativeButton
            positiveButton = this.informationDetail.scope.positiveButton
        }

        switch (menuTypeValue) {
            case 1:
                this.synchronizeState({ ...this.menu, type: ChannelMenuType.EVENT, eventId, description })
                break
            case 2:
                this.synchronizeState({ ...this.menu, type: ChannelMenuType.LINK, link, description })
                break
            case 3:
                this.synchronizeState({ ...this.menu, type: ChannelMenuType.ROBOT, description })
                break
            case 4:
                this.synchronizeState({ ...this.menu, type: ChannelMenuType.SUB_MENU, description })
                break
            case 5:
                this.synchronizeState({
                    ...this.menu,
                    type: ChannelMenuType.INFORMATION,
                    description,
                    eventId,
                    link,
                    message,
                    negativeButton,
                    positiveButton
                })
                break
        }
    }

    @action()
    private async onDelete() {
        this.owner.deleteOption(this)
    }

    isEventIdInUse(eventId: string) {
        if (this.eventDetail) {
            return this.eventDetail.isEventIdInUse(eventId)
        }
        return false
    }

    unsetEventIdIf(eventId: string) {
        this.eventDetail?.unsetEventIdIf(eventId)
        this.informationDetail?.unsetEventIdIf(eventId)
    }

    syncronizeEventOptions() {
        if (this.eventDetail) {
            this.eventDetail.syncronizeEventOptions()
        }
        if (this.informationDetail) {
            this.informationDetail.syncronizeEventOptions()
        }
    }

    extractOption() {
        if (this.eventDetail) {
            return this.eventDetail.extractOption()
        } else if (this.linkDetail) {
            return this.linkDetail.extractOption()
        } else if (this.robotDetail) {
            return this.robotDetail.extractOption()
        } else if (this.subMenuDetail) {
            return this.subMenuDetail.extractOption()
        } else if (this.informationDetail) {
            return this.informationDetail.extractOption()
        }
        return undefined
    }
}

class EventMenuPresenter extends Presenter<ChannelConfigurationMenuEditorFormEventScope> implements IPresenterMenu {
    private initialized = false

    validEventId = new Map<string, string>()

    private menuOptionPresenter: MenuOptionPresenter

    constructor(menuOptionPresenter: MenuOptionPresenter) {
        const owner = menuOptionPresenter.owner

        super(owner, new ChannelConfigurationMenuEditorFormEventScope(), owner.updateManager)

        this.menuOptionPresenter = menuOptionPresenter

        this.syncronizeEventOptions = syncronizeEventOptions.bind(this)
        this.unsetEventIdIf = unsetEventIdIf.bind(this)
        this.onEventChanged = onEventChanged.bind(this)
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.onEventChanged = this.onEventChanged.bind(this)

        this.scope.description.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_EVENT_CAPTION
        this.scope.description.update = this.update
        this.scope.description.onChanged = this.onDescriptionChanged.bind(this)

        // TODO: is this needed?
        this.syncronizeEventOptions()

        this.initialized = true
    }

    public synchronizeState(description: string, eventId?: string) {
        if (!this.initialized) {
            this.initializeState()
        }

        if (eventId !== undefined && this.validEventId.has(eventId)) {
            this.scope.eventValue = eventId
        } else {
            this.scope.eventValue = null
        }

        this.scope.description.text = whatsappToEditorState(description)
    }

    onCompanyToggled() {
        this.syncronizeEventOptions()
    }

    isEventIdInUse(eventId: string) {
        return this.scope.eventValue === eventId
    }

    @action()
    private onDescriptionChanged(value: EditorState) {
        this.scope.description.text = value
    }

    public extractOption(): ChannelMenuEntry {
        return {
            ...this.menuOptionPresenter.menu,
            type: ChannelMenuType.EVENT,
            eventId: this.scope.eventValue ?? undefined,
            description: editorStateToWhatsapp(this.scope.description.text)
        }
    }

    public onBeforeScopeUpdate() {
        const contentState = this.scope.description.text.getCurrentContent()

        if (HtmlUtils.isEmpty(contentState.getPlainText())) {
            this.scope.description.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_CAPTION_STATUS }
        } else {
            this.scope.description.status = null
        }
    }

    unsetEventIdIf: (this: IPresenterMenu, eventId: string) => void
    syncronizeEventOptions: (this: IPresenterMenu) => void
    onEventChanged: (evt: TextChangeEvent) => void
}

class LinkMenuPresenter extends Presenter<ChannelConfigurationMenuEditorFormLinkScope> {
    private initialized = false

    private menuOptionPresenter: MenuOptionPresenter

    constructor(menuOptionPresenter: MenuOptionPresenter) {
        const owner = menuOptionPresenter.owner

        super(owner, new ChannelConfigurationMenuEditorFormLinkScope(), owner.updateManager)

        this.menuOptionPresenter = menuOptionPresenter
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.description.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_LINK_CAPTION
        this.scope.description.update = this.update
        this.scope.description.onChanged = this.onDescriptionChanged.bind(this)
        this.scope.onLinkChanged = this.onLinkChanged.bind(this)
        this.initialized = true
    }

    public synchronizeState(description: string, link: string) {
        if (!this.initialized) {
            this.initializeState()
        }
        this.scope.link = link

        this.scope.description.text = whatsappToEditorState(description)
    }

    @action()
    private async onLinkChanged(evt: TextChangeEvent) {
        this.scope.link = evt.target.value
    }

    @action()
    private async onDescriptionChanged(value: EditorState) {
        this.scope.description.text = value
    }

    public extractOption(): ChannelMenuEntry {
        return {
            ...this.menuOptionPresenter.menu,
            type: ChannelMenuType.LINK,
            link: this.scope.link,
            description: editorStateToWhatsapp(this.scope.description.text)
        }
    }

    public onBeforeScopeUpdate() {
        const contentState = this.scope.description.text.getCurrentContent()

        const link = (this.scope.link ?? '').trim()
        if (link) {
            this.scope.linkError = ''
        } else {
            this.scope.linkError = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_NEEDED_FIELD
        }

        if (HtmlUtils.isEmpty(contentState.getPlainText())) {
            this.scope.description.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_CAPTION_STATUS }
        } else {
            this.scope.description.status = null
        }
    }
}

class RobotMenuPresenter extends Presenter<ChannelConfigurationMenuEditorFormSchedulingRobotScope> {
    private initialized = false

    private menuOptionPresenter: MenuOptionPresenter

    constructor(menuOptionPresenter: MenuOptionPresenter) {
        const owner = menuOptionPresenter.owner

        super(owner, new ChannelConfigurationMenuEditorFormSchedulingRobotScope(), owner.updateManager)

        this.menuOptionPresenter = menuOptionPresenter
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.description.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_ROBOT_CAPTION
        this.scope.description.update = this.update
        this.scope.description.onChanged = this.onDescriptionChanged.bind(this)
        this.initialized = true
    }

    public synchronizeState(description: string) {
        if (!this.initialized) {
            this.initializeState()
        }

        this.scope.description.text = whatsappToEditorState(description)
    }

    private async onDescriptionChanged(value: EditorState) {
        this.scope.description.text = value
    }

    public extractOption(): ChannelMenuEntry {
        return {
            ...this.menuOptionPresenter.menu,
            type: ChannelMenuType.ROBOT,
            description: editorStateToWhatsapp(this.scope.description.text)
        }
    }

    public onBeforeScopeUpdate() {
        const contentState = this.scope.description.text.getCurrentContent()

        if (HtmlUtils.isEmpty(contentState.getPlainText())) {
            this.scope.description.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD }
        } else {
            this.scope.description.status = null
        }
    }
}

class SubMenuPresenter extends Presenter<ChannelConfigurationMenuEditorFormSubMenuScope> {
    private initialized = false

    private menuOptionPresenter: MenuOptionPresenter

    constructor(menuOptionPresenter: MenuOptionPresenter) {
        const owner = menuOptionPresenter.owner

        super(owner, new ChannelConfigurationMenuEditorFormSubMenuScope(), owner.updateManager)

        this.menuOptionPresenter = menuOptionPresenter
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.description.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_ROBOT_CAPTION
        this.scope.description.update = this.update
        this.scope.description.onChanged = this.onDescriptionChanged.bind(this)
        this.initialized = true
    }

    public synchronizeState(description: string) {
        if (!this.initialized) {
            this.initializeState()
        }

        this.scope.description.text = whatsappToEditorState(description)
    }

    private async onDescriptionChanged(value: EditorState) {
        this.scope.description.text = value
    }

    public extractOption(): ChannelMenuEntry {
        return {
            ...this.menuOptionPresenter.menu,
            type: ChannelMenuType.SUB_MENU,
            description: editorStateToWhatsapp(this.scope.description.text)
        }
    }

    public onBeforeScopeUpdate() {
        const contentState = this.scope.description.text.getCurrentContent()

        if (HtmlUtils.isEmpty(contentState.getPlainText())) {
            this.scope.description.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD }
        } else {
            this.scope.description.status = null
        }
    }
}

class InformationMenuPresenter
    extends Presenter<ChannelConfigurationMenuEditorFormInformationScope>
    implements IPresenterMenu
{
    validEventId = new Map<string, string>()

    private initialized = false

    private menuOptionPresenter: MenuOptionPresenter

    constructor(menuOptionPresenter: MenuOptionPresenter) {
        const owner = menuOptionPresenter.owner

        super(owner, new ChannelConfigurationMenuEditorFormInformationScope(), owner.updateManager)

        this.menuOptionPresenter = menuOptionPresenter
        this.syncronizeEventOptions = syncronizeEventOptions.bind(this)
        this.unsetEventIdIf = unsetEventIdIf.bind(this)
        this.onEventChanged = onEventChanged.bind(this)
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get app(): MainPresenter {
        return (super.owner as ChannelConfigurationMenuEditorFormPresenter).app
    }

    public get owner(): ChannelConfigurationMenuEditorFormPresenter {
        return super.owner as ChannelConfigurationMenuEditorFormPresenter
    }

    private initializeState() {
        this.scope.description.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_ROBOT_CAPTION
        this.scope.description.update = this.update
        this.scope.description.onChanged = this.onDescriptionChanged.bind(this)

        this.scope.message.caption = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MESSAGE_INFORMATION_CAPTION
        this.scope.message.update = this.update
        this.scope.message.onChanged = this.onMessageChanged.bind(this)

        this.scope.onLinkChanged = this.onLinkChanged.bind(this)
        this.scope.onLinkValidation = this.onLinkValidation.bind(this)
        this.scope.onEventChanged = this.onEventChanged.bind(this)
        this.scope.onPositiveButton = this.onPositiveButton.bind(this)
        this.scope.onNegativeButton = this.onNegativeButton.bind(this)
        this.scope.onSelectFile = this.onSelectFile.bind(this)

        this.syncronizeEventOptions()

        this.initialized = true
    }

    public synchronizeState(menu: ChannelMenuEntry) {
        if (!this.initialized) this.initializeState()

        if (menu.description) this.scope.description.text = whatsappToEditorState(menu.description)

        if (menu.message) this.scope.message.text = whatsappToEditorState(menu.message)

        if (menu.link) this.scope.link = menu.link

        if (menu.negativeButton) this.scope.negativeButton = menu.negativeButton

        if (menu.positiveButton) this.scope.positiveButton = menu.positiveButton

        if (menu.eventId !== undefined && this.validEventId.has(menu.eventId)) {
            this.scope.eventValue = menu.eventId
        } else {
            this.scope.eventValue = undefined
        }
    }

    onCompanyToggled() {
        this.syncronizeEventOptions()
    }

    public extractOption(): ChannelMenuEntry {
        return {
            ...this.menuOptionPresenter.menu,
            type: ChannelMenuType.INFORMATION,
            description: editorStateToWhatsapp(this.scope.description.text),
            message: editorStateToWhatsapp(this.scope.message.text),
            link: this.scope.link,
            eventId: this.scope.eventValue ?? undefined,
            negativeButton: this.scope.negativeButton,
            positiveButton: this.scope.positiveButton
        }
    }

    public onBeforeScopeUpdate() {
        const descriptionContentState = this.scope.description.text.getCurrentContent()

        this.scope.eventError = !this.scope.eventValue
            ? (this.scope.linkError = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_NEEDED_FIELD)
            : ''

        const link = (this.scope.link ?? '').trim()
        if (link && toURL(link) instanceof Error) {
            this.scope.linkError = texts.CHANNEL_CONFIGURATION_MENU_EDITOR_NEEDED_FIELD
        } else if (link && getUrlType(link) === 'TEXT') {
            const linkSplited = link?.split('.') || []
            this.scope.linkError =
                texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_ERROR +
                    linkSplited[linkSplited.length - 1] || '?'
        } else {
            this.scope.linkError = ''
        }

        if (HtmlUtils.isEmpty(descriptionContentState.getPlainText())) {
            this.scope.description.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD }
        } else {
            this.scope.description.status = null
        }

        const messageContentState = this.scope.message.text.getCurrentContent()

        if (HtmlUtils.isEmpty(messageContentState.getPlainText())) {
            this.scope.message.status = { text: texts.CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD }
        } else {
            this.scope.message.status = null
        }
    }

    @action()
    private onLinkValidation(p: FileList) {
        let error: Error | null = null
        for (const file of p) {
            if (supportedTypes.every((t) => !file.type.toUpperCase().includes(t))) {
                error = new Error(texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_ERROR + file.type)
                break
            }
        }
        if (error) {
            this.alert(
                'error',
                error.message,
                texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_SUPORTED_TYPES + supportedTypes.join(', ')
            )
        }
        return error
    }

    @action()
    private async onSelectFile(f: File) {
        const data = await UploadFileService.singleton().upload(f)
        this.scope.link = data.url
    }

    @action()
    private onPositiveButton(evt: TextChangeEvent) {
        this.scope.positiveButton = evt.target.value
    }

    @action()
    private onNegativeButton(evt: TextChangeEvent) {
        this.scope.negativeButton = evt.target.value
    }

    @action()
    private onDescriptionChanged(value: EditorState) {
        this.scope.description.text = value
    }

    @action()
    private onMessageChanged(value: EditorState) {
        this.scope.message.text = value
    }

    @action()
    private onLinkChanged(evt: TextChangeEvent) {
        this.scope.link = evt.target.value
    }

    public onEventChanged: (evt: TextChangeEvent) => void
    public syncronizeEventOptions: (this: IPresenterMenu) => void
    public unsetEventIdIf: (this: IPresenterMenu, eventId: string) => void
}

interface IPresenterMenu {
    readonly owner: ChannelConfigurationMenuEditorFormPresenter
    readonly validEventId: Map<string, string>
    readonly scope: IEventMenu
    syncronizeEventOptions(this: IPresenterMenu): void
    unsetEventIdIf(this: IPresenterMenu, eventId: string): void
}

function onEventChanged(this: IPresenterMenu, evt: TextChangeEvent) {
    const eventId = evt.target.value

    if (this.validEventId.has(eventId)) {
        this.scope.eventValue = eventId
        this.owner.syncronizeEventOptions()
    } else {
        this.scope.eventValue = null
        this.owner.syncronizeEventOptions()
    }
}

function syncronizeEventOptions(this: IPresenterMenu) {
    this.validEventId.clear()

    const companyMap = this.owner.formPresenter.getSelectedCompanyMap()
    const allEventOptionMap = this.owner.formPresenter.menuPresenter.eventOptionMap
    const selectedCompanyIdArray = this.owner.companySelectorPresenter.extractCompanyIdArray()

    const eventOptionCounterMap = new Map<string, number>()

    for (const companyId of selectedCompanyIdArray) {
        const companyOption = companyMap.get(companyId)
        if (companyOption && companyOption.eventOptions) {
            for (const eventId of companyOption.eventOptions) {
                const eventCount = eventOptionCounterMap.get(eventId) ?? 0
                eventOptionCounterMap.set(eventId, eventCount + 1)
            }
        }
    }

    let i = 0
    for (const [eventId, eventName] of allEventOptionMap.entries()) {
        // Only event present in all companies
        if (eventOptionCounterMap.get(eventId) === selectedCompanyIdArray.length) {
            this.scope.eventOptions.set(i++, {
                id: eventId,
                name: eventName
            })
            this.validEventId.set(eventId, eventName)
        }
    }

    this.scope.eventOptions.length = i

    const eventId = this.scope.eventValue
    if (eventId) {
        if (this.validEventId.has(eventId)) {
            this.scope.eventValue = eventId
        } else {
            this.scope.eventValue = null
        }
    }
}

function unsetEventIdIf(this: IPresenterMenu, eventId: string) {
    if (this.scope.eventValue === eventId) {
        this.scope.eventValue = null
    }
}
