export type SupporteHeaderTypes = 'VIDEO' | 'IMAGE' | 'DOCUMENT' | 'TEXT'

export const VIDEO_SUPPORTED_TYPES = ['.MP4', '.3GPP']
export const IMAGE_SUPPORTED_TYPES = ['.JPG', '.JPEG', '.PNG']
export const DOCUMENT_SUPPORTED_TYPES = ['.PDF']

const checkUrlType = (supportedTypes: string[], url: string) => {
    return supportedTypes.some((type) => url.endsWith(type.toLowerCase()))
}

export const getUrlType = (url: string): SupporteHeaderTypes => {
    if (checkUrlType(VIDEO_SUPPORTED_TYPES, url)) {
        return 'VIDEO'
    }
    if (checkUrlType(IMAGE_SUPPORTED_TYPES, url)) {
        return 'IMAGE'
    }
    if (checkUrlType(DOCUMENT_SUPPORTED_TYPES, url)) {
        return 'DOCUMENT'
    }
    return 'TEXT'
}
