import {
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material'
import React from 'react'
import { ObservableArray } from 'wdc-cube'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import {
    ActiveMessageTotalsScope,
    DashboardActiveMessagesResumedTableScope
} from '../DashboardActiveMessagesResumed.scopes'
import { TextsProvider } from '../texts'
import { ReportHeader } from './components/ReportHeader'
import { getOrMakeResumedActiveMessagesStyles } from './Dashboard.styles'

// @Inject
const texts = TextsProvider.get()

type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

export type DashboardActiveMessagesResumedTableProps = IViewProps & {
    totalSentMessages: number
    scope: DashboardActiveMessagesResumedTableScope
}

export function DashboardActiveMessagesResumedTableView({
    className,
    totalSentMessages,
    scope
}: DashboardActiveMessagesResumedTableProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeResumedActiveMessagesStyles()

    return (
        <Paper className={className}>
            {scope.loading ? <LinearProgress className={styles.customLinearProgress} color="secondary" /> : undefined}
            <div className={styles.box}>
                <ReportHeader
                    title={`${texts.ACTIVE_MESSAGES_PER_USER_TITLE} (${totalSentMessages})`}
                    info={texts.ACTIVE_MESSAGES_PER_USER_DETAILED_LIST}
                    labelExportToXLS={texts.ACTIVE_MESSAGES_PER_USER_EXPORT_TABLE_TO_XLS_FORMAT}
                    showCaption={true}
                    onExportToXLS={scope.onExportToXLS}
                    onMoreInfo={scope.onMoreInfo}
                />
            </div>
            <div>
                <ReportTable
                    labelColumnUser={texts.ACTIVE_MESSAGES_PER_USER_LABEL}
                    labelColumnSent={texts.ACTIVE_MESSAGES_PER_USER_SENT_MESSAGES_LABEL}
                    labelTotal={texts.ACTIVE_MESSAGES_PER_USER_PARTIAL_TOTAL_VALUE_LABEL}
                    records={scope.entries}
                    totalSent={scope.partialSentMessageCount}
                    sticky
                />
            </div>
            <TablePagination
                rowsPerPageOptions={scope.itemsPerPageOptions}
                component="div"
                count={scope.itemCount}
                rowsPerPage={scope.itemsPerPage || 0}
                page={scope.pageIndex}
                onPageChange={scope.onChangePageIndex as unknown as PageChangeEventHandler}
                onRowsPerPageChange={scope.onChangeItemsPerPage}
                labelRowsPerPage={texts.ACTIVE_MESSAGES_PER_USER_ROWS_PER_PAGE}
            />
        </Paper>
    )
}

// :: Component(ReportTable)

export type ReportTableProps = IViewProps & {
    labelColumnUser: string
    labelColumnSent: string
    labelTotal: string
    records: ObservableArray<ActiveMessageTotalsScope>
    totalSent: number
    sticky: boolean
}

export function ReportTable(props: ReportTableProps) {
    const { classes: styles } = getOrMakeResumedActiveMessagesStyles()

    return (
        <TableContainer>
            <Table stickyHeader aria-label="sticky table" className={styles.table}>
                <TableHead className={styles.tableHeader}>
                    <TableRow>
                        <TableCell width="100%">{props.labelColumnUser}</TableCell>
                        <TableCell />
                        <TableCell align="right">{props.labelColumnSent}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.records.map((record, i) => (
                        <ReportTableRow key={i} scope={record} />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="left">
                            <Typography noWrap fontWeight="bold">
                                {props.labelTotal}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <Typography>{props.totalSent}</Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

// :: Component(ReportTableRow)

type ReportTableRowProps = IViewProps & {
    scope: ActiveMessageTotalsScope
}

function ReportTableRow({ scope }: ReportTableRowProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeResumedActiveMessagesStyles()

    return (
        <TableRow className={styles.customRow} onClick={scope.onClick} hover>
            <TableCell>{scope.userName}</TableCell>
            <TableCell />
            <TableCell align="right">{scope.quantity}</TableCell>
        </TableRow>
    )
}
