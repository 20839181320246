import { Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AllItemScope, ItemScope, MultiListingScope } from '../MultiListing.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeStyles } from './MultiListing.styles'

// @Inject
const texts = TextsProvider.get()

// :: Component(MultiListingView)

type MultiListingViewProps = IViewProps & {
    scope: MultiListingScope
}

export function MultiListingView({ className, scope }: MultiListingViewProps) {
    bindUpdate(React, scope)

    return (
        <div>
            <List
                className={className}
                sx={{ bgcolor: 'background.paper', overflow: 'auto' }}
                dense
                component="ul"
                role="list"
            >
                {scope.entries.map((itemScope, index) => (
                    <MyListItem key={index} index={index} scope={itemScope} />
                ))}
            </List>
        </div>
    )
}

// :: Component(MyListItem)

type MyListItemProps = {
    index: number
    scope: ItemScope
}

export const MyListItem = React.forwardRef<HTMLLIElement, MyListItemProps>(function MyListItemRaw(
    { index, scope }: MyListItemProps,
    ref
) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    let description: React.ReactNode
    if (scope instanceof AllItemScope || scope.code === texts.ALL_ITEM_CODE_OPTION) {
        description = (
            <span className={styles.filterAllItem}>
                <span className={styles.filterAllItemDescription}>{scope.description}</span>
                <span className={styles.filterAllItemSubdescription}>({scope.subdescription})</span>
            </span>
        )
    } else {
        description = <span>{scope.description}</span>
    }

    return (
        <div>
            <ListItem ref={ref} sx={{ px: 0, py: 1 }} role="listitem" value={index} onClick={scope.onSelectionToggled}>
                <ListItemIcon>
                    <Checkbox color="secondary" checked={scope.selected} />
                    <ListItemText className={styles.alignListText} primary={description} />
                </ListItemIcon>
            </ListItem>
            {scope.code === texts.ALL_ITEM_CODE_OPTION ? <Divider /> : undefined}
        </div>
    )
})
