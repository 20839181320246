import { convertDateToBeautifulString, convertToPhoneMask } from 'src/utils'
import { ExportToCsv } from 'src/utils/export-to-csv'
import { Logger } from 'wdc-cube'
import { DashboardService } from '../Dashboard.service'
import { TextsProvider } from '../texts'

const LOG = Logger.get('ActiveMessagesExportXLSCommand')

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

export type ActiveMessagesExportXLSCommandArguments = {
    channels: string[]
    startTime: Date
    endTime: Date
    customerId?: string
    alert?: (message: string) => void
}

export class ActiveMessagesExportXLSCommand {
    private args: ActiveMessagesExportXLSCommandArguments

    constructor(args: ActiveMessagesExportXLSCommandArguments) {
        this.args = args
    }

    public async run() {
        const { channels, startTime, endTime, customerId, alert } = this.args

        const recordSet = (
            await dashboardService.fetchActiveMessagesDetailed(channels, 10_000, 0, startTime, endTime, customerId)
        ).entries
        if (recordSet.length === 0) {
            const message = texts.NO_CONTENT_TO_GENERATE_FILE
            if (alert) {
                alert(message)
            } else {
                LOG.info(message)
            }
            return
        }

        const reportData: Record<string, unknown>[] = recordSet.map((record) => ({
            numero_do_atendimento: record.ticketId,
            nome_do_cliente: record.customer.name,
            telefone_do_cliente: convertToPhoneMask(record.customer.phone),
            data_de_envio: convertDateToBeautifulString(record.sendDate),
            mensagem: record.text
        }))

        const csvExporter = new ExportToCsv({
            filename: texts.FILE_NAME + (customerId ? `_${customerId}` : ''),
            fieldSeparator: '||',
            quoteStrings: '"',
            decimalSeparator: ',',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        })

        csvExporter.generateCsv(reportData)
    }
}
