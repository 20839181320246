import { ChipPanelScope } from 'src/components/chippanel'
import { RichEditorScope } from 'src/components/richeditor'
import { BaseEvent, CheckedChangeEvent, KeyPressEvent, TextChangeEvent } from 'src/utils'
import { v4 as uuid } from 'uuid'
import type { AlertSeverity } from 'wdc-cube'
import { Observable, ObservableArray, Scope, observe } from 'wdc-cube'
import { ChannelConfigurationSelfRescueFormScope } from './selfRescue/ChannelConfigurationSelfRescueForm.scopes'

export type { BaseEvent, TextChangeEvent, KeyPressEvent, CheckedChangeEvent, ChipPanelScope, RichEditorScope }

export enum Tabs {
    GENERAL = 0,
    MENU = 1,
    MESSAGES = 2,
    TIMES = 3,
    EXTRAS = 4
}

export interface ServiceBySegmentation {
    on: string
    servicesId: string[]
}

// :: General

@Observable
export class ChannelConfigurationFormCompanyScope extends Scope {
    readonly companyOptions = new ObservableArray<{ id: number; name: string }>(this)
    internalCompany = new ChannelConfigurationInternalCompanyConfigurationFormScope()

    @observe() uid = uuid()
    @observe() position = 1
    @observe() companyValue?: number
    @observe() segmentation = new ChipPanelScope()
    @observe() segmentationVisible = false
    @observe() segmentName = ''
    @observe() focus: 'segment' | '' = ''
    @observe() toggleShowAttendantName = true

    @observe() onToggleShowAttendantName = Scope.SYNC_ACTION
    @observe() onDelete = Scope.ASYNC_ACTION
    @observe() onCompanyChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onSegmentNameKeyDown = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
    @observe() onSegmentNameBlur = Scope.ASYNC_ACTION
    @observe() onSegmentNameChanged = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()

    onOpenCompanyConfigurationEditor = Scope.ASYNC_ACTION_ONE<{ id: number }>()
}

@Observable
export class ChannelConfigurationFormCompaniesScope extends Scope {
    readonly companyEntries = new ObservableArray<ChannelConfigurationFormCompanyScope>(this)

    @observe() uid = uuid()
    @observe() companySegmentation = false
    @observe() blink: 'add-company-button' | '' = ''

    @observe() onAppendCompany = Scope.SYNC_ACTION
    @observe() onCompanySegmentation = Scope.SYNC_ACTION_TWO<CheckedChangeEvent, boolean>()
    @observe() onShowInfo = Scope.SYNC_ACTION
    @observe() onSwapPosition = Scope.SYNC_ACTION_TWO<string, string>()
}

@Observable
export class ChannelConfigurationInternalCompanyConfigurationFormScope extends Scope {
    @observe() id!: number
    @observe() companyName: string | undefined
    @observe() companyReference: string | undefined
    @observe() showAttendantName: boolean | undefined
    @observe() usesPreSaleNotification: boolean | undefined
    @observe() serviceBySegmentation: ServiceBySegmentation[] | undefined
}

@Observable
export class ChannelConfigurationFormGeneralScope extends Scope {
    readonly index = Tabs.GENERAL
    readonly companies = new ChannelConfigurationFormCompaniesScope()
    readonly selfRescueConfig = new ChannelConfigurationSelfRescueFormScope()

    @observe() channelName = ''
    @observe() channelNameError = ''
    @observe() channelPhone = ''
    @observe() focus: 'channel-name' | '' = ''
    @observe() segmentation: string[] = []
    @observe() segmentationChanged: '' = ''

    @observe() onOpenSelfRescueEditor = Scope.ASYNC_ACTION

    onReorderSegmentationChanged = Scope.SYNC_ACTION_ONE<{ destination: number; source: number }>()
    onSegmentationChanged = Scope.SYNC_ACTION
    onChannelNameChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
}

// :: Menu

@Observable
export class ChannelConfigurationFormMenuItemScope extends Scope {
    readonly errors = new ObservableArray<string>(this)

    @observe() id = 0
    @observe() name = ''
    @observe() companies = ''

    @observe() eventCount = 0
    @observe() linkCount = 0
    @observe() robotCount = 0
    @observe() subMenuCount = 0
    @observe() informationCount = 0
    @observe() segmentationCount = 0

    @observe() onEditMenuSegmentation = Scope.ASYNC_ACTION
    @observe() onEdit = Scope.ASYNC_ACTION
    @observe() onDuplicate = Scope.ASYNC_ACTION
    @observe() onDelete = Scope.ASYNC_ACTION
}

@Observable
export class ChannelConfigurationFormMenuScope extends Scope {
    readonly index = Tabs.MENU
    readonly errors = new ObservableArray<string>(this)
    readonly entries = new ObservableArray<ChannelConfigurationFormMenuItemScope>(this)

    @observe() blink: 'add-menu-button' | '' = ''

    @observe() onOpenEditor = Scope.ASYNC_ACTION
}

// :: Messages

@Observable
export class ChannelConfigurationFormMessagesScope extends Scope {
    readonly index = Tabs.MESSAGES
    readonly greetingsMessage = new RichEditorScope()
    readonly companySegmentationMessage = new RichEditorScope()
    readonly companySelectionMessage = new RichEditorScope()
    readonly departmentSelectionMessage = new RichEditorScope()
    readonly newTicketMessage = new RichEditorScope()
    readonly outOfWorkingHoursNewTicketMessage = new RichEditorScope()
    readonly attendenceContinuationMessage = new RichEditorScope()
    readonly identityConfirmationMessage = new RichEditorScope()
    readonly offlineSystemMessage = new RichEditorScope()
    readonly invalidOptionMessage = new RichEditorScope()
}

// :: Working Hours

@Observable
export class ChannelConfigurationFormWorkingHourItemScope extends Scope {
    readonly companyNames = new ObservableArray(this)
    readonly frequencyDescription = new ObservableArray<string>(this)
    readonly workingHoursDescription = new ObservableArray<string>(this)
    readonly warnings = new ObservableArray<string>(this)
    readonly errors = new ObservableArray<string>(this)

    @observe() id = 0

    @observe() onEdit = Scope.ASYNC_ACTION
    @observe() onDelete = Scope.ASYNC_ACTION
}

@Observable
export class ChannelConfigurationFormWorkingHoursScope extends Scope {
    readonly index = Tabs.TIMES
    readonly entries = new ObservableArray<ChannelConfigurationFormWorkingHourItemScope>(this)

    @observe() blink: 'add-working-hours-button' | '' = ''

    @observe() onOpenEditor = Scope.ASYNC_ACTION
}

@Observable
export class ChannelConfigurationFormExtrasScope extends Scope {
    readonly index = Tabs.EXTRAS

    @observe() useGoBack = false

    @observe() onChangeGoBackButton = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
}

export const MAX_TAB_LEN = Object.keys(Tabs).length + 1

// :: Form

@Observable
export class ChannelConfigurationFormScope extends Scope {
    readonly uid = uuid()
    readonly generalTab = new ChannelConfigurationFormGeneralScope()
    readonly menuTab = new ChannelConfigurationFormMenuScope()
    readonly messagesTab = new ChannelConfigurationFormMessagesScope()
    readonly workingHoursTab = new ChannelConfigurationFormWorkingHoursScope()
    readonly extrasTab = new ChannelConfigurationFormExtrasScope()

    @observe() tabIndex = 0
    @observe() draft = false
    @observe() valid: AlertSeverity = 'success'
    @observe() saving = false

    @observe() onTabChange = Scope.ASYNC_ACTION_TWO<BaseEvent, number>()
    @observe() onClose = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
    @observe() onDiscard = Scope.ASYNC_ACTION
    @observe() onPublish = Scope.ASYNC_ACTION
    @observe() onShowErrorsReport = Scope.ASYNC_ACTION
    @observe() onLayoutStart = Scope.ASYNC_ACTION
    @observe() onLayoutEnd = Scope.ASYNC_ACTION
}
