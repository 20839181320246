export interface InfobipCredential {
    url: string
    phone: string
    group: string
    username: string
    password: string
    contractedContacts: number
    name?: string
    hsmPrice: number
    techProviderCredential?: boolean
    editedAt?: Date
    createdAt?: Date
}

export interface GupshupCredential {
    app: string
    appId: string
    channel: string
    apiKey: string
    domain: string
    name?: string
    type: 'WHATSAPP'
    editedAt?: Date
    createdAt?: Date
}

export enum BrokerEnum {
    INFOBIP = 'INFOBIP',
    GUPSHUP = 'GUPSHUP'
}
