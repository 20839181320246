import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

export const getOrMakeKpiCardStyles = makeStyles()((theme: Theme) => ({
    cardWrapper: {
        padding: '3px 0 0 0',
        borderRadius: 8,
        margin: theme.spacing(0, 0, 2, 0),
        background: grey[400]
    },
    card: {
        borderRadius: 4,
        padding: 16,
        color: 'white'
    },
    cardLabel: {
        whiteSpace: 'nowrap',
        fontWeight: 'bold'
    },
    cardValue: {
        whiteSpace: 'nowrap'
    },
    cardInfoIcon: {
        fontSize: 20,
        cursor: 'pointer'
    },
    cardIcon: {
        fontSize: 40,
        position: 'relative',
        left: 2
    },
    adjustText: {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexGrow: 1
    },
    adjustContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0
    },
    adjustOnMoreInfoIcon: {
        flexGrow: 0
    }
}))
