import type { CaughtException, ChannelId, UserContext } from '../common'

export const PATHS = {
    base: 'admin/main'
}

// :: Channels

export type ChannelsRequest = {
    user?: UserContext
}

export type ChannelsResponse = {
    channels: ChannelId[]
}

// :: Partners

export type Partner = {
    accountId: string
    url: string
}

export type PartnersRequest = {
    user?: UserContext
}

export type PartnersResponse = {
    partners: Partner[]
}

// :: Message Broker Topics

export const Topics = {
    channels: 'syo-whatsapp-bus-admin-channels',
    partners: 'syo-whatsapp-bus-admin-partners'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        channels?: ChannelsRequest
        partners?: PartnersRequest
    }

    mutation?: {
        // NOOP
    }
}

export type Response = {
    query?: {
        channels?: ChannelsResponse
        partners?: PartnersResponse
    }

    mutation?: {
        // NOOP
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
