import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()((theme: Theme) => ({
    styleList: {
        display: 'flex',
        overflow: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '0.65rem',
        justifyContent: 'flex-start',
        borderRadius: 4,
        gap: '.5rem',
        maxWidth: '68vw',
        marginTop: 20
    },
    styleLabel: {
        position: 'absolute',
        left: 0,
        minWidth: '10rem',
        transform: 'translateX(20px) translateY(-22px)',
        alignContent: 'center',
        padding: 'reapt(4 5rem)',
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: theme.palette.background.default
    }
}))