import { ChannelConfigurationSelfRescueFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { action, CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID } from 'wdc-cube'
import { MainPresenter } from '../../main'
import { Tabs } from '../ChannelConfigurationForm.scopes'
import { MessageEditorPresenter } from '../messageEditor/MessageEditor.presenter'
import { TextsProvider } from '../texts'
import { ChannelConfigurationSelfRescueFormScope } from './ChannelConfigurationSelfRescueForm.scopes'
import { ChannelConfigurationFormPresenter } from '../ChannelConfigurationForm.presenter'

import type { CheckedChangeEvent, TextChangeEvent } from 'src/utils'
import { AdminChannelConfiguration as NS } from '@whatsapp/communication'

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationSelfRescueFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationSelfRescueFormScope>
    implements IPresenterOwner
{
    private modalSlot = NOOP_VOID as DetachedScopeSlot
    private __formPresenter?: ChannelConfigurationFormPresenter
    private selfRescueId = -1
    private disposed = false
    private rescue = new MessageEditorPresenter(this, this.scope.rescueMessage, '', '')
    private goodbye = new MessageEditorPresenter(this, this.scope.goodbyeMessage, '', '')

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationSelfRescueFormScope())
    }

    public override release() {
        this.disposed = true
        this.modalSlot(this.scope, true)
        super.release()
    }

    public get formPresenter() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.__formPresenter!
    }

    // Cube context syncronization methods: Begin

    // @Override
    public async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChannelConfigurationSelfRescueFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    // @Override
    public publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationSelfRescueFormKeys(intent)
        if (this.selfRescueId > 0) {
            keys.selfRescueId(this.selfRescueId)
        }
    }

    private async initializeState(keys: ChannelConfigurationSelfRescueFormKeys) {
        this.bindListeners()
        this.modalSlot = keys.modalSlot()
        this.__formPresenter = keys.hostPresenter() as ChannelConfigurationFormPresenter

        await this.synchronizeState(keys, true)
    }

    private bindListeners() {
        this.scope.onSelfRescue = this.onSelfRescue.bind(this)
        this.scope.onSelfRescueMinutesChanged = this.onSelfRescueMinutesChanged.bind(this)
        this.scope.onSelfRescueTyped = this.onSelfRescueTyped.bind(this)
        this.scope.onCancel = this.onCancel.bind(this)
        this.scope.onSave = this.onSave.bind(this)

        this.scope.onSelfRescueTypeName = this.onSelfRescueTypeName.bind(this)
        this.scope.onSelfRescueGroup = this.onSelfRescueGroup.bind(this)
        this.scope.onSelfRescueOrigin = this.onSelfRescueOrigin.bind(this)
        this.scope.onSelfRescueMidia = this.onSelfRescueMidia.bind(this)

        this.rescue.bindListeners(texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_RESCUE_CAPTION)
        this.goodbye.bindListeners(texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_GOODBYE_CAPTION)
    }

    private async synchronizeState(keys: ChannelConfigurationSelfRescueFormKeys, force = false) {
        let changed = force || (keys.refresh() ?? false)

        const newSelfRescueId = keys.selfRescueId() ?? this.selfRescueId
        changed = changed || newSelfRescueId !== this.selfRescueId

        if (changed) {
            this.selfRescueId = newSelfRescueId
            await this.refresh()
        }
    }

    private async refresh() {
        const formSelfRescue = this.formPresenter.generalPresenter.getForm()
        const sourceAndMediaEventOptionsForm = this.formPresenter.generalPresenter.owner.sourceAndMediaEventOptionsForm

        this.scope.selfRescuemenuEventOptionsForm = this.syncronizeEventOptions()
        this.scope.selfRescueSourceAndMediaEventOptionsForm = sourceAndMediaEventOptionsForm

        this.scope.active = formSelfRescue.active
        this.scope.robotRescueMinutes = formSelfRescue.robotRescueMinutes

        this.scope.selfRescueType = formSelfRescue.selfRescueType
        this.scope.selfRescueGroup = formSelfRescue.selfRescueGroup
        this.scope.selfRescueOrigin = formSelfRescue.selfRescueOrigin
        this.scope.selfRescueMidia = formSelfRescue.selfRescueMidia
        this.scope.selfRescueTypeName = formSelfRescue.selfRescueTypeName

        this.rescue.synchronizeState(formSelfRescue.rescue)
        this.goodbye.synchronizeState(formSelfRescue.goodbye)
    }

    private async close() {
        if (!this.disposed) {
            const keys = KeysFactory.channelConfigurationForm(this.app)
            keys.tabIndex(Tabs.GENERAL)
            await this.flipToIntent(keys.intent)
        }
    }

    private onSelfRescueRobotMinutes(): boolean {
        return this.scope.robotRescueMinutes > 0
    }

    private onConfirmButtonEnabled() {
        this.scope.confirmButtonEnabled = !this.onSelfRescueRobotMinutes()
    }

    private onSelfRescueMinutesError() {
        this.scope.robotRescueMinutesError = !this.onSelfRescueRobotMinutes()
    }

    private extractForm(): NS.SelfRescueConfig {
        return {
            type: this.scope.selfRescueType === 'ROBOT_RESCUE' ? 'ROBOT_RESCUE' : 'EVENT_RESCUE',
            active: this.scope.active,
            expireIn: this.scope.robotRescueMinutes,
            robotRescueMessages: {
                rescue: this.rescue.getText(),
                goodbye: this.goodbye.getText()
            },
            eventRescueConfiguration: {
                group: this.scope.selfRescueGroup,
                media: this.scope.selfRescueMidia,
                origin: this.scope.selfRescueOrigin,
                type: this.scope.selfRescueTypeName
            }
        }
    }

    private syncronizeEventOptions(): NS.ChannelMenuEventOption[] {
        const eventOptions: NS.ChannelMenuEventOption[] = []
        const companyEventOptions: string[] = []
        const eventOptionCounterMap = new Map<string, number>()

        const companyMap = this.formPresenter.getSelectedCompanyMap()
        const menuEventOptions = this.formPresenter.generalPresenter.owner.menuEventOptionsForm

        for (const company of companyMap.values()) {
            if (company && company.eventOptions) {
                for (const eventId of company.eventOptions) {
                    const eventCount = eventOptionCounterMap.get(eventId) ?? 0
                    eventOptionCounterMap.set(eventId, eventCount + 1)
                    companyEventOptions.push(eventId)
                }
            }
        }

        for (const eventOption of menuEventOptions) {
            if (eventOptionCounterMap.get(eventOption.id) === companyMap.size) {
                eventOptions.push(eventOption)
            }
        }

        return eventOptions
    }

    @action()
    private onSelfRescue(evt: CheckedChangeEvent, value: boolean) {
        this.scope.active = value
    }

    @action()
    private onSelfRescueMinutesChanged(evt: TextChangeEvent) {
        this.scope.robotRescueMinutes = +evt.target.value
        this.onConfirmButtonEnabled()
        this.onSelfRescueMinutesError()
    }

    @action()
    private onSelfRescueTyped(type: string) {
        this.scope.selfRescueType = type
    }

    @action()
    private onSelfRescueGroup(id: string) {
        this.scope.selfRescueGroup = id
    }

    @action()
    private onSelfRescueTypeName(name: string) {
        this.scope.selfRescueTypeName = name
    }

    @action()
    private onSelfRescueOrigin(id: string) {
        this.scope.selfRescueOrigin = id
    }

    @action()
    private onSelfRescueMidia(id: string) {
        this.scope.selfRescueMidia = id
    }

    @action()
    private async onCancel() {
        await this.close()
    }

    @action()
    private async onSave() {
        this.formPresenter.generalPresenter.saveForm(this.extractForm())
        await this.close()
    }
}
