import { RichEditorScope } from 'src/components/richeditor'
import { ChannelConfigurationFormKeys } from 'src/Constants'
import { Presenter } from 'wdc-cube'
import { ChannelMessagesConfig } from './ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import { ChannelConfigurationFormMessagesScope } from './ChannelConfigurationForm.scopes'
import { MessageEditorPresenter } from './messageEditor/MessageEditor.presenter'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationFormMessagesPresenter extends Presenter<
    ChannelConfigurationFormMessagesScope,
    ChannelConfigurationFormPresenter
> {
    private greetings = new MessageEditorPresenter(this, this.scope.greetingsMessage, '', '')
    private companySegmentation = new MessageEditorPresenter(
        this,
        this.scope.companySegmentationMessage,
        'segmentations',
        texts.CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_TAG_NAME
    )
    private companySelection = new MessageEditorPresenter(
        this,
        this.scope.companySelectionMessage,
        'companies',
        texts.CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_TAG_NAME
    )
    private departmentSelection = new MessageEditorPresenter(
        this,
        this.scope.departmentSelectionMessage,
        'departments',
        texts.CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENT_SELECTION_TAG_NAME
    )
    private newTicket = new MessageEditorPresenter(
        this,
        this.scope.newTicketMessage,
        'event',
        texts.CHANNEL_CONFIGURATION_MESSAGES_NEW_TICKET_TAG_NAME
    )
    private outOfWorkingHoursNewTicket = new MessageEditorPresenter(
        this,
        this.scope.outOfWorkingHoursNewTicketMessage,
        'event',
        texts.CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_HOURS_NEW_TICKET_TAG_NAME
    )
    private attendenceContinuation = new MessageEditorPresenter(this, this.scope.attendenceContinuationMessage, '', '')
    private identityConfirmation = new MessageEditorPresenter(this, this.scope.identityConfirmationMessage, '', '')
    private offlineSystem = new MessageEditorPresenter(this, this.scope.offlineSystemMessage, '', '')
    private invalidOption = new MessageEditorPresenter(this, this.scope.invalidOptionMessage, '', '')

    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner, owner.scope.messagesTab, owner.updateManager)
    }

    public focusOnFirstMessageWithErrors() {
        this.owner.scope.tabIndex = this.scope.index

        for (const messagePresenter of [
            this.greetings,
            this.companySegmentation,
            this.companySelection,
            this.departmentSelection,
            this.newTicket,
            this.outOfWorkingHoursNewTicket,
            this.attendenceContinuation,
            this.identityConfirmation,
            this.offlineSystem,
            this.invalidOption
        ]) {
            if (messagePresenter.hasError) {
                messagePresenter.scope.focus = true
                break
            }
        }
    }

    private bindListeners() {
        this.greetings.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_GREETING_CAPTION)
        this.companySegmentation.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_CAPTION)
        this.companySelection.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_CAPTION)
        this.departmentSelection.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENTS_SELECTION_CAPTION)
        this.newTicket.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_GENERATED_EVENT_CAPTION)
        this.outOfWorkingHoursNewTicket.bindListeners(
            texts.CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_NEW_TICKET_CAPTION
        )
        this.attendenceContinuation.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_ATTENDENCE_CONTINUATION_CAPTION)
        this.identityConfirmation.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_IDENTITY_CONFIRMATION_CAPTION)
        this.offlineSystem.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_OFFLINE_SYSTEM_CAPTION)
        this.invalidOption.bindListeners(texts.CHANNEL_CONFIGURATION_MESSAGES_INVALID_OPTION_CAPTION)

        this.updateManager.hint(RichEditorScope, this.scope, 5)
    }

    async initializeState(keys: ChannelConfigurationFormKeys, form: ChannelMessagesConfig) {
        this.bindListeners()
        await this.synchronizeState(keys, form)
    }

    public async synchronizeState(keys: ChannelConfigurationFormKeys, form: ChannelMessagesConfig) {
        this.greetings.synchronizeState(form.greetings)
        this.companySegmentation.synchronizeState(form.companySegmentation)
        this.companySelection.synchronizeState(form.companySelection)
        this.departmentSelection.synchronizeState(form.departmentSelection)
        this.newTicket.synchronizeState(form.newTicket)
        this.outOfWorkingHoursNewTicket.synchronizeState(form.outOfWorkingHoursNewTicket)
        this.attendenceContinuation.synchronizeState(form.attendenceContinuation)
        this.identityConfirmation.synchronizeState(form.identityConfirmation)
        this.offlineSystem.synchronizeState(form.offlineSystem)
        this.invalidOption.synchronizeState(form.invalidOption)
    }

    public beforeUpdate() {
        const hasError =
            this.greetings.hasError ||
            this.companySegmentation.hasError ||
            this.companySelection.hasError ||
            this.departmentSelection.hasError ||
            this.newTicket.hasError ||
            this.outOfWorkingHoursNewTicket.hasError ||
            this.attendenceContinuation.hasError ||
            this.identityConfirmation.hasError ||
            this.offlineSystem.hasError ||
            this.invalidOption.hasError

        this.owner.validator.messageValidation(!hasError)
    }

    public override onBeforeScopeUpdate() {
        this.beforeUpdate()
    }

    public extractFormData(): ChannelMessagesConfig {
        return {
            greetings: this.greetings.getText(),
            companySegmentation: this.companySegmentation.getText(),
            companySelection: this.companySelection.getText(),
            departmentSelection: this.departmentSelection.getText(),
            newTicket: this.newTicket.getText(),
            outOfWorkingHoursNewTicket: this.outOfWorkingHoursNewTicket.getText(),
            attendenceContinuation: this.attendenceContinuation.getText(),
            identityConfirmation: this.identityConfirmation.getText(),
            offlineSystem: this.offlineSystem.getText(),
            invalidOption: this.invalidOption.getText()
        }
    }
}
