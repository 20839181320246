import { ViewFactory } from 'wdc-cube-react'
import { ChatsScope } from '../Chats.scopes'
import { ChatsView } from './Chats.view'

import { ChatsFilterScope } from '../ChatsFilter.scopes'
import { ChatsFilterView } from './ChatsFilter.view'

import { ChatsFormScope } from '../ChatsForm.scopes'
import { ChatsFormView } from './ChatsForm.view'

import { ChatsFormBodyScope } from '../ChatsForm.scopes'
import { ChatsFormBodyView } from './ChatsForm.view'

export function registerViews(rv = ViewFactory.register) {
    rv(ChatsFilterScope, ChatsFilterView)
    rv(ChatsScope, ChatsView)
    rv(ChatsFormScope, ChatsFormView)
    rv(ChatsFormBodyScope, ChatsFormBodyView)
}
