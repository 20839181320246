import { ChipItemScope, ChipPanelScope } from 'src/components/chippanel'
import { ChannelConfigurationListingKeys } from 'src/Constants'
import { action, IPresenterOwner, NOOP_PROMISE_VOID, Presenter } from 'wdc-cube'
import { ChannelConfigurationListingFilterScope, TextChangeEvent } from './ChannelConfigurationListingFilter.scopes'
import { TextsProvider } from './texts'

import type { KeyPressEvent } from './ChannelConfigurationListingFilter.scopes'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationListingFilterState = {
    name: string
    number: string
}

export class ChannelConfigurationListingFilterPresenter extends Presenter<ChannelConfigurationListingFilterScope> {
    public onSearch = NOOP_PROMISE_VOID as (args: ChannelConfigurationListingFilterState) => Promise<void>

    private state: ChannelConfigurationListingFilterState = {
        name: '',
        number: ''
    }

    public constructor(owner: IPresenterOwner) {
        super(owner, new ChannelConfigurationListingFilterScope())
        this.scope.update = this.update
        this.scope.onOpen = this.onOpen.bind(this)
        this.scope.onClose = this.onClose.bind(this)
        this.scope.onClear = this.onClear.bind(this)
        this.scope.onApply = this.onApply.bind(this)
        this.scope.onUpdateChannelName = this.onUpdateChannelName.bind(this)
        this.scope.onUpdateChannelNumber = this.onUpdateChannelNumber.bind(this)
        this.scope.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)
    }

    public cloneState() {
        return { ...this.state }
    }

    public publishParameters(keys: ChannelConfigurationListingKeys): void {
        keys.channelName(this.state.name)
        keys.channelNumber(this.state.number)
    }

    public synchronizeState(keys: ChannelConfigurationListingKeys) {
        let changed = false

        const newChannelName = keys.channelName() ?? this.state.name
        changed = changed || newChannelName !== this.state.name

        const newChannelNumber = keys.channelNumber() ?? this.state.number
        changed = changed || newChannelNumber !== this.state.number

        if (changed) {
            this.scope.opened = false
            this.applyData({ name: newChannelName, number: newChannelNumber })
        }

        return changed
    }

    public applyData(data: ChannelConfigurationListingFilterState) {
        this.state.name = data.name
        this.state.number = data.number
    }

    public close() {
        this.scope.opened = false
    }

    private onUpdateChannelName(evt: TextChangeEvent) {
        this.scope.channelName = evt.target.value
    }

    private onUpdateChannelNumber(evt: TextChangeEvent) {
        const value = evt.target.value
        const newValue = new Array(value.length)
        for (const ch of value) {
            if (ch >= '0' && ch <= '9') {
                newValue.push(ch)
            }
        }
        this.scope.channelNumber = newValue.join('')
    }

    @action()
    private onApplyFilterByPressedKey(evt: KeyPressEvent) {
        if (evt.key === 'Enter') {
            this.scope.onApply()
        }
    }

    @action()
    private async onOpen() {
        this.scope.channelName = this.state.name
        this.scope.channelNumber = this.state.number

        this.scope.opened = true
    }

    @action()
    private async onClose() {
        this.scope.opened = false
    }

    @action()
    private async onClear() {
        const filterArgs = this.cloneState()

        if (this.state.name) {
            filterArgs.name = ''
        }

        if (this.state.number) {
            filterArgs.number = ''
        }

        try {
            this.scope.opened = false
            await this.onSearch(filterArgs)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        }
    }

    @action()
    private async onApply() {
        const request: ChannelConfigurationListingFilterState = {
            name: (this.scope.channelName ?? '').trim(),
            number: (this.scope.channelNumber ?? '').trim()
        }

        try {
            this.scope.filtering = true
            this.scope.opened = false

            await this.onSearch(request)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    public syncChips(filterInfoPanel: ChipPanelScope) {
        let i = 0
        try {
            let chip: ChipItemScope

            const channelName = (this.state.name ?? '').trim()
            if (channelName) {
                chip = filterInfoPanel.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.update = this.update
                    filterInfoPanel.entries.set(i, chip)
                }

                chip.description = `${texts.CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NAME}: ${channelName}`
                chip.onRemove = this.onRemoveChannelNameFilter.bind(this)
                i++
            }

            if (this.state.number) {
                chip = filterInfoPanel.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.update = this.update
                    filterInfoPanel.entries.set(i, chip)
                }

                chip.description = `${texts.CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NUMBER}: "${this.state.number}"`
                chip.onRemove = this.onRemoveChannelNumberFilter.bind(this)
                i++
            }
        } finally {
            filterInfoPanel.entries.length = i
        }
    }

    @action()
    private async onRemoveChannelNameFilter() {
        const filterArgs = this.cloneState()
        filterArgs.name = ''
        await this.onSearch(filterArgs)
    }

    @action()
    private async onRemoveChannelNumberFilter() {
        const filterArgs = this.cloneState()
        filterArgs.number = ''
        await this.onSearch(filterArgs)
    }

    public override onBeforeScopeUpdate() {
        const valid = true

        this.scope.valid = valid
    }
}
