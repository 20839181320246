import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

export const getOrMakeHeaderReportStyles = makeStyles()(() => ({
    moreInfo: {
        fontSize: 20,
        color: grey[400],
        cursor: 'pointer'
    },
    adjustActiveMessagesPerUserTooltip: {
        position: 'relative',
        top: 6,
        marginLeft: 16.5
    },
    content: {
        display: 'flex'
    },
    adjustContent: {
        padding: 8,
        flexGrow: 1
    },
    adjustTooltipInfo: {
        padding: 8,
        flexGrow: 0
    },
    adjustTooltipFile: {
        margin: 2,
        flexGrow: 0
    },
    textColor: {
        color: grey[600]
    }
}))
