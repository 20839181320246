import React from 'react'
import clsx from 'clsx'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import MenuIcon from '@mui/icons-material/Menu'
import { Dialog, Fab } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { bindUpdate, ViewSlot } from 'wdc-cube-react'
import { MainScope } from '../Main.scopes'
import { TextsProvider } from '../texts'
import { Chat } from './Chat.view'
import { getOrMakeMainStyles } from './Main.styles'
import { MainModalDialogView } from './MainModalDialog.view'
import { MainFilterView } from './MainFilter.view'
import { MainInstantAlertView } from './MainInstantAlert.view'

// @Inject
const texts = TextsProvider.get()

// Reference: https://www.materialpalette.com/icons
// Reference: https://mui.com/pt/components/material-icons/

// Read: https://dmitripavlutin.com/dont-overuse-react-usecallback/

export type MainViewProps = {
    className?: string
    scope: MainScope
}

export function MainView({ className, scope }: MainViewProps) {
    bindUpdate(React, scope)

    const { styles, bodyRef, scrollUpRef } = MainViewContext.getOrCreate(scope)

    let content: JSX.Element

    if (scope.authenticated) {
        content = (
            <AppBar position="static" className={styles.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={styles.appBarMenuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={scope.onOpenMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={styles.appBarTitle}>
                        {texts.MENU_TITLE}
                    </Typography>
                    <MainFilterView scope={scope.filter} />
                </Toolbar>
            </AppBar>
        )
    } else if (scope.nonAuthorized) {
        content = (
            <div className={styles.appLoadingContainer}>
                <div>{texts.NON_AUTHORIZED}</div>
            </div>
        )
    } else {
        content = (
            <div className={styles.appLoadingContainer}>
                <div>
                    <div className={styles.appLoading} />
                    <div className={styles.appLoadingText}>{texts.AUTHENTICATING_CONTENT}</div>
                </div>
            </div>
        )
    }

    return (
        <div className={clsx(styles.MainView, className)}>
            {content}

            <div className={styles.Body} ref={bodyRef}>
                <ViewSlot className={styles.BodyMenu} scope={scope.menu} />
                <ViewSlot className={styles.BodyContent} scope={scope.body} />
            </div>
            <div ref={scrollUpRef} className={clsx(styles.fabContainer, styles.fabFadeOut)}>
                <div className={styles.fabContent}>
                    <Fab color="secondary" size="small" aria-label="scroll-to-top" onClick={scope.onScrollTopClicked}>
                        <ArrowUpwardIcon />
                    </Fab>
                </div>
            </div>

            <MainModalDialogView scope={scope.dialog} />

            <Dialog open={!!scope.alert} onClose={scope.alert?.onClose} aria-labelledby="form-dialog-title">
                <ViewSlot scope={scope.alert} />
            </Dialog>

            <Dialog
                open={!!scope.simpleAlert}
                onClose={scope.simpleAlert?.onClose}
                aria-labelledby="simple-dialog-title"
            >
                <ViewSlot scope={scope.simpleAlert} />
            </Dialog>

            <MainInstantAlertView scope={scope.instantAlert} />

            <Chat attributes={scope.intercomAttributes} />
        </div>
    )
}

class MainViewContext {
    static readonly create = () => new MainViewContext()

    static getOrCreate(scope: MainScope) {
        // Pull context from React
        const ctx = React.useMemo(MainViewContext.create, [])
        ctx.scope = scope
        ctx.bodyRef = React.useRef(null)
        ctx.scrollUpRef = React.useRef(null)
        ctx.styles = getOrMakeMainStyles().classes

        scope.scrollTop = ctx.doScrollTop

        // Bind Contextual Events
        React.useEffect(ctx.onUseEffect, [])

        return ctx
    }

    styles!: ReturnType<typeof getOrMakeMainStyles>['classes']
    scope!: MainScope
    bodyRef!: React.MutableRefObject<HTMLDivElement | null>
    scrollUpRef!: React.MutableRefObject<HTMLDivElement | null>

    // :: Private

    private running = true
    private animationHandler = 0

    private launchAnimationStep() {
        if (this.running && this.animationHandler === 0) {
            const animationStep = () => {
                if (this.animationHandler !== 0) {
                    window.cancelAnimationFrame(this.animationHandler)
                    this.animationHandler = 0
                }

                if (this.running) {
                    this.onBodyScrollChanged()
                    this.animationHandler = window.requestAnimationFrame(animationStep)
                }
            }

            this.animationHandler = window.requestAnimationFrame(animationStep)
        }
    }

    readonly doScrollTop = () => {
        const bodyElm = this.bodyRef.current
        if (bodyElm) {
            bodyElm.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    // Events

    readonly onUseEffect: React.EffectCallback = () => {
        this.running = true
        this.launchAnimationStep()

        return () => {
            this.running = false
            if (this.animationHandler !== 0) {
                window.cancelAnimationFrame(this.animationHandler)
                this.animationHandler = 0
            }
        }
    }

    readonly onBodyScrollChanged = () => {
        const scrollUpElm = this.scrollUpRef.current
        if (scrollUpElm) {
            const bodyElm = this.bodyRef.current
            const newClassName = bodyElm && bodyElm.scrollTop === 0 ? this.styles.fabFadeOut : this.styles.fabFadeIn

            if (newClassName !== scrollUpElm.className) {
                scrollUpElm.className = newClassName
            }
        }
    }
}
