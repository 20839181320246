import { lodash } from '@syonet/lang'
import { AutoCompleteField } from 'src/components/autocompletefield/AutoCompleteField.presenter'
import { ChipItemScope, ChipPanelScope } from 'src/components/chippanel'
import { DashboardKeys } from 'src/Constants'
import type { BaseEvent, KeyPressEvent, TextChangeEvent } from 'src/utils'
import voca from 'voca'
import { action, NOOP_PROMISE_VOID, Presenter } from 'wdc-cube'
import { DashboardPresenter } from './Dashboard.presenter'
import {
    CategoryOption,
    CompanyIdAndName,
    CustomerIdAndName,
    CustomerServiceType,
    DashboardService,
    SelectedFilterOptions,
    UserIdAndName
} from './Dashboard.service'
import {
    DashboardCustomerServiceFilterScope,
    NumberOptionType,
    StringOptionType
} from './DashboardCustomerServiceFilter.scopes'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

const ONLY_NUMBERS_REGEXP = /[^0-9]/g

type CategoryComboOption = {
    id: string
    name: string
}

export type DashboardCustomerServiceFilterState = {
    type: CustomerServiceType
    ticketId?: number
    customer?: CustomerIdAndName
    users: UserIdAndName[]
    categories: CategoryComboOption[]
    companies: CompanyIdAndName[]
    durationInDays: number
}

export class DashboardCustomerServiceFilterPresenter extends Presenter<DashboardCustomerServiceFilterScope> {
    public onSearch: (args: DashboardCustomerServiceFilterState) => Promise<void> = NOOP_PROMISE_VOID

    private initialized = false

    private state: DashboardCustomerServiceFilterState = {
        type: CustomerServiceType.ALL,
        users: [],
        categories: [],
        companies: [],
        durationInDays: 0
    }

    private hasFilter = localStorage.getItem('dashboardFilter')
    private filter = this.hasFilter ? JSON.parse(this.hasFilter) : ''

    private customerField = new AutoCompleteField<string>(this, this.scope.customer, this.updateManager)
    private companyField = new AutoCompleteField<number>(this, this.scope.company, this.updateManager)
    private userField = new AutoCompleteField<string>(this, this.scope.user, this.updateManager)
    private categoryField = new AutoCompleteField<string>(this, this.scope.category, this.updateManager)

    public constructor(owner: DashboardPresenter) {
        super(owner, owner.scope.customerServices.table.filterForm)
    }

    public getChannelMap() {
        return this.owner.filter.getChannelMap()
    }

    public override release(): void {
        this.categoryField.release()
        this.userField.release()
        this.companyField.release()
        this.customerField.release()
        super.release()
    }

    public override get owner() {
        return super.owner as DashboardPresenter
    }

    public clearCaches() {
        this.customerField.clearCache()
        this.companyField.clearCache()
        this.userField.clearCache()
        this.categoryField.clearCache()
    }

    public cloneState(): DashboardCustomerServiceFilterState {
        return lodash.cloneDeep(this.state)
    }

    public publishParameters(keys: DashboardKeys) {
        if (this.state.type !== CustomerServiceType.ALL) {
            keys.customerServicesTypeFilter(this.state.type)
        }

        if (this.state.ticketId !== undefined) {
            keys.customerServicesTicketIdFilter(this.state.ticketId)
        }

        if (this.state.customer) {
            keys.customerServicesCustomerFilter(this.state.customer.id)
        }

        if (this.state.categories.length > 0) {
            keys.customerServicesCategoryFilter(this.state.categories.map((v) => String(v.id)))
        }

        if (this.state.companies.length > 0) {
            keys.customerServicesCompaniesFilter(this.state.companies.map((v) => String(v.id)))
        }

        if (this.state.users.length > 0) {
            keys.customerServicesUsersFilter(this.state.users.map((v) => v.id))
        }

        if (this.state.durationInDays > 0) {
            keys.customerServicesDurationFilter(this.state.durationInDays)
        }
    }

    public propagateContext(keys: DashboardKeys) {
        this.publishParameters(keys)
    }

    private async initializeState() {
        this.customerField.onSearch = this.doCustomerSearch.bind(this)
        this.customerField.onGetSelectedValues = (response) => {
            if (this.state.customer) {
                response.set(this.state.customer.id, this.state.customer)
            }
        }

        this.companyField.onSearch = this.doCompanySearch.bind(this)
        this.companyField.onGetSelectedValues = (response) => {
            for (const company of this.state.companies) {
                response.set(company.id, company)
            }
        }

        this.userField.onSearch = this.doUserSearch.bind(this)
        this.userField.onGetSelectedValues = (response) => {
            for (const user of this.state.users) {
                response.set(user.id, user)
            }
        }

        this.categoryField.onSearch = this.doCategorySearch.bind(this)
        this.categoryField.onGetSelectedValues = (response) => {
            for (const category of this.state.categories) {
                response.set(category.id, category)
            }
        }

        this.scope.onTicketChange = this.onChangeTicket.bind(this)
        this.scope.onServiceTypeChange = this.onChangeService.bind(this)
        this.scope.onDurationChange = this.onChangeDuration.bind(this)

        this.scope.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.scope.onOpen = this.onOpen.bind(this)
        this.scope.onClose = this.onClose.bind(this)
        this.scope.onClear = this.onClean.bind(this)
        this.scope.onApply = this.onApply.bind(this)

        this.scope.serviceTypeOptions.set(CustomerServiceType.ALL, {
            id: CustomerServiceType.ALL,
            name: texts.CUSTOMER_SERVICE_FILTER_ALL
        })
        this.scope.serviceTypeOptions.set(CustomerServiceType.HUMAN, {
            id: CustomerServiceType.HUMAN,
            name: texts.CUSTOMER_SERVICE_FILTER_HUMAN
        })
        this.scope.serviceTypeOptions.set(CustomerServiceType.ROBOT, {
            id: CustomerServiceType.ROBOT,
            name: texts.CUSTOMER_SERVICE_FILTER_ROBOT
        })
        this.scope.serviceTypeValue = this.scope.serviceTypeOptions.get(CustomerServiceType.ALL)
    }

    public async synchronizeState(keys: DashboardKeys, force = false) {
        const mutableChanged = { value: false }

        if (!this.initialized) {
            this.initializeState()
            this.initialized = true
            mutableChanged.value = true
            force = true
        }

        const newServiceType = keys.customerServicesTypeFilter() ?? this.state.type
        const newTicketId = keys.customerServicesTicketIdFilter() ?? this.state.ticketId
        const newCustomerId = keys.customerServicesCustomerFilter() ?? this.state.customer?.id
        const newCompanyIds = [] as number[]
        const newCategoryIds = keys.customerServicesCategoryFilter() ?? []
        const newUserIds = keys.customerServicesUsersFilter() ?? []
        const newDurationInDays = Math.max(keys.customerServicesDurationFilter() ?? this.state.durationInDays, 0)

        for (const sCompanyId of keys.customerServicesCompaniesFilter() ?? []) {
            const companyId = Number.parseInt(sCompanyId)
            if (!Number.isNaN(sCompanyId)) {
                newCompanyIds.push(companyId)
            }
        }

        if (newServiceType !== this.state.type || force) {
            if (this.hasFilter) {
                this.state.type = this.filter['type']
                mutableChanged.value = true
            } else {
                this.state.type = newServiceType
                mutableChanged.value = true
            }
        }

        if (newTicketId !== this.state.ticketId || force) {
            if (this.hasFilter) {
                this.state.ticketId = this.filter['ticketId']
                mutableChanged.value = true
            } else {
                this.state.ticketId = Number.isNaN(newTicketId) ? undefined : newTicketId
                mutableChanged.value = true
            }
        }

        if (newCustomerId !== this.state.customer?.id || force) {
            if (this.hasFilter) {
                this.state.customer = this.filter['customer']
            } else {
                this.state.customer = newCustomerId ? { id: newCustomerId, name: '' } : undefined
                mutableChanged.value = true
            }
        }

        if (newDurationInDays !== this.state.durationInDays || force) {
            if (this.hasFilter) {
                this.state.durationInDays = this.filter['durationInDays']
                mutableChanged.value = true
            } else {
                this.state.durationInDays = newDurationInDays
                mutableChanged.value = true
            }
        }

        if (this.hasFilter) {
            this.state.categories = applyCategoryIdsParam(
                this.filter['categories'],
                newCategoryIds,
                force,
                mutableChanged
            )
            this.state.companies = applyCompanyIdsParam(this.filter['companies'], newCompanyIds, force, mutableChanged)
            this.state.users = applyUsersIdParam(this.filter['users'], newUserIds, force, mutableChanged)
        } else {
            this.state.categories = applyCategoryIdsParam(this.state.categories, newCategoryIds, force, mutableChanged)
            this.state.companies = applyCompanyIdsParam(this.state.companies, newCompanyIds, force, mutableChanged)
            this.state.users = applyUsersIdParam(this.state.users, newUserIds, force, mutableChanged)
        }

        return mutableChanged.value
    }

    public applyData(data: DashboardCustomerServiceFilterState, options: SelectedFilterOptions) {
        this.state.type = data.type
        this.state.ticketId = data.ticketId
        this.state.customer = lodash.cloneDeep(data.customer)
        this.state.categories = lodash.cloneDeep(data.categories)
        this.state.users = lodash.cloneDeep(data.users)
        this.state.companies = lodash.cloneDeep(data.companies)
        this.state.durationInDays = data.durationInDays

        this.fillCustomerOptions(options.customerOptions)
        this.fillCompanyOptions(options.companyOptions)
        this.fillCategoryOptions(options.categoryOptions)
        this.fillUserOptions(options.userOptions)
        this.fillDurationOptions(Math.min(options.maxDurationInDays, 100))

        this.owner.updateHistory()
    }

    private fillCustomerOptions(customers?: CustomerIdAndName[]) {
        const map = new Map<string, CustomerIdAndName>()
        if (customers) {
            customers.forEach((customer) => map.set(customer.id, customer))
        }

        if (this.state.customer) {
            this.state.customer = map.get(this.state.customer.id)
        }

        this.customerField.fill(Array.from(map.values()))
    }

    private fillCompanyOptions(companies?: CompanyIdAndName[]) {
        const map = new Map<number, CompanyIdAndName>()
        if (companies) {
            companies.forEach((company) => map.set(company.id, company))
        }

        const newCompanies: CompanyIdAndName[] = []
        for (const selectedCompany of this.state.companies) {
            const foundCompany = map.get(selectedCompany.id)
            if (foundCompany) {
                newCompanies.push(foundCompany)
            }
        }
        this.state.companies = newCompanies

        this.companyField.fill(Array.from(map.values()))
    }

    private fillUserOptions(users?: UserIdAndName[]) {
        const map = new Map<string, UserIdAndName>()
        if (users) {
            users.forEach((user) => map.set(user.id, user))
        }

        const newUsers: UserIdAndName[] = []
        for (const selectedUser of this.state.users) {
            const foundUser = map.get(selectedUser.id)
            if (foundUser) {
                newUsers.push(foundUser)
            }
        }
        this.state.users = newUsers

        this.userField.fill(Array.from(map.values()))
    }

    private fillCategoryOptions(categories?: CategoryOption[]) {
        const map = new Map<string, CategoryComboOption>()
        if (categories) {
            categories.forEach((category) =>
                map.set(category.id, {
                    id: category.id,
                    name: `${category.group} / ${category.name}`
                })
            )
        }

        const newSelectedCategories: CategoryComboOption[] = []
        for (const selectedCategory of this.state.categories) {
            const foundCategoryComboOption = map.get(selectedCategory.id)
            if (foundCategoryComboOption) {
                newSelectedCategories.push(foundCategoryComboOption)
            }
        }
        this.state.categories = newSelectedCategories

        this.categoryField.fill(Array.from(map.values()))
    }

    private fillDurationOptions(maxDurationInDays: number) {
        let i = 0
        for (let day = 1; day <= maxDurationInDays; day++, i++) {
            if (day === 1) {
                this.scope.durationOptions.set(i, {
                    id: 1,
                    name: voca.sprintf(texts.CUSTOMER_SERVICE_FILTER_ONE_DAY, day)
                })
            } else {
                this.scope.durationOptions.set(i, {
                    id: day,
                    name: voca.sprintf(texts.CUSTOMER_SERVICE_FILTER_MORE_THAN_ONE_DAY, day)
                })
            }
        }
        this.scope.durationOptions.length = i
    }

    @action()
    private async onChangeTicket(evt: TextChangeEvent) {
        const newValue = evt.target.value ?? ''

        const onlyNums = newValue.replace(ONLY_NUMBERS_REGEXP, '')
        this.scope.ticketValue = onlyNums
    }

    private async doCustomerSearch(text: string, maxItens: number) {
        const mainFilter = this.owner.filter.cloneState()

        const filteredOptions = await dashboardService.fetchCustomerByName(
            text,
            mainFilter.channels,
            maxItens,
            0,
            mainFilter.startTime,
            mainFilter.endTime
        )
        return filteredOptions
    }

    private async doCompanySearch(text: string, maxItens: number) {
        const mainFilter = this.owner.filter.cloneState()

        const filteredOptions = await dashboardService.fetchCompaniesByName(
            text,
            mainFilter.channels,
            maxItens,
            0,
            mainFilter.startTime,
            mainFilter.endTime
        )
        return filteredOptions
    }

    private async doUserSearch(text: string, maxItens: number) {
        const mainFilter = this.owner.filter.cloneState()

        const filteredOptions = await dashboardService.fetchUserByName(
            text,
            mainFilter.channels,
            maxItens,
            0,
            mainFilter.startTime,
            mainFilter.endTime
        )
        return filteredOptions
    }

    private async doCategorySearch(text: string, maxItens: number) {
        const mainFilter = this.owner.filter.cloneState()

        const filteredOptions = await dashboardService.fetchCategoryByName(
            text,
            mainFilter.channels,
            maxItens,
            0,
            mainFilter.startTime,
            mainFilter.endTime
        )
        return filteredOptions
    }

    @action()
    private async onChangeService(evt: BaseEvent, newValue: NumberOptionType | null) {
        if (newValue !== null && newValue !== undefined) {
            this.scope.serviceTypeValue = newValue
        } else {
            this.scope.serviceTypeValue = null
        }
    }

    @action()
    private async onChangeDuration(evt: BaseEvent, newValue: NumberOptionType | null) {
        if (newValue !== null && newValue !== undefined) {
            this.scope.durationValue = newValue
        } else {
            this.scope.durationValue = null
        }
    }

    @action()
    private onApplyFilterByPressedKey(evt: KeyPressEvent) {
        if (evt.key === 'Enter') {
            this.scope.onApply()
        }
    }

    @action()
    private async onOpen() {
        if (this.state.ticketId !== undefined) {
            this.scope.ticketValue = String(this.state.ticketId)
        } else {
            this.scope.ticketValue = ''
        }
        this.scope.serviceTypeValue = this.scope.serviceTypeOptions.get(this.state.type)

        if (this.state.customer) {
            this.scope.customer.value = { id: this.state.customer.id, name: this.state.customer.name }
        } else {
            this.scope.customer.value = null
        }

        if (this.state.companies.length > 0) {
            const companyValue: NumberOptionType[] = []
            for (const company of this.state.companies) {
                companyValue.push({ id: company.id, name: company.name })
            }
            this.scope.company.value = companyValue
        } else {
            this.scope.company.value = []
        }

        if (this.state.users.length > 0) {
            const userValue: StringOptionType[] = []
            for (const user of this.state.users) {
                userValue.push({ id: user.id, name: user.name })
            }
            this.scope.user.value = userValue
        } else {
            this.scope.user.value = []
        }

        if (this.state.categories.length > 0) {
            const categoryValue: StringOptionType[] = []
            for (const category of this.state.categories) {
                categoryValue.push({ id: category.id, name: category.name })
            }
            this.scope.category.value = categoryValue
        } else {
            this.scope.category.value = []
        }

        if (this.state.durationInDays > 0) {
            this.scope.durationValue = this.scope.durationOptions.get(this.state.durationInDays - 1)
        } else {
            this.scope.durationValue = null
        }

        this.scope.opened = true
    }

    @action()
    private async onClose() {
        this.scope.opened = false
    }

    @action()
    private async onClean() {
        try {
            this.scope.filtering = true
            this.scope.opened = false

            const request = {
                type: CustomerServiceType.ALL,
                users: [],
                categories: [],
                companies: [],
                durationInDays: 0
            }

            this.onSetFilterInLocalStorage(request)
            await this.onSearch(request)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    @action()
    private async onApply() {
        const request: DashboardCustomerServiceFilterState = {
            type: CustomerServiceType.ALL,
            users: [],
            categories: [],
            companies: [],
            durationInDays: 0
        }

        const serviceTypeValue = this.scope.serviceTypeValue
        if (serviceTypeValue) {
            request.type = serviceTypeValue.id
        }

        const ticketValue = this.scope.ticketValue
        if (ticketValue !== '') {
            const numValue = Number.parseInt(ticketValue)
            request.ticketId = Number.isNaN(numValue) ? undefined : numValue
        }

        const customerValue = this.scope.customer.value
        if (customerValue) {
            request.customer = { id: customerValue.id, name: customerValue.name }
        }

        const companyValue = this.scope.company.value
        if (companyValue && companyValue.length > 0) {
            request.companies = companyValue.map((option) => ({ id: option.id, name: option.name }))
        }

        const userValue = this.scope.user.value
        if (userValue && userValue.length > 0) {
            request.users = userValue.map((option) => ({ id: option.id, name: option.name }))
        }

        const categoryValue = this.scope.category.value
        if (categoryValue && categoryValue.length > 0) {
            const exp = /\//g
            request.categories = categoryValue.map((option) => {
                const parts = option.name.split(exp)
                return { id: option.id, name: parts[1], group: parts[0] }
            })
        }

        const durationInDaysValue = this.scope.durationValue
        if (durationInDaysValue) {
            request.durationInDays = durationInDaysValue.id
        }

        try {
            this.scope.filtering = true
            this.scope.opened = false
            this.onSetFilterInLocalStorage(request)
            await this.onSearch(request)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    public syncChips(chipPanelScope: ChipPanelScope) {
        let i = 0
        try {
            let chip: ChipItemScope

            if (this.state.ticketId !== undefined) {
                chip = chipPanelScope.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.defaultColor = true
                    chip.update = this.update
                    chipPanelScope.entries.set(i, chip)
                }

                chip.onRemove = this.onRemoveTicketFilter.bind(this)
                chip.description = `#${this.state.ticketId}`

                i++
            }

            if (this.state.type !== CustomerServiceType.ALL) {
                const typeOption = this.scope.serviceTypeOptions.get(this.state.type)
                if (typeOption) {
                    chip = chipPanelScope.entries.get(i)
                    if (!chip) {
                        chip = new ChipItemScope()
                        chip.defaultColor = true
                        chip.update = this.update
                        chipPanelScope.entries.set(i, chip)
                    }

                    chip.onRemove = this.onRemoveTypeFilter.bind(this)
                    chip.description = `${typeOption.name}`

                    i++
                }
            }

            if (this.state.customer) {
                chip = chipPanelScope.entries.get(i)
                if (!chip) {
                    chip = new ChipItemScope()
                    chip.defaultColor = true
                    chip.update = this.update
                    chipPanelScope.entries.set(i, chip)
                }

                chip.onRemove = this.onRemoveCustomerFilter.bind(this)
                chip.description = `Cliente: ${this.state.customer.name}`

                i++
            }

            if (this.state.companies.length > 0) {
                for (const company of this.state.companies) {
                    chip = chipPanelScope.entries.get(i)
                    if (!chip) {
                        chip = new ChipItemScope()
                        chip.defaultColor = true
                        chip.update = this.update
                        chipPanelScope.entries.set(i, chip)
                    }

                    chip.onRemove = this.onRemoveCompanyFilter.bind(this, company.id)
                    chip.description = company.name

                    i++
                }
            }

            if (this.state.users.length > 0) {
                for (const user of this.state.users) {
                    chip = chipPanelScope.entries.get(i)
                    if (!chip) {
                        chip = new ChipItemScope()
                        chip.defaultColor = true
                        chip.update = this.update
                        chipPanelScope.entries.set(i, chip)
                    }

                    chip.onRemove = this.onRemoveUserFilter.bind(this, user.id)
                    chip.description = user.name

                    i++
                }
            }

            if (this.state.categories.length > 0) {
                for (const category of this.state.categories) {
                    chip = chipPanelScope.entries.get(i)
                    if (!chip) {
                        chip = new ChipItemScope()
                        chip.defaultColor = true
                        chip.update = this.update
                        chipPanelScope.entries.set(i, chip)
                    }

                    chip.onRemove = this.onRemoveCategoryFilter.bind(this, category.id)
                    chip.description = category.name

                    i++
                }
            }

            if (this.state.durationInDays > 0) {
                const option = this.scope.durationOptions.get(this.state.durationInDays - 1)
                if (option) {
                    chip = chipPanelScope.entries.get(i)
                    if (!chip) {
                        chip = new ChipItemScope()
                        chip.defaultColor = true
                        chip.update = this.update
                        chipPanelScope.entries.set(i, chip)
                    }

                    chip.onRemove = this.onRemoveDurationFilter.bind(this)
                    chip.description = `${option.name}`

                    i++
                }
            }
        } finally {
            chipPanelScope.entries.length = i
        }
    }

    @action()
    private async onRemoveTicketFilter() {
        const request = this.cloneState()
        request.ticketId = undefined
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveTypeFilter() {
        const request = this.cloneState()
        request.type = CustomerServiceType.ALL
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveCustomerFilter() {
        const request = this.cloneState()
        request.customer = undefined
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveCompanyFilter(companyId: number) {
        const request = this.cloneState()
        request.companies = request.companies.filter((option) => option.id !== companyId)
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveUserFilter(userId: string) {
        const request = this.cloneState()
        request.users = request.users.filter((option) => option.id !== userId)
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveCategoryFilter(categoryId: string) {
        const request = this.cloneState()
        request.categories = request.categories.filter((option) => option.id !== categoryId)
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private async onRemoveDurationFilter() {
        const request = this.cloneState()
        request.durationInDays = 0
        this.onSetFilterInLocalStorage(request)
        await this.onSearch(request)
    }

    @action()
    private onSetFilterInLocalStorage(request: DashboardCustomerServiceFilterState) {
        localStorage.setItem('dashboardFilter', JSON.stringify(request))
    }
}

// Static functions

function applyCompanyIdsParam(
    oldCompanies: CompanyIdAndName[],
    paramCompanyIds: number[],
    force: boolean,
    mutableChanged: { value: boolean }
): CompanyIdAndName[] {
    if (!force && !mutableChanged.value) {
        const oldCompanyIdMap = new Map<number, boolean>()
        if (oldCompanies.length > 0) {
            for (const company of oldCompanies) {
                oldCompanyIdMap.set(company.id, true)
            }
        }

        if (paramCompanyIds) {
            for (const sComapanyId of paramCompanyIds) {
                oldCompanyIdMap.delete(sComapanyId)
            }
        }
    }

    return oldCompanies
}

function applyUsersIdParam(
    oldUsers: UserIdAndName[],
    paramUserIds: string[],
    force: boolean,
    mutableChanged: { value: boolean }
): UserIdAndName[] {
    if (!force && !mutableChanged.value) {
        const oldUserIdMap = new Map<string, boolean>()
        if (oldUsers.length > 0) {
            for (const company of oldUsers) {
                oldUserIdMap.set(String(company.id), true)
            }
        }

        if (paramUserIds) {
            for (const userId of paramUserIds) {
                oldUserIdMap.delete(userId)
            }
        }
    }

    return oldUsers
}

function applyCategoryIdsParam(
    oldCategories: CategoryComboOption[],
    paramCategoryIds: string[],
    force: boolean,
    mutableChanged: { value: boolean }
) {
    if (!force && !mutableChanged.value) {
        const oldCategoryIdMap = new Map<string, boolean>()
        if (oldCategories.length > 0) {
            for (const company of oldCategories) {
                oldCategoryIdMap.set(String(company.id), true)
            }
        }

        if (paramCategoryIds) {
            for (const userId of paramCategoryIds) {
                oldCategoryIdMap.delete(userId)
            }
        }
    }

    return oldCategories
}
