export function convertDateToBeautifulString(timestamp: number) {
    const date = new Date(timestamp)
    const day = formalizeByZero(date.getDate()).toString()
    const month = formalizeByZero(date.getMonth() + 1).toString()
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
}

function formalizeByZero(value: number) {
    return value <= 9 ? `0${value}` : value
}
