import { texts as Texts_pt_BR } from './Dashboard.texts-pt_BR'
import { Texts as Text_es_ES } from './Dashboard.texts-es_ES'

export type TextsType = typeof Texts_pt_BR

const map = new Map<string, TextsType>()
map.set('es', Text_es_ES)
map.set('es-ES', Text_es_ES)

map.set('pt', Texts_pt_BR)
map.set('pt-BR', Texts_pt_BR)

export const TextsProvider = {
    get(lang = navigator.language) {
        return map.get(lang) ?? map.get(lang.substring(0, 2)) ?? Texts_pt_BR
    }
}
