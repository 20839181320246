import React from 'react'
import clsx from 'clsx'

import { bindUpdate, ViewSlot } from 'wdc-cube-react'
import { FilterScope } from '../Main.scopes'
import { getOrMakeMainStyles } from './Main.styles'

type FilterProps = {
    className?: string
    scope: FilterScope
}

export function MainFilterView({ className, scope }: FilterProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeMainStyles()

    return (
        <div className={clsx(styles.Filter, className)}>
            <ViewSlot scope={scope.content} />
        </div>
    )
}
