import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberIconOutlined from '@mui/icons-material/WarningAmberOutlined'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, IconButton, Link, Tab, Tabs, Tooltip } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { Logger } from 'wdc-cube'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { ChannelConfigurationFormScope } from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'
import { ChannelConfigurationFormExtrasView } from './ChannelConfigurationForm.view-extras'
import { ChannelConfigurationFormGeneralView } from './ChannelConfigurationForm.view-general'
import { ChannelConfigurationFormMenuView } from './ChannelConfigurationForm.view-menu'
import { ChannelConfigurationFormMessagesView } from './ChannelConfigurationForm.view-messages'
import { ChannelConfigurationFormWorkingHoursView } from './ChannelConfigurationForm.view-working-hours'

const LOG = Logger.get('ChannelConfigurationFormView')

// @Inject
const texts = TextsProvider.get()

// ::Component(ChannelConfigurationFormView)

export type ChannelConfigurationFormViewProps = IViewProps & {
    scope: ChannelConfigurationFormScope
}

const inlineStyles = {
    errorIconButton: { color: '#F44336' },
    warningIconButton: { color: '#FF9800' },
    infoIconButton: { color: '#03A9F4' }
}

export function ChannelConfigurationFormView({ className, scope }: ChannelConfigurationFormViewProps) {
    bindUpdate(React, scope)

    const { styles, infoButtonColor } = ChannelConfigurationFormViewContext.getOrCreate(scope)

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustHeader}>
                <div className={styles.adjustHeaderTitle}>
                    <h1 className={styles.customHeaderFont}>{texts.CHANNEL_CONFIGURATION}</h1>
                    {scope.draft ? (
                        <>
                            <span className={styles.draftFont}>(</span>
                            <Link className={clsx(styles.draftFont, styles.draftContent)} onClick={scope.onDiscard}>
                                {texts.CHANNEL_CONFIGURATION_DRAFT}
                            </Link>
                            <span className={styles.draftFont}>)</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {scope.valid !== 'success' ? (
                        <span style={{ marginLeft: 5 }}>
                            <Tooltip
                                title={
                                    scope.valid === 'error'
                                        ? texts.CHANNEL_NOT_CONFIGURED_TOOLTIP
                                        : texts.CHANNEL_WITH_OBSERVATIONS_TOOLTIP
                                }
                                arrow
                            >
                                <IconButton style={infoButtonColor()} onClick={scope.onShowErrorsReport}>
                                    {scope.valid === 'error' ? <WarningAmberIconOutlined /> : <InfoOutlinedIcon />}
                                </IconButton>
                            </Tooltip>
                        </span>
                    ) : undefined}
                </div>
                <div>
                    <ActionButton
                        scope={scope}
                        onClose={scope.onClose}
                        onSave={scope.onSave}
                        onPublish={scope.onPublish}
                    />
                </div>
            </div>

            <div>
                <Divider />
                <Tabs
                    value={scope.tabIndex}
                    onChange={scope.onTabChange}
                    aria-label="tabs"
                    textColor="inherit"
                    className={styles.customLabelTab}
                    TabIndicatorProps={{ style: { background: '#43A047' } }}
                >
                    <Tab
                        label={texts.CHANNEL_CONFIGURATION_FORM_GENERAL_TAB}
                        {...a11yProps(scope.uid, scope.generalTab.index)}
                    />
                    <Tab
                        label={texts.CHANNEL_CONFIGURATION_FORM_MENU_TAB}
                        {...a11yProps(scope.uid, scope.menuTab.index)}
                    />
                    <Tab
                        label={texts.CHANNEL_CONFIGURATION_FORM_MESSAGES_TAB}
                        {...a11yProps(scope.uid, scope.messagesTab.index)}
                    />
                    <Tab
                        label={texts.CHANNEL_CONFIGURATION_FORM_WORKING_HOURS_TAB}
                        {...a11yProps(scope.uid, scope.workingHoursTab.index)}
                    />
                    <Tab
                        label={texts.CHANNEL_CONFIGURATION_FORM_EXTRAS_TAB}
                        {...a11yProps(scope.uid, scope.extrasTab.index)}
                    />
                    {
                        // <Tab label={Texts.CHANNEL_CONFIGURATION_FORM_SCHEDULE_ROBOT_TAB} {...a11yProps(scope.uid, scope.robotScheduleTab.index)} />
                    }
                </Tabs>
                <Divider />
            </div>
            <TabPanel value={scope.tabIndex} index={scope.generalTab.index}>
                <ViewSlot scope={scope.generalTab} view={ChannelConfigurationFormGeneralView} />
            </TabPanel>
            <TabPanel value={scope.tabIndex} index={scope.menuTab.index}>
                <ViewSlot scope={scope.menuTab} view={ChannelConfigurationFormMenuView} />
            </TabPanel>
            <TabPanel value={scope.tabIndex} index={scope.messagesTab.index}>
                <ViewSlot scope={scope.messagesTab} view={ChannelConfigurationFormMessagesView} />
            </TabPanel>
            <TabPanel value={scope.tabIndex} index={scope.workingHoursTab.index}>
                <ViewSlot scope={scope.workingHoursTab} view={ChannelConfigurationFormWorkingHoursView} />
            </TabPanel>
            <TabPanel value={scope.tabIndex} index={scope.extrasTab.index}>
                <ViewSlot scope={scope.extrasTab} view={ChannelConfigurationFormExtrasView} />
            </TabPanel>
        </div>
    )
}

class ChannelConfigurationFormViewContext {
    static readonly create = () => new ChannelConfigurationFormViewContext()

    static getOrCreate(scope: ChannelConfigurationFormScope) {
        // Pull React Context
        const [ctx] = React.useState(ChannelConfigurationFormViewContext.create)
        ctx.scope = scope
        ctx.styles = getOrMakeStyle().classes

        // Bind Contextual Events
        React.useLayoutEffect(ctx.onUseLayoutEffect, [scope])

        return ctx
    }

    styles!: ReturnType<typeof getOrMakeStyle>['classes']
    scope!: ChannelConfigurationFormScope

    readonly infoButtonColor = () => {
        const { scope } = this

        if (scope.valid === 'error') {
            return inlineStyles.errorIconButton
        } else if (scope.valid === 'success') {
            return inlineStyles.warningIconButton
        } else if (scope.valid === 'warning') {
            return inlineStyles.warningIconButton
        } else {
            return inlineStyles.infoIconButton
        }
    }

    readonly onUseLayoutEffect = () => {
        const { scope } = this

        scope.onLayoutStart().catch((caught) => LOG.error('onLayoutStart', caught))

        return () => {
            scope.onLayoutEnd().catch((caught) => LOG.error('onLayoutEnd', caught))
        }
    }
}

// :: Component(TabPanel)

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    const { classes: styles } = getOrMakeStyle()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className={styles.adjustContent}>{children}</div>}
        </div>
    )
}

function a11yProps(uid: string, index: number) {
    return {
        id: `${uid}-tab-${index}`,
        'aria-controls': `{${uid}}-tabpanel-${index}`
    }
}

// :: Component (NestedButton)

type ActionButtonProps = IViewProps & {
    className?: string
    scope: ChannelConfigurationFormScope
    onClose: () => Promise<void>
    onSave: () => Promise<void>
    onPublish: () => Promise<void>
}

export function ActionButton({ className, scope, onClose, onSave, onPublish }: ActionButtonProps) {
    const { classes: styles } = getOrMakeStyle()

    return (
        <div className={clsx(styles.adjustButtons, className)}>
            <div className={styles.customFontColorButton}>
                <Button variant="outlined" className={styles.customDefaultButton} onClick={onClose}>
                    {texts.CHANNEL_CONFIGURATION_FORM_BACK_BUTTON_ACTION}
                </Button>
                <LoadingButton
                    variant="outlined"
                    className={styles.customDefaultButton}
                    loading={scope.saving}
                    onClick={onSave}
                >
                    {texts.CHANNEL_CONFIGURATION_FORM_SAVE_ACTION}
                </LoadingButton>
                <Button variant="contained" color="info" className={styles.customPrimaryButton} onClick={onPublish}>
                    {texts.CHANNEL_CONFIGURATION_FORM_PUBLISH_BUTTON_ACTION}
                </Button>
            </div>
        </div>
    )
}
