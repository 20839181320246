import { castToError } from './error-utils'

export type LoggerLike = {
    log(message: unknown, ...optionalParams: unknown[]): void
    error(message: unknown, ...optionalParams: unknown[]): void
    warn(message: unknown, ...optionalParams: unknown[]): void
    debug?(message: unknown, ...optionalParams: unknown[]): void
    verbose?(message: unknown, ...optionalParams: unknown[]): void
}

export type LoggerLikeExt = Omit<LoggerLike, 'debug' | 'verbose'> & {
    caught(exn: unknown): void
    debug(message: unknown, ...optionalParams: unknown[]): void
    verbose(message: unknown, ...optionalParams: unknown[]): void
}

const NOOP = () => {
    // NOOP
}

export function extendsLogger(logger: LoggerLike): LoggerLikeExt {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loggerAny = logger as any

    loggerAny.caught = (exn: unknown) => {
        const error = castToError(exn)
        logger.error(error.message, error.stack, '')
    }

    loggerAny.debug = logger.debug ?? NOOP
    loggerAny.verbose = logger.verbose ?? NOOP

    return loggerAny
}
