import { ModalScopeAttributes } from 'src/Constants'
import { MouseEvent, TextChangeEvent } from 'src/utils'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

import { KpiValueScope } from './common.scopes'

export { KpiValueScope }
export type { MouseEvent, TextChangeEvent }

@Observable
export class MessageItemScope extends Scope {
    @observe() ticketId = ''
    @observe() customerName = ''
    @observe() customerPhone = ''
    @observe() sendDate = 0
    @observe() text = ''
}

@Observable
export class MessageTableScope extends Scope {
    readonly messages = new ObservableArray<MessageItemScope>(this)

    @observe() itemsPerPageOptions = [10, 25, 50, 75, 100]
    @observe() pageIndex = 0
    @observe() itemsPerPage = 50
    @observe() itemCount = 0

    @observe() onChangePageIndex = Scope.ASYNC_ACTION_TWO<MouseEvent, number>()
    @observe() onChangeItemsPerPage = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
}

@Observable
export class DashboardActiveMessagesDetailedScope extends Scope implements ModalScopeAttributes {
    readonly fullscreen = true
    readonly transition = 'slide-up'
    readonly kpiQuantity = new KpiValueScope()
    readonly messageTable = new MessageTableScope()

    @observe() loading = false
    @observe() userName = ''

    @observe() onExportToXLS = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
}
