import { FlipIntent, NOOP_VOID } from 'wdc-cube'
import { DateFns } from './date-fns'

export const keys = {
    boolParam(me: IntentKeys, name: string) {
        return function (value?: boolean) {
            const old = me.intent.getParameterAsNumber(name)
            if (arguments.length >= 1) {
                if (value) {
                    me.intent.setParameter(name, 1)
                } else {
                    me.intent.setParameter(name, undefined)
                }
            }
            return old === 1
        }
    },

    numberParam(me: IntentKeys, name: string) {
        return function (value?: number | null) {
            const old = me.intent.getParameterAsNumber(name)
            if (arguments.length >= 1) {
                if (value !== undefined && value !== null) {
                    me.intent.setParameter(name, value)
                } else {
                    me.intent.setParameter(name, undefined)
                }
            }
            return old
        }
    },

    stringParam(me: IntentKeys, name: string) {
        return function (value?: string | null) {
            const old = me.intent.getParameterAsString(name)
            if (arguments.length >= 1) {
                if (value !== undefined && value !== null) {
                    me.intent.setParameter(name, value)
                } else {
                    me.intent.setParameter(name, undefined)
                }
            }
            return old
        }
    },

    momentParam(me: IntentKeys, name: string) {
        return function (value?: Date | null) {
            const old = me.intent.getParameterAsString(name)
            if (arguments.length >= 1) {
                if (value !== undefined && value !== null) {
                    me.intent.setParameter(name, DateFns.formatISO(value))
                } else {
                    me.intent.setParameter(name, undefined)
                }
            }
            return DateFns.parseValidISO(old)
        }
    },

    stringArrayParam(me: IntentKeys, name: string) {
        const attr_name = `intent-key-${name}`
        return function (value?: string[]) {
            let old = me.intent.attributes.get(attr_name) as string[] | undefined
            if (old === undefined) {
                const sold = me.intent.getParameterAsString(name)
                if (sold) {
                    old = sold.split(';')
                    me.intent.attributes.set(attr_name, old)
                }
            }

            if (arguments.length >= 1) {
                if (value && value.length > 0) {
                    me.intent.attributes.set(attr_name, value)
                    me.intent.setParameter(name, value.join(';'))
                } else {
                    me.intent.setParameter(name, undefined)
                    me.intent.attributes.delete(attr_name)
                }
            }

            return old
        }
    },

    attr<T>(me: IntentKeys, name: string) {
        return function (value?: T | null) {
            const old = me.intent.attributes.get(name) as T | undefined
            if (arguments.length >= 1) {
                if (value !== undefined && value !== null) {
                    me.intent.attributes.set(name, value)
                } else {
                    me.intent.attributes.delete(name)
                }
            }
            return old
        }
    },

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    attrSlot<T extends (...args: any[]) => void>(me: IntentKeys, name: string) {
        return function (slot?: T | null): T {
            const old = me.intent.attributes.get(name) as T | undefined
            if (arguments.length >= 1) {
                if (slot !== undefined && slot !== null) {
                    me.intent.attributes.set(name, slot)
                } else {
                    me.intent.attributes.delete(name)
                }
            }

            if (old) {
                return old
            } else {
                return NOOP_VOID as T
            }
        }
    }
}

export class IntentKeys {
    readonly intent: FlipIntent

    constructor(intent: FlipIntent) {
        this.intent = intent
    }
}
