import { Strings } from '@syonet/lang'

export function buildTicketToRoomMapper(realm: string): (ticket: number) => string {
    return (ticket) => `${realm}-${ticket}`
}

export function nameOrPhoneAndGroup(data: { name?: string; phone: string; group: string }): string {
    const { name, phone, group } = data

    if (!name) {
        return `${Strings.formatPhoneV2(phone)} (${group})`
    }

    return name
}

export const isDevMode = () => process.env.DEV_MODE === 'true'
