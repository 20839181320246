import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { AlertScope } from '../Main.scopes'
import { getOrMakeAlertStyles } from './Alert.styles'

const SEVERITY_COLORS: Record<string, React.CSSProperties> = {
    success: { color: '#4CAF50' },
    warning: { color: '#FF9800' },
    info: { color: '#03A9F4' },
    error: { color: '#F44336' }
}

const SEVERITY_BACKGROUND: Record<string, React.CSSProperties> = {
    success: { backgroundColor: SEVERITY_COLORS.success.color },
    warning: { backgroundColor: SEVERITY_COLORS.warning.color },
    info: { backgroundColor: SEVERITY_COLORS.info.color },
    error: { backgroundColor: SEVERITY_COLORS.error.color }
}

export type AlertViewProps = IViewProps & {
    scope: AlertScope
}

export function AlertView({ className, scope }: AlertViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeAlertStyles()

    return (
        <div className={clsx(styles.alertPane, className)}>
            {scope.onClose && (
                <div className={styles.adjustCloseIcon}>
                    <IconButton onClick={scope.onClose}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                </div>
            )}
            <DialogTitle
                className={styles.alertBackground}
                style={SEVERITY_BACKGROUND[scope.severity] || SEVERITY_BACKGROUND.info}
            >
                <div className={styles.iconBody}>
                    <div className={styles.backgroundIcon}>
                        {scope.severity === 'success' && (
                            <CheckCircleOutlineIcon className={styles.iconAttributes} style={SEVERITY_COLORS.success} />
                        )}
                        {scope.severity === 'warning' && (
                            <InfoOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.warning} />
                        )}
                        {scope.severity === 'info' && (
                            <InfoOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.info} />
                        )}
                        {scope.severity === 'error' && (
                            <WarningAmberOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.error} />
                        )}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.alertTitle}>
                    <strong>{scope.title}</strong>
                </div>
                <div className={styles.alertContent}>
                    <ViewSlot scope={scope.content} />
                </div>
            </DialogContent>
        </div>
    )
}
