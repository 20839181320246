import { lodash } from '@syonet/lang'
import { MetaHTMLAttributes } from 'react'
import { KeysFactory, ModalScopeAttributes, Places, SessionKeys } from 'src/Constants'
import { buildCube } from 'src/Cube'
import { AxiosProviderService, BaseAdminService, EurekaService, registerServices } from 'src/services'
import { IntentKeys } from 'src/utils'
import {
    action,
    AlertSeverity,
    Application,
    ApplicationPresenter,
    FlipIntent,
    HistoryManager,
    Logger,
    NOOP_PROMISE_VOID,
    NOOP_VOID,
    Place,
    Scope,
    SingletonServices
} from 'wdc-cube'
import {
    AlertMessageContentScope,
    AlertScope,
    MainScope,
    SimpleAlertMessageContentScope,
    SimpleAlertScope
} from './Main.scopes'
import { MainService, Channel } from './Main.service'
import { TextsProvider } from './texts'
import { buildErrorMessage } from './texts/map-errors'

const LOG = Logger.get('MainPresenter')

// @Inject
const texts = TextsProvider.get()

// @Inject
const eurekaService = EurekaService.singleton()

// @Inject
const axiosProviderService = AxiosProviderService.singleton()

// @Inject
const mainService = MainService.singleton()

registerServices()

export interface NeedsCancelChangesPermition {
    cancelChanges(followUpAction: () => Promise<void>): Promise<void>
}

export class MainPresenter extends ApplicationPresenter<MainScope> {
    // :: Instance

    private stopServices = NOOP_PROMISE_VOID

    private loggedUserId?: string

    private modalStack = [] as Scope[]

    private attributeMap = new Map<string, unknown>()

    private __mockServiceEnabled = false

    private __embeded = false

    private lastAuthorization = {
        provider: '',
        token: ''
    }

    // :: Constructor

    public constructor(historyManager: HistoryManager) {
        super(historyManager, new MainScope())
        this.__mockServiceEnabled = isMockServiceEnabled()

        // Important to allow newIntentFromString to work properly
        this.setPlaces(buildCube())
    }

    public release() {
        this.scope.scrollTop = NOOP_VOID
        this.stopServices().catch(NOOP_PROMISE_VOID)
        this.stopServices = NOOP_PROMISE_VOID
        super.release()
    }

    public get isMockServiceEnabled() {
        return this.__mockServiceEnabled
    }

    public get dateFormat() {
        return texts.DATE_FORMAT
    }

    public get date_HH_Format() {
        return texts.DATE_HH_FORMAT
    }

    public get date_HH_MM_Format() {
        return texts.DATE_HH_MM_FORMAT
    }

    public get date_HH_MM_SS_Format() {
        return texts.DATE_HH_MM_SS_FORMAT
    }

    public get embedded() {
        return this.__embeded
    }

    public getAttribute<T>(name: string) {
        const value = this.attributeMap.get(name)
        if (value) {
            return value as T
        }
    }

    public setAttribute(name: string, value: unknown) {
        if (value !== undefined && value !== null) {
            this.attributeMap.set(name, value)
        } else {
            this.attributeMap.delete(name)
        }
    }

    public boot() {
        const action = async () => {
            let intent = this.newIntentFromString(this.historyManager.location)

            if (intent.place === Place.ROOT) {
                intent = intent.redirect(Places.channelConfigurationListing)
            }

            await this.intializeState(new SessionKeys(intent))

            await this.flipToIntent(intent)
        }

        action().catch((error) => LOG.error('On booting', error))
    }

    public async applyParameters(intent: FlipIntent, initialization: boolean, last: boolean): Promise<boolean> {
        if (initialization) {
            return false
        }

        const keys = new SessionKeys(intent)

        let notSincronized = true

        if (!this.scope.authenticated) {
            await this.synchronizeState(keys)
            notSincronized = false

            if (!this.scope.authenticated) {
                return false
            }
        }

        if (last) {
            await this.flipToIntent(intent.redirect(Places.dashboard))
            return false
        }

        if (notSincronized) {
            await this.synchronizeState(keys)
            notSincronized = true
        }

        this.propagateContext(keys)

        return true
    }

    private async intializeState(keys: SessionKeys) {
        try {
            this.bindListeners()

            this.stopServices = await SingletonServices.start()

            const newAuth = pullAuthorization(keys)

            if (newAuth.provider !== 'keycloak') {
                this.__embeded = true
                const reloadUrl = this.historyManager.location
                BaseAdminService.setReloadAction(() => {
                    // Do nothing. This kind of authorization has no mechanism to refresh
                    // authorization token
                    LOG.error(`Authorization failed to ${reloadUrl}`)
                    this.scope.authenticated = false
                    this.scope.nonAuthorized = true
                })
                await axiosProviderService.connect(newAuth.provider, newAuth.token)
            } else {
                await axiosProviderService.connect()

                if (!axiosProviderService.authenticated) {
                    await axiosProviderService.login()

                    if (!axiosProviderService.authenticated) {
                        return
                    }
                }

                newAuth.token = axiosProviderService.authorization || newAuth.token
            }

            const profile = await axiosProviderService.loadUserProfile()
            this.loggedUserId = profile.username

            await this.loadChannels(true)

            this.scope.authenticated = true
            this.scope.nonAuthorized = false

            this.lastAuthorization.provider = newAuth.provider
            this.lastAuthorization.token = newAuth.token

            this.scope.intercomAttributes = await this.getIntercomAttributes()

            LOG.info('Initialized')
        } catch (caught) {
            this.logout()
            this.unexpected('Authentication provider failed', caught)
        }
    }

    private async synchronizeState(keys: SessionKeys) {
        if (keys.hostAuthorization()) {
            const newAuth = pullAuthorization(keys)
            const oldAuth = this.lastAuthorization

            if (newAuth.provider !== 'keycloak' && oldAuth.token !== newAuth.token) {
                // Never fails with this parameters
                await axiosProviderService.connect(newAuth.provider, newAuth.token)
                const profile = await axiosProviderService.loadUserProfile()
                this.loggedUserId = profile.username

                try {
                    await this.loadChannels(true)
                    this.scope.authenticated = true
                    this.scope.nonAuthorized = false
                    this.lastAuthorization.provider = newAuth.provider
                    this.lastAuthorization.token = newAuth.token
                    this.closeAll()
                } catch (caught) {
                    this.logout()
                    this.unexpected('Authentication provider failed', caught)
                }
            }
        }

        keys.hostAuthorization(null)
        keys.hostProvider(null)
    }

    private logout() {
        axiosProviderService.logout().catch((caught) => LOG.error(caught))
        this.lastAuthorization.provider = ''
        this.lastAuthorization.token = ''
        this.scope.authenticated = false
        this.scope.nonAuthorized = true
    }

    private propagateContext(keys: SessionKeys) {
        keys.loggedUserId(this.loggedUserId)
        keys.parentSlot(this.parentSlot)
        keys.filterSlot(this.filterSlot)
        keys.modalSlot(this.modalSlot)
    }

    private bindListeners() {
        this.scope.onOpenMenu = this.onToggleMenuVisiblity.bind(this)
        this.scope.onScrollTopClicked = this.onScrollTopClicked.bind(this)

        this.scope.filter.update = this.update
        this.scope.dialog.update = this.update

        this.scope.instantAlert.update = this.update
        this.scope.instantAlert.onClosed = this.onInstantAlertClosed.bind(this)

        {
            // Configure menu
            const menu = this.scope.menu
            menu.update = this.update
            menu.onClose = this.onCloseMenu.bind(this)
            menu.onDashboard = this.onMenuItemClicked.bind(this, KeysFactory.dashboard)
            menu.onChats = this.onMenuItemClicked.bind(this, KeysFactory.chats)
            menu.onConfigureChannel = this.onMenuItemClicked.bind(this, KeysFactory.channelConfigurationListing)
        }
    }

    private readonly parentSlot = (scope?: Scope | null) => {
        this.scope.body = scope
    }

    private readonly filterSlot = (scope: Scope, remove = false) => {
        if (remove) {
            if (scope === this.scope.filter.content) {
                this.scope.filter.content = null
            }
        } else {
            this.scope.filter.content = scope
        }
    }

    private readonly modalSlot = (contentScope: Scope, remove = false) => {
        let idx = this.modalStack.indexOf(contentScope)

        if (remove) {
            if (idx !== -1 || this.scope.dialog.content === contentScope) {
                this.onModalClosed(contentScope)
            }
        } else if (this.scope.dialog.content !== contentScope) {
            if (idx !== -1) {
                const ilast = this.modalStack.length - 1
                while (idx < ilast) {
                    this.modalStack[idx] = this.modalStack[idx + 1]
                    idx++
                }
                this.modalStack[idx] = contentScope
            } else {
                this.modalStack.push(contentScope)
            }

            const modalProps = contentScope as ModalScopeAttributes

            if (modalProps.fullscreen !== undefined) {
                this.scope.dialog.fullscreen = modalProps.fullscreen
            } else {
                this.scope.dialog.fullscreen = false
            }

            if (modalProps.transition !== undefined) {
                this.scope.dialog.transition = modalProps.transition
            } else {
                this.scope.dialog.transition = 'none'
            }

            this.scope.dialog.onClose = this.onModalClosed.bind(this, contentScope)
            this.scope.dialog.content = contentScope
            this.scope.dialog.open = true
        }
    }

    private async onModalClosed(contentScope?: Scope) {
        this.scope.dialog.open = false
        this.scope.dialog.content = null
        this.scope.dialog.onClose = NOOP_PROMISE_VOID

        if (contentScope) {
            const recordScope = contentScope as unknown as Record<string, unknown>
            const onCloseOrCancel = recordScope.onClose || recordScope.onCancel

            if (recordScope && lodash.isFunction(onCloseOrCancel)) {
                try {
                    await onCloseOrCancel.call(recordScope)
                } catch (caught) {
                    LOG.error(`Closing dialog ${JSON.stringify(contentScope)}`, caught)
                }
            }

            let idx = this.modalStack.indexOf(contentScope)
            if (idx !== -1) {
                const ilast = this.modalStack.length - 1
                while (idx < ilast) {
                    this.modalStack[idx] = this.modalStack[idx + 1]
                    idx++
                }
                this.modalStack.length = idx
            }
        }

        if (this.modalStack.length > 0) {
            const nextContentScope = this.modalStack[this.modalStack.length - 1]

            const modalProps = nextContentScope as ModalScopeAttributes

            if (modalProps.fullscreen !== undefined) {
                this.scope.dialog.fullscreen = modalProps.fullscreen
            } else {
                this.scope.dialog.fullscreen = false
            }

            if (modalProps.transition !== undefined) {
                this.scope.dialog.transition = modalProps.transition
            } else {
                this.scope.dialog.transition = 'none'
            }

            this.scope.dialog.onClose = this.onModalClosed.bind(this, nextContentScope)
            this.scope.dialog.content = nextContentScope
            this.scope.dialog.open = true
        }
    }

    // :: Helper API

    public unexpected(message: string, error: unknown) {
        super.unexpected(message, error)
        const { title, text } = buildErrorMessage(error)
        this.alert('error', title, text)
    }

    public alert(
        severity: AlertSeverity,
        title: string,
        messageOrContent: string | Scope,
        onClose?: () => Promise<void>
    ) {
        const alertScope = new AlertScope()
        alertScope.update = this.update
        alertScope.severity = severity
        alertScope.title = title
        alertScope.onClose = this.onCloseAlert.bind(this, onClose)

        if (lodash.isString(messageOrContent)) {
            const messageContent = new AlertMessageContentScope()
            messageContent.message = messageOrContent
            messageContent.onClose = alertScope.onClose
            messageContent.update = this.update
            alertScope.content = messageContent
        } else {
            alertScope.content = messageOrContent
        }

        this.scope.alert = alertScope

        return () => {
            if (this.scope.alert === alertScope) {
                this.scope.alert = null
            }
        }
    }

    public simpleAlert(title: string, messageOrContent: string | Scope, onClose?: () => Promise<void>) {
        const simpleAlertScope = new SimpleAlertScope()
        simpleAlertScope.update = this.update
        simpleAlertScope.title = title
        simpleAlertScope.onClose = this.onCloseSimpleAlert.bind(this, onClose)

        if (lodash.isString(messageOrContent)) {
            const messageContent = new SimpleAlertMessageContentScope()
            messageContent.message = messageOrContent
            messageContent.onCancel = simpleAlertScope.onClose
            messageContent.update = this.update
            simpleAlertScope.content = messageContent
        } else {
            simpleAlertScope.content = messageOrContent
        }

        this.scope.simpleAlert = simpleAlertScope

        return () => {
            if (this.scope.simpleAlert === simpleAlertScope) {
                this.scope.simpleAlert = null
            }
        }
    }

    public instantAlert(severity: AlertSeverity, message: string) {
        const alertScope = this.scope.instantAlert
        alertScope.severity = severity
        alertScope.message = message

        return () => {
            if (alertScope.message === message) {
                alertScope.message = ''
                alertScope.severity = 'info'
            }
        }
    }

    private closeAll() {
        this.onCloseAlert()

        let place: Place | undefined = this.lastPlace
        while (place && place !== this.rootPlace) {
            const presenter = super.removePresenter(place)
            if (presenter) {
                presenter.release()
            }
            place = place.parent
        }
    }

    @action()
    private async onInstantAlertClosed() {
        const alertScope = this.scope.instantAlert
        alertScope.message = ''
        alertScope.severity = 'info'
    }

    public scrollTop() {
        this.scope.scrollTop()
    }

    // :: View Actions

    @action()
    protected async onCloseAlert(onClose?: () => Promise<void>) {
        this.scope.alert = null
        if (onClose) {
            await onClose()
        }
    }

    @action()
    protected async onCloseSimpleAlert(onClose?: () => Promise<void>) {
        this.scope.simpleAlert = null
        if (onClose) {
            await onClose()
        }
    }

    protected async onScrollTopClicked() {
        this.scope.scrollTop()
    }

    protected async onToggleMenuVisiblity() {
        if (!this.scope.menu.opened) {
            this.scrollTop()
            this.scope.menu.opened = true
        } else {
            this.scope.menu.opened = false
        }
    }

    protected async onCloseMenu() {
        this.scope.menu.opened = false
    }

    @action()
    protected async onMenuItemClicked(cb: (app: Application) => IntentKeys) {
        try {
            const doFlip = async () => {
                await this.flipToIntent(cb(this).intent)
            }

            const channelForm: unknown = this.getPresenter(Places.channelConfigurationForm)
            if (channelForm && (channelForm as NeedsCancelChangesPermition).cancelChanges) {
                await (channelForm as NeedsCancelChangesPermition).cancelChanges(doFlip)
            } else {
                await doFlip()
            }
        } catch (caught) {
            this.scope.menu.opened = true
            throw caught
        }
    }

    // :: Helper API

    public async loadChannels(force = false) {
        type ChannelHolder = {
            channels: Map<string, Channel>
            expiresInMillis: number
        }
        const key = 'cccdbe30-81fb-4522-a72a-a62f00ebc4f3'

        const now = Date.now()

        let holder = force ? undefined : this.getAttribute<ChannelHolder>(key)

        if (!holder || now > holder.expiresInMillis) {
            holder = {
                channels: new Map<string, Channel>(),
                expiresInMillis: now + 5 * 60_000
            }
            const channelArray = await mainService.fetchChannels()

            if (channelArray) {
                for (const credential of channelArray) {
                    holder.channels.set(credential.phone, credential)
                }
            }

            this.setAttribute(key, holder)
        }

        return holder.channels
    }

    public async getChannelPartnerUrl(phone: string) {
        const channelMap = await this.loadChannels()
        const channel = channelMap.get(phone)

        let url = channel?.partner.url
        if (!url && window !== top && top) {
            url = top.location.origin
        }

        return url
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    public async getIntercomAttributes(): Promise<any | undefined> {
        try {
            const attributes = await mainService.fetchIntercomAttributes()

            return attributes
        } catch (e) {
            return undefined
        }
    }
}

function isMockServiceEnabled() {
    const metaElms = document.querySelectorAll('meta[name="syonet:mock-service"]')
    if (metaElms.length > 0) {
        for (const entry of metaElms.entries()) {
            const metaElm_content = (entry[1] as MetaHTMLAttributes<unknown>).content
            if (metaElm_content === 'true') {
                return true
            }
        }
    }
    return false
}

function pullAuthorization(targetKeys: SessionKeys) {
    let token = targetKeys.hostAuthorization() || ''
    if (token && !token.startsWith('Basic ') && !token.startsWith('Bearer')) {
        // Fix token
        token = `Basic ${token}`
    }

    let provider = targetKeys.hostProvider() || ''
    if (!provider) {
        if (eurekaService.usingLocalTunnel || (token && eurekaService.usingLocalhost)) {
            provider = 'syo-whats-app'
        } else {
            provider = 'keycloak'
        }
    }

    if (provider !== 'keycloak' && !token) {
        // Invalid user and password but according to expecte format
        token = 'Basic dXNlcm5hbWU6cGFzc3dvcmQ='
    }

    return { provider, token }
}
