import { HttpError } from '@syonet/lang'
import { SingletonServices } from 'wdc-cube'
import { AxiosProviderService } from './AxiosProviderService'
import { EurekaService } from './EurekaService'
import { BaseAdminService } from './BaseAdminService'
import { UploadFileService } from './UploadFileService'
import { DashboardService } from '../feature/dashboard/Dashboard.service'
import { ChatsService } from '../feature/chats/Chats.service'
import { ChannelConfigurationService } from '../feature/channel/ChannelConfiguration.service'

// READ: https://samnewman.io/patterns/architectural/bff/

export { HttpError, EurekaService, AxiosProviderService, BaseAdminService, UploadFileService, registerServices }

function registerServices() {
    SingletonServices.add(EurekaService.singleton())
    SingletonServices.add(AxiosProviderService.singleton())
    SingletonServices.add(DashboardService.singleton())
    SingletonServices.add(UploadFileService.singleton())
    SingletonServices.add(ChatsService.singleton())
    SingletonServices.add(ChannelConfigurationService.singleton())
}
