import {
    ChannelConfig,
    ChannelExtrasConfig,
    ChannelMenuConfig,
    ChannelMenuType,
    ChannelMessagesConfig,
    ChannelValidationStatus,
    ChannelWorkingHoursConfig,
    GeneralChannelConfig,
    HourMinuteTime,
    PeriodTime
} from '../ChannelConfiguration.communication'

export class ChannelConfigurationValidator {
    public companyMap = new Map<number, string>()

    public errors: string[] = []
    public warnings: string[] = []
    public info: string[] = []

    public status: ChannelValidationStatus = { type: 'success' }

    private selectedCompanyMap = new Map<number, string>()

    public run(data: ChannelConfig) {
        if (data.general) {
            if (data.general.companies?.length > 0) {
                for (const company of data.general.companies) {
                    const companyName = this.companyMap.get(company.companyId)
                    if (companyName) {
                        this.selectedCompanyMap.set(company.companyId, companyName)
                    }
                }
            }

            this.validateGeneralData(data.general)
        } else {
            this.errors.push('Missing general data')
        }

        if (data.menus) {
            this.validateMenuData(data.menus)
        } else {
            this.errors.push('Missing menus data')
        }

        if (data.messages) {
            this.validateMessagesData(data.messages)
        } else {
            this.errors.push('Missing messages data')
        }

        if (data.workingHours) {
            this.validateWorkingHoursData(data.workingHours)
        } else {
            this.errors.push('Missing working hours data')
        }

        if (data.extras) {
            this.validateExtrasData(data.extras)
        } else {
            this.errors.push('Missing extras data')
        }

        if (this.errors.length > 0) {
            this.status.type = 'error'
        } else if (this.warnings.length > 0) {
            this.status.type = 'warning'
        } else {
            this.status.type = 'success'
        }
    }

    private validateGeneralData(data: GeneralChannelConfig) {
        const channelNameStatus: typeof this.status.channelName = {}

        data.channelName = (data.channelName ?? '').trim()
        if (!data.channelName) {
            this.errors.push('Missing channel name')
            channelNameStatus.empty = true
            this.status.channelName = channelNameStatus
        } else if (data.channelName.length === 1) {
            this.errors.push('Invalid channel name')
            channelNameStatus.invalid = true
            this.status.channelName = channelNameStatus
        }

        this.validateCompanies(data.useSegments, data.companies)
    }

    private validateCompanies(useSegments: boolean, data: GeneralChannelConfig['companies']) {
        const companyAssigmentStatus: typeof this.status.companyAssigment = {}
        if (data?.length > 0) {
            const companySegmentationStatus: typeof this.status.companySegmentation = {}

            let hasCompanyNotInformed = 0
            let hasMissingSegments = false

            for (const companyCfg of data) {
                if (companyCfg.companyId ?? -1 < 0) {
                    hasCompanyNotInformed++
                } else if (!this.selectedCompanyMap.has(companyCfg.companyId)) {
                    hasCompanyNotInformed++
                    this.errors.push(`Companies: companyId(${companyCfg.companyId}) not found`)
                }

                if (useSegments) {
                    if (companyCfg.segments.length > 0) {
                        for (let i = 0; i < companyCfg.segments.length; i++) {
                            const segmentName = (companyCfg.segments[i] ?? '').trim()
                            if (!segmentName) {
                                hasMissingSegments = true
                            }

                            companyCfg.segments[i] = segmentName
                        }
                    } else {
                        hasMissingSegments = true
                    }
                }
            }

            if (hasCompanyNotInformed === 0) {
                this.errors.push('companies: No company was assigned')
                companyAssigmentStatus.noCompanyAssigned = true
                this.status.companyAssigment = companyAssigmentStatus
            } else if (hasCompanyNotInformed < this.selectedCompanyMap.size) {
                this.info.push(
                    `companies: there ${hasCompanyNotInformed == 1 ? 'is' : 'are'
                    } ${hasCompanyNotInformed} not assinged`
                )
                companyAssigmentStatus.notAllCompanyWereAssigned = true
                this.status.companyAssigment = companyAssigmentStatus
            }

            if (hasMissingSegments) {
                this.warnings.push('companies: Missing segments in some companies')
                companySegmentationStatus.missingSome = true
                this.status.companySegmentation = companySegmentationStatus
            }
        } else {
            this.errors.push('companies: No company was assigned')
            companyAssigmentStatus.noCompanyAssigned = true
            this.status.companyAssigment = companyAssigmentStatus
        }
    }

    private validateMenuData(data: ChannelMenuConfig) {
        const menuStatus: typeof this.status.menuDefinition = {}

        if (data.items?.length > 0) {
            const usedCompanies = new Map<number, boolean>()

            for (const item of data.items) {
                if (item.companies.length > 0) {
                    for (const companyId of item.companies) {
                        if (this.selectedCompanyMap.has(companyId)) {
                            if (usedCompanies.has(companyId)) {
                                this.errors.push(`Menu(${item.id}): duplicated company on different menu. ${companyId}`)
                                menuStatus.hasDuplicatedCompanies = true
                                this.status.menuDefinition = menuStatus
                            } else {
                                usedCompanies.set(companyId, true)
                            }
                        } else {
                            this.errors.push(`Menu(${item.id}): non valid companyId for this channel. ${companyId}`)
                            menuStatus.hasNonValidCompanies = true
                            this.status.menuDefinition = menuStatus
                        }
                    }
                }

                if (item.entries?.length > 0) {
                    const usedEventId = new Map<string, boolean>()

                    for (const entry of item.entries) {
                        entry.description = (entry.description ?? '').trim()

                        let menuShouldValidateDescription = false
                        if (entry.type === ChannelMenuType.EVENT) {
                            if (entry.eventId === undefined || entry.eventId === null) {
                                this.errors.push(`Menu(${item.id}): eventId is required`)
                                menuStatus.hasMissingEventId = true
                                this.status.menuDefinition = menuStatus
                            } else {
                                usedEventId.set(entry.eventId, true)
                            }

                            menuShouldValidateDescription = true
                        } else if (entry.type === ChannelMenuType.LINK) {
                            entry.link = (entry.link ?? '').trim()
                            if (!entry.link) {
                                this.errors.push(`Menu(${item.id}): link is required`)
                                menuStatus.hasMissingLink = true
                                this.status.menuDefinition = menuStatus
                            }
                            menuShouldValidateDescription = true
                        } else if (entry.type === ChannelMenuType.ROBOT) {
                            menuShouldValidateDescription = true
                        } else if (entry.type === ChannelMenuType.SUB_MENU) {
                            menuShouldValidateDescription = true
                        } else if (entry.type === ChannelMenuType.INFORMATION) {
                            menuShouldValidateDescription = true
                            const errors = []
                            if (!entry.positiveButton) errors.push('positive button')
                            if (!entry.negativeButton) errors.push('negative button')
                            if (!entry.message) errors.push('message')
                            if (errors.length > 0) {
                                const message = `Menu(${item.id}): type(${entry.type
                                    }) have the following problems: ${errors.join(', ')}`
                                this.errors.push(message)
                                menuStatus.hasInvalidMenuType = true
                                this.status.menuDefinition = menuStatus
                            }
                        } else {
                            this.errors.push(`Menu(${item.id}): type(${entry.type}) not known`)
                            menuStatus.hasInvalidMenuType = true
                            this.status.menuDefinition = menuStatus
                        }

                        if (menuShouldValidateDescription && !entry.description) {
                            this.errors.push(`Menu(${item.id}): description is required`)
                            menuStatus.hasMissingDescription = true
                            this.status.menuDefinition = menuStatus
                        }
                    }
                } else {
                    this.errors.push(`Menu(${item.id}): empty`)
                    menuStatus.hasEmptyMenu = true
                    this.status.menuDefinition = menuStatus
                }
            }

            if (usedCompanies.size < this.selectedCompanyMap.size) {
                this.errors.push('Menu: Not all companies where used')
                menuStatus.hasCompanyNotUsed = true
                this.status.menuDefinition = menuStatus
            }
        } else {
            this.errors.push('Menu: No menu informed')
            menuStatus.noMenuDefined = true
            this.status.menuDefinition = menuStatus
        }
    }

    private validateMessagesData(data: ChannelMessagesConfig) {
        const messageStatus: typeof this.status.messageValidation = {}

        const checkMessage = (fieldName: keyof ChannelMessagesConfig, tag: string) => {
            const message = (data[fieldName] = (data[fieldName] ?? '').trim())
            if (message) {
                if (tag && !message.includes(tag)) {
                    this.errors.push(`${fieldName} message requires at least on tag(${tag}) entry`)
                    messageStatus.hasMissingTags = true
                    this.status.messageValidation = messageStatus
                }
            } else {
                this.errors.push(`${fieldName} message is required`)
                messageStatus.hasEmptyMessages = true
                this.status.messageValidation = messageStatus
            }
        }

        checkMessage('greetings', '')
        checkMessage('companySegmentation', '{{segmentations}}')
        checkMessage('companySelection', '{{companies}}')
        checkMessage('departmentSelection', '{{departments}}')
        checkMessage('newTicket', '{{event}}')
        checkMessage('outOfWorkingHoursNewTicket', '{{event}}')
        checkMessage('attendenceContinuation', '')
        checkMessage('identityConfirmation', '')
        checkMessage('offlineSystem', '')
        checkMessage('invalidOption', '')
    }

    private validateWorkingHoursData(data: ChannelWorkingHoursConfig) {
        const workingHourStatus: typeof this.status.workingHourValidation = {}

        if (data.entries?.length > 0) {
            let lastMomentInMinutes = 0

            const timeToMomentInMinutes = (value: HourMinuteTime) => {
                return value.h * 60 + value.m
            }

            const validatePeriod = (id: number, name: string, period: PeriodTime) => {
                if (period.startTime) {
                    let nonOk = false
                    if (period.startTime.h < 0 || period.startTime.h > 24) {
                        this.errors.push(`Working hours ${id}: ${name} - invalid startTime:hour(${period.startTime.h})`)
                        nonOk = true
                        workingHourStatus.hasInvalidTime = true
                        this.status.workingHourValidation = workingHourStatus
                    }

                    if (period.startTime.m < 0 || period.startTime.m >= 60) {
                        this.errors.push(
                            `Working hours ${id}: ${name} - invalid startTime:minute(${period.startTime.m})`
                        )
                        nonOk = true
                        workingHourStatus.hasInvalidTime = true
                        this.status.workingHourValidation = workingHourStatus
                    }

                    if (!nonOk) {
                        const nextMomentInMinutes = timeToMomentInMinutes(period.startTime)
                        if (lastMomentInMinutes > nextMomentInMinutes) {
                            this.errors.push(`Working hours ${id}: ${name} - overlaped times caused by startTime)`)
                            workingHourStatus.hasOverlapedTime = true
                            this.status.workingHourValidation = workingHourStatus
                        }
                        lastMomentInMinutes = nextMomentInMinutes
                    }
                } else {
                    this.errors.push(`Working hours ${id}: ${name} - missing startTime`)
                    workingHourStatus.hasEmptyTime = true
                    this.status.workingHourValidation = workingHourStatus
                }

                if (period.endTime) {
                    let nonOk = false
                    if (period.endTime.h < 0 || period.endTime.h > 24) {
                        this.errors.push(`Working hours ${id}: ${name} - invalid endTime:hour(${period.endTime.h})`)
                        nonOk = true
                        workingHourStatus.hasInvalidTime = true
                        this.status.workingHourValidation = workingHourStatus
                    }

                    if (period.endTime.m < 0 || period.endTime.m >= 60) {
                        this.errors.push(`Working hours ${id}: ${name} - invalid endTime:minute(${period.endTime.m})`)
                        nonOk = true
                        workingHourStatus.hasInvalidTime = true
                        this.status.workingHourValidation = workingHourStatus
                    }

                    if (!nonOk) {
                        const nextMomentInMinutes = timeToMomentInMinutes(period.endTime)
                        if (lastMomentInMinutes > nextMomentInMinutes) {
                            this.errors.push(`Working hours ${id}: ${name} - overlaped times caused by endTime)`)
                            workingHourStatus.hasOverlapedTime = true
                            this.status.workingHourValidation = workingHourStatus
                        }
                        lastMomentInMinutes = nextMomentInMinutes
                    }
                } else {
                    this.errors.push(`Working hours ${id}: ${name} - missing endTime`)
                    workingHourStatus.hasEmptyTime = true
                    this.status.workingHourValidation = workingHourStatus
                }
            }

            const validatePeriodEntries = (id: number, name: string, periods?: PeriodTime[]) => {
                lastMomentInMinutes = 0
                if (periods && periods.length > 0) {
                    const period0 = periods[0]
                    const period1 = periods[1]

                    if (period0) {
                        validatePeriod(id, `${name}.period0`, period0)
                    } else {
                        this.errors.push(`Working hours ${id}: missing period(0)`)
                        workingHourStatus.hasPeriodInconsistencies = true
                        this.status.workingHourValidation = workingHourStatus
                    }

                    if (period1) {
                        validatePeriod(id, `${name}.period1`, period1)
                    }

                    return true
                } else {
                    return false
                }
            }

            const usedCompanyMap = new Map<number, boolean>()
            for (const entry of data.entries) {
                if (entry.companies?.length > 0) {
                    for (const companyId of entry.companies) {
                        if (usedCompanyMap.has(companyId)) {
                            this.errors.push(`Working hours ${entry.id}: duplicated companyId(${companyId})`)
                            workingHourStatus.hasDuplicatedCompanyUsage = true
                            this.status.workingHourValidation = workingHourStatus
                        } else {
                            usedCompanyMap.set(companyId, true)
                        }
                    }
                } else {
                    this.errors.push(`Working hours ${entry.id}: no content provided`)
                }

                let numOfDayWithWorkingHours = 0

                if (entry.week) {
                    if (validatePeriodEntries(entry.id, 'sunday', entry.week.sunday)) {
                        numOfDayWithWorkingHours++
                    }

                    if (validatePeriodEntries(entry.id, 'monday', entry.week.monday)) {
                        numOfDayWithWorkingHours++
                    }

                    if (validatePeriodEntries(entry.id, 'tuesday', entry.week.tuesday)) {
                        numOfDayWithWorkingHours++
                    }

                    if (validatePeriodEntries(entry.id, 'wednesday', entry.week.wednesday)) {
                        numOfDayWithWorkingHours++
                    }

                    if (validatePeriodEntries(entry.id, 'friday', entry.week.friday)) {
                        numOfDayWithWorkingHours++
                    }

                    if (validatePeriodEntries(entry.id, 'saturday', entry.week.saturday)) {
                        numOfDayWithWorkingHours++
                    }
                }

                if (numOfDayWithWorkingHours === 0) {
                    this.warnings.push(`Working hours ${entry.id}: missing week configuration`)
                    workingHourStatus.hasDataInconsistencies = true
                    this.status.workingHourValidation = workingHourStatus
                }
            }

            if (usedCompanyMap.size < this.selectedCompanyMap.size) {
                this.warnings.push('Some companies using 24 hours as working hours')
                workingHourStatus.hasCompaniesUsing24Hours = true
                this.status.workingHourValidation = workingHourStatus
            }
        } else {
            this.warnings.push('No working hours were defined')
            workingHourStatus.hasNoWorkingHoursDefinition = true
            this.status.workingHourValidation = workingHourStatus
        }
    }

    private validateExtrasData(data: ChannelExtrasConfig) {
        const extraStatus: typeof this.status.extraValidation = {}

        if (data.useGoBack !== undefined) {
            extraStatus.hasUseGoBack = true
            this.status.extraValidation = extraStatus
        } else {
            this.errors.push(`${data.useGoBack} useGoBack is required`)
            extraStatus.hasUseGoBack = false
            this.status.extraValidation = extraStatus
        }
    }
}
