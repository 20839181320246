import { MouseEvent, TextChangeEvent } from 'src/utils'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import { KpiValueScope } from './common.scopes'

export { KpiValueScope }
export type { MouseEvent, TextChangeEvent }

@Observable
export class ActiveMessageTotalsScope extends Scope {
    @observe() userId = ''
    @observe() userName = ''
    @observe() quantity = 0

    @observe() onClick = Scope.ASYNC_ACTION
}

@Observable
export class DashboardActiveMessagesResumedTableScope extends Scope {
    readonly entries = new ObservableArray<ActiveMessageTotalsScope>(this)

    @observe() loading = true
    @observe() itemsPerPageOptions = [10, 25]
    @observe() pageIndex = 0
    @observe() itemsPerPage = 10
    @observe() itemCount = 0
    @observe() partialSentMessageCount = 0

    @observe() onExportToXLS = Scope.ASYNC_ACTION
    @observe() onMoreInfo = Scope.ASYNC_ACTION
    @observe() onChangePageIndex = Scope.ASYNC_ACTION_TWO<MouseEvent, number>()
    @observe() onChangeItemsPerPage = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
}

@Observable
export class DashboardActiveMessagesResumedScope extends Scope {
    readonly table = new DashboardActiveMessagesResumedTableScope()

    @observe() quantityOfSentMessages = 0
}
