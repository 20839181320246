export const PATHS = {
    base: 'admin/attachment'
}

export const Topics = {
    upload: `${PATHS.base}/upload`
}

export type Request = {
    username: string
    domain: string
}

export type Response = {
    url: string
}
