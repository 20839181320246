import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()((theme) => ({
    view: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        listStyle: 'none',
        padding: 0,
        columnGap: 6
    },
    chipPrimary: {
        color: theme.palette.primary.light
    },
    chipSecundary: {
        color: 'white',
        backgroundColor: theme.palette.grey[700],
        '& .MuiChip-deleteIcon': {
            color: 'rgba(255, 255, 255, 0.70)'
        },
        '& .MuiChip-deleteIcon:hover': {
            color: 'rgba(255, 255, 255, 0.40)'
        }
    }
}))
