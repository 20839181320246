import { keyframes } from '@emotion/react'
import { makeStyles } from 'tss-react/mui'

const load = keyframes`
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
`

export const getOrMakeWaitingStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 50
    },
    appLoading: {
        border: '16px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '16px solid #43A047',
        width: 100,
        height: 100,
        animation: `${load} 2s linear infinite`
    },
    appLoadingText: {
        marginTop: 15,
        fontSize: 16,
        letterSpacing: 0.15
    }
}))
