import { DashboardActiveMessagesKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { DateFns } from 'src/utils'
import { action, CubePresenter, FlipIntent, Logger, NOOP_PROMISE_VOID, NOOP_VOID } from 'wdc-cube'
import { MainPresenter } from '../main'
import { ActiveMessagesExportXLSCommand } from './commands/ActiveMessagesDetailedExportXLSCommand'
import {
    DashboardService,
    DetailedActiveMessageDTO,
    PagingDTO,
    ResumedActiveMessagesContent
} from './Dashboard.service'
import {
    DashboardActiveMessagesDetailedScope,
    MessageItemScope,
    MouseEvent,
    TextChangeEvent
} from './DashboardActiveMessagesDetailed.scopes'
import { TextsProvider } from './texts'

const LOG = Logger.get('DashboardActiveMessagesDetailedPresenter')

const texts = TextsProvider.get()

const dashboardService = DashboardService.singleton()

export class DashboardActiveMessagesDetailedPresenter extends CubePresenter<
    MainPresenter,
    DashboardActiveMessagesDetailedScope
> {
    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private loggedUserId = ''
    private userId = ''
    private startTime = DateFns.startOfMonth(Date.now())
    private endTime = DateFns.endOfMonth(Date.now())
    private channels = [] as string[]

    public constructor(app: MainPresenter) {
        super(app, new DashboardActiveMessagesDetailedScope())
    }

    public override release() {
        this.scope.onClose = NOOP_PROMISE_VOID
        this.modalSlot(this.scope, true)
        super.release()
    }

    // Cube context syncronization methods :: Begin

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new DashboardActiveMessagesKeys(intent)

        if (initialization) {
            this.modalSlot(this.scope)
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new DashboardActiveMessagesKeys(intent)
        keys.userId(this.userId)
    }

    // Cube context syncronization methods :: End

    private async initializeState(keys: DashboardActiveMessagesKeys) {
        this.bindListeners()
        this.modalSlot = keys.modalSlot()

        const selectedItem = keys.selectedItem()
        const userId = selectedItem ? selectedItem.userId : keys.userId()
        const loggedUserId = keys.loggedUserId()
        const startTime = keys.startTime()
        const endTime = keys.endTime()

        if (!userId || !loggedUserId || !startTime || !endTime) {
            throw new Error(texts.INVALID_CONTEXT_ARGUMENT_ERROR)
        }

        this.loggedUserId = loggedUserId
        this.startTime = startTime
        this.endTime = endTime
        this.userId = userId
        this.channels = keys.channels() ?? []

        this.refresh().catch(LOG.error)
    }

    private async synchronizeState(keys: DashboardActiveMessagesKeys) {
        let changed = false

        const selectedItem = keys.selectedItem()

        const newLoggedUserId = keys.loggedUserId() ?? this.loggedUserId
        if (this.loggedUserId !== newLoggedUserId) {
            this.loggedUserId = newLoggedUserId
            changed = true
        }

        const newStartTime = keys.startTime() ?? this.startTime
        if (!DateFns.isEqual(this.startTime, newStartTime)) {
            this.startTime = newStartTime
            changed = true
        }

        const newEndTime = keys.endTime() ?? this.endTime
        if (!DateFns.isEqual(this.endTime, newEndTime)) {
            this.endTime = newEndTime
            changed = true
        }

        const newUserId = (selectedItem ? selectedItem.userId : keys.userId()) ?? this.userId
        if (this.userId !== newUserId) {
            this.userId = newUserId
            changed = true
        }

        const newChannel = JSON.stringify(keys.channels())
        if (JSON.stringify(this.channels) !== newChannel) {
            this.channels = keys.channels() ?? []
            changed = true
        }

        if (changed) {
            await this.refresh()
        }
    }

    private bindListeners() {
        this.scope.onExportToXLS = this.onExportToXLS.bind(this)
        this.scope.onClose = this.onClose.bind(this)
        this.scope.kpiQuantity.update = this.update

        {
            const messageTableScope = this.scope.messageTable
            messageTableScope.update = this.update
            messageTableScope.onChangeItemsPerPage = this.onMessagesChangeItemsPerPage.bind(this)
            messageTableScope.onChangePageIndex = this.onMessagesChangePageIndex.bind(this)
        }

        this.updateManager.hint(MessageItemScope, this.scope, 10)
    }

    protected async refresh() {
        this.scope.loading = true
        try {
            const request: ResumedActiveMessagesContent = {
                channels: this.channels,
                startTime: this.startTime,
                endTime: this.endTime,
                user: this.userId,
                itemsPerPage: this.scope.messageTable.itemsPerPage > 0 ? this.scope.messageTable.itemsPerPage : 50
            }

            if (this.scope.messageTable.pageIndex > 0) {
                request.pageIndex = 0
            }

            const response = await dashboardService.fetchCustomerActiveMessagesContent(request)

            const message = response.activeMessagesResumed?.entries[0]
            if (message) {
                this.scope.userName = message.user.name
                this.scope.kpiQuantity.value = message.sentMessages
            } else {
                throw new Error(`${texts.NO_DATA_TO_USER_ERROR} ${this.userId}`)
            }

            if (response.activeMessagesDetailed) {
                await this.refreshMessages({ data: response.activeMessagesDetailed })
            } else {
                this.scope.messageTable.pageIndex = 0
                this.scope.messageTable.itemCount = 0
                this.scope.messageTable.messages.length = 0
            }
        } finally {
            this.scope.loading = false
        }
    }

    private async refreshMessages(request?: {
        pageIndex?: number
        itemsPerPage?: number
        hasRoom?: boolean
        data?: PagingDTO<DetailedActiveMessageDTO>
    }) {
        const messageTableScope = this.scope.messageTable

        const itemsPerPageOptions = messageTableScope.itemsPerPageOptions
        const minItemsPerPage = itemsPerPageOptions[0]
        const maxItemsPerPage = itemsPerPageOptions[itemsPerPageOptions.length - 1]

        const itemsPerPage = request?.itemsPerPage ?? messageTableScope.itemsPerPage
        const pageIndex = request?.pageIndex ?? messageTableScope.pageIndex

        const { entries, meta } =
            request?.data ??
            (await dashboardService.fetchActiveMessagesDetailed(
                this.channels,
                Math.min(Math.max(itemsPerPage, minItemsPerPage), maxItemsPerPage),
                Math.max(pageIndex, 0),
                this.startTime,
                this.endTime,
                this.userId
            ))

        let index = 0
        for (const item of entries) {
            let itemScope = messageTableScope.messages.get(index)
            if (!itemScope) {
                itemScope = new MessageItemScope()
                itemScope.update = this.scope.update
            }

            itemScope.ticketId = item.ticketId
            itemScope.customerName = item.customer.name
            itemScope.customerPhone = item.customer.phone
            itemScope.sendDate = item.sendDate
            itemScope.text = item.text

            messageTableScope.messages.set(index++, itemScope)
        }

        messageTableScope.messages.length = index
        messageTableScope.itemsPerPage = meta.itemsPerPage
        messageTableScope.pageIndex = meta.currentPage
        messageTableScope.itemCount = meta.totalItems
    }

    @action()
    protected async onClose() {
        if (!this.isReleasing) {
            const targetKeys = KeysFactory.dashboard(this.app)
            await this.flipToIntent(targetKeys.intent)
        }
    }

    @action()
    protected async onExportToXLS() {
        const cmd = new ActiveMessagesExportXLSCommand({
            channels: this.channels,
            startTime: this.startTime,
            endTime: this.endTime,
            customerId: this.userId,
            alert: this.alert.bind(this, 'info', texts.ON_ACTIVE_MESSAGES_EXPORT_TO_XLS_ALERT_TITLE)
        })

        await cmd.run()
        LOG.debug('onExportToXLS')
    }

    @action()
    protected async onMessagesChangePageIndex(evt: MouseEvent, pageIndex: number) {
        await this.refreshMessages({
            pageIndex,
            itemsPerPage: this.scope.messageTable.itemsPerPage
        })

        this.updateHistory()
    }

    @action()
    protected async onMessagesChangeItemsPerPage(evt: TextChangeEvent) {
        await this.refreshMessages({
            pageIndex: 0,
            itemsPerPage: evt.target.value ? +evt.target.value : this.scope.messageTable.itemsPerPage
        })

        this.updateHistory()
    }
}
