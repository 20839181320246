import { StringCompare } from '@syonet/lang'
import { AdminChannelConfiguration as NS } from '@whatsapp/communication'
import { ChannelConfigurationFormKeys, ChannelErrorLocation, KeysFactory } from 'src/Constants'
import {
    action,
    AlertSeverity,
    CubePresenter,
    FlipIntent,
    IPresenterOwner,
    Logger,
    NOOP_VOID,
    ScopeSlot
} from 'wdc-cube'
import { WaitingScope } from '../dashboard/Dashboard.scopes'
import { AlertHolder, MainPresenter, NeedsCancelChangesPermition } from '../main'
import {
    ChannelCompanyOption,
    ChannelConfig,
    ChannelConfigResponse,
    ChannelConfigurationService,
    ChannelConfigurationValidator,
    ChannelMenuEventOption
} from './ChannelConfiguration.service'
import { AlertWarningConfirmContentScope } from './ChannelConfigurationAlert.scopes'
import { ChannelConfigurationFormExtrasPresenter } from './ChannelConfigurationForm.presenter-extras'
import { ChannelConfigurationFormGeneralPresenter } from './ChannelConfigurationForm.presenter-general'
import { ChannelConfigurationFormMenuPresenter } from './ChannelConfigurationForm.presenter-menu'
import { ChannelConfigurationFormMessagesPresenter } from './ChannelConfigurationForm.presenter-messages'
import { ChannelConfigurationFormWorkingHoursPresenter } from './ChannelConfigurationForm.presenter-working-hours'
import type { BaseEvent } from './ChannelConfigurationForm.scopes'
import { ChannelConfigurationFormScope, MAX_TAB_LEN } from './ChannelConfigurationForm.scopes'
import { ChannelConfigurationFormValidator } from './ChannelConfigurationForm.validator'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

const channelConfigurationService = ChannelConfigurationService.singleton()

const LOG = Logger.get('ChannelConfigurationFormPresenter')

type ChannelMenuConfig = NS.ChannelMenuConfig
type ChannelMenuEntry = NS.ChannelMenuEntry
type ChannelSourceAndMediaEventOption = NS.ChannelSourceAndMediaEventOption

export class ChannelConfigurationFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationFormScope>
    implements IPresenterOwner, NeedsCancelChangesPermition
{
    private parentSlot = NOOP_VOID as ScopeSlot

    private __channelId = ''

    private __companyMap = new Map<number, ChannelCompanyOption>()
    private __menus = new Map<number, ChannelMenuConfig>()
    private __menuMap = new Map<number, ChannelMenuEntry[]>()
    private __eventOptionMap = new Map<string, string>()
    private __menuEventOptionsForm = [] as ChannelMenuEventOption[]
    private __sourceAndMediaEventOptionsForm = [] as ChannelSourceAndMediaEventOption[]

    public readonly alertHolder = new AlertHolder(this.app)
    public readonly generalPresenter = new ChannelConfigurationFormGeneralPresenter(this)
    public readonly menuPresenter = new ChannelConfigurationFormMenuPresenter(this)
    public readonly messagesPresenter = new ChannelConfigurationFormMessagesPresenter(this)
    public readonly workingHoursPresenter = new ChannelConfigurationFormWorkingHoursPresenter(this)
    public readonly extrasPresenter = new ChannelConfigurationFormExtrasPresenter(this)

    public readonly validator = new ChannelConfigurationFormValidator(this)

    private layoutPendingAction = NOOP_VOID

    private __oldData?: ChannelConfig

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationFormScope())
    }

    public override release() {
        this.layoutPendingAction = NOOP_VOID

        this.alertHolder.release()

        this.extrasPresenter.release()
        this.workingHoursPresenter.release()
        this.messagesPresenter.release()
        this.menuPresenter.release()
        this.generalPresenter.release()
        this.validator.release()
        super.release()
    }

    public get channelId() {
        return this.__channelId
    }

    public getCompanyMap() {
        return this.__companyMap as Readonly<Map<number, ChannelCompanyOption>>
    }

    public getSelectedCompanyMap(force = false) {
        return this.generalPresenter.getSelectedCompanyMap(force)
    }

    public getMenuMap() {
        return this.__menus as Readonly<Map<number, ChannelMenuConfig>>
    }

    public getMenuOptions() {
        return this.__menuMap as Readonly<Map<number, ChannelMenuEntry[]>>
    }

    public get eventOptionMap() {
        return this.__eventOptionMap as Readonly<Map<string, string>>
    }

    public get menuEventOptionsForm() {
        return this.__menuEventOptionsForm
    }

    public get sourceAndMediaEventOptionsForm() {
        return this.__sourceAndMediaEventOptionsForm
    }

    public isCompanyBeingUsed(companyId: number): boolean {
        if (this.menuPresenter.isCompanyBeingUsed(companyId)) {
            return true
        }

        if (this.workingHoursPresenter.isCompanyBeingUsed(companyId)) {
            return true
        }
        return false
    }

    // Cube context syncronization methods: Begin

    public override async applyParameters(
        intent: FlipIntent,
        initialization: boolean,
        last?: boolean
    ): Promise<boolean> {
        const keys = new ChannelConfigurationFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        if (!last) {
            keys.hostPresenter(this)
            keys.channelId(this.__channelId)
        }

        this.parentSlot(this.scope)

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationFormKeys(intent)
        keys.channelId(this.__channelId)
        keys.tabIndex(this.scope.tabIndex > 0 ? this.scope.tabIndex : undefined)
    }

    private async initializeState(keys: ChannelConfigurationFormKeys) {
        this.bindListeners()
        this.parentSlot = keys.parentSlot() ?? NOOP_VOID

        const waiting = new WaitingScope()
        waiting.text = texts.LOADING_CONTENT_DESCRIPTION
        waiting.update = this.update
        this.parentSlot(waiting)

        this.validator.initialize({
            closeAction: () => this.alertHolder.closeAlert(),
            publishAction: this.doPublish.bind(this)
        })

        await this.synchronizeState(keys, true, true)

        const errorLocation = keys.showError()
        if (errorLocation) {
            const lastLayoutPendingAction = this.layoutPendingAction
            this.layoutPendingAction = () => {
                lastLayoutPendingAction()
                try {
                    this.gotoErrorLocation(errorLocation)
                } catch (caught) {
                    LOG.error(`gotoErrorLocation(${errorLocation})`, caught)
                }
            }
        }
    }

    private async synchronizeState(keys: ChannelConfigurationFormKeys, force = false, initialization = false) {
        let changed = force || (keys.refresh() ?? false)

        const newChannelId = keys.channelId() ?? this.__channelId
        changed = changed || newChannelId !== this.__channelId

        if (changed) {
            if (!newChannelId) {
                throw new Error('ChannelId is a required argument')
            }

            const form = await channelConfigurationService.fetchForm(newChannelId)
            this.__channelId = newChannelId
            await this.refresh(keys, form, initialization)
        }

        // Just affect what is been seen
        const newTabIndex = Math.min(Math.max(keys.tabIndex() ?? 0, 0), MAX_TAB_LEN - 1)
        if (newTabIndex !== this.scope.tabIndex) {
            this.scope.tabIndex = newTabIndex
        }
    }

    private bindListeners() {
        this.scope.onTabChange = this.onTabChange.bind(this)
        this.scope.onClose = this.onCancel.bind(this)
        this.scope.onSave = this.onSave.bind(this)
        this.scope.onDiscard = this.onDiscard.bind(this)
        this.scope.onPublish = this.onPublish.bind(this)
        this.scope.onShowErrorsReport = this.onShowErrorsReport.bind(this)
        this.scope.onLayoutStart = this.onLayoutStart.bind(this)
        this.scope.onLayoutEnd = this.onLayoutEnd.bind(this)
    }

    private async refresh(keys: ChannelConfigurationFormKeys, form: ChannelConfigResponse, initialization: boolean) {
        this.__companyMap.clear()
        this.__menuMap.clear()
        this.__menus.clear()

        this.scope.draft = form.draft

        if (form.companyOptions && form.companyOptions.length > 0) {
            form.companyOptions.sort((a, b) => StringCompare(a.name, b.name))

            for (const companyInfo of form.companyOptions) {
                this.__companyMap.set(companyInfo.id, companyInfo)
            }
        }

        if (form.menus && form.menus.items.length > 0) {
            for (const menuInfo of form.menus.items) {
                this.__menus.set(menuInfo.id, form.menus)
                this.__menuMap.set(menuInfo.id, menuInfo.entries)
            }
        }

        this.__menuEventOptionsForm = form.menuEventOptions
        this.__sourceAndMediaEventOptionsForm = form.sourceAndMediaEventOptions

        if (form.menuEventOptions && form.menuEventOptions.length > 0) {
            form.menuEventOptions.sort((a, b) => StringCompare(a.caption, b.caption))

            for (const menuEventOption of form.menuEventOptions) {
                this.__eventOptionMap.set(menuEventOption.id, menuEventOption.caption)
            }
        }

        if (initialization) {
            this.generalPresenter.initializeState(keys, form.general)
            this.menuPresenter.initializeState(keys, form.menus)
            this.messagesPresenter.initializeState(keys, form.messages)
            this.workingHoursPresenter.initializeState(keys, form.workingHours)
            this.extrasPresenter.initializeState(keys, form.extras)
        } else {
            this.generalPresenter.synchronizeState(keys, form.general)
            this.menuPresenter.synchronizeState(form.menus)
            this.messagesPresenter.synchronizeState(keys, form.messages)
            this.workingHoursPresenter.synchronizeState(keys, form.workingHours)
            this.extrasPresenter.synchronizeState(keys, form.extras)
        }


        this.__oldData = this.extractFormData()
    }

    @action()
    private async onLayoutStart() {
        this.layoutPendingAction()
        this.layoutPendingAction = NOOP_VOID
    }

    @action()
    private async onLayoutEnd() {
        LOG.debug('onLayoutEnd')
    }

    @action()
    private async onTabChange(evt: BaseEvent, tabIndex: number) {
        if (tabIndex >= 0 && tabIndex < MAX_TAB_LEN) {
            this.scope.tabIndex = tabIndex
            this.updateHistory()
        }
    }

    public async cancelChanges(followUpAction: () => Promise<void>) {
        const oldValue = JSON.stringify(this.__oldData)
        const newValue = JSON.stringify(this.extractFormData())
        if (oldValue !== newValue) {
            const confirmScope = new AlertWarningConfirmContentScope()
            confirmScope.message = texts.CANCEL_EDITION_OPTION_MESSAGE
            confirmScope.cancelLabelOption = texts.CANCEL_EDITION_OPTION_NO
            confirmScope.confirmLabelOption = texts.CANCEL_EDITION_OPTION_YES
            confirmScope.unfocusConfirmButton = true

            confirmScope.onConfirm = async () => {
                try {
                    this.alertHolder.closeAlert()
                    await followUpAction()
                } finally {
                    this.alertHolder.closeAlert()
                }
            }

            confirmScope.onCancel = async () => {
                this.alertHolder.closeAlert()
            }

            confirmScope.update = this.update

            this.alertHolder.alert(
                'warning',
                texts.CANCEL_EDITION_OPTION_ALERT_TITLE,
                confirmScope,
                confirmScope.onCancel
            )
        } else {
            await followUpAction()
        }
    }

    @action()
    private async onCancel() {
        await this.cancelChanges(async () => {
            await this.close()
        })
    }

    @action()
    private async onSave() {
        this.scope.saving = true
        try {
            await this.doSave(false)
        } finally {
            this.scope.saving = false
        }
    }

    @action()
    private async onDiscard() {
        const confirmScope = new AlertWarningConfirmContentScope()
        confirmScope.message = texts.DISCARD_EDITION_OPTION_MESSAGE
        confirmScope.cancelLabelOption = texts.DISCARD_EDITION_OPTION_CLOSE
        confirmScope.confirmLabelOption = texts.DISCARD_EDITION_OPTION_DISCARD
        confirmScope.unfocusConfirmButton = true

        confirmScope.onConfirm = this.doDiscard.bind(this)

        confirmScope.onCancel = async () => {
            this.alertHolder.closeAlert()
        }

        confirmScope.update = this.update

        this.alertHolder.alert('info', texts.DISCARD_EDITION_OPTION_ALERT_TITLE, confirmScope, confirmScope.onCancel)
    }

    private async doDiscard() {
        const confirmScope = new AlertWarningConfirmContentScope()
        confirmScope.message = texts.DO_DISCARD_EDITION_DESCRIPTION
        confirmScope.cancelLabelOption = texts.DO_DISCARD_EDITION_NO_OPTION
        confirmScope.confirmLabelOption = texts.DO_DISCARD_EDITION_YES_OPTION
        confirmScope.unfocusConfirmButton = true

        confirmScope.onConfirm = async () => {
            try {
                this.alertHolder.closeAlert()

                const form = await channelConfigurationService.discardChangesAndFetchForm(this.__channelId)
                const keys = KeysFactory.channelConfigurationForm(this.app)
                await this.refresh(keys, form, false)
                this.update()
            } catch (caught) {
                this.app.unexpected(texts.DISCARD_EDITION_OPTION_ERROR, caught)
            }
        }

        confirmScope.onCancel = async () => {
            this.alertHolder.closeAlert()
        }

        confirmScope.update = this.update

        this.alertHolder.alert('warning', texts.DO_DISCARD_EDITION_TITLE, confirmScope, confirmScope.onCancel)
    }

    private extractFormData(): ChannelConfig {
        return {
            general: this.generalPresenter.extractFormData(),
            menus: this.menuPresenter.extractFormData(),
            messages: this.messagesPresenter.extractFormData(),
            workingHours: this.workingHoursPresenter.extractFormData(),
            extras: this.extrasPresenter.extractFormData()
        }
    }

    private async doSave(publish: boolean) {
        this.alertHolder.closeInstantAlert()

        const newData = this.extractFormData()

        await channelConfigurationService.saveForm(this.__channelId, newData, publish)

        this.__oldData = newData
        this.scope.draft = true

        this.alertHolder.instantAlert('success', texts.CHANGES_MADE_WITH_SUCCESS)

        return true
    }

    @action()
    private async onPublish() {
        const formData = this.extractFormData()

        const formDataValidator = new ChannelConfigurationValidator()
        for (const company of this.__companyMap.values()) {
            formDataValidator.companyMap.set(company.id, company.name)
        }

        formDataValidator.run(formData)

        this.validator.prepareToPublish(formDataValidator.status, formData)

        this.alertHolder.alert('info', this.validator.caption, this.validator.scope, this.validator.closeListener)
    }

    private async doPublish(severity: AlertSeverity, formData: ChannelConfig) {
        if (severity !== 'error') {
            try {
                const result = await channelConfigurationService.saveForm(this.__channelId, formData, true)

                this.__oldData = formData
                this.scope.draft = false

                if (result.status !== 0) {
                    if (result.message?.length ?? 0 > 0) {
                        throw new Error(
                            `${texts.CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_ERROR_STATUS}: ${
                                result.status
                            }. ${result.message?.join('\n')}.`
                        )
                    } else {
                        throw new Error(
                            `${texts.CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_ERROR_STATUS}: ${result.status}. ${texts.CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_UNKNOWN_ERROR_MESSAGE}`
                        )
                    }
                } else {
                    await this.close()
                }

                this.alertHolder.closeAlert()
            } catch (caught) {
                LOG.error('Saving', caught)

                this.alertHolder.alert(
                    'error',
                    texts.ERROR_DURING_SAVING_ALERT_TITLE,
                    texts.ERROR_DURING_SAVING_ALERT_CONTENT
                )
            }
        } else {
            try {
                await this.doSave(false)
            } catch (caught) {
                // It is not need to inform to the user this problem
                LOG.error(texts.SAVE_IS_NOT_POSSIBLE, caught)
            }
            const msg = texts.NOT_POSSIBLE_APPLY_CHANNEL_CONFIGURATION_WITH_ERRORS
            this.alertHolder.alert('error', texts.ERROR_DURING_PUBLISH_ALERT_TITLE, msg)
        }
    }

    @action()
    private async onShowErrorsReport() {
        this.onBeforeScopeUpdate()
        this.validator.prepareToInformation()
        this.alertHolder.alert(
            this.validator.severity,
            this.validator.caption,
            this.validator.scope,
            this.validator.closeListener
        )
    }

    private async close(refresh = false) {
        if (!this.isReleasing) {
            this.alertHolder.closeInstantAlert()

            const keys = KeysFactory.channelConfigurationListing(this.app)
            keys.refresh(refresh)
            await this.flipToIntent(keys.intent)
        }
    }

    public gotoErrorLocation(location: ChannelErrorLocation) {
        this.onBeforeScopeUpdate()

        switch (location) {
            case ChannelErrorLocation.CHANNEL_NAME:
                this.generalPresenter.focusOnChannelName()
                break
            case ChannelErrorLocation.COMPANY:
                this.generalPresenter.blinkAddCompanyButton()
                break
            case ChannelErrorLocation.COMPANY_SEGMENT:
                this.generalPresenter.focusOnFirstEmptyCompanyWithEmptySegment()
                break
            case ChannelErrorLocation.MENU:
                this.menuPresenter.openFirstFormWithErrors()
                break
            case ChannelErrorLocation.MESSAGE:
                this.messagesPresenter.focusOnFirstMessageWithErrors()
                break
            case ChannelErrorLocation.HOURS:
                this.workingHoursPresenter.openFirstFormWithErrors()
                break
            case ChannelErrorLocation.HOURS24:
                this.workingHoursPresenter.openFirstFormWith24Warning()
                break
            case ChannelErrorLocation.EXTRA:
                this.extrasPresenter.focusOnUseGoBackValue()
                break
        }
    }

    public emitAssignCompanyById(companyId: number) {
        this.workingHoursPresenter.onAssignCompanyById(companyId)
    }

    public emitUnassignCompanyById(companyId: number) {
        this.menuPresenter.onUnassignCompanyById(companyId)
        this.workingHoursPresenter.onUnassignCompanyById(companyId)
    }

    public override onBeforeScopeUpdate() {
        this.generalPresenter.beforeUpdate()
        this.menuPresenter.beforeUpdate()
        this.messagesPresenter.beforeUpdate()
        this.workingHoursPresenter.beforeUpdate()
        this.extrasPresenter.beforeUpdate()
        this.validator.beforeUpdate()

        this.scope.valid = this.validator.severity
    }
}
