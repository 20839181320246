import { HttpError, Strings } from '@syonet/lang'
import { ChatKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { ChipItemScope } from 'src/components/chippanel'
import { EurekaService } from 'src/services'
import { DateFns, MouseEvent, TextChangeEvent } from 'src/utils'
import { CubePresenter, FlipIntent, Logger, NOOP_VOID, ScopeSlot, action } from 'wdc-cube'
import { WaitingScope } from '../dashboard/Dashboard.scopes'
import { AlertHolder, MainPresenter } from '../main'
import { ChatsScope, ContactScope } from './Chats.scopes'
import { ChatTicketStatus, ChatsContentRequest, ChatsService } from './Chats.service'
import { ChatsFilterPresenter, ChatsFilterState } from './ChatsFilter.presenter'
import { TextsProvider } from './texts'

import type { ChatContact } from './Chats.service'

const LOG = Logger.get('ChatsPresenter')

// @Inject
const texts = TextsProvider.get()

// @Inject
const eurekaService = EurekaService.singleton()

// @Inject
const chatsService = ChatsService.singleton()

export class ChatsPresenter extends CubePresenter<MainPresenter, ChatsScope> {
    private parentSlot = NOOP_VOID as ScopeSlot

    private filterSlot = NOOP_VOID as DetachedScopeSlot

    private alertHolder = new AlertHolder(this.app)

    private readonly filter = new ChatsFilterPresenter(this)

    private loggedUserId = ''

    private selectedContactId?: string
    private selectedContact?: ChatContact

    private optionsCache = new Map<string, Map<unknown, unknown>>()

    public constructor(app: MainPresenter) {
        super(app, new ChatsScope())
    }

    public override release() {
        this.alertHolder.release()
        this.optionsCache.clear()
        this.filterSlot(this.filter.scope, true)
        this.filter.release()
        super.release()
    }

    // Cube context syncronization methods: Begin

    public override async applyParameters(
        intent: FlipIntent,
        initialization: boolean,
        last: boolean
    ): Promise<boolean> {
        const keys = this.filter.fixKeys(new ChatKeys(intent))

        if (initialization) {
            await this.initializeState(keys)
            this.parentSlot(this.scope)
        } else {
            this.parentSlot(this.scope)
            await this.synchronizeState(keys)
        }

        if (!last) {
            this.propagateContext(keys)
        }

        this.filterSlot(this.filter.scope)

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new ChatKeys(intent)
        this.filter.publishParameters(keys)

        if (this.selectedContactId) {
            keys.contactId(this.selectedContactId)
        }
    }

    private propagateContext(keys: ChatKeys) {
        this.filter.propagateContext(keys)

        keys.alertHolder(this.alertHolder)
        keys.optionsCache(this.optionsCache)

        if (this.selectedContactId && this.selectedContact) {
            keys.contactId(this.selectedContactId)
            keys.contact({
                id: this.selectedContactId,
                channel: this.selectedContact.company.phone,
                customer: { account: this.selectedContact.account, ...this.selectedContact.customer }
            })
        }
    }

    // Cube context syncronization methods: End

    private async initializeState(keys: ChatKeys) {
        this.parentSlot = keys.parentSlot() ?? NOOP_VOID
        this.filterSlot = keys.filterSlot() ?? NOOP_VOID

        const waiting = new WaitingScope()
        waiting.text = texts.LOADING_CONTENT_DESCRIPTION
        waiting.update = this.update
        this.parentSlot(waiting)

        this.filter.onSearch = this.onSearch.bind(this)

        {
            // Filter Information Panel
            const filterInfoPanel = this.scope.filterPanel

            filterInfoPanel.update = this.update

            this.updateManager.hint(ChipItemScope, filterInfoPanel, 5)
        }

        this.scope.chatHeader.update = this.update
        this.scope.chatHeader.onOpenTicket = this.onOpenTicket.bind(this)
        this.scope.chatHeader.onNewTicket = this.onNewTicket.bind(this)

        this.scope.contacts.onChangeItemsPerPage = this.onContactItemsPerPageChanged.bind(this)
        this.scope.contacts.onChangePageIndex = this.onContactPageIndexChanged.bind(this)

        this.scope.whatsapp.eureka = eurekaService.getSyoWhatsAppBusEndPoint()
        this.scope.whatsapp.loading = true
        this.scope.whatsapp.error = false
        this.scope.whatsapp.update = this.update

        this.updateManager.hint(ContactScope, this.scope.contacts, 5)

        await this.loadChannels()
        keys.refresh(true)
        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChatKeys, force = false) {
        let changed = keys.refresh() ?? force

        const newLoggedUserId = keys.loggedUserId() ?? this.loggedUserId
        changed = changed || this.loggedUserId !== newLoggedUserId

        const newSelectedContactId = keys.contactId()
        changed = changed || newSelectedContactId !== this.selectedContactId

        changed = (await this.filter.synchronizeState(keys, force)) || changed

        if (changed || force) {
            this.loggedUserId = newLoggedUserId
            this.selectedContactId = newSelectedContactId
            this.selectedContact = undefined
            await this.load(this.filter.cloneState())
        }
    }

    private async loadChannels() {
        const channelMap = await this.app.loadChannels()
        this.filter.applyCredentialData(channelMap)
    }

    private async load(newState: ChatsFilterState & { pageIndex?: number; itemsPerPage?: number }) {
        let customerNameApplied = false
        let customerPhoneApplied = false

        this.scope.waiting = true
        try {
            const request: ChatsContentRequest = {
                channels: newState.channels,
                startTime: newState.startTime,
                endTime: newState.endTime,
                itemsPerPage: newState.itemsPerPage ?? this.scope.contacts.itemsPerPage ?? 50,
                pageIndex: newState.pageIndex ?? this.scope.contacts.pageIndex ?? 0,
                noCache: newState.noCache
            }

            if ((newState.ticketStatus ?? ChatTicketStatus.ALL) !== ChatTicketStatus.ALL) {
                request.ticketStatus = newState.ticketStatus
            }

            if (newState.customerName) {
                request.customerIds = [newState.customerName.id]
                customerNameApplied = true
            }

            if (newState.customerPhone) {
                request.customerIds = [newState.customerPhone.id]
                customerPhoneApplied = true
            }

            if (this.selectedContactId) {
                request.assureContactId = this.selectedContactId
            }

            const response = await chatsService.fetchContent(request)

            this.scope.contacts.itemsPerPage = response.meta.itemsPerPage
            this.scope.contacts.itemCount = response.meta.totalItems
            this.scope.contacts.pageIndex = response.meta.currentPage

            newState.customerName = undefined
            newState.customerPhone = undefined
            newState.totalItems = response.meta.totalItems

            if (response.filters.customers) {
                for (const customer of response.filters.customers) {
                    if (customerNameApplied) {
                        newState.customerName = { ...customer }
                    }

                    if (customerPhoneApplied) {
                        newState.customerPhone = { ...customer }
                    }
                    break
                }
            }

            await this.filter.applyData(newState)

            let newSelectedContact: ChatContact | undefined

            let i = 0
            for (const contact of response.contacts) {
                if (this.selectedContactId === contact.id) {
                    newSelectedContact = contact
                }

                let contactScope = this.scope.contacts.entries.get(i)
                if (!contactScope) {
                    contactScope = new ContactScope()
                    this.scope.contacts.entries.set(i, contactScope)
                }

                contactScope.id = contact.id
                contactScope.name = contact.customer.name
                contactScope.phone = contact.customer.phone
                contactScope.datetime = DateFns.format(contact.datetime, this.app.date_HH_MM_SS_Format)
                contactScope.hasRobot = contact.hasRobot
                contactScope.initials = computeInitials(contact.customer.name)

                contactScope.update = this.update
                contactScope.onSelect = this.onCustomerSelected.bind(this, contact)

                this.scope.contacts.entries.set(i, contactScope)

                i++
            }
            this.scope.contacts.entries.length = i

            if (!newSelectedContact) {
                newSelectedContact = response.contacts[0]
            }

            if (newSelectedContact) {
                await this.onCustomerSelected(newSelectedContact)
            } else {
                this.scope.chatHeader.initials = ''
                this.scope.chatHeader.name = ''
                this.scope.chatHeader.phone = ''
                this.selectedContact = undefined
                this.selectedContactId = undefined

                // TODO: desabilitar botão que abre o novo ticket
            }

            this.updateWhastappProps(newSelectedContact)

            this.filter.save()
        } finally {
            this.scope.waiting = false
            this.update()
        }
    }

    protected updateWhastappProps(contact?: ChatContact) {
        if (contact) {
            const room = `${contact.account}-admin`
            const username = room

            this.scope.whatsapp.customerName = contact.customer.name
            this.scope.whatsapp.customerPhone = contact.customer.phone

            this.scope.whatsapp.companyId = contact.company.id
            this.scope.whatsapp.companyPhone = contact.company.phone

            this.scope.whatsapp.userName = username
            this.scope.whatsapp.room = room
            this.scope.whatsapp.readOnly = false
            this.scope.whatsapp.sendHsm = true
            this.scope.chatHeader.hasTicket = !!contact.ticket
        } else {
            this.scope.whatsapp.customerName = ''
            this.scope.whatsapp.customerPhone = ''
            this.scope.whatsapp.companyId = null
            this.scope.whatsapp.companyPhone = ''
            this.scope.whatsapp.userName = ''
            this.scope.whatsapp.room = ''
            this.scope.whatsapp.readOnly = true
            this.scope.whatsapp.sendHsm = true
            this.scope.chatHeader.hasTicket = false
        }

        if (!this.app.embedded) {
            this.scope.chatHeader.hasTicket = false
        }
    }

    protected async onSearch(request: ChatsFilterState) {
        try {
            this.load({
                pageIndex: 0,
                itemsPerPage: this.scope.contacts.itemsPerPage,
                ...request
            })
        } finally {
            this.update()
            this.updateHistory()
        }
    }

    private async onContactItemsPerPageChanged(evt: TextChangeEvent) {
        try {
            const itemsPerPage = evt.target.value ? +evt.target.value : this.scope.contacts.itemsPerPage
            const request = this.filter.cloneState()

            this.load({
                pageIndex: 0,
                itemsPerPage,
                ...request
            })
        } finally {
            this.update()
            this.updateHistory()
        }
    }

    private async onContactPageIndexChanged(evt: MouseEvent, pageIndex: number) {
        try {
            const request = this.filter.cloneState()

            this.load({
                pageIndex,
                itemsPerPage: this.scope.contacts.itemsPerPage,
                ...request
            })
        } finally {
            this.update()
            this.updateHistory()
        }
    }

    @action()
    private async onOpenTicket() {
        if (!this.selectedContact) {
            LOG.warn('Missing selected contact')
            return
        }

        const ticketId = this.selectedContact.ticket?.id
        if (!ticketId) {
            LOG.warn(`No ticket to contact ${JSON.stringify(this.selectedContact)}`)
            return
        }

        const channelPhone = this.selectedContact.company.phone
        if (!channelPhone) {
            LOG.warn(`No channelPhone to contact ${JSON.stringify(this.selectedContact)}`)
            return
        }

        const customerPhone = this.selectedContact.customer.phone
        if (!customerPhone) {
            LOG.warn(`No customerPhone to contact ${JSON.stringify(this.selectedContact)}`)
            return
        }

        const partnerUrl = await this.app.getChannelPartnerUrl(channelPhone)
        if (partnerUrl) {
            const partnerAppUrl = `${partnerUrl}/portal/cic.do?modulo=CIC2#/evento/${ticketId}/whatsapp/${customerPhone}`
            window.open(partnerAppUrl, '_blank')
        }
    }

    @action()
    private async onNewTicket() {
        try {
            const targetKeys = KeysFactory.chatsForm(this.app)
            await this.app.flipToIntent(targetKeys.intent)
        } catch (caught) {
            if (caught instanceof HttpError) {
                if (caught.status === 102) {
                    this.alertHolder.alert(
                        'info',
                        'Aguarde...',
                        'As opções do formulário estão sendo preparadas neste momento.\n' +
                            'Aguarde alguns instantes e tente novamente.'
                    )
                    return
                }
            }
            throw caught
        }
    }

    @action()
    private async onCustomerSelected(contact: ChatContact) {
        this.scope.chatHeader.initials = computeInitials(contact.customer.name)
        this.scope.chatHeader.name = contact.customer.name
        this.scope.chatHeader.phone = contact.customer.phone
        this.selectedContactId = contact.id
        this.selectedContact = contact
        this.updateWhastappProps(contact)
        this.app.updateHistory()
    }

    public override onBeforeScopeUpdate(): void {
        this.filter.synchronizeChips(this.scope.filterPanel)
    }
}

function computeInitials(name: string) {
    const parts = Strings.removeDiacritics(name).split(' ')
    const firstName = parts[0]
    const secondName = parts[1]
    if (firstName && secondName) {
        return `${firstName.charAt(0)}${secondName.charAt(0)}`.toUpperCase()
    } else if (firstName && firstName.length >= 2) {
        return `${firstName.charAt(0)}${firstName.charAt(1)}`.toUpperCase()
    } else {
        return '??'
    }
}
