import { action, IPresenterOwner, IUpdateManager, Presenter } from 'wdc-cube'
import { ComboScope, TextChangeEvent } from './Combo.scopes'

export class ComboPresenter extends Presenter<ComboScope<TextChangeEvent>> {
    public constructor(owner: IPresenterOwner, scope?: ComboScope<TextChangeEvent>, updateManager?: IUpdateManager) {
        super(owner, scope ?? new ComboScope(), updateManager)
    }

    public initializeState() {
        this.scope.onChange = this.onChange.bind(this)
    }

    @action()
    public async onChange(evt: TextChangeEvent) {
        const value = evt.target.value

        if (typeof value === 'number') {
            this.scope.onChange(value as TextChangeEvent)
        } else if (value && typeof value === 'string') {
            this.scope.onChange(value as unknown as TextChangeEvent)
        }
    }
}
