import { Observable, observe, Scope } from 'wdc-cube'
import { TextChangeEvent, CheckedChangeEvent, ServiceBySegmentation } from '../ChannelConfigurationForm.scopes'

@Observable
export class ChannelConfigurationCompanyConfigurationFormScope extends Scope {
    @observe() id!: number
    @observe() companyName: string | undefined
    @observe() companyReference: string | undefined
    @observe() showAttendantName: boolean | undefined
    @observe() usesPreSaleNotification: boolean | undefined
    @observe() serviceBySegmentation: ServiceBySegmentation[] | undefined

    @observe() onCompanyNameChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onCompanyDescriptionChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onShowAtendentInMessage = Scope.SYNC_ACTION_ONE<CheckedChangeEvent>()
    @observe() onUsesPreSalNotification = Scope.SYNC_ACTION_ONE<CheckedChangeEvent>()

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
}
