import EventIcon from '@mui/icons-material/Event'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddAltIcon from '@mui/icons-material/PersonAdd'
import { cyan, lightBlue, purple } from '@mui/material/colors'
import React from 'react'
import { bindUpdate } from 'wdc-cube-react'
import { DashboardCustomerServiceScope } from '../DashboardCustomerService.scopes'
import { TextsProvider } from '../texts'
import { KpiCard } from './components/KpiCard'
import { getOrMakeCustomerServiceStyles } from './Dashboard.styles'
import { DashboardCustomerServiceTableView } from './DashboardCustomerServiceTable.view'

// @Inject
const texts = TextsProvider.get()

export type DashboardCustomerServiceProps = { scope: DashboardCustomerServiceScope }

export function DashboardCustomerServiceView({ scope }: DashboardCustomerServiceProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeCustomerServiceStyles()

    return (
        <>
            <div className={styles.gridContainer}>
                <KpiCard
                    className={styles.kpi0}
                    label={texts.KPI_CONTACTS_TITLE}
                    value={`${scope.kpiContactQuantity.value}`}
                    color={lightBlue[600]}
                    icon={PersonIcon}
                    info={texts.KPI_CONTACTS_INFO}
                    onMoreInfo={scope.kpiContactQuantity.onMoreInfo}
                />
                <KpiCard
                    className={styles.kpi1}
                    label={texts.KPI_NEW_CONTACTS_TITLE}
                    value={`${scope.kpiNewContactQuantity.value}`}
                    color={'#E59319'}
                    icon={PersonAddAltIcon}
                    info={texts.KPI_NEW_CONTACTS_INFO}
                    onMoreInfo={scope.kpiNewContactQuantity.onMoreInfo}
                />
                <KpiCard
                    className={styles.kpi2}
                    label={texts.KPI_EVENTS_TITLE}
                    value={`${scope.kpiHumanTicketQuantity.value}`}
                    color={cyan[500]}
                    icon={LocalOfferIcon}
                    info={texts.KPI_EVENTS_INFO}
                    onMoreInfo={scope.kpiHumanTicketQuantity.onMoreInfo}
                />
                <KpiCard
                    className={styles.kpi3}
                    label={texts.KPI_SCHEDULE_TITLE}
                    value={`${scope.kpiScheduledTiketsQuantity.value}`}
                    color={purple[400]}
                    icon={EventIcon}
                    info={texts.KPI_SCHEDULE_INFO}
                    onMoreInfo={scope.kpiScheduledTiketsQuantity.onMoreInfo}
                />
                <DashboardCustomerServiceTableView className={styles.table} scope={scope.table} />
            </div>
        </>
    )
}
