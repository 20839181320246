import { Button } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AlertErrorContentScope } from '../ChannelConfigurationAlert.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeErrorAlertStyles } from './Alert.styles'

// @Inject
const texts = TextsProvider.get()

export type AlertErrorContentViewProps = IViewProps & {
    scope: AlertErrorContentScope
}

export function AlertErrorContentView({ className, scope }: AlertErrorContentViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeErrorAlertStyles()

    return (
        <div className={clsx(className)}>
            <p className={styles.adjustText}>{texts.CHANNEL_CONFIGURATION_ALERT_CONTENT_TITLE}</p>
            <div className={styles.adjustButton}>
                <Button color="inherit" onClick={scope.onClose}>
                    {texts.CHANNEL_CONFIGURATION_ALERT_CONTENT_CLOSE_BUTTON}
                </Button>
                <Button variant="contained" style={{ backgroundColor: '#00000080' }}>
                    {texts.CHANNEL_CONFIGURATION_ALERT_CONTENT_CONFIGURE_CHANNEL_BUTTON}
                </Button>
            </div>
        </div>
    )
}
