import { ChipPanelScope } from 'src/components/chippanel'
import { WhatsAppScope } from 'src/components/whatsapp'
import { MouseEvent, TextChangeEvent } from 'src/utils'
import { v4 as uuid } from 'uuid'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

@Observable
export class ContactScope extends Scope {
    readonly uid = uuid()

    @observe() id = ''
    @observe() initials = ''
    @observe() name = ''
    @observe() phone = ''
    @observe() datetime = ''
    @observe() hasRobot = false

    @observe() onSelect = Scope.ASYNC_ACTION
}

@Observable
export class ContactListScope extends Scope {
    readonly entries = new ObservableArray<ContactScope>(this)

    @observe() itemsPerPageOptions: number[] = [10, 25, 50, 75, 100]
    @observe() pageIndex = 0
    @observe() itemsPerPage = 50
    @observe() itemCount = 0

    @observe() onChangePageIndex = Scope.ASYNC_ACTION_TWO<MouseEvent, number>()
    @observe() onChangeItemsPerPage = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
}

@Observable
export class ChatHeaderScope extends Scope {
    @observe() initials = ''
    @observe() name = ''
    @observe() phone = ''
    @observe() hasTicket = false

    onOpenTicket = Scope.ASYNC_ACTION
    onNewTicket = Scope.ASYNC_ACTION
}

@Observable
export class ChatsScope extends Scope {
    readonly contacts = new ContactListScope()
    readonly chatHeader = new ChatHeaderScope()
    readonly filterPanel = new ChipPanelScope()
    readonly whatsapp = new WhatsAppScope()
    @observe() waiting = false
}
