import { Button, Link } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AlertWarningContentScope } from '../ChannelConfigurationAlert.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeWarningAlertStyles as getOrMakeStyle } from './Alert.styles'

// @Inject
const texts = TextsProvider.get()

export type AlertWarningContentViewProps = IViewProps & {
    scope: AlertWarningContentScope
}

export function AlertWarningContentView({ className, scope }: AlertWarningContentViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyle()

    return (
        <div className={clsx(className)}>
            <div className={styles.adjustText}>
                <p style={{ textAlign: 'left' }}>{texts.CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS}</p>
                <p style={{ textAlign: 'left' }}>
                    {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS_DESCRIPTION}:
                </p>
            </div>
            <ul style={{ paddingInlineStart: 20, marginTop: -8 }}>
                <li style={{ textAlign: 'left' }}>
                    <Link href="menu" color="inherit">
                        {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_MENU}
                    </Link>
                </li>
                <li style={{ textAlign: 'left' }}>
                    <Link href="mensagens" color="inherit">
                        {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_MESSAGES}
                    </Link>
                </li>
                <li style={{ textAlign: 'left' }}>
                    <Link href="horarios" color="inherit">
                        {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_TIMES}
                    </Link>
                </li>
            </ul>
            <div className={styles.adjustButton}>
                <Button color="inherit" onClick={scope.onClose}>
                    {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_CONTINUE_BUTTON}
                </Button>
                <Button variant="contained" style={{ backgroundColor: '#00000080' }}>
                    {texts.CHANNEL_CONFIGURATION_ALERT_WARNING_CONFIGURE_CHANNEL_BUTTON}
                </Button>
            </div>
        </div>
    )
}
