import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ChannelConfigurationFormExtrasScope } from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormExtrasStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'

const texts = TextsProvider.get()

export type ChannelConfigurationFormExtrasViewProps = IViewProps & { scope: ChannelConfigurationFormExtrasScope }

export function ChannelConfigurationFormExtrasView({ className, scope }: ChannelConfigurationFormExtrasViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyle()

    return <div className={clsx(styles.view, className)}>
        {/* <SimpleAccordion
            title={texts.CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_TITLE}
            description={texts.CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_DESCRIPTION}
            content={<IgnoredWords />}
        /> */}
        <SimpleAccordion
            title={texts.CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_TITLE}
            description={texts.CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_DESCRIPTION}
            content={<GoBackOption scope={scope} />}
        />
    </div>
}

// TODO: DESCOMENTAR AO APLICAR A LÓGICA DE PALAVRAS IGNORADAS
// function IgnoredWords() {

//     const ignoredWords = [
//         { id: 1, title: 'obrigado' },
//         { id: 2, title: 'vlw' },
//         { id: 3, title: 'valeu' },
//         { id: 4, title: 'agradeço' }
//     ]

//     const { classes: styles } = getOrMakeStyle()

//     return <div className={styles.accordionContent}>
//         <Autocomplete
//             multiple
//             size="small"
//             options={ignoredWords.map((option) => option.title)}
//             freeSolo
//             renderTags={(value: readonly string[], getTagProps) =>
//                 value.map((option: string, index: number) => {
//                     return <div key={index}>
//                         <Chip size="small" color="secondary" label={option} {...getTagProps({ index })} />
//                     </div>
//                 })
//             }
//             renderInput={(params) => (
//                 <TextField
//                     {...params}
//                     variant="outlined"
//                     label={texts.CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_TITLE}
//                     placeholder={texts.CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_LABEL}
//                 />
//             )}
//         />
//     </div>
// }

// :: Component(GoBackOption)

type GoBackOptionProps = IViewProps & {
    scope: ChannelConfigurationFormExtrasScope
}

function GoBackOption({ scope }: GoBackOptionProps) {

    const { classes: styles } = getOrMakeStyle()

    return <div className={styles.radioAccordionContent}>
        <FormControl>
            <RadioGroup
                aria-labelledby={`radio-buttons-group-label-${uuid()}`}
                value={scope.useGoBack}
                onChange={scope.onChangeGoBackButton}
            >
                <FormControlLabel
                    value={false}
                    control={<Radio color="secondary" />}
                    label={<Typography variant="body1">{texts.CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_NOT_SHOW_GO_BACK_OPTION_LABEL}</Typography>}
                />
                <FormControlLabel
                    value={true}
                    control={<Radio color="secondary" />}
                    label={<Typography variant="body1">{texts.CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_SHOW_GO_BACK_OPTION_LABEL}</Typography>}
                />
            </RadioGroup>
        </FormControl>
    </div>
}

// :: Component(Accordion)

interface SimpleAccordionProps {
    title: string
    description: string
    content: JSX.Element
}

function SimpleAccordion({ title, description, content }: SimpleAccordionProps) {

    const { classes: styles } = getOrMakeStyle()

    return <Accordion elevation={0} defaultExpanded className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${uuid()}-content`} id={`panel-header-${uuid()}`}>
            <Typography variant="h5">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
            <Typography component="div" variant="body1" color="text.secondary">
                {description}
            </Typography>
            <div>
                {content}
            </div>
        </AccordionDetails>
    </Accordion>
}