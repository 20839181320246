import { ViewFactory } from 'wdc-cube-react'

import { ChannelConfigurationListingScope } from '../ChannelConfigurationListing.scopes'
import { ChannelConfigurationListingView } from './ChannelConfigurationListing.view'

import { ChannelConfigurationListingFilterScope } from '../ChannelConfigurationListingFilter.scopes'
import { ChannelConfigurationListingFilterView } from './ChannelConfigurationListingFilter.view'

import { ChannelConfigurationFormScope } from '../ChannelConfigurationForm.scopes'
import { ChannelConfigurationFormView } from './ChannelConfigurationForm.view'

import {
    ChannelConfigurationMenuEditorFormScope,
    ChannelConfigurationMenuEditorFormSubMenuScope
} from '../ChannelConfigurationMenuEditorForm.scopes'
import {
    ChannelConfigurationMenuEditorFormSubMenuView,
    ChannelConfigurationMenuEditorFormView
} from './ChannelConfigurationMenuEditorForm.view'

import { ChannelConfigurationWorkingHoursEditorFormScope } from '../ChannelConfigurationWorkingHoursEditorForm.scopes'
import { ChannelConfigurationWorkingHoursEditorFormView } from './ChannelConfigurationWorkingHoursEditorForm.view'

import { ChannelConfigurationMenuEditorFormEventScope } from '../ChannelConfigurationMenuEditorForm.scopes'
import { ChannelConfigurationMenuEditorFormEventView } from './ChannelConfigurationMenuEditorForm.view'

import { ChannelConfigurationMenuEditorFormLinkScope } from '../ChannelConfigurationMenuEditorForm.scopes'
import { ChannelConfigurationMenuEditorFormLinkView } from './ChannelConfigurationMenuEditorForm.view'

import { ChannelConfigurationMenuEditorFormInformationScope } from '../ChannelConfigurationMenuEditorForm.scopes'
import { ChannelConfigurationMenuEditorFormInformationView } from './ChannelConfigurationMenuEditorForm.view'

import { ChannelConfigurationMenuEditorFormSchedulingRobotScope } from '../ChannelConfigurationMenuEditorForm.scopes'
import { ChannelConfigurationMenuEditorFormSchedulingRobotView } from './ChannelConfigurationMenuEditorForm.view'

import { AlertWarningConfirmContentScope } from '../ChannelConfigurationAlert.scopes'
import { AlertWarningContentView } from './AlertWarningContentView'

import { AlertErrorContentScope } from '../ChannelConfigurationAlert.scopes'
import { AlertErrorContentView } from './AlertErrorContentView'

import { AlertWarningContentScope } from '../ChannelConfigurationAlert.scopes'
import { AlertWarningConfirmContentView } from './AlertWarningConfirmContentView'

import { ValidationContentScope } from '../ChannelConfigurationAlert.scopes'
import { AlertValidationContentView } from './AlertValidationContentView'

import { ChannelConfigurationSelfRescueFormScope } from '../selfRescue/ChannelConfigurationSelfRescueForm.scopes'
import { ChannelConfigurationSelfRescueFormView } from './selfRescue/ChannelConfigurationSelfRescueForm.view'

import { ChannelConfigurationCompanyConfigurationFormScope } from '../companyConfiguration/ChannelConfigurationCompanyConfiguration.scopes'
import { ChannelConfigurationCompanyConfigurationFormView } from './companyConfiguration/ChannelConfigurationCompanyConfigurationForm.view'

import { ChannelConfigurationMenuSegmentationEditorFormScope } from '../menuSegmentation/ChannelConfigurationMenuSegmentationEditorForm.scopes'
import { ChannelConfigurationMenuSegmentationEditorFormView } from './menuSegmentation/ChannelConfigurationMenuSegmentationEditorForm.view'

export function registerViews(rv = ViewFactory.register) {
    rv(ChannelConfigurationListingScope, ChannelConfigurationListingView)
    rv(ChannelConfigurationListingFilterScope, ChannelConfigurationListingFilterView)
    rv(ChannelConfigurationFormScope, ChannelConfigurationFormView)
    rv(ChannelConfigurationWorkingHoursEditorFormScope, ChannelConfigurationWorkingHoursEditorFormView)

    rv(ChannelConfigurationSelfRescueFormScope, ChannelConfigurationSelfRescueFormView)
    rv(ChannelConfigurationCompanyConfigurationFormScope, ChannelConfigurationCompanyConfigurationFormView)
    rv(ChannelConfigurationMenuSegmentationEditorFormScope, ChannelConfigurationMenuSegmentationEditorFormView)

    rv(ChannelConfigurationMenuEditorFormScope, ChannelConfigurationMenuEditorFormView)
    rv(ChannelConfigurationMenuEditorFormLinkScope, ChannelConfigurationMenuEditorFormLinkView)
    rv(ChannelConfigurationMenuEditorFormEventScope, ChannelConfigurationMenuEditorFormEventView)
    rv(ChannelConfigurationMenuEditorFormInformationScope, ChannelConfigurationMenuEditorFormInformationView)
    rv(ChannelConfigurationMenuEditorFormSchedulingRobotScope, ChannelConfigurationMenuEditorFormSchedulingRobotView)
    rv(ChannelConfigurationMenuEditorFormSubMenuScope, ChannelConfigurationMenuEditorFormSubMenuView)

    rv(AlertWarningContentScope, AlertWarningContentView)
    rv(AlertErrorContentScope, AlertErrorContentView)
    rv(AlertWarningConfirmContentScope, AlertWarningConfirmContentView)
    rv(ValidationContentScope, AlertValidationContentView)
}
