import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ComboScope } from '../Combo.scopes'

type StringOrNumberOrUndefined = string | number | undefined

export type ComboViewProps<T = StringOrNumberOrUndefined> = IViewProps & {
    label: string
    size?: "small" | "medium" | undefined
    scope: ComboScope<T>
}

export function ComboView<T = StringOrNumberOrUndefined>({ className, label, size, scope }: ComboViewProps<T>) {
    bindUpdate(React, scope)

    const value = scope.value

    return (
        <FormControl className={className} variant="outlined" size={size || "small"} error={scope.value === undefined && scope.required} fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={value !== null && value !== undefined ? String(value) : ''}
                onChange={scope.onChange}
                disabled={scope.disabled}
            >
                {scope.options.map((option, index) => (
                    <MenuItem key={index} value={option.id as unknown as StringOrNumberOrUndefined}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
