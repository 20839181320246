import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import { v4 as uuid } from 'uuid'

@Observable
export class ItemScope extends Scope {
    @observe() index = 0
    @observe() code = ''
    @observe() description = ''
    @observe() selected = false
    @observe() inUse = false

    @observe() onSelectionToggled = Scope.SYNC_ACTION
}

@Observable
export class AllItemScope extends ItemScope {
    @observe() subdescription = ''
}

@Observable
export class MultiSelectionScope extends Scope {
    readonly uid = uuid()
    @observe() description = ''
    @observe() selectedIndexes: number[] = []
    @observe() selectedDescription = ''
    @observe() entries = new ObservableArray<ItemScope>(this)
    @observe() error = ''

    @observe() onSelectedToggled = Scope.SYNC_ACTION_ONE<string | undefined>()
}
