import { AlertSeverity, NOOP_VOID, Scope } from 'wdc-cube'
import { MainPresenter } from './Main.presenter'

export class AlertHolder {
    private __app: MainPresenter

    private __closeAlert = NOOP_VOID

    private __closeInstantAlert = NOOP_VOID

    public constructor(app: MainPresenter) {
        this.__app = app
    }

    public release() {
        this.closeAlert()
        this.closeInstantAlert()
    }

    public closeAlert(): void {
        this.__closeAlert()
        this.__closeAlert = NOOP_VOID
    }

    public alert(
        severity: AlertSeverity,
        title: string,
        messageOrContent: string | Scope,
        onClose?: () => Promise<void>
    ): void {
        this.__closeAlert()

        if (onClose) {
            this.__closeAlert = this.__app.alert(severity, title, messageOrContent, async () => {
                this.__closeAlert = NOOP_VOID
                await onClose()
            })
        } else {
            this.__closeAlert = this.__app.alert(severity, title, messageOrContent, async () => {
                this.__closeAlert = NOOP_VOID
            })
        }
    }

    public closeInstantAlert(): void {
        this.__closeInstantAlert()
        this.__closeInstantAlert = NOOP_VOID
    }

    public instantAlert(severity: AlertSeverity, message: string) {
        this.__closeInstantAlert()
        this.__closeInstantAlert = this.__app.instantAlert(severity, message)
    }

    public simpleAlert(
        title: string,
        messageOrContent: string | Scope,
        onClose?: () => Promise<void>
    ): void {
        this.__closeAlert()

        if (onClose) {
            this.__closeAlert = this.__app.simpleAlert(title, messageOrContent, async () => {
                this.__closeAlert = NOOP_VOID
                await onClose()
            })
        } else {
            this.__closeAlert = this.__app.simpleAlert(title, messageOrContent, async () => {
                this.__closeAlert = NOOP_VOID
            })
        }
    }
}
