import { makeStyles } from 'tss-react/mui'

export const getOrMakeAlertStyles = makeStyles()(() => ({
    alertPane: {
        margin: 'auto',
        width: 'fit-content',
        minWidth: 429,
        minHeight: 300,
        overflowX: 'auto'
    },
    alertBackground: {
        textAlign: 'center',
        height: 94
    },
    alertTitle: {
        marginTop: 55,
        marginBottom: 10,
        textAlign: 'center'
    },
    alertContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontFamily: 'Arial',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        padding: 8,
        whiteSpace: 'break-spaces'
    },
    alertMessageContent: {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 1.5,
        gap: 20
    },
    iconBody: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: '58%'
    },
    backgroundIcon: {
        backgroundColor: '#FFF',
        borderRadius: 50,
        width: 100,
        paddingTop: 10
    },
    iconAttributes: {
        fontSize: '78px!important',
        textAlign: 'center'
    },
    adjustCloseIcon: {
        position: 'absolute',
        top: 8,
        right: 8,
        textAlign: 'end',
        color: '#fff'
    }
}))
