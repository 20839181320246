import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import Keycloak from 'keycloak-js'
import { Logger, ServiceLike } from 'wdc-cube'

const LOG = Logger.get('AxiosProviderService')

const SESSION_GUID = '68959d70-fa4f-4121-a22a-338f5d9c6ea9'
const TOKEN_EXPIRATION_IN_MILLIS = 300_000
const TOKEN_VALIDATION_INTERVAL_IN_MILLIS = 50_000

type AuthSession = {
    idToken?: string
    refreshToken?: string
    authorization?: string
    token?: string
    provider?: string
}

export class AxiosProviderService implements ServiceLike {
    private static INSTANCE = new AxiosProviderService()

    public static singleton() {
        return AxiosProviderService.INSTANCE
    }

    // :: Instance

    private __initialized = false

    private __keyCloak = static_newKeycloak()

    private __keyCloakInitialized = false

    private __authorization?: string

    private __provider?: string

    private __keepConnectedHandler?: NodeJS.Timer

    public get name() {
        return 'axios-authenticated-service'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.stopKeepingConnected()
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public getAxios(config?: AxiosRequestConfig): AxiosInstance {
        const axiosConfig = config || {}

        if (!axiosConfig.headers) {
            axiosConfig.headers = {}
        }

        if (this.__provider && this.__provider !== 'keycloak' && this.__authorization) {
            axiosConfig.headers['provider-auth'] = this.__provider
            axiosConfig.headers['authorization'] = this.__authorization
        } else if (this.__keyCloakInitialized && this.__keyCloak.authenticated) {
            axiosConfig.headers['provider-auth'] = 'keycloak'
            axiosConfig.headers['authorization'] = `Bearer ${this.__keyCloak.token}`
        }

        axiosConfig.headers['Access-Control-Allow-Origin'] = '*'

        return Axios.create(axiosConfig)
    }

    private saveSession(session: AuthSession) {
        if (sessionStorage) {
            sessionStorage.setItem(SESSION_GUID, JSON.stringify(session))
        }
    }

    private loadSession() {
        if (sessionStorage) {
            const sessionJson = sessionStorage.getItem(SESSION_GUID)
            if (sessionJson) {
                return JSON.parse(sessionJson) as AuthSession
            }
        }

        if (this.__provider) {
            return {
                provider: this.__provider,
                authorization: this.__authorization
            } as AuthSession
        } else if (this.__keyCloakInitialized) {
            return {
                provider: 'keycloak',
                token: this.__keyCloak.token,
                idToken: this.__keyCloak.idToken,
                refreshToken: this.__keyCloak.refreshToken
            } as AuthSession
        } else {
            return {
                provider: 'unknown',
                authorization: 'Basic '
            } as AuthSession
        }
    }

    public async connect(provider = 'keycloak', authorization?: string) {
        if (provider && provider !== 'keycloak') {
            this.__authorization = authorization
            this.__provider = provider
            this.saveSession({ provider, authorization: authorization })
            this.stopKeepingConnected()
            return
        }

        this.__authorization = undefined
        this.__provider = undefined

        if (!this.__keyCloakInitialized) {
            const session = this.loadSession()
            const isKeycloakProvider = session.provider === 'keycloak'

            const ok = await this.__keyCloak.init({
                onLoad: 'check-sso',
                responseMode: 'fragment',
                token: isKeycloakProvider ? session.token : undefined,
                idToken: isKeycloakProvider ? session.idToken : undefined,
                refreshToken: isKeycloakProvider ? session.refreshToken : undefined
            })

            this.__keyCloakInitialized = true

            if (!ok) {
                await this.__keyCloak.login()
            }
        } else if (!this.__keyCloak.authenticated) {
            await this.__keyCloak.login()
        }

        if (this.__keyCloak.authenticated) {
            if (this.__keyCloak.isTokenExpired(TOKEN_VALIDATION_INTERVAL_IN_MILLIS)) {
                await this.refreshAndUpdateKeycloakToken()
            } else {
                this.saveKeycloakSession()
            }

            this.startKeepingConnected()
        }
    }

    public get authenticated() {
        if (this.__provider) {
            return !!this.__authorization
        }

        if (this.__keyCloakInitialized) {
            return this.__keyCloak.authenticated ?? false
        }

        return false
    }

    public get authorization() {
        if (this.__provider) {
            return this.__authorization
        } else if (this.__keyCloakInitialized) {
            return `Bearer ${this.__keyCloak.token}`
        } else {
            return undefined
        }
    }

    public get provider() {
        if (this.__provider) {
            return this.__provider
        } else {
            return 'keycloak'
        }
    }

    public async login() {
        if (this.__provider) {
            LOG.info('When using provider, login is not needed')
        } else {
            await this.__keyCloak.login()

            if (this.__keyCloak.authenticated) {
                await this.refreshAndUpdateKeycloakToken()
            } else {
                this.__authorization = undefined
            }
        }
    }

    public async logout() {
        if (sessionStorage) {
            sessionStorage.removeItem(SESSION_GUID)
        }

        if (this.__provider) {
            this.__provider = undefined
            this.__authorization = undefined
        } else if (this.__keyCloakInitialized) {
            this.stopKeepingConnected()
            await this.__keyCloak.logout()
        }
    }

    public async loadUserProfile() {
        if (this.__authorization?.startsWith('Bearer')) {
            return { username: `admin-${new Date().getTime()}` } as Keycloak.KeycloakProfile
        } else if (this.__provider && this.__authorization) {
            const userAndPassword = this.__authorization
                ? atob(this.__authorization.replace('Basic ', '')).split(':')[0]
                : ''
            return { username: userAndPassword } as Keycloak.KeycloakProfile
        } else if (this.__keyCloakInitialized) {
            return await this.__keyCloak.loadUserProfile()
        } else {
            return {} as Keycloak.KeycloakProfile
        }
    }

    private startKeepingConnected() {
        if (!this.__keepConnectedHandler) {
            this.__keepConnectedHandler = setInterval(
                this.refreshAndUpdateKeycloakToken.bind(this),
                TOKEN_VALIDATION_INTERVAL_IN_MILLIS
            )
        }
    }

    private stopKeepingConnected() {
        if (this.__keepConnectedHandler) {
            clearInterval(this.__keepConnectedHandler)
            this.__keepConnectedHandler = undefined
        }
    }

    private async refreshAndUpdateKeycloakToken() {
        try {
            await this.__keyCloak.updateToken(TOKEN_EXPIRATION_IN_MILLIS)

            this.saveKeycloakSession()
        } catch (caught) {
            LOG.error('refreshAndUpdateKeycloakToken', caught)
        }
    }

    private saveKeycloakSession() {
        this.saveSession({
            provider: 'keycloak',
            token: this.__keyCloak.token,
            idToken: this.__keyCloak.idToken,
            refreshToken: this.__keyCloak.refreshToken
        })
    }
}

// :: Private static methods

function static_newKeycloak() {
    const hostname = window.location.hostname

    const isLocalhost = hostname === 'localhost'
    const isInternal = !isLocalhost && hostname.indexOf('internal.') !== -1

    const url =
        isLocalhost || isInternal
            ? 'https://keycloak.internal.syonet.com/auth/'
            : 'https://keycloak.dallas.linode.syonet.com/auth/'

    const clientId = isInternal ? 'admin.internal.syonet.com' : window.location.hostname

    return Keycloak({
        url,
        realm: 'master',
        clientId: clientId
    })
}
