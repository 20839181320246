import { LoggerLike } from './LoggerLike'

const FG_RED = '\x1b[31m'
const FG_GREEN = '\x1b[32m'
const FG_BLUE = '\x1b[34m'
const FG_YELLOW = '\x1b[33m'
const FG_MAGENTA = '\x1b[35m'
const FG_WHITE = '\x1b[37m'

export class Logger implements LoggerLike {
    log: (message: unknown, ...optionalParams: unknown[]) => void
    info: (message: unknown, ...optionalParams: unknown[]) => void
    warn: (message: unknown, ...optionalParams: unknown[]) => void
    error: (message: unknown, ...optionalParams: unknown[]) => void
    debug: (message: unknown, ...optionalParams: unknown[]) => void

    constructor(context: string) {
        const tag = `[${context}]`
        this.log = console.log.bind(console, `${FG_GREEN}${tag}${FG_WHITE}`)
        this.info = console.info.bind(console, `${FG_BLUE}${tag}${FG_WHITE}`)
        this.warn = console.warn.bind(console, `${FG_YELLOW}${tag}${FG_WHITE}`)
        this.error = console.error.bind(console, `${FG_RED}${tag}${FG_WHITE}`)
        this.debug = console.debug.bind(console, `${FG_MAGENTA}${tag}${FG_WHITE}`)
    }
}
