import { Logger } from './logger'
import * as DateFnsNs from 'date-fns'
import * as DateFnsTzNs from 'date-fns-tz'
import { lodash } from './lodash'

export type { Day, Duration, Interval, Locale } from 'date-fns'
export type { OptionsWithTZ } from 'date-fns-tz'

const LOG = new Logger('date-fns')

function clone(source: Date) {
    return DateFnsTzNs.toDate(source)
}

function parseISOSafely(iso8601Value: string | undefined, defaultValue: Date) {
    if (!iso8601Value) {
        return defaultValue
    }

    const value = DateFnsNs.parseISO(iso8601Value)
    if (DateFnsNs.isValid(value)) {
        return value
    } else {
        LOG.error(`Invalid ISO value ${iso8601Value}`)
        return defaultValue
    }
}

function parseValidISO(iso8601Value: string | undefined) {
    if (!iso8601Value) {
        return undefined
    }

    const value = DateFnsNs.parseISO(iso8601Value)
    if (DateFnsNs.isValid(value)) {
        return value
    } else {
        LOG.error(`Invalid ISO value ${iso8601Value}`)
        return undefined
    }
}

function coerceToDate(value: unknown) {
    if (lodash.isNil(value)) {
        return undefined
    }

    if (lodash.isNumber(value)) {
        return new Date(value)
    }

    if (lodash.isDate(value)) {
        return value
    }

    if (lodash.isString(value)) {
        if (value.indexOf(':') !== -1) {
            return DateFns.parseISO(value)
        }
        const timeInMillis = +value
        if (lodash.isFinite(timeInMillis)) {
            return new Date(timeInMillis)
        }
    }

    return undefined
}

export const DateFns = {
    clone,
    parseISOSafely,
    parseValidISO,
    coerceToDate,
    ...DateFnsNs,
    ...DateFnsTzNs
}
