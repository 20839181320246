import { ViewFactory } from 'wdc-cube-react'

import { DashboardScope } from '../Dashboard.scopes'
import { DashboardView } from './Dashboard.view'

import { DashboardFilterView } from './DashboardFilter.view'
import { DashboardFilterScope } from '../DashboardFilter.scopes'

import { DashboardActiveMessagesDetailedScope } from '../DashboardActiveMessagesDetailed.scopes'
import { DashboardActiveMessagesDetailedView } from './DashboardActiveMessagesDetailed.view'

import { DashboardCustomerServiceFilterView } from './DashboardCustomerServiceFilter.view'
import { DashboardCustomerServiceFilterScope } from '../DashboardCustomerServiceFilter.scopes'

export function registerViews(rv = ViewFactory.register) {
    rv(DashboardScope, DashboardView)
    rv(DashboardFilterScope, DashboardFilterView)
    rv(DashboardCustomerServiceFilterScope, DashboardCustomerServiceFilterView)
    rv(DashboardActiveMessagesDetailedScope, DashboardActiveMessagesDetailedView)
}
