import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import {
    ChannelConfigurationWorkingHoursEditorFormScope,
    ChannelConfigurationWorkingHoursEditorFormWeekDayScope,
    PeriodActionType,
    PeriodScope
} from '../ChannelConfigurationWorkingHoursEditorForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationWorkingHoursEditorFormStyles as getOrMakeStyles } from './ChannelConfigurationWorkingHoursEditorForm.styles'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationWorkingHoursEditorFormViewProps = IViewProps & {
    scope: ChannelConfigurationWorkingHoursEditorFormScope
}

export function ChannelConfigurationWorkingHoursEditorFormView({
    className,
    scope
}: ChannelConfigurationWorkingHoursEditorFormViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <h3>{texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TITLE}</h3>
            <ViewSlot scope={scope.companiesSelector} />
            <div className={styles.adjustWeekDaysContent}>
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={2} scope={scope.monday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={3} scope={scope.tuesday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={4} scope={scope.wednesday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={5} scope={scope.thursday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={6} scope={scope.friday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={7} scope={scope.saturday} />
                <ChannelConfigurationWorkingHoursEditorFormWeekDayView key={1} scope={scope.sunday} />
            </div>
            <div className={styles.adjustButtons}>
                <div>
                    <Button color="inherit" onClick={scope.onCancel}>
                        {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_DISCARD_BUTTON}
                    </Button>
                </div>
                <div>
                    <Button variant="contained" color="secondary" onClick={scope.onSave}>
                        {texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CONFIRM_BUTTON}
                    </Button>
                </div>
            </div>
        </div>
    )
}

// :: Component(ChannelConfigurationTimesEditorFormWeekDayView)

export type ChannelConfigurationTimesEditorFormWeekDayViewProps = IViewProps & {
    scope: ChannelConfigurationWorkingHoursEditorFormWeekDayScope
}

export function ChannelConfigurationWorkingHoursEditorFormWeekDayView({
    className,
    scope
}: ChannelConfigurationTimesEditorFormWeekDayViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    const children = [] as JSX.Element[]

    if (scope.checked) {
        let i = 0

        for (const periodScope of scope.periods) {
            if (children.length > 0) {
                children.push(
                    <span key={i++} className={styles.adjustEndInputTime}>
                        {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_AND_BETWEEN_TIMES}
                    </span>
                )
            }
            children.push(<PeriodView key={i++} scope={periodScope} />)
        }

        switch (scope.periodActionType) {
            case PeriodActionType.REMOVE:
                children.push(
                    <div key={i++} className={styles.adjustRemoveButton}>
                        <IconButton onClick={scope.onPeriodActionClicked} color="error">
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </div>
                )
                break
            case PeriodActionType.ADD:
                children.push(
                    <div key={i++} className={styles.adjustAddButton}>
                        <IconButton onClick={scope.onPeriodActionClicked} color="secondary">
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </div>
                )
                break
        }
    }
    return (
        <div className={clsx(styles.adjustItemsAtStart, className)}>
            <FormGroup className={styles.adjustItemCenter}>
                <FormControlLabel
                    control={<Checkbox checked={scope.checked} onChange={scope.onCheckChanged} color="secondary" />}
                    label={scope.caption}
                />
            </FormGroup>
            <div className={styles.adjustItemCenter}>{children}</div>
            {scope.error ? <div className={styles.errorMsg}>{scope.error}</div> : undefined}
        </div>
    )
}

type PeriodViewProps = IViewProps & {
    scope: PeriodScope
}

function PeriodView({ scope }: PeriodViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={styles.periodPane}>
            <ViewSlot scope={scope.startTime} />
            <span className={styles.spaceBetweenInputTime}>
                {texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SPACE_BETWEEN_TIMES}
            </span>
            <ViewSlot scope={scope.endTime} />
        </div>
    )
}
