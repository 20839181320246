/* eslint-disable react/react-in-jsx-scope */
import { Draggable } from 'react-beautiful-dnd'
import { Card, Typography } from '@mui/material'

interface ItemProps {
    name: string
    index: number
}

const SegmentationItem = ({ name, index }: ItemProps) => {
    return (
        <Draggable draggableId={index + ''} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={index}
                    id={index + ''}
                    sx={(theme) => ({
                        background: theme.palette.secondary.main,
                        padding: '0 12px',
                        height: '32px',
                        borderRadius: 16,
                        minWidth: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    })}
                >
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.common.white,
                            fontSize: '13px',
                            whiteSpace: 'nowrap',
                            overflow: 'clip',
                            width: '100%'
                        })}
                    >
                        {`${index + 1}° ${name}`}
                    </Typography>
                </Card>
            )}
        </Draggable>
    )
}

export default SegmentationItem
