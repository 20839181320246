import { makeStyles } from 'tss-react/mui'

export const getOrMakeChannelConfigurationSelfRescueFormStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 22px',
        width: 750,
        flexShrink: 1,
        gap: 20
    },
    customHeaderFont: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: 0.15
    },
    content: {
        width: '100%'
    },
    adjustFooterButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: '#fff'
    }
}))
