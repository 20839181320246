import { lodash, StringCICompare } from '@syonet/lang'
import { ChannelConfigurationFormKeys, KeysFactory } from 'src/Constants'
import { action, Logger, Presenter } from 'wdc-cube'
import {
    ChannelCompaniesWorkingHours,
    ChannelWorkingHoursConfig,
    HourMinuteTime,
    PeriodTime
} from './ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import {
    ChannelConfigurationFormWorkingHourItemScope,
    ChannelConfigurationFormWorkingHoursScope
} from './ChannelConfigurationForm.scopes'
import { TextsProvider } from './texts'

const LOG = Logger.get('ChannelConfigurationFormWorkingHoursPresenter')

const texts = TextsProvider.get()

type ChannelConfigurationFormTimesItem = {
    id: number
    companyNames: string[]
    frequencyDescription: string[]
    timeDescription: string[]

    form: ChannelCompaniesWorkingHours
}

export class ChannelConfigurationFormWorkingHoursPresenter extends Presenter<
    ChannelConfigurationFormWorkingHoursScope,
    ChannelConfigurationFormPresenter
> {
    private listingItemMap = new Map<number, ChannelConfigurationFormTimesItem>()

    private firstConfigIdWithError?: number

    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner, owner.scope.workingHoursTab, owner.updateManager)
    }

    public isCompanyBeingUsed(companyId: number): boolean {
        for (const item of this.listingItemMap.values()) {
            if (item.id !== -1 && item.form) {
                if (item.form.companies.indexOf(companyId) !== -1) {
                    return true
                }
            }
        }
        return false
    }

    public onAssignCompanyById(companyId: number) {
        LOG.debug(`onAssignCompanyById(${companyId})`)

        const formArray: ChannelCompaniesWorkingHours[] = []

        for (const item of this.listingItemMap.values()) {
            if (item.id !== -1 && item.form) {
                formArray.push(item.form)
            }
        }
        this.refresh(formArray)
    }

    public onUnassignCompanyById(companyId: number) {
        const formArray: ChannelCompaniesWorkingHours[] = []

        for (const item of this.listingItemMap.values()) {
            if (item.id !== -1 && item.form) {
                const idx = item.form.companies.indexOf(companyId)
                if (idx !== -1) {
                    item.form.companies.splice(idx, 1)
                }

                if (item.form.companies.length > 0) {
                    formArray.push(item.form)
                }
            }
        }

        this.refresh(formArray)
    }

    public openFirstFormWithErrors() {
        this.owner.scope.tabIndex = this.scope.index
        if (this.firstConfigIdWithError !== undefined) {
            this.flipToForm(this.firstConfigIdWithError)
        } else {
            this.scope.blink = 'add-working-hours-button'
        }
    }

    public openFirstFormWith24Warning() {
        this.owner.scope.tabIndex = this.scope.index
        this.scope.blink = 'add-working-hours-button'
    }

    private bindListeners() {
        this.scope.onOpenEditor = this.onOpenEditor.bind(this)
    }

    async initializeState(keys: ChannelConfigurationFormKeys, listingData: ChannelWorkingHoursConfig) {
        this.bindListeners()

        await this.synchronizeState(keys, listingData)
    }

    public async synchronizeState(keys: ChannelConfigurationFormKeys, listingData: ChannelWorkingHoursConfig) {
        this.refresh(this.mergeChanges(new Map(), undefined, listingData.entries))
    }

    private async flipToForm(itemId: number) {
        const targetKeys = KeysFactory.channelConfigurationTimesEditorForm(this.owner.app)
        targetKeys.channelId(this.owner.channelId)
        targetKeys.timesId(itemId)
        await this.owner.app.flipToIntent(targetKeys.intent)
    }

    @action()
    private async onOpenEditor() {
        await this.flipToForm(-1)
    }

    refresh(listingData: ChannelCompaniesWorkingHours[]) {
        const companyMap = this.owner.getSelectedCompanyMap()

        this.listingItemMap.clear()

        const holeDayLabel = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_HOLE_DAY_LABEL

        const timeDescriptionBuilder = (
            dayName: string,
            map: Map<string, Map<string, boolean>>,
            periods: PeriodTime[]
        ) => {
            const result = [] as string[]

            const firstPeriod = periods[0]
            const secondPeriod = periods[1]

            let totalHours = 0

            if (firstPeriod) {
                result.push(HourMinuteTime_toString(firstPeriod.startTime))
                result.push(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SPACE_BETWEEN_TIMES)
                result.push(HourMinuteTime_toString(firstPeriod.endTime))

                const startTimeInHours = HourMinuteTime_toHours(firstPeriod.startTime)
                const endTimeInHours = HourMinuteTime_toHours(firstPeriod.endTime)
                totalHours += endTimeInHours - startTimeInHours
            }

            if (secondPeriod) {
                result.push(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_AND_BETWEEN_TIMES)
                result.push(HourMinuteTime_toString(secondPeriod.startTime))
                result.push(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SPACE_BETWEEN_TIMES)
                result.push(HourMinuteTime_toString(secondPeriod.endTime))

                const startTimeInHours = HourMinuteTime_toHours(secondPeriod.startTime)
                const endTimeInHours = HourMinuteTime_toHours(secondPeriod.endTime)
                totalHours += endTimeInHours - startTimeInHours
            }

            if (totalHours >= 24) {
                result.length = 1
                result[0] = holeDayLabel
            }

            const key = result.join(' ')

            let dayNameMap = map.get(key)
            if (!dayNameMap) {
                dayNameMap = new Map<string, boolean>()
                map.set(key, dayNameMap)
            }

            dayNameMap.set(dayName, true)

            return key
        }

        const notUsedCompanyMap = new Map(companyMap)
        for (const listingEntry of listingData) {
            for (const companyId of listingEntry.companies) {
                notUsedCompanyMap.delete(companyId)
            }
        }

        if (notUsedCompanyMap.size > 0) {
            const fallbackEntry = ChannelTimesForm_buildFallback()

            for (const companyId of notUsedCompanyMap.keys()) {
                fallbackEntry.companies.push(companyId)
            }

            listingData = [fallbackEntry, ...listingData]
        } else if (listingData.length === 0) {
            const fallbackEntry = ChannelTimesForm_buildFallback()
            listingData = [fallbackEntry]
        }

        for (const listingEntry of listingData) {
            const companyNames = [] as string[]
            for (const companyId of listingEntry.companies) {
                const companyOption = companyMap.get(companyId)
                if (companyOption) {
                    companyNames.push(companyOption.name)
                }
            }

            companyNames.sort(StringCICompare)

            const frequencyDescription = new Map<string, boolean>()
            const timeDescription = new Map<string, Map<string, boolean>>()

            if (listingEntry.week) {
                let count = 0

                if (listingEntry.week.monday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_MONDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.monday)
                    count++
                }

                if (listingEntry.week.tuesday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_TUESDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.tuesday)
                    count++
                }

                if (listingEntry.week.wednesday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_WEDNESDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.wednesday)
                    count++
                }

                if (listingEntry.week.thursday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_THURSDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.thursday)
                    count++
                }

                if (listingEntry.week.friday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_FRIDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.friday)
                    count++
                }

                if (listingEntry.week.saturday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_SATURDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.saturday)
                    count++
                }

                if (listingEntry.week.sunday) {
                    const dayName = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_SUNDAY
                    frequencyDescription.set(dayName, true)
                    timeDescriptionBuilder(dayName, timeDescription, listingEntry.week.sunday)
                    count++
                }

                if (count === 7) {
                    frequencyDescription.clear()
                    frequencyDescription.set(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_ALL_DAYS, true)
                }
            }

            this.listingItemMap.set(listingEntry.id, {
                id: listingEntry.id,
                companyNames,
                frequencyDescription: StringIteratorToArray(frequencyDescription.keys()),
                timeDescription: TimeDescriptionMapToArray(timeDescription),
                form: listingEntry
            })
        }

        this.transferStateToScopes()
    }

    private transferStateToScopes() {
        let i = 0
        for (const [itemId, item] of this.listingItemMap.entries()) {
            let menuItemScope = this.scope.entries.get(i)
            if (!menuItemScope) {
                menuItemScope = new ChannelConfigurationFormWorkingHourItemScope()
                menuItemScope.onEdit = this.onTimesItemEdit.bind(this, menuItemScope)
                menuItemScope.onDelete = this.onMenuItemDelete.bind(this, menuItemScope)
                this.scope.entries.set(i, menuItemScope)
            }

            menuItemScope.id = itemId
            menuItemScope.frequencyDescription.assign(item.frequencyDescription)
            menuItemScope.workingHoursDescription.assign(item.timeDescription)

            let j = 0
            for (const companyName of item.companyNames) {
                menuItemScope.companyNames.set(j++, companyName)
            }
            menuItemScope.companyNames.length = j

            i++
        }

        this.scope.entries.length = i
    }

    @action()
    private async onTimesItemEdit(item: ChannelConfigurationFormWorkingHourItemScope) {
        await this.flipToForm(item.id)
    }

    private mergeChanges(
        usedCompanyMap: Map<number, boolean>,
        form?: ChannelCompaniesWorkingHours,
        listing?: ChannelCompaniesWorkingHours[]
    ) {
        const listingData: { weekId: string; form: ChannelCompaniesWorkingHours }[] = []

        const map = new Map<string, ChannelCompaniesWorkingHours>()

        if (form) {
            for (const companyId of form.companies) {
                usedCompanyMap.set(companyId, true)
            }

            const entry = {
                weekId: computeWeekId(form.week),
                form
            }

            map.set(entry.weekId, entry.form)
            listingData.push(entry)
        }

        if (!listing) {
            listing = []
            for (const entry of this.listingItemMap.values()) {
                if (entry.id < 0) {
                    continue
                }
                listing.push(entry.form)
            }
        }

        for (const formEntry of listing) {
            const companyIdMap = new Map<number, boolean>()

            for (const companyId of formEntry.companies) {
                if (!usedCompanyMap.has(companyId)) {
                    companyIdMap.set(companyId, true)
                    usedCompanyMap.set(companyId, true)
                }
            }

            if (companyIdMap.size > 0) {
                const weekId = computeWeekId(formEntry.week)

                const existingNewEntry = map.get(weekId)
                if (existingNewEntry) {
                    Array.prototype.push.apply(existingNewEntry.companies, formEntry.companies)
                } else {
                    const existingNewEntry = {
                        weekId,
                        form: {
                            id: formEntry.id,
                            companies: [] as number[],
                            week: { ...formEntry.week }
                        }
                    }

                    for (const companyId of companyIdMap.keys()) {
                        existingNewEntry.form.companies.push(companyId)
                    }

                    map.set(existingNewEntry.weekId, existingNewEntry.form)
                    listingData.push(existingNewEntry)
                }
            }
        }

        listingData.sort((a, b) => StringCICompare(a.weekId, b.weekId))

        const result: ChannelCompaniesWorkingHours[] = []

        for (const newEntry of listingData) {
            result.push(newEntry.form)
        }

        return result
    }

    @action()
    private async onMenuItemDelete(item: ChannelConfigurationFormWorkingHourItemScope) {
        this.doMenuItemDelete(item.id)
    }

    private doMenuItemDelete(itemId: number) {
        const deletionListingEntry = this.listingItemMap.get(itemId)
        if (deletionListingEntry) {
            const usedCompanyMap = new Map<number, boolean>()

            for (const companyId of deletionListingEntry.form.companies) {
                usedCompanyMap.set(companyId, true)
            }

            this.refresh(this.mergeChanges(usedCompanyMap))
        }
    }

    public getForm(entryId: number): ChannelCompaniesWorkingHours {
        if (entryId !== -1) {
            const entry = this.listingItemMap.get(entryId)
            if (entry) {
                return entry.form
            }
        }

        return {
            id: -1,
            companies: [],
            week: {
                monday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 18, m: 0 } }],
                tuesday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 18, m: 0 } }],
                wednesday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 18, m: 0 } }],
                thursday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 18, m: 0 } }],
                friday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 18, m: 0 } }],
                saturday: [{ startTime: { h: 8, m: 0 }, endTime: { h: 12, m: 0 } }]
            }
        }
    }

    public async saveForm(form: ChannelCompaniesWorkingHours) {
        if (form.companies.length > 0) {
            const usedCompanyMap = new Map<number, boolean>()

            if (form.id < 0) {
                for (const companyId of form.companies) {
                    usedCompanyMap.set(companyId, true)
                }

                let maxId = 0
                for (const entry of this.listingItemMap.values()) {
                    maxId = Math.max(maxId, entry.id)
                }

                form.id = maxId + 1
            } else {
                const existingEntry = this.listingItemMap.get(form.id)
                if (existingEntry) {
                    for (const companyId of existingEntry.form.companies) {
                        usedCompanyMap.set(companyId, true)
                    }
                }
            }

            this.refresh(this.mergeChanges(usedCompanyMap, form))
        }
        this.update()
    }

    public beforeUpdate() {
        let hasEmptyTimesAtSomeDay = false
        let hasPartialFulfilled = false
        let hasInvalidHours = false
        let hasWarning = false

        this.firstConfigIdWithError = undefined

        const checker = new WorkingDayHoursChecker()

        for (const entryScope of this.scope.entries) {
            const itemId = entryScope.id
            let warningIdx = 0
            let errorIdx = 0

            if (itemId === -1) {
                entryScope.warnings.set(warningIdx++, texts.CHANNEL_CONFIGURATION_WORKING_HOURS_DEFAULT_VALUE)
                entryScope.warnings.length = warningIdx
                hasWarning = true
            } else {
                const entry = this.listingItemMap.get(itemId)
                if (entry && entry.form) {
                    const week = entry.form.week

                    checker.clear()

                    for (const periodArray of [
                        week.sunday,
                        week.monday,
                        week.tuesday,
                        week.wednesday,
                        week.thursday,
                        week.friday,
                        week.saturday
                    ]) {
                        if (periodArray) {
                            checker.checkTwoPeriod(periodArray[0], periodArray[1])
                        }
                    }

                    let hasError = false

                    if (checker.emptyTimes > 0) {
                        entryScope.errors.set(
                            errorIdx++,
                            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_MISSING_HOUR_CONFIGURATION
                        )
                        hasError = hasEmptyTimesAtSomeDay = true
                    }

                    if (checker.partialFulfilledCount > 0) {
                        entryScope.errors.set(
                            errorIdx++,
                            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_PARTIALLY_PERIOD_CONFIGURATION
                        )
                        hasError = hasPartialFulfilled = true
                    }

                    if (checker.invalidTimes > 0) {
                        entryScope.errors.set(errorIdx++, texts.CHANNEL_CONFIGURATION_WORKING_HOURS_ICONSISTENT_HOUR)
                        hasError = hasInvalidHours = true
                    }

                    if (hasError && this.firstConfigIdWithError === undefined) {
                        this.firstConfigIdWithError = itemId
                    }
                }
            }

            entryScope.errors.length = errorIdx
            entryScope.warnings.length = warningIdx
        }

        if (hasInvalidHours) {
            this.owner.validator.workingHourInvalidHours()
        } else if (hasPartialFulfilled || hasEmptyTimesAtSomeDay) {
            this.owner.validator.workingHourPartialFulfilled()
        } else {
            this.owner.validator.workingHourOk()
        }

        this.owner.validator.workingHourExisting24(!hasWarning)
    }

    public extractFormData(): ChannelWorkingHoursConfig {
        const entries: ChannelCompaniesWorkingHours[] = []

        for (const entry of this.listingItemMap.values()) {
            if (entry.form && entry.id !== -1) {
                entries.push(lodash.cloneDeep(entry.form))
            }
        }

        return { entries }
    }
}

class WorkingDayHoursChecker {
    public emptyTimes = 0
    public partialFulfilledCount = 0
    public invalidTimes = 0

    private __previousMomentInHours = 0

    public clear() {
        this.emptyTimes = 0
        this.partialFulfilledCount = 0
        this.invalidTimes = 0
        this.__previousMomentInHours = 0
    }

    public checkTwoPeriod(period0?: PeriodTime, period1?: PeriodTime) {
        this.__previousMomentInHours = 0

        if (period0) {
            this.checkPeriod(period0)
        }

        if (period1) {
            this.checkPeriod(period1)
        }
    }

    private checkPeriod(period: PeriodTime) {
        let fulfilledCount = 0

        if (period.startTime) {
            fulfilledCount++

            if (this.checkIfTimeIsValid(period.startTime)) {
                const momentInHours = HourMinuteTime_toHours(period.startTime)
                if (this.__previousMomentInHours >= momentInHours) {
                    this.invalidTimes++
                } else {
                    this.__previousMomentInHours = momentInHours
                }
            } else {
                this.invalidTimes++
            }
        }

        if (period.endTime) {
            fulfilledCount++

            if (this.checkIfTimeIsValid(period.endTime)) {
                const hours = HourMinuteTime_toHours(period.endTime)
                if (this.__previousMomentInHours >= hours) {
                    this.invalidTimes++
                } else {
                    this.__previousMomentInHours = hours
                }
            } else {
                this.invalidTimes++
            }
        }

        if (fulfilledCount === 0) {
            this.emptyTimes++
        } else if (fulfilledCount < 2) {
            this.partialFulfilledCount++
        }
    }

    private checkIfTimeIsValid(time: HourMinuteTime) {
        if (time.h < 0 || time.h >= 24) {
            return false
        }

        if (time.m < 0 || time.m >= 60) {
            return false
        }

        return true
    }
}

const ChannelTimesForm_buildFallback = () => {
    const fallbackEntry: ChannelCompaniesWorkingHours = {
        id: -1,
        companies: [],
        week: {
            sunday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            monday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            tuesday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            wednesday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            thursday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            friday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }],
            saturday: [{ startTime: { h: 0, m: 0 }, endTime: { h: 24, m: 0 } }]
        }
    }

    return fallbackEntry
}

const HourMinuteTime_toHours = (time?: HourMinuteTime) => {
    if (time) {
        const hh = Math.max(Math.min(time.h, 24), 0)
        const mm = Math.max(Math.min(time.m, 60), 0)
        return hh + mm / 60
    } else {
        return 0
    }
}

const HourMinuteTime_toString = (time?: HourMinuteTime) => {
    if (time) {
        const hh = time.h < 0 || time.h > 24 ? '--' : time.h < 10 ? '0' + time.h : String(time.h)
        const mm = time.m < 0 || time.m > 59 ? '--' : time.m < 10 ? '0' + time.m : String(time.m)
        return `${hh}:${mm}`
    } else {
        return '--:--'
    }
}

const StringIteratorToArray = (it: IterableIterator<string>) => {
    const result = [] as string[]

    for (const s of it) {
        result.push(s)
    }

    return result
}

const TimeDescriptionMapToArray = (map: Map<string, Map<string, boolean>>) => {
    const result = [] as string[]

    for (const [timeDescription, dayNameMap] of map.entries()) {
        let description = timeDescription

        if (dayNameMap.size < 7 && map.size > 1) {
            const dayNameArray = StringIteratorToArray(dayNameMap.keys())
            if (dayNameArray.length > 0) {
                description += `; ${dayNameArray[0]}`

                const ilen = dayNameArray.length
                const ilast = ilen - 1
                for (let i = 1; i < ilast; i++) {
                    description += `, ${dayNameArray[i]}`
                }

                if (ilen > 1) {
                    description += ` ${texts.CHANNEL_CONFIGURATION_WORKING_HOURS_AND_BETWEEN_TIMES} ${dayNameArray[ilast]}`
                }
            }
        }

        result.push(description)
    }

    return result
}

const computeWeekId = (function () {
    const computePeriodId = (periods: PeriodTime[]) => {
        const result = [] as string[]

        for (const period of periods) {
            result.push(`${HourMinuteTime_toString(period.startTime)}:${HourMinuteTime_toString(period.endTime)}`)
        }

        return result.join(';')
    }

    return (week: ChannelCompaniesWorkingHours['week']) => {
        let weekId = ''

        if (week.sunday) {
            weekId += '&' + computePeriodId(week.sunday)
        }

        if (week.monday) {
            weekId += '&' + computePeriodId(week.monday)
        }

        if (week.tuesday) {
            weekId += '&' + computePeriodId(week.tuesday)
        }

        if (week.wednesday) {
            weekId += '&' + computePeriodId(week.wednesday)
        }

        if (week.thursday) {
            weekId += '&' + computePeriodId(week.thursday)
        }

        if (week.friday) {
            weekId += '&' + computePeriodId(week.friday)
        }

        if (week.saturday) {
            weekId += '&' + computePeriodId(week.saturday)
        }

        return weekId
    }
})()
