import { ChipPanelScope } from 'src/components/chippanel'
import { MouseEvent, TextChangeEvent } from 'src/utils'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import { KpiValueScope } from './common.scopes'
import { DashboardCustomerServiceFilterScope } from './DashboardCustomerServiceFilter.scopes'

export { KpiValueScope }
export type { MouseEvent, TextChangeEvent }

@Observable
export class CustomerServiceEntryScope extends Scope {
    @observe() id?: string
    @observe() customerName?: string
    @observe() companyName?: string
    @observe() departamentName?: string
    @observe() userName?: string
    @observe() unreadSince?: string
    @observe() showTicketButton = false

    @observe() onCustomerClick = Scope.ASYNC_ACTION
    @observe() onTicketClick = Scope.ASYNC_ACTION
}

@Observable
export class DashboardCustomerServiceTableScope extends Scope {
    readonly filterChips = new ChipPanelScope()
    readonly filterForm = new DashboardCustomerServiceFilterScope()
    readonly entries = new ObservableArray<CustomerServiceEntryScope>(this)

    @observe() loading = true
    @observe() showAll = false
    @observe() itemsPerPageOptions = [10, 25, 50, 100]
    @observe() pageIndex = 0
    @observe() itemsPerPage = 10
    @observe() itemCount = 0

    @observe() onChangePageIndex = Scope.ASYNC_ACTION_TWO<MouseEvent, number>()
    @observe() onChangeItemsPerPage = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onToggleVisualization = Scope.ASYNC_ACTION
    @observe() onChangeTableLabel = Scope.SYNC_ACTION
    @observe() onMoreInfo = Scope.ASYNC_ACTION
}

@Observable
export class DashboardCustomerServiceScope extends Scope {
    readonly kpiContactQuantity = new KpiValueScope()
    readonly kpiNewContactQuantity = new KpiValueScope()
    readonly kpiHumanTicketQuantity = new KpiValueScope()
    readonly kpiScheduledTiketsQuantity = new KpiValueScope()
    readonly table = new DashboardCustomerServiceTableScope()

    @observe() onScrollToTop = Scope.SYNC_ACTION
}
