import { lodash, StringCICompare } from '@syonet/lang'
import { PagingDTO } from 'src/feature/dashboard/Dashboard.service'
import { IPresenterOwner, IUpdateManager, Logger, NOOP_PROMISE_VOID, NOOP_VOID, Presenter } from 'wdc-cube'
import { BaseEvent, MultipleValuesAutoCompleteScope, SimpleValueAutoCompleteScope } from './AutoCompleteField.scopes'

const LOG = Logger.get('AutoCompleteField')

type OptionValue<V> = { id: V; name: string; phone?: string }

export class AutoCompleteField<V, T extends OptionValue<V> = OptionValue<V>> extends Presenter<
    SimpleValueAutoCompleteScope<T> | MultipleValuesAutoCompleteScope<T>
> {
    public onSearch?: (text: string, maxPageSize: number) => Promise<PagingDTO<T>>

    public onGetSelectedValues?: (response: Map<V, T>) => void

    private timeoutHandler?: NodeJS.Timeout

    private doSearchCb: (text: string) => void

    private __maxCacheLen = 100

    private __pageSize = 10

    private noFilterCacheArray: T[] = []

    private noFilterCacheInitialized = false

    private allItemsOnCache = false

    private tempCacheArray: T[] = []

    private tempCacheKey?: string

    constructor(
        owner: IPresenterOwner,
        scope: SimpleValueAutoCompleteScope<T> | MultipleValuesAutoCompleteScope<T>,
        updateManager?: IUpdateManager
    ) {
        super(owner, scope, updateManager)
        this.doSearchCb = this.doSearch.bind(this)

        this.scope.onFocus = this.onFocus.bind(this)
        this.scope.onInputChange = this.onInputChange.bind(this)
        this.scope.onChange = this.onChange.bind(this)
    }

    private onFocus() {
        this.search('')
    }

    private async onInputChange(evt: BaseEvent, newValue: string) {
        this.search(newValue)
    }

    private async onChange(evt: BaseEvent, newValue: T[] | null) {
        if (this.scope instanceof MultipleValuesAutoCompleteScope) {
            this.scope.value = newValue ?? []
        } else {
            this.scope.value = newValue as T | null | undefined
        }
    }

    public release() {
        this.scope.onInputChange = NOOP_VOID
        this.scope.onChange = NOOP_PROMISE_VOID
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }

    public get maxCacheLen() {
        return this.__maxCacheLen
    }

    public set maxCacheLen(value: number) {
        this.__maxCacheLen = value
    }

    public get pageSize() {
        return this.__pageSize
    }

    public set pageSize(value: number) {
        if (value) {
            this.__pageSize = value
        }
    }

    public clearCache() {
        this.noFilterCacheArray.length = 0
        this.noFilterCacheInitialized = false
        this.allItemsOnCache = false

        this.tempCacheArray.length = 0
        this.tempCacheKey = undefined
    }

    public search(text: string) {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }

        const searchText = text.trim()

        if (searchText === '' && this.noFilterCacheInitialized) {
            this.handleResponseFromCache('', this.noFilterCacheArray)
            return
        }

        if (this.tempCacheKey && searchText.startsWith(this.tempCacheKey)) {
            this.handleResponseFromCache(searchText, this.tempCacheArray)
            return
        }

        this.timeoutHandler = setTimeout(this.doSearchCb, 300, searchText)
    }

    private doSearch(searchText: string) {
        if (this.onSearch) {
            this.onSearch(searchText, this.__pageSize)
                .then(this.handleResponse.bind(this, searchText))
                .catch((caught) => {
                    LOG.error('Searching options', caught)
                })
        }
    }

    private handleResponse(searchText: string, response: PagingDTO<T>) {
        this.tempCacheKey = undefined
        this.tempCacheArray.length = 0

        if (response.meta.totalPages === 1 && response.meta.totalItems <= response.meta.itemsPerPage) {
            // Condition when there are no extra itens with the current filter
            const cacheArray = [...response.entries].sort((a, b) => StringCICompare(a.name, b.name))

            if (searchText) {
                this.tempCacheKey = searchText
                this.tempCacheArray = cacheArray
            }

            if (searchText === '') {
                this.noFilterCacheArray = [...cacheArray]
                this.noFilterCacheInitialized = true
            }
        }
        this.fill(response.entries)
    }

    private handleResponseFromCache(text: string, cacheArray: T[]) {
        const options: T[] = []
        this.pageSize = cacheArray.length || 10
        if (text) {
            const filterText = text.toLocaleLowerCase()

            for (const option of cacheArray) {
                const filterName = option.name.toLocaleLowerCase()
                if (filterName.includes(filterText)) {
                    options.push(option)

                    if (options.length >= this.__pageSize) {
                        break
                    }
                }
                const filterPhone = option.phone
                if (filterPhone?.includes(filterText)) {
                    options.push(option)

                    if (options.length >= this.__pageSize) {
                        break
                    }
                }
            }
        } else {
            for (const option of cacheArray) {
                options.push(option)
                if (options.length >= this.__pageSize) {
                    break
                }
            }
        }

        this.fill(options)
    }

    public fill(options: T[]) {
        const mapSelectedOptionMap = new Map<V, T>()

        {
            // Assure temporary selected values is among selected values
            const currentValue = this.scope.value
            if (currentValue) {
                if (lodash.isArray(currentValue)) {
                    for (const selectedValue of currentValue) {
                        mapSelectedOptionMap.set(selectedValue.id, selectedValue)
                    }
                } else {
                    mapSelectedOptionMap.set(currentValue.id, currentValue)
                }
            }
        }

        if (this.onGetSelectedValues) {
            this.onGetSelectedValues(mapSelectedOptionMap)
        }

        let i = 0

        const existingOptionById = new Map<V, boolean>()
        const existingOptionByName = new Map<string, boolean>()
        const existingOptionByPhone = new Map<string, boolean>()
        if (options && options.length > 0) {
            for (const newOption of options) {
                const selectedValue = mapSelectedOptionMap.get(newOption.id)

                if (selectedValue) {
                    selectedValue.name = newOption.name
                    selectedValue.phone = newOption.phone
                    mapSelectedOptionMap.delete(selectedValue.id)
                }

                if (!existingOptionById.has(newOption.id) && !existingOptionByName.has(newOption.name)) {
                    existingOptionById.set(newOption.id, true)
                    existingOptionByName.set(newOption.name, true)

                    if (newOption.phone && !existingOptionByPhone.has(newOption.phone)) {
                        existingOptionByPhone.set(newOption.phone, true)
                    }

                    this.scope.options.set(i++, {
                        id: newOption.id,
                        name: newOption.name,
                        ...(newOption.phone && { phone: newOption.phone })
                    } as T)
                }
            }
        }

        // Preserve selected values
        if (mapSelectedOptionMap.size > 0) {
            for (const selectedValue of mapSelectedOptionMap.values()) {
                if (
                    !existingOptionById.has(selectedValue.id) &&
                    !existingOptionByName.has(selectedValue.name) &&
                    selectedValue.phone &&
                    !existingOptionByPhone.has(selectedValue.phone)
                ) {
                    this.scope.options.set(i++, { ...selectedValue })
                }
            }
        }

        this.scope.options.length = i

        // Sort  options
        this.scope.options.sort((a, b) => StringCICompare(a.name, b.name))
    }
}
