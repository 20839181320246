import DateFnsUtils from '@date-io/date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TimePicker from '@mui/lab/TimePicker'
import { Stack, TextField } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { TimePickerScope } from '..'
import { getOrMakeStyles } from './TimePicker.styles'

export type TimePickerViewProps = IViewProps & {
    scope: TimePickerScope
}

export function TimePickerView({ className, scope }: TimePickerViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    const hasError = scope.error ? true : false

    return (
        <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterDateFns}>
            <Stack className={clsx(styles.view, className)} spacing={3}>
                <TimePicker
                    ampm={false}
                    label={scope.label}
                    value={scope.value}
                    onChange={scope.onChange}
                    renderInput={(params) => (
                        <TextField {...params} error={hasError || params.error} sx={{ width: 150 }} />
                    )}
                />
            </Stack>
        </LocalizationProvider>
    )
}
