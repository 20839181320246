import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { EurekaService } from 'src/services'
import { Logger, NOOP_VOID } from 'wdc-cube'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { TextsProvider } from '../texts'
import { WhatsAppScope } from '../WhatsApp.scopes'
import { getOrMakeWhatsAppStyles as getOrMakeStyles } from './WhatsApp.styles'
import { handleSyoWhatsAppBaseUrl } from '@whatsapp/communication'

// @Inject
const texts = TextsProvider.get()

const LOG = Logger.get('WhatsAppView')

export type WhatsAppViewProps = IViewProps & {
    scope: WhatsAppScope
}

export function WhatsAppView({ className, scope }: WhatsAppViewProps) {
    bindUpdate(React, scope)

    const { toPropString } = WhatsAppViewContext.getOrCreate(scope)

    const { classes: styles } = getOrMakeStyles()

    let elem: ReactElement = <></>

    if (scope.loading) {
        elem = (
            <div className={styles.loadingContainer}>
                <CircularProgress color="secondary" disableShrink />
                <div className={styles.loadingText}>{texts.LOADING_CONTENT_DESCRIPTION}</div>
            </div>
        )
    } else if (scope.error) {
        elem = (
            <div className={styles.errorContainer}>
                <ErrorOutlineRoundedIcon fontSize="large" color="error" />
                <div className={styles.errorText}>{texts.FAILED_DURING_LOAD_WHATSAPP_COMPONENT}</div>
            </div>
        )
    } else {
        elem = React.createElement('syo-whats-app', {
            class: styles.syoWhatsApp,
            eureka: toPropString(scope.eureka, HAS_SYO_WHATS_APP_ERROR_ON_CONFIG?.eureka ?? ''),
            customername: toPropString(scope.customerName, ''),
            customerphone: toPropString(scope.customerPhone, ''),
            companyphone: toPropString(scope.companyPhone, ''),
            companyid: toPropString(scope.companyId, ''),
            username: toPropString(scope.userName, ''),
            room: toPropString(scope.room, ''),
            templatefields: toPropString(scope.templatefields, ''),
            sendhsm: toPropString(scope.sendHsm, 'false'),
            readonly: toPropString(scope.readOnly, 'false')
        })
    }

    return <div className={clsx(styles.view, className)}>{elem}</div>
}

const getBaseUrl = () => {
    let url = localStorage.getItem('URL_CDN_WHATSAPP')
    if (!url) {
        url = handleSyoWhatsAppBaseUrl(window.location.hostname)
    }
    return url
}

const BASE_URL = getBaseUrl()

let SCRIPT_LOADED = false

const HAS_SYO_WHATS_APP_ERROR_ON_CONFIG = (function () {
    let cfg = {
        eureka: EurekaService.singleton().endpoint(),
        socket_io_worker_js: BASE_URL
    }

    const s = localStorage.getItem('syowhatsapp')
    if (s) {
        try {
            cfg = JSON.parse(s)
        } catch (caught) {
            LOG.error('Parsing syowhatsapp from LocalStorage', caught)
        }
    }

    return cfg
})()

class WhatsAppViewContext {
    static readonly create = () => new WhatsAppViewContext()

    static getOrCreate(scope: WhatsAppScope) {
        const [ctx] = React.useState(WhatsAppViewContext.create)
        ctx.prepare(scope)
        return ctx
    }

    scope!: WhatsAppScope

    private readonly prepare = (scope: WhatsAppScope) => {
        this.scope = scope
        React.useEffect(this.onComponentDidMount, [])
        return this
    }

    readonly toPropString = (value: unknown, defaultValue: string) => {
        if (value !== null && value !== undefined) {
            return String(value)
        } else if (defaultValue !== null && defaultValue !== undefined) {
            return defaultValue
        } else {
            return ''
        }
    }

    // :: Internal

    private readonly onComponentDidMount = () => {
        this.assureScriptIsLoaded()
    }

    private readonly assureScriptIsLoaded = () => {
        if (SCRIPT_LOADED) {
            this.scope.loading = false
            return
        }

        const scriptElementArray = document.querySelectorAll('script')
        for (const entry of scriptElementArray.entries()) {
            const scriptElm = entry[1]
            if (scriptElm.src.includes('/syoWhatsApp.js') || scriptElm.src.includes('/direflowBundle.js')) {
                SCRIPT_LOADED = true
                this.scope.loading = false
                return
            }
        }

        this.scope.loading = true

        const script = document.createElement('script')
        script.src = BASE_URL + '/syoWhatsApp.js'
        // Para desenvolvimento descomente a linha abaixo
        //script.src = 'http://localhost:3000/direflowBundle.js'
        script.async = true

        script.onload = () => {
            this.scope.loading = false
            script.onload = script.onerror = NOOP_VOID
        }

        script.onerror = () => {
            this.scope.loading = false
            this.scope.error = true
            script.onload = script.onerror = NOOP_VOID
        }

        document.getElementsByTagName('head')[0].appendChild(script)
    }
}
