export const Texts = {
    MENU_TITLE: 'WhatsApp',
    MENU_DASHBOARD_OPTION: 'DASHBOARD',
    MENU_CHATS_OPTION: 'Conversaciones',
    MENU_SETTINGS_OPTION_TITLE: 'Ajustes',
    MENU_CHANNEL_OPTION: 'Canales',
    MENU_TEMPLATE_OPTION: 'Plantillas',

    AUTHENTICATING_CONTENT: 'Autenticando...',
    AUTHENTICATING_MESSAGE_FAIL_TITLE: 'Autenticación',
    AUTHENTICATING_MESSAGE_FAIL_DESCRIPTION: 'No se pudo usar el código de autenticación provisto',

    NON_AUTHORIZED: 'No autorizado',

    ERROR_TITLE_GENERATE_TICKET: 'Error inesperado del CRM',
    ERROR_TEXT_GENERATE_TICKET: 'Por favor, intenta realizar la acción',

    ERROR_TITLE_GENERATE_DEFAULT: 'Error inesperado',
    ERROR_TEXT_GENERATE_DEFAULT: 'Por favor, póngase en contacto con el soporte.',
    ERROR_TEXT_GENERATE_TICKET_USER_FORBIDDEN: 'El responsable del evento no tiene los permisos adecuados.',

    ALERT_MESSAGE_CONTENT_CONTINUE_BUTTON: 'Cerrar',

    SIMPLE_ALERT_MESSAGE_CANCEL_BUTTON: 'Cancelar',
    SIMPLE_ALERT_MESSAGE_CONFIRM_BUTTON: 'Confirmar',

    SCROLL_TO_TOP: 'Volver arriba',

    DATE_FORMAT: 'dd/MM/yyyy',
    DATE_HH_FORMAT: 'dd/MM/yyyy HH',
    DATE_HH_MM_FORMAT: 'dd/MM/yyyy HH:mm',
    DATE_HH_MM_SS_FORMAT: 'dd/MM/yyyy HH:mm:ss'
}
