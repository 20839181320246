import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CreateIcon from '@mui/icons-material/Create'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { CssBaseline, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import {
    ChannelConfigurationListingScope,
    ChannelEntryScope,
    ChannelTableScope,
    ValidationType
} from '../ChannelConfigurationListing.scopes'
import { TextsProvider } from '../texts'
import {
    getOrMakeChannelConfigurationListingStyles as getOrMakeListingStyles,
    getOrMakeChannelConfigurationListingTableStyles as getOrMakeTableStyles
} from './ChannelConfigurationListing.styles'
import { Strings } from '@syonet/lang'

// @Inject
const texts = TextsProvider.get()

// :: Component(ChannelConfigurationListingView)

export type ChannelConfigurationListingViewProps = IViewProps & { scope: ChannelConfigurationListingScope }

export function ChannelConfigurationListingView({ className, style, scope }: ChannelConfigurationListingViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeListingStyles()

    return (
        <div className={clsx(styles.view, className)} style={style}>
            <CssBaseline />
            <ViewSlot scope={scope.filterChipPanel} />
            <ChannelConfigurationListingTableView scope={scope.channelTable} />
        </div>
    )
}

// :: Component(ChannelConfigurationListingTableView)

type ChannelConfigurationListingTableViewProps = IViewProps & {
    scope: ChannelTableScope
}

function ChannelConfigurationListingTableView({ className, scope }: ChannelConfigurationListingTableViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeTableStyles()

    return (
        <div className={clsx(styles.root, className)}>
            <Typography variant="h6" className={styles.customHeader}>
                {texts.CHANNELS}
            </Typography>
            {scope.loading ? <LinearProgress className={styles.progress} color="secondary" /> : undefined}
            {scope.entries.length > 0 && (
                <div className={scope.loading ? styles.blockActions : undefined}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" className={styles.table}>
                            <TableHead className={styles.tableHeader}>
                                <TableRow>
                                    <TableCell>{texts.CHANNEL_NAME_LISTING}</TableCell>
                                    <TableCell>{texts.CHANNEL_NUMBER_LISTING}</TableCell>
                                    <TableCell>{texts.CHANNEL_STATUS_LISTING}</TableCell>
                                    <TableCell>{texts.CHANNEL_PUBLISHED_LISTING}</TableCell>
                                    <TableCell className={styles.tableAction} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scope.entries.map((row, index) => (
                                    <ChannelViewRow key={index} row={row} index={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    )
}

// :: Component(ChannelViewRow)

type ChannelViewRowProps = {
    row: ChannelEntryScope
    index: number
}

function ChannelViewRow({ index, row }: ChannelViewRowProps): JSX.Element {
    bindUpdate(React, row)

    const { classes: styles } = getOrMakeTableStyles()

    return (
        <TableRow key={index} className={styles.customTableRow} hover>
            <ChannelCell content={`${row.name} ${row.draft ? texts.CHANNEL_DRAFT_LISTING : ''}`} />
            <ChannelCell content={Strings.formatPhoneV2(row.phone)} />
            <ChannelCell
                content={
                    <ChannelConfigurationListingStatusView
                        warningType={row.warningType}
                        onAlertClick={row.onClickAlert}
                    />
                }
            />
            <ChannelCell content={row.publishedStatus} />
            <ChannelCell content={<ChannelConfigurationListingEditFormActionView onClick={row.onEdit} />} />
        </TableRow>
    )
}

// :: Component(ChannelCell)

type ChannelCellProps = TableCellProps & {
    content?: string | JSX.Element
}

function ChannelCell({ content, ...props }: ChannelCellProps) {
    return (
        <TableCell {...props}>
            <div>
                <span>{content}</span>
            </div>
        </TableCell>
    )
}

// :: Component(ChannelConfigurationListingWarningView)

type ChannelConfigurationListingWarningViewProps = IViewProps & {
    warningType?: ValidationType
    onAlertClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function ChannelConfigurationListingWarningView({
    warningType,
    onAlertClick
}: ChannelConfigurationListingWarningViewProps) {
    return (
        <div>
            {warningType === 'warning' ? (
                <Tooltip title={texts.PARTIALLY_CONFIGURED_CHANNEL} arrow>
                    <IconButton onClick={onAlertClick}>
                        <InfoOutlinedIcon color="warning" />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </div>
    )
}

// :: Component(ChannelConfigurationListingSuccessView)

type ChannelConfigurationListingSuccessViewProps = IViewProps & {
    warningType?: ValidationType
    onAlertClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function ChannelConfigurationListingSuccessView({
    warningType,
    onAlertClick
}: ChannelConfigurationListingSuccessViewProps) {
    return (
        <div>
            {warningType === 'success' ? (
                <Tooltip title={texts.CONFIGURED_CHANNEL} arrow>
                    <IconButton onClick={onAlertClick}>
                        <CheckCircleOutlineIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </div>
    )
}

// :: Component(ChannelConfigurationListingErrorView)

type ChannelConfigurationListingErrorViewProps = IViewProps & {
    warningType?: ValidationType
    onAlertClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function ChannelConfigurationListingErrorView({
    warningType,
    onAlertClick
}: ChannelConfigurationListingErrorViewProps) {
    return (
        <div>
            {warningType === 'error' ? (
                <Tooltip title={texts.NOT_CONFIGURED_CHANNEL} arrow>
                    <IconButton onClick={onAlertClick}>
                        <WarningAmberOutlinedIcon color="error" />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </div>
    )
}

// :: Component(ChannelConfigurationListingStatusView)

type ChannelConfigurationListingStatusViewProps = IViewProps & {
    warningType?: ValidationType
    onAlertClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function ChannelConfigurationListingStatusView({
    warningType,
    onAlertClick
}: ChannelConfigurationListingStatusViewProps) {
    return (
        <div>
            <ChannelConfigurationListingWarningView warningType={warningType} onAlertClick={onAlertClick} />
            <ChannelConfigurationListingSuccessView warningType={warningType} onAlertClick={onAlertClick} />
            <ChannelConfigurationListingErrorView warningType={warningType} onAlertClick={onAlertClick} />
        </div>
    )
}

// :: Component(ChannelConfigurationListingEditFormActionView)

type ChannelConfigurationListingEditFormActionViewProps = IViewProps & {
    onClick: () => void
}

function ChannelConfigurationListingEditFormActionView({
    onClick
}: ChannelConfigurationListingEditFormActionViewProps) {
    return (
        <IconButton onClick={onClick}>
            <CreateIcon />
        </IconButton>
    )
}
