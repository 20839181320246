import { AxiosError } from 'axios'
import { HttpError } from './errors/HttpError'
import { LoggerLike } from './LoggerLike'
import { lodash } from './lodash'

export function castToError(value: unknown) {
    if (value instanceof Error) {
        return value as Error
    } else if (lodash.isNil(value)) {
        return new Error('Unknown')
    } else if (lodash.isString(value)) {
        return new Error(`${value}`)
    } else if (lodash.isObjectLike(value)) {
        const exn = value as Record<string, unknown>
        if (exn.message) {
            const error = new Error(`${exn.message}`)
            if (exn.stack) {
                error.stack = `${exn.stack}`
            }
            return error
        } else {
            return new Error(JSON.stringify(exn))
        }
    } else {
        return new Error(`Expected exception value ${value}`)
    }
}

export function errorResponse(response: { err?: number; msg?: string }, logger: LoggerLike, caught: unknown) {
    const exn = caught as Record<string, unknown>
    if (exn.isAxiosError) {
        const error = caught as AxiosError
        if (error.response) {
            response.err = error.response.status
            response.msg = error.response.statusText
        } else {
            response.err = 421
            response.msg = 'Server that is not able to produce a response'
        }
        logger.error(error.message, error.stack, '')
    } else if (caught instanceof HttpError) {
        response.err = caught.status
        response.msg = caught.message
        logger.error(caught.message, caught.stack, '')
    } else if (caught instanceof Error) {
        logger.error(caught.message, caught.stack, '')
        response.err = 500
        response.msg = caught.message || 'Internal Server Error'
        logger.error(caught.message, caught.stack, '')
    } else {
        response.err = 500
        response.msg = String(caught) || 'Internal Server Error'
    }

    return false
}
export function isHttpError(code: number): boolean {
    return code >= 400
}
