import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Paper, SvgIconTypeMap, Tooltip, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import clsx from 'clsx'
import React, { ReactElement, useLayoutEffect, useState } from 'react'
import { IViewProps } from 'wdc-cube-react'
import { getOrMakeKpiCardStyles } from './KpiCard.styles'

export type KpiCardProps = IViewProps & {
    flexGrow?: number
    label: string
    value: string
    info?: string
    icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    color?: string
    onMoreInfo?: () => Promise<void>
}

export function KpiCard(props: KpiCardProps) {
    const isSizeOverflowed = resizeMonitor()

    const { classes: styles } = getOrMakeKpiCardStyles()
    const CardIcon = props.icon

    const ref = React.useRef<HTMLDivElement | null>(null)

    return (
        <Paper
            className={clsx(styles.cardWrapper, props.className)}
            style={{ background: props.color, flexGrow: props.flexGrow }}
        >
            <div className={styles.card}>
                <div className={styles.adjustContent}>
                    <div className={styles.adjustText} ref={ref}>
                        <Typography variant="overline" className={styles.cardLabel}>
                            {isEllipsisActive(ref.current as HTMLElement) && isSizeOverflowed ? (
                                <EllipsisActiveView label={props.label}>
                                    <span>{props.label}</span>
                                </EllipsisActiveView>
                            ) : (
                                <>{props.label}</>
                            )}
                        </Typography>
                    </div>
                    {props.info && (
                        <div className={styles.adjustOnMoreInfoIcon}>
                            <Tooltip title={<Typography variant="caption">{props.info}</Typography>}>
                                <InfoIcon className={styles.cardInfoIcon} onClick={props.onMoreInfo} />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className={styles.adjustContent}>
                    <div>
                        <Typography variant="h4" className={styles.cardValue}>
                            {props.value}
                        </Typography>
                    </div>
                    <div>
                        <CardIcon className={styles.cardIcon} style={{ fontSize: 28 }} />
                    </div>
                </div>
            </div>
        </Paper>
    )
}

export type EllipsisActiveViewProps = IViewProps & {
    label: string
    children: ReactElement<string, string>
}

export function EllipsisActiveView(props: EllipsisActiveViewProps) {
    return (
        <>
            <Tooltip title={props.label}>{props.children}</Tooltip>
        </>
    )
}

const isEllipsisActive = (el: HTMLElement): boolean => {
    if (el instanceof HTMLElement) {
        return el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth
    }

    return false
}

export const resizeMonitor = () => {
    const [size, setSize] = useState([0, 0])

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])

    return size
}
