import { Chip, styled } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ChipItemScope, ChipPanelScope } from '../ChipPanel.scopes'
import { getOrMakeStyles } from './ChipPanel.styles'

// :: Component(ChipPanelView)

type ChipPanelViewProps = IViewProps & {
    scope: ChipPanelScope
}

export function ChipPanelView({ className, scope }: ChipPanelViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    if (scope.entries.length === 0) {
        return <></>
    }

    return (
        <ul className={clsx(styles.view, className)}>
            {scope.entries.map((item, idx) => (
                <ListItem key={idx} scope={item} />
            ))}
        </ul>
    )
}

// :: Component(ListItem)

type ListItemProps = {
    scope: ChipItemScope
}

function ListItem({ scope }: ListItemProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <StyledListItem>
            <Chip
                className={scope.defaultColor ? styles.chipPrimary : styles.chipSecundary}
                label={scope.description}
                onDelete={scope.onRemove}
            />
        </StyledListItem>
    )
}

const StyledListItem = styled('li')(() => ({
    margin: 3,
    maxWidth: '100%'
}))
