/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AdminChats as NS, ChannelId, PagingDTO } from '@whatsapp/communication'
import { BaseAdminService } from 'src/services'
import { DateFns } from 'src/utils'
import { Logger, ServiceLike } from 'wdc-cube'

const LOG = Logger.get('ChatsService')

// :: Re-exporting types and consts because on commonjs there is no Namespace

export type { ChannelId, PagingDTO }

export type ChatTicketStatus = NS.ChatTicketStatus
export const ChatTicketStatus = NS.ChatTicketStatus

export type ChatsGenerateTicketStatus = NS.ChatsGenerateTicketStatus
export const ChatsGenerateTicketStatus = NS.ChatsGenerateTicketStatus

export type ChatCompanyOption = NS.ChatCompanyOption
export type ChatMediaOption = NS.ChatMediaOption
export type ChatSourceOption = NS.ChatSourceOption
export type ChatTicketOption = NS.ChatTicketOption
export type CustomerIdAndName = NS.CustomerIdAndName
export type CustomerIdAndNameAndPhone = NS.CustomerIdAndNameAndPhone
export type ChatResponsableOption = NS.ChatResponsableOption
export type ChatContact = Omit<NS.ChatContact, 'datetime'> & { datetime: Date }

export type ChatsContentRequest = Omit<NS.ChatsContentRequest, 'startTime' | 'endTime'> & {
    startTime: Date
    endTime: Date
}
export type ChatsContentResponse = Omit<NS.ChatsContentResponse, 'contacts'> & { contacts: ChatContact[] }

export type ChatsCustomerSearchRequest = Omit<NS.ChatsCustomerSearchRequest, 'startTime' | 'endTime'> & {
    startTime: Date
    endTime: Date
}
export type ChatsCustomerSearchResponse = NS.ChatsCustomerSearchResponse

export type ChatsCompanyOptionsRequest = NS.ChatsCompanyOptionsRequest
export type ChatsCompanyOptionsResponse = NS.ChatsCompanyOptionsResponse

export type ChatsSourceOptionsRequest = NS.ChatsSourceOptionsRequest
export type ChatsSourceOptionsResponse = NS.ChatsSourceOptionsResponse

export type ChatsMediaOptionsRequest = NS.ChatsMediaOptionsRequest
export type ChatsMediaOptionsResponse = NS.ChatsMediaOptionsResponse

export type TicketSourceAndResponsableOptionsResponse = {
    tickets: ChatTicketOption[]
    sources: ChatSourceOption[]
    responsables: ChatResponsableOption[]
}

export type ChatsResponsableOptionsRequest = NS.ChatsResponsableOptionsRequest
export type ChatsResponsableOptionsResponse = NS.ChatsResponsableOptionsResponse

export type ChatsGenerateTicketRequest = NS.ChatsGenerateTicketRequest
export type ChatsGenerateTicketResponse = NS.ChatsGenerateTicketResponse

export class ChatsService extends BaseAdminService implements ServiceLike {
    private static readonly INSTANCE = new ChatsService()

    public static singleton() {
        return ChatsService.INSTANCE
    }

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    // :: Instance

    private __initialized = false

    public get name() {
        return 'ChatsService'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        this.__initialized = true
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public async fetchContent(request: ChatsContentRequest): Promise<ChatsContentResponse> {
        const realRequest: NS.ChatsContentRequest = {
            channels: request.channels,
            startTime: DateFns.formatISO(request.startTime),
            endTime: DateFns.formatISO(request.endTime),
            assureContactId: request.assureContactId,
            ticketStatus: request.ticketStatus,
            customerIds: request.customerIds,
            itemsPerPage: request.itemsPerPage,
            pageIndex: request.pageIndex,
            noCache: request.noCache
        }

        const { content } = (
            await this.send({
                query: {
                    content: realRequest
                }
            })
        ).query!

        if (!content) {
            throw new Error('Missing content on response')
        }

        this.raiseUnexpected(content)

        // Adapt contacts
        const contacts: ChatContact[] = []
        for (const originalContact of content.contacts) {
            const adaptedContact = originalContact as unknown as Record<keyof ChatContact, unknown>
            if (originalContact.datetime) {
                adaptedContact.datetime = DateFns.parseISO(originalContact.datetime)
            }
            contacts.push(adaptedContact as ChatContact)
        }

        return {
            contacts,
            meta: content.meta,
            filters: content.filters
        }
    }

    public async fetchCustomers(request: ChatsCustomerSearchRequest): Promise<ChatsCustomerSearchResponse> {
        const realRequest: NS.ChatsCustomerSearchRequest = {
            channels: request.channels,
            startTime: DateFns.formatISO(request.startTime),
            endTime: DateFns.formatISO(request.endTime),
            ticketStatus: request.ticketStatus,
            text: request.text,
            itemsPerPage: request.itemsPerPage,
            pageIndex: request.pageIndex
        }

        const { customerOptions } = (
            await this.send({
                query: {
                    customerOptions: realRequest
                }
            })
        ).query!

        this.raiseUnexpected(customerOptions)

        return customerOptions!
    }

    public async fetchCompanyOptions(account: string) {
        const { companyOptions } = (
            await this.send({
                query: {
                    companyOptions: { account }
                }
            })
        ).query!

        this.raiseUnexpected(companyOptions)

        return companyOptions!.companies
    }

    public async fetchTicketSourceAndResponsableOptions(
        account: string,
        companyId: number
    ): Promise<TicketSourceAndResponsableOptionsResponse> {
        const optionRequest = { account, companyId }

        const { ticketOptions, sourceOptions, responsableOptions } = (
            await this.send({
                query: {
                    ticketOptions: optionRequest,
                    sourceOptions: optionRequest,
                    responsableOptions: optionRequest
                }
            })
        ).query!

        this.raiseUnexpected(ticketOptions)
        this.raiseUnexpected(sourceOptions)
        this.raiseUnexpected(responsableOptions)

        return {
            tickets: ticketOptions!.tickets,
            sources: sourceOptions!.sources,
            responsables: responsableOptions!.responsables
        }
    }

    public async fetchMediaOptions(account: string, companyId: number, sourceId: number) {
        const { mediaOptions } = (
            await this.send({
                query: {
                    mediaOptions: { account, companyId, sourceId }
                }
            })
        ).query!

        this.raiseUnexpected(mediaOptions)

        return mediaOptions!.medias
    }

    public async generateTicket(request: ChatsGenerateTicketRequest): Promise<ChatsGenerateTicketResponse> {
        const { generateTicket } = (
            await this.send({
                mutation: {
                    generateTicket: request
                }
            })
        ).mutation!

        this.raiseUnexpected(generateTicket)

        return generateTicket!
    }
}
