import { ComboScope, OptionType } from 'src/components/combo'
import { Observable, observe, Scope } from 'wdc-cube'
import { TextChangeEvent } from './ChatsFilter.scopes'

export { ComboScope }
export type { OptionType, TextChangeEvent }

@Observable
export class ChatsFormBodyScope extends Scope {
    readonly company = new ComboScope<number>()
    readonly ticketType = new ComboScope<string>()
    readonly source = new ComboScope<number>()
    readonly media = new ComboScope<number>()
    readonly responsable = new ComboScope<string>()

    @observe() customerName = ''
    @observe() customerPhone = ''
    @observe() acceptCustomResponsable = false
    @observe() generatingTicket = false
    @observe() valid = true

    @observe() onChangeCustomerName = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onChangeCustomerPhone = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onToggleAcceptCustomResponsable = Scope.ASYNC_ACTION
    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onGenerateTicket = Scope.ASYNC_ACTION
}

export class ChatsFormScope extends Scope {
    @observe() @observe() body?: Scope | null
}
