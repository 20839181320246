export const Texts = {
    CHANNELS: 'Canales',
    CHANNEL_NAME_LISTING: 'Nombre',
    CHANNEL_DRAFT_LISTING: '(Sequía)',
    CHANNEL_NUMBER_LISTING: 'Número',
    CHANNEL_STATUS_LISTING: 'Estatus',
    CHANNEL_PUBLISHED_LISTING: 'Publicado',

    CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_AFIRMATION: 'Sí',
    CHANNEL_CONFIGURATION_LISTING_PUBLISH_STATUS_DENY: 'No',
    CHANNEL_CONFIGURATION_LISTING_ON_EDIT_CHANNEL_ERROR: 'Missing channelId which is a required argument',
    CHANNEL_CONFIGURATION_LISTING_ON_CLICK_ALERT_CHANNEL_ERROR: 'Missing channelId which is a required argument',

    CHANNEL_FILTER_CHANNEL_NAME: 'Nombre',
    CHANNEL_FILTER_CHANNEL_NAME_PLACEHOLDER: 'Introduce el nombre del canal',
    CHANNEL_FILTER_CHANNEL_NUMBER: 'Número',
    CHANNEL_FILTER_CHANNEL_NUMBER_PLACEHOLDER: 'Ingrese el número de canal',
    CHANNEL_FILTER_CLEAR_BUTTON: 'Limpiar',
    CHANNEL_FILTER_FILTER_BUTTON: 'Filtrar',

    CHANNEL_CONFIGURATION_LISTING_FILTER_STATUS: 'Estatus',
    CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NAME: 'Canal',
    CHANNEL_CONFIGURATION_LISTING_FILTER_CHANNEL_NUMBER: 'Número de canal',

    PARTIALLY_CONFIGURED_CHANNEL: 'Configuración válida, con reservas.',
    CONFIGURED_CHANNEL: 'Configuración válida.',
    NOT_CONFIGURED_CHANNEL: 'Configuración pendiente.',

    CANCEL_EDITION_OPTION_MESSAGE: '¿Está seguro de que desea cancelar la edición?',
    CANCEL_EDITION_OPTION_NO: 'No',
    CANCEL_EDITION_OPTION_YES: 'Sí',
    CANCEL_EDITION_OPTION_ALERT_TITLE: 'Cancelar',

    DELETE_MENU_OPTION_NO: 'No',
    DELETE_MENU_OPTION_YES: 'Sí',

    DISCARD_EDITION_OPTION_MESSAGE:
        'Los cambios a este canal siguen como un borrador y solamente\nse aplicará si se publican los cambios.\n\n' +
        'Es posible guardar o descartar los cambios, sin ellos\nser publicado.',
    DISCARD_EDITION_OPTION_CLOSE: 'Cerrar',
    DISCARD_EDITION_OPTION_DISCARD: 'Descartar',
    DISCARD_EDITION_OPTION_ALERT_TITLE: 'Sequía',
    DISCARD_EDITION_OPTION_ERROR: 'Al intentar descartar el borrador',

    DO_DISCARD_EDITION_TITLE: '¿Descartar borrador?',
    DO_DISCARD_EDITION_DESCRIPTION: '¿Seguro que quieres descartar este borrador?',
    DO_DISCARD_EDITION_NO_OPTION: 'No',
    DO_DISCARD_EDITION_YES_OPTION: 'Sí',

    CHANGES_MADE_WITH_SUCCESS: 'Los cambios se guardaron exitosamente',
    ERROR_DURING_SAVING_ALERT_TITLE: 'Error al guardar',
    ERROR_DURING_SAVING_ALERT_CONTENT: 'Se ha producido un error imprevisto. Intenta más tarde',
    SAVE_IS_NOT_POSSIBLE: 'No pude salvar',
    NOT_POSSIBLE_APPLY_CHANNEL_CONFIGURATION_WITH_ERRORS:
        'No se permite publicar una configuración de canal que tenga errores',
    ERROR_DURING_PUBLISH_ALERT_TITLE: 'Error de la aplicación',

    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_DESCRIPTION: 'Agencias',
    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_UNEXPECTED_ERROR: 'Unexpected error',
    CHANNEL_CONFIGURATION_COMPANY_SELECTOR_MISSING_COMPANIES_ERROR: 'Missing companyItemScope for code',

    CHANNEL_CONFIGURATION: 'Configuración de canales',
    CHANNEL_CONFIGURATION_DRAFT: 'Sequía',
    CHANNEL_NOT_CONFIGURED_TOOLTIP: 'Canal no configurado',
    CHANNEL_WITH_OBSERVATIONS_TOOLTIP: 'Canal con comentarios',

    CHANNEL_CONFIGURATION_FORM_GENERAL_TAB: 'General',
    CHANNEL_CONFIGURATION_FORM_MENU_TAB: 'Menú',
    CHANNEL_CONFIGURATION_FORM_MESSAGES_TAB: 'Mensajes',
    CHANNEL_CONFIGURATION_FORM_WORKING_HOURS_TAB: 'Horarios',
    CHANNEL_CONFIGURATION_FORM_EXTRAS_TAB: 'Extras',
    CHANNEL_CONFIGURATION_FORM_SCHEDULE_ROBOT_TAB: 'Robot de programación',

    CHANNEL_CONFIGURATION_FORM_SAVE_ACTION: 'Guardar',
    CHANNEL_CONFIGURATION_FORM_BACK_BUTTON_ACTION: 'Volver',
    CHANNEL_CONFIGURATION_FORM_PUBLISH_BUTTON_ACTION: 'Publicar',

    CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_ERROR_STATUS: 'Estatus',
    CHANNEL_CONFIGURATION_FORM_DO_PUBLISH_UNKNOWN_ERROR_MESSAGE: 'Unknown error message.',

    CHANNEL_CONFIGURATION_GENERAL_NAME: 'Nombre del canal',
    CHANNEL_CONFIGURATION_GENERAL_NAME_PLACEHOLDER: 'Ingrese el nombre del canal aquí',
    CHANNEL_CONFIGURATION_GENERAL_NUMBER: 'Numero de canal',
    CHANNEL_CONFIGURATION_GENERAL_PACKAGE: 'Paquetes',
    CHANNEL_CONFIGURATION_GENERAL_COMPANIES: 'Agencias',
    CHANNEL_CONFIGURATION_GENERAL_PACKAGE_TOOLTIP: 'Para cambiar el paquete, comuníquese con el soporte de Syonet.',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_TITLE: 'Segmentación de negocios',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_ORDER: 'Orden de segmentación',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_CONTENT:
        'Al marcar esta opción, habilitas la segmentación de empresas al inicio del servicio.\nAntes de mostrar el menú principal, se mostrará la segmentación configurada, generalmente mostrando las banderas de las tiendas, pero también es posible segmentar por cualquier otra información, como ciudad o estado.Antes de mostrar el menú principal, se mostrará la segmentación configurada, generalmente mostrando las banderas de las tiendas, pero también es posible segmentar por cualquier otra información, como ciudad o estado.',
    CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_TITLE: 'No permitido',
    CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_CONTENT: 'Todas las empresas ya han sido incluidas.',
    CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_CONFIRM_MESSAGE: '¿Está seguro de que desea eliminar la empresa?',
    CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_AFIRMATION_MESSAGE: 'Eliminar empresa',
    CHANNEL_CONFIGURATION_GENERAL_FIELD_CANNOT_BE_BLANK_ERROR: 'El campo no puede estar en blanco',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE:
        '¿Está seguro de que desea reemplazar la empresa?',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE_COMPLEMENT: 'para la Agencia',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_DELETE_COMPANY_NAME: 'Eliminar empresa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_ADD_COMPANY: 'Añadir Agencia',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_LABEL: 'Utilice la segmentación empresarial',
    CHANNEL_CONFIGURATION_GENERAL_ON_SHOW_INFO_TOOLTIP:
        'Al marcar esta opción, habilitas la segmentación de empresas al inicio del servicio.',
    CHANNEL_CONFIGURATION_GENERAL_TOOGLE_SHOW_ATTENDANT_NAME_TOOLTIP:
        'Presenta el nombre del agente en cada mensaje de texto enviado en el chat.',
    CHANNEL_CONFIGURATION_GENERAL_CARD_POSITION_DESCRIPTION: 'Posición',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_INPUT_LABEL: 'Agencia',
    CHANNEL_CONFIGURATION_GENERAL_SEGMENTATION_INPUT_LABEL: 'Segmentación',
    CHANNEL_CONFIGURATION_GENERAL_INCLUDE_NEW_SEGMENTATION_HINT: 'ENTER para nueva segmentación',
    CHANNEL_CONFIGURATION_GENERAL_NO_COMPANY_ADDED: 'No se agregaron empresas.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE: 'Auto Rescate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_TITLE: 'Configuración de Auto Rescate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_COMPLET:
        'Este tipo de redención hará que el robot llame automáticamente al cliente X minutos después de que termine la conversación.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE: 'Redención automática',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE_EVENT: 'Generar evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME:
        'Tras una respuesta positiva del cliente, el robot reanudará la conversación donde la dejó.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_NEGATIVE_ANSWER_RESUME:
        'Ante una respuesta negativa del cliente, el robot solo se despedirá de él.',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CHECK_MESSAGE: 'Usar Auto Rescate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_START: 'Canjear cliente después de',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END: 'minutos',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_RESCUE_CAPTION: 'Mensaje de rescate',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_GOODBYE_CAPTION: 'Mensaje de despedida',

    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_EXPLICATION_TEXT_TYPE_GENERATE_EVENT:
        'Este tipo de rescate generará el evento definido a continuación X minutos después de que finalice la conversación',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END_TYPE_GENERATE_EVENT: 'Generar evento después',

    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION:
        'Utilizar la notificación en el flujo de preventa',
    CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION_TOOLTIP:
        'El asistente al evento también recibirá una notificación de cada mensaje recibido.',

    CHANNEL_CONFIGURATION_MENU_NEW_MENU_BUTTON: 'CONFIGURAR NUEVO MENÚ',
    CHANNEL_CONFIGURATION_MENU_VINCULATE_SEGMENTATION: 'Vincular la segmentación a los menús',
    CHANNEL_CONFIGURATION_MENU_NEW_MENU: 'Nuevo',
    CHANNEL_CONFIGURATION_MENU_COMPANIES_TITLE: 'Agencias',
    CHANNEL_CONFIGURATION_MENU_OPTIONS_TITLE: 'Opciones',
    CHANNEL_CONFIGURATION_MENU_COMPANIES_DESCRIPTION: 'Agencias: %s',
    CHANNEL_CONFIGURATION_MENU_EVENT_CHIP: 'Evento',
    CHANNEL_CONFIGURATION_MENU_LINK_CHIP: 'Enlace',
    CHANNEL_CONFIGURATION_MENU_SCHEDULE_ROBOT_CHIP: 'Robot de programación',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CHIP: 'Segmentario',
    CHANNEL_CONFIGURATION_MENU_EDIT_MENU_TOOLTIP: 'Menú de edición',
    CHANNEL_CONFIGURATION_MENU_DUPLICATE_MENU_TOOLTIP: 'Menú duplicado',
    CHANNEL_CONFIGURATION_MENU_DELETE_MENU_TOOLTIP: 'Eliminar menú',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CHANNEL_ID_ERROR: 'ChannelId is a required argument',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_MENU_CONFIGURATION: 'Configuración del menú %d',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEW_OPTION_BUTTON: 'Nueva opción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NO_DATA_DESCRIPTION: 'No hay opciones definidas.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_DELETE_OPTION_BUTTON: 'Eliminar opción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_OPTION: 'Opción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE: 'Escribe',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK: 'Enlace',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK: 'Enlace de archivo o Subir',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_ERROR: 'Tipo de archivo no soportado: ',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_SUPORTED_TYPES: 'Tipos admitidos: ',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_LINK_TOOLTIP: `Se permiten imágenes en formato jpeg, jpg y png.
        Los archivos PDF y los videos MP4 también se pueden configurar para carga automática.
        El tamaño máximo permitido para un archivo es de 5mb para imagen, 16mb para video y 100mb para PDF`,
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_DESCRIPTION_TOOLTIP: 'Texto utilizado en la selección del menú.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_MESSAGE_TOOLTIP:
        'Mensaje enviado al cliente cuando se selecciona el artículo.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_POSITIVE_BUTTON_TOOLTIP:
        'El botón positivo representa la respuesta del cliente que quiere avanzar en la negociación y comunicarse con un humano.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_NEGATIVE_BUTTON_TOOLTIP:
        'El botón negativo representa la respuesta del cliente que quiere repetir (volver) el último paso de la conversación.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_INFORMATION_SELECT_EVENT_TOOLTIP:
        'Si el cliente responde positivamente, se generará un evento en el CRM. Configure el evento apropiado para cada opción de menú.',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_POSITIVE_BUTTON: 'Botón positivo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_NEGATIVE_BUTTON: 'Botón negativo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_TYPE_MENU_DISABLE:
        'Este menu possui submenus, não é possível alterar o tipo',
    CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_LINK_PLACEHOLDER: 'Escriba o pegue el enlace deseado',
    CHANNEL_CONFIGURATION_MENU_EDITOR_COMPANY_COUNT_ERROR: 'Necesitas etiquetar al menos una empresa',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_EVENT_TYPE: 'Evento',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_LINK_TYPE: 'Enlace',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SCHEDULE_ROBOT_TYPE: 'Robot de programación',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SUB_MENU_TYPE: 'Menú',
    CHANNEL_CONFIGURATION_MENU_EDITOR_INFORMATION_MENU_TYPE: 'Información',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_EVENT_CAPTION: 'Descripción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_CAPTION_STATUS: 'Falta contenido',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_LINK_CAPTION: 'Descripción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_NEEDED_FIELD: 'Campo obligatorio',
    CHANNEL_CONFIGURATION_MENU_EDITOR_DESCRIPTION_ROBOT_CAPTION: 'Descripción',
    CHANNEL_CONFIGURATION_MENU_EDITOR_MESSAGE_INFORMATION_CAPTION: 'Mensaje',
    CHANNEL_CONFIGURATION_MENU_EDITOR_EMPTY_FIELD: 'Falta contenido',
    CHANNEL_CONFIGURATION_MENU_ITEM_TITLE: 'MENÚ %d',
    CHANNEL_CONFIGURATION_MENU_TITLE: 'Menú %d',
    CHANNEL_CONFIGURATION_MENU_NO_COMPANY_ESTABLISHED: 'Ninguna empresa definida.',
    CHANNEL_CONFIGURATION_MENU_NO_MENU_ITEM_ESTABLISHED: 'No hay elementos de menú definidos.',
    CHANNEL_CONFIGURATION_MENU_MISSING_MESSAGE: 'Falta definición de mensaje en',
    CHANNEL_CONFIGURATION_MENU_MISSING_POSITIVE_BUTTON: 'Falta la definición de botón positivo en',
    CHANNEL_CONFIGURATION_MENU_MISSING_NEGATIVE_BUTTON: 'Falta la definición del botón negativo en',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION: 'Definición de evento faltante',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_SINGULAR: 'ít',
    CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_PLURAL: 'elementos',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION: 'Falta el texto de la descripción en',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_SINGULAR: 'ít',
    CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_PLURAL: 'elementos',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL: 'Falta la url del enlace en',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR: 'ít',
    CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL: 'elementos',
    CHANNEL_CONFIGURATION_MENU_MISSING_COMPANIES_WITHOUT_MENUS: 'Las siguientes empresas no tienen menús: %s.',
    CHANNEL_CONFIGURATION_MENU_ON_DELETE_QUESTION: '¿Está seguro de que desea eliminar "%s"?',
    CHANNEL_CONFIGURATION_MENU_ON_DELETE_TITLE: 'Borrar %s',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_TITLE: 'Rebanadoras de menú - Menú %d',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_FIRST_COLUMN_HEADER: 'Opciones de menú',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SECOND_COLUMN_HEADER: 'Segmentaciones',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_BUTTON_TITLE: 'Nueva segmentación',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_TOOLTIP_TITLE: 'Seleccione una opción de menú',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SELECTED_MENU: 'Menú seleccionado: %s',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CONFIRM_BUTTON_TITLE: 'Confirmar',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_LINK: 'Enlace',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_ROBOT: 'Robot de programación',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_SUB_MENU: 'Menú',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_INFORMATION: 'Información',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_UNKNOWN: 'Tipo desconocido',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_LABEL: 'Segmentaciones',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_PLACEHOLDER: 'Segmentaciones',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_DELETE_SEGMENTATION_BUTTON_TITLE: 'Eliminar segmentación',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NO_SEGMENTED_COMPANY: 'Ninguna empresa apuntada',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_OPTION_NOT_SELECTED: 'No se ha seleccionado ninguna opción de menú',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_TITLE: 'Error al agregar empresa',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_CONTENT:
        '¡Ya ha agregado la empresa %s al objetivo!',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_TITLE: 'Error al segmentar empresa',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_CONTENT:
        '¡Seleccione un menú antes de dirigirse a un negocio!',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU:
        'Ha segmentado un submenú (hijo). ¿Agregar esta segmentación también al menú (principal)?',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CANCEL_OPTION: 'No',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CONFIRM_OPTION: 'Segmento',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SIMPLE_ALERT_TITLE: 'Menú de segmentos',
    CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ALERT_WARNING_DURING_MENU_CREATION:
        'Publique el canal para que pueda orientar el menú recién creado.',

    CHANNEL_CONFIGURATION_MESSAGES_TITLE: 'Inicio de servicio',
    CHANNEL_CONFIGURATION_MESSAGES_EVENT_GENERATION: 'Generación de eventos',
    CHANNEL_CONFIGURATION_MESSAGES_SPECIFIC_MESSAGE: 'Situaciones específicas',
    CHANNEL_CONFIGURATION_MESSAGES_GREETING_CAPTION: 'Saludo',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_CAPTION: 'Segmentación de negocios',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_CAPTION: 'Selección de empresas',
    CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENTS_SELECTION_CAPTION: 'Selección de departamentos',
    CHANNEL_CONFIGURATION_MESSAGES_GENERATED_EVENT_CAPTION: 'Evento generado',
    CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_NEW_TICKET_CAPTION: 'Evento generado fuera del horario comercial',
    CHANNEL_CONFIGURATION_MESSAGES_ATTENDENCE_CONTINUATION_CAPTION: 'Continuación del servicio',
    CHANNEL_CONFIGURATION_MESSAGES_IDENTITY_CONFIRMATION_CAPTION: 'Confirmación de identidad',
    CHANNEL_CONFIGURATION_MESSAGES_OFFLINE_SYSTEM_CAPTION: 'Sistema no disponible',
    CHANNEL_CONFIGURATION_MESSAGES_INVALID_OPTION_CAPTION: 'Opción inválida',
    CHANNEL_CONFIGURATION_MESSAGES_MISSING_CONTENT_MESSAGE: 'Falta contenido',
    CHANNEL_CONFIGURATION_MESSAGES_INSERT_NEEDED_VARIABLE: 'Ingrese la variable requerida:',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SEGMENTATION_TAG_NAME: 'segmentaciones',
    CHANNEL_CONFIGURATION_MESSAGES_COMPANY_SELECTION_TAG_NAME: 'Agencias',
    CHANNEL_CONFIGURATION_MESSAGES_DEPARTMENT_SELECTION_TAG_NAME: 'departamentos',
    CHANNEL_CONFIGURATION_MESSAGES_NEW_TICKET_TAG_NAME: 'evento',
    CHANNEL_CONFIGURATION_MESSAGES_OUT_OF_WORKING_HOURS_NEW_TICKET_TAG_NAME: 'evento',

    CHANNEL_CONFIGURATION_WORKING_HOURS_NEW_HOUR_BUTTON: 'ESTABLECER NUEVO HORRARIO',
    CHANNEL_CONFIGURATION_WORKING_HOURS_COMPANY: 'Agencia',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DAYS: 'Días',
    CHANNEL_CONFIGURATION_WORKING_HOURS_TIME: 'Horarios',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDIT_HOUR_TOOLTIP: 'Editar tiempo',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DELETE_HOUR_TOOLTIP: 'Borrar tiempo',
    CHANNEL_CONFIGURATION_WORKING_HOURS_HOLE_DAY_LABEL: '24 horas',
    CHANNEL_CONFIGURATION_WORKING_HOURS_MONDAY: 'Lun',
    CHANNEL_CONFIGURATION_WORKING_HOURS_TUESDAY: 'Mar',
    CHANNEL_CONFIGURATION_WORKING_HOURS_WEDNESDAY: 'Miér',
    CHANNEL_CONFIGURATION_WORKING_HOURS_THURSDAY: 'Jue',
    CHANNEL_CONFIGURATION_WORKING_HOURS_FRIDAY: 'Vie',
    CHANNEL_CONFIGURATION_WORKING_HOURS_SATURDAY: 'Sab',
    CHANNEL_CONFIGURATION_WORKING_HOURS_SUNDAY: 'Dom',
    CHANNEL_CONFIGURATION_WORKING_HOURS_ALL_DAYS: 'Todos los días',
    CHANNEL_CONFIGURATION_WORKING_HOURS_DEFAULT_VALUE: 'Hora predeterminada cuando no está configurada',
    CHANNEL_CONFIGURATION_WORKING_HOURS_MISSING_HOUR_CONFIGURATION:
        'Necesidad de establecer horarios para las empresas',
    CHANNEL_CONFIGURATION_WORKING_HOURS_PARTIALLY_PERIOD_CONFIGURATION: 'Configuración parcial de algunos periodos',
    CHANNEL_CONFIGURATION_WORKING_HOURS_ICONSISTENT_HOUR: 'Inconsistencias en los horarios',
    CHANNEL_CONFIGURATION_WORKING_HOURS_AND_BETWEEN_TIMES: 'y',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_CHANNEL_ID_ERROR: 'ChannelId is a required argument',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_NEW_TIMES_ID_ERROR: 'newTimesId is a required argument',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TITLE: 'Configuración de horas de servicio',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_DISCARD_BUTTON: 'Descartar',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_CONFIRM_BUTTON: 'Confirmar',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_AND_BETWEEN_TIMES: 'y',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SPACE_BETWEEN_TIMES: 'a las',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_COMPANY_QUANTITY_ERROR: 'Necesitas etiquetar al menos una empresa',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SUNDAY_CAPTION: 'Domingo',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_MONDAY_CAPTION: 'Lunes',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TUESDAY_CAPTION: 'Martes',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_WEDNESDAY_CAPTION: 'Miércoles',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_THURSDAY_CAPTION: 'Jueves',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_FRIDAY_CAPTION: 'Viernes',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SATURDAY_CAPTION: 'Sábado',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_START_TIME_FIELD: 'Hora de inicio',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_END_TIME_FIELD: 'Hora de finalización',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TIME_ERROR: 'El segundo período debe ser posterior al primero.',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_START_TIME_VALIDATION: 'Antes del tiempo final',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_END_TIME_VALIDATION: 'Después de la hora de finalización',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_START_TIME_PERIOD_VALIDATION: 'Requerido',
    CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_END_TIME_PERIOD_VALIDATION: 'Requerido',

    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_TITLE: 'Palabras ignoradas',
    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_ACCORDION_DESCRIPTION:
        'Las palabras configuradas se ignorarán para iniciar una conversación, ya sea con el robot o con un asistente. No se diferencia entre mayúsculas y minúsculas.',
    CHANNEL_CONFIGURATION_EXTRAS_IGNORED_WORDS_LABEL: 'Palabras',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_TITLE: 'Opción Volver',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_ACCORDION_DESCRIPTION:
        'Con cada pregunta que el robot le hace al cliente, es posible mostrar un botón "Volver". Nuestra sugerencia es no mostrar este botón en todas las preguntas, sino enseñar al usuario que puede escribir "volver" cuando quiera.',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_NOT_SHOW_GO_BACK_OPTION_LABEL: 'No mostrar el botón "Volver"',
    CHANNEL_CONFIGURATION_EXTRAS_GO_BACK_RADIO_BUTTON_SHOW_GO_BACK_OPTION_LABEL:
        'Mostrar el botón Volver en todos los pasos de conversación del robot',

    CHANNEL_CONFIGURATION_VALIDATION_CAPTION_VALIDATE_PUBLISH: 'Validar publicación',
    CHANNEL_CONFIGURATION_VALIDATION_INFO_VALIDATE_PUBLISH:
        'Atención, estás a punto de <strong>publicar este canal</strong>, a partir de ahora entrarán en vigor las nuevas configuraciones.',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_CAPTION: 'Canal configurado correctamente',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_SUCCESSFULLY_CONFIGURED_INFO:
        'Todo bien con la configuración de este canal',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_CAPTION:
        'El canal tiene errores de configuración',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_CONFIGURATIONS_WITH_ERRORS_INFO:
        'Parece que hay errores que impiden que se publique el canal.',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_CAPTION:
        'Canal configurado correctamente, pero con notas',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITH_OBSERVATIONS_INFO:
        '¡Parece que hay configuraciones no recomendadas en tu canal!',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_CAPTION: 'Canal parcialmente configurado',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_PARTIALLY_CONFIGURED_INFO:
        '¡Parece que hay ajustes pendientes en tu canal!',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_NAME_ESTABLISHED: 'Nombre de canal definido',
    CHANNEL_CONFIGURATION_VALIDATION_CHANNEL_WITHOUT_NAME_ESTABLISHED: 'Canal sin nombre',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_LINKED_TO_CHANNEL: 'Empresas vinculadas al canal',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_NOT_LINKED_TO_CHANNEL: 'Empresas no vinculadas al canal',
    CHANNEL_CONFIGURATION_VALIDATION_SEGMENTED_COMPANIES: 'Empresas segmentadas',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_WITHOUT_SEGMENTATION: 'Hay empresas sin segmentación',
    CHANNEL_CONFIGURATION_VALIDATION_COMPANIES_SEGMENTATION_DEFAULT_VALUE: '',
    CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_MENUS_MESSAGE: 'Menús definidos',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_MENUS_MESSAGE: 'Menús no definidos',
    CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_MENUS_MESSAGE: 'Menús parcialmente definidos',
    CHANNEL_CONFIGURATION_VALIDATION_MENUS_WITH_ICONSISTENCY_MESSAGE: 'Menús con inconsistencias',
    CHANNEL_CONFIGURATION_VALIDATION_MENUS_COMPANIES_WITHOUT_MENU_MESSAGE: 'No todas las empresas tienen menús.',
    CHANNEL_CONFIGURATION_VALIDATION_VALID_MESSAGES: 'Mensajes válidos',
    CHANNEL_CONFIGURATION_VALIDATION_INVALID_MESSAGES: 'Mensajes inválidos',
    CHANNEL_CONFIGURATION_VALIDATION_ESTABLISHED_WORKING_HOURS: 'Tiempos definidos',
    CHANNEL_CONFIGURATION_VALIDATION_PARTIALLY_ESTABLISHED_WORKING_HOURS: 'Horarios parcialmente definidos',
    CHANNEL_CONFIGURATION_VALIDATION_INVALID_WORKING_HOURS: 'Horas inválidas',
    CHANNEL_CONFIGURATION_VALIDATION_WORKING_HOURS_WITH_INCONSISTENCIES: 'Tiempos con inconsistencias',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_ESTABLISHED_WORKING_HOURS: 'Tiempos no definidos',
    CHANNEL_CONFIGURATION_VALIDATION_VALID_EXTRAS_CONFIG: 'Configuraciones adicionales válidas',
    CHANNEL_CONFIGURATION_VALIDATION_NOT_VALID_EXTRAS_CONFIG: 'Configuraciones adicionales no válidas',

    CHANNEL_CONFIGURATION_ALERT_CONTENT_TITLE: 'Configura y activa tu canal ahora para comenzar a usarlo.',
    CHANNEL_CONFIGURATION_ALERT_CONTENT_CLOSE_BUTTON: 'Cerrar',
    CHANNEL_CONFIGURATION_ALERT_CONTENT_CONFIGURE_CHANNEL_BUTTON: 'Configurar canal',

    CHANNEL_CONFIGURATION_SUCCESS_ALERT_VALIDATION_CONTENT_TITLE: 'Ajustes válidos',
    CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TITLE: 'Configuraciones inválidas',
    CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TEXT: '(correcto para publicar)',
    CHANNEL_CONFIGURATION_WARNING_ALERT_VALIDATION_CONTENT_TITLE: 'Comentarios',
    CHANNEL_CONFIGURATION_VALIDATION_CLOSE_ACTION_BUTTON: 'Cerrar',
    CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_CLOSE_BUTTON: 'Cerrar',
    CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_PUBLISH_BUTTON: 'Publicar',

    CHANNEL_CONFIGURATION_ALERT_VALIDATION_CANCEL_BUTTON_TEXT: 'Cancelar',
    CHANNEL_CONFIGURATION_ALERT_VALIDATION_CONFIRM_BUTTON_TEXT: 'Confirmar',

    CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS: '¡Parece que hay ajustes pendientes en tu canal!',
    CHANNEL_CONFIGURATION_ALERT_WARNING_PENDING_SETTINGS_DESCRIPTION: 'Estos son los ajustes pendientes',
    CHANNEL_CONFIGURATION_ALERT_WARNING_MENU: 'Menú',
    CHANNEL_CONFIGURATION_ALERT_WARNING_MESSAGES: 'Mensajes',
    CHANNEL_CONFIGURATION_ALERT_WARNING_TIMES: 'Horarios',
    CHANNEL_CONFIGURATION_ALERT_WARNING_CONTINUE_BUTTON: 'Continuar',
    CHANNEL_CONFIGURATION_ALERT_WARNING_CONFIGURE_CHANNEL_BUTTON: 'Configurar canal',

    LOADING_CONTENT_DESCRIPTION: 'Cargando...',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_GROUP_AND_TYPE_EVENT: 'Evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_ORIGIN_EVENT: 'Origen del evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_MEDIA_EVENT: 'Medios de comunicación',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_EVENT_GENERATE: 'Generar evento',
    CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_AUTO_RESCUE: 'Rescate automático',

    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_COMPANY_ALERT_TITLE: 'Empresa vinculada al menú',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_TITLE: 'Segmentación vinculada al menú',
    CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_SEGMENTATION_ALERT_CONTENT:
        'Antes de eliminar la segmentación "%s", desvincúlela del Menú %s.',
    CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_COMPANY_ALERT_CONTENT:
        'Antes de eliminar la empresa "%s", desvincúlela del Menú %s.',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CLOSE_ACTION: 'Cerrar',
    CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CONFIRM_ACTION: 'ir al menú'
}
