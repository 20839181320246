import React from 'react'
import clsx from 'clsx'

import { Dialog, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import { bindUpdate, ViewSlot } from 'wdc-cube-react'
import { DialogScope } from '../Main.scopes'
import { getOrMakeMainStyles } from './Main.styles'

const transitionDic = createTransitionDic()

export type MainModalDialogProps = {
    className?: string
    scope: DialogScope
}

export function MainModalDialogView({ className, scope }: MainModalDialogProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeMainStyles()

    return (
        <Dialog
            className={clsx(styles.modal, className)}
            open={scope.open}
            fullScreen={scope.fullscreen}
            maxWidth={false}
            TransitionComponent={transitionDic[scope.transition ?? 'none']}
            onClose={scope.onClose}
            aria-labelledby="form-dialog-title"
        >
            <ViewSlot scope={scope.content} />
        </Dialog>
    )
}

function createTransitionDic() {
    const DialogTransitionSlideUp = React.forwardRef(function DialogTransitionSlideUpRaw(
        { children, ...props }: TransitionProps & { children?: React.ReactElement },
        ref: React.Ref<unknown>
    ) {
        return (
            <Slide direction="up" ref={ref} exit={true} {...props}>
                {children ?? <></>}
            </Slide>
        )
    })

    const transitionDic: Record<
        string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>
    > = {
        'slide-up': DialogTransitionSlideUp
    }

    return transitionDic
}
