/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AdminMain as NS, ChannelId } from '@whatsapp/communication'
import { BaseAdminService } from 'src/services'
import { Logger, ServiceLike } from 'wdc-cube'

const LOG = Logger.get('MainService')

// :: Re-exporting types and consts because on commonjs there is no Namespace

export type { ChannelId }

export type Channel = ChannelId & {
    partner: {
        url: string
    }
}

export type ChannelsRequest = NS.ChannelsRequest
export type ChannelsResponse = NS.ChannelsResponse

export class MainService extends BaseAdminService implements ServiceLike {
    private static readonly INSTANCE = new MainService()

    public static singleton() {
        return MainService.INSTANCE
    }

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    // :: Instance

    private __initialized = false

    public get name() {
        return 'MainService'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        this.__initialized = true
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public async fetchChannels(): Promise<Channel[]> {
        const { channels, partners } = (
            await this.send({
                query: {
                    channels: {},
                    partners: {}
                }
            })
        ).query!

        this.raiseUnexpected(channels)
        this.raiseUnexpected(partners)

        const partnerMap = new Map<string, NS.Partner>()
        if (partners) {
            for (const partner of partners.partners) {
                partnerMap.set(partner.accountId, partner)
            }
        }

        const entries: Channel[] = []

        if (channels) {
            for (const channelId of channels.channels) {
                const partner = partnerMap.get(channelId.group)
                entries.push({
                    ...channelId,
                    partner: {
                        url: partner ? partner.url : ''
                    }
                })
            }
        }

        return entries
    }

    public async fetchIntercomAttributes() {
        const url = new URL('/adminV2/intercom', this.httpClient.endpoint)
        const attributes = await this.httpClient.fetchGet(url, 200)
        return attributes
    }
}
