import { lodash, StringCICompare, StringCompare } from '@syonet/lang'
import { MonitorType } from '@whatsapp/communication'
import {
    SelfRescueConfig,
    ServiceBySegmentation
} from '@whatsapp/communication/lib/bff/admin/ChannelConfiguration.communication'
import { ChipItemScope } from 'src/components/chippanel'
import { ChannelConfigurationFormKeys, KeysFactory } from 'src/Constants'
import type { CheckedChangeEvent } from 'src/utils'
import voca from 'voca'
import { action, Logger, Presenter } from 'wdc-cube'
import { AlertHolder } from '../main'
import { ChannelCompanyOption, CompanyChannelConfig, GeneralChannelConfig } from './ChannelConfiguration.service'
import { AlertWarningConfirmContentScope } from './ChannelConfigurationAlert.scopes'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import type { KeyPressEvent, TextChangeEvent } from './ChannelConfigurationForm.scopes'
import {
    ChannelConfigurationFormCompanyScope,
    ChannelConfigurationFormGeneralScope,
    ChannelConfigurationInternalCompanyConfigurationFormScope,
    Tabs
} from './ChannelConfigurationForm.scopes'
import { ChannelConfigurationCompanyConfigurationFormScope } from './companyConfiguration/ChannelConfigurationCompanyConfiguration.scopes'
import { ChannelConfigurationSelfRescueFormScope } from './selfRescue/ChannelConfigurationSelfRescueForm.scopes'
import { TextsProvider } from './texts'

const LOG = Logger.get('ChannelConfigurationFormGeneralPresenter')

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationFormGeneralPresenter extends Presenter<
    ChannelConfigurationFormGeneralScope,
    ChannelConfigurationFormPresenter
> {
    private companyPresenterArray = [] as ChannelConfigurationFormCompanyPresenter[]
    private segmentationArray = [] as string[]
    private selectedCompanyMapDirty = true
    private selectedCompanyMapCache: Map<number, ChannelCompanyOption> = new Map()

    private alertHolder: AlertHolder

    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner, owner.scope.generalTab, owner.updateManager)
        this.alertHolder = owner.alertHolder
    }

    public override release() {
        this.releaseCompanyPresentersAfterIndex(0)
        super.release()
    }

    public getSelectedCompanyMap(force = false) {
        if (this.selectedCompanyMapDirty || force) {
            const getCompanyMap = this.owner.getCompanyMap()

            const map = new Map<number, ChannelCompanyOption>()

            for (const companyPresenter of this.companyPresenterArray) {
                const companyId = companyPresenter.scope.companyValue
                if (!lodash.isNil(companyId)) {
                    const company = getCompanyMap.get(companyId)
                    if (company) {
                        map.set(companyId, company)
                    }
                }
            }

            this.selectedCompanyMapCache = map
            this.selectedCompanyMapDirty = false
        }

        return this.selectedCompanyMapCache as Readonly<Map<number, ChannelCompanyOption>>
    }

    private releaseCompanyPresentersAfterIndex(index: number) {
        if (index < this.companyPresenterArray.length) {
            this.scope.companies.companyEntries.length = index

            for (let j = this.companyPresenterArray.length - 1; j >= index; j--) {
                const companyPresenter = this.companyPresenterArray[j]
                try {
                    companyPresenter.release()
                } catch (caught) {
                    LOG.error('Unexpected error releasing companyPresenter', caught)
                }
                this.companyPresenterArray.length = j
            }
        }
    }

    public focusOnChannelName() {
        this.owner.scope.tabIndex = this.scope.index
        this.scope.focus = 'channel-name'
    }

    public blinkAddCompanyButton() {
        this.owner.scope.tabIndex = this.scope.index
        this.scope.companies.blink = 'add-company-button'
    }

    public focusOnFirstEmptyCompanyWithEmptySegment() {
        this.owner.scope.tabIndex = this.scope.index
        for (const companyPresenter of this.companyPresenterArray) {
            if (!companyPresenter.hasSegments) {
                companyPresenter.focusOnSegmentField()
                break
            }
        }
    }

    private bindListeners() {
        this.scope.onChannelNameChanged = this.onChannelNameChanged.bind(this)

        this.scope.companies.update = this.update
        this.scope.companies.onAppendCompany = this.onAppendCompany.bind(this)
        this.scope.companies.onCompanySegmentation = this.onCompanySegmentation.bind(this)
        this.scope.companies.onShowInfo = this.onShowInfo.bind(this)
        this.scope.companies.onSwapPosition = this.onSwapCompaniesPositions.bind(this)
        this.scope.onOpenSelfRescueEditor = this.onOpenSelfRescueEditor.bind(this)

        this.scope.onReorderSegmentationChanged = this.onReorderSegmentationChanged.bind(this)
        this.updateManager.hint(ChannelConfigurationFormCompanyScope, this.scope.companies, 5)
    }

    async initializeState(keys: ChannelConfigurationFormKeys, generalTabData: GeneralChannelConfig) {
        this.bindListeners()
        await this.synchronizeState(keys, generalTabData)
    }

    async synchronizeState(keys: ChannelConfigurationFormKeys, generalTabData: GeneralChannelConfig) {
        this.scope.channelName = generalTabData.channelName ?? ''
        this.scope.channelPhone = generalTabData.channelPhone
        this.scope.companies.companySegmentation = generalTabData.useSegments ?? this.onSegmentationChanged()

        const selfRescueConfig = generalTabData.selfRescueConfig
        if (selfRescueConfig) {
            this.scope.selfRescueConfig.active = selfRescueConfig?.active
            this.scope.selfRescueConfig.robotRescueMinutes = selfRescueConfig?.expireIn
            this.scope.selfRescueConfig.rescue = selfRescueConfig?.robotRescueMessages?.rescue ?? ''
            this.scope.selfRescueConfig.goodbye = selfRescueConfig?.robotRescueMessages?.goodbye ?? ''
            this.scope.selfRescueConfig.selfRescueType = selfRescueConfig?.type ?? 'ROBOT_RESCUE'

            this.scope.selfRescueConfig.selfRescueGroup = selfRescueConfig?.eventRescueConfiguration?.group ?? ''
            this.scope.selfRescueConfig.selfRescueTypeName = selfRescueConfig?.eventRescueConfiguration?.type ?? ''
            this.scope.selfRescueConfig.selfRescueOrigin = selfRescueConfig?.eventRescueConfiguration?.origin ?? ''
            this.scope.selfRescueConfig.selfRescueMidia = selfRescueConfig?.eventRescueConfiguration?.media ?? ''
        }

        this.segmentationArray = generalTabData.segmentation ?? this.onSegmentationChanged()
        this.scope.segmentation = this.segmentationArray

        this.synchronizeCompanyEntries(generalTabData.companies)
    }

    private synchronizeCompanyEntries(companyConfigurationArray: CompanyChannelConfig[]) {
        this.selectedCompanyMapDirty = true

        const remainingCompanyMap = new Map(this.owner.getCompanyMap())

        for (const companyConfig of companyConfigurationArray) {
            remainingCompanyMap.delete(companyConfig.companyId)
        }

        let i = 0

        for (const companyConfig of companyConfigurationArray) {
            let companyPresenter = this.companyPresenterArray[i]
            if (!companyPresenter) {
                companyPresenter = new ChannelConfigurationFormCompanyPresenter(this, this.alertHolder)
                this.companyPresenterArray[i] = companyPresenter
            }

            companyPresenter.scope.position = i + 1
            companyPresenter.synchronizeData(companyConfig, remainingCompanyMap)

            this.scope.companies.companyEntries.set(i, companyPresenter.scope)
            i++
        }

        this.scope.companies.companyEntries.length = i
        this.releaseCompanyPresentersAfterIndex(i)
    }

    private buildCompanyConfigurationArrayFromScope(someCompanyPresenter?: ChannelConfigurationFormCompanyPresenter) {
        const companyConfigurationArray: CompanyChannelConfig[] = []

        // Collect current state
        for (const companyPresenter of this.companyPresenterArray) {
            if (someCompanyPresenter === companyPresenter) {
                continue
            }

            const companyId = companyPresenter.scope.companyValue
            if (lodash.isNumber(companyId)) {
                const companyConfig = {
                    ...companyPresenter.extractFormData()
                }

                companyConfigurationArray.push(companyConfig)
            }
        }

        return companyConfigurationArray
    }

    getForm(): ChannelConfigurationSelfRescueFormScope {
        return this.scope.selfRescueConfig
    }

    saveForm(selfRescueConfig: SelfRescueConfig) {
        this.scope.selfRescueConfig.selfRescueType = selfRescueConfig.type
        this.scope.selfRescueConfig.active = selfRescueConfig.active
        this.scope.selfRescueConfig.robotRescueMinutes = selfRescueConfig.expireIn
        this.scope.selfRescueConfig.rescue = selfRescueConfig.robotRescueMessages?.rescue ?? ''
        this.scope.selfRescueConfig.goodbye = selfRescueConfig.robotRescueMessages?.goodbye ?? ''

        this.scope.selfRescueConfig.selfRescueGroup = selfRescueConfig.eventRescueConfiguration?.group ?? ''
        this.scope.selfRescueConfig.selfRescueOrigin = selfRescueConfig.eventRescueConfiguration?.origin ?? ''
        this.scope.selfRescueConfig.selfRescueMidia = selfRescueConfig.eventRescueConfiguration?.media ?? ''
        this.scope.selfRescueConfig.selfRescueTypeName = selfRescueConfig.eventRescueConfiguration?.type ?? ''
        this.update()
    }

    saveCompanyForm(companyConfiguration: ChannelConfigurationCompanyConfigurationFormScope) {
        const { id, companyName, companyReference, showAttendantName, serviceBySegmentation, usesPreSaleNotification } =
            companyConfiguration
        const company = this.getCompanyConfig(id)
        if (!company) return
        company.internalCompany.companyName = companyName
        company.internalCompany.companyReference = companyReference
        company.internalCompany.serviceBySegmentation = serviceBySegmentation
        if (showAttendantName !== company.toggleShowAttendantName) {
            company.onToggleShowAttendantName()
        }
        company.internalCompany.usesPreSaleNotification = usesPreSaleNotification
        this.update()
    }

    saveSegmentationByMenuForm(companyConfiguration: CompanyChannelConfig) {
        const { companyId, internal } = companyConfiguration
        if (companyConfiguration) {
            const company = this.getCompanyConfig(companyId)
            if (!company) return
            company.internalCompany.serviceBySegmentation = internal?.generalConfig?.servicesBySegmentation
            this.update()
        }
    }

    public getCompanyConfig(companyID: number) {
        return this.scope.companies.companyEntries.find((c) => c.companyValue === companyID)
    }

    refreshCompanyOptions() {
        this.selectedCompanyMapDirty = true
        const companyConfigurationArray = this.buildCompanyConfigurationArrayFromScope()
        this.synchronizeCompanyEntries(companyConfigurationArray)
    }

    @action()
    private async onOpenSelfRescueEditor() {
        const targetKeys = KeysFactory.channelConfigurationSelfRescueForm(this.owner.app)
        this.owner.app.flipToIntent(targetKeys.intent)
    }

    @action()
    public async onOpenCompanyConfigurationEditor({ id }: { id: number }) {
        const targetKeys = KeysFactory.channelConfigurationCompanyConfigurationForm(this.owner.app)
        targetKeys.companyConfiguration(id)
        this.owner.app.flipToIntent(targetKeys.intent)
    }

    @action()
    private onChannelNameChanged(evt: TextChangeEvent) {
        this.scope.channelName = evt.target.value
    }

    @action()
    private onAppendCompany() {
        const usedCompanyMap = new Map<number, boolean>()
        const remainingCompanyMap = new Map(this.owner.getCompanyMap())

        for (const companyPresenter of this.companyPresenterArray) {
            const companyId = companyPresenter.scope.companyValue
            if (!lodash.isNil(companyId)) {
                usedCompanyMap.set(companyId, true)
                remainingCompanyMap.delete(companyId)
            }
        }

        if (remainingCompanyMap.size > 0) {
            const i = this.companyPresenterArray.length

            const companyId = remainingCompanyMap.keys().next().value

            const companyPresenter = new ChannelConfigurationFormCompanyPresenter(this, this.alertHolder)
            companyPresenter.scope.position = i
            companyPresenter.synchronizeData(
                { companyId, segments: [], serviceBySegmentation: [] },
                remainingCompanyMap
            )

            this.companyPresenterArray[i] = companyPresenter
            this.scope.companies.companyEntries.set(i, companyPresenter.scope)

            this.refreshCompanyOptions()
            this.owner.emitAssignCompanyById(companyId)
        } else {
            this.owner.app.alert(
                'info',
                texts.CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_TITLE,
                texts.CHANNEL_CONFIGURATION_GENERAL_ALL_COMPANIES_INCLUDED_CONTENT
            )
        }
    }

    @action()
    private onCompanySegmentation(evt: CheckedChangeEvent, value: boolean) {
        this.scope.companies.companySegmentation = value

        for (const companyPresenter of this.companyPresenterArray) {
            companyPresenter.scope.segmentationVisible = value
        }
    }

    @action()
    async onDeleteCompany(companyPresenter: ChannelConfigurationFormCompanyPresenter) {
        const company = this.owner.getCompanyMap().get(companyPresenter.scope.companyValue ?? -1)
        const menuMap = this.owner.getMenuMap()
        const segmentationsBeignUsed: string[] = []

        if (company) {
            if (this.owner.isCompanyBeingUsed(company.id)) {
                const confirmScope = new AlertWarningConfirmContentScope()
                if (segmentationsBeignUsed.length === 0) {
                    confirmScope.message = `${texts.CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_CONFIRM_MESSAGE} ${company.name}?`
                    confirmScope.cancelLabelOption = texts.DELETE_MENU_OPTION_NO
                    confirmScope.confirmLabelOption = texts.DELETE_MENU_OPTION_YES
                    confirmScope.unfocusConfirmButton = true

                    confirmScope.onConfirm = async () => {
                        this.alertHolder.closeAlert()
                        const companyConfigurationArray = this.buildCompanyConfigurationArrayFromScope(companyPresenter)
                        this.synchronizeCompanyEntries(companyConfigurationArray)

                        this.owner.emitUnassignCompanyById(company.id)
                    }

                    confirmScope.onCancel = async () => {
                        LOG.info('Company exclusion canceled')
                        this.alertHolder.closeAlert()
                    }

                    confirmScope.update = this.update

                    this.alertHolder.alert(
                        'warning',
                        `${texts.CHANNEL_CONFIGURATION_GENERAL_ON_DELETE_COMPANY_AFIRMATION_MESSAGE} ${company.name}`,
                        confirmScope,
                        confirmScope.onCancel
                    )
                }

                for (const segmentationEntry of companyPresenter.scope.segmentation.entries) {
                    companyPresenter.scope.internalCompany.serviceBySegmentation?.forEach((segmentation) => {
                        if (segmentation.servicesId.length > 0 && segmentation.on === segmentationEntry.description) {
                            let menuId = 0

                            segmentationsBeignUsed.push(segmentation.on)

                            const confirmScope = new AlertWarningConfirmContentScope()
                            for (const [, menus] of menuMap) {
                                menus.items.forEach((menu) => {
                                    if (
                                        menu.companies.includes(companyPresenter.scope.internalCompany.id) ||
                                        menu.segmentations.includes(segmentationEntry.description)
                                    ) {
                                        menuId = menu.id
                                    }
                                })
                            }

                            if (segmentationsBeignUsed.length > 0) {
                                confirmScope.message = voca.sprintf(
                                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_COMPANY_ALERT_CONTENT,
                                    company.name,
                                    menuId
                                )
                                confirmScope.cancelLabelOption =
                                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CLOSE_ACTION
                                confirmScope.confirmLabelOption =
                                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CONFIRM_ACTION
                                confirmScope.unfocusConfirmButton = true

                                confirmScope.onConfirm = async () => {
                                    this.alertHolder.closeAlert()
                                    await companyPresenter.onOpenMenuSegmentation()
                                    await companyPresenter.flipToForm(menuId)
                                }

                                confirmScope.onCancel = async () => {
                                    this.alertHolder.closeAlert()
                                }

                                confirmScope.update = this.update

                                this.alertHolder.alert(
                                    'warning',
                                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_COMPANY_ALERT_TITLE,
                                    confirmScope,
                                    confirmScope.onCancel
                                )
                            }
                        }
                    })
                }
            } else {
                const companyConfigurationArray = this.buildCompanyConfigurationArrayFromScope(companyPresenter)
                this.synchronizeCompanyEntries(companyConfigurationArray)

                this.owner.emitUnassignCompanyById(company.id)
            }
        } else {
            const companyId = companyPresenter.scope.companyValue
            if (companyId) {
                const companyConfigurationArray = this.buildCompanyConfigurationArrayFromScope(companyPresenter)
                this.synchronizeCompanyEntries(companyConfigurationArray)
                this.owner.emitUnassignCompanyById(companyId)
            }
        }
    }

    @action()
    private onSwapCompaniesPositions(targetUid: string, sourceUid: string) {
        if (targetUid === sourceUid) {
            return
        }

        const targetIndex = this.scope.companies.companyEntries.findIndex((item) => item.uid === targetUid)
        const sourceIndex = this.scope.companies.companyEntries.findIndex((item) => item.uid === sourceUid)

        if (targetIndex !== -1 && sourceIndex !== -1) {
            const targetPresenter = this.companyPresenterArray[targetIndex]
            const sourcePresenter = this.companyPresenterArray[sourceIndex]

            this.companyPresenterArray[targetIndex] = sourcePresenter
            this.companyPresenterArray[sourceIndex] = targetPresenter

            const targetScope = this.scope.companies.companyEntries.get(targetIndex)
            const sourceScope = this.scope.companies.companyEntries.get(sourceIndex)

            this.scope.companies.companyEntries.set(targetIndex, sourceScope)
            this.scope.companies.companyEntries.set(sourceIndex, targetScope)

            let position = 1
            for (const entryScope of this.scope.companies.companyEntries) {
                entryScope.position = position++
            }
        }
    }

    @action()
    private onShowInfo() {
        this.alert(
            'info',
            texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_TITLE,
            texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_CONTENT
        )
    }

    public beforeUpdate() {
        const channelName = (this.scope.channelName ?? '').trim()
        if (channelName) {
            this.scope.channelNameError = ''
            this.owner.validator.channelName(true)
        } else {
            this.scope.channelNameError = texts.CHANNEL_CONFIGURATION_GENERAL_FIELD_CANNOT_BE_BLANK_ERROR
            this.owner.validator.channelName(false)
        }

        if (this.companyPresenterArray.length === 0) {
            this.owner.validator.companyAssigment(false)
            this.owner.validator.companySegmentationNonUsed()
        } else {
            this.owner.validator.companyAssigment(true)

            if (this.scope.companies.companySegmentation) {
                let informedSegmentCount = 0
                for (const companyPresenter of this.companyPresenterArray) {
                    if (companyPresenter.hasSegments) {
                        informedSegmentCount++
                    }
                }

                if (informedSegmentCount === this.companyPresenterArray.length) {
                    this.owner.validator.companySegmentationOk()
                } else {
                    this.owner.validator.companySegmentationIncomplete()
                }
            } else {
                this.owner.validator.companySegmentationNonUsed()
            }
        }
    }

    @action()
    public onSegmentationChanged() {
        const listSegCompanies = new Set<string>()
        this.scope.companies.companyEntries.map((ce) =>
            ce.segmentation.entries.map((se) => listSegCompanies.add(se.description))
        )
        if (this.segmentationArray && this.segmentationArray.length == 0) Array.from(listSegCompanies).sort()
        for (const description of Array.from(listSegCompanies)) {
            if (!this.segmentationArray.includes(description)) {
                this.segmentationArray.push(description)
            }
        }
        for (const description of this.segmentationArray) {
            if (!listSegCompanies.has(description)) {
                const index = this.segmentationArray.indexOf(description)
                if (index != -1) {
                    this.segmentationArray.splice(index, 1)
                }
            }
        }
        return this.segmentationArray
    }

    @action()
    public onReorderSegmentationChanged({ destination, source }: { destination: number; source: number }) {
        if (isNaN(destination)) return
        const [removed] = this.segmentationArray.splice(source, 1)
        this.segmentationArray.splice(destination, 0, removed)
    }

    public extractFormData(): GeneralChannelConfig {
        const companies: CompanyChannelConfig[] = []
        for (const companyPresenter of this.companyPresenterArray) {
            companies.push(companyPresenter.extractFormData())
        }

        return {
            channelName: this.scope.channelName,
            channelPhone: this.scope.channelPhone,
            useSegments: this.scope.companies.companySegmentation,
            companies,
            selfRescueConfig: {
                type: this.scope.selfRescueConfig.selfRescueType as MonitorType,
                active: this.scope.selfRescueConfig.active,
                expireIn: this.scope.selfRescueConfig.robotRescueMinutes,
                robotRescueMessages: {
                    rescue: this.scope.selfRescueConfig.rescue,
                    goodbye: this.scope.selfRescueConfig.goodbye
                },
                eventRescueConfiguration: {
                    group: this.scope.selfRescueConfig.selfRescueGroup,
                    media: this.scope.selfRescueConfig.selfRescueMidia,
                    origin: this.scope.selfRescueConfig.selfRescueOrigin,
                    type: this.scope.selfRescueConfig.selfRescueTypeName
                }
            },
            segmentation: this.onSegmentationChanged()
        }
    }
}

class ChannelConfigurationFormCompanyPresenter extends Presenter<ChannelConfigurationFormCompanyScope> {
    private alertHolder: AlertHolder

    public constructor(formPresenter: ChannelConfigurationFormGeneralPresenter, alertHolder: AlertHolder) {
        super(formPresenter, new ChannelConfigurationFormCompanyScope(), formPresenter.updateManager)
        this.alertHolder = alertHolder
        this.scope.onDelete = formPresenter.onDeleteCompany.bind(formPresenter, this)
        this.scope.onCompanyChanged = this.onCompanyChanged.bind(this)
        this.scope.onSegmentNameKeyDown = this.onSegmentNameKeyDown.bind(this)
        this.scope.onSegmentNameBlur = this.onSegmentNameBlur.bind(this)
        this.scope.onSegmentNameChanged = this.onSegmentNameChanged.bind(this)
        this.scope.onToggleShowAttendantName = this.onToggleShowAttendantName.bind(this)
        this.scope.onOpenCompanyConfigurationEditor = formPresenter.onOpenCompanyConfigurationEditor.bind(formPresenter)
    }

    public override get owner(): ChannelConfigurationFormGeneralPresenter {
        return super.owner as ChannelConfigurationFormGeneralPresenter
    }

    public get hasSegments() {
        return this.scope.segmentation.entries.length > 0
    }

    public focusOnSegmentField() {
        this.scope.focus = 'segment'
    }

    public synchronizeData(
        companyConfig: CompanyChannelConfig,
        remainingCompanyMap: Map<number, ChannelCompanyOption>
    ) {
        this.scope.segmentationVisible = this.owner.scope.companies.companySegmentation

        {
            // Put only available options
            let i = 0

            // If informed company exists
            const companyOption = this.owner.owner.getCompanyMap().get(companyConfig.companyId)
            if (companyOption) {
                this.scope.companyOptions.set(i++, { id: companyConfig.companyId, name: companyOption.name })
                this.scope.companyValue = companyConfig.companyId
                remainingCompanyMap.delete(companyConfig.companyId)
            } else {
                this.scope.companyValue = companyConfig.companyId
            }

            // Populate with unused companies
            for (const [companyId, companyOptions] of remainingCompanyMap.entries()) {
                this.scope.companyOptions.set(i++, { id: companyId, name: companyOptions.name })
            }

            const internalCompany = new ChannelConfigurationInternalCompanyConfigurationFormScope()
            internalCompany.id = companyConfig.companyId
            internalCompany.companyName =
                companyConfig.internal?.name || companyConfig.companyId === companyOption?.id
                    ? companyOption?.name
                    : this.owner.owner.getCompanyMap().get(companyConfig.companyId)?.name
            internalCompany.companyReference = companyConfig.internal?.reference
            internalCompany.showAttendantName = companyConfig.showAttendantName
            internalCompany.usesPreSaleNotification = companyConfig.usesPreSaleNotification
            internalCompany.serviceBySegmentation = companyConfig.internal?.generalConfig?.servicesBySegmentation

            this.scope.internalCompany = internalCompany

            this.scope.companyOptions.length = i

            this.scope.toggleShowAttendantName = companyConfig.showAttendantName ?? false

            this.scope.companyOptions.sort((a, b) => StringCompare(a.name, b.name))
        }

        if (companyConfig.segments && companyConfig.segments.length > 0) {
            let i = 0
            for (const segmentName of companyConfig.segments) {
                let segmentScope = this.scope.segmentation.entries.get(i)
                if (!segmentScope) {
                    segmentScope = new ChipItemScope()
                    segmentScope.onRemove = this.onRemoveSegment.bind(this, segmentScope)
                    segmentScope.update = this.update
                    this.scope.segmentation.entries.set(i, segmentScope)
                }
                segmentScope.description = segmentName
                i++
            }
            this.scope.segmentation.entries.length = i
        } else {
            this.scope.segmentation.entries.length = 0
        }
    }

    @action()
    private async onToggleShowAttendantName() {
        this.scope.toggleShowAttendantName = !this.scope.toggleShowAttendantName
        this.scope.internalCompany.showAttendantName = this.scope.toggleShowAttendantName
    }

    @action()
    private async onCompanyChanged(evt: TextChangeEvent) {
        const companyId = +evt.target.value
        const newCompany = this.owner.owner.getCompanyMap().get(companyId)
        const oldCompany = this.owner.owner.getCompanyMap().get(this.scope.companyValue ?? -1)
        if (newCompany && oldCompany && this.owner.owner.isCompanyBeingUsed(oldCompany.id)) {
            const confirmScope = new AlertWarningConfirmContentScope()
            confirmScope.message = `${texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE} ${oldCompany.name} ${texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_CONFIRMATION_MESSAGE_COMPLEMENT} ${newCompany.name}?`

            confirmScope.onConfirm = async () => {
                this.alertHolder.closeAlert()

                this.scope.companyValue = companyId
                this.owner.refreshCompanyOptions()
                this.owner.owner.emitUnassignCompanyById(oldCompany.id)
            }

            confirmScope.onCancel = async () => {
                LOG.info('Company exclusion canceled')
                this.alertHolder.closeAlert()
            }

            confirmScope.update = this.update

            this.alertHolder.alert(
                'warning',
                `${texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_CHANGED_DELETE_COMPANY_NAME} ${oldCompany.name}`,
                confirmScope,
                confirmScope.onCancel
            )
        } else {
            this.scope.companyValue = companyId
            this.owner.refreshCompanyOptions()
            if (oldCompany) {
                this.owner.owner.emitUnassignCompanyById(oldCompany.id)
            }
        }
    }

    @action()
    private async onRemoveSegment(segmentScope: ChipItemScope) {
        const index = this.scope.segmentation.entries.findIndex((item) => item === segmentScope)
        const menuMap = this.owner.owner.getMenuMap()
        const segmentationsBeignUsed: string[] = []

        this.scope.internalCompany.serviceBySegmentation?.forEach((segmentation) => {
            if (segmentation.servicesId.length > 0 && segmentation.on === segmentScope.description) {
                let menuId = 0

                segmentationsBeignUsed.push(segmentation.on)

                const confirmScope = new AlertWarningConfirmContentScope()
                for (const [, menus] of menuMap) {
                    menus.items.forEach((menu) => {
                        if (
                            menu.companies.includes(this.scope.internalCompany.id) ||
                            menu.segmentations.includes(segmentScope.description)
                        ) {
                            menuId = menu.id
                        }
                    })
                }

                confirmScope.message = voca.sprintf(
                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_DELETE_SEGMENTATION_ALERT_CONTENT,
                    segmentScope.description,
                    menuId
                )
                confirmScope.cancelLabelOption =
                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CLOSE_ACTION
                confirmScope.confirmLabelOption =
                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_CONFIRM_ACTION
                confirmScope.unfocusConfirmButton = true

                confirmScope.onConfirm = async () => {
                    this.alertHolder.closeAlert()
                    await this.onOpenMenuSegmentation()
                    await this.flipToForm(menuId)
                }

                confirmScope.onCancel = async () => {
                    this.alertHolder.closeAlert()
                }

                confirmScope.update = this.update

                this.alertHolder.alert(
                    'warning',
                    texts.CHANNEL_CONFIGURATION_GENERAL_MENU_SEGMENTATION_ALERT_TITLE,
                    confirmScope,
                    confirmScope.onCancel
                )
            }
        })

        if (index !== -1 && segmentationsBeignUsed.length === 0) {
            this.scope.segmentation.entries.removeByIndex(index)
        }

        this.owner.onSegmentationChanged()
    }

    @action()
    public async onOpenMenuSegmentation() {
        const keys = KeysFactory.channelConfigurationForm(this.owner.owner.app)
        keys.tabIndex(Tabs.MENU)
        await this.owner.owner.app.flipToIntent(keys.intent)
    }

    public async flipToForm(menuId: number) {
        const targetKeys = KeysFactory.channelConfigurationMenuSegmentationEditorForm(this.owner.owner.app)
        targetKeys.channelId(this.owner.owner.channelId)
        targetKeys.menuId(menuId)
        await this.owner.owner.flipToIntent(targetKeys.intent)
    }

    @action()
    private async onSegmentNameChanged(evt: TextChangeEvent) {
        this.scope.segmentName = evt.target.value
    }

    @action()
    private onSegmentNameKeyDown(evt: KeyPressEvent) {
        if (evt.key === 'Enter' || evt.key === 'Tab') {
            const segmentName = (this.scope.segmentName ?? '').trim()
            if (segmentName) {
                try {
                    for (const segmentationScope of this.scope.segmentation.entries) {
                        if (StringCICompare(segmentationScope.description, segmentName) === 0) {
                            segmentationScope.description = segmentName
                            return
                        }
                    }

                    const i = this.scope.segmentation.entries.length
                    const segmentScope = new ChipItemScope()
                    segmentScope.onRemove = this.onRemoveSegment.bind(this, segmentScope)
                    segmentScope.description = segmentName
                    segmentScope.update = this.update
                    this.scope.segmentation.entries.set(i, segmentScope)
                    evt.preventDefault()
                } finally {
                    this.owner.onSegmentationChanged()
                    this.scope.segmentName = ''
                }
            }
        }
    }

    @action()
    private async onSegmentNameBlur() {
        this.scope.segmentName = ''
    }

    public extractFormData(): CompanyChannelConfig {
        const segments: string[] = []
        for (const segmentationScope of this.scope.segmentation.entries) {
            segments.push(segmentationScope.description)
        }

        const internalCompany = this.scope.internalCompany

        const servicesBySegmentation: ServiceBySegmentation[] = []
        if (internalCompany && internalCompany.serviceBySegmentation) {
            for (const serviceBySegmentation of internalCompany.serviceBySegmentation) {
                if (serviceBySegmentation.servicesId.length > 0) {
                    servicesBySegmentation.push(serviceBySegmentation)
                }
            }
        }

        return {
            companyId: this.scope.companyValue ?? -1,
            segments,
            serviceBySegmentation: [],
            internal: {
                name: internalCompany.companyName,
                reference: internalCompany.companyReference,
                generalConfig: {
                    servicesBySegmentation
                }
            },
            showAttendantName: this.scope.toggleShowAttendantName,
            usesPreSaleNotification: internalCompany.usesPreSaleNotification
        }
    }
}
