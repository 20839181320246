import { DashboardKeys, KeysFactory } from 'src/Constants'
import { action, Presenter } from 'wdc-cube'
import { ActiveMessagesResumedExportXLSCommand } from './commands/ActiveMessagesResumedExportXLSCommand'
import { DashboardPresenter } from './Dashboard.presenter'
import {
    ActiveMessagesResumedResponse,
    ContentActiveMessagesResumedRequest,
    DashboardService
} from './Dashboard.service'
import { TextChangeEvent } from './DashboardActiveMessagesDetailed.scopes'
import {
    ActiveMessageTotalsScope,
    DashboardActiveMessagesResumedScope,
    MouseEvent
} from './DashboardActiveMessagesResumed.scopes'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

export class DashboardActiveMessagesResumedPresenter extends Presenter<DashboardActiveMessagesResumedScope> {
    private initialized = false

    public constructor(owner: DashboardPresenter) {
        super(owner, owner.scope.activeMessages)
    }

    public override get owner() {
        return super.owner as DashboardPresenter
    }

    private initializeState() {
        const section = this.scope

        section.update = this.update

        const activeMessages = this.scope.table

        activeMessages.update = this.update
        activeMessages.onChangePageIndex = this.onActiveMessagesChangePageIndex.bind(this)
        activeMessages.onChangeItemsPerPage = this.onActiveMessagesChangeItemsPerPage.bind(this)
        activeMessages.onExportToXLS = this.onActiveMessagesExportToXLS.bind(this)

        this.updateManager.hint(ActiveMessageTotalsScope, activeMessages, 5)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public publishParameters(keys: DashboardKeys) {
        // TODO
    }

    public propagateContext(keys: DashboardKeys) {
        this.publishParameters(keys)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async synchronizeState(keys: DashboardKeys, force = false) {
        let changed = false

        if (!this.initialized) {
            this.initializeState()
            this.initialized = true
            changed = true
        }

        return changed
    }

    public prepareRequest(request: Partial<ContentActiveMessagesResumedRequest>) {
        const mainFilter = this.owner.filter.cloneState()

        if (!request.channels) {
            request.channels = mainFilter.channels
        }

        if (!request.startDateTime) {
            request.startDateTime = mainFilter.startTime
        }

        if (!request.startDateTime) {
            request.endDateTime = mainFilter.endTime
        }

        if (request.pageIndex === undefined && this.scope.table.pageIndex > 0) {
            request.pageIndex = this.scope.table.pageIndex
        }

        if (request.itemsPerPage === undefined) {
            if (this.scope.table.itemsPerPage > 0) {
                request.itemsPerPage = this.scope.table.itemsPerPage
            } else {
                request.itemsPerPage = 10
            }
        }

        return request as ContentActiveMessagesResumedRequest
    }

    private async refresh(request: Partial<ContentActiveMessagesResumedRequest>, data?: ActiveMessagesResumedResponse) {
        this.scope.table.loading = true
        const response = data || (await dashboardService.fetchActiveMessagesResumed(this.prepareRequest(request)))
        this.applyData(response)
    }

    public async applyData(response: ActiveMessagesResumedResponse) {
        const tableScope = this.scope.table
        try {
            let totalSentMessages = 0
            let itemIndex = 0
            for (const item of response.entries) {
                let itemScope = tableScope.entries.get(itemIndex)
                if (!itemScope) {
                    itemScope = new ActiveMessageTotalsScope()
                    itemScope.onClick = this.onActiveMessageClicked.bind(this, itemScope)
                    itemScope.update = tableScope.update
                    tableScope.entries.set(itemIndex, itemScope)
                }

                itemScope.userId = item.user.id
                itemScope.userName = item.user.name
                itemScope.quantity = item.sentMessages

                totalSentMessages += item.sentMessages

                itemIndex++
            }
            tableScope.entries.length = itemIndex
            tableScope.pageIndex = response.meta.currentPage
            tableScope.itemsPerPage = response.meta.itemsPerPage
            tableScope.itemCount = response.meta.totalItems

            tableScope.partialSentMessageCount = totalSentMessages

            const kpi = response?.kpi
            if (kpi) {
                this.scope.quantityOfSentMessages = kpi.quantityOfSentMessages
            } else {
                this.scope.quantityOfSentMessages = 0
            }
        } finally {
            tableScope.loading = false
        }
    }

    @action()
    protected async onActiveMessageClicked(item: ActiveMessageTotalsScope) {
        const keys = KeysFactory.dashboardUserActiveMessagesDetail(this.owner.app)
        keys.userId(item.userId)
        keys.selectedItem({
            userId: item.userId,
            quantity: item.quantity
        })
        await this.owner.flipToIntent(keys.intent)
    }

    @action()
    protected async onActiveMessagesChangePageIndex(evt: MouseEvent, pageIndex: number) {
        const activeMessages = this.scope.table
        await this.refresh({
            pageIndex,
            itemsPerPage: activeMessages.itemsPerPage
        })

        this.owner.updateHistory()
    }

    @action()
    protected async onActiveMessagesChangeItemsPerPage(evt: TextChangeEvent) {
        const activeMessages = this.scope.table
        await this.refresh({
            pageIndex: 0,
            itemsPerPage: evt.target.value ? +evt.target.value : activeMessages.itemsPerPage
        })
        this.owner.updateHistory()
    }

    @action()
    protected async onActiveMessagesExportToXLS() {
        const mainFilter = this.owner.filter.cloneState()
        await new ActiveMessagesResumedExportXLSCommand({
            channels: mainFilter.channels,
            startTime: mainFilter.startTime,
            endTime: mainFilter.endTime,
            alert: this.alert.bind(this, 'info', texts.ON_ACTIVE_MESSAGES_EXPORT_TO_XLS_ALERT_TITLE)
        }).run()
    }
}
