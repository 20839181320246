import { DateTimePickerScope } from 'src/components/datetimepicker'
import { MultiListingScope } from 'src/components/multilist'
import { KeyPressEvent } from 'src/utils'
import { Observable, observe, Scope } from 'wdc-cube'

export type { KeyPressEvent }

@Observable
export class DashboardFilterScope extends Scope {
    readonly channelsSelector = new MultiListingScope()
    readonly startTime = new DateTimePickerScope()
    readonly endTime = new DateTimePickerScope()

    @observe() opened = false
    @observe() valid = false
    @observe() filtering = false

    @observe() onOpen = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
    @observe() onClear = Scope.ASYNC_ACTION
    @observe() onApply = Scope.ASYNC_ACTION
}
