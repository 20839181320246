import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { LoadingButton } from '@mui/lab'
import { Button, ClickAwayListener, FormControl, MenuItem, Paper, Select } from '@mui/material'
import { lodash } from '@syonet/lang'
import clsx from 'clsx'
import React from 'react'
import { StringOptionType } from 'src/components/autocompletefield'
import { SimpleAutoCompleteStringFieldView } from 'src/components/autocompletefield/views'
import { NOOP_PROMISE_VOID } from 'wdc-cube'
import { IViewProps, ViewSlot, bindUpdate } from 'wdc-cube-react'
import { ChatsFilterScope } from '../ChatsFilter.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChatsStyles } from './Chats.styles'

// @Inject
const texts = TextsProvider.get()

// :: Component(ChatsFilterView)

type ChatsFilterProps = IViewProps & {
    scope: ChatsFilterScope
}

export function ChatsFilterView({ className, scope }: ChatsFilterProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatsStyles()

    return (
        <div
            key="container"
            className={clsx(styles.filter, className)}
            onClick={scope.opened ? NOOP_PROMISE_VOID : scope.onOpen}
        >
            <FilterAltOutlinedIcon />
            {scope.opened ? (
                <div className={styles.filterPopover}>
                    <PanelSearch scope={scope} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

type PanelSearchProps = {
    scope: ChatsFilterScope
}

function PanelSearch({ scope }: PanelSearchProps) {
    const { classes: styles } = getOrMakeChatsStyles()

    return (
        <>
            <ClickAwayListener onClickAway={scope.onClose}>
                <Paper className={styles.filterPopoverContent}>
                    <div className={styles.content}>
                        <div className={styles.adjustItem}>
                            <div className={styles.adjustLabel}>
                                <label className={styles.filterLabelText}>{texts.CHATS_FILTER_CUSTOMER_LABEL}</label>
                            </div>
                            <SimpleAutoCompleteStringFieldView
                                scope={scope.customerName}
                                optionLabel={customerNameOptionLabel}
                                placeholder={texts.CHATS_FILTER_CUSTOMER_PLACEHOLDER}
                                disabled={scope.isCustomerPhoneChanging}
                                onInputChange={scope.onCustomerNameInputChanged}
                                onKeyUp={scope.onApplyFilterByPressedKey}
                            />
                        </div>
                        <div className={styles.adjustItem}>
                            <div className={styles.adjustLabel}>
                                <label className={styles.filterLabelText}>{texts.CHATS_FILTER_CUSTOMER_PHONE_LABEL}</label>
                            </div>
                            <SimpleAutoCompleteStringFieldView
                                scope={scope.customerPhone}
                                optionLabel={customerPhoneOptionLabel}
                                placeholder={texts.CHATS_FILTER_CUSTOMER_PHONE_PLACEHOLDER}
                                disabled={scope.isCustomerNameChanging}
                                onInputChange={scope.onCustomerPhoneInputChanged}
                                onKeyUp={scope.onApplyFilterByPressedKey}
                            />
                        </div>
                        <div className={styles.adjustItem}>
                            <div className={styles.adjustLabel}>
                                <label className={styles.filterLabelText}>{texts.CHATS_FILTER_CHAT_LABEL}</label>
                            </div>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <Select
                                    onChange={scope.onTicketStatusValueUpdate}
                                    value={scope.ticketStatusValue.toString()}
                                    MenuProps={{ disablePortal: true }}
                                >
                                    {scope.ticketStatusOptions.map((text, index) => (
                                        <MenuItem key={index} value={index}>
                                            {text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={styles.adjustLabel}>
                            <label className={styles.filterLabelText}>{texts.CHATS_FILTER_DATE_TIME_LABEL}</label>
                        </div>
                        <div className={styles.adjustItem}>
                            <ViewSlot scope={scope.startTime} />
                        </div>

                        <div className={styles.adjustItem}>
                            <ViewSlot scope={scope.endTime} />
                        </div>

                        <div className={styles.adjustLabel}>
                            <label className={styles.filterLabelText}>{texts.CHATS_FILTER_CHANNEL_LABEL}</label>
                        </div>
                        <div className={styles.adjustChannelListing}>
                            <FormControl>
                                <ViewSlot scope={scope.channelsSelector} />
                            </FormControl>
                        </div>

                        <div className={styles.filterAlignButton}>
                            <Button color="inherit" onClick={scope.onClear}>
                                {texts.CHATS_FILTER_CLEAR_BUTTON}
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                loading={scope.filtering}
                                disabled={!scope.valid}
                                onClick={scope.onApply}
                            >
                                {texts.CHATS_FILTER_FILTER_BUTTON}
                            </LoadingButton>
                        </div>
                    </div>
                </Paper>
            </ClickAwayListener>
        </>
    )

    function customerNameOptionLabel(option: string | StringOptionType) {
        return lodash.isString(option) ? option : option.name
    }

    function customerPhoneOptionLabel(option: string | StringOptionType) {
        let result = ''
        if (lodash.isString(option)) {
            result = option
        } else if (option.phone) {
            result = option.phone
        }

        return result
    }
}
