import { Command } from './command.decorator'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function Inject<T = any>(token?: T) {
    return (target: object, key: string | symbol) => {
        const type = token || Reflect.getMetadata('design:type', target, key)

        let properties = Reflect.getMetadata(Command.PROPERTY_DEPS_METADATA, target.constructor) || []

        properties = [...properties, { key, type }]
        Reflect.defineMetadata(Command.PROPERTY_DEPS_METADATA, properties, target.constructor)
    }
}
