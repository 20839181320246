import { keyframes } from '@emotion/react'
import { tableCellClasses } from '@mui/material'
import { grey, yellow } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

const Arial = 'Arial'
const Roboto = 'Roboto'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const getOrMakeChannelConfigurationListingStyles = makeStyles()(() => ({
    view: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        justifySelf: 'flex-start'
    },
    filter: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        backgroundColor: '#797979',
        padding: '4px 12px 4px 12px',
        fontFamily: Roboto,
        fontWeight: 500,
        fontSize: 13,
        letterSpacing: 0.46,
        cursor: 'pointer',
        borderRadius: 4,

        '&:hover': {
            backgroundColor: '#898989'
        }
    },
    filterPopover: {
        position: 'absolute',
        top: 50,
        right: 22,
        zIndex: 10
    },
    filterPopoverContent: {
        width: '40ch',
        position: 'absolute',
        right: 0,
        padding: 12.5,
        animation: `${fadeIn} 0.2s ease-in`
    },
    filterMarginButton: {
        marginLeft: 8
    },
    filterAlignButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 2,
        padding: '0 4px 0 4px'
    },
    filterLabelText: {
        color: grey[700],
        marginLeft: 5,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: Arial,
        fontSize: 14,
        letterSpacing: 0.15
    },
    adjustLabel: {
        marginBottom: 12
    },
    item: {
        marginBottom: 8,
        padding: '0 4px 0 4px'
    }
}))

export const getOrMakeChannelConfigurationListingTableStyles = makeStyles()(() => ({
    root: {
        width: '100%'
    },
    progress: {
        position: 'fixed',
        zIndex: 100,
        top: 64,
        left: 0,
        right: 0
    },
    noContentAlert: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 10,
        padding: 20,
        color: 'rgba(0, 0, 0, .6)',
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${yellow[800]}`,
        '& .icon': {
            color: yellow[800]
        },
        '& .rows': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    customHeader: {
        paddingTop: 6,
        paddingLeft: 8,
        paddingBottom: 16
    },
    caption: {
        color: grey[600],
        whiteSpace: 'nowrap'
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
            whiteSpace: 'nowrap',
            width: 250,
            overflowX: 'auto'
        }
    },
    tableHeader: {
        position: 'relative',
        zIndex: 0
    },
    tableAction: {
        [`&.${tableCellClasses.head}`]: {
            width: 70
        }
    },
    customTableRow: {
        '&:last-child td, &:last-child th': {
            border: 0
        }
    },
    blockActions: {
        pointerEvents: 'none'
    }
}))
