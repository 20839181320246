import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

const defaultFont = 'Arial'

export const getOrMakeStyles = makeStyles()((theme: Theme) => ({
    messageBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    editorBox: {
        border: `1px solid ${grey[400]}`,
        borderRadius: 3,
        '&:hover': {
            border: `1px solid ${theme.palette.text.primary}`
        }
    },
    editorBoxWithoutError: {
        '&:focus-within': {
            border: `1px solid ${theme.palette.info.main}`,
            outline: `1px solid ${theme.palette.info.main}`,

            '& .SyoRichEditor-Label': {
                color: theme.palette.info.dark
            }
        }
    },
    editorBoxWithError: {
        borderColor: theme.palette.error.main,

        '&:hover': {
            borderColor: theme.palette.error.main
        },
        '&:focus-within': {
            borderColor: theme.palette.error.main,
            outline: `1px solid ${theme.palette.error.main}`
        },
        '& .SyoRichEditor-Label': {
            color: theme.palette.error.main
        }
    },
    caption: {
        fontFamily: defaultFont,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.15,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.contrastText,
        width: 'fit-content',
        border: '1px solid transparent',
        whiteSpace: 'nowrap',
        paddingLeft: 5,
        paddingRight: 5,
        position: 'relative',
        top: -10,
        left: 8
    },
    editorInput: {
        position: 'relative',
        marginTop: -15,
        paddingBottom: 14
    },
    editorConfiguration: {
        overflow: 'scroll',
        maxHeight: 120,
        fontFamily: defaultFont,
        fontWeight: 400,
        fontSize: 14,
        color: grey[700],
        letterSpacing: 0.15,
        lineHeight: 1.5,
        minHeight: 44,
        border: 'transparent',
        paddingTop: 10,
        paddingLeft: 10,

        '& .public-DraftStyleDefault-block': {
            margin: '0!important'
        }
    },
    toolbarConfiguration: {
        cursor: 'default',
        display: 'flex!important',
        justifyContent: 'end!important',
        alignItems: 'center',
        borderTop: `1px solid ${grey[400]}!important`,
        borderLeft: 'transparent!important',
        borderRight: 'transparent!important',
        borderBottom: 'transparent!important',
        borderRadius: '0px 0px 3px 3px!important',
        marginBottom: '-12px!important'
    },
    toolbarButtons: {
        border: 'transparent!important'
    },
    emojiPopup: {
        left: 'initial!important',
        right: 1
    },
    errorMessageRelativeToToolbar: {
        position: 'absolute',
        bottom: 30,
        left: 10,
        right: 220,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginBottom: '-15px!important'
    },
    view: {
        display: 'flex',
        alignItems: 'center'
    },
    adjustErrorMessage: {
        fontFamily: defaultFont,
        fontSize: 13,
        fontWeight: 400,
        letterSpacing: 0.15,
        color: theme.palette.error.main
    },
    adjustTag: {
        fontFamily: defaultFont,
        fontSize: 13,
        fontWeight: 700,
        letterSpacing: 0.15,
        color: theme.palette.info.main,
        marginLeft: 2,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 1
    }
}))
