import assignIn from 'lodash/assignIn'
import assignInWith from 'lodash/assignInWith'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import escape from 'lodash/escape'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isDate from 'lodash/isDate'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isError from 'lodash/isError'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isFinite from 'lodash/isFinite'
import isObject from 'lodash/isObject'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import padEnd from 'lodash/padEnd'
import padStart from 'lodash/padStart'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import trim from 'lodash/trim'
import chunk from 'lodash/chunk'
import capitalize from 'lodash/capitalize'
import uniqueId from 'lodash/uniqueId'

export type { DebouncedFunc } from 'lodash'

export const lodash = {
    assignIn,
    assignInWith,
    camelCase,
    cloneDeep,
    chunk,
    escape,
    debounce,
    isArray,
    isBoolean,
    isDate,
    isEmpty,
    isEqual,
    isError,
    isFunction,
    isNil,
    isNull,
    isNumber,
    isFinite,
    isObject,
    isObjectLike,
    isString,
    padStart,
    padEnd,
    replace,
    sortBy,
    trim,
    capitalize,
    uniqueId,
}
