import { ItemScope as MultiSelectItemScope, MultiSelectionScope } from 'src/components/multiselect'
import { action, IUpdateManager, Logger, NOOP_FALSE, NOOP_VOID, Presenter } from 'wdc-cube'
import { MainPresenter } from '../main'
import { ChannelCompanyOption } from './ChannelConfiguration.service'
import { TextsProvider } from './texts'

const LOG = Logger.get('CompanySelectorPresenter')

// @Inject
const texts = TextsProvider.get()

type CompanyItem = {
    id: number
    name: string
    checked: boolean
}

export class CompanySelectorPresenter extends Presenter<MultiSelectionScope> {
    public onAfterCompanyToggled: (companyId: number, checked: boolean) => void = NOOP_VOID

    private companyMap = new Map<string, CompanyItem>()

    private initialized = false

    public isCompanyInUse: (companyId: number) => boolean = NOOP_FALSE

    constructor(app: MainPresenter, scope: MultiSelectionScope, updateManager?: IUpdateManager) {
        super(app, scope, updateManager)
    }

    public override release() {
        this.scope.update = NOOP_VOID
        super.release()
    }

    public get app() {
        return this.owner as MainPresenter
    }

    private initializeState(availableCompanyMap: Map<number, ChannelCompanyOption>) {
        this.scope.update = this.update

        this.scope.description = texts.CHANNEL_CONFIGURATION_COMPANY_SELECTOR_DESCRIPTION

        for (const [companyId, companyOption] of availableCompanyMap.entries()) {
            this.companyMap.set(String(companyId), { id: companyId, name: companyOption.name, checked: false })
        }

        this.initialized = true
    }

    public synchronizeState(availableCompanyMap: Map<number, ChannelCompanyOption>, selectedCompanies: number[]) {
        if (!this.initialized) {
            this.initializeState(availableCompanyMap)
        }

        for (const companyId of selectedCompanies) {
            const company = this.companyMap.get(String(companyId))
            if (company) {
                company.checked = true
            }
        }

        this.transferStateToScopes()
    }

    public transferStateToScopes() {
        const selectedIndexes = [] as number[]
        const selectedDescription = [] as string[]
        let i = 0
        for (const [code, companyItem] of this.companyMap.entries()) {
            let comboItem = this.scope.entries.get(i)
            if (!comboItem) {
                comboItem = new MultiSelectItemScope()
                comboItem.onSelectionToggled = this.onCompanyItemCheckToggled.bind(this, comboItem)
                this.scope.entries.set(i, comboItem)
            }
            comboItem.code = code
            comboItem.description = companyItem.name
            comboItem.inUse = this.isCompanyInUse(companyItem.id)

            comboItem.selected = companyItem.checked
            if (companyItem.checked) {
                selectedIndexes.push(i)
                selectedDescription.push(companyItem.name)
            }
            i++
        }
        this.scope.entries.length = i
        this.scope.selectedIndexes = selectedIndexes
        this.scope.selectedDescription = selectedDescription.join(', ')
    }

    @action()
    private async onCompanyItemCheckToggled(item: MultiSelectItemScope) {
        const companyOption = this.companyMap.get(item.code)
        if (companyOption) {
            companyOption.checked = !item.selected
            this.transferStateToScopes()
            try {
                this.onAfterCompanyToggled(companyOption.id, companyOption.checked)
            } catch (caught) {
                LOG.error(texts.CHANNEL_CONFIGURATION_COMPANY_SELECTOR_UNEXPECTED_ERROR, caught)
            }
        } else {
            LOG.error(`${texts.CHANNEL_CONFIGURATION_COMPANY_SELECTOR_MISSING_COMPANIES_ERROR} ${item.code}`)
        }
    }

    public extractCompanyIdArray() {
        const result = [] as number[]

        for (const company of this.companyMap.values()) {
            if (company.checked) {
                result.push(company.id)
            }
        }

        return result
    }
}
