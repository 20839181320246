const SYO_WHATSAPP_STAGING_URL = 'https://syo-whats-app.staging.prod.whatsapp.syonet.com'
const SYO_WHATSAPP_GUPSHUP_URL = 'https://syo-whats-app.gupshup.prod.whatsapp.syonet.com'
const SYO_WHATSAPP_PROD_URL = 'https://syo-whats-app.prod.whatsapp.syonet.com'
const SYO_WHATSAPP_INTERNAL_URL = 'https://syo-whats-app.internal.syonet.com'

export const handleSyoWhatsAppBaseUrl = (hostName: string) => {
    if (hostName.includes('.staging.prod.whatsapp.syonet.com')) {
        return SYO_WHATSAPP_STAGING_URL
    }

    if (hostName.includes('.gupshup.prod.whatsapp.syonet.com')) {
        return SYO_WHATSAPP_GUPSHUP_URL
    }

    if (hostName.includes('.prod.whatsapp.syonet.com')) {
        return SYO_WHATSAPP_PROD_URL
    }

    return SYO_WHATSAPP_INTERNAL_URL
}
