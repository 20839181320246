import { MultiSelectionScope } from 'src/components/multiselect'
import { TimePickerScope } from 'src/components/timepicker'
import { v4 as uuid } from 'uuid'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import { CheckedChangeEvent } from './ChannelConfigurationForm.scopes'

export type { CheckedChangeEvent }

export enum PeriodActionType {
    NONE,
    ADD,
    REMOVE
}

@Observable
export class PeriodScope extends Scope {
    readonly startTime = new TimePickerScope()
    readonly endTime = new TimePickerScope()
}

@Observable
export class ChannelConfigurationWorkingHoursEditorFormWeekDayScope extends Scope {
    readonly periods = new ObservableArray<PeriodScope>(this)

    @observe() checked = false
    @observe() caption = ''
    @observe() error = ''
    @observe() periodActionType: PeriodActionType = PeriodActionType.ADD

    @observe() onCheckChanged = Scope.ASYNC_ACTION_ONE<CheckedChangeEvent>()
    @observe() onPeriodActionClicked = Scope.ASYNC_ACTION
}

@Observable
export class ChannelConfigurationWorkingHoursEditorFormScope extends Scope {
    readonly uid = uuid()
    readonly companiesSelector = new MultiSelectionScope()
    readonly monday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly tuesday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly wednesday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly thursday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly friday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly saturday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()
    readonly sunday = new ChannelConfigurationWorkingHoursEditorFormWeekDayScope()

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
}
