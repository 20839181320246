import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, TextField } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { ComboView } from 'src/components/combo/views'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { ChatsFormBodyScope, ChatsFormScope } from '../ChatsForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChatsFormStyles } from './Chats.styles'

// @Inject
const texts = TextsProvider.get()

// :: ChatsForm

export type ChatsFormViewProps = IViewProps & {
    scope: ChatsFormScope
}

export function ChatsFormView({ scope, className }: ChatsFormViewProps) {
    bindUpdate(React, scope)
    return <ViewSlot className={className} scope={scope.body} />
}

// :: ChatsFormBody

export type ChatsFormBodyProps = IViewProps & {
    scope: ChatsFormBodyScope
}

export function ChatsFormBodyView({ scope, className }: ChatsFormBodyProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatsFormStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.header}>{texts.CHATS_FORM_TITLE}</div>
            <Divider />
            <div className={styles.newTicketContainer}>
                <div className={styles.adjustField}>
                    <TextField
                        label={texts.CHATS_FORM_CUSTOMER_NAME_LABEL}
                        size="small"
                        value={scope.customerName}
                        onChange={scope.onChangeCustomerName}
                        disabled
                        fullWidth
                    />
                </div>
                <div className={styles.adjustField}>
                    <TextField
                        label={texts.CHATS_FORM_CUSTOMER_PHONE_LABEL}
                        size="small"
                        value={scope.customerPhone}
                        onChange={scope.onChangeCustomerPhone}
                        disabled
                        fullWidth
                    />
                </div>
                <div className={styles.adjustField}>
                    <ComboView label={texts.CHATS_FORM_COMPANY_NAME_LABEL} scope={scope.company} />
                </div>
                <div className={styles.adjustField}>
                    <ComboView label={texts.CHATS_FORM_TICKET_LABEL} scope={scope.ticketType} />
                </div>
                <div className={styles.adjustGrid}>
                    <div className={styles.adjustGridItem}>
                        <ComboView label={texts.CHATS_FORM_SOURCE_LABEL} scope={scope.source} />
                    </div>
                    <div className={styles.adjustGridItem}>
                        <ComboView
                            label={texts.CHATS_FORM_MEDIA_LABEL}
                            scope={scope.media}
                            className={styles.adjustGridItemSize}
                        />
                    </div>
                </div>
                <div className={styles.adjustField}>
                    <ComboView label={texts.CHATS_FORM_TICKET_OWNER_LABEL} scope={scope.responsable} />
                </div>
                <div className={styles.adjustField}>
                    <FormGroup className={styles.adjustCheckboxBody}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    checked={scope.acceptCustomResponsable}
                                    onChange={scope.onToggleAcceptCustomResponsable}
                                />
                            }
                            label={texts.CHATS_FORM_TICKET_RULE_LABEL}
                            className={styles.adjustCheckboxLabel}
                        />
                    </FormGroup>
                </div>
                <Divider />
            </div>
            <div className={styles.adjustButtons}>
                <Button color="inherit" onClick={scope.onCancel}>
                    {texts.CHATS_FORM_CANCEL_BUTTON}
                </Button>
                {scope.generatingTicket ? (
                    <LoadingButton loading loadingPosition="end" endIcon={<SaveIcon />} variant="contained">
                        {texts.CHATS_FORM_GENERATE_TICKET_BUTTON}
                    </LoadingButton>
                ) : (
                    <Button variant="contained" color="secondary" onClick={scope.onGenerateTicket}>
                        {texts.CHATS_FORM_GENERATE_TICKET_BUTTON}
                    </Button>
                )}
            </div>
        </div>
    )
}
