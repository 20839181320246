import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { TextsProvider } from '../texts'
import { WaitingScope } from '../Waiting.scopes'
import { getOrMakeWaitingStyles } from './Waiting.styles'

// @Inject
const texts = TextsProvider.get()

export type WaitingViewProps = IViewProps & {
    scope: WaitingScope
    text?: string
}

export function WaitingView({ className, scope, text }: WaitingViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeWaitingStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.appLoading} />
            <div className={styles.appLoadingText}>{scope.text ?? (text || texts.LOADING_CONTENT_DESCRIPTION)}</div>
        </div>
    )
}
