import React, { useEffect, useState } from 'react'
import { TextsProvider } from '../../texts'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { ChannelConfigurationSelfRescueFormScope } from '../../selfRescue/ChannelConfigurationSelfRescueForm.scopes'
import { getOrMakeChannelConfigurationSelfRescueFormStyles as getOrMakeStyles } from './ChannelConfigurationSelfRescueForm.styles'
import clsx from 'clsx'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { SourceAndMediaType } from '@whatsapp/communication/lib/bff/admin/ChannelConfiguration.communication'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationSelfRescueFormViewProps = IViewProps & {
    scope: ChannelConfigurationSelfRescueFormScope
}

export function ChannelConfigurationSelfRescueFormView({
    className,
    scope
}: ChannelConfigurationSelfRescueFormViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()
    const [mediasFinded, setMediasFinded] = useState<SourceAndMediaType[]>([])
    const [idOrigin, setIdOrigin] = useState('')
    const [idGroup, setIdGroup] = useState('')

    const types = [
        {
            id: 'ROBOT_RESCUE',
            name: texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_AUTO_RESCUE
        },
        {
            id: 'EVENT_RESCUE',
            name: texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_EVENT_GENERATE
        }
    ]

    function loadInformationsByScope() {
        if (scope.selfRescueGroup != '') {
            const groupFounded = scope.selfRescuemenuEventOptionsForm.find((item) => {
                return item.group === scope.selfRescueGroup && item.type === scope.selfRescueTypeName
            })

            if (groupFounded) {
                setIdGroup(groupFounded?.id)
            }
        }

        if (scope.selfRescueOrigin != '') {
            const originFounded = scope.selfRescueSourceAndMediaEventOptionsForm.find(
                (item) => item.source.name === scope.selfRescueOrigin
            )

            if (originFounded) {
                loadMedias(String(originFounded.source.id))
                setIdOrigin(String(originFounded.source.id))
            }
        }
    }

    function handleSelectEvent(id: string) {
        const res = scope.selfRescuemenuEventOptionsForm.find((item) => item.id === id)

        if (res) {
            setIdGroup(id)
            scope.onSelfRescueGroup(res.group.trim())
            scope.onSelfRescueTypeName(res.type.trim())
        }
    }

    function loadMedias(id: string) {
        const response = scope.selfRescueSourceAndMediaEventOptionsForm.find(
            (item) => item.source.id.toString() === id.toString()
        )

        if (response) {
            scope.onSelfRescueOrigin(response?.source.name)
            setMediasFinded(response.medias)
        }
    }

    useEffect(() => {
        loadInformationsByScope()
    }, [scope.selfRescueOrigin, scope.selfRescueGroup])

    useEffect(() => {
        loadMedias(idOrigin)
    }, [idOrigin])

    return (
        <div className={clsx(styles.view, className)}>
            <h2 className={styles.customHeaderFont}>{texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_TITLE}</h2>

            <div className={clsx(styles.adjustItemsAtStart, className)}>
                <FormControlLabel
                    control={<Checkbox checked={scope.active} onChange={scope.onSelfRescue} color="secondary" />}
                    label={texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CHECK_MESSAGE}
                />

                <FormControl sx={{ minWidth: '100%' }}>
                    <InputLabel id="type_selfrescue">Selecione o tipo de Auto-resgate</InputLabel>
                    <Select
                        labelId="type_selfrescue"
                        id="typeselfrescue"
                        value={scope.selfRescueType}
                        label="Selecione o tipo de Auto-resgate"
                        onChange={(e) => scope.onSelfRescueTyped(String(e.target.value))}
                        sx={{ width: '100%' }}
                    >
                        {types.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {scope.selfRescueType == 'ROBOT_RESCUE' ? (
                <div className={styles.content}>
                    <div className={styles.customContentSelfRescueResumeDiv}>
                        <h2>{texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE}</h2>
                        <div className={styles.customContentSelfRescueResumeContent}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_COMPLET}
                        </div>
                        <div className={styles.customContentSelfRescueResumeContent}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME}
                        </div>
                        <div className={styles.customContentSelfRescueResumeContent}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_NEGATIVE_ANSWER_RESUME}
                        </div>
                    </div>

                    <div className={styles.adjustItemCenter}>
                        <span className={styles.spaceBetweenMessageStart}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_START}
                        </span>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            className={styles.adjustMinutesField}
                            value={scope.robotRescueMinutes}
                            onChange={scope.onSelfRescueMinutesChanged}
                            error={scope.robotRescueMinutesError}
                        />
                        <span className={styles.spaceBetweenMessageEnd}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END}
                        </span>
                    </div>

                    <div className={styles.viewMessages}>
                        <div className={styles.adjustRichEditor}>
                            <div className={styles.richEditorWidth}>
                                <ViewSlot scope={scope.rescueMessage} />
                            </div>
                        </div>
                        <div className={styles.adjustRichEditor}>
                            <div className={styles.richEditorWidth}>
                                <ViewSlot scope={scope.goodbyeMessage} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.customContentSelfRescueResumeDiv}>
                        <h2>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_POSITIVE_ANSWER_RESUME_TITLE_EVENT}
                        </h2>
                        <div className={styles.customContentSelfRescueResumeContent}>
                            {
                                texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_EXPLICATION_TEXT_TYPE_GENERATE_EVENT
                            }
                        </div>
                    </div>

                    <div className={styles.adjustItemCenter}>
                        <span className={styles.spaceBetweenMessageStart}>
                            {
                                texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END_TYPE_GENERATE_EVENT
                            }
                        </span>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            className={styles.adjustMinutesField}
                            value={scope.robotRescueMinutes}
                            onChange={scope.onSelfRescueMinutesChanged}
                            error={scope.robotRescueMinutesError}
                        />
                        <span className={styles.spaceBetweenMessageEnd}>
                            {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_MINUTES_MESSAGE_END}
                        </span>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <div>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="group_event_selfrescue">
                                    {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_GROUP_AND_TYPE_EVENT}
                                </InputLabel>
                                <Select
                                    labelId="group_event_selfrescue"
                                    id="groupSelfrescue"
                                    value={idGroup}
                                    label={texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_GROUP_AND_TYPE_EVENT}
                                    onChange={(e) => handleSelectEvent(e.target.value)}
                                    sx={{ width: '100%' }}
                                >
                                    {scope.selfRescuemenuEventOptionsForm.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.caption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', marginTop: '2rem' }}>
                            <div>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="origin_selfrescue">
                                        {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_ORIGIN_EVENT}
                                    </InputLabel>
                                    <Select
                                        labelId="origin_selfrescue"
                                        id="originSelfrescue"
                                        value={idOrigin}
                                        label={texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_ORIGIN_EVENT}
                                        onChange={(e) => loadMedias(e.target.value)}
                                        sx={{ width: '100%' }}
                                    >
                                        {scope.selfRescueSourceAndMediaEventOptionsForm.map((type) => (
                                            <MenuItem key={type.source.id} value={type.source.id}>
                                                {type.source.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="midia_selfrescue">
                                        {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_MEDIA_EVENT}
                                    </InputLabel>
                                    <Select
                                        labelId="midia_selfrescue"
                                        id="midiaSelfrescue"
                                        value={scope.selfRescueMidia ? scope.selfRescueMidia : ''}
                                        label={texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_LABEL_MEDIA_EVENT}
                                        onChange={(e) => scope.onSelfRescueMidia(e.target.value)}
                                        sx={{ width: '100%' }}
                                    >
                                        {mediasFinded.map((item) => (
                                            <MenuItem key={item.id} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Box>
                    </div>
                </div>
            )}

            <div className={styles.adjustFooterButtons}>
                <Button color="inherit" onClick={scope.onCancel}>
                    {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_DISCARD_BUTTON}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={scope.onSave}
                    disabled={scope.confirmButtonEnabled}
                >
                    {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CONFIRM_BUTTON}
                </Button>
            </div>
        </div>
    )
}
