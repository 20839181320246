import { action, IPresenterOwner, IUpdateManager, NOOP_VOID, Presenter } from 'wdc-cube'
import { AllItemScope, ItemScope, MultiSelectionScope } from './MultiSelection.scopes'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

export type SelectionItem = {
    code: string
    description: string
    selected: boolean
    inUse?: boolean
}

export type OnChangedListener = (codes: string[]) => void

export class MultiSelectionPresenter extends Presenter<MultiSelectionScope> {
    private readonly allItem = new AllItemScope()

    public onChanged = NOOP_VOID as OnChangedListener

    public constructor(owner: IPresenterOwner, scope?: MultiSelectionScope, updateManager?: IUpdateManager) {
        super(owner, scope ?? new MultiSelectionScope(), updateManager)

        this.allItem.index = 0
        this.allItem.code = texts.ALL_ITEM_CODE_OPTION
        this.allItem.description = texts.ALL_ITEM_DESCRIPTION_OPTION
        this.allItem.subdescription = ''
        this.allItem.selected = true
        this.allItem.update = this.update
        this.allItem.onSelectionToggled = this.onItemSelectionToggled.bind(this, this.allItem)

        this.scope.entries.set(0, this.allItem)

        this.updateManager.hint(ItemScope, this.scope, 10)
    }

    public clear() {
        this.scope.entries.length = 1
    }

    public get codes() {
        const codeArray = [] as string[]

        for (const itemScope of this.scope.entries) {
            if (itemScope.selected) {
                codeArray.push(itemScope.code)
            }
        }

        return codeArray
    }

    public syncItems(items: SelectionItem[]) {
        let i = 1
        for (const itemData of items) {
            const itemScope = this.scope.entries.get(i)
            if (!itemScope) {
                const newItem = new ItemScope()
                newItem.index = i
                newItem.code = itemData.code
                newItem.description = itemData.description
                newItem.selected = itemData.selected
                newItem.inUse = itemData.inUse === true
                newItem.onSelectionToggled = this.onItemSelectionToggled.bind(this, newItem)
                newItem.update = this.update
                this.scope.entries.push(newItem)
            } else if (ItemScope_isNotEquivalent(itemScope, itemData)) {
                itemScope.code = itemData.code
                itemScope.description = itemData.description
                itemScope.selected = itemData.selected
                itemScope.inUse = itemData.inUse === true
            }
            i++
        }
        this.scope.entries.length = i
        this.scope.update()
    }

    @action()
    private onItemSelectionToggled(selectedItemScope: ItemScope) {
        if (this.allItem === selectedItemScope) {
            this.allItem.selected = !this.allItem.selected

            for (const item of this.scope.entries) {
                item.selected = this.allItem.selected
            }
        } else {
            selectedItemScope.selected = !selectedItemScope.selected
        }
    }

    public onBeforeScopeUpdate() {
        const codes = [] as string[]
        const selectedIndexes = [] as number[]
        const selectedDescription = [] as string[]

        for (const itemScope of this.scope.entries) {
            if (itemScope !== this.allItem && itemScope.selected) {
                codes.push(itemScope.code)
                selectedIndexes.push(itemScope.index)
                selectedDescription.push(itemScope.description)
            }
        }

        const totalItemCount = this.scope.entries.length - 1

        this.allItem.selected = selectedIndexes.length === totalItemCount
        this.allItem.subdescription = `${selectedIndexes.length}/${totalItemCount}`

        this.scope.selectedIndexes = selectedIndexes
        if (selectedIndexes.length === 0) {
            this.scope.selectedDescription = texts.NO_OPTION_DESCRIPTION
        } else {
            if (this.allItem.selected) {
                this.scope.selectedDescription = this.allItem.description
            } else {
                this.scope.selectedDescription = selectedDescription.join('; ')
            }
        }

        this.onChanged(codes)
    }
}

function ItemScope_isNotEquivalent(a: ItemScope, b: SelectionItem) {
    return a.code !== b.code || a.description !== b.description || a.selected !== b.selected || a.inUse !== !!b.inUse
}
