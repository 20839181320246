export enum SUPPORTED_LANGUAGES {
    'PT-BR' = 'pt_BR',
    'EN-US' = 'en_US',
    'ES' = 'es',
    'ES-PY' = 'es_PY',
    'ES-PER' = 'es_PE'
}

export type Language = keyof typeof SUPPORTED_LANGUAGES

export type LanguageValue = typeof SUPPORTED_LANGUAGES[keyof typeof SUPPORTED_LANGUAGES]
