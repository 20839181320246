import { makeStyles } from 'tss-react/mui'

export const getOrMakeChannelConfigurationWorkingHoursEditorFormStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        position: 'relative',
        bottom: 10,
        gap: 20,
        padding: '2vh 3vh',
        maxHeight: 850,
        overflow: 'hidden'
    },
    adjustWeekDaysContent: {
        height: 850,
        maxHeight: '77%',
        paddingBottom: 40,
        marginTop: -18,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    adjustButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        left: -20,
        right: 30,
        bottom: 0,
        overflow: 'hidden',
        paddingTop: 10,
        paddingLeft: 30,
        width: '95.7%',
        backgroundColor: '#fff',
        zIndex: 1
    },
    adjustItemCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    adjustItemsAtStart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        paddingTop: 8
    },
    periodPane: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10
    },
    spaceBetweenInputTime: {
        paddingLeft: 20,
        paddingRight: 20
    },
    adjustEndInputTime: {
        paddingLeft: 15,
        paddingRight: 8
    },
    errorMsg: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
        marginLeft: 10,
        marginTop: 5
    },
    adjustRemoveButton: {
        marginLeft: 5
    },
    adjustAddButton: {
        marginLeft: 18
    }
}))
