import { action, Presenter } from 'wdc-cube'
import { RichEditorScope } from '../ChannelConfigurationForm.scopes'
import { EditorStateUtils, HtmlUtils } from 'src/utils'
import { editorStateToWhatsapp, whatsappToEditorState } from 'src/utils/draft-js'
import { lodash } from '@syonet/lang'
import { ContentBlock, EditorState, Modifier } from 'draft-js'
import { OrderedSet } from 'immutable'
import { TextsProvider } from '../texts'
import { ChannelConfigurationSelfRescueFormPresenter } from '../selfRescue/ChannelConfigurationSelfRescueForm.presenter'
import { ChannelConfigurationFormMessagesPresenter } from '../ChannelConfigurationForm.presenter-messages'

const markBodyStyle = 'BOLD'
const markEdgeStyle = 'color-rgb(26,188,156)'
const TAG_EXP = /\{\{[*,_,~,`]*[A-z\u00C0-\u00ff]+[*,_,~,`]*}\}/g

// @Inject
const texts = TextsProvider.get()

type OwnerType = ChannelConfigurationFormMessagesPresenter | ChannelConfigurationSelfRescueFormPresenter

export class MessageEditorPresenter extends Presenter<RichEditorScope> {
    private readonly tag: string

    private readonly decoratedTag: string

    private readonly userTag: string

    private readonly userDecoratedTag: string

    private modified = true

    private __hasError = false

    constructor(owner: OwnerType, scope: RichEditorScope, tag: string, userTag: string) {
        super(owner, scope, owner.updateManager)
        this.tag = tag
        this.decoratedTag = tag ? `{{${tag}}}` : ''

        this.userTag = userTag
        this.userDecoratedTag = userTag ? `{{${userTag}}}` : ''
    }

    public override get owner() {
        return super.owner as OwnerType
    }

    get hasError() {
        if (this.modified) {
            this.validate(true)
        }
        return this.__hasError
    }

    getText(): string {
        let text = editorStateToWhatsapp(this.scope.text)
        if (this.tag) {
            text = lodash.replace(text, TAG_EXP, (match) => {
                if (match.includes(this.userTag)) {
                    return this.decoratedTag
                } else {
                    return match
                }
            })
        }
        return text
    }

    bindListeners(caption: string) {
        this.scope.caption = caption
        this.scope.onChanged = this.onTextChanged.bind(this)
        this.scope.onTagClicked = this.onTagClicked.bind(this)
    }

    public async synchronizeState(markupText: string) {
        if (this.tag) {
            markupText = lodash.replace(markupText, TAG_EXP, (match) => {
                if (match.includes(this.tag)) {
                    return this.userDecoratedTag
                } else {
                    return match
                }
            })
        }

        let editorState = whatsappToEditorState(markupText)

        if (this.decoratedTag) {
            editorState = this.highlightMacro(editorState, true)
        }

        this.scope.text = editorState
        this.validate(true)

        this.modified = true
    }

    @action()
    private onTextChanged(editorState: EditorState) {
        if (this.tag) {
            this.scope.text = this.highlightMacro(editorState, false)
        } else {
            this.scope.text = editorState
        }

        this.modified = true
    }

    @action()
    private onTagClicked() {
        if (!this.tag) {
            return
        }

        let editorState = this.scope.text
        let contentState = editorState.getCurrentContent()

        let selection = editorState.getSelection()
        contentState = Modifier.removeRange(contentState, selection, 'forward')
        editorState = EditorState.push(editorState, contentState, 'delete-character')
        selection = editorState.getSelection()

        contentState = Modifier.insertText(contentState, selection, this.userDecoratedTag, EditorStateUtils.NoStyle)
        editorState = EditorState.push(editorState, contentState, 'insert-characters')
        selection = editorState.getSelection()

        this.scope.status = null
        this.scope.focus = true

        editorState = this.highlightMacro(editorState, false)
        editorState = EditorState.forceSelection(editorState, selection)
        this.scope.text = editorState
    }

    private updateMessageStatus(editorState: EditorState) {
        this.__hasError = false

        const text = (editorState.getCurrentContent().getPlainText() ?? '').trim()
        if (HtmlUtils.isEmpty(text)) {
            this.scope.status = {
                text: texts.CHANNEL_CONFIGURATION_MESSAGES_MISSING_CONTENT_MESSAGE
            }
            this.__hasError = true
        } else if (this.tag) {
            if (text.includes(this.userDecoratedTag)) {
                this.scope.status = null
            } else {
                this.scope.status = {
                    text: texts.CHANNEL_CONFIGURATION_MESSAGES_INSERT_NEEDED_VARIABLE,
                    tags: [this.userDecoratedTag]
                }
                this.__hasError = true
            }
        } else {
            this.scope.status = null
        }
    }

    private highlightMacro(editorState: EditorState, all: boolean) {
        const previousSelection = editorState.getSelection()

        const undoStack = editorState.getUndoStack()
        const redoStack = editorState.getRedoStack()
        const decorator = editorState.getDecorator()

        const changed = [false]

        const tagStyles = OrderedSet.of<string>(markEdgeStyle, markBodyStyle)
        const nonValidEdgeStyles = OrderedSet.of<string>(markBodyStyle, 'ITALIC')
        const nonValidBodyTagStyles = OrderedSet.of<string>(markEdgeStyle, 'ITALIC')

        let blocks: ContentBlock[]
        if (all) {
            blocks = editorState.getCurrentContent().getBlocksAsArray()
        } else {
            const previousSelection = editorState.getSelection()
            blocks = []
            const editionBlock = editorState.getCurrentContent().getBlockForKey(previousSelection.getStartKey())
            if (editionBlock) {
                blocks.push(editionBlock)
            }
        }

        for (let block of blocks) {
            const text = block.getText()

            // Format valid tags
            let i = 0
            do {
                const rangeStartIndex = text.indexOf(this.userDecoratedTag, i)
                if (rangeStartIndex !== -1) {
                    const rangeEndIndex = rangeStartIndex + this.userDecoratedTag.length
                    const tagBodyIndex = rangeStartIndex + 2
                    const tabBodyEndIndex = rangeEndIndex - 2

                    editorState = EditorStateUtils.applyStyleIfNeeded(
                        editorState,
                        block,
                        markEdgeStyle,
                        rangeStartIndex,
                        tagBodyIndex,
                        changed
                    )
                    editorState = EditorStateUtils.removeStyleIfNeeded(
                        editorState,
                        block,
                        nonValidEdgeStyles,
                        rangeStartIndex,
                        tagBodyIndex,
                        changed
                    )

                    editorState = EditorStateUtils.applyStyleIfNeeded(
                        editorState,
                        block,
                        markBodyStyle,
                        tagBodyIndex,
                        tabBodyEndIndex,
                        changed
                    )
                    editorState = EditorStateUtils.removeStyleIfNeeded(
                        editorState,
                        block,
                        nonValidBodyTagStyles,
                        tagBodyIndex,
                        tabBodyEndIndex,
                        changed
                    )

                    editorState = EditorStateUtils.applyStyleIfNeeded(
                        editorState,
                        block,
                        markEdgeStyle,
                        tabBodyEndIndex,
                        rangeEndIndex,
                        changed
                    )
                    editorState = EditorStateUtils.removeStyleIfNeeded(
                        editorState,
                        block,
                        nonValidEdgeStyles,
                        tabBodyEndIndex,
                        rangeEndIndex,
                        changed
                    )
                    i = rangeEndIndex
                } else {
                    break
                }
            } while (i < text.length)

            // Unformat invalid tags, when detectable
            i = 0
            while (i < text.length) {
                let ch = text.charAt(i)
                if (ch === '{') {
                    const tagStartIdx = i
                    let tagEndIdx = -1

                    i++
                    if (i < text.length) {
                        ch = text.charAt(i)
                        if (ch === '{') {
                            i++
                        }
                    }

                    while (i < text.length) {
                        ch = text.charAt(i)
                        if (ch === '{') {
                            tagEndIdx = Math.max(i - 1, 0)
                            break
                        } else if (ch === '}') {
                            tagEndIdx = Math.min(i + 2, text.length)
                            break
                        } else if (ch === '\n') {
                            break
                        }
                        i++
                    }

                    if (tagEndIdx !== -1 && tagEndIdx > tagStartIdx) {
                        const possibleDecoratedTag = text.substring(tagStartIdx, tagEndIdx)
                        if (possibleDecoratedTag !== this.userDecoratedTag) {
                            editorState = EditorStateUtils.removeStyleIfNeeded(
                                editorState,
                                block,
                                tagStyles,
                                tagStartIdx,
                                tagEndIdx,
                                changed
                            )
                        }
                    }
                } else {
                    i++
                }
            }

            block = editorState.getCurrentContent().getBlockForKey(block.getKey())
            block.findStyleRanges(
                // Filter
                (characters) => {
                    return characters.hasStyle(markEdgeStyle)
                },
                // Callback
                (start, end) => {
                    let tagStartIdx = text.indexOf('{{', start)
                    let tagEndIdx = -1
                    if (tagStartIdx !== -1) {
                        tagEndIdx = Math.min(tagStartIdx + this.userDecoratedTag.length, text.length)
                    } else {
                        const p = text.indexOf('}}', start)
                        if (p !== -1) {
                            tagEndIdx = p + 2
                            tagStartIdx = Math.max(tagEndIdx - this.userDecoratedTag.length, 0)
                        } else {
                            editorState = EditorStateUtils.removeInlineStyle(
                                editorState,
                                block,
                                start,
                                end,
                                markEdgeStyle
                            )
                            changed[0] = true
                        }
                    }

                    if (tagStartIdx !== -1 && tagEndIdx !== -1) {
                        const possibleTag = text.substring(tagStartIdx, tagEndIdx)
                        if (possibleTag !== this.userDecoratedTag) {
                            editorState = EditorStateUtils.removeInlineStyle(
                                editorState,
                                block,
                                start,
                                end,
                                markEdgeStyle
                            )
                            changed[0] = true
                        } else {
                            if (tagStartIdx > start) {
                                while ('}' === text.charAt(start)) {
                                    start++
                                }

                                editorState = EditorStateUtils.removeInlineStyle(
                                    editorState,
                                    block,
                                    start,
                                    tagStartIdx,
                                    markEdgeStyle
                                )
                                changed[0] = true
                            }

                            if (tagEndIdx < end) {
                                editorState = EditorStateUtils.removeInlineStyle(
                                    editorState,
                                    block,
                                    tagEndIdx,
                                    end,
                                    markEdgeStyle
                                )
                                changed[0] = true
                            }
                        }
                    } else {
                        editorState = EditorStateUtils.removeInlineStyle(editorState, block, start, end, markEdgeStyle)
                        changed[0] = true
                    }
                }
            )
        }

        if (changed[0]) {
            editorState = EditorState.create({
                currentContent: editorState.getCurrentContent(),
                undoStack,
                redoStack,
                decorator,
                selection: previousSelection
            })
        }

        return editorState
    }

    public validate(force = false) {
        if (force || this.modified) {
            this.updateMessageStatus(this.scope.text)
            this.modified = false
        }
    }

    public override onBeforeScopeUpdate() {
        this.validate()
    }
}
