import { Observable, observe, Scope } from 'wdc-cube'

@Observable
export class TimePickerScope extends Scope {
    @observe() label?: string
    @observe() value: Date | null = null
    @observe() format?: string
    @observe() error = ''

    @observe() onChange = Scope.SYNC_ACTION_TWO<Date | null, string | undefined>()
}
