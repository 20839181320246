import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InboxIcon from '@mui/icons-material/Inbox'
import ListAltIcon from '@mui/icons-material/ListAlt'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Autocomplete,
    Button,
    Chip,
    Collapse,
    Divider,
    IconButton,
    List, ListItemButton,
    ListItemText, Menu,
    MenuItem,
    PopoverOrigin,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import { AdminChannelConfiguration as NS } from '@whatsapp/communication'
import clsx from 'clsx'
import React from 'react'
import { v4 as uuid } from 'uuid'
import voca from 'voca'
import { IViewProps, bindUpdate } from 'wdc-cube-react'
import { ChannelConfigurationMenuSegmentationEditorFormScope } from '../../menuSegmentation/ChannelConfigurationMenuSegmentationEditorForm.scopes'
import { TextsProvider } from '../../texts'
import {
    getOrMakeListHeaderStyles,
    getOrMakeNoMenuOptionSelectedStyles,
    getOrMakeNoSegmentedCompanyStyles,
    getOrMakeChannelConfigurationMenuSegmentationEditorForm as getOrMakeStyles
} from './ChannelConfigurationMenuSegmentationEditorForm.styles'

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

const texts = TextsProvider.get()

type ChannelMenuEntry = NS.ChannelMenuEntry
type CompanyChannelConfig = NS.CompanyChannelConfig
const ChannelMenuType = NS.ChannelMenuType

export type ChannelConfigurationMenuSegmentationEditorFormViewProps = IViewProps & {
    scope: ChannelConfigurationMenuSegmentationEditorFormScope
}

export function ChannelConfigurationMenuSegmentationEditorFormView({
    scope
}: ChannelConfigurationMenuSegmentationEditorFormViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={styles.view}>
            <Typography component="div" variant="h6" color="text" className={styles.adjustTitle}>
                {voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_TITLE, scope.menuId)}
            </Typography>
            {scope.newMenuAlert
                ? <Alert severity="warning" className={styles.adjustSpacingBetweenAlertAndContent}>
                    {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ALERT_WARNING_DURING_MENU_CREATION}
                </Alert>
                : <></>
            }
            <div className={clsx(styles.adjustSection, styles.adjustSectionContent)}>
                <div className={styles.adjustHeaderAndMenuOptions}>
                    <div className={styles.header}>
                        <ChannelConfigurationMenuSegmentationEditorFormSubHeaderMenuOptionView />
                    </div>
                    <div className={styles.adjustMenuOptions}>
                        <List component="div" className={styles.adjustSpaceBetweenItems}>
                            {scope.wholeMenu.map((item) =>
                                item.menu.map((menu: ChannelMenuEntry) => {
                                    return <CustomizedListItem key={menu.id} menu={menu} scope={scope} />
                                })
                            )}
                        </List>
                    </div>
                </div>
                <div className={styles.adjustMenuSegmentations}>
                    <div className={styles.adjustMenuSegmentationHeader}>
                        <Typography component="div" variant="body1">
                            {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SECOND_COLUMN_HEADER}
                        </Typography>
                        <Tooltip
                            title={
                                !scope.menuSelected
                                    ? texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_TOOLTIP_TITLE
                                    : ''
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    id={`segmentation-button-${uuid()}`}
                                    aria-controls={scope.openCompanies ? `menu-${uuid()}` : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={scope.openCompanies ? 'true' : undefined}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    onClick={scope.onOpenCompanyToSegment}
                                    disabled={!scope.menuSelected}
                                >
                                    {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NEW_SEGMENTATION_BUTTON_TITLE}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                    <Divider />
                    <div className={styles.adjustSegmentationContent}>
                        <Menu
                            id={`menu-${uuid()}`}
                            anchorEl={scope.anchorEl}
                            anchorOrigin={anchorOrigin}
                            transformOrigin={transformOrigin}
                            open={scope.openCompanies}
                            onClose={scope.onCloseCompanyItems}
                            MenuListProps={{ 'aria-labelledby': `segmentation-button-${uuid()}` }}
                            className={styles.customizeMenu}
                        >
                            {scope.companyOptions.map((company) => {
                                return (
                                    <MenuItem
                                        key={company.companyId}
                                        onClick={() => scope.onAddNewCompany(company)}
                                        disabled={scope.companyToSegment.filter((segmentedCompany) => segmentedCompany.companyId && segmentedCompany.companyId === company.companyId).length > 0}
                                        disableRipple
                                    >
                                        {company.internal?.name || ''}
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                        <div>
                            {scope.menuSelected ? (
                                <>
                                    <div className={styles.adjustSelectedMenuSpacing}>
                                        <Typography variant="body2" color="text.secondary">
                                            {voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SELECTED_MENU, scope.menuSelected.description)}
                                            <CustomizedSelectedMenuView entry={scope.menuSelected} isMenuSelected />
                                        </Typography>
                                    </div>
                                    {scope.companyToSegment.map((company) => {
                                        return <div key={company.companyId} className={styles.adjustCompanySegmentation}>
                                            <SegmentedCompaniesView scope={scope} selectedCompany={company} />
                                        </div>
                                    })}
                                </>
                            ) : (
                                <NoMenuOptionSelectedView />
                            )}
                            {scope.menuSelected && scope.companyToSegment.length === 0 ? <NoSegmentedCompanyView /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.adjustFooter}>
                <Button variant="contained" color="secondary" onClick={scope.onSave}>
                    {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CONFIRM_BUTTON_TITLE}
                </Button>
            </div>
        </div>
    )
}

type CustomizedListItemProps = IViewProps & {
    scope: ChannelConfigurationMenuSegmentationEditorFormScope
    menu: ChannelMenuEntry
}

function CustomizedListItem({ scope, menu }: CustomizedListItemProps) {

    const { classes: styles } = getOrMakeStyles()

    return (
        <div>
            <ListItemButton
                key={menu.id}
                onClick={() => scope.onSelectMenu(menu)}
                className={
                    scope.menuSelected === menu
                        ? styles.changeBackgroundColorOnSelectMenuOrSubmenu
                        : styles.changeBackgroundColorListOnHoverAndFocus
                }
            >
                <ListItemText
                    primary={menu.description}
                    secondary={<CustomizedSelectedMenuView entry={menu} />}
                    primaryTypographyProps={{ className: styles.adjustText }}
                />
                {menu.subServices.length > 0 ? (
                    <IconButton size="small" onClick={(evt) => scope.onToggleSubMenu(evt)}>
                        {scope.openSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                ) : (
                    <></>
                )}
            </ListItemButton>
            {scope.wholeMenu.map(
                (value) =>
                    value.submenu &&
                    value.submenu.map((submenu: ChannelMenuEntry) => {
                        return (
                            <div key={submenu.id}>
                                {menu.subServices.includes(submenu.id) ? (
                                    <Collapse key={submenu.id} in={scope.openSubmenu} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton
                                                onClick={() => scope.onSelectMenu(submenu)}
                                                className={
                                                    scope.menuSelected === submenu
                                                        ? styles.changeBackgroundColorOnSelectMenuOrSubmenu
                                                        : styles.changeBackgroundColorListOnHoverAndFocus
                                                }
                                            >
                                                <ListItemText
                                                    primary={submenu.description}
                                                    secondary={<CustomizedSelectedMenuView entry={submenu} />}
                                                    className={styles.adjustTextSpacing}
                                                    primaryTypographyProps={{ className: styles.adjustText }}
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )
                    })
            )}
        </div>
    )
}

type CustomizedSelectedMenuViewProps = IViewProps & {
    entry: ChannelMenuEntry
    isMenuSelected?: boolean
}

function CustomizedSelectedMenuView({ entry, isMenuSelected }: CustomizedSelectedMenuViewProps) {
    let menuType = ''

    switch (entry.type) {
        case ChannelMenuType.EVENT:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_EVENT
            break
        case ChannelMenuType.LINK:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_LINK
            break
        case ChannelMenuType.ROBOT:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_ROBOT
            break
        case ChannelMenuType.SUB_MENU:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_SUB_MENU
            break
        case ChannelMenuType.INFORMATION:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_INFORMATION
            break
        default:
            menuType = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_TYPE_UNKNOWN
    }

    return (
        <span>
            {isMenuSelected
                ? <span>
                    {' '}
                    (<b>{menuType}</b>)
                </span>
                : <span>{menuType}</span>
            }
        </span>
    )
}

type SegmentedCompaniesViewProps = IViewProps & {
    scope: ChannelConfigurationMenuSegmentationEditorFormScope
    selectedCompany: CompanyChannelConfig
}

function SegmentedCompaniesView({ scope, selectedCompany }: SegmentedCompaniesViewProps) {

    const { classes: styles } = getOrMakeStyles()

    return (
        <Accordion elevation={0} defaultExpanded disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${uuid()}`}
                id={`panel-header-${uuid()}`}
            >
                <Typography>{selectedCompany.internal?.name || ''}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Autocomplete
                    multiple
                    size="small"
                    id={`tags-${uuid()}`}
                    options={selectedCompany.segments}
                    value={selectedCompany.serviceBySegmentation}
                    onChange={(event: unknown, newValue: string[]) =>
                        scope.onChangeSegmentations(selectedCompany, newValue)
                    }
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <span key={index}>
                                <Chip size="small" color="secondary" label={option} {...getTagProps({ index })} />
                            </span>
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_LABEL}
                            placeholder={texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_AUTOCOMPLETE_PLACEHOLDER}
                        />
                    )}
                />
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    className={styles.adjustRemoveSegmentationButton}
                    onClick={() => scope.onRemoveSegmentedCompany(selectedCompany)}
                >
                    {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_DELETE_SEGMENTATION_BUTTON_TITLE}
                </Button>
            </AccordionDetails>
        </Accordion>
    )
}

function ChannelConfigurationMenuSegmentationEditorFormSubHeaderMenuOptionView() {
    const { classes: styles } = getOrMakeListHeaderStyles()

    return (
        <>
            <div className={styles.adjustHeader}>
                <Typography variant="body1">
                    {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_FIRST_COLUMN_HEADER}
                </Typography>
            </div>
            <Divider />
        </>
    )
}

function NoSegmentedCompanyView() {
    const { classes: styles } = getOrMakeNoSegmentedCompanyStyles()

    return (
        <div className={styles.adjustContent}>
            <InboxIcon color="disabled" className={styles.adjustIcon} />
            <Typography component="div" variant="body1" color="text.disabled">
                {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_NO_SEGMENTED_COMPANY}
            </Typography>
        </div>
    )
}

function NoMenuOptionSelectedView() {
    const { classes: styles } = getOrMakeNoMenuOptionSelectedStyles()

    return (
        <div className={styles.adjustContent}>
            <ListAltIcon color="disabled" className={styles.adjustIcon} />
            <Typography component="div" variant="body1" color="text.disabled">
                {texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_MENU_OPTION_NOT_SELECTED}
            </Typography>
        </div>
    )
}
