import { ViewFactory } from 'wdc-cube-react'
import { AlertMessageContentScope, AlertScope, MainScope, MenuScope, SimpleAlertMessageContentScope, SimpleAlertScope } from '../Main.scopes'
import { AlertView } from './Alert.view'
import { AlertMessageContentView } from './AlertMessageContent'
import { MainView } from './Main.view'
import { MenuView } from './Menu.view'
import { SimpleAlertView } from './SimpleAlert.view'
import { SimpleAlertMessageContentView } from './SimpleAlertMessageContent'

export { MainView } from './Main.view'

export function registerViews(rv = ViewFactory.register) {
    rv(MainScope, MainView)
    rv(MenuScope, MenuView)
    rv(AlertScope, AlertView)
    rv(AlertMessageContentScope, AlertMessageContentView)
    rv(SimpleAlertScope, SimpleAlertView)
    rv(SimpleAlertMessageContentScope, SimpleAlertMessageContentView)
}
