import InfoIcon from '@mui/icons-material/InfoOutlined'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { lodash } from '@syonet/lang'
import clsx from 'clsx'
import React from 'react'
import voca from 'voca'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { CustomerServiceEntryScope, DashboardCustomerServiceTableScope } from '../DashboardCustomerService.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeCustomerServiceTableStyles } from './Dashboard.styles'

// @Inject
const texts = TextsProvider.get()

type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

type DashboardCustomerServiceTableProps = IViewProps & {
    scope: DashboardCustomerServiceTableScope
}

export function DashboardCustomerServiceTableView({ className, scope }: DashboardCustomerServiceTableProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeCustomerServiceTableStyles()

    return (
        <Paper className={clsx(styles.root, className)}>
            {scope.loading ? <LinearProgress className={styles.customLinearProgress} color="secondary" /> : undefined}
            <span className={styles.tableContainer}>
                <div className={styles.adjustTableHead}>
                    <span className={styles.tableContent}>
                        <FormGroup className={styles.adjustFormGroupContent}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={scope.showAll}
                                        onChange={scope.onToggleVisualization}
                                        color="success"
                                    />
                                }
                                label={
                                    <Typography className={styles.caption} variant="button">
                                        <TableLabelView scope={scope} />
                                        <Tooltip
                                            title={
                                                <Typography variant="caption">{texts.SERVICE_TABLE_INFO}</Typography>
                                            }
                                            className={styles.adjustAttendancesTooltip}
                                        >
                                            <InfoIcon className={styles.moreInfo} onClick={scope.onMoreInfo} />
                                        </Tooltip>
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </span>
                    <div className={styles.adjustFilterIcon}>
                        <ViewSlot scope={scope.filterForm} />
                    </div>
                </div>
                {scope.filterChips.entries.length > 0 ? (
                    <div className={styles.adjustChipsPosition}>
                        <ViewSlot scope={scope.filterChips} />
                    </div>
                ) : (
                    <></>
                )}
            </span>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" className={styles.table}>
                    <TableHead className={styles.tableHeader}>
                        <MessageHeader />
                    </TableHead>
                    <TableBody>
                        {scope.entries.map((row, index) => (
                            <MessageRow key={index} row={row} index={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={scope.itemsPerPageOptions}
                component="div"
                count={scope.itemCount}
                rowsPerPage={scope.itemsPerPage}
                page={scope.pageIndex}
                onPageChange={scope.onChangePageIndex as unknown as PageChangeEventHandler}
                onRowsPerPageChange={scope.onChangeItemsPerPage}
                labelRowsPerPage={texts.SERVICE_TABLE_ROWS_PER_PAGE}
            />
        </Paper>
    )
}

// :: Component(TableLabelView)
export type TableLabelViewProps = IViewProps & {
    scope: DashboardCustomerServiceTableScope
}

function TableLabelView({ scope }: TableLabelViewProps) {
    if (scope.showAll) {
        return <span>{voca.sprintf(texts.SERVICE_TABLE_ALL_ATTENDANCES_TITLE, scope.itemCount)}</span>
    } else {
        return <span>{voca.sprintf(texts.SERVICE_TABLE_TITLE, scope.itemCount)}</span>
    }
}

// :: Component(MessageHeader)

function MessageHeader() {
    return (
        <TableRow>
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_CLIENT} minWidth={120} />
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_COMPANY} minWidth={100} />
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_DEPARTMENT} minWidth={100} />
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_USER} minWidth={100} />
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_EVENT} minWidth={100} />
            <MessageCellHeader label={texts.UNREAD_MESSAGES_COLUMN_UNREAD_SINCE} minWidth={70} />
        </TableRow>
    )
}

// :: Component(MessageRow)

type MessageRowProps = {
    row: CustomerServiceEntryScope
    index: number
}

function MessageRow({ index, row }: MessageRowProps) {
    bindUpdate(React, row)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight])

    React.useEffect(() => {
        const updateSize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', updateSize)

        return () => {
            window.removeEventListener('resize', updateSize)
        }
    }, [])

    const ticketId = row.id ? row.id : undefined

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            <AttendenceCell
                content={row.customerName}
                icon={<QuestionAnswerIcon />}
                iconHint={texts.SERVICE_TABLE_GO_TO_CHAT}
                onIconClick={row.onCustomerClick}
            />
            <AttendenceCell content={row.companyName} />
            <AttendenceCell content={row.departamentName} />
            <AttendenceCell content={row.userName} />
            {(lodash.isEmpty(ticketId) || !row.showTicketButton) && <AttendenceCell content={ticketId} />}
            {row.showTicketButton && !lodash.isEmpty(ticketId) && (
                <AttendenceCell
                    content={ticketId}
                    icon={<LocalOfferIcon />}
                    iconHint={texts.SERVICE_TABLE_GO_TO_TICKET}
                    onIconClick={row.onTicketClick}
                />
            )}
            <AttendenceCell content={row.unreadSince} />
        </TableRow>
    )
}

// :: Component(MessageCellHeader)

type MessageCellHeaderProps = TableCellProps & {
    label: string
    minWidth: number
}

function MessageCellHeader({ label, minWidth, ...props }: MessageCellHeaderProps) {
    return (
        <TableCell style={{ minWidth }} {...props}>
            {label}
        </TableCell>
    )
}

// :: Component(MessageCell)

type AtendenceCellProps = TableCellProps & {
    uri?: string
    content?: string | null
    icon?: React.ReactNode
    iconHint?: string
    onIconClick?: () => void
}

function AttendenceCell({ uri, content, icon, iconHint, onIconClick, ...props }: AtendenceCellProps) {
    const { classes: styles } = getOrMakeCustomerServiceTableStyles()

    const spanRef = React.useMemo(() => ({ current: null as HTMLSpanElement | null }), [])
    const previous = React.useMemo(() => ({ hint: undefined as string | undefined }), [])

    const [updateCount, setUpdateCount] = React.useState(0)
    const handleSpanRef = React.useCallback((instance: HTMLSpanElement | null) => {
        spanRef.current = instance
        const newHint = computeHint(instance, content)
        if (newHint !== previous.hint) {
            previous.hint = newHint
            setUpdateCount(updateCount + 1)
        }
    }, [])

    const hint = computeHint(spanRef.current, content)
    previous.hint = hint

    let iconEl: JSX.Element | undefined
    if (icon) {
        iconEl = <IconButton onClick={onIconClick}>{icon}</IconButton>
        if (iconHint) {
            iconEl = <Tooltip title={iconHint}>{iconEl}</Tooltip>
        }
    }

    return (
        <TableCell {...props}>
            <div className={styles.cellContent}>
                {!lodash.isNil(uri) && (
                    <Link color="inherit">
                        <span ref={handleSpanRef} className={styles.cellContentSpan} title={hint}>
                            {content}
                        </span>
                    </Link>
                )}
                {lodash.isNil(uri) && (
                    <span ref={handleSpanRef} className={styles.cellContentSpan} title={hint}>
                        {content}
                    </span>
                )}
                {iconEl}
            </div>
        </TableCell>
    )
}

function computeHint(el: HTMLSpanElement | null, content: string | null | undefined) {
    if (el && el.scrollWidth > el.offsetWidth) {
        return content ?? undefined
    }
    return undefined
}
