import { ChipItemScope } from 'src/components/chippanel'
import { WaitingScope } from 'src/components/waiting'
import { DashboardKeys, DetachedScopeSlot } from 'src/Constants'
import { CubePresenter, FlipIntent, NOOP_VOID, ScopeSlot } from 'wdc-cube'
import { MainPresenter } from '../main'
import { DashboardScope } from './Dashboard.scopes'
import { DashboardService } from './Dashboard.service'
import { DashboardActiveMessagesResumedPresenter } from './DashboardActiveMessagesResumed.presenter'
import { DashboardCustomerServicePresenter } from './DashboardCustomerService.presenter'
import { DashboardFilterPresenter, DashboardFilterState } from './DashboardFilter.presenter'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

export class DashboardPresenter extends CubePresenter<MainPresenter, DashboardScope> {
    private released = false

    private parentSlot = NOOP_VOID as ScopeSlot

    private filterSlot = NOOP_VOID as DetachedScopeSlot

    private loggedUserId = ''

    public readonly filter = new DashboardFilterPresenter(this)

    private readonly customerServicePresenter = new DashboardCustomerServicePresenter(this)

    private readonly activeMessagesResumedPresenter = new DashboardActiveMessagesResumedPresenter(this)

    constructor(app: MainPresenter) {
        super(app, new DashboardScope())
    }

    public override release() {
        this.released = true
        this.filterSlot(this.filter.scope, true)

        this.filter.release()
        this.activeMessagesResumedPresenter.release()
        this.customerServicePresenter.release()

        super.release()
    }

    // Cube context syncronization methods: Begin

    public override async applyParameters(
        intent: FlipIntent,
        initialization: boolean,
        last: boolean
    ): Promise<boolean> {
        const keys = this.filter.fix(new DashboardKeys(intent))

        if (initialization) {
            await this.initializeState(keys)
            this.parentSlot(this.scope)
        } else {
            this.parentSlot(this.scope)
            await this.synchronizeState(keys)
        }

        if (!last) {
            this.propagateContext(keys)
        }

        this.filterSlot(this.filter.scope)

        return true
    }

    public override publishParameters(intent: FlipIntent): void {
        const keys = new DashboardKeys(intent)
        this.filter.publishParameters(keys)
        this.customerServicePresenter.publishParameters(keys)
        this.activeMessagesResumedPresenter.publishParameters(keys)
    }

    private propagateContext(keys: DashboardKeys) {
        this.filter.propagateContext(keys)
        this.customerServicePresenter.propagateContext(keys)
        this.activeMessagesResumedPresenter.propagateContext(keys)
    }

    // Cube context syncronization methods: End

    private async initializeState(keys: DashboardKeys) {
        this.scope.filterChips.update = this.update
        this.filter.onSearch = this.onSearch.bind(this)
        this.updateManager.hint(ChipItemScope, this.scope.filterChips, 5)

        this.parentSlot = keys.parentSlot()
        this.filterSlot = keys.filterSlot()

        const waiting = new WaitingScope()
        waiting.text = texts.LOADING_CONTENT_DESCRIPTION
        waiting.update = this.update
        this.parentSlot(waiting)

        await this.loadChannels()

        keys.refresh(true)
        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: DashboardKeys, force = false) {
        let changed = keys.refresh() ?? force

        const newLoggedUserId = keys.loggedUserId() ?? this.loggedUserId
        changed = changed || this.loggedUserId !== newLoggedUserId

        changed = (await this.filter.synchronizeState(keys, force)) || changed
        changed = (await this.customerServicePresenter.synchronizeState(keys, force)) || changed
        changed = (await this.activeMessagesResumedPresenter.synchronizeState(keys, force)) || changed

        if (changed) {
            this.loggedUserId = newLoggedUserId
            await this.load(this.filter.cloneState())
        }
    }

    private async load(mainFilterArgs: DashboardFilterState) {
        this.activeMessagesResumedPresenter.scope.table.loading = true
        this.customerServicePresenter.scope.table.loading = true
        try {
            this.activeMessagesResumedPresenter.scope.table.update()
            this.customerServicePresenter.scope.table.update()

            const customerServiceFilterArgs = this.customerServicePresenter.getFilterArguments()

            const response = await dashboardService.fetchContent({
                channels: mainFilterArgs.channels,
                startTime: mainFilterArgs.startTime,
                endTime: mainFilterArgs.endTime,
                noCache: mainFilterArgs.noCache,

                customerServices: this.customerServicePresenter.prepareRequest(
                    {
                        channels: mainFilterArgs.channels,
                        startDateTime: mainFilterArgs.startTime,
                        endDateTime: mainFilterArgs.endTime
                    },
                    customerServiceFilterArgs
                ),

                activeMessages: this.activeMessagesResumedPresenter.prepareRequest({
                    channels: mainFilterArgs.channels,
                    startDateTime: mainFilterArgs.startTime,
                    endDateTime: mainFilterArgs.endTime
                })
            })

            if (this.released) {
                return
            }

            this.filter.applyData(mainFilterArgs)
            await this.customerServicePresenter.applyData(response.customerServices, customerServiceFilterArgs, true)
            await this.activeMessagesResumedPresenter.applyData(response.activeMessagesResumed)

            this.filter.save()
        } finally {
            this.customerServicePresenter.scope.table.loading = false
            this.activeMessagesResumedPresenter.scope.table.loading = false
            this.update()
            this.updateHistory()
        }
    }

    private async loadChannels() {
        const channelMap = await this.app.loadChannels()
        this.filter.applyCredentialsData(channelMap)
    }

    protected async onSearch(args: DashboardFilterState) {
        await this.load(args)
    }

    public override onBeforeScopeUpdate() {
        this.filter.syncChips(this.scope.filterChips)
    }
}
