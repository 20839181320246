export function convertToPhoneMask(phoneNumber: string) {
    const ddi = phoneNumber.substr(0, 2)
    const size = phoneNumber.length
    switch (ddi) {
        case '55':
            return size === 13
                ? phoneNumber.replace(/^([0-9]{2})([0-9]{2})([0-9]{5})([0-9]{4})$/, '+$1 ($2) $3-$4')
                : size === 12
                ? phoneNumber.replace(/^([0-9]{2})([0-9]{2})([0-9]{4})([0-9]{4})$/, '+$1 ($2) $3-$4')
                : phoneNumber
        default:
            return phoneNumber
    }
}
