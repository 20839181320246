export interface EurekaServiceDTO {
    name: string
    version: string
    websocket: boolean
    internalIp?: string
    internalPort?: string
    connectionUrl?: string
}

export const NEW_PREFIX = 'NEW_'
