import { NumberOptionType, SimpleStringValueAutoCompleteScope } from 'src/components/autocompletefield'
import { DateTimePickerScope } from 'src/components/datetimepicker'
import { MultiListingScope } from 'src/components/multilist'
import { BaseEvent, KeyPressEvent, TextChangeEvent } from 'src/utils'
import { Observable, ObservableArray, Scope, observe } from 'wdc-cube'

export type { NumberOptionType, SimpleStringValueAutoCompleteScope, KeyPressEvent, TextChangeEvent }

@Observable
export class ChatsFilterScope extends Scope {
    @observe() isCustomerNameChanging = false
    readonly customerName = new SimpleStringValueAutoCompleteScope()
    @observe() onCustomerNameInputChanged = Scope.SYNC_ACTION_TWO<BaseEvent, string>()

    @observe() isCustomerPhoneChanging = false
    readonly customerPhone = new SimpleStringValueAutoCompleteScope()
    @observe() onCustomerPhoneInputChanged = Scope.SYNC_ACTION_TWO<BaseEvent, string>()

    @observe() ticketStatusValue = 0
    readonly ticketStatusOptions = new ObservableArray<string>(this)

    readonly channelsSelector = new MultiListingScope()
    readonly startTime = new DateTimePickerScope()
    readonly endTime = new DateTimePickerScope()

    @observe() opened = false
    @observe() valid = false
    @observe() filtering = false

    @observe() onOpen = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
    @observe() onClear = Scope.ASYNC_ACTION
    @observe() onApply = Scope.ASYNC_ACTION
    @observe() onTicketStatusValueUpdate = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onApplyFilterByPressedKey = Scope.SYNC_ACTION_ONE<KeyPressEvent>()
}
