import { makeStyles } from 'tss-react/mui'

export const getOrMakeWhatsAppStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    syoWhatsApp: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    loadingText: {
        marginTop: 15
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(254, 242, 241)',
        height: '100%'
    },
    errorText: {
        color: 'rgb(99, 51, 48)',
        marginTop: 12
    }
}))
