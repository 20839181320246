import { alpha, Theme } from '@mui/material'
import { green } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

export const getOrMakeChannelConfigurationMenuSegmentationEditorForm = makeStyles()((theme: Theme) => ({
    view: {
        width: 928,
        height: 'auto',
        padding: '16px 16px 0px 16px'
    },
    adjustTitle: {
        marginBottom: 16
    },
    adjustSection: {
        height: 481.5,
        border: `1px solid ${theme.palette.action.hover}`,
        borderRadius: 4
    },
    adjustSectionContent: {
        display: 'flex'
    },
    adjustFooter: {
        display: 'flex',
        justifyContent: 'end',
        gap: 16,
        marginTop: 16,
        marginBottom: 16
    },
    spaceBetweenIconAndCheckbox: {
        marginRight: 8
    },
    header: {
        borderRight: `1px solid ${theme.palette.action.hover}`
    },
    adjustHeaderAndMenuOptions: {
        display: 'flex',
        flexDirection: 'column'
    },
    adjustMenuOptions: {
        width: 375,
        maxHeight: 481.5,
        overflow: 'auto',
        borderRight: `1px solid ${theme.palette.action.hover}`
    },
    adjustSpaceBetweenItems: {
        padding: 0
    },
    changeBackgroundColorOnSelectMenuOrSubmenu: {
        backgroundColor: `${green[50]} !important`
    },
    changeBackgroundColorListOnHoverAndFocus: {
        '&:hover, &:focus': {
            backgroundColor: `${green[50]} !important`
        }
    },
    adjustText: {
        maxWidth: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    adjustTextSpacing: {
        paddingLeft: 16
    },
    adjustMenuSegmentations: {
        width: '100%',
        maxHeight: 481.5
    },
    adjustMenuSegmentationHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '9px 16px',
        height: 41
    },
    adjustSegmentationContent: {
        maxHeight: 421,
        overflow: 'auto',
        borderLeft: `1px solid ${theme.palette.action.hover}`,
        marginLeft: -1,
        minHeight: 421
    },
    customizeMenu: {
        '& .MuiPaper-root': {
            minWidth: 400,
            marginLeft: 212,
            maxHeight: 481.5,
            overflow: 'auto',

            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    )
                }
            }
        }
    },
    adjustSelectedMenuSpacing: {
        paddingTop: 17,
        paddingLeft: 17
    },
    adjustCompanySegmentation: {
        margin: 16,
        border: `1px solid ${theme.palette.action.hover}`,
        borderRadius: 4
    },
    adjustRemoveSegmentationButton: {
        marginTop: 16
    },
    adjustSpacingBetweenAlertAndContent: {
        marginBottom: 8
    }
}))

export const getOrMakeListHeaderStyles = makeStyles()(() => ({
    adjustHeader: {
        margin: 10,
        height: 34,
        paddingTop: 5
    }
}))

export const getOrMakeNoSegmentedCompanyStyles = makeStyles()(() => ({
    adjustContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 382
    },
    adjustIcon: {
        fontSize: 64,
        marginBottom: 16
    }
}))

export const getOrMakeNoMenuOptionSelectedStyles = makeStyles()(() => ({
    adjustContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 387
    },
    adjustIcon: {
        fontSize: 64,
        marginBottom: 16
    }
}))