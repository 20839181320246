import { ChannelId } from '../../common'

export type Company = {
    group: string
    segment?: boolean
    companyId: number
    partnerId: string
    phone: string
    cnpj?: string
    name?: string
    email?: string
    reference?: string
    segmentations?: string[]
    eventGroupsWorked?: string[]
    expiryTime?: number
    editedAt?: Date
    createdAt?: Date
    code?: number
}

export type variable = {
    id: string
    name: string
}

export type CompanyGroupType = {
    companyId: number
    groups: Group[]
}

export type Group = {
    group: string
    types: Type[]
}

export type Type = {
    typeId: string
    typeDescription: string
}

export type OptionsQuickReply = {
    channels: ChannelId[]
    companies: Pick<Company, 'companyId' | 'name' | 'phone'>[]
    variables: variable[]
    groups: string[]
}

const path = 'adminV2/options'
export const OPTIONS_QUICK_REPLY_URL = `${path}/quick-reply`
export const OPTIONS_CHANNELS_URL = `${path}/channels`
