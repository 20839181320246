import { Autocomplete, Chip, TextField } from '@mui/material'
import React from 'react'
import { bindUpdate } from 'wdc-cube-react'
import { MultipleStringValuesAutoCompleteScope } from '../AutoCompleteField.scopes'

type MultipleAutoCompleteStringFieldProps = {
    scope: MultipleStringValuesAutoCompleteScope
    label: string
    placeholder: string
    size?: "small" | "medium"
    color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
    onKeyUp?: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

export function MultipleAutoCompleteStringFieldView({
    scope,
    label,
    size,
    color,
    placeholder,
    onKeyUp
}: MultipleAutoCompleteStringFieldProps) {
    bindUpdate(React, scope)

    const error = scope.value === undefined && scope.required || scope.value?.length === 0 && scope.required

    return (
        <Autocomplete
            multiple
            size={size || "small"}
            options={scope.options.map((option) => option)}
            getOptionLabel={(option) => option.name}
            value={scope.value ?? []}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={scope.onChange}
            onInputChange={scope.onInputChange}
            onFocus={scope.onFocus}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <div key={option.id}>
                        <Chip
                            color={color || "default"}
                            label={option.name}
                            {...getTagProps({ index })}
                        />
                    </div>
                ))}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder={placeholder} error={error} />
            )}
            onKeyUp={onKeyUp}
        />
    )
}
