import { ChipPanelScope } from 'src/components/chippanel'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import type { ValidationType } from './ChannelConfiguration.service'

export type { ValidationType }

@Observable
export class ChannelEntryScope extends Scope {
    @observe() id = ''
    @observe() url = ''
    @observe() name = ''
    @observe() draft = false
    @observe() phone = ''
    @observe() publishedStatus = ''
    @observe() warningType: ValidationType = 'info'

    @observe() onEdit = Scope.ASYNC_ACTION
    @observe() onClickAlert = Scope.ASYNC_ACTION
}

@Observable
export class ChannelTableScope extends Scope {
    readonly entries = new ObservableArray<ChannelEntryScope>(this)
    @observe() loading = false
}

@Observable
export class ChannelConfigurationListingScope extends Scope {
    readonly filterChipPanel = new ChipPanelScope()
    readonly channelTable = new ChannelTableScope()
}
