import { AdminChannelConfiguration as NS } from '@whatsapp/communication'
import lodash from 'lodash'
import { ChannelConfigurationMenuSegmentationEditorFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import voca from 'voca'
import { CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID, action } from 'wdc-cube'
import type { MouseEvent } from 'wdc-cube/lib/events-react-compatible'
import { AlertHolder, MainPresenter } from '../../main'
import { SimpleAlertMessageContentScope } from '../../main/Main.scopes'
import { ChannelMenuItem } from '../ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from '../ChannelConfigurationForm.presenter'
import { Tabs } from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { ChannelConfigurationMenuSegmentationEditorFormScope } from './ChannelConfigurationMenuSegmentationEditorForm.scopes'

// @Inject
const texts = TextsProvider.get()

type ChannelMenuEntry = NS.ChannelMenuEntry
type CompanyChannelConfig = NS.CompanyChannelConfig

export class ChannelConfigurationMenuSegmentationEditorFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationMenuSegmentationEditorFormScope>
    implements IPresenterOwner
{
    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private __formPresenter?: ChannelConfigurationFormPresenter

    private channelId = ''

    private menuId = -1

    private disposed = false

    private alertHolder = new AlertHolder(this.app)

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationMenuSegmentationEditorFormScope())
    }

    public override release() {
        this.disposed = true
        this.modalSlot(this.scope, true)
        this.alertHolder.release()
        super.release()
    }

    public get formPresenter() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.__formPresenter!
    }

    // @Override
    public async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChannelConfigurationMenuSegmentationEditorFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    // @Override
    public publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationMenuSegmentationEditorFormKeys(intent)
        keys.channelId(this.channelId)

        if (this.menuId > 0) {
            keys.menuId(this.menuId)
        }
    }

    private async initializeState(keys: ChannelConfigurationMenuSegmentationEditorFormKeys) {
        this.bindListeners()
        this.modalSlot = keys.modalSlot()
        this.__formPresenter = keys.hostPresenter() as ChannelConfigurationFormPresenter
        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChannelConfigurationMenuSegmentationEditorFormKeys, force = false) {
        let changed = force || (keys.refresh() ?? false)

        const newChannelId = keys.channelId() ?? this.channelId
        changed = changed || newChannelId !== this.channelId

        const newMenuId = keys.menuId() ?? this.menuId
        changed = changed || newMenuId !== this.menuId

        if (changed) {
            if (!newChannelId) {
                throw new Error(texts.CHANNEL_CONFIGURATION_MENU_EDITOR_FORM_CHANNEL_ID_ERROR)
            }

            this.channelId = newChannelId
            this.menuId = newMenuId

            await this.refresh()
        }
    }

    private bindListeners() {
        this.scope.onChangeSegmentations = this.onChangeSegmentations.bind(this)

        this.scope.onOpenCompanyToSegment = this.onOpenCompanyToSegment.bind(this)
        this.scope.onCloseCompanyItems = this.onCloseCompanyItems.bind(this)
        this.scope.onSelectMenu = this.onSelectMenu.bind(this)
        this.scope.onToggleSubMenu = this.onToggleSubMenu.bind(this)
        this.scope.onAddNewCompany = this.onAddNewCompany.bind(this)
        this.scope.onRemoveSegmentedCompany = this.onRemoveSegmentedCompany.bind(this)

        this.scope.onCancel = this.onCancel.bind(this)
        this.scope.onSave = this.onSave.bind(this)
    }

    private async refresh() {
        const form = this.formPresenter.menuPresenter.getForm(this.menuId)
        this.scope.menuId = this.menuId = form.id
        this.synchronizeCompanyAndMenuEntries()
    }

    private async synchronizeCompanyAndMenuEntries() {
        const menuMap = new Map(this.__formPresenter?.getMenuMap())
        const menuUpdatedForm = this.formPresenter.menuPresenter.getForm(this.menuId)
        const companyMap = new Map(this.__formPresenter?.getSelectedCompanyMap())

        this.scope.companyToSegment = []
        this.scope.menuSelected = undefined

        let oldMenus: ChannelMenuItem[] = []
        menuMap.forEach((menus) => (oldMenus = menus.items))
        oldMenus.forEach((oldMenu) => {
            if (menuUpdatedForm.id === oldMenu.id) {
                if (oldMenu.entries.length !== menuUpdatedForm.entries.length) {
                    this.scope.newMenuAlert = true
                }
            }
        })

        const menuOptions: ChannelMenuEntry[] = []
        const subMenuOptions: ChannelMenuEntry[] = []
        if (menuUpdatedForm.id === this.menuId) {
            menuUpdatedForm.entries.forEach((entry) => {
                if (!oldMenus.includes(menuUpdatedForm) && entry.id.includes('NEW')) {
                    this.scope.newMenuAlert = true
                }

                if (!entry.id.includes('NEW')) {
                    if (!entry.isSubService) {
                        menuOptions.push(entry)
                    } else {
                        subMenuOptions.push(entry)
                    }
                }
            })
        }

        for (const [companyIdx, company] of companyMap) {
            const companyUpdatedForm = this.formPresenter.generalPresenter.getCompanyConfig(companyIdx)

            if (menuUpdatedForm.companies.includes(companyIdx)) {
                if (companyUpdatedForm) {
                    const updatedSegmentations: string[] = []
                    company.internal.segmentations = []
                    for (const segmentations of companyUpdatedForm.segmentation.entries) {
                        company.internal.segmentations?.push(segmentations.description)

                        if (!company.internal.segmentations) {
                            company.internal.name = company.name
                            company.internal.segmentations = []
                        }
                        if (!company.internal.segmentations?.includes(segmentations.description)) {
                            updatedSegmentations.push(segmentations.description)
                        }
                    }

                    if (companyUpdatedForm.segmentation.entries.length > 0) {
                        this.scope.companyOptions.set(companyIdx, {
                            companyId: company.id,
                            internal: {
                                ...company.internal,
                                name: company.name,
                                generalConfig: {
                                    servicesBySegmentation: companyUpdatedForm.internalCompany.serviceBySegmentation
                                }
                            },
                            segments: company.internal.segmentations
                                ? company.internal.segmentations.concat(updatedSegmentations)
                                : [],
                            serviceBySegmentation: []
                        })
                    }
                } else {
                    this.scope.companyOptions.set(companyIdx, {
                        companyId: company.id,
                        internal: {
                            ...company.internal,
                            name: company.name
                        },
                        segments: company.internal.segmentations ? company.internal.segmentations : [],
                        serviceBySegmentation: []
                    })
                }
            }
        }

        this.scope.wholeMenu = [{ menu: menuOptions, submenu: subMenuOptions }]
    }

    @action()
    private async onOpenCompanyToSegment(evt: MouseEvent) {
        this.scope.anchorEl = evt.currentTarget
        this.scope.openCompanies = Boolean(this.scope.anchorEl)
    }

    @action()
    private async onCloseCompanyItems() {
        this.scope.openCompanies = false
    }

    @action()
    private async onSelectMenu(menuSelected: ChannelMenuEntry) {
        this.scope.menuSelected = menuSelected
        const menuUpdatedForm = this.formPresenter.menuPresenter.getForm(this.menuId)

        menuUpdatedForm.entries.forEach((entry) => {
            entry.serviceInfo?.forEach((info) => {
                if (menuSelected._id?.includes(info.menuId)) {
                    this.scope.serviceInfo = entry.serviceInfo
                }
            })
        })

        const companyOptions: CompanyChannelConfig[] = []
        for (const company of this.scope.companyOptions) {
            if (company && menuUpdatedForm.companies.includes(company.companyId)) {
                companyOptions.push(company)
            }
        }

        const resultCompany: CompanyChannelConfig[] = []
        companyOptions.forEach((companyValue) => {
            const generalConfig = companyValue.internal?.generalConfig
            generalConfig?.servicesBySegmentation?.forEach((segmentations) => {
                segmentations.servicesId.forEach((servicesId) => {
                    this.scope.serviceInfo?.forEach((info) => {
                        if (info.menuId === servicesId) {
                            resultCompany.push({
                                segments: companyValue.segments,
                                serviceBySegmentation: [segmentations.on],
                                companyId: companyValue.companyId,
                                internal: {
                                    name: companyValue.internal?.name,
                                    generalConfig: {
                                        servicesBySegmentation: [
                                            {
                                                on: segmentations.on,
                                                servicesId: segmentations.servicesId
                                            }
                                        ]
                                    }
                                }
                            })
                        }
                    })
                })
            })
        })

        const output: CompanyChannelConfig[] = []
        resultCompany.forEach((item) => {
            const existing = output.filter((value) => {
                return value.companyId === item.companyId
            })
            if (existing.length) {
                const existingIndex = output.indexOf(existing[0])
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const entry = output[existingIndex].internal!.generalConfig!.servicesBySegmentation!.concat(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    item.internal!.generalConfig!.servicesBySegmentation!
                )
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                output[existingIndex].internal!.generalConfig!.servicesBySegmentation = entry
            } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item.internal!.generalConfig!.servicesBySegmentation =
                    item.internal?.generalConfig?.servicesBySegmentation
                output.push(item)
            }
        })

        output.forEach((outputValue) => {
            outputValue.internal?.generalConfig?.servicesBySegmentation?.forEach((segmentation) => {
                this.scope.serviceInfo?.forEach((info) => {
                    if (
                        segmentation.servicesId.includes(info.menuId) &&
                        outputValue.segments.includes(segmentation.on)
                    ) {
                        outputValue.serviceBySegmentation.push(segmentation.on)
                    }
                })
                outputValue.serviceBySegmentation = [...new Set(outputValue.serviceBySegmentation)]
            })
        })

        this.scope.companyToSegment = output
    }

    @action()
    private async onToggleSubMenu(evt: MouseEvent) {
        evt.stopPropagation()
        this.scope.openSubmenu = !this.scope.openSubmenu
    }

    @action()
    private async onAddNewCompany(selectedCompany: CompanyChannelConfig) {
        const companyObj = new Set()
        this.scope.companyToSegment.filter((company) => {
            if (companyObj.has(company)) {
                return false
            }
            companyObj.add(company)
            return true
        })

        selectedCompany.serviceBySegmentation = []

        if (this.scope.menuSelected && companyObj.has(selectedCompany)) {
            this.alertHolder.alert(
                'error',
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_TITLE,
                voca.sprintf(
                    texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_ADDING_COMPANY_CONTENT,
                    selectedCompany.internal?.name
                )
            )
            return
        }

        if (!this.scope.menuSelected) {
            this.alertHolder.alert(
                'error',
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_TITLE,
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_ERROR_DURING_SEGMENTATION_CONTENT
            )
        }

        if (this.scope.menuSelected && this.scope.anchorEl) {
            this.onCloseCompanyItems()
            this.scope.companyToSegment.unshift(selectedCompany)
        }
    }

    @action()
    private async onRemoveSegmentedCompany(selectedCompany: CompanyChannelConfig) {
        this.scope.serviceInfo?.forEach((info) => {
            selectedCompany.internal?.generalConfig?.servicesBySegmentation?.forEach((service) => {
                if (selectedCompany.companyId === info.companyId && service.servicesId.includes(info.menuId)) {
                    const serviceIndex = service.servicesId.indexOf(info.menuId)
                    if (serviceIndex > -1) {
                        service.servicesId.splice(serviceIndex, 1)
                    }
                }
            })
        })

        const companyIndex = this.scope.companyToSegment.indexOf(selectedCompany)
        this.scope.companyToSegment.splice(companyIndex, 1)
    }

    @action()
    private async onChangeSegmentations(selectedCompany: CompanyChannelConfig, segmentations: string[]) {
        selectedCompany.serviceBySegmentation = segmentations.map((option) => option)

        let menuId: string | undefined
        this.scope.serviceInfo?.forEach((info) => {
            if (info.companyId === selectedCompany.companyId) {
                menuId = info.menuId
            }
        })

        if (this.scope.menuSelected) {
            this.scope.menuSelected._id = menuId
        }

        for (const form of this.extractForm()) {
            for (const company of this.scope.companyOptions) {
                if (company && form.companyId === company.companyId) {
                    if (
                        company.internal &&
                        company.internal.generalConfig &&
                        company.internal.generalConfig.servicesBySegmentation
                    ) {
                        if (form?.internal?.generalConfig?.servicesBySegmentation) {
                            const confSeg = new Map(
                                company.internal.generalConfig.servicesBySegmentation.map((cs) => [cs.on, cs])
                            )
                            const forSeg = new Map(
                                (form.internal?.generalConfig?.servicesBySegmentation || []).map((fs) => [fs.on, fs])
                            )

                            for (const [on, newService] of forSeg) {
                                const cs = confSeg.get(on)
                                if (cs) {
                                    cs.servicesId = Array.from(new Set([...cs.servicesId, ...newService.servicesId]))
                                } else {
                                    confSeg.set(on, newService)
                                }
                            }
                            for (const [on, cs] of confSeg) {
                                if (cs.servicesId.length === 0) {
                                    confSeg.delete(on)
                                } else {
                                    if (!form.serviceBySegmentation.includes(on)) {
                                        const removeService = cs.servicesId.findIndex((value) => value == menuId)
                                        if (removeService != -1) {
                                            cs.servicesId.splice(removeService, 1)
                                        }
                                    }
                                }
                            }
                            company.internal.generalConfig.servicesBySegmentation = Array.from(confSeg.values())
                            form.internal.generalConfig.servicesBySegmentation = Array.from(confSeg.values())
                        } else {
                            company.internal.generalConfig.servicesBySegmentation =
                                form.internal?.generalConfig?.servicesBySegmentation
                        }
                    }
                }
            }
            this.formPresenter.generalPresenter.saveSegmentationByMenuForm(form)
        }
    }

    @action()
    private async onCancel() {
        await this.close()
    }

    @action()
    private async onSave() {
        if (this.scope.menuSelected && this.scope.menuSelected.isSubService) {
            const confirmScope = new SimpleAlertMessageContentScope()
            confirmScope.message = texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU
            confirmScope.cancelLabelOption =
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CANCEL_OPTION
            confirmScope.confirmLabelOption =
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_WARNING_DURING_SEGMENT_SUB_MENU_CONFIRM_OPTION
            confirmScope.unfocusConfirmButton = true

            confirmScope.onConfirm = async () => {
                for (const form of this.extractSubForm()) {
                    this.formPresenter.generalPresenter.saveSegmentationByMenuForm(form)
                }
                this.alertHolder.closeAlert()
                await this.close()
            }

            confirmScope.onCancel = async () => {
                for (const form of this.extractForm()) {
                    this.formPresenter.generalPresenter.saveSegmentationByMenuForm(form)
                }
                this.alertHolder.closeAlert()
                await this.close()
            }

            confirmScope.update = this.update

            this.alertHolder.simpleAlert(
                texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_SIMPLE_ALERT_TITLE,
                confirmScope,
                confirmScope.onCancel
            )
        } else {
            for (const form of this.extractForm()) {
                this.formPresenter.generalPresenter.saveSegmentationByMenuForm(form)
            }
            await this.close()
        }
    }

    private async close() {
        if (!this.disposed) {
            const keys = KeysFactory.channelConfigurationForm(this.app)
            keys.tabIndex(Tabs.MENU)
            await this.flipToIntent(keys.intent)
        }
    }

    private extractSubForm(): CompanyChannelConfig[] {
        const result: CompanyChannelConfig[] = []

        for (const item of this.scope.wholeMenu) {
            item.menu.forEach((menu) => {
                item.submenu?.forEach((submenu) => {
                    if (this.scope.menuSelected) {
                        if (this.scope.menuSelected.id === submenu.id) {
                            for (const company of this.scope.companyToSegment) {
                                if (menu._id && submenu._id && menu.subServices.includes(submenu.id)) {
                                    company.internal?.generalConfig?.servicesBySegmentation?.forEach((service) => {
                                        if (!service.servicesId.includes(menu._id ?? '')) {
                                            service.servicesId.push(menu._id ?? '')
                                        }
                                    })
                                }
                                result.push(company)
                            }
                        }
                    }
                })
            })
        }

        return result
    }

    private extractForm(): CompanyChannelConfig[] {
        const result: CompanyChannelConfig[] = []

        for (const company of this.scope.companyToSegment) {
            const generalConfig = company.internal?.generalConfig
            if (generalConfig && generalConfig.servicesBySegmentation) {
                company.serviceBySegmentation.forEach((segmentation) => {
                    this.scope.serviceInfo?.forEach((info) => {
                        if (info.companyId === company.companyId && info.menuId === this.scope.menuSelected?._id) {
                            generalConfig.servicesBySegmentation?.push({
                                on: segmentation,
                                servicesId: [info.menuId]
                            })
                            generalConfig.servicesBySegmentation?.forEach((service) => {
                                if (!service.servicesId.includes(info.menuId) && service.on == segmentation) {
                                    service.servicesId.push(info.menuId)
                                }

                                if (!company.serviceBySegmentation.includes(service.on)) {
                                    const indexOfSegmentUsedOnlyOneTime =
                                        service.servicesId.indexOf(info.menuId) &&
                                        !company.serviceBySegmentation.includes(service.on)
                                    const indexOfSegmentUsedInAnotherMenu = service.servicesId.indexOf(info.menuId)
                                    if (indexOfSegmentUsedInAnotherMenu === 1) {
                                        service.servicesId.splice(indexOfSegmentUsedInAnotherMenu, 1)
                                    }
                                    if (indexOfSegmentUsedOnlyOneTime === 0) {
                                        service.servicesId.splice(indexOfSegmentUsedOnlyOneTime, 1)
                                    }
                                }
                            })
                        }
                    })
                })

                generalConfig.servicesBySegmentation = lodash.uniqBy(generalConfig.servicesBySegmentation, 'on')
            }
            result.push(company)
        }

        return result
    }
}
