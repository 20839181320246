import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const getOrMakeStyles = makeStyles()((theme) => ({
    filterAllItem: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterAllItemDescription: {
        flexGrow: 1
    },
    inUseItem: {
        color: grey[400]
    },
    filterAllItemSubdescription: {
        alignSelf: 'flex-end',
        color: theme.palette.grey[400]
    },
    selectedValuesDescription: {
        position: 'absolute',
        textOverflow: 'ellipsis',
        left: 10,
        right: 30,
        overflow: 'hidden'
    },
    content: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        overflowY: 'scroll'
    }
}))
