export const TEXTS = {
    templates: {
        types: {
            default: 'Predeterminado',
            interactive: 'Interactivo'
        },
        interactionTypes: {
            schedule: {
                label: 'Prospecto automático de servicios',
                buttons: {
                    yes: 'Sí',
                    no: 'No'
                }
            },
            scheduleConfirmation: {
                label: 'Confirmación automática de programaciones',
                buttons: {
                    yes: 'Sí',
                    no: 'No',
                    reschedule: 'Reprogramar'
                }
            },
            ticketGeneration: {
                label: 'Generación automática de eventos',
                buttons: {
                    yes: 'Sí',
                    no: 'No'
                }
            },
            updateKm: {
                label: 'Actualización automática de kilometraje',
                buttons: {
                    yes: 'Sí',
                    doNotRemember: 'No recuerdo',
                    doNotOwn: 'No lo poseo más'
                }
            },
            userNotification: {
                label: 'Notificación automática de usuarios',
                buttons: {
                    accessTicket: 'Acceder al evento'
                }
            },
            ownerNotification: {
                label: 'Notificación automática de gestores',
                buttons: {
                    yes: 'Sí',
                    no: 'No'
                }
            }
        },
        categories: {
            authentication: { label: 'Autenticación', description: '' },
            marketing: { label: 'Marketing', description: '' },
            utility: { label: 'Utilidad', description: '' }
        },
        supportedLanguages: {
            enUs: 'Inglés (Estados Unidos)',
            es: 'Español',
            ptBr: 'Portugués (Brasil)'
        },
        fields: {
            CONSORTIUM_CONTRACT_NUMBER: 'Número de contrato del consorcio',
            CONSORTIUM_GROUP_ID: 'Consorcio de grupo',
            CONSORTIUM_QUOTA_ID: 'Consorcio de cuotas',
            CONSORTIUM_CS_DUE_DATE: 'Fecha de vencimiento del consorcio',
            CONSORTIUM_CS_BANK_ID: 'Banco del consorcio',
            CONSORTIUM_CS_ASSEMBLY: 'Consorcio de montaje',
            CONSORTIUM_CS_DIGIT: 'Consorcio de dígitos',
            CONSORTIUM_CS_NO_JURIDICO: 'Consorcio en lo legal',
            CONSORTIUM_CS_NUMBER_DAYS: 'Número de días del consorcio',
            CONSORTIUM_CS_PX_ASSEMBLY: 'Consorcio de montaje px',
            TICKET_ID: 'Evento',
            TICKET_USER: 'Usuario accedido',
            TICKET_COMPANY: 'Nombre de la tienda',
            TICKET_COMPANY_ADDRESS: 'Dirección de la tienda',
            TICKET_COMPANY_PHONE: 'Teléfono de la tienda',
            TICKET_TYPE: 'Tipo evento',
            TICKET_DATE: 'Fecha del evento',
            TICKET_HOUR: 'Hora del evento',
            TICKET_CURRENT_USER: 'Usuario actual del evento',
            TICKET_CURRENT_USER_FIRST_NAME: 'Primer nombre del usuario actual del evento',
            TICKET_URL_RESEARCH: 'Enlace (URL de encuesta)',
            TICKET_DATE_VISIT: 'Fecha del visita a la tienda',
            TICKET_HOUR_VISIT: 'Hora del visita a la tienda',
            CUSTOMER_NAME: 'Nombre del cliente',
            CUSTOMER_FULLNAME: 'Nombre completo del cliente',
            VEHICLE_MODEL: 'Modelo de vehículo',
            VEHICLE_DS_PRODUCT: 'Modelo de vehículo (Descripción)',
            VEHICLE_PLATE: 'Matrícula del vehículo',
            VEHICLE_CHASSI: 'Chasis del vehículo',
            VEHICLE_SELLER: 'Vendedor de vehículos',
            SERVICE_VEHICLE_MODEL: 'Modelo de vehículo (O.S.)',
            SERVICE_VEHICLE_PLATE: 'Matrícula del vehículo (O.S.)',
            SERVICE_CONSULTANT: 'Nombre del consultor',
            SERVICE_VEHICLE_CHASSI: 'Chasis del vehículo (O.S.)',
            SERVICE_VEHICLE_DS_MODEL: 'Modelo de vehículo O.S (Descripción)',
            NEGOTIATION_BRAND_INTEREST: 'Marca de vehículo de interés',
            NEGOTIATION_MODEL_INTEREST: 'Modelo de vehículo de interés'
        },
        deleteErrorMessages: {
            noTemplatesToDelete: 'No hay plantillas para eliminar.',
            credentialNotFound: 'Credencial no encontrada para el canal.'
        },
        createErrorMessages: {
            duplicateNameAndLanguage: 'Ya existe una plantilla con el nombre e idioma proporcionados.'
        },
        uploadTemplateImageMessages: {
            uploadError:
                'Hay un problema al subir la imagen a Gupshup al crear la plantilla. Por favor, inténtelo nuevamente en unos minutos.'
        }
    }
}
