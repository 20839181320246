import { Strings } from '@syonet/lang'
import { ChipItemScope } from 'src/components/chippanel'
import { DashboardKeys, KeysFactory } from 'src/Constants'
import { DateFns } from 'src/utils'
import { action, NOOP_VOID, Presenter } from 'wdc-cube'
import { DashboardPresenter } from './Dashboard.presenter'
import {
    ChatTicketStatus,
    ContentCustomerServiceRequest,
    CustomerServiceEntry,
    CustomerServicesResponse,
    CustomerServiceType,
    DashboardService,
    Visualization
} from './Dashboard.service'
import type {
    MouseEvent,
    TextChangeEvent
} from './DashboardCustomerService.scopes'
import {
    CustomerServiceEntryScope,
    DashboardCustomerServiceScope
} from './DashboardCustomerService.scopes'
import {
    DashboardCustomerServiceFilterPresenter,
    DashboardCustomerServiceFilterState
} from './DashboardCustomerServiceFilter.presenter'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

// @Inject
const dashboardService = DashboardService.singleton()

export class DashboardCustomerServicePresenter extends Presenter<DashboardCustomerServiceScope> {
    private initialized = false

    private readonly filter = new DashboardCustomerServiceFilterPresenter(this.owner)

    public constructor(owner: DashboardPresenter) {
        super(owner, owner.scope.customerServices)
    }

    public override release(): void {
        this.filter.release()
        super.release()
    }

    public override get owner() {
        return super.owner as DashboardPresenter
    }

    public publishParameters(keys: DashboardKeys) {
        if (this.scope.table.showAll) {
            keys.customerServicesShowAll(true)
        }
        this.filter.publishParameters(keys)
    }

    public propagateContext(keys: DashboardKeys) {
        this.publishParameters(keys)
    }

    private async initializeState() {
        this.scope.kpiContactQuantity.update = this.update
        this.scope.kpiNewContactQuantity.update = this.update
        this.scope.kpiHumanTicketQuantity.update = this.update
        this.scope.kpiScheduledTiketsQuantity.update = this.update

        this.scope.table.update = this.update
        this.scope.table.onChangePageIndex = this.onPageIndexChanged.bind(this)
        this.scope.table.onChangeItemsPerPage = this.onItemsPerPageChanged.bind(this)
        this.scope.table.onToggleVisualization = this.onToggleVisualization.bind(this)

        this.scope.table.filterChips.update = this.update

        this.filter.onSearch = this.onSearch.bind(this)

        this.updateManager.hint(CustomerServiceEntryScope, this.scope.table, 5)
        this.updateManager.hint(ChipItemScope, this.scope.table.filterChips, 5)
    }

    public async synchronizeState(keys: DashboardKeys, force = false) {
        let changed = false

        if (!this.initialized) {
            this.initializeState()
            this.initialized = true
            force = true
        }

        const newShowAll = keys.customerServicesShowAll() ?? false
        if (newShowAll !== this.scope.table.showAll || force) {
            this.scope.table.showAll = newShowAll
            changed = true
        }

        changed = (await this.filter.synchronizeState(keys, force)) || changed

        return changed
    }

    public getFilterArguments() {
        return this.filter.cloneState()
    }

    public prepareRequest(
        request: Partial<ContentCustomerServiceRequest>,
        filterArgs: DashboardCustomerServiceFilterState
    ) {
        const mainFilter = this.owner.filter.cloneState()

        const contentRequest: ContentCustomerServiceRequest = {
            channels: request.channels ?? mainFilter.channels,
            startDateTime: request.startDateTime ?? mainFilter.startTime,
            endDateTime: request.endDateTime ?? mainFilter.endTime
        }

        if (request.visualization !== undefined) {
            contentRequest.visualization = request.visualization
        } else if (this.scope.table.showAll) {
            contentRequest.visualization = Visualization.ALL
        } else {
            contentRequest.visualization = Visualization.NON_VISUALIZED
        }

        const pageIndex = request.pageIndex ?? this.scope.table.pageIndex
        if (pageIndex) {
            contentRequest.pageIndex = Math.max(pageIndex, 0)
        }

        const itemsPerPage = request.itemsPerPage ?? this.scope.table.itemsPerPage
        if (itemsPerPage) {
            const itemsPerPageOptions = this.scope.table.itemsPerPageOptions
            const minItemsPerPage = itemsPerPageOptions[0]
            const maxItemsPerPage = itemsPerPageOptions[itemsPerPageOptions.length - 1]
            contentRequest.itemsPerPage = Math.min(Math.max(itemsPerPage, minItemsPerPage), maxItemsPerPage)
        }

        if (filterArgs.type != null) {
            contentRequest.type = filterArgs.type
        }

        if (filterArgs.ticketId !== undefined) {
            contentRequest.ticketIds = [filterArgs.ticketId]
        }

        if (filterArgs.customer) {
            contentRequest.customerIds = [filterArgs.customer.id]
        }

        if (filterArgs.users.length > 0) {
            contentRequest.userIds = filterArgs.users.map((item) => item.id)
        }

        if (filterArgs.categories.length > 0) {
            contentRequest.categoryIds = filterArgs.categories.map((item) => item.id)
        }

        if (filterArgs.companies.length > 0) {
            contentRequest.companyIds = filterArgs.companies.map((item) => item.id)
        }

        if (filterArgs.durationInDays > 0) {
            contentRequest.durationInDays = filterArgs.durationInDays
        }

        return contentRequest
    }

    public async applyData(
        response: CustomerServicesResponse,
        filterData: DashboardCustomerServiceFilterState,
        force = false
    ) {
        this.scope.table.loading = false
        this.scope.table.pageIndex = response.meta.currentPage
        this.scope.table.itemsPerPage = response.meta.itemsPerPage
        this.scope.table.itemCount = response.meta.totalItems

        let index = 0
        for (const customerServiceEntry of response.entries) {
            let rowScope: CustomerServiceEntryScope
            if (index < this.scope.table.entries.length) {
                rowScope = this.scope.table.entries.get(index)
                rowScope.update = NOOP_VOID
                fillCustomerServiceEntry(rowScope, this.owner.app.embedded, customerServiceEntry)
                rowScope.update = this.scope.table.update
            } else {
                rowScope = new CustomerServiceEntryScope()
                fillCustomerServiceEntry(rowScope, this.owner.app.embedded, customerServiceEntry)
                rowScope.update = this.scope.table.update
                this.scope.table.entries.push(rowScope)
            }
            rowScope.onCustomerClick = this.onCustomerClicked.bind(this, customerServiceEntry.id)

            rowScope.onTicketClick = this.onTickedClicked.bind(this, customerServiceEntry)
            index++
        }
        this.scope.table.entries.length = index

        const kpi = response.kpi
        if (kpi) {
            this.scope.kpiContactQuantity.value = kpi.contactCount
            this.scope.kpiNewContactQuantity.value = kpi.newContactCount
            this.scope.kpiHumanTicketQuantity.value = kpi.humanTicketCount
            this.scope.kpiScheduledTiketsQuantity.value = kpi.scheduledCount
        } else {
            this.scope.kpiContactQuantity.value = 0
            this.scope.kpiNewContactQuantity.value = 0
            this.scope.kpiHumanTicketQuantity.value = 0
            this.scope.kpiScheduledTiketsQuantity.value = 0
        }

        if (response.filter.visualization !== undefined) {
            this.scope.table.showAll = response.filter.visualization === Visualization.ALL
        }

        if (force) {
            this.filter.clearCaches()
        }
        this.filter.applyData(filterData, response.filter)
        this.filter.syncChips(this.scope.table.filterChips)
    }

    private async refresh(
        request: ContentCustomerServiceRequest,
        filterData: DashboardCustomerServiceFilterState,
        data?: CustomerServicesResponse
    ) {
        this.scope.table.loading = true
        try {
            const response = data || (await dashboardService.fetchCustomerServices(request))
            await this.applyData(response, filterData)
        } finally {
            this.scope.table.loading = false
            this.owner.updateHistory()
        }
    }

    protected async onSearch(args: DashboardCustomerServiceFilterState) {
        const request = this.prepareRequest({}, args)
        await this.refresh(request, args)
    }

    @action()
    protected async onPageIndexChanged(evt: MouseEvent, pageIndex: number) {
        const unreadMessageTable = this.scope.table
        const filterArgs = this.filter.cloneState()

        const request = this.prepareRequest(
            {
                pageIndex,
                itemsPerPage: unreadMessageTable.itemsPerPage
            },
            filterArgs
        )

        await this.refresh(request, filterArgs)

        this.owner.updateHistory()
    }

    @action()
    protected async onItemsPerPageChanged(evt: TextChangeEvent) {
        const serviceTable = this.scope.table

        const filterArgs = this.filter.cloneState()
        const request = this.prepareRequest(
            {
                pageIndex: 0,
                itemsPerPage: evt.target.value ? +evt.target.value : serviceTable.itemsPerPage
            },
            filterArgs
        )

        await this.refresh(request, filterArgs)

        this.owner.updateHistory()
    }

    @action()
    protected async onToggleVisualization() {
        const filterArgs = this.filter.cloneState()
        const request = this.prepareRequest(
            {
                visualization: this.scope.table.showAll ? Visualization.NON_VISUALIZED : Visualization.ALL,
                pageIndex: 0
            },
            filterArgs
        )

        await this.refresh(request, filterArgs)

        this.owner.updateHistory()
    }

    protected async onCustomerClicked(contactId: string) {
        const onwerFilter = this.owner.filter.cloneState()
        const filter = this.filter.cloneState()

        const targetKey = KeysFactory.chats(this.owner.app)
        targetKey.channels(onwerFilter.channels)
        targetKey.startTime(onwerFilter.startTime)
        targetKey.endTime(onwerFilter.endTime)
        if (filter.type == CustomerServiceType.ROBOT) {
            targetKey.ticketStatus(ChatTicketStatus.NO_TICKET)
        } else if (filter.type == CustomerServiceType.HUMAN) {
            targetKey.ticketStatus(ChatTicketStatus.HAS_TICKET)
        }

        targetKey.contactId(contactId)
        this.owner.flipToIntent(targetKey.intent)
    }

    protected async onTickedClicked(serviceEntry: CustomerServiceEntry) {
        const ticketId = serviceEntry.ticket.id
        const channelPhone = serviceEntry.channel.phone
        const customerPhone = serviceEntry.customer.phone

        const partnerUrl = await this.owner.app.getChannelPartnerUrl(channelPhone)
        if (partnerUrl) {
            const url = partnerUrl.replace(/\/api|\/portal/, '')
            const partnerAppUrl = `${url}/portal/cic.do?modulo=CIC2#/evento/${ticketId}/whatsapp/${customerPhone}`
            window.open(partnerAppUrl, '_blank')
        }
    }
}

// :: Static utils

function fillCustomerServiceEntry(
    scope: CustomerServiceEntryScope,
    embedded: boolean,
    entry: CustomerServiceEntry
) {
    const { ticket, customer, company, channel } = entry
    const defaultValue = '---'

    let userName = defaultValue
    if (ticket.user) {
        userName = ticket.user.name || String(ticket.user.id)
        if (ticket.user.robot) {
            userName = texts.CUSTOMER_SERVICE_FILTER_ROBOT
        }
    }

    let customerName = customer.name
    if (customer.phone) {
        customerName += `, ${formatPhoneNumber(customer.phone)}`
    }

    let lastReadAtDesc = defaultValue
    if (entry.readAt) {
        const readAt = DateFns.parseISO(entry.readAt)
        const fmtDate = readAt.toLocaleDateString(texts.LOCALE)
        lastReadAtDesc = `${fmtDate} - ${texts.DURATION.format(readAt)}`
    }

    let departamentName = defaultValue
    if (ticket.category) {
        if (ticket.category.group) {
            departamentName = `${ticket.category.group} / ${ticket.category.name}`
        } else {
            departamentName = ticket.category.name
        }
    }

    let companyName = company.name ?? defaultValue
    if (company.id) {
        companyName = company.name
    } else if (channel.name) {
        companyName = channel.name
    } else {
        companyName = `${Strings.formatPhoneV2(channel.phone)} (${channel.group})`
    }

    scope.id = ticket.user.robot ? '' : String(ticket.id)
    scope.companyName = companyName
    scope.departamentName = departamentName
    scope.customerName = customerName
    scope.unreadSince = lastReadAtDesc
    scope.showTicketButton = embedded
    scope.userName = userName
}

const formatPhoneNumber = (function () {
    const phoneExp = /(\d{2})(\d{2})(\d{5})(\d{3})/
    return function formatPhoneNumber(phone: string) {
        if (phone) {
            return `${phone.replace(phoneExp, '$2 $3-$4')}`
        } else {
            return '---'
        }
    }
})()
