import { Observable, observe, Scope } from 'wdc-cube'

@Observable
export class WhatsAppScope extends Scope {
    @observe() customerName = ''
    @observe() companyId: number | null = null
    @observe() sendHsm = false
    @observe() companyPhone = ''
    @observe() userName = ''
    @observe() room = ''
    @observe() readOnly = false
    @observe() eureka: string | null = null
    @observe() customerPhone = ''
    @observe() templatefields?: string

    @observe() loading = true
    @observe() error = false
}
