import { ChannelConfigurationFormKeys } from 'src/Constants'
import { action, Presenter } from 'wdc-cube'
import { ChannelExtrasConfig } from './ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import type { TextChangeEvent } from './ChannelConfigurationForm.scopes'
import { ChannelConfigurationFormExtrasScope } from './ChannelConfigurationForm.scopes'

export class ChannelConfigurationFormExtrasPresenter extends Presenter<
    ChannelConfigurationFormExtrasScope,
    ChannelConfigurationFormPresenter
> {
    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner, owner.scope.extrasTab, owner.updateManager)
    }

    public focusOnUseGoBackValue() {
        this.owner.scope.tabIndex = this.scope.index
    }

    private bindListeners() {
        this.scope.onChangeGoBackButton = this.onChangeGoBackButton.bind(this)
    }

    async initializeState(keys: ChannelConfigurationFormKeys, extras: ChannelExtrasConfig) {
        this.bindListeners()
        await this.synchronizeState(keys, extras)
    }

    public async synchronizeState(keys: ChannelConfigurationFormKeys, extras: ChannelExtrasConfig) {
        this.scope.useGoBack = extras?.useGoBack ?? false
    }

    @action()
    private onChangeGoBackButton(evt: TextChangeEvent) {
        this.scope.useGoBack = evt.target.value === 'true' ? true : false
    }

    getForm(): ChannelExtrasConfig {
        return { useGoBack: this.scope.useGoBack }
    }

    saveForm(extras: ChannelExtrasConfig) {
        this.scope.useGoBack = extras.useGoBack
        this.update()
    }

    public beforeUpdate() {
        if (this.scope.useGoBack !== undefined) {
            this.owner.validator.extraValidation(true)
        } else {
            this.owner.validator.extraValidation(false)
        }
    }

    public override onBeforeScopeUpdate() {
        this.beforeUpdate()
    }

    public extractFormData(): ChannelExtrasConfig {
        return {
            useGoBack: this.scope.useGoBack
        }
    }
}
