import { keyframes } from '@emotion/react'
import grey from '@mui/material/colors/grey'
import { makeStyles } from 'tss-react/mui'

const POPUP_WIDTH = 386

export const infoIcon = {
    color: grey[400],
    fontSize: 20,
    cursor: 'pointer'
}

const Arial = 'Arial'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const getOrMakeDashboardStyles = makeStyles()((theme) => ({
    view: {
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        marginTop: -16
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    cardInfo: infoIcon,
    card: {
        borderRadius: 8,
        background: '#fff'
    },
    moreInfo: {
        fontSize: 20,
        color: grey[400],
        cursor: 'pointer'
    },
    filter: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        backgroundColor: '#797979',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 13,
        letterSpacing: 0.46,
        cursor: 'pointer',
        borderRadius: 4,

        '&:hover': {
            backgroundColor: '#898989'
        }
    },
    filterPopover: {
        position: 'absolute',
        '& .MuiPaper-root': {
            display: 'flex'
        }
    },
    filterPopoverContent: {
        width: `${POPUP_WIDTH}px`,
        maxHeight: 'calc(100vh - 80px)',
        position: 'fixed',
        animation: `${fadeIn} 0.2s ease-in`,
        top: 50,
        right: 22,
        display: 'flex',
        flexDirection: 'column'
    },
    filterAlignButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        left: 9,
        bottom: 0,
        overflow: 'hidden',
        paddingTop: 10,
        paddingBottom: 4,
        width: '95.7%',
        height: 60,
        backgroundColor: '#fff',
        zIndex: 1
    },
    filterLabelText: {
        color: grey[700],
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: Arial,
        fontSize: 14,
        letterSpacing: 0.15
    },
    filterChannelLabelText: {
        marginTop: 16,
        marginBottom: -15
    },
    spacingIntoPopoverContent: {
        padding: '16px 16px 32px 16px',
        flexShrink: 1,
        alignSelf: 'stretch',
        overflowY: 'scroll'
    },
    adjustGrid: {
        padding: 2
    },
    adjustGridLabelItem: {
        marginBottom: 16
    },
    adjustChannelLabel: {
        marginTop: 20
    },
    adjustGridItem: {
        marginTop: 12
    }
}))

export const getOrMakeResumedActiveMessagesStyles = makeStyles()(() => ({
    view: {
        marginTop: 20
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
            whiteSpace: 'nowrap'
        }
    },
    tableHeader: {
        position: 'relative',
        zIndex: 0
    },
    box: {
        padding: 8
    },
    customLinearProgress: {
        flex: 1,
        minHeight: 5
    },
    customRow: {
        cursor: 'pointer',
        padding: 0
    }
}))

export const getOrMakeDashboardActiveMessagesResumedStyles = makeStyles()(() => ({
    gridContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        flexDirection: 'row',
        boxSizing: 'border-box',
        marginTop: 16
    },
    gridItem: {
        flexDirection: 'row',
        flexBasis: '33.333333%',
        flexGrow: 0,
        margin: 0,
        boxSizing: 'border-box',
        maxWidth: '32.8%'
    }
}))

export const getOrMakeDashboardCustomerActiveMessagesStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        flexGrow: 1,
        padding: 1,
        minWidth: 1200
    },
    body: {
        padding: '20px 10px 0 10px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        flexGrow: 1
    },
    tableContainer: {
        position: 'relative',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 1400,
        '& .MuiTable-root': {
            position: 'absolute',
            left: 0,
            right: 0,
            width: 'unset'
        },
        '& .MuiTableCell-head': {
            top: -10
        }
    },
    table: {
        minWidth: 800,
        '& .MuiTableCell-head': {
            fontWeight: 700,
            whiteSpace: 'nowrap'
        }
    },
    ticketId: {
        whiteSpace: 'nowrap'
    },
    customerName: {
        whiteSpace: 'nowrap'
    },
    customerPhone: {
        whiteSpace: 'nowrap'
    },
    sendDate: {
        whiteSpace: 'nowrap'
    },
    adjustKpiCards: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: 16,
        padding: 8
    },
    kpiCardSize: {
        width: '100%'
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    activeMessageDetailedFormTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        marginLeft: 16,
        flexGrow: 1,
        columnGap: 10
    },
    activeMessageDetailedFormClose: {
        display: 'inherit',
        padding: 8,
        flexGrow: 0
    },
    adjustContentSpacing: {
        padding: 8
    }
}))

export const getOrMakeCustomerServiceTableStyles = makeStyles()(() => ({
    root: {
        width: '100%'
    },
    caption: {
        color: grey[600],
        whiteSpace: 'nowrap'
    },
    table: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
            whiteSpace: 'nowrap'
        }
    },
    tableHeader: {
        position: 'relative',
        zIndex: 0
    },
    inlineIcon: {
        verticalAlign: 'bottom',
        fontSize: '18px !important'
    },
    adjustAttendancesTooltip: {
        position: 'relative',
        top: 6,
        marginLeft: 16.5
    },
    adjustFilterIcon: {
        marginRight: 15
    },
    adjustTableHead: {
        display: 'flex',
        alignItems: 'center',
        marginTop: -16,
        marginBottom: -16
    },
    adjustChipsPosition: {
        margin: '-16px 20px -37px 20px'
    },
    tableContainer: {
        padding: 16
    },
    tableContent: {
        display: 'flex',
        flexGrow: 1,
        padding: 8
    },
    adjustFormGroupContent: {
        marginLeft: 17,
        width: 'fit-content'
    },
    cellContent: {
        display: 'flex',
        alignItems: 'center'
    },
    cellContentSpan: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        contain: 'size',
        height: '2.76ex',
        width: '100%'
    },
    customLinearProgress: {
        flex: 1,
        minHeight: 5
    },
    moreInfo: infoIcon
}))

export const getOrMakeCustomerServiceStyles = makeStyles()(() => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: 'min-content min-content',
        gap: '15px 15px',
        gridTemplateAreas: '"kpi0 kpi1 kpi2 kpi3" "table table table table"',
        minWidth: ''
    },
    kpi0: {
        gridArea: 'kpi0'
    },
    kpi1: {
        gridArea: 'kpi1'
    },
    kpi2: {
        gridArea: 'kpi2'
    },
    kpi3: {
        gridArea: 'kpi3'
    },
    table: {
        gridArea: 'table'
    }
}))

export const getOrMakeCustomerServiceFilterStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        padding: 32
    },
    filter: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 0,

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            transition: '0.5s'
        }
    },
    filterPopover: {
        marginTop: 2,
        maxHeight: 'inherit',
        '& .MuiPopover-paper': {
            overflowX: 'inherit',
            overflowY: 'inherit',
            backgroundColor: '#f3f3f3'
        }
    },
    filterPopoverContent: {
        padding: 16,
        width: 444,
        position: 'absolute',
        right: 0
    },
    filterPopoverContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterPopoverItem: {
        marginBottom: 12,
        width: '100%',
        paddingRight: 5
    },
    filterPopoverButtons: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginRight: 5
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}))
