import { keyframes } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

const Arial = 'Arial'
const Roboto = ['Roboto', 'sans-serif'].join(',')

const blink = keyframes`
    from { opacity: 0 }
    to { opacity: 1 }
`

export const getOrMakeChannelConfigurationFormStyles = makeStyles()((theme) => ({
    view: {
        padding: '10px 32px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        gap: 20
    },
    customHeaderFont: {
        fontFamily: Arial,
        fontWeight: 700,
        fontSize: 22,
        letterSpacing: 0.15,
        marginRight: 6
    },
    customLabelTab: {
        color: grey[700],

        '& .Mui-selected': {
            color: theme.palette.secondary.main
        }
    },
    adjustHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    adjustHeaderTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    adjustButtons: {
        display: 'flex',
        alignItems: 'center'
    },
    adjustList: {
        '& .MuiPaper-root': {
            marginTop: 1,
            width: 144,
            textTransform: 'uppercase',
            color: grey[700],
            border: '1px solid rgba(0, 0, 0, 0.23)'
        }
    },
    customFontColorButton: {
        display: 'flex',
        color: grey[700]
    },
    customDefaultButton: {
        marginRight: '8px!important'
    },
    customLoadingButton: {
        marginRight: '8px!important',
        transition: '0.5s'
    },
    customPrimaryButton: {
        boxShadow: 'none!important'
    },
    draftFont: {
        fontFamily: Roboto,
        fontSize: 20,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    draftContent: {
        textDecorationColor: 'rgba(0, 0, 0, 0.54)!important',
        cursor: 'pointer'
    },
    adjustContent: {
        padding: 24
    }
}))

export const getOrMakeChannelConfigurationFormCompanyStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 0
    },
    card: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        padding: 3,
        backgroundColor: '#fff'
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    customFont: {
        fontFamily: Arial,
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: 0.07,
        color: grey[700]
    },
    segmentationInfo: {
        fontFamily: Arial,
        fontWeight: 400,
        fontSize: 10,
        letterSpacing: 0.15,
        color: grey[500],
        textAlign: 'right',
        marginRight: 10
    },
    adjustCardGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(349px, 1fr))',
        maxWidth: '100%',
        gap: '1rem',
        marginTop: '1rem',
        overflow: 'hidden'
    },
    addCompanyButton: {
        marginTop: 3,
        marginBottom: 20
    },
    companyBand: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        width: '100%'
    },
    companyHeader: {
        fontFamily: Arial,
        fontSize: 18,
        letterSpacing: 0.15
    },
    buttonBlink: {
        marginTop: 3,
        marginLeft: 5,
        animation: `${blink} 1s linear infinite`
    },
    adjustButtonAndIconBlink: {
        display: 'flex'
    },
    adjustActionIconsPosition: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
    },
    adjustSegmentationContent: {
        width: '100%'
    },
    adjustSegmentationPosition: {
        margin: '8px 0'
    },
    adjustSegmentationContainer: {
        maxWidth: 'calc(100% - 16px)'
    }
}))

export const getOrMakeChannelConfigurationFormGeneralStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        justifySelf: 'center',
        flexShrink: 1,
        padding: 10,
        gap: 20
    },
    adjustCardGrid: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    adjustElements: {
        width: '100%',
        display: 'flex'
    },
    adjustChannelNameInputFieldSize: {
        width: '400px',
        marginRight: '15px!important'
    },
    adjustChannelInputFieldSize: {
        width: '220px',
        marginRight: '15px!important'
    },
    adjustTextFields: {
        flexGrow: 1,
        justifySelf: 'start',
        display: 'flex',
        alignItems: 'center'
    },
    adjustButtons: {
        justifySelf: 'end',
        alignSelf: 'center',
        minWidth: '180px'
    }
}))

export const getOrMakeChannelConfigurationFormMenuStyles = makeStyles()(() => ({
    view: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        gap: 20,
        marginTop: 'auto',

        '& .MuiTableCell-root': {
            verticalAlign: 'initial'
        }
    },
    adjustHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    table: {
        '& .MuiTableCell-head': {
            fontFamily: 'Arial',
            fontSize: 16,
            letterSpacing: 0.15,
            fontWeight: 700,
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.54)',
            paddingLeft: 0,
            overflowX: 'auto'
        }
    },
    tableHeader: {
        position: 'relative',
        zIndex: 0
    },
    customFont: {
        fontFamily: Arial,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 0.15,
        color: grey[700]
    },
    chip: {
        borderRadius: 16
    },
    chipContent: {
        fontFamily: Roboto,
        fontSize: '0.8125rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        color: '#fff',
        padding: '7px 8px 6px 10px',
        whiteSpace: 'nowrap'
    },
    chipContentIcon: {
        marginLeft: 5,
        paddingLeft: 6,
        paddingRight: 6,
        borderRadius: 20
    },
    chipInnerSpacing: {
        paddingRight: 4
    },
    listing: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuTypeRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        paddingTop: 8,
        marginBottom: 5
    },
    buttonBlink: {
        height: 36,
        marginLeft: 3,
        animation: `${blink} 1s linear infinite`
    },
    tableCellWithError: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336'
    },
    customFontWithError: {
        color: '#F44336',
        lineHeight: 1.5
    },
    adjustErrorMessage: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
    },
    adjustErrorMessagesWithListLayout: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',

        '& .MuiAlert-message': {
            padding: 0
        }
    },
    adjustSpaceBetweenMenuAndErrorMessages: {
        marginTop: 8,
        marginLeft: 10
    },
    adjustBlinkAndNewMenuButton: {
        display: 'flex',
        alignItems: 'center'
    },
    adjustUlElementWithinAlert: {
        marginBlockStart: 8,
        paddingInlineStart: 25
    },
    companiesCustomFont: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: 0.15,
        color: grey[600],
        marginTop: 8,
        marginLeft: 10
    }
}))

export const ConfigurationFormMessagesInputProps = {
    style: {
        fontFamily: Arial,
        fontWeight: 400,
        fontSize: 14,
        color: '#616161',
        letterSpacing: 0.15
    }
}

export const getOrMakeChannelConfigurationFormMessagesStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        justifySelf: 'center',
        marginTop: -32,
        gap: 20
    },
    messageBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    alignContentBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    caption: {
        fontFamily: Arial,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: '#fff',
        width: 'fit-content',
        border: '1px solid transparent',
        whiteSpace: 'nowrap',
        paddingLeft: 5,
        paddingRight: 5,
        position: 'relative',
        top: -10,
        left: 8
    },
    messageTitle: {
        fontFamily: Arial,
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: 0.15,
        marginTop: 32,
        marginBottom: 12
    },
    messagesLabelText: {
        fontFamily: Arial,
        fontWeight: 700,
        fontSize: 16,
        color: grey[700],
        letterSpacing: 0.15,
        marginBottom: -50
    },
    editorConfiguration: {
        fontFamily: Arial,
        fontWeight: 400,
        fontSize: 14,
        color: grey[700],
        letterSpacing: 0.15,
        lineHeight: 1.5,
        minHeight: 100,
        border: 'transparent',
        paddingLeft: 10
    },
    editorBox: {
        marginBottom: 18,
        border: '1px solid #C4C4C4',
        borderRadius: 3,

        '&:hover': {
            border: '1px solid #000000'
        },

        '&:focus-within': {
            border: '1px solid #1976d2',
            outline: '1px solid #1976d2',

            '& .SyoRichEditor-Label': {
                color: '#1976d2'
            }
        }
    },
    editorInput: {
        marginTop: -15,
        paddingBottom: 14
    },
    toolbarConfiguration: {
        display: 'flex!important',
        justifyContent: 'end!important',
        alignItems: 'center',
        borderTop: '1px solid #C4C4C4!important',
        borderLeft: 'transparent!important',
        borderRight: 'transparent!important',
        borderBottom: 'transparent!important',
        borderRadius: '0px 0px 3px 3px!important',
        marginBottom: '-12px!important'
    },
    toolbarButtons: {
        border: 'transparent!important'
    },
    errorMessageRelativeToToolbar: {
        position: 'relative',
        top: -18,
        left: 10,
        maxWidth: '50%',
        whiteSpace: 'nowrap',
        marginBottom: '-15px!important'
    },
    adjustErrorMessage: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    adjustTagLimit: {
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    adjustRichEditor: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    richEditorWidth: {
        width: '49.43%'
    }
}))

export const getOrMakeChannelConfigurationFormTimesStyles = makeStyles()(() => ({
    view: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        gap: 20,

        '& .MuiTableCell-root': {
            verticalAlign: 'initial'
        }
    },
    adjustHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    table: {
        '& .MuiTableCell-head': {
            fontFamily: 'Arial',
            fontSize: 16,
            letterSpacing: 0.15,
            fontWeight: 700,
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.54)'
        }
    },
    container: {
        maxHeight: 740
    },
    customTableCell: {
        minWidth: 70
    },
    customTableCellFont: {
        fontFamily: Arial,
        fontSize: 14,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    customFont: {
        fontFamily: Arial,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15,
        color: grey[700]
    },
    listing: {
        display: 'flex',
        flexDirection: 'column'
    },
    listingRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column'
    },
    tableCellWithWarning: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800'
    },
    tableCellWithError: {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336'
    },
    buttonBlink: {
        marginLeft: 3,
        animation: `${blink} 1s linear infinite`
    },
    newHourButton: {
        height: 35,
        whiteSpace: 'nowrap'
    },
    adjustSpaceBetweenMenuAndErrorMessages: {
        marginTop: 16
    }
}))

export const getOrMakeChannelConfigurationFormExtrasStyles = makeStyles()(() => ({
    view: {
        width: 613,
        margin: -24
    },
    accordion: {
        '&.MuiAccordion-root::before': {
            display: 'none'
        }
    },
    accordionDetails: {
        marginTop: -16
    },
    accordionContent: {
        marginTop: 16
    },
    radioAccordionContent: {
        marginTop: 16,
        marginLeft: 11
    }
}))