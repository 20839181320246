import { ChannelConfigurationTimesEditorFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { action, CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID, Presenter } from 'wdc-cube'
import { MainPresenter } from '../main'
import { ChannelCompaniesWorkingHours, PeriodTime } from './ChannelConfiguration.service'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import { Tabs } from './ChannelConfigurationForm.scopes'
import type { CheckedChangeEvent } from './ChannelConfigurationForm.scopes'
import {
    ChannelConfigurationWorkingHoursEditorFormScope,
    ChannelConfigurationWorkingHoursEditorFormWeekDayScope,
    PeriodActionType,
    PeriodScope
} from './ChannelConfigurationWorkingHoursEditorForm.scopes'
import { CompanySelectorPresenter } from './CompanySelectorPresenter'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationWorkingHoursEditorFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationWorkingHoursEditorFormScope>
    implements IPresenterOwner
{
    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private channelId = ''

    private timesId = -1

    private __formPresenter?: ChannelConfigurationFormPresenter

    private companySelectorPresenter = new CompanySelectorPresenter(
        this.app,
        this.scope.companiesSelector,
        this.updateManager
    )

    weekPresenterGroup = new WeekPresenterGroup(this)

    private disposing = false

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationWorkingHoursEditorFormScope())
        this.companySelectorPresenter.isCompanyInUse = this.checkIfCompanyIsInUseBySomeMenuEntry.bind(this)
    }

    public override release() {
        this.disposing = true
        this.modalSlot(this.scope, true)
        this.weekPresenterGroup.release()
        this.companySelectorPresenter.release()
        super.release()
    }

    public get formPresenter() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.__formPresenter!
    }

    // Cube context syncronization methods: Begin

    // @Override
    public async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChannelConfigurationTimesEditorFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    // @Override
    public publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationTimesEditorFormKeys(intent)
        keys.channelId(this.channelId)
        if (this.timesId > 0) {
            keys.timesId(this.timesId)
        }
    }

    private async initializeState(keys: ChannelConfigurationTimesEditorFormKeys) {
        this.bindListeners()

        this.modalSlot = keys.modalSlot()

        this.__formPresenter = keys.hostPresenter() as ChannelConfigurationFormPresenter

        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChannelConfigurationTimesEditorFormKeys, force = false) {
        let changed = force || (keys.refresh() ?? false)

        const newChannelId = keys.channelId() ?? this.channelId
        changed = changed || newChannelId !== this.channelId

        const newTimesId = keys.timesId() ?? this.timesId
        changed = changed || newTimesId !== this.timesId

        if (changed) {
            if (!newChannelId) {
                throw new Error(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_CHANNEL_ID_ERROR)
            }

            if (newTimesId === undefined) {
                throw new Error(texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_NEW_TIMES_ID_ERROR)
            }

            this.channelId = newChannelId
            this.timesId = newTimesId

            await this.refresh()
        }
    }

    private bindListeners() {
        this.scope.onCancel = this.onCancel.bind(this)
        this.scope.onSave = this.onSave.bind(this)
        this.updateManager.hint(ChannelConfigurationWorkingHoursEditorFormWeekDayScope, this.scope, 5)
    }

    private async refresh() {
        const form = this.formPresenter?.workingHoursPresenter.getForm(this.timesId)
        if (form) {
            this.companySelectorPresenter.synchronizeState(
                this.formPresenter?.getSelectedCompanyMap() ?? new Map(),
                form.companies
            )

            this.weekPresenterGroup.synchronizeState(form)
        }
    }

    private checkIfCompanyIsInUseBySomeMenuEntry(companyId: number) {
        if (this.__formPresenter) {
            return this.__formPresenter.workingHoursPresenter.isCompanyBeingUsed(companyId)
        }
        return false
    }

    @action()
    private async onCancel() {
        await this.close()
    }

    @action()
    private async onSave() {
        const form: ChannelCompaniesWorkingHours = {
            id: this.timesId,
            companies: this.companySelectorPresenter.extractCompanyIdArray(),
            week: this.weekPresenterGroup.extractWeekData()
        }

        await this.formPresenter.workingHoursPresenter.saveForm(form)
        await this.close()
    }

    private async close() {
        if (!this.disposing) {
            const keys = KeysFactory.channelConfigurationForm(this.app)
            keys.tabIndex(Tabs.TIMES)
            await this.flipToIntent(keys.intent)
        }
    }

    public override onBeforeScopeUpdate() {
        let companyCount = 0
        for (const itemScope of this.scope.companiesSelector.entries) {
            if (itemScope.selected) {
                companyCount++
            }
        }

        if (companyCount === 0) {
            this.scope.companiesSelector.error =
                texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_COMPANY_QUANTITY_ERROR
        } else {
            this.scope.companiesSelector.error = ''
        }
    }
}

class WeekPresenterGroup {
    readonly owner: ChannelConfigurationWorkingHoursEditorFormPresenter

    readonly sunday: WeekdayPresenter
    readonly monday: WeekdayPresenter
    readonly tuesday: WeekdayPresenter
    readonly wednesday: WeekdayPresenter
    readonly thursday: WeekdayPresenter
    readonly friday: WeekdayPresenter
    readonly saturday: WeekdayPresenter

    constructor(owner: ChannelConfigurationWorkingHoursEditorFormPresenter) {
        this.owner = owner
        this.sunday = new WeekdayPresenter(
            owner,
            owner.scope.sunday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SUNDAY_CAPTION
        )
        this.monday = new WeekdayPresenter(
            owner,
            owner.scope.monday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_MONDAY_CAPTION
        )
        this.tuesday = new WeekdayPresenter(
            owner,
            owner.scope.tuesday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TUESDAY_CAPTION
        )
        this.wednesday = new WeekdayPresenter(
            owner,
            owner.scope.wednesday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_WEDNESDAY_CAPTION
        )
        this.thursday = new WeekdayPresenter(
            owner,
            owner.scope.thursday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_THURSDAY_CAPTION
        )
        this.friday = new WeekdayPresenter(
            owner,
            owner.scope.friday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_FRIDAY_CAPTION
        )
        this.saturday = new WeekdayPresenter(
            owner,
            owner.scope.saturday,
            texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_SATURDAY_CAPTION
        )
    }

    public release() {
        this.sunday.release()
        this.monday.release()
        this.tuesday.release()
        this.wednesday.release()
        this.thursday.release()
        this.friday.release()
        this.saturday.release()
    }

    public synchronizeState(form: ChannelCompaniesWorkingHours) {
        this.sunday.synchronizeState(form.week.sunday)
        this.monday.synchronizeState(form.week.monday)
        this.tuesday.synchronizeState(form.week.tuesday)
        this.wednesday.synchronizeState(form.week.wednesday)
        this.thursday.synchronizeState(form.week.thursday)
        this.friday.synchronizeState(form.week.friday)
        this.saturday.synchronizeState(form.week.saturday)
    }

    extractWeekData() {
        const result: ChannelCompaniesWorkingHours['week'] = {}

        if (this.sunday.scope.checked) {
            const periodValue = this.sunday.extractPeriods()
            if (periodValue) {
                result.sunday = periodValue
            }
        }

        if (this.monday.scope.checked) {
            const periodValue = this.monday.extractPeriods()
            if (periodValue) {
                result.monday = periodValue
            }
        }

        if (this.tuesday.scope.checked) {
            const periodValue = this.tuesday.extractPeriods()
            if (periodValue) {
                result.tuesday = periodValue
            }
        }

        if (this.wednesday.scope.checked) {
            const periodValue = this.wednesday.extractPeriods()
            if (periodValue) {
                result.wednesday = periodValue
            }
        }

        if (this.thursday.scope.checked) {
            const periodValue = this.thursday.extractPeriods()
            if (periodValue) {
                result.thursday = periodValue
            }
        }

        if (this.friday.scope.checked) {
            const periodValue = this.friday.extractPeriods()
            if (periodValue) {
                result.friday = periodValue
            }
        }

        if (this.saturday.scope.checked) {
            const periodValue = this.saturday.extractPeriods()
            if (periodValue) {
                result.saturday = periodValue
            }
        }

        return result
    }
}

class WeekdayPresenter extends Presenter<ChannelConfigurationWorkingHoursEditorFormWeekDayScope> {
    public readonly app: MainPresenter

    private initialized = false

    constructor(
        owner: ChannelConfigurationWorkingHoursEditorFormPresenter,
        scope: ChannelConfigurationWorkingHoursEditorFormWeekDayScope,
        caption: string
    ) {
        super(owner, scope, owner.updateManager)
        this.app = owner.app
        this.scope.caption = caption
    }

    public release() {
        this.scope.update = NOOP_VOID
    }

    public get owner(): ChannelConfigurationWorkingHoursEditorFormPresenter {
        return super.owner as ChannelConfigurationWorkingHoursEditorFormPresenter
    }

    private initializeState() {
        this.scope.update = this.update
        this.scope.onCheckChanged = this.onCheckChanged.bind(this)
        this.scope.onPeriodActionClicked = this.onPeriodAction.bind(this)

        this.initialized = true
    }

    public synchronizeState(periods?: PeriodTime[]) {
        if (!this.initialized) {
            this.initializeState()
        }

        const refTime = new Date()
        refTime.setSeconds(0)
        refTime.setMilliseconds(0)

        let i = 0
        if (periods && periods.length > 0) {
            for (const period of periods) {
                this.setPeriod(i, period)
                i++
            }
        }

        this.scope.periods.length = i

        if (periods) {
            this.scope.checked = true
            if (i > 1) {
                this.scope.periodActionType = PeriodActionType.REMOVE
            } else {
                this.scope.periodActionType = PeriodActionType.ADD
            }
        } else {
            this.scope.checked = false
            this.scope.periodActionType = PeriodActionType.NONE
        }
    }

    private setPeriod(i: number, periodValue?: PeriodTime) {
        if (!periodValue) {
            const startTime = { h: 8, m: 0 }
            const endTime = { h: this.owner.scope.sunday === this.scope ? 12 : 18, m: 0 }
            periodValue = { startTime, endTime }

            if (i > 0) {
                let maxHour = 0
                for (const otherPeriodScope of this.scope.periods) {
                    let hh = otherPeriodScope.startTime.value
                    if (hh) {
                        maxHour = Math.max(maxHour, hh.getHours())
                    }

                    hh = otherPeriodScope.endTime.value
                    if (hh) {
                        maxHour = Math.max(maxHour, hh.getHours())
                    }
                }

                maxHour += 1

                startTime.h = maxHour

                const endHourValue = Math.min(maxHour + 4, 24)
                if (endHourValue <= 22) {
                    endTime.h = endHourValue
                } else if (endHourValue <= 24 && maxHour < 22) {
                    endTime.h = 22
                } else {
                    endTime.h = 23
                    endTime.m = 59
                }
            }
        }

        let periodScope = this.scope.periods.get(i)
        if (!periodScope) {
            periodScope = new PeriodScope()
            periodScope.startTime.label = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_START_TIME_FIELD
            periodScope.startTime.onChange = this.onStartTimeChanged.bind(this, i)
            periodScope.endTime.label = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_END_TIME_FIELD
            periodScope.endTime.onChange = this.onEndTimeChanged.bind(this, i)
            periodScope.update = this.update
            this.scope.periods.set(i, periodScope)
        }

        const refTime = new Date()
        refTime.setSeconds(0)
        refTime.setMilliseconds(0)

        if (periodValue.startTime) {
            refTime.setHours(periodValue.startTime.h)
            refTime.setMinutes(periodValue.startTime.m)
            periodScope.startTime.value = new Date(refTime.getTime())
        } else {
            periodScope.startTime.value = null
        }

        if (periodValue.endTime) {
            refTime.setHours(periodValue.endTime.h)
            refTime.setMinutes(periodValue.endTime.m)
            periodScope.endTime.value = new Date(refTime.getTime())
        } else {
            periodScope.endTime.value = null
        }
    }

    @action()
    private async onCheckChanged(evt: CheckedChangeEvent) {
        this.scope.checked = evt.target.checked
        if (this.scope.periods.length === 0) {
            this.setPeriod(0, undefined)
            this.scope.periodActionType = PeriodActionType.ADD
        }
    }

    @action()
    private async onPeriodAction() {
        if (this.scope.periodActionType === PeriodActionType.ADD) {
            this.setPeriod(this.scope.periods.length, undefined)
            this.scope.periodActionType = PeriodActionType.REMOVE
        } else if (this.scope.periodActionType === PeriodActionType.REMOVE) {
            if (this.scope.periods.length > 1) {
                this.scope.periods.length -= 1

                if (this.scope.periods.length === 1) {
                    this.scope.periodActionType = PeriodActionType.ADD
                }
            }
        }
    }

    @action()
    private async onStartTimeChanged(index: number, value: Date | null) {
        const periodScope = this.scope.periods.get(index)
        if (periodScope) {
            periodScope.startTime.value = value
        }
    }

    @action()
    private async onEndTimeChanged(index: number, value: Date | null) {
        const periodScope = this.scope.periods.get(index)
        if (periodScope) {
            periodScope.endTime.value = value
        }
    }

    public extractPeriods() {
        if (this.scope.checked) {
            const result = [] as PeriodTime[]

            for (const periodScope of this.scope.periods) {
                const startTimeInDate = periodScope.startTime.value
                const endTimeInDate = periodScope.endTime.value

                const period: PeriodTime = {}

                if (startTimeInDate && !Number.isNaN(startTimeInDate.getTime())) {
                    period.startTime = { h: startTimeInDate.getHours(), m: startTimeInDate.getMinutes() }
                }

                if (endTimeInDate && !Number.isNaN(endTimeInDate.getTime())) {
                    period.endTime = { h: endTimeInDate.getHours(), m: endTimeInDate.getMinutes() }
                }

                result.push(period)
            }

            return result.length > 0 ? result : undefined
        }

        return undefined
    }

    public onBeforeScopeUpdate() {
        let hasError = false
        const period0 = this.scope.periods.get(0)
        if (period0) {
            hasError = periodScopeValidation(period0)

            const period1 = this.scope.periods.get(1)
            if (period1) {
                hasError = periodScopeValidation(period1) || hasError

                if (!hasError) {
                    const v0 = period0.startTime.value
                    const v1 = period0.endTime.value
                    const v2 = period1.startTime.value
                    const v3 = period1.endTime.value

                    if (v0 && v1 && v2 && v3) {
                        if (v1 >= v2) {
                            this.scope.error = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_TIME_ERROR
                            hasError = true
                        }
                    }
                }
            }
        }

        if (!hasError) {
            this.scope.error = ''
        }
    }
}

const periodScopeValidation = (periodScope: PeriodScope) => {
    const startTimeInDate = periodScope.startTime.value
    const endTimeInDate = periodScope.endTime.value

    let hasError = false

    if (startTimeInDate && endTimeInDate) {
        if (startTimeInDate >= endTimeInDate) {
            periodScope.startTime.error =
                texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_START_TIME_VALIDATION
            periodScope.endTime.error = texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_PERIOD_END_TIME_VALIDATION
            hasError = true
        } else {
            periodScope.startTime.error = ''
            periodScope.endTime.error = ''
        }
    } else {
        if (startTimeInDate) {
            periodScope.startTime.error = ''
        } else {
            periodScope.startTime.error =
                texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_START_TIME_PERIOD_VALIDATION
            hasError = true
        }

        if (endTimeInDate) {
            periodScope.endTime.error = ''
        } else {
            periodScope.endTime.error =
                texts.CHANNEL_CONFIGURATION_WORKING_HOURS_EDITOR_FORM_REQUIRED_END_TIME_PERIOD_VALIDATION
            hasError = true
        }
    }

    return hasError
}
