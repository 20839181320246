import type { CaughtException } from '../common'

export const PATHS = {
    base: 'chat-boat/main'
}

// :: Authorize

export type AuthorizedUser = {
    userName: string
    name: string
    givenName: string
    familyName: string
    email: string
    realm: string
    role: string
    isAdmin: boolean
    preferredLang: string
}

export type AuthorizeRequest = {
    lang: string
    authorization?: string
}

export type AuthorizeResponse = CaughtException & {
    channels: string[]
    user?: AuthorizedUser
}

// :: Message Broker Topics

export const Topics = {
    authorize: 'syo-whatsapp-bus-chat-boat-authorize'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        authorize?: AuthorizeRequest
    }

    mutation?: {
        // NOOP
    }
}

export type Response = {
    query?: {
        authorize?: AuthorizeResponse
    }

    mutation?: {
        // NOOP
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
