import { Command, Construtor, InstrumentAction } from './decorators/command.decorator'
import { Logger } from './logger'
import { LoggerLike } from './LoggerLike'
import { errorResponse } from './error-utils'

export abstract class BaseCommand<REQ, RSP> {
    request!: REQ

    response!: RSP & {
        err?: number
        msg?: string
    }

    caught?: unknown

    context?: string

    private logger: LoggerLike

    constructor(logger?: LoggerLike) {
        let commandName = 'BaseCommand'

        const proto = Reflect.getPrototypeOf(this) as Construtor['prototype']
        if (proto && proto.constructor) {
            commandName = proto.constructor.name
            const action = proto.constructor[Command.PROPERTY_INSTRUMENT_ACTION] as InstrumentAction
            action && action(this)
        }

        this.logger = logger ?? new Logger(commandName)
    }

    public async handle(request: REQ) {
        this.request = request
        await this.execute()
        return this.response
    }

    async execute() {
        const { response } = this
        try {
            await this.doExecute()
            return true
        } catch (caught: unknown) {
            if (this.context) {
                this.logger.log(this.context)
            }
            errorResponse(response, this.logger, caught)
            return false
        }
    }

    async perform(request?: REQ) {
        if (request) {
            this.request = request
        }
        await this.execute()
        if (this.caught) {
            throw this.caught
        }
        return this.response
    }

    protected abstract doExecute(): Promise<void>
}
