import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

@Observable
export class ItemScope extends Scope {
    @observe() index = 0
    @observe() code = ''
    @observe() description = ''
    @observe() subdescription = ''
    @observe() selected = false

    @observe() onSelectionToggled = Scope.SYNC_ACTION
}

@Observable
export class AllItemScope extends ItemScope {
    @observe() subdescription = ''
}

@Observable
export class MultiListingScope extends Scope {
    @observe() selectedIndexes: number[] = []
    @observe() selectedDescription = ''
    readonly entries = new ObservableArray<ItemScope>(this)

    onSelectedToggled = Scope.SYNC_ACTION_ONE<string | undefined>()
}
