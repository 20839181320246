import type { CaughtException, PagingDTO, UserContext } from '../common'
import { InteractionType } from '../../infobip'

export const PATHS = {
    base: 'admin/config/templates'
}

export type TemplateInterationType = 'NONE' | InteractionType

export enum TemplateStatus {
    ALL = 0,
    AVAILABLE = 1,
    PENDING = 2,
    INACTIVE = 3
}

export enum InteractiveTemplateStatus {
    NO = 0,
    YES = 1,
    BOTH = 2
}

// :: FieldIds

export type FieldIdsOption = { id: string; name: string }

export type FieldIdsRequest = { user?: UserContext; lang?: string }
export type FieldIdsResponse = { items: FieldIdsOption[] }

// :: TemplateListing

export type TemplateListingItem = {
    id: string
    name: string
    channel: string
    status: TemplateStatus
    interactiveHsm: boolean
}

export type TemplateListingRequest = {
    user?: UserContext
    itemsPerPage?: number
    pageIndex?: number
    status?: TemplateStatus
    name?: string
    channels?: string[]
    hasAdditionalCharge?: boolean
    interactiveHsm?: InteractiveTemplateStatus
    canCreateRoom?: boolean
}
export type TemplateListingResponse = PagingDTO<TemplateListingItem>

// :: FetchTicketTypes

export type TicketOption = {
    id: string
    group: string
    type: string
}

export type TicketTypesRequest = {
    user?: UserContext
    account: string
    phone: string
}

export type TicketTypesResponse = {
    entries: TicketOption[]
}

// :: TemplateForm

export type TemplateFormField = {
    id: number
    field?: string
    defaultValue?: string
}

export type TemplateFormInteraction = {
    mode: TemplateInterationType
    hsm: boolean
    availableCompanies?: boolean
    ticketType?: TicketOption
}

export type TemplateFormRequest = { user?: UserContext; id: string }
export type TemplateFormResponse = {
    id: string
    name: string
    account: string
    phone: string
    status: TemplateStatus
    published: boolean
    interaction: TemplateFormInteraction
    template: string
    partner?: string
    mediaUrl?: string
    templateType?: string
    hasAdditionalCharge?: boolean
    canCreateRoom?: boolean
    buttons?: ButtonHsmType[]
    fields: TemplateFormField[]
}
export type ButtonHsmType = {
    type: 'QUICK_REPLY' | 'URL'
    parameter: string
}

// :: SaveTemplate

export type SaveTemplateRequest = {
    user?: UserContext
    id: string
    name: string
    published: boolean
    mediaUrl: string
    hasAdditionalCharge?: boolean
    interaction?: TemplateFormInteraction
    enableSyoAlert?: boolean
    fields: TemplateFormField[]
    canCreateRoom?: boolean
}

export type EnableProductsRequest = {
    group: string
}

export type SaveTemplateResponse = {
    status: number
    message?: string
    duration: number
}

// :: Message Broker Topics

export const Topics = {
    fieldIds: 'syo-whatsapp-bus-admin-templates-fieldIds',
    templateListing: 'syo-whatsapp-bus-admin-templates-templateListing',
    templateForm: 'syo-whatsapp-bus-admin-templates-templateForm',
    ticketTypes: 'syo-whatsapp-bus-admin-templates-ticketTypes',
    enableProducts: 'syo-whatsapp-bus-admin-templates-enableProducts',
    saveTemplate: 'syo-whatsapp-bus-admin-templates-saveTemplate'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        fieldIds?: FieldIdsRequest
        templateListing?: TemplateListingRequest
        templateForm?: TemplateFormRequest
        ticketTypes?: TicketTypesRequest
        enableProducts?: EnableProductsRequest
    }

    mutation?: {
        saveTemplate?: SaveTemplateRequest
    }
}

export type EnableProductsResponse = EnableProducts[]

export type EnableProducts = {
    group: string
    syoAlert: boolean
}

export type Response = {
    query?: {
        fieldIds?: FieldIdsResponse
        templateListing?: TemplateListingResponse
        templateForm?: TemplateFormResponse
        ticketTypes?: TicketTypesResponse
        enableProducts?: EnableProductsResponse
    }

    mutation?: {
        saveTemplate?: SaveTemplateResponse
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
