import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Button, { ButtonProps } from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { hasFiles } from 'src/utils/html-utils'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
    className?: string
    id?: string
    name?: string
    label?: string
    textField?: React.ReactElement
    buttonProps?: ButtonProps
    iconProps?: SvgIconProps
    Icon?: typeof SvgIcon
    onSelectFile: (f: File[]) => Promise<void>
    sx?: SxProps<Theme>
}

type PropsWithValidation = Props & {
    accept: string
    validationFn: (f: FileList) => Error | null
}

type P = PropsWithValidation | Props

const isPropsWithValidation = (p: Props | PropsWithValidation): p is PropsWithValidation => 'accept' in p

export const UploadFile: React.FC<P> = (props: P) => {
    const [loading, setLoading] = React.useState(false)
    const uploadInputRef = React.useRef<HTMLInputElement>(null)

    const { Icon = CloudUploadIcon, iconProps = {}, buttonProps = {}, textField = null, label = 'Upload' } = props

    const onUploadFile = () => {
        if (uploadInputRef === null) return
        uploadInputRef.current?.click()
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files

        if (!hasFiles(files)) return

        if (isPropsWithValidation(props)) {
            const error = props.validationFn(files)
            if (error !== null) {
                return
            }
        }

        setLoading(true)

        props.onSelectFile([...files]).finally(() => setLoading(false))
    }

    return (
        <Box
            className={props.className}
            sx={{
                ...(props.sx ?? {})
            }}
        >
            {textField}

            <input
                ref={uploadInputRef}
                accept={isPropsWithValidation(props) ? props.accept : undefined}
                id={props.id ?? `btn-upload-${Math.random()}`}
                name={props.name ?? `btn-upload-${Math.random()}`}
                style={{ display: 'none' }}
                type="file"
                onChange={onSelectFile}
            />
            {loading ? (
                <Box alignSelf="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box alignSelf="center">
                    <Button
                        component="span"
                        variant="contained"
                        color="secondary"
                        {...buttonProps}
                        onClick={onUploadFile}
                    >
                        <Icon sx={{ mr: 1 }} {...iconProps} />
                        {label}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
