import Duration from 'duration-relativetimeformat'

export const Texts = {
    LOCALE: 'es-ES',
    DURATION: new Duration('es'),
    DASHBOARD_FILTER_DATE_TIME: 'Fecha y hora',
    DASHBOARD_FILTER_CHANNEL_NAME: 'Canales',
    DASHBOARD_FILTER_CLEAR_BUTTON: 'Limpiar',
    DASHBOARD_FILTER_FILTER_BUTTON: 'Filtrar',
    DASHBOARD_FILTER_DATE_TIME_START: 'Fecha/hora de inicio',
    DASHBOARD_FILTER_DATE_TIME_END: 'Fecha/hora de finalización',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_ALERT_ERROR_TITLE: 'Inconsistencia del estado',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_DATE_TIME_ALERT_ERROR_CONTENT:
        'La fecha de inicio debe ser anterior a la fecha de finalización',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_MULTILISTING_ALERT_ERROR_CONTENT:
        'No se permite la búsqueda sin el elemento seleccionado',

    KPI_EVENTS_TITLE: 'Eventos',
    KPI_EVENTS_INFO: 'Este KPI presenta el total de eventos generados en CRM 360 para el canal y periodo filtrado',

    KPI_CONTACTS_TITLE: 'Contactos',
    KPI_CONTACTS_INFO:
        'Este KPI presenta el número total de clientes diferentes que contactaron con el canal en el período filtrado',

    KPI_NEW_CONTACTS_TITLE: 'Nuevos contactos',
    KPI_NEW_CONTACTS_INFO:
        'Este KPI presenta el número total de nuevos clientes diferentes que contactaron con el canal en el período filtrado.\n\nClientes nuevos: clientes que aún no existían en la base de datos de CRM 360',

    KPI_SCHEDULE_TITLE: 'Horarios',
    KPI_SCHEDULE_INFO: 'Este KPI presenta el número total de citas',

    SERVICE_TABLE_ALL_ATTENDANCES_TITLE: 'TODOS LOS SERVICIOS (%d)',
    SERVICE_TABLE_TITLE: 'Llamadas sin visualización (%d)',
    SERVICE_TABLE_INFO:
        'Esta tabla devuelve todos los eventos que tienen mensajes no vistos por el usuario de Syonet CRM para el canal filtrado, en orden descendente (del más antiguo al más nuevo).',
    SERVICE_TABLE_ROWS_PER_PAGE: 'Filas por página: ',
    SERVICE_TABLE_GO_TO_CHAT: 'ir a conversaciones',
    SERVICE_TABLE_GO_TO_TICKET: 'ir al evento',

    SERVICE_TABLE_FILTER_TICKET_NUMBER: 'Numero de evento',
    SERVICE_TABLE_FILTER_CUSTOMERS: 'Clientes',
    SERVICE_TABLE_FILTER_COMPANIES: 'Agencias',
    SERVICE_TABLE_FILTER_CATEGORY: 'Grupo/Evento',
    SERVICE_TABLE_FILTER_USERS: 'Usuarios',
    SERVICE_TABLE_FILTER_SERVICES: 'Atenciones',
    SERVICE_TABLE_FILTER_TIME: 'Hora',
    SERVICE_TABLE_FILTER_OPEN_BUTTON: 'Filtros',
    SERVICE_TABLE_FILTER_CLEAR_BUTTON: 'Limpiar',
    SERVICE_TABLE_FILTER_APPLY_BUTTON: 'Aplicar',

    AVERAGE_VISUALIZATION_TIME_CHART:
        'Tiempo promedio para ver el gráfico\n\nEste gráfico presenta el tiempo promedio de visualización de los mensajes recibidos dentro de cada hora del día, según el canal filtrado y el período.\n\nDará una visión general del tiempo promedio que el cliente envía el mensaje al canal y espera a ser visto por el usuario de CRM 360.\n\nEn principio, los tiempos de mayor espera son a los que el gestor debe prestar más atención. Algunas posibles causas: falta de personas para atender la demanda del tiempo, descuido de los usuarios que reciben los eventos, etc.\n\nEjemplo: Si estoy filtrando el canal X, del 01/10/2020 al 31/10/2020, el sistema analizará los 31 días, hora por hora.\n\nSi solo miramos el rango de tiempo de 08:00:00 a 08:59:59, mostrará un tiempo promedio para ver los mensajes recibidos entre ese tiempo.\n\nEn la gráfica de este ejemplo pondremos la media en el momento de las 8h.',
    CONTACTS_BY_DAY:
        'Contactos por dia\n\nLa sugerencia de Syonet para filtrar este informe es siempre mensual (todo el mes). Si desea obtener resultados durante un período más prolongado, siga filtrando los meses completos.\nEjemplo: 01/01/2020 al 30/04/2020.\n\nUso correcto\n“El gráfico CONTACTOS POR DÍA presenta los resultados por DÍA, es decir, si filtramos un período de 31 días, por ejemplo, del 01/01/2021 al 31/01/2021. El gráfico mostrará el resultado del día 1 al 31.\nEsto aplica para la cantidad de meses que estemos usando, lo ideal es usar números pares y meses completos, por ejemplo, 01/01/2021- 30/04/2021, así tendremos un total de contactos por día de estos 4 meses\nAhora bien, si realizamos un filtro de 31 días, sin embargo, con un período que abarca el mismo día, pero en diferentes meses, por ejemplo, del 22/01/2021 al 22/02/2021, el gráfico agregará los contactos por día. , es decir:\n\nCaso 1:\n2 contactos el 20/01\n5 contactos el 20/02\nEl gráfico presenta 7 contactos el día 20\n\nCaso 2:\n0 contatos no dia 21/01\n10 contactos el 21/01\nEl gráfico presenta 10 contactos el día 21\n\nNota: Para la consulta de gráficos, el tiempo de filtro se ignora, el sistema considera solo los días de inicio y finalización.',

    ACTIVE_MESSAGES_PER_USER_TITLE: 'Mensajes activos por usuario',
    ACTIVE_MESSAGES_PER_USER_DETAILED_LIST:
        'Los HSM (s) son mensajes enviados por la empresa para intentar iniciar una conversación con el cliente, deben ser aprobados previamente.',
    ACTIVE_MESSAGES_PER_USER_EXPORT_TABLE_TO_XLS_FORMAT: 'Exportar tabla a XLS',
    ACTIVE_MESSAGES_PER_USER_LABEL: 'Usuario',
    ACTIVE_MESSAGES_PER_USER_SENT_MESSAGES_LABEL: 'Mensajes enviados',
    ACTIVE_MESSAGES_PER_USER_PARTIAL_TOTAL_VALUE_LABEL: 'Total de parcial',
    ACTIVE_MESSAGES_PER_USER_ROWS_PER_PAGE: 'Filas por página: ',

    UNREAD_MESSAGES_COLUMN_EVENT: 'Evento',
    UNREAD_MESSAGES_COLUMN_CLIENT: 'Cliente',
    UNREAD_MESSAGES_COLUMN_COMPANY: 'Agencias',
    UNREAD_MESSAGES_COLUMN_DEPARTMENT: 'Departamento',
    UNREAD_MESSAGES_COLUMN_USER: 'Usuario',
    UNREAD_MESSAGES_COLUMN_UNREAD_SINCE: 'Visto por última vez',

    ACTIVE_USER_MESSAGES_MODAL_TITLE: 'Mensajes activos del usuario',
    ACTIVE_USER_MESSAGES_MODAL_CLOSE_BUTTON: 'Cerrar',
    ACTIVE_USER_MESSAGES_MODAL_EXPORT_BUTTON: 'Exportar',
    ACTIVE_USER_MESSAGES_MODAL_ATTENDANCE_NUMBER: 'Número de asistencia',
    ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_NAME: 'Nombre del cliente',
    ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_PHONE: 'Teléfono del cliente',
    ACTIVE_USER_MESSAGES_MODAL_SENT_DATE: 'Transporte',
    ACTIVE_USER_MESSAGES_MODAL_TEXT_MESSAGE: 'Mensaje de texto',
    ACTIVE_USER_MESSAGES_MODAL_ROWS_PER_PAGE: 'Filas por página: ',

    NO_CONTENT_TO_GENERATE_FILE: 'No hay contenido para generar el archivo.',
    FILE_NAME: 'active_messages',

    ATTENDANCE_TABLE_DATE: 'Fecha',
    ATTENDANCE_TABLE_TIME: 'Hora',

    ON_ACTIVE_MESSAGES_EXPORT_TO_XLS_ALERT_TITLE: 'Reporte',
    DATE_TIME_INITIAL_CHIP_DESCRIPTION: 'Fecha/hora de inicio',
    DATE_TIME_FINAL_CHIP_DESCRIPTION: 'Fecha/hora de finalización',
    CHANNEL_NAME_CHIP_DESCRIPTION: 'Canale',

    INVALID_CONTEXT_ARGUMENT_ERROR: 'Invalid context or arguments',
    NO_DATA_TO_USER_ERROR: 'Sin datos de usuario',

    CUSTOMER_SERVICE_FILTER_ALL: 'Todo',
    CUSTOMER_SERVICE_FILTER_ROBOT: 'Robot',
    CUSTOMER_SERVICE_FILTER_HUMAN: 'Humano',
    CUSTOMER_SERVICE_FILTER_ONE_DAY: 'Más de %d día',
    CUSTOMER_SERVICE_FILTER_MORE_THAN_ONE_DAY: 'Más de %d días',

    LOADING_CONTENT_DESCRIPTION: 'Cargando...'
}
