import { lodash } from '@syonet/lang'
import { ChannelConfigurationFormKeys, KeysFactory } from 'src/Constants'
import { HtmlUtils } from 'src/utils'
import voca from 'voca'
import { Logger, Presenter, action } from 'wdc-cube'
import { AlertHolder } from '../main'
import {
    ChannelCompanyOption,
    ChannelMenuConfig,
    ChannelMenuItem,
    ChannelMenuType
} from './ChannelConfiguration.service'
import { AlertWarningConfirmContentScope } from './ChannelConfigurationAlert.scopes'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'
import {
    ChannelConfigurationFormMenuItemScope,
    ChannelConfigurationFormMenuScope
} from './ChannelConfigurationForm.scopes'
import { TextsProvider } from './texts'

const LOG = Logger.get('ChannelConfigurationFormMenuPresenter')

// @Inject
const texts = TextsProvider.get()

export class ChannelConfigurationFormMenuPresenter extends Presenter<
    ChannelConfigurationFormMenuScope,
    ChannelConfigurationFormPresenter
> {
    private listingItemMap = new Map<number, ChannelMenuItem>()

    private firstMenuIdWithError?: number

    private alertHolder: AlertHolder

    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner, owner.scope.menuTab, owner.updateManager)
        this.alertHolder = owner.alertHolder
    }

    public get eventOptionMap() {
        return this.owner.eventOptionMap
    }

    public isCompanyBeingUsed(companyId: number): boolean {
        for (const item of this.listingItemMap.values()) {
            if (item.companies.indexOf(companyId) !== -1) {
                return true
            }
        }
        return false
    }

    public onUnassignCompanyById(companyId: number) {
        let changed = false

        const companyMap = this.owner.getCompanyMap()

        for (const item of this.listingItemMap.values()) {
            const idx = item.companies.indexOf(companyId)
            if (idx !== -1) {
                item.companies.splice(idx, 1)

                let maxEventIdCount = 0
                const map = new Map<number, number>()
                for (const companyId of item.companies) {
                    const menuCompany = companyMap.get(companyId)
                    if (menuCompany) {
                        const nextCount = (map.get(menuCompany.id) ?? 0) + 1
                        map.set(menuCompany.id, nextCount)
                        maxEventIdCount = Math.max(maxEventIdCount, nextCount)
                    }
                }

                for (const [menuId, eventIdCount] of map.entries()) {
                    if (eventIdCount !== maxEventIdCount) {
                        map.delete(menuId)
                    }
                }

                changed = true
            }
        }

        if (changed) {
            this.synchronizeListing()
        }
    }

    public openFirstFormWithErrors() {
        this.owner.scope.tabIndex = this.scope.index
        if (this.firstMenuIdWithError !== undefined) {
            this.flipToForm(this.firstMenuIdWithError).catch((error) =>
                LOG.error('Opening menu form ' + this.firstMenuIdWithError, error)
            )
        } else {
            this.scope.blink = 'add-menu-button'
        }
    }

    private bindListeners() {
        this.scope.onOpenEditor = this.onOpenEditor.bind(this)
    }

    async initializeState(keys: ChannelConfigurationFormKeys, menus: ChannelMenuConfig) {
        this.bindListeners()
        await this.synchronizeState(menus)
    }

    async synchronizeState(menus: ChannelMenuConfig) {
        if (menus.items) {
            this.listingItemMap.clear()

            for (const listingItem of menus.items) {
                this.listingItemMap.set(listingItem.id, listingItem)
            }
        }

        this.synchronizeListing()
    }

    @action()
    private async onOpenEditor() {
        const targetKeys = KeysFactory.channelConfigurationMenuEditorForm(this.owner.app)
        this.owner.app.flipToIntent(targetKeys.intent)
    }

    async refresh() {
        this.synchronizeListing()
    }

    private synchronizeListing() {
        const companyMap = this.owner.getSelectedCompanyMap()

        let i = 0
        for (const [itemId, item] of this.listingItemMap.entries()) {
            let menuItemScope = this.scope.entries.get(i)
            if (!menuItemScope) {
                menuItemScope = new ChannelConfigurationFormMenuItemScope()
                menuItemScope.onEdit = this.onMenuItemEdit.bind(this, menuItemScope)
                menuItemScope.onEditMenuSegmentation = this.onMenuItemSegment.bind(this, menuItemScope)
                menuItemScope.onDuplicate = this.onMenuItemDuplicate.bind(this, menuItemScope)
                menuItemScope.onDelete = this.onMenuItemDelete.bind(this, menuItemScope)
                this.scope.entries.set(i, menuItemScope)
            }

            menuItemScope.id = itemId
            menuItemScope.name = voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_ITEM_TITLE, i + 1)

            const companyNames: string[] = []
            const companySegmentations = []
            for (const companyId of item.companies) {
                const companyOption = companyMap.get(companyId)
                if (companyOption) {
                    companyNames.push(companyOption.name)
                    companySegmentations.push(companyOption)
                }
            }
            menuItemScope.companies = companyNames.join(', ')

            let eventCount = 0,
                linkCount = 0,
                robotCount = 0,
                subMenuCount = 0,
                informationCount = 0,
                segmentationCount = 0
            for (const menuOption of item.entries) {
                switch (menuOption.type) {
                    case ChannelMenuType.EVENT:
                        eventCount++
                        break
                    case ChannelMenuType.LINK:
                        linkCount++
                        break
                    case ChannelMenuType.ROBOT:
                        robotCount++
                        break
                    case ChannelMenuType.SUB_MENU:
                        subMenuCount++
                        break
                    case ChannelMenuType.INFORMATION:
                        informationCount++
                        break
                }

                menuOption.segmentations = item.segmentations
            }

            for (const company of companySegmentations) {
                const generalConfig = company.internal.generalConfig
                generalConfig?.servicesBySegmentation?.forEach((segmentation) => {
                    if (item.companies.includes(company.id) && item.segmentations.includes(segmentation.on) && segmentation.servicesId.join('').length > 0) {
                        segmentationCount++
                    }
                })
            }

            menuItemScope.eventCount = eventCount
            menuItemScope.linkCount = linkCount
            menuItemScope.robotCount = robotCount
            menuItemScope.subMenuCount = subMenuCount
            menuItemScope.informationCount = informationCount
            menuItemScope.segmentationCount = segmentationCount
            i++
        }

        this.scope.entries.length = i
    }

    private async flipToForm(menuId: number) {
        const targetKeys = KeysFactory.channelConfigurationMenuEditorForm(this.owner.app)
        targetKeys.channelId(this.owner.channelId)
        targetKeys.menuId(menuId)
        await this.owner.flipToIntent(targetKeys.intent)
    }

    @action()
    private async onMenuItemEdit(item: ChannelConfigurationFormMenuItemScope) {
        await this.flipToForm(item.id)
    }

    private async flipToSegmentationForm(menuId: number) {
        const targetKeys = KeysFactory.channelConfigurationMenuSegmentationEditorForm(this.owner.app)
        targetKeys.channelId(this.owner.channelId)
        targetKeys.menuId(menuId)
        await this.owner.flipToIntent(targetKeys.intent)
    }

    @action()
    private async onMenuItemSegment(item: ChannelConfigurationFormMenuItemScope) {
        await this.flipToSegmentationForm(item.id)
    }

    @action()
    private async onMenuItemDuplicate(item: ChannelConfigurationFormMenuItemScope) {
        const itemData = this.listingItemMap.get(item.id)
        if (itemData) {
            const newItem = lodash.cloneDeep(itemData)
            newItem.id = this.nextMenuId()
            newItem.companies.length = 0

            // Without defined company/companies it is impossivel to have a valid eventId
            for (const menuEntry of newItem.entries) {
                if (menuEntry.eventId !== undefined) {
                    // ... so, remove it
                    menuEntry.eventId = undefined
                }
            }

            this.saveForm(newItem)
        }
    }

    @action()
    private async onMenuItemDelete(item: ChannelConfigurationFormMenuItemScope) {
        if (item) {
            const confirmScope = new AlertWarningConfirmContentScope()
            const menuName = voca.capitalize(item.name.toLocaleLowerCase())

            confirmScope.message = voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_ON_DELETE_QUESTION, menuName)
            confirmScope.cancelLabelOption = texts.DELETE_MENU_OPTION_NO
            confirmScope.confirmLabelOption = texts.DELETE_MENU_OPTION_YES
            confirmScope.unfocusConfirmButton = true

            confirmScope.onConfirm = async () => {
                if (this.listingItemMap.delete(item.id)) {
                    this.synchronizeListing()
                }
                this.alertHolder.closeAlert()
            }
            confirmScope.onCancel = async () => {
                LOG.info('Menu exclusion canceled')
                this.alertHolder.closeAlert()
            }

            confirmScope.update = this.update

            this.alertHolder.alert(
                'warning',
                voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_ON_DELETE_TITLE, menuName),
                confirmScope,
                confirmScope.onCancel
            )
        }
    }

    private nextMenuId() {
        let maxId = -1
        for (const otherMenu of this.listingItemMap.values()) {
            maxId = Math.max(otherMenu.id, maxId)
        }
        return maxId + 1
    }

    getForm(menuId: number): ChannelMenuItem {
        const menuForm = this.listingItemMap.get(menuId)
        if (menuForm) {
            return menuForm
        } else {
            return {
                id: this.nextMenuId(),
                companies: [],
                entries: [],
                segmentations: []
            }
        }
    }

    saveForm(menuForm: ChannelMenuItem) {
        const usedCompanyMap = new Map<number, boolean>()
        for (const companyId of menuForm.companies) {
            usedCompanyMap.set(companyId, true)
        }

        for (const otherMenu of this.listingItemMap.values()) {
            const newOtherCompanyIdArray: number[] = []

            for (const otherCompanyId of otherMenu.companies) {
                if (!usedCompanyMap.has(otherCompanyId)) {
                    newOtherCompanyIdArray.push(otherCompanyId)
                    usedCompanyMap.set(otherCompanyId, true)
                }
            }

            otherMenu.companies = newOtherCompanyIdArray
        }

        this.listingItemMap.set(menuForm.id, menuForm)
        this.synchronizeListing()
        this.update()
    }

    public beforeUpdate() {
        this.firstMenuIdWithError = undefined

        const remainingCompanyMap = new Map(this.owner.getSelectedCompanyMap())

        let hasInconsistencies = false

        for (const entry of this.scope.entries) {
            const menuItem = this.listingItemMap.get(entry.id)
            if (menuItem) {
                for (const companyId of menuItem.companies) {
                    remainingCompanyMap.delete(companyId)
                }

                let j = 0

                if (menuItem.companies.length === 0) {
                    entry.errors.set(j++, texts.CHANNEL_CONFIGURATION_MENU_NO_COMPANY_ESTABLISHED)
                    hasInconsistencies = true
                }

                let missingDescription = 0
                let missingLink = 0
                let missingEventOption = 0
                let missingMessage = 0
                let missingPositiveButton = 0
                let missingNegativeButton = 0

                for (const option of menuItem.entries) {
                    if (HtmlUtils.isEmpty(option.description)) {
                        missingDescription++
                    }

                    switch (option.type) {
                        case ChannelMenuType.EVENT:
                            if (option.eventId === undefined) {
                                missingEventOption++
                            }
                            break
                        case ChannelMenuType.INFORMATION: {
                            const { message, eventId, positiveButton, negativeButton } = option
                            if (!message || HtmlUtils.isEmpty(message)) missingMessage++
                            if (!eventId) missingEventOption++
                            if (!positiveButton) missingPositiveButton++
                            if (!negativeButton) missingNegativeButton++
                            break
                        }
                        case ChannelMenuType.LINK:
                            if (!option.link) {
                                missingLink++
                            }
                            break
                    }
                }

                if (menuItem.entries.length === 0) {
                    entry.errors.set(j++, texts.CHANNEL_CONFIGURATION_MENU_NO_MENU_ITEM_ESTABLISHED)
                    hasInconsistencies = true
                }

                if (missingEventOption > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION} ${missingEventOption} ${missingEventOption === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_EVENT_DEFINITION_ITEM_PLURAL
                        }`
                    )
                    hasInconsistencies = true
                }

                if (missingDescription > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION} ${missingDescription} ${missingDescription === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_TEXT_IN_DESCRIPTION_ITEM_PLURAL
                        }`
                    )
                    hasInconsistencies = true
                }

                if (missingLink > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL} ${missingLink} ${missingLink === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL
                        }`
                    )
                    hasInconsistencies = true
                }

                if (missingMessage > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_MESSAGE} ${missingMessage} ${missingMessage === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL
                        }`
                    )
                }

                if (missingPositiveButton > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_POSITIVE_BUTTON} ${missingPositiveButton} ${missingPositiveButton === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL
                        }`
                    )
                }

                if (missingNegativeButton > 0) {
                    entry.errors.set(
                        j++,
                        `${texts.CHANNEL_CONFIGURATION_MENU_MISSING_NEGATIVE_BUTTON} ${missingNegativeButton} ${missingNegativeButton === 1
                            ? texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_SINGULAR
                            : texts.CHANNEL_CONFIGURATION_MENU_MISSING_URL_ITEM_PLURAL
                        }`
                    )
                }

                entry.errors.length = j

                if (hasInconsistencies && this.firstMenuIdWithError === undefined) {
                    this.firstMenuIdWithError = entry.id
                }
            }
        }

        if (this.scope.entries.length === 0) {
            const msg = this.owner.validator.menuDefinitionsNoMenuDefined()
            this.scope.errors.set(0, msg)
        } else if (remainingCompanyMap.size > 0) {
            this.owner.validator.menuDefinitionsNotAllCompaniesHaveMenu()
            const msg = voca.sprintf(
                texts.CHANNEL_CONFIGURATION_MENU_MISSING_COMPANIES_WITHOUT_MENUS,
                this.buildCompanyNames(remainingCompanyMap)
            )
            this.scope.errors.set(0, msg)
        } else if (hasInconsistencies) {
            this.scope.errors.length = 0
            this.owner.validator.menuDefinitionsPartialDefined()
        } else {
            this.scope.errors.length = 0
            this.owner.validator.menuDefinitionsOk()
        }
    }

    private buildCompanyNames(companyIdMap: Map<number, ChannelCompanyOption>) {
        this.owner.getCompanyMap()

        const companyNames: string[] = []
        for (const company of companyIdMap.values()) {
            companyNames.push(company.name)
        }

        return companyNames.join(', ')
    }

    public extractFormData(): ChannelMenuConfig {
        const items: ChannelMenuItem[] = []

        for (const item of this.listingItemMap.values()) {
            items.push(lodash.cloneDeep(item))
        }

        return { items }
    }
}
