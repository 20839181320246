/* eslint-disable react/display-name */
import { Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { DragDropContext, Droppable, DropResult as LibDropResult, OnDragEndResponder } from 'react-beautiful-dnd'
import { TextsProvider } from '../../feature/channel/texts'
import SegmentationItem from './SegmentationItem'
import { getOrMakeStyles } from './SegmentationList.styles'

const texts = TextsProvider.get()

export type DropResult = LibDropResult
interface ListProps {
    segmentations: string[]
    onDragEnd: OnDragEndResponder
    className?: string
}

export type SegmentationListElement = React.FunctionComponentElement<ListProps>

export const SegmentationList: React.FC<ListProps> = ({ segmentations, onDragEnd, className }: ListProps) => {

    const { classes: styles } = getOrMakeStyles()

    return (
        <div>
            {segmentations.length > 0
                ? <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list" direction="horizontal">
                        {(provided) => (
                            <div
                                className={clsx(styles.styleList, className)}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <Typography variant="caption" className={styles.styleLabel}>
                                    {texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_SEGMENTATION_INFO_ORDER}
                                </Typography>
                                {segmentations.map((description, index) => (
                                    <SegmentationItem key={index} index={index} name={description} />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                : <></>
            }
        </div>
    )
}
