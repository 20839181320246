import { Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { SectionHeaderScope } from '..'
import { getOrMakeStyles } from './SectionHeader.styles'

// :: Component(SectionHeaderView)

type SectionHeaderProps = IViewProps & {
    scope: SectionHeaderScope
}

export function SectionHeaderView({ className, scope }: SectionHeaderProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustBoxes}>
                <Typography variant="h6" color="textSecondary">
                    {scope.description}
                </Typography>
            </div>
        </div>
    )
}
