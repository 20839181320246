import { lodash } from '@syonet/lang'
import { WaitingScope } from 'src/components/waiting'
import { ChatContactInfo, ChatsFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { action, CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID } from 'wdc-cube'
import { AlertHolder, MainPresenter } from '../main'
import {
    ChatCompanyOption,
    ChatMediaOption,
    ChatResponsableOption,
    ChatsGenerateTicketRequest,
    ChatsGenerateTicketStatus,
    ChatSourceOption,
    ChatsService,
    ChatTicketOption,
    TicketSourceAndResponsableOptionsResponse
} from './Chats.service'
import { TextChangeEvent } from './ChatsFilter.scopes'
import { ChatsFormBodyScope, ChatsFormScope } from './ChatsForm.scopes'
import { TextsProvider } from './texts'

// @Inject
const texts = TextsProvider.get()

// @Inject
const chatsService = ChatsService.singleton()

export class ChatsFormPresenter extends CubePresenter<MainPresenter, ChatsFormScope> implements IPresenterOwner {
    private formScope = new ChatsFormBodyScope()

    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private contact!: Readonly<ChatContactInfo>

    private disposed = false

    private alertHolder?: AlertHolder

    private optionsCache = new Map<string, Map<unknown, unknown>>()

    public constructor(app: MainPresenter) {
        super(app, new ChatsFormScope())
        this.formScope.update = this.update
    }

    public override release(): void {
        this.disposed = true
        this.alertHolder?.closeAlert()
        this.modalSlot(this.scope, true)
        super.release()
    }

    // Cube context syncronization methods: Begin

    public override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChatsFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
            this.scope.body = this.formScope
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    // Cube context syncronization methods: End

    private async initializeState(keys: ChatsFormKeys) {
        const { formScope } = this
        this.modalSlot = keys.modalSlot()

        const waiting = new WaitingScope()
        waiting.text = texts.LOADING_CONTENT_DESCRIPTION
        waiting.update = this.update
        this.scope.body = waiting

        this.modalSlot(this.scope)

        this.optionsCache = keys.optionsCache() ?? this.optionsCache

        formScope.onChangeCustomerName = this.onChangeCustomerName.bind(this)
        formScope.onChangeCustomerPhone = this.onChangeCustomerPhone.bind(this)

        formScope.company.onChange = this.onChangeCompany.bind(this)
        formScope.company.update = this.update

        formScope.ticketType.onChange = this.onChangeTicket.bind(this)
        formScope.ticketType.update = this.update

        formScope.source.onChange = this.onChangeSource.bind(this)
        formScope.source.update = this.update

        formScope.media.onChange = this.onChangeMedia.bind(this)
        formScope.media.update = this.update

        formScope.responsable.onChange = this.onChangeResponsable.bind(this)
        formScope.responsable.update = this.update

        formScope.onToggleAcceptCustomResponsable = this.onToggleAcceptCustomResponsable.bind(this, false)

        formScope.onCancel = this.onCancel.bind(this)
        formScope.onGenerateTicket = this.onGenerateTicket.bind(this)

        this.alertHolder = keys.alertHolder()

        // It will always present
        const contact = keys.contact()
        if (contact) {
            this.contact = contact
        }

        await this.refreshOptions()

        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChatsFormKeys, force = false) {
        let changed = force

        const contact = keys.contact()
        if (!contact) {
            throw new Error('Missing contact from flip context')
        }

        const newContactId = contact.id ?? this.contact.id
        changed = changed || newContactId !== this.contact.id

        if (changed) {
            this.contact = contact
            await this.refresh()
        }
    }

    private async fetchCompanyOptions(accountId: string) {
        let options = CacheHelper.company.get(this.optionsCache, accountId)
        if (!options) {
            const optionArray = await chatsService.fetchCompanyOptions(accountId)

            options = new Map()
            for (const option of optionArray) {
                options.set(option.id, option)
            }
            CacheHelper.company.set(this.optionsCache, accountId, options)
        }
        return options
    }

    private async fetchTicketSourceAndResponsableOptions(accountId: string, companyId: number) {
        let response: TicketSourceAndResponsableOptionsResponse | undefined

        let ticketOptions = CacheHelper.ticket.get(this.optionsCache, accountId, companyId)
        if (!ticketOptions) {
            response = response || (await chatsService.fetchTicketSourceAndResponsableOptions(accountId, companyId))

            ticketOptions = new Map()
            for (const option of response.tickets) {
                ticketOptions.set(option.id, option)
            }
            CacheHelper.ticket.set(this.optionsCache, accountId, companyId, ticketOptions)
        }

        let sourceOptions = CacheHelper.source.get(this.optionsCache, accountId, companyId)
        if (!sourceOptions) {
            response = response || (await chatsService.fetchTicketSourceAndResponsableOptions(accountId, companyId))

            sourceOptions = new Map()
            for (const option of response.sources) {
                sourceOptions.set(option.id, option)
            }
            CacheHelper.source.set(this.optionsCache, accountId, companyId, sourceOptions)
        }

        let responsableOptions = CacheHelper.responsable.get(this.optionsCache, accountId, companyId)
        if (!responsableOptions) {
            response = response || (await chatsService.fetchTicketSourceAndResponsableOptions(accountId, companyId))

            responsableOptions = new Map()
            for (const option of response.responsables) {
                responsableOptions.set(option.id, option)
            }
            CacheHelper.responsable.set(this.optionsCache, accountId, companyId, responsableOptions)
        }

        return {
            ticketOptions,
            sourceOptions,
            responsableOptions
        }
    }

    private async fetchTicketOptions(accountId: string, companyId: number) {
        const ticketOptions = CacheHelper.ticket.get(this.optionsCache, accountId, companyId)
        if (ticketOptions) {
            return ticketOptions
        } else {
            const response = await this.fetchTicketSourceAndResponsableOptions(accountId, companyId)
            return response.ticketOptions
        }
    }

    private async fetchSourceOptions(accountId: string, companyId: number) {
        const sourceOptions = CacheHelper.source.get(this.optionsCache, accountId, companyId)
        if (sourceOptions) {
            return sourceOptions
        } else {
            const response = await this.fetchTicketSourceAndResponsableOptions(accountId, companyId)
            return response.sourceOptions
        }
    }

    private async fetchResponsableOptions(accountId: string, companyId: number) {
        const responsableOptions = CacheHelper.responsable.get(this.optionsCache, accountId, companyId)
        if (responsableOptions) {
            return responsableOptions
        } else {
            const response = await this.fetchTicketSourceAndResponsableOptions(accountId, companyId)
            return response.responsableOptions
        }
    }

    private async fetchMediaOptions(accountId: string, companyId: number, sourceId: number) {
        let options = CacheHelper.media.get(this.optionsCache, accountId, companyId, sourceId)
        if (!options) {
            const optionArray = await chatsService.fetchMediaOptions(accountId, companyId, sourceId)

            options = new Map()
            for (const option of optionArray) {
                options.set(option.id, option)
            }
            CacheHelper.media.set(this.optionsCache, accountId, companyId, sourceId, options)
        }
        return options
    }

    private async refreshOptions() {
        await this.refreshCompanyOptions()
        await this.refreshTicketOptions()
        await this.refreshSourceOptions()
        await this.refreshMediaOptions()
        await this.refreshResponsableOptions()
        await this.refreshResponsableOptions()
    }

    private async refreshCompanyOptions() {
        const { formScope } = this
        const accountId = this.contact.customer.account
        const companyOptions = await this.fetchCompanyOptions(accountId)

        if (companyOptions.size > 0) {
            const companyId = formScope.company.value ?? -1
            let companyIdFound = false

            let i = 0
            for (const option of companyOptions.values()) {
                if (companyId === option.id) {
                    companyIdFound = true
                }
                formScope.company.options.set(i++, { id: option.id, name: option.name })
            }
            formScope.company.options.length = i

            if (!companyIdFound) {
                formScope.company.value = null
            }
        } else {
            formScope.company.value = null
            formScope.company.options.length = 0
        }
    }

    private async refreshTicketOptions() {
        const { formScope } = this
        const accountId = this.contact.customer.account
        const companyId = formScope.company.value ?? -1
        if (companyId !== -1) {
            const ticketTypeId = formScope.ticketType.value ?? -1
            let ticketIdFound = false
            let i = 0
            const sourceOptions = await this.fetchTicketOptions(accountId, companyId)
            for (const option of sourceOptions.values()) {
                if (ticketTypeId === option.id) {
                    ticketIdFound = true
                }
                formScope.ticketType.options.set(i++, { id: option.id, name: option.name })
            }
            formScope.ticketType.options.length = i

            if (!ticketIdFound) {
                formScope.ticketType.value = null
            }
        } else {
            formScope.ticketType.value = null
            formScope.ticketType.options.length = 0
        }
    }

    private async refreshSourceOptions() {
        const { formScope } = this
        const accountId = this.contact.customer.account
        const companyId = formScope.company.value ?? -1
        if (companyId != -1) {
            const sourceId = formScope.source.value ?? -1
            let sourceIdFound = false
            let i = 0
            const sourceOptions = await this.fetchSourceOptions(accountId, companyId)
            for (const option of sourceOptions.values()) {
                if (sourceId === option.id) {
                    sourceIdFound = true
                }
                formScope.source.options.set(i++, { id: option.id, name: option.name })
            }
            formScope.source.options.length = i

            if (!sourceIdFound) {
                formScope.source.value = null
            }
        } else {
            formScope.source.value = null
            formScope.source.options.length = 0
        }
    }

    private async refreshMediaOptions() {
        const { formScope } = this
        const accountId = this.contact.customer.account
        const companyId = formScope.company.value ?? -1
        const sourceId = formScope.source.value ?? -1
        if (companyId !== -1 && sourceId !== -1) {
            const mediaId = formScope.media.value ?? -1
            let mediaIdFound = false

            let i = 0
            const mediaOptions = await this.fetchMediaOptions(accountId, companyId, sourceId)
            for (const option of mediaOptions.values()) {
                if (mediaId === option.id) {
                    mediaIdFound = true
                }
                formScope.media.options.set(i++, { id: option.id, name: option.name })
            }
            formScope.media.options.length = i

            if (!mediaIdFound) {
                formScope.media.value = null
            }
        } else {
            formScope.media.value = null
            formScope.media.options.length = 0
        }
    }

    private async refreshResponsableOptions() {
        const { formScope } = this
        const accountId = this.contact.customer.account
        const companyId = formScope.company.value ?? -1
        if (companyId !== -1) {
            const responsableId = formScope.responsable.value ?? -1
            let responsableIdFound = false

            let i = 0
            const responsableOptions = await this.fetchResponsableOptions(accountId, companyId)
            for (const option of responsableOptions.values()) {
                if (responsableId === option.id) {
                    responsableIdFound = true
                }
                formScope.responsable.options.set(i++, { id: option.id, name: option.name })
            }
            formScope.responsable.options.length = i

            if (!responsableIdFound) {
                formScope.responsable.value = ''
            }
        } else {
            formScope.responsable.value = ''
            formScope.responsable.options.length = 0
        }
    }

    private async refresh() {
        const { formScope } = this
        formScope.customerName = this.contact.customer.name
        formScope.customerPhone = this.contact.customer.phone
    }

    @action()
    protected async onCancel() {
        await this.close()
    }

    @action()
    private async onGenerateTicket() {
        const { formScope } = this
        const request: ChatsGenerateTicketRequest = {
            account: this.contact.customer.account,
            channel: this.contact.channel,
            contactId: this.contact.id,
            customerPhone: this.contact.customer.phone,
            customerName: this.contact.customer.name,
            companyId: formScope.company.value ?? -1,
            ticketTypeId: formScope.ticketType.value ?? '',
            sourceId: formScope.source.value ?? -1,
            mediaId: formScope.media.value ?? -1
        }

        if (formScope.acceptCustomResponsable) {
            request.respectDistribution = true
        } else {
            const responsableUserId = formScope.responsable.value
            if (responsableUserId) {
                request.userId = responsableUserId
            }
        }

        const missingValuesMessage: string[] = []

        if (!request.customerPhone) {
            missingValuesMessage.push(texts.CHATS_FORM_TICKET_GENERATION_MISSING_CUSTOMER)
        }

        if (request.companyId < 0 || Number.isNaN(request.companyId)) {
            missingValuesMessage.push(texts.CHATS_FORM_TICKET_GENERATION_MISSING_COMPANY)
        }

        if (lodash.isEmpty(request.ticketTypeId)) {
            missingValuesMessage.push(texts.CHATS_FORM_TICKET_GENERATION_MISSING_TICKET)
        }

        if (request.sourceId < 0 || Number.isNaN(request.sourceId)) {
            missingValuesMessage.push(texts.CHATS_FORM_TICKET_GENERATION_MISSING_SOURCE)
        }

        if (request.mediaId < 0 || Number.isNaN(request.mediaId)) {
            missingValuesMessage.push(texts.CHATS_FORM_TICKET_GENERATION_MISSING_MEDIA)
        }

        if (missingValuesMessage.length > 0) {
            this.alertHolder?.alert('error', texts.CHATS_FORM_TICKET_GENERATION_ERROR, missingValuesMessage.join('\n'))
            return
        }

        formScope.generatingTicket = true
        try {
            const response = await chatsService.generateTicket(request)
            if (response.status === ChatsGenerateTicketStatus.OK) {
                this.alertHolder?.instantAlert('success', texts.CHATS_FORM_TICKET_GENERATED_WITH_SUCCESS)

                const keys = KeysFactory.chats(this.app)
                keys.contactId(this.contact.id)
                keys.refresh(true)
                await this.flipToIntent(keys.intent)
            } else {
                switch (response.status) {
                    case ChatsGenerateTicketStatus.TICKET_TYPE_NOT_FOUND:
                        break
                    case ChatsGenerateTicketStatus.SOURCE_NOT_FOUND:
                        break
                    case ChatsGenerateTicketStatus.MEDIA_NOT_FOUND:
                        break
                    case ChatsGenerateTicketStatus.RESPONSABLE_NOT_FOUND:
                        break
                    case ChatsGenerateTicketStatus.COMPANY_NOT_FOUND:
                        break
                    case ChatsGenerateTicketStatus.VEHICLE_NOT_FOUND:
                        break
                }

                this.alertHolder?.instantAlert('error', response.message ?? 'Unexpected error')
            }
        } catch (error) {
            this.alertHolder?.alert('error', texts.CHATS_ERROR_TITLE_GENERATE_TICKET, `${texts.CHATS_ERROR_TEXT_GENERATE_TICKET_USER_FORBIDDEN}`)
            formScope.generatingTicket = false
        } finally {
            formScope.generatingTicket = false
        }
    }

    private async close() {
        if (!this.disposed) {
            const keys = KeysFactory.chats(this.app)
            await this.flipToIntent(keys.intent)
        }
    }

    @action()
    private async onChangeCustomerName(evt: TextChangeEvent) {
        const value = evt.target.value

        if (value !== null || value !== undefined) {
            this.formScope.customerName = value
        } else {
            this.formScope.customerName = ''
        }
    }

    @action()
    private async onChangeCustomerPhone(evt: TextChangeEvent) {
        const value = evt.target.value

        if (value !== null || value !== undefined) {
            this.formScope.customerPhone = value
        } else {
            this.formScope.customerPhone = ''
        }
    }

    @action()
    private async onChangeCompany(evt: TextChangeEvent) {
        const { formScope } = this
        const value = +evt.target.value

        if (formScope.company.value !== value) {
            formScope.company.value = value
            formScope.ticketType.value = null
            formScope.source.value = null
            formScope.media.value = null
            formScope.responsable.value = null
            await this.refreshTicketOptions()
            await this.refreshSourceOptions()
            await this.refreshMediaOptions()
            await this.refreshResponsableOptions()
        }
    }

    @action()
    private async onChangeTicket(evt: TextChangeEvent) {
        this.formScope.ticketType.value = evt.target.value
    }

    @action()
    private async onChangeSource(evt: TextChangeEvent) {
        const { formScope } = this
        const value = +evt.target.value
        if (formScope.source.value !== value) {
            formScope.source.value = value
            formScope.media.value = null
            await this.refreshMediaOptions()
        }
    }

    @action()
    private async onChangeMedia(evt: TextChangeEvent) {
        this.formScope.media.value = +evt.target.value
    }

    @action()
    private async onChangeResponsable(evt: TextChangeEvent) {
        this.formScope.responsable.value = evt.target.value
    }

    @action()
    private async onToggleAcceptCustomResponsable() {
        const acceptCustomResponsable = !this.formScope.acceptCustomResponsable
        this.formScope.acceptCustomResponsable = acceptCustomResponsable
        this.formScope.responsable.disabled = acceptCustomResponsable
    }
}

const CacheHelper = (function () {
    const fnCompanyKey = (account: string) => `bde014f7-c259-4bea-8482-764425190ef5:${account}`
    const fnTicketKey = (account: string, companyId: number) =>
        `8c9841b7-e745-4148-ab50-8efeec64aaa8:${account}:${companyId}`
    const fnSourceKey = (account: string, companyId: number) =>
        `56d79158-caff-4c1a-940d-f4f0625947d6:${account}:${companyId}`
    const fnResponsableKey = (account: string, companyId: number) =>
        `e9799074-9c72-4d26-acf7-b1a786b45067:${account}:${companyId}`
    const fnMediaKey = (account: string, companyId: number, sourceId: number) =>
        `c67784a7-43e1-4bc5-bedc-749e96c57014:${account}:${companyId}:${sourceId}`

    return {
        company: {
            get: (optionsCache: Map<string, Map<unknown, unknown>>, account: string) => {
                const key = fnCompanyKey(account)
                return optionsCache.get(key) as Map<number, ChatCompanyOption> | undefined
            },

            set: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                options: Map<number, ChatCompanyOption>
            ) => {
                const key = fnCompanyKey(account)
                optionsCache.set(key, options)
            }
        },

        ticket: {
            get: (optionsCache: Map<string, Map<unknown, unknown>>, account: string, companyId: number) => {
                const key = fnTicketKey(account, companyId)
                return optionsCache.get(key) as Map<string, ChatTicketOption> | undefined
            },
            set: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                companyId: number,
                options: Map<string, ChatTicketOption>
            ) => {
                const key = fnTicketKey(account, companyId)
                optionsCache.set(key, options)
            }
        },

        source: {
            get: (optionsCache: Map<string, Map<unknown, unknown>>, account: string, companyId: number) => {
                const key = fnSourceKey(account, companyId)
                return optionsCache.get(key) as Map<number, ChatSourceOption> | undefined
            },
            set: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                companyId: number,
                options: Map<number, ChatSourceOption>
            ) => {
                const key = fnSourceKey(account, companyId)
                optionsCache.set(key, options)
            }
        },

        media: {
            get: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                companyId: number,
                sourceId: number
            ) => {
                const key = fnMediaKey(account, companyId, sourceId)
                return optionsCache.get(key) as Map<number, ChatMediaOption> | undefined
            },
            set: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                companyId: number,
                sourceId: number,
                options: Map<number, ChatMediaOption>
            ) => {
                const key = fnMediaKey(account, companyId, sourceId)
                optionsCache.set(key, options)
            }
        },

        responsable: {
            get: (optionsCache: Map<string, Map<unknown, unknown>>, account: string, companyId: number) => {
                const key = fnResponsableKey(account, companyId)
                return optionsCache.get(key) as Map<string, ChatResponsableOption> | undefined
            },
            set: (
                optionsCache: Map<string, Map<unknown, unknown>>,
                account: string,
                companyId: number,
                options: Map<string, ChatResponsableOption>
            ) => {
                const key = fnResponsableKey(account, companyId)
                optionsCache.set(key, options)
            }
        }
    }
})()
