import { Logger } from 'wdc-cube'
import * as DateFnsNs from 'date-fns'
import * as DateFnsTzNs from 'date-fns-tz'

export type { Day, Duration, Interval, Locale } from 'date-fns'
export type { OptionsWithTZ } from 'date-fns-tz'

const LOG = Logger.get('date-fns')

function clone(source: Date) {
    return DateFnsTzNs.toDate(source)
}

function parseISOSafely(iso8601Value: string | undefined, defaultValue: Date) {
    if (!iso8601Value) {
        return defaultValue
    }

    const value = DateFnsNs.parseISO(iso8601Value)
    if (DateFnsNs.isValid(value)) {
        return value
    } else {
        LOG.error(`Invalid ISO value ${iso8601Value}`)
        return defaultValue
    }
}

function parseValidISO(iso8601Value: string | undefined) {
    if (!iso8601Value) {
        return undefined
    }

    const value = DateFnsNs.parseISO(iso8601Value)
    if (DateFnsNs.isValid(value)) {
        return value
    } else {
        LOG.error(`Invalid ISO value ${iso8601Value}`)
        return undefined
    }
}

export const DateFns = {
    clone,
    parseISOSafely,
    parseValidISO,
    ...DateFnsNs,
    ...DateFnsTzNs
}
