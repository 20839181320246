import { HttpError, lodash } from '@syonet/lang'
import { HttpClientAPI, CaughtException } from '@whatsapp/communication'
import { AxiosResponse } from 'axios'
import { AxiosProviderService } from './AxiosProviderService'
import { EurekaService } from './EurekaService'

// @inject
const eurekaService = EurekaService.singleton()

// @inject
const axiosProviderService = AxiosProviderService.singleton()

export abstract class BaseAdminService {
    public static setReloadAction(reloadAction: () => void) {
        HttpClientAPIImpl.singleton().setReloadAction(reloadAction)
    }

    public get httpClient(): HttpClientAPI {
        return HttpClientAPIImpl.singleton()
    }

    protected newSend<REQ, RESP>(path: string) {
        const httpClient = HttpClientAPIImpl.singleton()
        const urlPath = `/${path}`
        return async (request: REQ): Promise<RESP> => {
            const url = new URL(urlPath, httpClient.endpoint)
            const response = await httpClient.fetchPost<RESP>(url, 200, request)
            this.raiseUnexpected(response)
            return response
        }
    }

    protected raiseUnexpected(response: unknown) {
        const caught = response as CaughtException
        if (lodash.isNumber(caught.err) && caught.err !== 0 && caught.err !== 200) {
            throw new Error(`${caught.err}: ${caught.msg ?? ''}`)
        }
    }
}

class HttpClientAPIImpl implements HttpClientAPI {
    private static readonly INSTANCE = new HttpClientAPIImpl()

    public static singleton() {
        return HttpClientAPIImpl.INSTANCE
    }

    private reloadAction = () => {
        //window.location.reload()
    }

    get endpoint(): string {
        return eurekaService.getSyoWhatsAppBusEndPoint()
    }

    setReloadAction(reloadAction: () => void) {
        this.reloadAction = reloadAction
    }

    async fetchGet<T>(url: URL, expectedStatusCode: number): Promise<T> {
        let response: AxiosResponse
        try {
            response = await axiosProviderService.getAxios().get(url.toString())
        } catch (caught: unknown) {
            const message = (caught as Error).message
            throw new HttpError(500, message)
        }

        if (response.status !== expectedStatusCode) {
            throw new HttpError(response.status, response.statusText)
        }

        if (response.data.err) {
            throw new HttpError(response.status, `Error ${response.data.err}: ${response.data.msg}`)
        }

        return response.data as T
    }

    async fetchPost<T>(url: URL, expectedStatusCode: number, obj?: unknown): Promise<T> {
        let response: AxiosResponse
        try {
            if (obj ?? false) {
                response = await axiosProviderService.getAxios().post(url.toString(), obj)
            } else {
                response = await axiosProviderService.getAxios().post(url.toString())
            }
        } catch (caught: unknown) {
            const exn = caught as Record<string, unknown>
            if (exn.response) {
                response = exn.response as AxiosResponse
            } else {
                const message = String(exn.message)
                throw new HttpError(500, message || 'Internal Server Error')
            }
        }

        if (response.status === 401) {
            this.reloadAction()
        }

        if (response.status === 403) {
            console.error('O usuário não possui as devidas permissões.')
        }

        if (response.status !== expectedStatusCode) {
            throw new HttpError(response.status, response.statusText)
        }

        if (response.data.err) {
            throw new HttpError(response.data.err, `Error ${response.data.err}: ${response.data.msg}`)
        }

        return response.data as T
    }
}
