import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@mui/material'
import { CacheProvider as EmotionCacheProvider } from '@emotion/react'
import { TssCacheProvider } from 'tss-react/cache'
import { PageHistoryManager } from 'wdc-cube-react'
import { getOrCreateTheme, getOrCreateThemeCache } from 'src/theme'
import { MainPresenter } from './feature/main'
import { registerViews as registerWaitingViews } from './components/waiting/views'
import { registerViews as registerSectionHeaderViews } from './components/sectionheader/views'
import { registerViews as registerMultiListingViews } from './components/multilist/views'
import { registerViews as registerMultiSelectionViews } from './components/multiselect/views'
import { registerViews as registerChipPanelViews } from './components/chippanel/views'
import { registerViews as registerDateTimePickerViews } from './components/datetimepicker/views'
import { registerViews as registerTimePickerViews } from './components/timepicker/views'
import { registerViews as registerRichEditorViews } from './components/richeditor/views'
import { registerViews as registerWhatsAppViews } from './components/whatsapp/views'
import { registerViews as registerMainViews, MainView } from './feature/main/views'
import { registerViews as registerDashboardViews } from './feature/dashboard/views'
import { registerViews as registerChatsViews } from './feature/chats/views'
import { registerViews as registerChannelViews } from './feature/channel/views'
import './index.css'

registerWaitingViews()
registerSectionHeaderViews()
registerMultiListingViews()
registerMultiSelectionViews()
registerChipPanelViews()
registerDateTimePickerViews()
registerTimePickerViews()
registerRichEditorViews()
registerWhatsAppViews()
registerMainViews()
registerDashboardViews()
registerChatsViews()
registerChannelViews()

function App() {
    const presenter = React.useMemo(() => new MainPresenter(new PageHistoryManager(true)), [])

    React.useEffect(() => {
        presenter.boot()
        return () => {
            presenter.release()
        }
    }, [presenter])

    return (
        <EmotionCacheProvider value={getOrCreateThemeCache()}>
            <TssCacheProvider value={getOrCreateThemeCache()}>
                <ThemeProvider theme={getOrCreateTheme()}>
                    <MainView scope={presenter.scope} />
                </ThemeProvider>
            </TssCacheProvider>
        </EmotionCacheProvider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
