import { OptionsQuickReply } from './options'

export type QuickReplyDTO = {
    name: string
    slug: string
    description: string
    domain: string
    template: Pick<TemplateQuickReply, 'customTemplate'>
    target: QuickReplyTarget
    defaultQuickReply: DefaultQuickReply[]
    createdBy?: string
    editedBy?: string
    createdAt?: Date
    updatedAt?: Date
}

export declare type TemplateQuickReply = {
    customTemplate: string
    originalTemplate: string
    preview: string
}
export declare type DefaultQuickReply = {
    key: string
    value: string
}
export declare type QuickReplyTarget = {
    type: string
    targetValues: unknown[]
    child?: QuickReplyTarget
}

export type ResponseQuickReply = {
    options: OptionsQuickReply
    configured: QuickReplyDTO[]
}

export type RequestQuickReply = QuickReplyDTO & { _id?: string }

const Path = 'adminV2/quick-reply'

export const QUICK_REPLY_LIST_URL = `${Path}/list`
export const QUICK_REPLY_CREATE_URL = `${Path}/create`
export const QUICK_REPLY_UPDATE_URL = `${Path}/update`
export const QUICK_REPLY_DELETE_URL = `${Path}/delete`
