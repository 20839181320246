export function NOOP_VOID() {
    // NOOP
}

export function NOOP_STRING() {
    return ''
}

export function NOOP_FALSE() {
    return false
}

export async function NOOP_PROMISE_VOID() {
    // NOOP
}
