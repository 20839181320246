/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AdminChannelConfiguration as NS } from '@whatsapp/communication'
import { BaseAdminService } from 'src/services'
import { Logger, ServiceLike } from 'wdc-cube'

const LOG = Logger.get('ChannelConfigurationService')

// :: Re-exporting types and consts because on commonjs there is no Namespace

export type ChannelConfigurationValidator = NS.ChannelConfigurationValidator
export const ChannelConfigurationValidator = NS.ChannelConfigurationValidator

export type ChannelMenuType = NS.ChannelMenuType
export const ChannelMenuType = NS.ChannelMenuType

// :: Listing

export type ValidationType = NS.ValidationType
export type ChannelConfigInfo = NS.ChannelConfigInfo
export type ChannelInfoListing = NS.ChannelInfoListing
export type ChannelValidationStatus = NS.ChannelValidationStatus

type ChannelConfigListingRequest = NS.ChannelConfigListingRequest

// :: General Tab

export type CompanyChannelConfig = NS.CompanyChannelConfig
export type GeneralChannelConfig = NS.GeneralChannelConfig
export type Company = GeneralChannelConfig['companies'][0]
export type InternalConfiguration = Company['internal'] & { id: Company['companyId'] }

// :: Menu Tab

export type ChannelMenuEventOption = NS.ChannelMenuEventOption
export type ChannelMenuEntry = NS.ChannelMenuEntry
export type ChannelMenuItem = NS.ChannelMenuItem
export type ChannelMenuConfig = NS.ChannelMenuConfig

// :: Messages Tab

export type ChannelMessagesConfig = NS.ChannelMessagesConfig

// :: Working Hours Tab

export type HourMinuteTime = NS.HourMinuteTime
export type PeriodTime = NS.PeriodTime
export type ChannelCompaniesWorkingHours = NS.ChannelCompaniesWorkingHours
export type ChannelWorkingHoursConfig = NS.ChannelWorkingHoursConfig

// :: Extras Tab

export type ChannelExtrasConfig = NS.ChannelExtrasConfig

// :: Hole Form

export type ChannelConfig = NS.ChannelConfig
export type ChannelConfigResponse = NS.ChannelConfigResponse
export type ChannelCompanyOption = NS.ChannelCompanyOption

// :: The Service

export class ChannelConfigurationService extends BaseAdminService implements ServiceLike {
    private static readonly INSTANCE = new ChannelConfigurationService()

    public static singleton() {
        return ChannelConfigurationService.INSTANCE
    }

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    // :: Instance

    private __initialized = false

    public get name() {
        return 'ChannelConfigService'
    }

    public get initialized(): boolean {
        return this.__initialized
    }

    public async postConstruct() {
        this.__initialized = true
        LOG.info('Initialized')
    }

    public async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    // :: API

    public async fetchListing(channelName?: string, channelNumber?: string): Promise<ChannelInfoListing> {
        const request: ChannelConfigListingRequest = {}

        if (channelName) {
            request.channelName = channelName
        }

        if (channelNumber) {
            request.channelNumber = channelNumber
        }

        const response = await this.send({ query: { listing: request } })

        const listing = response.query!.listing!
        this.raiseUnexpected(listing)

        return listing
    }

    public async fetchForm(channelId: string) {
        return (
            await this.send({
                query: {
                    form: { id: channelId }
                }
            })
        ).query!.form!
    }

    public async discardChangesAndFetchForm(channelId: string) {
        const { discard } = (
            await this.send({
                mutation: {
                    discard: { id: channelId, returnForm: true }
                }
            })
        ).mutation!

        this.raiseUnexpected(discard)

        if (!discard) {
            throw new Error('Missing discard response')
        }

        if (discard.status !== 0) {
            throw new Error((discard.message ?? []).join('\n'))
        }

        const { form } = discard
        this.raiseUnexpected(form)

        return form!
    }

    public async saveForm(channelId: string, data: ChannelConfig, publish = false) {
        const { form } = (
            await this.send({
                mutation: {
                    form: { id: channelId, publish, data }
                }
            })
        ).mutation!

        this.raiseUnexpected(form)

        return form!
    }
}
