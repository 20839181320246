import CloseIcon from '@mui/icons-material/Close'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PersonIcon from '@mui/icons-material/Person'
import {
    Divider,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { convertDateToBeautifulString, convertToPhoneMask } from 'src/utils'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import {
    DashboardActiveMessagesDetailedScope,
    MessageItemScope,
    MessageTableScope
} from '../DashboardActiveMessagesDetailed.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeDashboardCustomerActiveMessagesStyles } from './Dashboard.styles'

// @Inject
const texts = TextsProvider.get()

// :: Component(DetailReportContent)

export type DashboardActiveMessagesDetailedViewProps = IViewProps & {
    scope: DashboardActiveMessagesDetailedScope
}

export function DashboardActiveMessagesDetailedView({ className, scope }: DashboardActiveMessagesDetailedViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeDashboardCustomerActiveMessagesStyles()

    let content: JSX.Element
    if (scope.loading) {
        content = <LinearProgress className={styles.adjustContentSpacing} color="secondary" />
    } else {
        content = <DetailedUserActiveMessageTable className={styles.adjustContentSpacing} scope={scope.messageTable} />
    }

    return (
        <div className={clsx(styles.view, className)}>
            <span className={styles.hbox}>
                <Typography variant="h6" className={styles.activeMessageDetailedFormTitle}>
                    <PersonIcon fontSize="large" />
                    <span>{scope.userName}</span>
                </Typography>
                <span className={styles.activeMessageDetailedFormClose}>
                    <Tooltip title={texts.ACTIVE_USER_MESSAGES_MODAL_CLOSE_BUTTON}>
                        <IconButton onClick={scope.onClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </span>
            </span>
            <Divider />
            <div className={styles.body}>
                <div className={styles.hbox}>
                    <Typography variant="h5">{texts.ACTIVE_USER_MESSAGES_MODAL_TITLE}</Typography>
                    <span>
                        <Tooltip title={texts.ACTIVE_USER_MESSAGES_MODAL_EXPORT_BUTTON}>
                            <IconButton onClick={scope.onExportToXLS}>
                                <FileCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>
                {content}
            </div>
        </div>
    )
}

// :: Component(DetailedUserActiveMessageTable)

type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

type DetailedUserActiveMessageTableProps = {
    className?: string
    scope: MessageTableScope
}

function DetailedUserActiveMessageTable({ className, scope }: DetailedUserActiveMessageTableProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeDashboardCustomerActiveMessagesStyles()

    return (
        <>
            <TableContainer className={clsx(styles.tableContainer, className)}>
                <Table stickyHeader size="small" className={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{texts.ACTIVE_USER_MESSAGES_MODAL_ATTENDANCE_NUMBER}</TableCell>
                            <TableCell>{texts.ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_NAME}</TableCell>
                            <TableCell>{texts.ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_PHONE}</TableCell>
                            <TableCell>{texts.ACTIVE_USER_MESSAGES_MODAL_SENT_DATE}</TableCell>
                            <TableCell>{texts.ACTIVE_USER_MESSAGES_MODAL_TEXT_MESSAGE}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scope.messages.map((record, i) => (
                            <DetailedUserActiveMessageReportRow key={i} scope={record} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={scope.itemsPerPageOptions}
                component="div"
                count={scope.itemCount}
                rowsPerPage={scope.itemsPerPage}
                page={scope.pageIndex}
                onPageChange={scope.onChangePageIndex as unknown as PageChangeEventHandler}
                onRowsPerPageChange={scope.onChangeItemsPerPage}
                labelRowsPerPage={texts.ACTIVE_USER_MESSAGES_MODAL_ROWS_PER_PAGE}
            />
        </>
    )
}

// :: Component(DetailedUserActiveMessageReportRow)

type DetailedUserActiveMessageReportRowProps = {
    scope: MessageItemScope
}

function DetailedUserActiveMessageReportRow({ scope }: DetailedUserActiveMessageReportRowProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeDashboardCustomerActiveMessagesStyles()

    return (
        <TableRow>
            <TableCell className={styles.ticketId}>{scope.ticketId}</TableCell>
            <TableCell className={styles.customerName}>{scope.customerName}</TableCell>
            <TableCell className={styles.customerPhone}>{convertToPhoneMask(scope.customerPhone)}</TableCell>
            <TableCell className={styles.sendDate}>{convertDateToBeautifulString(scope.sendDate)}</TableCell>
            <TableCell>{scope.text}</TableCell>
            <TableCell />
        </TableRow>
    )
}
