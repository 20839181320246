import { ChannelErrorLocation } from 'src/Constants'
import { AbstractValidationContentPresenter } from './AbstractValidationContentPresenter'
import { ChannelConfigurationFormPresenter } from './ChannelConfigurationForm.presenter'

export class ChannelConfigurationFormValidator extends AbstractValidationContentPresenter {
    public constructor(owner: ChannelConfigurationFormPresenter) {
        super(owner)
    }

    public override get owner() {
        return super.owner as ChannelConfigurationFormPresenter
    }

    protected override async onChannelNameClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.CHANNEL_NAME)
    }

    protected override async onCompanyAssigmentClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.COMPANY)
    }

    protected override async onCompanySegmentationClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.COMPANY_SEGMENT)
    }

    protected override async onMenuDefinitionsClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.MENU)
    }

    protected override async onMessageValidationClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.MESSAGE)
    }

    protected override async onWorkingHourValidationClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.HOURS)
    }

    protected override async onWorkingHourExisting24Clicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.HOURS24)
    }

    protected override async onExtrasValidationClicked() {
        this.__closeAction()
        this.owner.gotoErrorLocation(ChannelErrorLocation.EXTRA)
    }
}
