import {
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select
} from '@mui/material'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AllItemScope, ItemScope, MultiSelectionScope } from '../MultiSelection.scopes'
import { getOrMakeStyles } from './MultiSelection.styles'

// :: Component(MultiSelectionView)

type MultiSelectionViewProps = IViewProps & {
    scope: MultiSelectionScope
}

export function MultiSelectionView({ className, scope }: MultiSelectionViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    // This use is in favor of performance, because it avoid unecessary repaint
    const renderValueCb = useCallback(
        MultiSelectorView_renderValue.bind(undefined, scope, styles.selectedValuesDescription),
        [scope]
    )

    const labelId = scope.uid + '-label'

    return (
        <div className={className}>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id={labelId} error={scope.error ? true : false}>
                    {scope.description}
                </InputLabel>
                <Select
                    value={scope.selectedIndexes}
                    labelId={labelId}
                    input={<OutlinedInput label={scope.description} />}
                    renderValue={renderValueCb}
                    error={scope.error ? true : false}
                    multiple
                >
                    <div className={styles.content}>
                        {scope.entries.map((itemScope, index) => (
                            <MyMenuItem key={index} index={index} scope={itemScope} />
                        ))}
                    </div>
                </Select>
            </FormControl>
        </div>
    )
}

function MultiSelectorView_renderValue(scope: MultiSelectionScope, className: string): React.ReactNode {
    return <span className={className}>{scope.selectedDescription}</span>
}

// :: Component(MyMenuItem)

type MyMenuItemProps = {
    index: number
    scope: ItemScope
}

export const MyMenuItem = React.forwardRef<HTMLLIElement, MyMenuItemProps>(function MyMenuItemRaw(
    { index, scope }: MyMenuItemProps,
    ref
) {
    bindUpdate(React, scope)
    // hack used to avoid not rerender component
    const [, setUpdate] = React.useState(0)

    const { classes: styles } = getOrMakeStyles()

    let description: JSX.Element
    if (scope instanceof AllItemScope) {
        description = (
            <span className={clsx(styles.filterAllItem, scope.inUse ? styles.inUseItem : undefined)}>
                <span className={styles.filterAllItemDescription}>{scope.description}</span>
                <span className={styles.filterAllItemSubdescription}>({scope.subdescription})</span>
            </span>
        )
    } else {
        description = <span className={scope.inUse ? styles.inUseItem : undefined}>{scope.description}</span>
    }

    return (
        <div>
            <MenuItem
                ref={ref}
                value={index}
                onClick={() => {
                    scope.onSelectionToggled()
                    setUpdate((value) => value + 1)
                }}
            >
                <Checkbox checked={scope.selected} color="secondary" />
                <ListItemText primary={description} />
            </MenuItem>
            {scope instanceof AllItemScope ? <Divider /> : undefined}
        </div>
    )
})
