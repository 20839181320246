const captilizeName = (function () {
    // eslint-disable-next-line no-control-regex
    const wordSepExp = /(?:^|\s|['`\u2018\u2019.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g
    return function capitalizeName(s: string) {
        return s ? s.toLocaleLowerCase().replace(wordSepExp, (a) => a.toUpperCase()) : s
    }
})()

const onlyNumbers = (function () {
    const onlyNumbersExp = /\D+/g
    return function onlyNumbers(s: string) {
        return s ? s.replace(onlyNumbersExp, '') : s
    }
})()

const formatPhone = (function () {
    const phoneExp = /(\d{2})(\d{2})(\d{5})(\d{3})/

    return function formatPhoneNumber(phone: string) {
        if (phone) {
            const onlyPhoneNumbers = onlyNumbers(phone)
            if (onlyPhoneNumbers) {
                return `${onlyPhoneNumbers.replace(phoneExp, '$2 $3-$4')}`
            } else {
                return ''
            }
        } else {
            return phone
        }
    }
})()

const formatPhoneV2 = (function () {
    const phoneExp08 = /(\d{4})(\d{4})/
    const phoneExp09 = /(\d{5})(\d{3})/
    const phoneExp10 = /(\d{2})(\d{4})(\d{3})/
    const phoneExp11 = /(\d{2})(\d{5})(\d{3})/
    const phoneExp12 = /(\d{2})(\d{2})(\d{4})(\d{3})/
    const phoneExp13 = /(\d{2})(\d{2})(\d{5})(\d{3})/

    return function formatPhoneNumber(phone: string) {
        if (phone) {
            const onlyPhoneNumbers = onlyNumbers(phone)
            if (onlyPhoneNumbers) {
                if (onlyPhoneNumbers.length === 13) {
                    return `${onlyPhoneNumbers.replace(phoneExp13, '+$1 ($2) $3-$4')}`
                }

                if (onlyPhoneNumbers.length === 12) {
                    return `${onlyPhoneNumbers.replace(phoneExp12, '+$1 ($2) $3-$4')}`
                }

                if (onlyPhoneNumbers.length === 11) {
                    return `${onlyPhoneNumbers.replace(phoneExp11, '($1) $2-$3')}`
                }

                if (onlyPhoneNumbers.length === 10) {
                    return `${onlyPhoneNumbers.replace(phoneExp10, '($1) $2-$3')}`
                }

                if (onlyPhoneNumbers.length === 9) {
                    return `${onlyPhoneNumbers.replace(phoneExp09, '$1-$2')}`
                }

                if (onlyPhoneNumbers.length === 8) {
                    return `${onlyPhoneNumbers.replace(phoneExp08, '$1-$2')}`
                }
            }
        }
        return phone
    }
})()

const escapeRegExp = (function () {
    // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex

    // Referring to the table here:
    // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/regexp
    // these characters should be escaped
    // \ ^ $ * + ? . ( ) | { } [ ]
    // These characters only have special meaning inside of brackets
    // they do not need to be escaped, but they MAY be escaped
    // without any adverse effects (to the best of my knowledge and casual testing)
    // : ! , =
    // my test "~!@#$%^&*(){}[]`/=?+\|-_;:'\",<.>".match(/[\#]/g)

    const specials = [
        // order matters for these
        '-',
        '[',
        ']',
        // order doesn't matter for any of these
        '/',
        '{',
        '}',
        '(',
        ')',
        '*',
        '+',
        '?',
        '.',
        '\\',
        '^',
        '$',
        '|'
    ]

    // I choose to escape every character with '\'
    // even though only some strictly require it when inside of []
    const regex = RegExp('[' + specials.join('\\') + ']', 'g')

    return function escapeRegExp(str: string) {
        return str.replace(regex, '\\$&')
    }
})()

const removeDiacritics = (function () {
    const exp = /\p{Diacritic}/gu
    return function replaceDiacritics(str: string) {
        return str.normalize('NFD').replace(exp, '')
    }
})()

const replaceSpecialCharacters = (text: string) => {
    const regex = /\*/g
    return `${text || ''}`.replace(regex, '')
}

export const Strings = {
    captilizeName,
    removeDiacritics,
    onlyNumbers,
    formatPhone,
    formatPhoneV2,
    escapeRegExp,
    replaceSpecialCharacters
}
