import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LoadingButton } from '@mui/lab'
import { Button, Link } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import {
    ValidationContentScope,
    ValidationContinueActionScope,
    ValidationMessageScope,
    ValidationPublishActionScope
} from '../ChannelConfigurationAlert.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeValidationAlertStyles as getOrMakeStyles } from './Alert.styles'

// @Inject
const texts = TextsProvider.get()

export type AlertValidationContentViewProps = IViewProps & {
    scope: ValidationContentScope
}

export function AlertValidationContentView({ className, scope }: AlertValidationContentViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustText} dangerouslySetInnerHTML={{ __html: scope.information }} />
            <div>
                <SuccessAlertValidationContentView scope={scope} />
                <ErrorAlertValidationContentView scope={scope} />
                <WarningAlertValidationContentView scope={scope} />
            </div>
            {scope.actions instanceof ValidationContinueActionScope ? (
                <ValidationContinueActionView scope={scope.actions as ValidationContinueActionScope} />
            ) : scope.actions instanceof ValidationPublishActionScope ? (
                <ValidationPublishActionView scope={scope.actions as ValidationPublishActionScope} />
            ) : (
                <></>
            )}
        </div>
    )
}

// :: Component(SuccessAlertValidationContentView)

type SuccessAlertValidationContentViewProps = IViewProps & {
    scope: ValidationContentScope
}

function SuccessAlertValidationContentView({ className, scope }: SuccessAlertValidationContentViewProps) {
    const { classes: styles } = getOrMakeStyles()

    return scope.successes.length > 0 ? (
        <div className={clsx(className)}>
            <div className={styles.adjustValidationStatus}>
                <div>
                    <CheckCircleOutlineIcon color="success" />
                </div>
                <div className={styles.adjustStatusRelativeToIcon}>
                    <strong>{texts.CHANNEL_CONFIGURATION_SUCCESS_ALERT_VALIDATION_CONTENT_TITLE}</strong>
                </div>
            </div>
            <ul className={styles.alertBody}>
                {scope.successes.map((messageScope) => (
                    <li key={messageScope.id} className={styles.alertContent}>
                        <ValidationMessageView scope={messageScope} />{' '}
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        <></>
    )
}

// :: Component(ErrorAlertValidationContentView)

type ErrorAlertValidationContentViewProps = IViewProps & {
    scope: ValidationContentScope
}

function ErrorAlertValidationContentView({ className, scope }: ErrorAlertValidationContentViewProps) {
    const { classes: styles } = getOrMakeStyles()

    return scope.errors.length > 0 ? (
        <div className={clsx(className)}>
            <div className={styles.adjustValidationStatus}>
                <div>
                    <WarningAmberIcon color="error" />
                </div>
                <div className={styles.adjustStatusRelativeToIcon}>
                    <p>
                        <strong>{texts.CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TITLE}</strong>
                        <span className={styles.secondaryTitleText}>
                            {texts.CHANNEL_CONFIGURATION_ERROR_ALERT_VALIDATION_CONTENT_TEXT}
                        </span>
                    </p>
                </div>
            </div>
            <ul className={styles.alertBody}>
                {scope.errors.map((messageScope) => (
                    <li key={messageScope.id} className={styles.alertContent}>
                        <ValidationMessageView scope={messageScope} />{' '}
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        <></>
    )
}

// :: Component(WarningAlertValidationContentView)

type WarningAlertValidationContentViewProps = IViewProps & {
    scope: ValidationContentScope
}

function WarningAlertValidationContentView({ className, scope }: WarningAlertValidationContentViewProps) {
    const { classes: styles } = getOrMakeStyles()

    return scope.warnings.length > 0 ? (
        <div className={clsx(className)}>
            <div className={styles.adjustValidationStatus}>
                <div>
                    <InfoOutlinedIcon color="warning" />
                </div>
                <div className={styles.adjustStatusRelativeToIcon}>
                    <strong>{texts.CHANNEL_CONFIGURATION_WARNING_ALERT_VALIDATION_CONTENT_TITLE}</strong>
                </div>
            </div>
            <ul className={styles.alertBody}>
                {scope.warnings.map((messageScope) => (
                    <li key={messageScope.id} className={styles.alertContent}>
                        <ValidationMessageView scope={messageScope} />
                    </li>
                ))}
            </ul>
        </div>
    ) : (
        <></>
    )
}

// :: Component(ValidationMessageView)

type ValidationMessageViewProps = IViewProps & {
    scope: ValidationMessageScope
}

function ValidationMessageView({ className, scope }: ValidationMessageViewProps) {
    bindUpdate(React, scope)

    return (
        <Link className={clsx(className)} component="button" variant="subtitle1" onClick={scope.onClick}>
            {scope.message}
        </Link>
    )
}

// :: Component(ValidationContinueActionView)

type ValidationContinueActionViewProps = IViewProps & {
    scope: ValidationContinueActionScope
}

function ValidationContinueActionView({ className, scope }: ValidationContinueActionViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.continueActionBar, className)}>
            <Button color="inherit" onClick={scope.onContinue}>
                {texts.CHANNEL_CONFIGURATION_VALIDATION_CLOSE_ACTION_BUTTON}
            </Button>
        </div>
    )
}

// :: Component(ValidationPublishActionView)

type ValidationPublishActionViewProps = IViewProps & {
    scope: ValidationPublishActionScope
}

function ValidationPublishActionView({ className, scope }: ValidationPublishActionViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.publishActionBar, className)}>
            <Button color="inherit" onClick={scope.onCancel}>
                {texts.CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_CLOSE_BUTTON}
            </Button>
            <LoadingButton
                variant="contained"
                color="info"
                style={{ marginLeft: 12 }}
                loading={scope.publishing}
                disabled={!scope.publishedEnabled}
                onClick={scope.onPublish}
            >
                {texts.CHANNEL_CONFIGURATION_VALIDATION_PUBLISH_ACTION_PUBLISH_BUTTON}
            </LoadingButton>
        </div>
    )
}
