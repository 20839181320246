import DateFnsUtils from '@date-io/date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DateTimePicker from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Stack, TextField } from '@mui/material'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { DateTimePickerScope } from '../DateTimerPicker.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeStyles } from './DateTimePicker.styles'

// @Inject
const texts = TextsProvider.get()

export type DateTimePickerViewProps = IViewProps & {
    scope: DateTimePickerScope
}

export function DateTimePickerView({ scope }: DateTimePickerViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DateTimePicker
                    className={styles.view}
                    ampm={false}
                    label={scope.label}
                    value={scope.value}
                    inputFormat={texts.DATE_TIME_FORMAT}
                    onChange={scope.onChange}
                    renderInput={(params) => <TextField {...params} onKeyUp={scope.onApplyFilterByPressedKey} />}
                />
            </Stack>
        </LocalizationProvider>
    )
}
