import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { DashboardActiveMessagesResumedScope } from '../DashboardActiveMessagesResumed.scopes'
import { getOrMakeResumedActiveMessagesStyles } from './Dashboard.styles'
import { DashboardActiveMessagesResumedTableView } from './DashboardActiveMessagesResumedTable.view'

export type DashboardActiveMessagesResumedProps = IViewProps & {
    scope: DashboardActiveMessagesResumedScope
}

export function DashboardActiveMessagesResumedView({ className, scope }: DashboardActiveMessagesResumedProps) {
    bindUpdate(React, scope)

    const { classes } = getOrMakeResumedActiveMessagesStyles()

    return (
        <DashboardActiveMessagesResumedTableView
            className={clsx(classes.view, className)}
            totalSentMessages={scope.quantityOfSentMessages}
            scope={scope.table}
        />
    )
}
