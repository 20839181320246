import AddIcon from '@mui/icons-material/Add'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit'
import {
    Alert,
    Button, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import voca from 'voca'
import { bindUpdate, CubeRefObject, IViewProps } from 'wdc-cube-react'
import {
    ChannelConfigurationFormMenuItemScope,
    ChannelConfigurationFormMenuScope
} from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormMenuStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationFormMenuViewProps = IViewProps & {
    scope: ChannelConfigurationFormMenuScope
}

export function ChannelConfigurationFormMenuView({ className, scope }: ChannelConfigurationFormMenuViewProps) {
    bindUpdate(React, scope)

    const { styles, addMenuButtonRef, blinkAddMenuButton } = ChannelConfigurationFormMenuViewContext.getOrCreate(scope)

    if (scope.blink === 'add-menu-button') {
        blinkAddMenuButton()
    }

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustHeader}>
                <div className={styles.adjustBlinkAndNewMenuButton}>
                    <Button
                        ref={addMenuButtonRef}
                        startIcon={<AddIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={scope.onOpenEditor}
                    >
                        <span>{texts.CHANNEL_CONFIGURATION_MENU_NEW_MENU_BUTTON}</span>
                    </Button>
                    {scope.blink === 'add-menu-button' ? (
                        <div className={styles.buttonBlink}>
                            <IconButton>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {scope.errors.length > 0 ? (
                <Alert
                    severity="error"
                    className={
                        scope.errors.length > 1 ? styles.adjustErrorMessagesWithListLayout : styles.adjustErrorMessage
                    }
                >
                    {scope.errors.length > 1 ? (
                        <ul className={styles.adjustUlElementWithinAlert}>
                            <li>{scope.errors.join(', ')}</li>
                        </ul>
                    ) : (
                        <span>{scope.errors.join(', ')}</span>
                    )}
                </Alert>
            ) : undefined}
            {scope.entries.length > 0 ? (
                <div className={styles.listing}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" className={styles.table}>
                            <TableHead className={styles.tableHeader}>
                                <TableRow>
                                    <TableCell>{texts.CHANNEL_CONFIGURATION_MENU_COMPANIES_TITLE}</TableCell>
                                    <TableCell>{texts.CHANNEL_CONFIGURATION_MENU_OPTIONS_TITLE}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scope.entries.map((item) => (
                                    <MenuRow key={item.id} scope={item} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

class ChannelConfigurationFormMenuViewContext {
    static readonly create = () => new ChannelConfigurationFormMenuViewContext()

    static getOrCreate(scope: ChannelConfigurationFormMenuScope) {
        const [ctx] = React.useState(ChannelConfigurationFormMenuViewContext.create)
        ctx.scope = scope
        ctx.styles = getOrMakeStyle().classes
        return ctx
    }

    readonly addMenuButtonRef = new CubeRefObject<HTMLButtonElement>()

    styles!: ReturnType<typeof getOrMakeStyle>['classes']
    scope!: ChannelConfigurationFormMenuScope

    readonly blinkAddMenuButton = () => {
        const { scope } = this
        const buttomElm = this.addMenuButtonRef.current
        if (buttomElm) {
            buttomElm.scrollIntoView({ behavior: 'smooth' })

            setTimeout(() => {
                scope.blink = ''
            }, 3000)
        }
    }
}

type MenuRowProps = {
    className?: string
    scope: ChannelConfigurationFormMenuItemScope
}

function MenuRow({ className, scope }: MenuRowProps) {
    bindUpdate(React, scope)

    const hasError = scope.errors.length > 0

    const { classes: styles } = getOrMakeStyle()

    return (
        <TableRow className={clsx(hasError ? styles.tableCellWithError : '', className)}>
            <TableCell>
                <span className={styles.customFont}>{scope.name}</span>
                {scope.companies.length > 0 ? (
                    <div className={styles.companiesCustomFont}>
                        {voca.sprintf(texts.CHANNEL_CONFIGURATION_MENU_COMPANIES_DESCRIPTION, scope.companies)}
                    </div>
                ) : (
                    <></>
                )}
                <div className={styles.adjustSpaceBetweenMenuAndErrorMessages}>
                    {scope.errors.map((msg, i) => (
                        <div key={i} className={styles.customFontWithError}>
                            {msg}
                        </div>
                    ))}
                </div>
            </TableCell>
            <TableCell>
                <div className={styles.menuTypeRow}>
                    {scope.eventCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_EVENT_CHIP}
                            color="#1976d2"
                            count={scope.eventCount}
                        />
                    ) : undefined}
                    {scope.subMenuCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_MENU_SUB_MENU_TYPE}
                            color="#256"
                            count={scope.subMenuCount}
                        />
                    ) : undefined}
                    {scope.informationCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_EDITOR_INFORMATION_MENU_TYPE}
                            color="#80391e"
                            count={scope.informationCount}
                        />
                    ) : undefined}
                    {scope.linkCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_LINK_CHIP}
                            color="#9c27b0"
                            count={scope.linkCount}
                        />
                    ) : undefined}
                    {scope.robotCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_SCHEDULE_ROBOT_CHIP}
                            color="#606060"
                            count={scope.robotCount}
                        />
                    ) : undefined}
                    {scope.segmentationCount > 0 ? (
                        <CustomChip
                            label={texts.CHANNEL_CONFIGURATION_MENU_SEGMENTATION_CHIP}
                            color="#43A047"
                        />
                    ) : undefined}
                </div>
            </TableCell>
            <TableCell align="right">
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_VINCULATE_SEGMENTATION} arrow>
                    <IconButton onClick={scope.onEditMenuSegmentation}>
                        <VerticalSplitIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_EDIT_MENU_TOOLTIP} arrow>
                    <IconButton onClick={scope.onEdit}>
                        <CreateIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_DUPLICATE_MENU_TOOLTIP} arrow>
                    <IconButton onClick={scope.onDuplicate}>
                        <AddToPhotosIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={texts.CHANNEL_CONFIGURATION_MENU_DELETE_MENU_TOOLTIP} arrow>
                    <IconButton onClick={scope.onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

// :: Component(CustomChip)

type CustomChipProps = {
    className?: string
    label: string
    color: string
    count?: number
    badgeColor?: string
}

export function CustomChip({ className, label, color, count, badgeColor }: CustomChipProps) {
    const { classes: styles } = getOrMakeStyle()

    return (
        <div className={clsx(styles.chip, className)}>
            <div className={styles.chipContent} style={{ backgroundColor: color }}>
                {label}
                {count && count > 1 ? (
                    <div className={styles.chipContentIcon} style={{ backgroundColor: badgeColor || '#000' }}>
                        {count}
                    </div>
                ) : (
                    <div className={styles.chipInnerSpacing} />
                )}
            </div>
        </div>
    )
}
