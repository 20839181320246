import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

@Observable
export class AlertWarningConfirmContentScope extends Scope {
    @observe() message = ''
    @observe() cancelLabelOption?: string
    @observe() confirmLabelOption?: string
    @observe() unfocusConfirmButton?: boolean

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onConfirm = Scope.ASYNC_ACTION
}

@Observable
export class AlertWarningContentScope extends Scope {
    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class AlertErrorContentScope extends Scope {
    @observe() onClose = Scope.ASYNC_ACTION
}

// :: Validations

@Observable
export class ValidationPublishActionScope extends Scope {
    @observe() publishedEnabled = false
    @observe() publishing = false

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onPublish = Scope.ASYNC_ACTION
}

@Observable
export class ValidationContinueActionScope extends Scope {
    @observe() onContinue = Scope.ASYNC_ACTION
}

@Observable
export class ValidationMessageScope extends Scope {
    @observe() id = 0
    @observe() message = ''

    @observe() onClick = Scope.ASYNC_ACTION
}

export class ValidationContentScope extends Scope {
    readonly successes = new ObservableArray<ValidationMessageScope>(this)
    readonly warnings = new ObservableArray<ValidationMessageScope>(this)
    readonly errors = new ObservableArray<ValidationMessageScope>(this)

    @observe() information = ''
    @observe() actions?: ValidationPublishActionScope | ValidationContinueActionScope
}
