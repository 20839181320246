// :: Component(RichEditorView)

import clsx from 'clsx'
import React, { useRef } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { RichEditorScope } from '../RichEditor.scopes'
import { getOrMakeStyles } from './RichEditor.styles'

type RichEditorViewProps = IViewProps & {
    scope: RichEditorScope
}

export function RichEditorView({ className, scope }: RichEditorViewProps) {
    bindUpdate(React, scope)

    const editorContainerRef = useRef<HTMLDivElement>(null)
    const editorRef = useRef<Editor>(null)

    if (scope.focus) {
        const editorContainer = editorContainerRef.current
        const editor = editorRef.current
        if (editorContainer && editor) {
            scope.focus = false
            editorContainer.scrollIntoView({ behavior: 'smooth' })
            editor.focusEditor()
        }
    }

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.messageBox, className)}>
            <div
                className={clsx(
                    styles.editorBox,
                    scope.status?.text ? styles.editorBoxWithError : styles.editorBoxWithoutError
                )}
            >
                <div className={clsx(styles.caption, 'SyoRichEditor-Label')}>{scope.caption}</div>
                <div className={styles.editorInput} ref={editorContainerRef}>
                    <Editor
                        ref={editorRef}
                        editorState={scope.text}
                        onEditorStateChange={scope.onChanged}
                        wrapperStyle={{ display: 'flex', flexDirection: 'column-reverse' }}
                        editorClassName={styles.editorConfiguration}
                        toolbarClassName={styles.toolbarConfiguration}
                        toolbar={{
                            options: ['inline', 'emoji', 'history'],
                            inline: {
                                bold: { className: styles.toolbarButtons },
                                italic: { className: styles.toolbarButtons },
                                options: ['bold', 'italic']
                            },
                            emoji: { className: styles.toolbarButtons, popupClassName: styles.emojiPopup },
                            history: {
                                undo: { className: styles.toolbarButtons },
                                redo: { className: styles.toolbarButtons }
                            }
                        }}
                    />
                    {scope.status?.text ? (
                        <div className={styles.errorMessageRelativeToToolbar}>
                            <RichEditorErrorMessageView status={scope.status} onTagClicked={scope.onTagClicked} />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}

// :: Component(RichEditorErrorMessageView)

type RichEditorErrorMessageViewProps = {
    className?: string
    status?: { text: string; tags?: string[] } | null
    onTagClicked?: (tag: string) => void
}

export function RichEditorErrorMessageView({ className, status, onTagClicked }: RichEditorErrorMessageViewProps) {
    const { classes: styles } = getOrMakeStyles()

    if (status && status.text) {
        const tags = status?.tags ?? []

        return (
            <div className={clsx(styles.view, className)}>
                <span className={styles.adjustErrorMessage}>{status.text}</span>
                {tags.length > 0
                    ? tags.map((tag, i) => (
                          <span key={i} onClick={onTagClicked?.bind(undefined, tag)} className={styles.adjustTag}>
                              {tag}
                          </span>
                      ))
                    : undefined}
            </div>
        )
    } else {
        return <></>
    }
}
