// :: Component(DashboardCustomerServiceFilterView)

import FilterListIcon from '@mui/icons-material/FilterList'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Paper, Popover, PopoverOrigin, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React, { FormEventHandler } from 'react'
import {
    MultipleAutoCompleteNumericFieldView,
    MultipleAutoCompleteStringFieldView,
    SimpleAutoCompleteStringFieldView
} from 'src/components/autocompletefield/views'
import { NOOP_PROMISE_VOID } from 'wdc-cube'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { DashboardCustomerServiceFilterScope } from '../DashboardCustomerServiceFilter.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeCustomerServiceFilterStyles } from './Dashboard.styles'

// @Inject
const texts = TextsProvider.get()

type DashboardCustomerServiceFilterProps = IViewProps & {
    scope: DashboardCustomerServiceFilterScope
}

const anchorOrigin = { vertical: 'bottom', horizontal: 'right' } as PopoverOrigin
const transformOrigin = { vertical: 'top', horizontal: 'right' } as PopoverOrigin

export function DashboardCustomerServiceFilterView({ className, scope }: DashboardCustomerServiceFilterProps) {
    bindUpdate(React, scope)

    const [container, setContainer] = React.useState<HTMLDivElement | null>(null)

    const { classes: styles } = getOrMakeCustomerServiceFilterStyles()

    return (
        <>
            <div ref={setContainer} className={clsx(styles.filter, className)}>
                <Button
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    onClick={scope.opened ? NOOP_PROMISE_VOID : scope.onOpen}
                >
                    {texts.SERVICE_TABLE_FILTER_OPEN_BUTTON}
                </Button>
                <Popover
                    key="popover"
                    className={styles.filterPopover}
                    open={scope.opened}
                    anchorEl={container}
                    onClose={scope.onClose}
                    container={container}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    <PanelSearch scope={scope} />
                </Popover>
            </div>
        </>
    )
}

type PanelSearchProps = {
    scope: DashboardCustomerServiceFilterScope
}

function PanelSearch({ scope }: PanelSearchProps) {
    const { classes: styles } = getOrMakeCustomerServiceFilterStyles()

    return (
        <>
            <Paper sx={{ boxShadow: 3 }} className={styles.filterPopoverContent}>
                <div className={styles.filterPopoverContainer}>
                    <div className={styles.filterPopoverItem}>
                        <TextField
                            size="small"
                            label={texts.SERVICE_TABLE_FILTER_TICKET_NUMBER}
                            placeholder={texts.SERVICE_TABLE_FILTER_TICKET_NUMBER}
                            value={scope.ticketValue}
                            onInput={scope.onTicketChange as unknown as FormEventHandler<HTMLDivElement>}
                            onKeyUp={scope.onApplyFilterByPressedKey}
                        />
                    </div>

                    <div className={styles.filterPopoverItem}>
                        <SimpleAutoCompleteStringFieldView
                            scope={scope.customer}
                            label={texts.SERVICE_TABLE_FILTER_CUSTOMERS}
                            placeholder={texts.SERVICE_TABLE_FILTER_CUSTOMERS}
                            onKeyUp={scope.onApplyFilterByPressedKey}
                        />
                    </div>
                </div>
                <div className={styles.filterPopoverItem}>
                    <MultipleAutoCompleteNumericFieldView
                        scope={scope.company}
                        label={texts.SERVICE_TABLE_FILTER_COMPANIES}
                        placeholder={texts.SERVICE_TABLE_FILTER_COMPANIES}
                        onKeyUp={scope.onApplyFilterByPressedKey}
                    />
                </div>
                <div className={styles.filterPopoverItem}>
                    <MultipleAutoCompleteStringFieldView
                        scope={scope.category}
                        label={texts.SERVICE_TABLE_FILTER_CATEGORY}
                        placeholder={texts.SERVICE_TABLE_FILTER_CATEGORY}
                        onKeyUp={scope.onApplyFilterByPressedKey}
                    />
                </div>
                <div className={styles.filterPopoverItem}>
                    <MultipleAutoCompleteStringFieldView
                        scope={scope.user}
                        label={texts.SERVICE_TABLE_FILTER_USERS}
                        placeholder={texts.SERVICE_TABLE_FILTER_USERS}
                        onKeyUp={scope.onApplyFilterByPressedKey}
                    />
                </div>
                <div className={styles.filterPopoverContainer}>
                    <div className={styles.filterPopoverItem}>
                        <Autocomplete
                            size="small"
                            options={scope.serviceTypeOptions.map((serviceType) => serviceType)}
                            getOptionLabel={(serviceType) => serviceType.name}
                            value={scope.serviceTypeValue}
                            onChange={scope.onServiceTypeChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={texts.SERVICE_TABLE_FILTER_SERVICES}
                                    placeholder={texts.SERVICE_TABLE_FILTER_SERVICES}
                                />
                            )}
                            onKeyUp={scope.onApplyFilterByPressedKey}
                        />
                    </div>
                    <div className={styles.filterPopoverItem}>
                        <Autocomplete
                            size="small"
                            options={scope.durationOptions.map((duration) => duration)}
                            getOptionLabel={(duration) => duration.name}
                            value={scope.durationValue}
                            isOptionEqualToValue={(duration, value) => value.id === duration.id}
                            onChange={scope.onDurationChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={texts.SERVICE_TABLE_FILTER_TIME}
                                    placeholder={texts.SERVICE_TABLE_FILTER_TIME}
                                />
                            )}
                            onKeyUp={scope.onApplyFilterByPressedKey}
                        />
                    </div>
                </div>
                <div className={styles.filterPopoverButtons}>
                    <CustomButton color="inherit" variant="outlined" onClick={scope.onClear}>
                        {texts.SERVICE_TABLE_FILTER_CLEAR_BUTTON}
                    </CustomButton>
                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        loading={scope.filtering}
                        onClick={scope.onApply}
                    >
                        {texts.SERVICE_TABLE_FILTER_APPLY_BUTTON}
                    </LoadingButton>
                </div>
            </Paper>
        </>
    )
}

const CustomButton = styled(Button)({
    fontWeight: 500,
    letterSpacing: 0.4,
    border: '1px solid #8d8a8aba',
    marginRight: 8
})

// :: Component
