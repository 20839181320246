import { ArrayStringCompare, DateFns, lodash } from '@syonet/lang'
import { ChipItemScope, ChipPanelScope } from 'src/components/chippanel'
import { MultiListingPresenter } from 'src/components/multilist'
import { DashboardKeys } from 'src/Constants'
import { action, NOOP_PROMISE_VOID, Presenter } from 'wdc-cube'
import { DashboardPresenter } from './Dashboard.presenter'
import { ChannelId } from './Dashboard.service'
import { DashboardFilterScope } from './DashboardFilter.scopes'
import { TextsProvider } from './texts'

import type { KeyPressEvent } from './DashboardFilter.scopes'
import { Utils } from '@whatsapp/communication'

// @Inject
const texts = TextsProvider.get()

const FILTER_ID = 'f3f86061-9a93-41af-8a52-d08f6ad09e97'

export type DashboardFilterState = {
    channels: string[]
    startTime: Date
    endTime: Date
    noCache?: boolean
}

export class DashboardFilterPresenter extends Presenter<DashboardFilterScope> {
    public onSearch = NOOP_PROMISE_VOID as (args: DashboardFilterState) => Promise<void>

    private __initialized = false

    private __credentialMap = new Map<string, ChannelId>()
    private __channelMap = new Map<string, ChannelId>()

    private __state: DashboardFilterState = {
        channels: [],
        startTime: DateFns.startOfDay(Date.now()),
        endTime: DateFns.endOfDay(Date.now())
    }

    private __multiListingPresenter: MultiListingPresenter

    public constructor(owner: DashboardPresenter) {
        super(owner, new DashboardFilterScope())

        this.scope.onOpen = this.onOpen.bind(this)
        this.scope.onClose = this.onClose.bind(this)
        this.scope.onClear = this.onClear.bind(this)
        this.scope.onApply = this.onApply.bind(this)

        this.scope.startTime.update = this.update
        this.scope.startTime.label = texts.DASHBOARD_FILTER_DATE_TIME_START
        this.scope.startTime.onChange = this.onUpdateStartTime.bind(this)
        this.scope.startTime.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.scope.endTime.update = this.update
        this.scope.endTime.label = texts.DASHBOARD_FILTER_DATE_TIME_END
        this.scope.endTime.onChange = this.onUpdateEndTime.bind(this)
        this.scope.endTime.onApplyFilterByPressedKey = this.onApplyFilterByPressedKey.bind(this)

        this.__multiListingPresenter = new MultiListingPresenter(this, this.scope.channelsSelector, this.updateManager)
    }

    public release() {
        this.__multiListingPresenter.release()
        super.release()
    }

    public getChannelMap() {
        return this.__channelMap
    }

    public override get owner() {
        return super.owner as DashboardPresenter
    }

    public save() {
        this.owner.app.setAttribute(FILTER_ID, this.__state)
    }

    public fix(keys: DashboardKeys) {
        let state = this.__state
        if (!this.__initialized) {
            state = this.owner.app.getAttribute(FILTER_ID) || state
        }

        if (!keys.startTime()) {
            keys.startTime(state.startTime)
        }

        if (!keys.endTime()) {
            keys.endTime(state.endTime)
        }

        if (!keys.channels()) {
            keys.channels(state.channels)
        }

        const startTime = keys.startTime() ?? state.startTime
        const endTime = keys.endTime() ?? state.endTime

        if (DateFns.isAfter(startTime, endTime)) {
            keys.startTime(endTime)
            keys.endTime(startTime)
        }

        return keys
    }

    public publishParameters(keys: DashboardKeys) {
        keys.channels(this.__state.channels)
        keys.startTime(this.__state.startTime)
        keys.endTime(this.__state.endTime)
    }

    public propagateContext(keys: DashboardKeys) {
        this.publishParameters(keys)
        keys.credentials(this.__credentialMap)
    }

    public cloneState() {
        return lodash.cloneDeep(this.__state)
    }

    public applyCredentialsData(credentials: Map<string, ChannelId>) {
        const oldState = this.cloneState()

        this.__credentialMap.clear()
        for (const [phone, credential] of credentials.entries()) {
            this.__credentialMap.set(phone, credential)
        }

        this.applyData(oldState)
    }

    public async synchronizeState(keys: DashboardKeys, force = false) {
        let changed = false

        const newChannels = keys.channels() ?? this.__state.channels
        changed = changed || ArrayStringCompare(newChannels, this.__state.channels) !== 0

        const newStartTime = keys.startTime() || this.__state.startTime
        changed = changed || !DateFns.isEqual(newStartTime, this.__state.startTime)

        const newEndTime = keys.endTime() || this.__state.endTime
        changed = changed || !DateFns.isEqual(newEndTime, this.__state.endTime)

        if (changed || force) {
            this.applyData({ channels: newChannels, startTime: newStartTime, endTime: newEndTime })
            changed = true
        }

        return changed
    }

    public applyData(args: DashboardFilterState) {
        this.__state.startTime = new Date(args.startTime.getTime())
        this.__state.endTime = new Date(args.endTime.getTime())

        this.__channelMap.clear()
        this.__state.channels.length = 0

        for (const phone of args.channels) {
            const credential = this.__credentialMap.get(phone)
            if (credential) {
                this.__state.channels.push(phone)
                this.__channelMap.set(phone, credential)
            }
        }

        if (this.__state.channels.length === 0) {
            for (const [phone, credential] of this.__credentialMap.entries()) {
                this.__state.channels.push(phone)
                this.__channelMap.set(phone, credential)
            }
        }
    }

    @action()
    private onUpdateStartTime(value: Date | null | undefined) {
        if (value) {
            this.scope.startTime.value = value
        } else {
            this.scope.startTime.value = this.__state.startTime
        }
    }

    @action()
    private onUpdateEndTime(value: Date | null | undefined) {
        if (value) {
            this.scope.endTime.value = value
        } else {
            this.scope.endTime.value = this.__state.endTime
        }
    }

    @action()
    private async onOpen() {
        this.scope.startTime.value = this.__state.startTime
        this.scope.endTime.value = this.__state.endTime

        this.__multiListingPresenter.syncItems(
            Array.from(this.__credentialMap.values()).map((credential) => ({
                code: credential.phone,
                description: Utils.nameOrPhoneAndGroup(credential),
                selected: this.__channelMap.has(credential.phone)
            }))
        )

        this.scope.opened = true
    }

    @action()
    private async onClose() {
        this.scope.opened = false
    }

    @action()
    private async onClear() {
        const filterArgs = this.cloneState()

        if (this.__state.startTime || this.__state.endTime) {
            const now = new Date()
            filterArgs.startTime = DateFns.startOfDay(now)
            filterArgs.endTime = DateFns.endOfDay(now)
        }

        if (this.__state.channels.length < this.scope.channelsSelector.entries.length - 1) {
            filterArgs.channels = filterArgs.channels.filter((item) => item)
            if (filterArgs.channels.length === 0) {
                filterArgs.channels = Array.from(this.__credentialMap.values()).map((credential) => credential.phone)
            } else {
                const channels: string[] = []
                Array.from(this.__credentialMap.values()).map((credential) => {
                    channels.push(credential.phone)
                })
                filterArgs.channels = channels
            }
        }

        try {
            this.scope.filtering = true
            this.scope.opened = false
            filterArgs.noCache = true
            await this.onSearch(filterArgs)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    @action()
    private async onApplyFilterByPressedKey(evt: KeyPressEvent) {
        if (evt.key === 'Enter') {
            this.onApply()
        }
    }

    @action()
    private async onApply() {
        const request: DashboardFilterState = {
            startTime: lodash.cloneDeep(this.scope.startTime.value ?? this.__state.startTime),
            endTime: lodash.cloneDeep(this.scope.endTime.value ?? this.__state.endTime),
            channels: lodash.cloneDeep(this.__multiListingPresenter.codes)
        }

        if (DateFns.isAfter(request.startTime, request.endTime)) {
            this.alert(
                'error',
                texts.DASHBOARD_FILTER_STATE_INCONSISTENCY_ALERT_ERROR_TITLE,
                texts.DASHBOARD_FILTER_STATE_INCONSISTENCY_DATE_TIME_ALERT_ERROR_CONTENT
            )
            return
        }

        if (request.channels.length === 0) {
            this.alert(
                'error',
                texts.DASHBOARD_FILTER_STATE_INCONSISTENCY_ALERT_ERROR_TITLE,
                texts.DASHBOARD_FILTER_STATE_INCONSISTENCY_MULTILISTING_ALERT_ERROR_CONTENT
            )
            return
        }

        try {
            this.scope.opened = false
            this.scope.filtering = true
            request.noCache = true
            await this.onSearch(request)
        } catch (caught) {
            this.scope.opened = true
            throw caught
        } finally {
            this.scope.filtering = false
        }
    }

    public syncChips(chipPanelScope: ChipPanelScope) {
        let i = 0
        try {
            let chip

            // start moment
            chip = chipPanelScope.entries.get(i)
            if (!chip) {
                chip = new ChipItemScope()
                chip.update = this.update
                chipPanelScope.entries.set(i, chip)
            }

            chip.description = `${texts.DATE_TIME_INITIAL_CHIP_DESCRIPTION}: ${DateFns.format(
                this.__state.startTime,
                'dd/MM/yyyy HH:mm'
            )}`
            chip.onRemove = this.onRemoveIntervalFilter.bind(this)
            i++

            // end moment
            chip = chipPanelScope.entries.get(i)
            if (!chip) {
                chip = new ChipItemScope()
                chip.update = this.update
                chipPanelScope.entries.set(i, chip)
            }
            chip.description = `${texts.DATE_TIME_FINAL_CHIP_DESCRIPTION}: ${DateFns.format(
                this.__state.endTime,
                this.owner.app.date_HH_MM_Format
            )}`
            chip.onRemove = this.onRemoveIntervalFilter.bind(this)
            i++

            // It is not possible to remove channel when there is only one
            if (this.__credentialMap.size > 1) {
                if (this.__state.channels.length < this.__credentialMap.size) {
                    for (const channel of this.__state.channels) {
                        const credential = this.__credentialMap.get(channel)
                        if (credential) {
                            chip = chipPanelScope.entries.get(i)
                            if (!chip) {
                                chip = new ChipItemScope()
                                chip.update = this.update
                                chipPanelScope.entries.set(i, chip)
                            }

                            const channelDescription = Utils.nameOrPhoneAndGroup(credential)
                            chip.description = `${texts.CHANNEL_NAME_CHIP_DESCRIPTION}: ${channelDescription}`
                            chip.onRemove = this.onRemoveChannelFilter.bind(this, channel)
                            i++
                        }
                    }
                }
            }
        } finally {
            chipPanelScope.entries.length = i
        }
    }

    @action()
    private async onRemoveChannelFilter(channel: string) {
        const request = this.cloneState()

        request.channels = request.channels.filter((item) => item !== channel)
        if (request.channels.length === 0) {
            request.channels = Array.from(this.__credentialMap.values()).map((credential) => credential.phone)
        }

        await this.onSearch(request)
    }

    @action()
    private async onRemoveIntervalFilter() {
        const now = new Date()

        const request = this.cloneState()
        request.startTime = DateFns.startOfDay(now)
        request.endTime = DateFns.endOfDay(now)

        await this.onSearch(request)
    }

    public override onBeforeScopeUpdate() {
        let valid = true

        if (this.__multiListingPresenter.codes.length === 0) {
            valid = false
        }

        if (valid) {
            const startTime = this.scope.startTime.value ?? this.__state.startTime
            const endTime = this.scope.endTime.value ?? this.__state.endTime

            if (DateFns.isAfter(startTime, endTime)) {
                valid = false
            }
        }

        this.scope.valid = valid
    }
}
