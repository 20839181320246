import 'reflect-metadata'
import { lodash } from '../lodash'
import { Logger } from '../logger'

const LOG = new Logger('@syonet/lang/Command')

export type Construtor<T = unknown> = {
    new (...args: T[]): T
    name: string
    prototype: {
        constructor: Construtor<T>
        [n: string]: unknown
    }
    [n: string]: unknown
}

export type ServiceDependency = {
    key: string
    type: Construtor
}

export type InstrumentAction = (instance: object) => void

export type InstrumentActionBuilder = (deps: ServiceDependency[]) => InstrumentAction

function defaultInstrumentAction(deps: ServiceDependency[]): InstrumentAction {
    return (instance) => {
        LOG.debug(`[${Command.PROPERTY_INSTRUMENT_ACTION}]: Instrumenting with sevices injection`, {
            instance,
            deps
        })
    }
}

let INSTRUMENT_ACTION_BUILD_INSTANCE = defaultInstrumentAction

export function Command(): ClassDecorator {
    return (target: object) => {
        if (!lodash.isFunction(target)) {
            return
        }

        const ctor = target as unknown as Construtor

        const deps = Reflect.getMetadata(Command.PROPERTY_DEPS_METADATA, ctor) as ServiceDependency[]
        if (lodash.isEmpty(deps)) return

        ctor[Command.PROPERTY_INSTRUMENT_ACTION] = INSTRUMENT_ACTION_BUILD_INSTANCE(deps)
    }
}

/** @description Equals to NestJS value */
Command.PROPERTY_DEPS_METADATA = 'self:properties_metadata'

Command.PROPERTY_INSTRUMENT_ACTION = 'self:instrument_action'

Command.setInstrumentActionBuilder = (builder: InstrumentActionBuilder) => {
    INSTRUMENT_ACTION_BUILD_INSTANCE = builder
}
