import { v4 } from 'uuid'
import { TemplateFields, TemplateMessage } from './internal-communication'
import { MessageForPartner } from './syonet'
import { ActiveMessage, MultimediaHeader, ReplyContext, WrapperMessage } from './infobip'

export function getActiveMessage(messageForPartner: MessageForPartner): ActiveMessage | Error {
    const message = messageForPartner?.wrapper?.message
    if (message?.type === 'ACTIVE_MESSAGE') {
        return message
    }
    return new Error('Não é um Hsm')
}

export function toTemplateTextData(template: string, templateFields: TemplateFields) {
    const startTag = '###'
    let customTemplate = `${template}`
    type TabIndexControl = { tag: string; value: string; index: number }
    const tagIndexControl: TabIndexControl[] = []

    for (const key in templateFields) {
        const tag = `${startTag}${key}`
        const value = adjustTextSpacing(`${templateFields[key]}`)
        const rgx = new RegExp(`${tag}(?!_)`)

        let index = 0
        const end = -1
        while (index !== end) {
            index = customTemplate.search(rgx)
            if (index === end) break
            tagIndexControl.push({ tag, value, index })
            customTemplate = customTemplate.replace(rgx, value)
        }
    }

    const tagIndexControlSorted = [...tagIndexControl].sort((a, b) => a.index - b.index)
    const content = tagIndexControlSorted.map(({ value }) => `${value}`)

    return { text: customTemplate, content }
}

function adjustTextSpacing(text: string) {
    if (!text) {
        return text
    }
    return text
        .split(' ')
        .filter((character) => character.trim() !== '')
        .join(' ')
}

export function buildWrapperMessage(params: {
    companyPhone: string
    customerPhone: string
    contact: string
    message: ActiveMessage
}): WrapperMessage {
    const { companyPhone, contact, customerPhone, message } = params

    return {
        message,
        messageId: v4(),
        from: companyPhone,
        to: customerPhone,
        integrationType: 'WHATSAPP',
        receivedAt: '',
        createAt: new Date().getTime(),
        contact: { name: contact },
        channel: companyPhone,
        interactionType: message?.preview?.template?.interactionType
    }
}

export function buildActiveMessage(params: {
    template: TemplateMessage
    templateFields: TemplateFields
    customerName: string
    context: ActiveMessage['context']
    replyContext?: ReplyContext
}): ActiveMessage {
    const { template, templateFields, customerName, context, replyContext } = params

    const { text, content } = toTemplateTextData(template.customTemplate, templateFields)

    const templateData = () => {
        const requestBody = {
            body: { placeholders: content },
            buttons: template.buttons,
            footer: template.footer ?? null
        }

        if (template.interactionType === 'USER_NOTIFICATION' && replyContext?.ticket?.id && template?.buttons?.length) {
            const { buttons } = template
            if (buttons[0].type === 'URL') {
                buttons[0].parameter = `evento/${replyContext?.ticket?.id}?tab=whatsapp`
            }
        }
        if (template.interactionType === 'USER_NOTIFICATION' && template.partner === 'GUPSHUP') {
            requestBody.body.placeholders.push(`evento/${replyContext?.ticket?.id}?tab=whatsapp`)
        }
        switch (template.templateType) {
            case 'VIDEO':
            case 'IMAGE': {
                const header: MultimediaHeader = { type: template.templateType, mediaUrl: template.mediaUrl || '' }
                return { ...requestBody, header }
            }
            default:
                return requestBody
        }
    }

    const getTemplateIdentifier = (template: Pick<TemplateMessage, 'partner' | 'key' | 'id'>) =>
        ({
            INFOBIP: template.key,
            GUPSHUP: template.id
        }[template.partner])

    return {
        id: template.id,
        language: template.language,
        templateData: templateData(),
        templateName: getTemplateIdentifier(template) || '',
        type: 'ACTIVE_MESSAGE',
        preview: {
            text,
            type: 'TEXT',
            replyContext,
            template: {
                id: template.id,
                interactive: template.interactive || false,
                interactionType: template.interactionType
            }
        },
        channelPhone: template.channelPhone,
        context,
        customerName,
        canCreateRoom: template.canCreateRoom
    }
}

export function extractTagAndValueFromObject(obj: { [key: string]: Record<string, unknown> }) {
    const result: { [key: string]: unknown } = {}
    const entries = Object.entries(obj)

    const mapToResult = (prop: [string, unknown][]) => {
        for (const [key, value] of prop) {
            if (value && typeof value === 'object') {
                mapToResult(Object.entries(value))
                continue
            }
            if (value && typeof value !== 'string') {
                result[key.toUpperCase()] = value
            } else if (value && (value as string).trim()) {
                result[key.toUpperCase()] = value
            }
        }
    }

    mapToResult(entries)

    return result
}

export default {
    toTemplateTextData,
    adjustTextSpacing
}
