import { AdminChannelConfiguration as NS } from '@whatsapp/communication'
import { MultipleStringValuesAutoCompleteScope } from 'src/components/autocompletefield'
import { v4 as uuid } from 'uuid'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import type { MouseEvent } from 'wdc-cube/lib/events-react-compatible'

export type { MultipleStringValuesAutoCompleteScope }

type ChannelMenuEntry = NS.ChannelMenuEntry
type CompanyChannelConfig = NS.CompanyChannelConfig

interface Menu {
    menu: ChannelMenuEntry[]
    submenu?: ChannelMenuEntry[]
}

@Observable
export class ChannelConfigurationMenuSegmentationEditorFormScope extends Scope {
    readonly uid = uuid()
    readonly companyOptions = new ObservableArray<CompanyChannelConfig>(this)

    @observe() serviceInfo: NS.ServiceInfo[] | undefined

    @observe() anchorEl: HTMLElement | null | undefined
    @observe() openCompanies = false
    @observe() openSubmenu = false

    @observe() menuId = 0
    @observe() menuSelected: ChannelMenuEntry | undefined

    @observe() segmentations: string[] = []
    @observe() segmentationByCompany: string[] = []

    @observe() wholeMenu: Menu[] = []

    @observe() menuType = ''
    @observe() newMenuAlert = false

    @observe() companyToSegment: CompanyChannelConfig[] = []

    @observe() onChangeSegmentations = Scope.ASYNC_ACTION_TWO<CompanyChannelConfig, string[]>()

    @observe() onOpenCompanyToSegment = Scope.ASYNC_ACTION_ONE<MouseEvent>()
    @observe() onCloseCompanyItems = Scope.ASYNC_ACTION_ONE<((event: Record<string, unknown>, reason: "backdropClick" | "escapeKeyDown") => void)>()
    @observe() onSelectMenu = Scope.ASYNC_ACTION_ONE<ChannelMenuEntry>()
    @observe() onToggleSubMenu = Scope.ASYNC_ACTION_ONE<MouseEvent>()
    @observe() onAddNewCompany = Scope.ASYNC_ACTION_ONE<CompanyChannelConfig>()
    @observe() onRemoveSegmentedCompany = Scope.ASYNC_ACTION_ONE<CompanyChannelConfig>()

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
}
