import { Button } from '@mui/material'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { AlertMessageContentScope } from '../Main.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeAlertStyles } from './Alert.styles'

// @Inject
const texts = TextsProvider.get()

export type AlertMessageContentViewProps = IViewProps & {
    scope: AlertMessageContentScope
}

export function AlertMessageContentView({ className, scope }: AlertMessageContentViewProps) {
    bindUpdate(React, scope)

    const onClose = useCallback(scope.onClose, [scope.onClose])

    const { classes: styles } = getOrMakeAlertStyles()

    return (
        <div className={clsx(styles.alertMessageContent, className)}>
            <div>{scope.message}</div>
            <Button color="inherit" onClick={onClose}>
                {texts.ALERT_MESSAGE_CONTENT_CONTINUE_BUTTON}
            </Button>
        </div>
    )
}
