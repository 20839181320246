import type { CaughtException, PagingDTO, ChannelId, UserContext } from '../common'

export const PATHS = {
    base: 'admin/dashboard'
}

export type CustomerServiceKpiDTO = {
    contactCount: number
    newContactCount: number
    nonVisualizedTicketCount: number
    visualizedTicketCount: number
    humanTicketCount: number
    scheduledCount: number
}

export type ActiveMessagesKpiDTO = {
    quantityOfSentMessages: number
}

export type CustomerIdAndName = {
    id: string
    name: string
}

export type CustomerIdAndNameAndPhone = {
    id: string
    name: string
    phone: string
}

export type UserIdAndName = {
    id: string
    name: string
}

export type CategoryOption = {
    id: string
    name: string
    group: string
}

export type CompanyIdAndName = {
    id: number
    name: string
}

export enum Visualization {
    ALL = 0,
    VISUALIZED = 1,
    NON_VISUALIZED = 2
}

export enum CustomerServiceType {
    ALL = 0,
    HUMAN = 1,
    ROBOT
}

export type CustomerEntryType = {
    id: string
    name: string
    phone: string
}

export type CategoryEntryType = {
    id: string
    name: string
    group: string
}

export type CompanyEntryType = {
    id: number
    name: string
}

export type CustomerServiceEntry = {
    id: string
    date: string
    readAt?: string
    unreadInterval: number
    channel: ChannelId
    customer: CustomerEntryType
    company: CompanyEntryType
    ticket: {
        id: number
        account: string
        category?: CategoryEntryType
        user: {
            id: string
            name: string
            robot?: boolean
        }
    }
}

export type ResumedActiveMessageDTO = {
    user: {
        id: string
        name: string
    }
    sentMessages: number
}

export type DetailedActiveMessageDTO = {
    ticketId: string
    customer: {
        name: string
        phone: string
    }
    sendDate: number
    text: string
}

type CommonArgs = {
    user?: UserContext
    channels: string[]
    startTime?: string
    endTime?: string
}

type CommonPageArgs = CommonArgs & {
    itemsPerPage?: number
    pageIndex?: number
}

// :: CustomerServices

export type CustomerServicesRequest = CommonPageArgs & {
    type?: CustomerServiceType
    visualization?: Visualization
    ticketIds?: number[]
    customerIds?: string[]
    companyIds?: number[]
    userIds?: string[]
    categoryIds?: string[]
    durationInDays?: number
    noCache?: boolean
}
export type CustomerServicesResponse = PagingDTO<CustomerServiceEntry> & {
    kpi: CustomerServiceKpiDTO
    filter: {
        customerOptions?: CustomerIdAndName[]
        companyOptions?: CompanyIdAndName[]
        userOptions?: UserIdAndName[]
        categoryOptions?: CategoryOption[]
        categoryId?: string
        visualization?: Visualization
        maxDurationInDays: number
    }
}

// :: ActiveMessagesResumed

export type ActiveMessagesResumedRequest = CommonPageArgs & { userId?: string }
export type ActiveMessagesResumedResponse = PagingDTO<ResumedActiveMessageDTO> & { kpi: ActiveMessagesKpiDTO }

// :: ActiveMessagesDetailed

export type ActiveMessagesDetailedRequest = CommonPageArgs & { userId?: string }
export type ActiveMessagesDetailedResponse = PagingDTO<DetailedActiveMessageDTO>

// :: CustomersFromCustomerServices

export type CustomersFromCustomerServicesRequest = CommonPageArgs & { customerName?: string; customerPhone?: string; customerIds?: string[] }
export type CustomersFromCustomerServicesResponse = PagingDTO<CustomerIdAndNameAndPhone>

// :: UsersFromCustomerServices

export type UsersFromCustomerServicesRequest = CommonPageArgs & { userName?: string; userIds?: string[] }
export type UsersFromCustomerServicesResponse = PagingDTO<UserIdAndName>

// :: CategoriesFromCustomerServices

export type CategoriesFromCustomerServicesRequest = CommonPageArgs & { categoryName?: string; categoryIds?: string[] }
export type CategoriesFromCustomerServicesResponse = PagingDTO<UserIdAndName>

// :: CompaniesFromCustomerServices

export type CompaniesFromCustomerServicesRequest = CommonPageArgs & { companyName?: string; companyIds?: number[] }
export type CompaniesFromCustomerServicesResponse = PagingDTO<CompanyIdAndName>

// :: Message Broker Topics

export const Topics = {
    customerServices: 'syo-whatsapp-bus-admin-dashboard-customerServices',
    activeMessagesResumed: 'syo-whatsapp-bus-admin-dashboard-activeMessagesResumed',
    activeMessagesDetailed: 'syo-whatsapp-bus-admin-dashboard-activeMessagesDetailed',
    customersFromCustomerService: 'syo-whatsapp-bus-admin-dashboard-customersFromCustomerService',
    usersFromCustomerService: 'syo-whatsapp-bus-admin-dashboard-usersFromCustomerService',
    categoriesFromCustomerService: 'syo-whatsapp-bus-admin-dashboard-categoriesFromCustomerService',
    companiesFromCustomerService: 'syo-whatsapp-bus-admin-dashboard-companiesFromCustomerService'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        customerServices?: CustomerServicesRequest
        activeMessagesResumed?: ActiveMessagesResumedRequest
        activeMessagesDetailed?: ActiveMessagesDetailedRequest
        customersFromCustomerService?: CustomersFromCustomerServicesRequest
        usersFromCustomerService?: UsersFromCustomerServicesRequest
        categoriesFromCustomerService?: CategoriesFromCustomerServicesRequest
        companiesFromCustomerService?: CompaniesFromCustomerServicesRequest
    }

    mutation?: Record<string, unknown>
}

export type Response = {
    query?: {
        customerServices?: CustomerServicesResponse
        activeMessagesResumed?: ActiveMessagesResumedResponse
        activeMessagesDetailed?: ActiveMessagesDetailedResponse
        customersFromCustomerService?: CustomersFromCustomerServicesResponse
        usersFromCustomerService?: UsersFromCustomerServicesResponse
        categoriesFromCustomerService?: CategoriesFromCustomerServicesResponse
        companiesFromCustomerService?: CompaniesFromCustomerServicesResponse
    }

    mutation?: Record<string, unknown>
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
