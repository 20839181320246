import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1
    },
    adjustBoxes: {
        paddingX: 1,
        paddingBottom: '10px',
        display: 'flex',
        width: '100%'
    }
}))
