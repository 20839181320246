import { LanguageValue, Language, SUPPORTED_LANGUAGES } from './languages'
import { en_US, es, es_PY, es_PE, pt_BR } from './locales'
import { AdminV2TranslationModules } from '../bff/adminV2'

export type Translation = ReturnType<typeof loadTranslation>

export const loadTranslation = (language: Language, module: AdminV2TranslationModules) => {
    const languageValue = SUPPORTED_LANGUAGES[language] as LanguageValue

    if (!module) return

    switch (languageValue) {
        case 'en_US':
            return en_US![module]
        case 'es':
            return es![module]
        case 'es_PE':
            return es_PE![module]
        case 'es_PY':
            return es_PY![module]
        case 'pt_BR':
            return pt_BR![module]
        default:
            return pt_BR![module]
    }
}

export const getLanguageValue = (language: Language): LanguageValue => {
    return SUPPORTED_LANGUAGES[language]
}
