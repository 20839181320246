import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { LoadingButton } from '@mui/lab'
import { Button, ClickAwayListener, FormControl, Paper, TextField } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { NOOP_PROMISE_VOID } from 'wdc-cube'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ChannelConfigurationListingFilterScope } from '../ChannelConfigurationListingFilter.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationListingStyles } from './ChannelConfigurationListing.styles'

// @Inject
const texts = TextsProvider.get()

type ChannelConfigurationListingFilterProps = IViewProps & {
    scope: ChannelConfigurationListingFilterScope
}

export function ChannelConfigurationListingFilterView({ className, scope }: ChannelConfigurationListingFilterProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChannelConfigurationListingStyles()

    return (
        <div
            key="container"
            className={clsx(styles.filter, className)}
            onClick={scope.opened ? NOOP_PROMISE_VOID : scope.onOpen}
        >
            <FilterAltOutlinedIcon />
            {scope.opened ? (
                <div className={styles.filterPopover}>
                    <PanelSearch scope={scope} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

type PanelSearchProps = {
    scope: ChannelConfigurationListingFilterScope
}

function PanelSearch({ scope }: PanelSearchProps) {
    const { classes: styles } = getOrMakeChannelConfigurationListingStyles()

    return (
        <>
            <ClickAwayListener onClickAway={scope.onClose}>
                <Paper className={styles.filterPopoverContent}>
                    <div className={styles.item}>
                        <div className={styles.adjustLabel}>
                            <label className={styles.filterLabelText} htmlFor="name">
                                {texts.CHANNEL_FILTER_CHANNEL_NAME}
                            </label>
                        </div>
                        <FormControl size="small" variant="outlined">
                            <TextField
                                id="name"
                                variant="outlined"
                                size="small"
                                placeholder={texts.CHANNEL_FILTER_CHANNEL_NAME_PLACEHOLDER}
                                value={scope.channelName}
                                onChange={scope.onUpdateChannelName}
                                onKeyUp={scope.onApplyFilterByPressedKey}
                            />
                        </FormControl>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.adjustLabel}>
                            <label className={styles.filterLabelText} htmlFor="number">
                                {texts.CHANNEL_FILTER_CHANNEL_NUMBER}
                            </label>
                        </div>
                        <FormControl size="small" variant="outlined">
                            <TextField
                                size="small"
                                placeholder={texts.CHANNEL_FILTER_CHANNEL_NUMBER_PLACEHOLDER}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={scope.channelNumber}
                                onChange={scope.onUpdateChannelNumber}
                                onKeyUp={scope.onApplyFilterByPressedKey}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.filterAlignButton}>
                        <Button color="inherit" onClick={scope.onClear}>
                            {texts.CHANNEL_FILTER_CLEAR_BUTTON}
                        </Button>
                        <LoadingButton
                            className={styles.filterMarginButton}
                            variant="contained"
                            color="secondary"
                            loading={scope.filtering}
                            disabled={!scope.valid}
                            onClick={scope.onApply}
                        >
                            {texts.CHANNEL_FILTER_FILTER_BUTTON}
                        </LoadingButton>
                    </div>
                </Paper>
            </ClickAwayListener>
        </>
    )
}
