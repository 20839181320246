import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { ChannelConfigurationFormMessagesScope } from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormMessagesStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'

// @Inject
const Texts = TextsProvider.get()

export type ChannelConfigurationFormMessagesViewProps = IViewProps & { scope: ChannelConfigurationFormMessagesScope }

export function ChannelConfigurationFormMessagesView({ className, scope }: ChannelConfigurationFormMessagesViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyle()

    return (
        <div className={clsx(styles.view, className)}>
            <h2 className={styles.messageTitle}>{Texts.CHANNEL_CONFIGURATION_MESSAGES_TITLE}</h2>
            <div className={styles.adjustRichEditor}>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.greetingsMessage} />
                </div>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.companySegmentationMessage} />
                </div>
            </div>

            <div className={styles.adjustRichEditor}>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.companySelectionMessage} />
                </div>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.departmentSelectionMessage} />
                </div>
            </div>

            <h2 className={styles.messageTitle}>{Texts.CHANNEL_CONFIGURATION_MESSAGES_EVENT_GENERATION}</h2>
            <div className={styles.adjustRichEditor}>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.newTicketMessage} />
                </div>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.outOfWorkingHoursNewTicketMessage} />
                </div>
            </div>

            <h2 className={styles.messageTitle}>{Texts.CHANNEL_CONFIGURATION_MESSAGES_SPECIFIC_MESSAGE}</h2>
            <div className={styles.adjustRichEditor}>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.attendenceContinuationMessage} />
                </div>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.identityConfirmationMessage} />
                </div>
            </div>

            <div className={styles.adjustRichEditor}>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.offlineSystemMessage} />
                </div>
                <div className={styles.richEditorWidth}>
                    <ViewSlot scope={scope.invalidOptionMessage} />
                </div>
            </div>
        </div>
    )
}
