export type { OnChangedListener, SelectionItem } from '../MultiSelection.presenter'

import { NOOP_VOID } from 'wdc-cube'
import { ViewFactory } from 'wdc-cube-react'
import { MultiSelectionScope } from '../MultiSelection.scopes'
import { MultiSelectionView } from './MultiSelection.view'

let doRegisterViews = function (rv = ViewFactory.register) {
    rv(MultiSelectionScope, MultiSelectionView)
}

export function registerViews() {
    doRegisterViews()
    doRegisterViews = NOOP_VOID
}
