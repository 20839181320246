import { NEW_PREFIX } from '@whatsapp/communication'
import { MultiSelectionScope } from 'src/components/multiselect'
import { RichDefaultEditorScope, RichEditorScope } from 'src/components/richeditor'
import { v4 as uuid } from 'uuid'
import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import { ChannelMenuEntry } from './ChannelConfiguration.service'
import { TextChangeEvent } from './ChannelConfigurationForm.scopes'

export type { TextChangeEvent }

export interface IEventMenu {
    eventOptions: ObservableArray<{ id: string; name: string }>
    eventValue?: string | null
}

@Observable
export class ChannelConfigurationMenuEditorFormEventScope extends Scope implements IEventMenu {
    readonly uid = uuid()
    readonly eventOptions = new ObservableArray<{ id: string; name: string }>(this)
    readonly description = new RichEditorScope()
    readonly type = 'EVENT'

    @observe() valid = false
    @observe() eventValue?: string | null
    @observe() eventError = ''

    @observe() onEventChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onDescriptionChanged = Scope.SYNC_ACTION_STRING
}

@Observable
export class ChannelConfigurationMenuEditorFormLinkScope extends Scope {
    readonly description = new RichEditorScope()
    readonly type = 'LINK'

    @observe() link = ''
    @observe() linkError = ''

    @observe() onLinkChanged = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onDescriptionChanged = Scope.ASYNC_ACTION_STRING
}

@Observable
export class ChannelConfigurationMenuEditorFormSchedulingRobotScope extends Scope {
    readonly description = new RichEditorScope()
    readonly type = 'ROBOT'

    @observe() onDescriptionChanged = Scope.ASYNC_ACTION_STRING
}

@Observable
export class ChannelConfigurationMenuEditorFormSubMenuScope extends Scope {
    readonly description = new RichDefaultEditorScope()
    readonly type = 'SUB_MENU'

    @observe() onDescriptionChanged = Scope.ASYNC_ACTION_STRING
}

@Observable
export class ChannelConfigurationMenuEditorFormInformationScope extends Scope implements IEventMenu {
    readonly uid = uuid()
    readonly description = new RichEditorScope()
    readonly message = new RichEditorScope()
    readonly eventOptions = new ObservableArray<{ id: string; name: string }>(this)
    readonly type = 'INFORMATION'

    @observe() eventValue?: string | null
    @observe() eventError = ''
    @observe() link = ''
    @observe() linkError = ''
    @observe() positiveButton = ''
    @observe() negativeButton = ''

    @observe() onEventChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onLinkChanged = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onLinkValidation: (p: FileList) => Error | null = () => null
    @observe() onPositiveButton = Scope.SYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onNegativeButton = Scope.SYNC_ACTION_ONE<TextChangeEvent>()

    @observe() onSelectFile = Scope.ASYNC_ACTION_ONE<File>()
    @observe() onDescriptionChanged = Scope.SYNC_ACTION_STRING
}

type MenuTypeDetail =
    | ChannelConfigurationMenuEditorFormEventScope
    | ChannelConfigurationMenuEditorFormLinkScope
    | ChannelConfigurationMenuEditorFormSchedulingRobotScope
    | ChannelConfigurationMenuEditorFormSubMenuScope
    | ChannelConfigurationMenuEditorFormInformationScope
    | null
@Observable
export class MenuOptionScope extends Scope implements Pick<ChannelMenuEntry, 'subServices' | 'id' | 'isSubService'> {
    readonly uid = uuid()
    readonly menuTypeOptions = new ObservableArray<{ id: number; name: string }>(this)

    @observe() position = 1
    @observe() menuTypeValue?: number
    @observe() id = `${NEW_PREFIX}${this.uid}`
    @observe() subServices: string[] = []
    @observe() isSubService = false
    @observe() deactivated = false
    @observe() menuTypeDetail?: MenuTypeDetail
    @observe() segmentations?: string[] = []

    @observe() onMenuTypeChanged = Scope.ASYNC_ACTION_ONE<TextChangeEvent>()
    @observe() onDelete = Scope.ASYNC_ACTION
    @observe() onUnselect = Scope.SYNC_ACTION
}

@Observable
export class ChannelConfigurationMenuEditorFormScope extends Scope {
    readonly uid = uuid()
    readonly companiesSelector = new MultiSelectionScope()
    readonly entries = new ObservableArray<MenuOptionScope>(this)

    @observe() menuId = 0
    @observe() selectedMenu: MenuOptionScope | undefined
    @observe() lastSelectedMenu: MenuOptionScope | undefined

    @observe() onAppendOption = Scope.ASYNC_ACTION
    @observe() onSwapPosition = Scope.SYNC_ACTION_TWO<number, number>()
    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onSave = Scope.ASYNC_ACTION
    @observe() onMenuSelect = Scope.SYNC_ACTION_STRING
}
