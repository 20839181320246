import { BaseAdminService } from '.'
import { Logger, ServiceLike } from 'wdc-cube'
import { AdminAttachment as NS } from '@whatsapp/communication'

const LOG = Logger.get('AxiosProviderService')

export class UploadFileService extends BaseAdminService implements ServiceLike {
    private static readonly INSTANCE = new UploadFileService()
    private __initialized = false
    private send = this.newSend<FormData, NS.Response>(NS.Topics.upload)

    public static singleton() {
        return UploadFileService.INSTANCE
    }

    get initialized(): boolean {
        return this.__initialized
    }

    get name(): string {
        return UploadFileService.name
    }

    async postConstruct() {
        this.__initialized = true
        LOG.info('Initialized')
    }

    async preDestroy() {
        this.__initialized = false
        LOG.info('Finalized')
    }

    public async upload(file: File) {
        const form = new FormData()
        form.append('file', file)
        return this.send(form)
    }
}
