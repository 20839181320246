import { keyframes } from '@emotion/react'
import { grey, yellow } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

const POPUP_WIDTH = 386

const Roboto = ['Roboto', 'sans-serif'].join(',')

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const getOrMakeChatsStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        padding: 16,
        flexGlow: 1,
        marginTop: -16
    },
    progress: {
        position: 'fixed',
        zIndex: 100,
        top: 64,
        left: 0,
        right: 0
    },
    filter: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        backgroundColor: '#797979',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 13,
        letterSpacing: 0.46,
        cursor: 'pointer',
        borderRadius: 4,

        '&:hover': {
            backgroundColor: '#898989'
        }
    },
    filterPopover: {
        position: 'absolute',
        '& .MuiPaper-root': {
            display: 'flex'
        }
    },
    filterPopoverContent: {
        width: `${POPUP_WIDTH}px`,
        maxHeight: 'calc(100vh - 160px)',
        position: 'fixed',
        animation: `${fadeIn} 0.2s ease-in`,
        top: 50,
        right: 22,
        display: 'flex',
        flexDirection: 'column'
    },
    filterAlignButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        left: 9,
        bottom: 0,
        overflow: 'hidden',
        paddingTop: 10,
        paddingBottom: 4,
        width: '95.7%',
        height: 50,
        backgroundColor: '#fff',
        zIndex: 1
    },
    filterLabelText: {
        color: grey[700],
        paddingTop: 10,
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: 'Arial',
        fontSize: 14,
        letterSpacing: 0.15
    },
    adjustChannelListing: {
        marginTop: -15,
        marginBottom: 20
    },
    content: {
        padding: 16,
        flexShrink: 1,
        alignSelf: 'stretch',
        overflowY: 'scroll'
    },
    noContentAlert: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 10,
        padding: 20,
        color: 'rgba(0, 0, 0, .6)',
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${yellow[800]}`,
        '& .icon': {
            color: yellow[800]
        },
        '& .rows': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    adjustLabel: {
        paddingLeft: 5,
        paddingBottom: 16
    },
    adjustItem: {
        marginBottom: 12,
        '&:first-of-type': {
            marginTop: -6
        }
    },
    customFont: {
        paddingBottom: 16
    },
    chatContainer: {
        display: 'flex',
        border: '3px solid #EEEEEE',
        borderRadius: 4,
        height: 'max-content',
        flexGrow: 1
    },
    contactContainer: {
        borderRight: '1px solid #E0E0E0',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 350,
        maxWidth: 500
    },
    contactTitle: {
        height: 64,
        backgroundColor: '#EEEEEE',
        display: 'flex',
        alignItems: 'center',
        fontFamily: Roboto,
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: 0.15
    },
    contactListView: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .MuiList-root': {
            overflow: 'auto',
            flexShrink: 1,
            flexGrow: 1,
            alignSelf: 'stretch',
            flexBasis: '64px'
        },
        '& .MuiTablePagination-root': {
            overflow: 'hidden',

            '& .MuiTablePagination-spacer': {
                display: 'none'
            },
            '& .MuiInputBase-root': {
                margin: 0
            },
            '& .MuiToolbar-root': {
                paddingLeft: 18
            }
        }
    },
    contactLabel: {
        marginLeft: 16
    },
    contactItems: {
        overflow: 'auto'
    },
    contactHeaderImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 45,
        height: 40,
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: 0.14,
        backgroundColor: grey[400],
        borderRadius: '50%',
        color: '#fff',
        margin: '12px 16px 12px 16px'
    },
    contactNameContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactName: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: -1,
        right: -1,
        marginLeft: -1,
        height: 64,
        backgroundColor: '#EEEEEE'
    },
    contactNameLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15
    },
    contactPhoneLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.38)'
    },
    adjustContactAndActionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    adjustActionButton: {
        paddingRight: 16
    },
    chatBody: {
        alignSelf: 'stretch',
        flexGrow: 1,
        borderRadius: '0px 0px 4px 0px',
        zIndex: 0,
        flexBasis: 200
    },
    body: {
        display: 'flex',
        flexShrink: 1,
        flexGrow: 1,
        gap: 10,
        flexDirection: 'column'
    }
}))

export const getOrMakeChatMenuItemStyles = makeStyles()(() => ({
    contactItemImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 40,
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: 0.14,
        backgroundColor: grey[400],
        borderRadius: '50%',
        color: '#fff',
        margin: '12px 16px 12px 0px'
    },
    contactLastMessageDateTimeLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.38)'
    },
    adjustContactAndActionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    contactNameContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactNameLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15
    },
    robotIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        paddingRight: 6
    }
}))

export const getOrMakeChatsFormStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        padding: 16
    },
    header: {
        fontFamily: Roboto,
        fontWeight: 700,
        fontSize: 20,
        letterSpacing: 0.15,
        color: grey[700],
        marginBottom: 16
    },
    newTicketContainer: {
        marginBottom: 16
    },
    adjustField: {
        margin: '16px 0 16px 0'
    },
    adjustGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16
    },
    adjustGridItem: {
        width: '48%'
    },
    adjustGridItemSize: {
        width: '100%'
    },
    adjustCheckboxBody: {
        position: 'relative',
        left: 12
    },
    adjustCheckboxLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        color: grey[600],
        whiteSpace: 'nowrap'
    },
    adjustButtons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}))
