export const Texts = {
    MENU_TITLE: 'WhatsApp',
    MENU_DASHBOARD_OPTION: 'Dashboard',
    MENU_CHATS_OPTION: 'Conversas',
    MENU_SETTINGS_OPTION_TITLE: 'Configurações',
    MENU_CHANNEL_OPTION: 'Canais',
    MENU_TEMPLATE_OPTION: 'Templates',

    AUTHENTICATING_CONTENT: 'Autenticando...',
    AUTHENTICATING_MESSAGE_FAIL_TITLE: 'Autenticação',
    AUTHENTICATING_MESSAGE_FAIL_DESCRIPTION: 'Falhou ao tentar usar o código de autenticação fornecido',

    NON_AUTHORIZED: 'Não autorizado',

    ERROR_TITLE_GENERATE_TICKET: 'Erro inesperado do CRM',
    ERROR_TEXT_GENERATE_TICKET: 'Por favor, tente realizar a ação novamente.',
    ERROR_TEXT_GENERATE_TICKET_USER_FORBIDDEN: 'O responsável pelo evento não possui as devidas permissões.',

    ERROR_TITLE_GENERATE_DEFAULT: 'Erro inesperado',
    ERROR_TEXT_GENERATE_DEFAULT: 'Por favor, contate o suporte.',

    ALERT_MESSAGE_CONTENT_CONTINUE_BUTTON: 'Fechar',

    SIMPLE_ALERT_MESSAGE_CANCEL_BUTTON: 'Cancelar',
    SIMPLE_ALERT_MESSAGE_CONFIRM_BUTTON: 'Confirmar',

    DATE_FORMAT: 'dd/MM/yyyy',
    DATE_HH_FORMAT: 'dd/MM/yyyy HH',
    DATE_HH_MM_FORMAT: 'dd/MM/yyyy HH:mm',
    DATE_HH_MM_SS_FORMAT: 'dd/MM/yyyy HH:mm:ss'
}
