import { NOOP_VOID } from 'wdc-cube'
import { ViewFactory } from 'wdc-cube-react'
import { RichDefaultEditorScope, RichEditorScope } from '../RichEditor.scopes'
import { RichDefaultEditorView } from './RichDefaultEditor.view'
import { RichEditorView } from './RichEditor.view'

let doRegisterViews = function (rv = ViewFactory.register) {
    rv(RichEditorScope, RichEditorView)
    rv(RichDefaultEditorScope, RichDefaultEditorView)
}

export function registerViews() {
    doRegisterViews()
    doRegisterViews = NOOP_VOID
}
