import FileCopyIcon from '@mui/icons-material/FileCopy'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Tooltip, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { IViewProps } from 'wdc-cube-react'
import { getOrMakeHeaderReportStyles } from './ReportHeader.styles'

export type ReportHeaderProps = IViewProps & {
    title: string
    info: string
    labelExportToXLS: string
    showCaption: boolean
    onExportToXLS: () => Promise<void>
    onMoreInfo: () => Promise<void>
}

export function ReportHeader(props: ReportHeaderProps) {
    const onExportToXLS = useCallback(props.onExportToXLS, [props.onExportToXLS])
    const onMoreInfo = useCallback(props.onMoreInfo, [props.onMoreInfo])

    const { classes: styles } = getOrMakeHeaderReportStyles()

    return (
        <span className={styles.content}>
            <span className={styles.adjustContent}>
                <Typography variant="button" className={styles.textColor}>
                    {props.title}
                </Typography>
                {props.info && (
                    <span className={styles.adjustTooltipInfo}>
                        <Tooltip
                            title={<Typography variant="caption">{props.info}</Typography>}
                            className={styles.adjustActiveMessagesPerUserTooltip}
                        >
                            <InfoIcon className={styles.moreInfo} onClick={onMoreInfo} />
                        </Tooltip>
                    </span>
                )}
            </span>
            {props.showCaption && (
                <span className={styles.adjustTooltipFile}>
                    <Tooltip title={<Typography variant="caption">{props.labelExportToXLS}</Typography>}>
                        <IconButton onClick={onExportToXLS}>
                            <FileCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </span>
            )}
        </span>
    )
}
