export enum AdminV2Modules {
    DASHBOARD = 'dashboard',
    CONVERSATIONS = 'conversations',
    CHANNELS = 'channels',
    TEMPLATES = 'templates',
    QUICK_REPLY = 'quick-reply',
    SYOALERT = 'syo-alert',
    TECH_PROVIDER = 'tech-provider',
    INTEGRATIONS = 'integrations'
}

export enum AdminV2TranslationModules {
    TEMPLATES = 'templates'
}

export type ModuleEnabled = {
    enabled: boolean
}
