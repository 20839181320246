import { makeStyles } from 'tss-react/mui'

export const getOrMakeStyles = makeStyles()((theme) => ({
    filterAllItem: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterAllItemDescription: {
        flexGrow: 1
    },
    filterAllItemSubdescription: {
        marginLeft: '13rem',
        color: theme.palette.grey[400]
    },
    alignListText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
