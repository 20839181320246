import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import WarningIcon from '@mui/icons-material/Warning'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { ChatHeaderScope, ChatsScope, ContactListScope, ContactScope } from '../Chats.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChatMenuItemStyles, getOrMakeChatsStyles } from './Chats.styles'

// @Inject
const texts = TextsProvider.get()

export type ChatsViewProps = IViewProps & {
    scope: ChatsScope
}

export function ChatsView({ scope, className }: ChatsViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatsStyles()

    let content: JSX.Element
    if (scope.contacts.itemCount === 0) {
        content = (
            <>
                <div className={styles.noContentAlert}>
                    <WarningIcon className="icon" />
                    <div className="rows">
                        <span>{texts.NO_CHATS_AVAILABLE}</span>
                        <span>{texts.NO_CHATS_AVAILABLE_TIP}</span>
                    </div>
                </div>
            </>
        )
    } else {
        content = (
            <>
                <Typography className={styles.customFont} variant="h6">
                    {texts.CHATS_TITLE}
                </Typography>
                <div className={styles.chatContainer}>
                    <div className={styles.contactContainer}>
                        <div className={styles.contactTitle}>
                            <span className={styles.contactLabel}>{texts.CHATS_CONTACTS}</span>
                        </div>
                        <ContactListView scope={scope.contacts} />
                    </div>
                    <div className={styles.body}>
                        <ChatHeaderView scope={scope.chatHeader} />
                        <ViewSlot className={styles.chatBody} scope={scope.whatsapp} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={clsx(styles.view, className)}>
            {scope.waiting && <LinearProgress className={styles.progress} color="secondary" />}
            <ViewSlot scope={scope.filterPanel} />
            {content}
        </div>
    )
}

// :: Component(ContactItemView)

type ContactItemViewProps = IViewProps & {
    scope: ContactScope
}

function ContactItemView({ scope }: ContactItemViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatMenuItemStyles()

    return (
        <MenuItem key={scope.id} onClick={scope.onSelect}>
            <span className={styles.contactItemImg}>{scope.initials}</span>
            <div className={styles.adjustContactAndActionButton}>
                <div className={styles.contactNameContent}>
                    <div className={styles.contactNameLabel}>{scope.name}</div>
                    <div className={styles.contactLastMessageDateTimeLabel}>{scope.datetime}</div>
                </div>
                {scope.hasRobot ? (
                    <div className={styles.robotIcon}>
                        <SmartToyIcon fontSize="small" />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </MenuItem>
    )
}

// :: Component(ContactListView)

type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

type ContactListViewProps = IViewProps & {
    scope: ContactListScope
}

function ContactListView({ className, scope }: ContactListViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatsStyles()

    return (
        <div className={clsx(styles.contactListView, className)}>
            <MenuList>
                {scope.entries.map((contactScope) => (
                    <ContactItemView key={contactScope.uid} scope={contactScope} />
                ))}
            </MenuList>
            <TablePagination
                rowsPerPageOptions={scope.itemsPerPageOptions}
                component="div"
                size="small"
                count={scope.itemCount}
                rowsPerPage={scope.itemsPerPage || 0}
                page={scope.pageIndex}
                onPageChange={scope.onChangePageIndex as unknown as PageChangeEventHandler}
                onRowsPerPageChange={scope.onChangeItemsPerPage}
                labelRowsPerPage={texts.CHATS_PER_USER_ROWS_PER_PAGE}
            />
        </div>
    )
}

// :: Component(ChatHeaderView)

type ChatHeaderViewProps = IViewProps & {
    scope: ChatHeaderScope
}

function ChatHeaderView({ scope }: ChatHeaderViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeChatsStyles()

    return (
        <div className={styles.contactName}>
            <span className={styles.contactHeaderImg}>{scope.initials}</span>
            <div className={styles.adjustContactAndActionButton}>
                <div className={styles.contactNameContent}>
                    <div className={styles.contactNameLabel}>{scope.name}</div>
                    <div className={styles.contactPhoneLabel}>{scope.phone}</div>
                </div>
                <div className={styles.adjustActionButton}>
                    {scope.hasTicket && (
                        <Tooltip title={texts.CHATS_OPEN_TICKET_TOOLTIP}>
                            <IconButton onClick={scope.onOpenTicket}>
                                <LocalOfferIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={texts.CHATS_GENERATE_NEW_TICKET_TOOLTIP} arrow>
                        <IconButton onClick={scope.onNewTicket}>
                            <BookmarkAddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}
