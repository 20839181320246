import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'

@Observable
export class ChipItemScope extends Scope {
    @observe() description = ''
    @observe() defaultColor = false

    @observe() onRemove = Scope.ASYNC_ACTION
}

@Observable
export class ChipPanelScope extends Scope {
    readonly entries = new ObservableArray<ChipItemScope>(this)
}
