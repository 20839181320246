import { TextsProvider } from '.'

const texts = TextsProvider.get()
export type ErrorAlertText = {
    title: string
    text: string
}

export const buildErrorMessage = (error: unknown): ErrorAlertText => {
    const errorCode = extractMessageFromTrowable(error).replace(/\D/g, '')
    return errors[errorCode] || errors.default
}

const errors: Record<string, ErrorAlertText> = {
    '403': {
        title: texts.ERROR_TITLE_GENERATE_TICKET,
        text: texts.ERROR_TEXT_GENERATE_TICKET_USER_FORBIDDEN
    },
    '421': {
        title: texts.ERROR_TITLE_GENERATE_TICKET,
        text: texts.ERROR_TEXT_GENERATE_TICKET
    },
    default: {
        title: texts.ERROR_TITLE_GENERATE_DEFAULT,
        text: texts.ERROR_TEXT_GENERATE_DEFAULT
    }
}
function extractMessageFromTrowable(error: unknown) {
    if (!(error instanceof Error)) {
        return typeof error === 'string' ? error : 'Error desconhecido'
    }

    return error.message
}
