export const StringCompare = (function () {
    return (a: string, b: string) => a.localeCompare(b)
})()

export const StringCICompare = (function () {
    const options: Intl.CollatorOptions = {
        sensitivity: 'base'
    }
    return (a: string, b: string) => a.localeCompare(b, undefined, options)
})()

export function ArrayStringCompare(a: string[], b: string[]) {
    if (a === b) {
        return 0
    }

    if (a.length !== b.length) {
        return a.length - b.length
    }

    for (let i = 0; i < a.length; i++) {
        const ai = a[i]
        const bi = b[i]

        if (ai === bi) {
            continue
        } else if (ai > bi) {
            return 1
        } else {
            return -1
        }
    }

    return 0
}
