import { NOOP_VOID } from 'wdc-cube'
import { ViewFactory } from 'wdc-cube-react'
import { MultiListingScope } from '../MultiListing.scopes'
import { MultiListingView } from './MultiListing.view'

export type { SelectionItem, OnChangedListener } from '../MultiListing.presenter'

let doRegisterViews = function (rv = ViewFactory.register) {
    rv(MultiListingScope, MultiListingView)
}

export function registerViews() {
    doRegisterViews()
    doRegisterViews = NOOP_VOID
}
