export enum WebhookConfigType {
    MAILTOP = 'MAILTOP'
}

export type WebhookConfigDTO = {
    group: string
    type: WebhookConfigType
    channels: Channel[]
    baseUrl: string
}

export type Channel = { phone: string; name: string; active?: boolean }
export type ListRequest = { group: string; channels: Channel[] }
