import { ChannelId } from '@whatsapp/communication'
import { Application, IPresenter, Place, Scope, ScopeSlot } from 'wdc-cube'
import { AlertHolder } from './feature/main'
import { IntentKeys, keys } from './utils'

export const MAX_INTERNAL_WIDTH = '100%'

export const Places = {
    root: Place.ROOT,
    dashboard: Place.UNKNOWN,
    dashboardUserActiveMessagesDetail: Place.UNKNOWN,
    chats: Place.UNKNOWN,
    chatsForm: Place.UNKNOWN,
    channelConfigurationListing: Place.UNKNOWN,
    channelConfigurationForm: Place.UNKNOWN,
    channelConfigurationMenuEditorForm: Place.UNKNOWN,
    channelConfigurationMenuSegmentationEditorForm: Place.UNKNOWN,
    channelConfigurationTimesEditorForm: Place.UNKNOWN,
    channelConfigurationSelfRescueForm: Place.UNKNOWN,
    templateConfigurationListing: Place.UNKNOWN,
    templateConfigurationForm: Place.UNKNOWN,
    channelConfigurationCompanyConfigurationForm: Place.UNKNOWN
}

export const KeysFactory = {
    root: (app: Application) => new RootKeys(app.newFlipIntent(Places.root)),

    dashboard: (app: Application) => new DashboardKeys(app.newFlipIntent(Places.dashboard)),
    dashboardUserActiveMessagesDetail: (app: Application) =>
        new DashboardActiveMessagesKeys(app.newFlipIntent(Places.dashboardUserActiveMessagesDetail)),

    chats: (app: Application) => new ChatKeys(app.newFlipIntent(Places.chats)),
    chatsForm: (app: Application) => new ChatsFormKeys(app.newFlipIntent(Places.chatsForm)),

    channelConfigurationListing: (app: Application) =>
        new ChannelConfigurationListingKeys(app.newFlipIntent(Places.channelConfigurationListing)),
    channelConfigurationForm: (app: Application) =>
        new ChannelConfigurationFormKeys(app.newFlipIntent(Places.channelConfigurationForm)),
    channelConfigurationMenuEditorForm: (app: Application) =>
        new ChannelConfigurationMenuEditorFormKeys(app.newFlipIntent(Places.channelConfigurationMenuEditorForm)),
    channelConfigurationTimesEditorForm: (app: Application) =>
        new ChannelConfigurationTimesEditorFormKeys(app.newFlipIntent(Places.channelConfigurationTimesEditorForm)),
    channelConfigurationSelfRescueForm: (app: Application) =>
        new ChannelConfigurationSelfRescueFormKeys(app.newFlipIntent(Places.channelConfigurationSelfRescueForm)),
    channelConfigurationCompanyConfigurationForm: (app: Application) =>
        new ChannelConfigurationCompanyConfigurationFormKeys(
            app.newFlipIntent(Places.channelConfigurationCompanyConfigurationForm)
        ),
    channelConfigurationMenuSegmentationEditorForm: (app: Application) =>
        new ChannelConfigurationMenuSegmentationEditorFormKeys(
            app.newFlipIntent(Places.channelConfigurationMenuSegmentationEditorForm)
        )
}

export type ModalScopeAttributes = {
    fullscreen?: boolean
    transition?: 'slide-up' | 'none'
}

export type DetachedScopeSlot = {
    (scope: Scope, remove?: boolean): void
}

export class RootKeys extends IntentKeys {
    // Attributes
    readonly parentSlot = keys.attrSlot<ScopeSlot>(this, 'parent_slot')
    readonly filterSlot = keys.attrSlot<DetachedScopeSlot>(this, 'filter_slot')
    readonly modalSlot = keys.attrSlot<DetachedScopeSlot>(this, 'modal_slot')
}

export class SessionKeys extends RootKeys {
    // Parameters
    readonly hostAuthorization = keys.stringParam(this, 'token')
    readonly hostProvider = keys.stringParam(this, 'provider')
    readonly oAuthState = keys.stringParam(this, 'state')
    readonly oAuthSessionState = keys.stringParam(this, 'session_state')
    readonly oAuthCode = keys.stringParam(this, 'code')

    // Attributes
    readonly loggedUserId = keys.attr<string>(this, 'SessionKeys-userId')
    readonly alertHolder = keys.attr<AlertHolder>(this, 'SessionKeys-alertHolder')
}

export class DashboardKeys extends SessionKeys {
    // Parameters
    readonly startTime = keys.momentParam(this, 'dsh_stm')
    readonly endTime = keys.momentParam(this, 'dsh_etm')
    readonly channels = keys.stringArrayParam(this, 'dsh_chn')
    readonly customerServicesTypeFilter = keys.numberParam(this, 'dsh_usv_typ')
    readonly customerServicesCategoryFilter = keys.stringArrayParam(this, 'dsh_usv_cat')
    readonly customerServicesShowAll = keys.boolParam(this, 'dsh_usv_snv')
    readonly customerServicesTicketIdFilter = keys.numberParam(this, 'dsh_usv_tid')
    readonly customerServicesCustomerFilter = keys.stringParam(this, 'dsh_usv_cid')
    readonly customerServicesUsersFilter = keys.stringArrayParam(this, 'dsh_usv_uids')
    readonly customerServicesCompaniesFilter = keys.stringArrayParam(this, 'dsh_usv_cpids')
    readonly customerServicesDurationFilter = keys.numberParam(this, 'dsh_usv_dlay')

    // Attributes
    readonly credentials = keys.attr<Map<string, ChannelId>>(this, 'SessionKeys-Credentials')
    readonly refresh = keys.attr<boolean>(this, 'DashboardKeys-refresh')
}

export class DashboardActiveMessagesKeys extends DashboardKeys {
    // Parameters
    readonly userId = keys.stringParam(this, 'dsh_cid')

    // Attributes
    readonly selectedItem = keys.attr<{ userId: string; quantity: number }>(
        this,
        'DashboardActiveMessagesKeys-selectedItem'
    )
}

export type ChatContactInfo = {
    id: string
    channel: string
    customer: {
        account: string
        id: string
        name: string
        phone: string
    }
}

export class ChatKeys extends SessionKeys {
    // Parameters
    readonly startTime = keys.momentParam(this, 'cht_stm')
    readonly endTime = keys.momentParam(this, 'cht_etm')
    readonly channels = keys.stringArrayParam(this, 'cht_chn')
    readonly contactId = keys.stringParam(this, 'cht_contact')
    readonly customerNameId = keys.stringParam(this, 'cht_cstnmid')
    readonly customerPhoneId = keys.stringParam(this, 'cht_cstphid')
    readonly ticketStatus = keys.numberParam(this, 'cht_vis')

    // Attributes
    readonly credentials = keys.attr<Map<string, Partial<ChannelId>>>(this, 'ChatsKeys-Credentials')
    readonly refresh = keys.attr<boolean>(this, 'ChatKeys-refresh')
    readonly contact = keys.attr<ChatContactInfo>(this, 'ChatKeys-customer')
    readonly optionsCache = keys.attr<Map<string, Map<unknown, unknown>>>(this, 'ChatKeys-optionsCache')
}

export class ChatsFormKeys extends ChatKeys {
    // NOATTR
}

export class ChannelConfigurationListingKeys extends SessionKeys {
    // Parameters
    readonly channelName = keys.stringParam(this, 'chn_cna')
    readonly channelNumber = keys.stringParam(this, 'chn_cnu')

    // Attributes
    readonly refresh = keys.attr<boolean>(this, 'ConfigChannelKeys-refresh')
}

export enum ChannelErrorLocation {
    NONE,
    CHANNEL_NAME,
    COMPANY,
    COMPANY_SEGMENT,
    MENU,
    MESSAGE,
    HOURS,
    HOURS24,
    EXTRA
}

export class ChannelConfigurationFormKeys extends ChannelConfigurationListingKeys {
    // Parameters
    readonly channelId = keys.stringParam(this, 'chn_id')
    readonly tabIndex = keys.numberParam(this, 'chn_tix')

    // Attributes
    readonly hostPresenter = keys.attr<IPresenter>(this, 'ChannelConfigurationFormKeys-hostPresenter')
    readonly showError = keys.attr<ChannelErrorLocation>(this, 'ChannelConfigurationFormKeys-showError')
}

export class ChannelConfigurationMenuEditorFormKeys extends ChannelConfigurationFormKeys {
    readonly menuId = keys.numberParam(this, 'chnme_mid')
}

export class ChannelConfigurationMenuSegmentationEditorFormKeys extends ChannelConfigurationFormKeys {
    readonly menuId = keys.numberParam(this, 'chnmes_mid')
}

export class ChannelConfigurationTimesEditorFormKeys extends ChannelConfigurationFormKeys {
    readonly timesId = keys.numberParam(this, 'chnte_tid')
}

export class ChannelConfigurationSelfRescueFormKeys extends ChannelConfigurationFormKeys {
    readonly selfRescueId = keys.numberParam(this, 'chnsr_fid')
}

export class ChannelConfigurationCompanyConfigurationFormKeys extends ChannelConfigurationFormKeys {
    readonly companyConfiguration = keys.numberParam(this, 'chncc_cid')
}
