import { v4 as uuid } from 'uuid'
import type { AlertSeverity } from 'wdc-cube'
import { NOOP_VOID, Observable, observe, Scope } from 'wdc-cube'

@Observable
export class DialogScope extends Scope {
    @observe() open = false
    @observe() fullscreen = false
    @observe() transition = 'none'
    @observe() content?: Scope | null

    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class AlertMessageContentScope extends Scope {
    @observe() message?: string

    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class SimpleAlertMessageContentScope extends Scope {
    @observe() message = ''
    @observe() cancelLabelOption?: string
    @observe() confirmLabelOption?: string
    @observe() unfocusConfirmButton?: boolean

    @observe() onCancel = Scope.ASYNC_ACTION
    @observe() onConfirm = Scope.ASYNC_ACTION
}

@Observable
export class SimpleAlertScope extends Scope {
    @observe() title?: string
    @observe() content?: Scope

    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class AlertScope extends Scope {
    @observe() severity: AlertSeverity = 'info'
    @observe() title?: string
    @observe() content?: Scope

    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class InstantAlertScope extends Scope {
    @observe() severity: AlertSeverity = 'info'
    @observe() message = ''

    @observe() onClosed = Scope.SYNC_ACTION
}

@Observable
export class FilterScope extends Scope {
    @observe() content?: Scope | null
}

@Observable
export class MenuScope extends Scope {
    @observe() opened = true

    @observe() onDashboard = Scope.ASYNC_ACTION
    @observe() onChats = Scope.ASYNC_ACTION
    @observe() onConfigureChannel = Scope.ASYNC_ACTION
    @observe() onConfigureTemplates = Scope.ASYNC_ACTION
    @observe() onClose = Scope.ASYNC_ACTION
}

@Observable
export class MainScope extends Scope {
    readonly uid = uuid()
    readonly menu = new MenuScope()
    readonly filter = new FilterScope()
    readonly dialog = new DialogScope()
    readonly instantAlert = new InstantAlertScope()

    scrollTop = NOOP_VOID

    @observe() nonAuthorized = false
    @observe() authenticated = false
    @observe() body?: Scope | null
    @observe() alert?: AlertScope | null
    @observe() simpleAlert?: SimpleAlertScope | null
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    @observe() intercomAttributes?: any

    @observe() onOpenMenu = Scope.ASYNC_ACTION
    @observe() onScrollTopClicked = Scope.ASYNC_ACTION
}
