import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'

export const getOrMakeChannelConfigurationSelfRescueFormStyles = makeStyles()(() => ({
    view: {
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        gap: 20,
        width: 750
    },
    customHeaderFont: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: 0.15
    },
    content: {
        position: 'relative',
        bottom: 24,
        margin: '20px 0px',
        width: '100%',
        maxHeight: 584,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    customContentSelfRescueResumeDiv: {
        fontSize: '14px',
        color: grey[700],
        marginBottom: 24
    },
    customContentSelfRescueResumeContent: {
        marginBottom: 5
    },
    adjustFooterButtons: {
        display: 'inherit',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
    adjustItemsAtStart: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center'
    },
    adjustItemCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        marginBottom: 10
    },
    spaceBetweenMessageStart: {
        paddingRight: 10
    },
    spaceBetweenMessageEnd: {
        paddingLeft: 10
    },
    adjustMinutesField: {
        display: 'flex',
        flexDirection: 'row',
        width: '13%',
        height: '45px'
    },
    adjustRichEditor: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    richEditorWidth: {
        width: '100%'
    },
    viewMessages: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 30,
        flexShrink: 1
    }
}))
