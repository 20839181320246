import { SelfRescueStatus } from './channel'
import { ChannelDraftConfig } from './channel-draft'
import { EventGroupInfo } from './commons'
import { Media, Source } from './self-schedule'

export declare type SelfRescueResponseView = {
    options: OptionsSelfRescue
    configured: SelfRescueConfigView
    status: SelfRescueStatus
}

export type OptionsSelfRescue = {
    medias: Media[]
    origins: Source[]
    groupAndType: EventGroupInfo[]
    selfRescueType: SelfRescueType[]
}

export declare type ChannelDraftSelfRescue = SelfRescueConfig & {
    messages?: MessageRobot[]
}

export type MessageRobot = {
    key: string
    message: string
}

export type RequestRescueMessage = {
    group: string
    channel: string
    messages: MessageRobot[]
}

export type SelfRescueRequest = { draft: ChannelDraftConfig; group: string; channel: string; ignoreCache?: boolean }

export type SelfRescueConfig = {
    channel: string
    active: boolean
    eventRescueConfiguration: EventOptions
    expireIn: number
    type: SelfRescueType
}

export type SelfRescueConfigView = {
    active: boolean
    channel?: string
    eventRescueConfiguration: EventOptions
    expireIn: number
    selfRescue: boolean
    selfRescueEvent: boolean
    messages?: MessageRobot[]
}

type SelfRescueType = 'ROBOT_RESCUE' | 'EVENT_RESCUE'

type EventOptions = {
    group: string
    media: string
    origin: string
    type: string
}

export const buildView = (configured: ChannelDraftSelfRescue | SelfRescueConfigView): SelfRescueConfigView => {
    if ('selfRescue' in configured && configured.selfRescue !== undefined) {
        return configured as SelfRescueConfigView
    }
    const { active, channel, eventRescueConfiguration, expireIn, type, messages } = configured as ChannelDraftSelfRescue
    const selfRescue = type === `ROBOT_RESCUE`
    const selfRescueEvent = type === `EVENT_RESCUE`
    return {
        active,
        eventRescueConfiguration,
        expireIn,
        channel,
        messages,
        selfRescueEvent,
        selfRescue
    }
}
