import { Button, TextField } from '@mui/material'
import { Strings } from '@syonet/lang'
import clsx from 'clsx'
import React, { useRef } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ChannelConfigurationFormGeneralScope } from '../ChannelConfigurationForm.scopes'
import { TextsProvider } from '../texts'
import { getOrMakeChannelConfigurationFormGeneralStyles as getOrMakeStyle } from './ChannelConfigurationForm.styles'
import { ChannelConfigurationFormCompaniesView } from './ChannelConfigurationFormCompanies.view'
import HistoryIcon from '@mui/icons-material/History'
import { SegmentationList } from '../../../components/segmentation/SegmentationList'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationFormGeneralViewProps = IViewProps & {
    scope: ChannelConfigurationFormGeneralScope
}

export function ChannelConfigurationFormGeneralView({ className, scope }: ChannelConfigurationFormGeneralViewProps) {
    bindUpdate(React, scope)
    const channelNameRef = useRef<HTMLInputElement>(null)

    if (scope.focus === 'channel-name') {
        focusOnChannelName(scope, channelNameRef)
    }

    const { classes: styles } = getOrMakeStyle()

    function onDragEnd({ destination, source }: DropResult) {
        if (!destination && !source) {
            return
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const indexDest: number = destination!.index | 0

        const indexSource: number = source.index

        scope.onReorderSegmentationChanged({ destination: indexDest, source: indexSource })
    }

    return (
        <div className={clsx(styles.view, className)}>
            <div className={styles.adjustElements}>
                <div className={styles.adjustTextFields}>
                    <TextField
                        inputRef={channelNameRef}
                        error={scope.channelNameError ? true : false}
                        id="outlined-basic"
                        label={texts.CHANNEL_CONFIGURATION_GENERAL_NAME}
                        placeholder={texts.CHANNEL_CONFIGURATION_GENERAL_NAME_PLACEHOLDER}
                        variant="outlined"
                        className={styles.adjustChannelNameInputFieldSize}
                        value={scope.channelName}
                        onChange={scope.onChannelNameChanged}
                    />
                    <TextField
                        id="outlined-basic"
                        label={texts.CHANNEL_CONFIGURATION_GENERAL_NUMBER}
                        variant="outlined"
                        className={styles.adjustChannelInputFieldSize}
                        value={Strings.formatPhoneV2(scope.channelPhone)}
                        disabled
                    />
                </div>
                <div className={styles.adjustButtons}>
                    <Button variant="contained" color="secondary" onClick={scope.onOpenSelfRescueEditor}>
                        <HistoryIcon style={{ paddingRight: 5 }} />
                        <span>{texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE}</span>
                    </Button>
                </div>
            </div>
            <ChannelConfigurationFormCompaniesView
                scope={scope.companies}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                segmentation={<SegmentationList segmentations={scope.segmentation!} onDragEnd={onDragEnd} />}
            />
        </div>
    )
}

function focusOnChannelName(
    scope: ChannelConfigurationFormGeneralScope,
    channelNameRef: React.RefObject<HTMLInputElement>
) {
    const inputElm = channelNameRef.current
    if (inputElm) {
        scope.focus = ''
        inputElm.scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => inputElm.focus(), 100)
    }
}
