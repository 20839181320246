import Duration from 'duration-relativetimeformat'

export const texts = {
    LOCALE: 'pt-BR',
    DURATION: new Duration('pt'),
    DASHBOARD_FILTER_DATE_TIME: 'Data e hora',
    DASHBOARD_FILTER_CHANNEL_NAME: 'Canais',
    DASHBOARD_FILTER_CLEAR_BUTTON: 'Limpar',
    DASHBOARD_FILTER_FILTER_BUTTON: 'Filtrar',
    DASHBOARD_FILTER_DATE_TIME_START: 'Data/hora início',
    DASHBOARD_FILTER_DATE_TIME_END: 'Data/hora fim',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_ALERT_ERROR_TITLE: 'Inconsistência de estado',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_DATE_TIME_ALERT_ERROR_CONTENT:
        'Data de início deve ser menor que a data de fim',
    DASHBOARD_FILTER_STATE_INCONSISTENCY_MULTILISTING_ALERT_ERROR_CONTENT: 'Busca sem item selecionado não é permitida',

    KPI_EVENTS_TITLE: 'Eventos',
    KPI_EVENTS_INFO: 'Este KPI apresenta o total de eventos gerados no CRM Syonet para o canal e período filtrado',

    KPI_CONTACTS_TITLE: 'Contatos',
    KPI_CONTACTS_INFO:
        'Este KPI apresenta o total de clientes diferentes que entraram em contato com o canal no período filtrado',

    KPI_NEW_CONTACTS_TITLE: 'Novos contatos',
    KPI_NEW_CONTACTS_INFO:
        'Este KPI apresenta o total de novos clientes diferentes que entraram em contato com o canal no período filtrado.\n\nNovos clientes: clientes que ainda não existiam na base de dados do CRM Syonet',

    KPI_SCHEDULE_TITLE: 'Agendamentos',
    KPI_SCHEDULE_INFO: 'Este KPI apresenta o total de agendamentos',

    SERVICE_TABLE_ALL_ATTENDANCES_TITLE: 'TODAS AS CONVERSAS (%d)',
    SERVICE_TABLE_TITLE: 'Sem visualização (%d)',
    SERVICE_TABLE_INFO:
        'Esta tabela retorna todos os eventos que possuem mensagens não visualizadas pelo usuário do CRM Syonet para o canal filtrado, ordenando de forma decrescente (do mais antigo para o mais novo).',
    SERVICE_TABLE_ROWS_PER_PAGE: 'Linhas por página: ',
    SERVICE_TABLE_GO_TO_CHAT: 'Ir para conversas',
    SERVICE_TABLE_GO_TO_TICKET: 'Ir para evento',

    SERVICE_TABLE_FILTER_TICKET_NUMBER: 'Número do evento',
    SERVICE_TABLE_FILTER_CUSTOMERS: 'Clientes',
    SERVICE_TABLE_FILTER_COMPANIES: 'Empresas',
    SERVICE_TABLE_FILTER_CATEGORY: 'Grupo/Evento',
    SERVICE_TABLE_FILTER_USERS: 'Usuários',
    SERVICE_TABLE_FILTER_SERVICES: 'Atendimentos',
    SERVICE_TABLE_FILTER_TIME: 'Tempo',
    SERVICE_TABLE_FILTER_OPEN_BUTTON: 'Filtros',
    SERVICE_TABLE_FILTER_CLEAR_BUTTON: 'Limpar',
    SERVICE_TABLE_FILTER_APPLY_BUTTON: 'Aplicar',

    AVERAGE_VISUALIZATION_TIME_CHART:
        'Gráfico tempo médio para visualização\n\nEste gráfico apresenta a média de tempo para visualização de mensagens recebidas dentro de cada hora do dia, a partir do canal e período filtrados.\n\nEle dará uma visão geral da média de tempo que o cliente envia a mensagem ao canal e espera visualização do usuário do CRM Syonet.\n\nA princípio, os horários com maior espera são os que o gestor deve ter mais atenção. Algumas possíveis causas: falta de pessoas para atender a demanda do horário, desleixo dos usuários que recebem os eventos, etc.\n\nExemplo: Se eu estiver filtrado o canal X, de 01/10/2020 a 31/10/2020, o sistema analisará os 31 dias, hora a hora.\n\nSe olharmos somente para o intervalo de hora das 08:00:00 a 08:59:59, ele apresentará uma média de tempo para visualização das mensagens recebidas entre esse horário.\n\nNo gráfico este exemplo colocará a média no horário 8h.',
    CONTACTS_BY_DAY:
        'Contatos por dia\n\nA sugestão da Syonet para filtro deste relatório é sempre mensal (o mês inteiro). Se quiser resultado para um período maior, ainda assim filtrar os meses inteiros.\nExemplo: 01/01/2020 a 30/04/2020.\n\nUso correto\n“O gráfico CONTATOS POR DIA exibe os resultados por DIA, ou seja, se filtrarmos  um período de 31 dias, por exemplo,  do dia 01/01/2021 até o dia  31/01/2021. O gráfico exibirá o resultado por dia 1 ao 31.\nIsso vale para a quantidade de meses que estamos usando, o ideal é uso de números pares e com os meses completos, por exemplo, 01/01/2021- 30/04/2021, assim teremos um total de contatos por dia desses 4 meses.\nAgora se realizarmos um filtro de 31 dias, porém, com um período que abrange o mesmo dia, mas em meses diferentes, por exemplo, do dia 22/01/2021 até  o dia 22/02/2021,  o gráfico irá somar os contatos por dia, ou seja:\n\nCaso 1:\n2 contatos no dia 20/01\n5 contatos no dia 20/02\nO gráfico exibe 7 contatos no dia 20\n\nCaso 2:\n0 contatos no dia 21/01\n10 contatos no dia 21/02\nO gráfico exibe 10 contatos no dia 21\n\nObs: Para a consulta do gráfico a hora do filtro é ignorada, o sistema considera apenas o dia inicial e o dia final.',

    ACTIVE_MESSAGES_PER_USER_TITLE: 'Mensagens pré-aprovadas (HSM)',
    ACTIVE_MESSAGES_PER_USER_DETAILED_LIST:
        'HSM(s) são mensagens enviadas pela empresa para tentar iniciar uma conversa com o cliente, as mesmas precisam ser pré-aprovadas.',
    ACTIVE_MESSAGES_PER_USER_EXPORT_TABLE_TO_XLS_FORMAT: 'Exportar tabela para XLS',
    ACTIVE_MESSAGES_PER_USER_LABEL: 'Usuário',
    ACTIVE_MESSAGES_PER_USER_SENT_MESSAGES_LABEL: 'Mensagens',
    ACTIVE_MESSAGES_PER_USER_PARTIAL_TOTAL_VALUE_LABEL: 'Total da parcial',
    ACTIVE_MESSAGES_PER_USER_ROWS_PER_PAGE: 'Linhas por página: ',

    UNREAD_MESSAGES_COLUMN_EVENT: 'Evento',
    UNREAD_MESSAGES_COLUMN_CLIENT: 'Cliente',
    UNREAD_MESSAGES_COLUMN_COMPANY: 'Empresa',
    UNREAD_MESSAGES_COLUMN_DEPARTMENT: 'Departamento',
    UNREAD_MESSAGES_COLUMN_USER: 'Usuário',
    UNREAD_MESSAGES_COLUMN_UNREAD_SINCE: 'Última visualização',

    ACTIVE_USER_MESSAGES_MODAL_TITLE: 'Mensages ativas do usuário',
    ACTIVE_USER_MESSAGES_MODAL_CLOSE_BUTTON: 'Fechar',
    ACTIVE_USER_MESSAGES_MODAL_EXPORT_BUTTON: 'Exportar',
    ACTIVE_USER_MESSAGES_MODAL_ATTENDANCE_NUMBER: 'Nº atendimento',
    ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_NAME: 'Nome cliente',
    ACTIVE_USER_MESSAGES_MODAL_CUSTOMER_PHONE: 'Telefone cliente',
    ACTIVE_USER_MESSAGES_MODAL_SENT_DATE: 'Envio',
    ACTIVE_USER_MESSAGES_MODAL_TEXT_MESSAGE: 'Texto mensagem',
    ACTIVE_USER_MESSAGES_MODAL_ROWS_PER_PAGE: 'Linhas por página: ',

    NO_CONTENT_TO_GENERATE_FILE: 'Sem conteúdo para gerar o arquivo',
    FILE_NAME: 'active_messages',

    ATTENDANCE_TABLE_DATE: 'Data',
    ATTENDANCE_TABLE_TIME: 'Hora',

    ON_ACTIVE_MESSAGES_EXPORT_TO_XLS_ALERT_TITLE: 'Relatório',
    DATE_TIME_INITIAL_CHIP_DESCRIPTION: 'Data/hora início',
    DATE_TIME_FINAL_CHIP_DESCRIPTION: 'Data/hora fim',
    CHANNEL_NAME_CHIP_DESCRIPTION: 'Canal',

    INVALID_CONTEXT_ARGUMENT_ERROR: 'Invalid context or arguments',
    NO_DATA_TO_USER_ERROR: 'Sem dados para o usuário',

    CUSTOMER_SERVICE_FILTER_ALL: 'Todos',
    CUSTOMER_SERVICE_FILTER_ROBOT: 'Robô',
    CUSTOMER_SERVICE_FILTER_HUMAN: 'Humano',
    CUSTOMER_SERVICE_FILTER_ONE_DAY: 'Mais de %d dia',
    CUSTOMER_SERVICE_FILTER_MORE_THAN_ONE_DAY: 'Mais de %d dias',

    LOADING_CONTENT_DESCRIPTION: 'Carregando...'
}
