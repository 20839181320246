import { CssBaseline } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { bindUpdate, IViewProps, ViewSlot } from 'wdc-cube-react'
import { DashboardScope } from '../Dashboard.scopes'
import { getOrMakeDashboardStyles } from './Dashboard.styles'
import { DashboardActiveMessagesResumedView } from './DashboardActiveMessagesResumed.view'
import { DashboardCustomerServiceView } from './DashboardCustomerService.view'

export type DashboardViewProps = IViewProps & { scope: DashboardScope }

export function DashboardView({ className, style, scope }: DashboardViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeDashboardStyles()

    return (
        <>
            <div className={clsx(styles.view, className)} style={style}>
                <CssBaseline />
                <ViewSlot scope={scope.filterChips} />
                <DashboardCustomerServiceView scope={scope.customerServices} />
                <DashboardActiveMessagesResumedView scope={scope.activeMessages} />
            </div>
        </>
    )
}
