import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { bindUpdate } from 'wdc-cube-react'
import { MultipleNumericValuesAutoCompleteScope } from '../AutoCompleteField.scopes'

type MultipleAutoCompleteNumericFieldProps = {
    scope: MultipleNumericValuesAutoCompleteScope
    label: string
    placeholder: string
    onKeyUp?: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

export function MultipleAutoCompleteNumericFieldView({
    scope,
    label,
    placeholder,
    onKeyUp
}: MultipleAutoCompleteNumericFieldProps) {
    bindUpdate(React, scope)

    return (
        <Autocomplete
            multiple
            size="small"
            options={scope.options.map((option) => option)}
            getOptionLabel={(option) => option.name}
            value={scope.value ?? []}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={scope.onChange}
            onInputChange={scope.onInputChange}
            onFocus={scope.onFocus}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
            )}
            onKeyUp={onKeyUp}
        />
    )
}
