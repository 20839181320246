import React from 'react'
import { TextsProvider } from '../../texts'
import { getOrMakeChannelConfigurationSelfRescueFormStyles as getOrMakeStyles } from './ChannelConfigurationCompanyConfigurationForm.styles'
import { bindUpdate, IViewProps } from 'wdc-cube-react'
import { ChannelConfigurationCompanyConfigurationFormScope } from '../../companyConfiguration/ChannelConfigurationCompanyConfiguration.scopes'
import clsx from 'clsx'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button, Checkbox, FormControl, FormControlLabel, IconButton, TextField, Tooltip } from '@mui/material'

// @Inject
const texts = TextsProvider.get()

export type ChannelConfigurationCompanyConfigurationFormViewProps = IViewProps & {
    scope: ChannelConfigurationCompanyConfigurationFormScope
}

export function ChannelConfigurationCompanyConfigurationFormView({
    className,
    scope
}: ChannelConfigurationCompanyConfigurationFormViewProps) {
    bindUpdate(React, scope)

    const { classes: styles } = getOrMakeStyles()

    return (
        <div className={clsx(styles.view, className)}>
            <h2 className={styles.customHeaderFont}>Configuração da empresa</h2>

            <div className={styles.content}>
                <FormControl sx={{ my: 1, width: '100%', display: 'flex', gap: 2 }}>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Empresa"
                        value={scope.companyName}
                        onChange={scope.onCompanyNameChanged}
                    />
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Descrição"
                        value={scope.companyReference}
                        onChange={scope.onCompanyDescriptionChanged}
                    />
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={scope.showAttendantName}
                                    onChange={scope.onShowAtendentInMessage}
                                    color="secondary"
                                />
                            }
                            label="Exibir nome do atendente em mensagens para o cliente"
                        />

                        <Tooltip title="Mostrar o nome do atendente em cada mensagem de texto enviada no chat." arrow>
                            <IconButton>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={scope.usesPreSaleNotification}
                                    onChange={scope.onUsesPreSalNotification}
                                    color="secondary"
                                />
                            }
                            label={texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION}
                        />

                        <Tooltip
                            title={
                                texts.CHANNEL_CONFIGURATION_GENERAL_COMPANY_CONFIGURATION_USES_PRE_SALE_NOTIFICATION_TOOLTIP
                            }
                            arrow
                        >
                            <IconButton>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </FormControl>
            </div>

            <div className={styles.adjustFooterButtons}>
                <Button color="inherit" onClick={scope.onCancel}>
                    {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_DISCARD_BUTTON}
                </Button>
                <Button variant="contained" color="secondary" onClick={scope.onSave}>
                    {texts.CHANNEL_CONFIGURATION_GENERAL_SELF_RESCUE_FORM_CONFIRM_BUTTON}
                </Button>
            </div>
        </div>
    )
}
