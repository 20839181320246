import { ChannelConfigurationCompanyConfigurationFormKeys, DetachedScopeSlot, KeysFactory } from 'src/Constants'
import { action, CubePresenter, FlipIntent, IPresenterOwner, NOOP_VOID } from 'wdc-cube'
import { MainPresenter } from '../../main'
import { ChannelConfigurationCompanyConfigurationFormScope } from './ChannelConfigurationCompanyConfiguration.scopes'

import type { CheckedChangeEvent, TextChangeEvent } from 'src/utils'
import { ChannelConfigurationFormPresenter } from '../ChannelConfigurationForm.presenter'
import { Tabs } from '../ChannelConfigurationForm.scopes'

export class ChannelConfigurationCompanyConfigurationFormPresenter
    extends CubePresenter<MainPresenter, ChannelConfigurationCompanyConfigurationFormScope>
    implements IPresenterOwner
{
    private modalSlot = NOOP_VOID as DetachedScopeSlot

    private companyConfiguration = -1
    private disposed = false
    private __formPresenter!: ChannelConfigurationFormPresenter

    public constructor(app: MainPresenter) {
        super(app, new ChannelConfigurationCompanyConfigurationFormScope())
    }

    public override release() {
        this.disposed = true
        this.modalSlot(this.scope, true)
        super.release()
    }

    public async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        const keys = new ChannelConfigurationCompanyConfigurationFormKeys(intent)

        if (initialization) {
            await this.initializeState(keys)
        } else {
            await this.synchronizeState(keys)
        }

        this.modalSlot(this.scope)

        return true
    }

    public publishParameters(intent: FlipIntent): void {
        const keys = new ChannelConfigurationCompanyConfigurationFormKeys(intent)
        if (this.companyConfiguration > 0) {
            keys.companyConfiguration(this.companyConfiguration)
        }
    }

    private bindListeners() {
        this.scope.onCancel = this.onCancel.bind(this)
        this.scope.onSave = this.save.bind(this)
        this.scope.onCompanyNameChanged = this.onCompanyNameChanged.bind(this)
        this.scope.onCompanyDescriptionChanged = this.onCompanyDescriptionChanged.bind(this)
        this.scope.onShowAtendentInMessage = this.onShowAtendentInMessage.bind(this)
        this.scope.onUsesPreSalNotification = this.onUsesPreSaleNotification.bind(this)
    }

    private async initializeState(keys: ChannelConfigurationCompanyConfigurationFormKeys) {
        this.bindListeners()
        this.modalSlot = keys.modalSlot()
        this.__formPresenter = keys.hostPresenter() as ChannelConfigurationFormPresenter

        await this.synchronizeState(keys, true)
    }

    private async synchronizeState(keys: ChannelConfigurationCompanyConfigurationFormKeys, force = false) {
        let changed = force || (keys.refresh() ?? false)

        const newCompanyConfiguration = keys.companyConfiguration() ?? this.companyConfiguration
        changed = changed || newCompanyConfiguration !== this.companyConfiguration
        if (changed) {
            this.companyConfiguration = newCompanyConfiguration
            await this.refresh()
        }
    }

    private async refresh() {
        const form = this.formPresenter.generalPresenter.getCompanyConfig(this.companyConfiguration)
        if (!form) return
        this.scope.id = this.companyConfiguration
        this.scope.companyName = form.internalCompany.companyName
        this.scope.companyReference = form.internalCompany.companyReference
        this.scope.showAttendantName = form.internalCompany.showAttendantName
        this.scope.serviceBySegmentation = form.internalCompany.serviceBySegmentation
        this.scope.usesPreSaleNotification = form.internalCompany.usesPreSaleNotification
    }

    private async close() {
        if (!this.disposed) {
            const keys = KeysFactory.channelConfigurationForm(this.app)
            keys.tabIndex(Tabs.GENERAL)
            await this.flipToIntent(keys.intent)
        }
    }

    private async save() {
        this.formPresenter.generalPresenter.saveCompanyForm(this.scope)
        this.close()
    }

    public get formPresenter() {
        return this.__formPresenter
    }

    @action()
    private onCompanyNameChanged(evt: TextChangeEvent) {
        this.scope.companyName = evt.target.value
    }

    @action()
    private onCompanyDescriptionChanged(evt: TextChangeEvent) {
        this.scope.companyReference = evt.target.value
    }

    @action()
    private onShowAtendentInMessage(evt: CheckedChangeEvent) {
        this.scope.showAttendantName = evt.target.checked
    }

    @action()
    private onUsesPreSaleNotification(evt: CheckedChangeEvent) {
        this.scope.usesPreSaleNotification = evt.target.checked
    }

    @action()
    private async onCancel() {
        await this.close()
    }
}
