import React from 'react'
import clsx from 'clsx'

import { Alert, Snackbar } from '@mui/material'

import { bindUpdate } from 'wdc-cube-react'
import { InstantAlertScope } from '../Main.scopes'
import { getOrMakeMainStyles } from './Main.styles'

export type MainInstantAlertProps = {
    className?: string
    scope: InstantAlertScope
}

export function MainInstantAlertView({ className, scope }: MainInstantAlertProps) {
    bindUpdate(React, scope)

    const { open, onClose } = InstantAlertViewContext.getOrCreate(scope)

    const { classes: styles } = getOrMakeMainStyles()

    return (
        <Snackbar
            className={clsx(styles.adjustAlertPosition, className)}
            open={open}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={scope.severity ?? 'success'} sx={{ width: '100%' }}>
                {scope.message}
            </Alert>
        </Snackbar>
    )
}

class InstantAlertViewContext {
    static readonly create = () => new InstantAlertViewContext()

    static getOrCreate(scope: InstantAlertScope) {
        const [ctx] = React.useState(InstantAlertViewContext.create)
        ctx.scope = scope
        ctx.open = !!scope.message
        return ctx
    }

    open!: boolean
    scope!: InstantAlertScope

    readonly onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        this.scope.onClosed()
    }
}
