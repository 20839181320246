import { action, IPresenterOwner, IUpdateManager, Presenter } from 'wdc-cube'
import { TimePickerScope } from './TimePicker.scopes'

export class TimePickerPresenter extends Presenter<TimePickerScope> {
    public constructor(owner: IPresenterOwner, scope?: TimePickerScope, updateManager?: IUpdateManager) {
        super(owner, scope ?? new TimePickerScope(), updateManager)
    }

    public initializeState() {
        this.scope.onChange = this.onChange.bind(this)
    }

    @action()
    public async onChange(time: Date | null, keyboardInputValue?: string) {
        this.scope.onChange(time, keyboardInputValue)
    }
}
