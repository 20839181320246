import { action, IPresenterOwner, IUpdateManager, Presenter } from 'wdc-cube'
import { DateTimePickerScope } from './DateTimerPicker.scopes'

export class DateTimePickerPresenter extends Presenter<DateTimePickerScope> {
    public constructor(owner: IPresenterOwner, scope?: DateTimePickerScope, updateManager?: IUpdateManager) {
        super(owner, scope ?? new DateTimePickerScope(), updateManager)
    }

    public initializeState() {
        this.scope.onChange = this.onChange.bind(this)
    }

    @action()
    public async onChange(time: Date | null | undefined, keyboardInputValue?: string) {
        this.scope.onChange(time, keyboardInputValue)
    }
}
