import { EditorState } from 'draft-js'
import { Observable, observe, Scope } from 'wdc-cube'

@Observable
export class RichEditorScope extends Scope {
    @observe() caption = ''
    @observe() text = EditorState.createEmpty()
    @observe() status: { text: string; tags?: string[] } | null = null
    @observe() focus = false

    @observe() onChanged = Scope.SYNC_ACTION_ONE<EditorState>()
    @observe() onTagClicked = Scope.SYNC_ACTION_STRING
}

@Observable
export class RichDefaultEditorScope extends Scope {
    @observe() caption = ''
    @observe() text = EditorState.createEmpty()
    @observe() status: { text: string } | null = null
    @observe() focus = false

    @observe() onChanged = Scope.SYNC_ACTION_ONE<EditorState>()
}
